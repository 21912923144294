import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pagination, { IPagination } from "components/Pagination";
import SearchFilter from "components/SearchFilter";
import { useHistory, useLocation } from "react-router-dom";
import { getCountries, getStates } from "api/countriesAPI";
import SortTableColumnDropdown from "components/SortTableColumnDropdown";
import { CorporationFacilitiesWrapper } from "./styled";
import CorporationTabs from "../CorporationMenu/CorporationMenu";
import { sortIndBooleanValue, parseJwt } from "../../../common/utils";
import {
    getCorporationDetailsById,
    getCorporationFacilities,
} from "../../../api/corporationAPI";
import CONSTANTS from "../../../common/constants";
import TableRowCount from "../../../components/TableRowCount";
import { setPageLoadingStatus } from "../../../components/Loader/loader.slice";
import { getCategoriesForPrograms } from "../../../api/programs";
import FacilitiesRegisteredTable from "../FacilitiesRegisteredTable/FacilitiesRegisteredTable";
import { setRegion } from "../../../components/SelectDomesticInternational/region.slice.";
import {
    setProgramCategoryId,
    setSearchKey,
    setCountryCode,
    setStateCode,
} from "../../../components/SearchFilter/searchFilter.slice";

export const CorporationRenewingFacilities = () => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const region = useSelector((state: any) => {
        return state.region;
    });
    const searchFilter = useSelector((state: any) => {
        return state.searchFilter;
    });
    const paginationSetLimit: number = 5;
    const [pageNumber, setPageNumber] = useState<number>(
        location?.state?.pageNumber ? location.state.pageNumber : 1
    );
    const defaultPagination: IPagination = {
        pageNumber: 1,
        pageSize: 25,
        isFirst: true,
        isLast: false,
        setPageNumber,
        paginationSetLimit,
    };
    const [pagination, setPagination] =
        useState<IPagination>(defaultPagination);
    const [facilities, setFacilities] = useState<any>([]);
    const [isTableLoading, setIstableLoading] = useState<boolean>(false);
    const [corpPrograms, setCorpPrograms] = useState<any>([]);
    const [mounted, setMounted] = useState<boolean>(false);
    const [isCountryLoading, setIsCountryLoading] = useState<boolean>(false);
    const [isStateLoading, setIsStateLoading] = useState<boolean>(false);
    const [countryDropdown, setCountryDropdown] = useState<any>([]);
    const [stateDropdown, setStateDropdown] = useState<any>([]);
    const [programCategory, setProgramCategory] = useState<any>(
        searchFilter.programCategory
    );
    const [sortInd, setSortInd] = useState<any>(
        location?.state?.sortingInd ? location.state.sortingInd : undefined
    );
    const [country, setCountry] = useState<any>(searchFilter.countryCode);
    const [stateCd, setStateCd] = useState<any>(searchFilter.stateCode);
    const [roleValue, setRoleValue] = useState<string>("none");
    const [clearFilters, setClearFilters] = useState<boolean>(false);
    const [tab, setTab] = useState<any>("");
    const corporationId = parseJwt(
        window.localStorage.getItem("userAccessToken")
    ).corporation_id;
    useEffect(() => {
        if (!mounted) {
            if (localStorage.getItem("clearFilter") == "true") {
                dispatch(setRegion(CONSTANTS.ALL));
                dispatch(setProgramCategoryId(CONSTANTS.ALL));
                dispatch(setSearchKey(""));
                dispatch(setCountryCode(CONSTANTS.ALL));
                dispatch(setStateCode(CONSTANTS.ALL));
                setProgramCategory(CONSTANTS.ALL);
                getSearchedFacilities("", true);
            }
            getCorporationDetailsById(corporationId).then(
                (corporationResponse: any) => {
                    if (corporationResponse.success) {
                        const selectedProgramsMap: any = [];
                        corporationResponse.data.corporationDetails.corporationProgramDetails.map(
                            (programs: any) => {
                                selectedProgramsMap.push(programs.programId);
                            }
                        );
                        const selectedcategoriesMap: any = [
                            { label: "All", value: CONSTANTS.ALL },
                        ];
                        getCategoriesForPrograms(selectedProgramsMap).then(
                            (categoriesResponse: any) => {
                                if (categoriesResponse.success) {
                                    categoriesResponse.data.categories.map(
                                        (category: any) => {
                                            selectedcategoriesMap.push({
                                                label: category.categoryName,
                                                value: category.categoryId,
                                                programId: category.programId,
                                            });
                                        }
                                    );
                                    setCorpPrograms(selectedcategoriesMap);
                                    setMounted(true);
                                    localStorage.setItem(
                                        "resetFilter",
                                        "false"
                                    );
                                }
                            }
                        );
                    }
                }
            );
        }
        if (!clearFilters && localStorage.getItem("resetFilter") == "false") {
            getSearchedFacilities(searchFilter.searchKey);
        }
    }, [pageNumber, region.region, programCategory, sortInd, country, stateCd]);

    useEffect(() => {
        const countries = [{ label: "All", value: CONSTANTS.ALL }];
        const states = [{ label: "All", value: CONSTANTS.ALL }];
        getCountries().then((response) => {
            setIsCountryLoading(true);
            if (response?.data?.countries) {
                response.data.countries.map((item: any) => {
                    countries.push({
                        label: item.countryName,
                        value: item.countryCode,
                    });
                });
                setCountryDropdown(countries);
                setIsCountryLoading(false);
            }
        });
        getStates("US").then((response) => {
            setIsStateLoading(true);
            if (response?.data) {
                response.data.map((item: any) => {
                    states.push({
                        label: item.name,
                        value: item.name,
                    });
                });
                setStateDropdown(states);
                setIsStateLoading(false);
            }
        });
        window.onbeforeunload = function () {
            history.replace();
            return undefined;
        };
        return () => {
            window.onbeforeunload = null;
        };
    }, []);

    useEffect(() => {
        localStorage.setItem("clearFilter", "false");
        if (clearFilters) {
            getSearchedFacilities("", true);
        }
    }, [clearFilters]);
    const tabName = (value: any) => {
        setTab(value);
    };

    const getSearchedFacilities = (value: string, clearFilterInd?: boolean) => {
        setIstableLoading(true);
        getCorporationFacilities({
            corporationId,
            pageNumber: clearFilterInd ? 1 : pageNumber,
            pageSize: pagination.pageSize,
            status: CONSTANTS.RENEWING,
            region: clearFilterInd ? CONSTANTS.ALL : region.region,
            categoryId: clearFilterInd ? CONSTANTS.ALL : programCategory,
            sortInd: sortIndBooleanValue(searchFilter.sortOrganizations, tab),
            sortOrganizations: searchFilter.sortOrganizations,
            country: clearFilterInd ? CONSTANTS.ALL : country,
            stateCd: clearFilterInd ? CONSTANTS.ALL : stateCd,
            searchKey: value,
        })
            .then((response: any) => {
                setFacilities(response.data.facilityDetails);
                setPagination({
                    ...response.data._pagination,
                    setPageNumber,
                });
                setIstableLoading(false);
                setClearFilters(false);
                if (mounted) {
                    setRoleValue("alert");
                }
            })
            .catch(() => {
                if (mounted) {
                    setIstableLoading(false);
                }
            });
    };

    const setDefaultPagination = () => {
        setPageNumber(1);
        setPagination(defaultPagination);
    };

    const getFacilitiesResult = ({
        regionName,
        categoryId,
        countryCd,
        stateCode,
        search,
    }: any) => {
        setIstableLoading(true);
        setDefaultPagination();
        getCorporationFacilities({
            corporationId,
            pageNumber: 1,
            pageSize: pagination.pageSize,
            status: CONSTANTS.RENEWING,
            region: regionName || region.region,
            categoryId: categoryId || programCategory,
            sortInd,
            country: countryCd || country,
            stateCd: stateCode || stateCd,
            searchKey: search !== undefined ? search : searchFilter.searchKey,
        })
            .then((response: any) => {
                setFacilities(response.data.facilityDetails);
                setPagination({
                    ...response.data._pagination,
                    setPageNumber,
                });
                setIstableLoading(false);
                setClearFilters(false);
                if (mounted) {
                    setRoleValue("alert");
                }
            })
            .catch(() => {
                if (mounted) {
                    setIstableLoading(false);
                }
            });
    };
    const sortIndValue = (value: any) => {
        setSortInd(value);
    };

    return (
        <CorporationFacilitiesWrapper className="corporation-container">
            <CorporationTabs
                activeTab="corporationRenewingFacilties"
                tabName={tabName}
            />
            <div className="container pt-lg-3 pb-5">
                <h1 className="h4 font-500 mb-4">Renewing Organizations</h1>
                <SearchFilter
                    placeholder="By Organization Name/Code"
                    programCategories={corpPrograms}
                    programCategory={programCategory}
                    setProgramCategory={setProgramCategory}
                    setDefaultPagination={setDefaultPagination}
                    country={country}
                    setCountry={setCountry}
                    stateCd={stateCd}
                    setStateCd={setStateCd}
                    setClearFilters={setClearFilters}
                    countryDropdown={countryDropdown}
                    stateDropdown={stateDropdown}
                    isCountryLoading={isCountryLoading}
                    isStateLoading={isStateLoading}
                    getFacilitiesResult={getFacilitiesResult}
                    setSortInd={setSortInd}
                />
                <div className="d-flex justify-content-between mt-4 align-items-md-end mt-4 pt-2 flex-md-row flex-column">
                    <TableRowCount
                        isFirst={pagination.isFirst}
                        pageNumber={pagination.pageNumber}
                        pageSize={pagination.pageSize}
                        isLast={pagination.isLast}
                        totalCount={pagination.totalCount}
                        facilityType="Renewing"
                        roleValue={roleValue}
                    />
                    <div className="mt-3 mt-md-0">
                        <SortTableColumnDropdown
                            getCorporationFacilities={getCorporationFacilities}
                            corporationId={corporationId}
                            pageNumber={1}
                            pageSize={pagination.pageSize}
                            status={CONSTANTS.RENEWING}
                            categoryId={programCategory}
                            searchKey={searchFilter.searchKey}
                            sortIndValue={sortIndValue}
                            setFacilities={setFacilities}
                            id="sortRenewingFacilities"
                            stateCd={stateCd}
                            country={country}
                        />
                    </div>
                </div>
                {isTableLoading ? (
                    <div
                        role="alert"
                        aria-label="Renewed organizations table is loading"
                        className="sr-only"
                    />
                ) : (
                    ""
                )}
                <FacilitiesRegisteredTable
                    source="registeredFacility"
                    facilities={facilities}
                    isTableLoading={isTableLoading}
                    tab={CONSTANTS.RENEWING}
                    setSortInd={setSortInd}
                    sortInd={sortInd}
                    pageNumber={pageNumber}
                />
                {pagination && Number(pagination.totalPages) > 0 ? (
                    <Pagination
                        pageNumber={pagination.pageNumber}
                        pageSize={pagination.pageSize}
                        totalCount={pagination.totalCount}
                        totalPages={pagination.totalPages}
                        isFirst={pagination.isFirst}
                        isLast={pagination.isLast}
                        setPageNumber={pagination.setPageNumber}
                        paginationSetLimit={paginationSetLimit}
                    />
                ) : null}
            </div>
        </CorporationFacilitiesWrapper>
    );
};

export default CorporationRenewingFacilities;
