import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import RegistryBanner from "components/RegistryBanner";
import { getRegistryById, getRegistryHospitals } from "api/registryAPI";
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import { logError } from "utils/logger.utils";
import CONSTANTS from "common/constants";
import { BreadCrumb } from "components/BreadCrumb";
import { RegistryManagementDetailsWrapper } from "./styled";
import Sidebar from "../../Admin/Sidebar/Sidebar";
import AddOrEditRegistry from "./AddOrEditRegistryPage";
import ViewRegistry from "./ViewRegistryPage";
import ProgramFacilitiesRegistered from "../../Admin/ProgramFacilitiesRegistered/ProgramFacilitiesRegisteredPage";
import "../../styles/styles.scss";
import "styles/toggle-switch.scss";

export const RegistryDetails = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { registryId }: any = useParams();
    const NEW_REGISTRY = "new-registry";
    const registryMangangementTab = location.state?.bT
        ? location.state?.bT
        : CONSTANTS.ACTIVE;
    const registryMangangementPage = location.state?.bP
        ? Number(location.state?.bP)
        : 1;
    const hospitalsRegistryPage = location.state?.hRP
        ? Number(location.state?.hRP)
        : null;
    const [registry, setRegistry] = useState<any>();
    const [selectedCategories, setSelectedCategories] = useState<any>([]);
    const [editMode, setEditMode] = useState<boolean>(false);
    const [breadcrumbItems, setBreadcrumbItems] = useState<any>([]);
    const returnPath: Object = registryMangangementTab
        ? { bT: registryMangangementTab, bP: registryMangangementPage }
        : {};
    const backToRegistryManagement: Object = {
        pathname: `/registry/registry-management`,
        state: {
            t: registryMangangementTab,
            p: registryMangangementPage,
        },
    };
    useEffect(() => {
        if (registryId != NEW_REGISTRY) {
            dispatch(setPageLoadingStatus({ isPageLoading: true }));
            Promise.all([
                getRegistryById(registryId),
                getRegistryHospitals(registryId, 25, 1),
            ])
                .then((registryResponse: any) => {
                    if (
                        registryResponse[0].success &&
                        registryResponse[1].success
                    ) {
                        const RegistryData = registryResponse[0].data.registry;
                        RegistryData.totalHospitalsRegistered =
                            registryResponse[1].data._pagination.totalCount;
                        setRegistry(RegistryData);
                    }
                    if (
                        registryResponse[0]?.data?.registry?.programs.length > 0
                    ) {
                        const selectedCategoriesMap: any = [];
                        registryResponse[0] &&
                            registryResponse[0].data &&
                            registryResponse[0].data.registry &&
                            registryResponse[0].data.registry.programs.map(
                                (category: any) => {
                                    selectedCategoriesMap.push({
                                        label: category.categoryName,
                                        value: category.categoryId,
                                    });
                                }
                            );
                        setSelectedCategories(selectedCategoriesMap);
                    }
                    dispatch(setPageLoadingStatus({ isPageLoading: false }));
                })
                .catch((error: any) => {
                    logError(error);
                    dispatch(setPageLoadingStatus({ isPageLoading: false }));
                });
        }
    }, [editMode]);
    useEffect(() => {
        const elementsArray = [];
        if (
            location.pathname ==
            "/registry/registry-management/registry-details/add-registry/new-registry"
        ) {
            elementsArray.push(
                {
                    label: "Registry Management",
                    returnPath: backToRegistryManagement,
                },
                {
                    label: "Add Registry",
                }
            );
            setBreadcrumbItems(elementsArray);
        } else if (registryId) {
            if (registryMangangementTab == CONSTANTS.ACTIVE) {
                elementsArray.push(
                    {
                        label: "Registry Management",
                        returnPath: backToRegistryManagement,
                    },
                    {
                        label: "Active Registries",
                        returnPath: backToRegistryManagement,
                    },
                    {
                        label: location.state.registryName,
                    }
                );
            } else if (registryMangangementTab == CONSTANTS.INACTIVE) {
                elementsArray.push(
                    {
                        label: "Registry Management",
                        returnPath: backToRegistryManagement,
                    },
                    {
                        label: "Inactive Registries",
                        returnPath: backToRegistryManagement,
                    },
                    {
                        label: location.state.registryName,
                    }
                );
            }
            setBreadcrumbItems(elementsArray);
        }
    }, []);

    return (
        <RegistryManagementDetailsWrapper className="container-fluid p-0 d-flex flex-grow-1">
            <div className="row no-gutters w-100 flex-grow-1">
                <Sidebar activeTab="registryManagement" />
                <section className="d-flex admin-main-content container">
                    <div className="row no-gutters w-100 admin-main">
                        <div className="col-12 navbar-expand-lg">
                            <BreadCrumb breadcrumbItems={breadcrumbItems} />
                            <div className="registry-mgnt-header d-sm-flex justify-content-between align-items-center mb-4">
                                <div className="pr-2">
                                    <h1 className="h4 font-500">
                                        {registryId !== NEW_REGISTRY
                                            ? "Registry Management"
                                            : "Add Registry"}
                                    </h1>
                                </div>
                            </div>
                            {registry && (
                                <RegistryBanner
                                    registryName={registry.registryName}
                                    registryCode={registry.registryCode}
                                    countryName={registry.countryName}
                                />
                            )}
                            {registryId !== NEW_REGISTRY && (
                                <div className="d-flex d-lg-none justify-content-end mt-n3">
                                    <button
                                        type="button"
                                        className="aui-navbar-toggler navbar-toggler btn btn-round btn-secondary"
                                        data-toggle="collapse"
                                        data-target="#registryNavbarManagement"
                                        aria-controls="registryNavbarManagement"
                                        aria-expanded="false"
                                        aria-label="navigation menu"
                                    >
                                        Menu
                                    </button>
                                </div>
                            )}
                            <div className="aui-p-tabs">
                                {registryId !== NEW_REGISTRY ? (
                                    <div
                                        id="registryNavbarManagement"
                                        className="collapse navbar-collapse"
                                    >
                                        <ul
                                            className="nav nav-tabs mb-4"
                                            role="tablist"
                                        >
                                            <li
                                                className="nav-item"
                                                role="presentation"
                                            >
                                                <a
                                                    className={`nav-link ${
                                                        hospitalsRegistryPage
                                                            ? ""
                                                            : "active"
                                                    }`}
                                                    data-toggle="tab"
                                                    href="#registryDetailsTab"
                                                    role="tab"
                                                >
                                                    Details
                                                </a>
                                            </li>
                                            <li
                                                className="nav-item"
                                                role="presentation"
                                            >
                                                <a
                                                    className={`nav-link ${
                                                        hospitalsRegistryPage
                                                            ? "active"
                                                            : ""
                                                    }`}
                                                    data-toggle="tab"
                                                    href="#registryHospitalsTab"
                                                    role="tab"
                                                >
                                                    Organizations Registered
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                ) : null}
                                <div className="tab-content">
                                    <div
                                        id="registryDetailsTab"
                                        className={`tab-pane py-md-0 pt-3 pb-5 ${
                                            hospitalsRegistryPage
                                                ? ""
                                                : "active"
                                        }`}
                                        role="tabpanel"
                                    >
                                        {registryId !== NEW_REGISTRY && (
                                            <div className="registry-title-border d-flex justify-content-between">
                                                <h3
                                                    className="h4 font-400"
                                                    id="registryDetailsTitle"
                                                >
                                                    Registry Details
                                                </h3>
                                                <div className="edit-btn-toggle">
                                                    <input
                                                        type="checkbox"
                                                        id="editRegistryDetails"
                                                        className="edit-btn-checkbox"
                                                        role="button"
                                                        aria-pressed={editMode}
                                                        onClick={() => {
                                                            setEditMode(
                                                                !editMode
                                                            );
                                                        }}
                                                    />
                                                    {editMode ? (
                                                        <label
                                                            htmlFor="editRegistryDetails"
                                                            className="toggle-label"
                                                        >
                                                            Cancel Edit
                                                        </label>
                                                    ) : (
                                                        <label
                                                            htmlFor="editRegistryDetails"
                                                            className="toggle-label"
                                                        >
                                                            <i
                                                                className="aha-icon-edit toggle-edit"
                                                                title="Edit Registry Details"
                                                                aria-hidden="true"
                                                            />
                                                            <span className="sr-only">
                                                                Edit
                                                            </span>
                                                        </label>
                                                    )}
                                                    {editMode ? (
                                                        <div
                                                            aria-live="polite"
                                                            className="d-block d-md-none sr-only"
                                                        >
                                                            Cancel editing
                                                            registry details
                                                        </div>
                                                    ) : (
                                                        <div
                                                            aria-live="polite"
                                                            className="d-block d-md-none sr-only"
                                                        >
                                                            Edit registry
                                                            details
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                        {registryId != NEW_REGISTRY &&
                                        editMode == false ? (
                                            <>
                                                {registry && (
                                                    <>
                                                        <ViewRegistry
                                                            setEditMode={
                                                                setEditMode
                                                            }
                                                            registryMangangementTab={
                                                                registryMangangementTab
                                                            }
                                                            registryMangangementPage={
                                                                registryMangangementPage
                                                            }
                                                            registry={registry}
                                                            profileMode={false}
                                                        />
                                                    </>
                                                )}
                                            </>
                                        ) : (
                                            <AddOrEditRegistry
                                                registry={registry}
                                                selectedCategories={
                                                    selectedCategories
                                                }
                                                backToRegistryManagement={
                                                    backToRegistryManagement
                                                }
                                                editMode={editMode}
                                                setEditMode={setEditMode}
                                            />
                                        )}
                                    </div>
                                    <div
                                        id="registryHospitalsTab"
                                        className={`tab-pane py-lg-3 ${
                                            hospitalsRegistryPage
                                                ? "active"
                                                : ""
                                        }`}
                                        role="tabpanel"
                                    >
                                        {registryId !== NEW_REGISTRY ? (
                                            <ProgramFacilitiesRegistered
                                                editMode={editMode}
                                                registryId={registryId}
                                                pageNumber={
                                                    hospitalsRegistryPage
                                                }
                                                returnPath={returnPath}
                                            />
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </RegistryManagementDetailsWrapper>
    );
};

export default RegistryDetails;
