import React from "react";
import HomePage from "components/Home/HomePage";

interface Props {}

const CorporationHomePage = () => {
    return (
        <HomePage userType="corporationPortal" className="corporation-Portal" />
    );
};

export default CorporationHomePage;
