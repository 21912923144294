import styled from "styled-components";

export const PaginationStyle = styled.div`
    .page-disabled {
        pointer-events: none;
        opacity: 0.5;
    }
    .pagination-block {
        margin-top: 36px;
    }
`;
