/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { PaymentResultWrapper } from "./styled";

interface Props {
    goToPaymentSummaryPage: any;
}

export const PaymentFailurePage = (props: Props) => {
    return (
        <PaymentResultWrapper>
            <div className="container d-flex flex-column align-items-center py-5 mb-4 text-center payment-failed-content">
                <img
                    src="/images/failure.svg"
                    alt="Failed"
                    className="mb-3 pt-5"
                />
                <h6 className="h6 font-600 mt-3 mb-3">
                    WE COULDN'T PROCESS YOUR PAYMENT.
                </h6>
                <p className="pt-2 mb-0">
                    Unfortunately, we couldn't collect payment on your purchase.
                </p>
                <p className="payment-failed-detail">
                    Please take a moment to review your billing information – if
                    it looks correct, please contact{" "}
                    <a
                        href="mailto:certification@heart.org"
                        className="font-red"
                    >
                        certification@heart.org
                    </a>{" "}
                    for assistance.
                </p>
                <button
                    onClick={props.goToPaymentSummaryPage}
                    className="btn btn-round btn-primary mt-3 mb-5"
                >
                    Retry Payment
                </button>
            </div>
        </PaymentResultWrapper>
    );
};

export default PaymentFailurePage;
