import {
    calculatNumOfDaysBetweenDates,
    expireDataRowStatus,
    hospitalApprovalStatus,
    localDateToYYYYMMDDFormatString,
    showModal,
} from "common/utils";
import Pagination, { IPagination } from "components/Pagination";
import TableRowCount from "components/TableRowCount";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import FocusTrap from "focus-trap-react";
import ExpireIndicator from "components/ExpireIndicator";
import { unmapFacilityFromCorporation } from "../../../api/corporationAPI";
import CONSTANTS from "../../../common/constants";
import { setPageLoadingStatus } from "../../../components/Loader/loader.slice";
import { showToast } from "../../../components/Toast/toast.slice";
import "../../styles/styles.scss";
import "styles/expire-tooltip.scss";
import { CorporationRegisteredFacilitiesWrapper } from "./styled";

interface Props {
    corporationFacilities: any;
    corporationName: string;
    corporationId: any;
    returnPath: any;
    pagination: IPagination;
    paginationSetLimit: number;
    setFacilityRemovedInd: any;
    facilityRemovedInd: boolean;
    roleValue: string;
}

export const CorporationRegisteredFacilities = (props: Props) => {
    const [facilityName, setFacilityName] = useState<string>("");
    const [facilityId, setFacilityId] = useState<any>();
    const dispatch = useDispatch();
    const [removeFacilityActivateInd, setRemoveFacilityActivateInd] =
        useState<boolean>(false);

    const unmapFacility = (event: any) => {
        event.preventDefault();
        dispatch(setPageLoadingStatus({ isPageLoading: true }));
        unmapFacilityFromCorporation(props.corporationId, facilityId)
            .then((data: any) => {
                dispatch(setPageLoadingStatus({ isPageLoading: false }));
                if (data.success == true) {
                    props.setFacilityRemovedInd(!props.facilityRemovedInd);
                    dispatch(
                        showToast({
                            visibility: true,
                            message: `${facilityName} removed from the Corporation Successfully.`,
                            type: "success",
                            code: "Success:",
                        })
                    );
                    removeFacilityConfirmationDismissModal();
                }
            })
            .catch(() => {
                dispatch(setPageLoadingStatus({ isPageLoading: false }));
            });
    };
    const checkIsExpierSoon = (
        expiresOn: any,
        programId: any,
        hospitalId: any,
        isActive: any
    ) => {
        if (!expiresOn || !isActive) {
            return "";
        }
        return (
            <div>
                <ExpireIndicator
                    expiresOn={expiresOn}
                    programId={programId}
                    hospitalId={hospitalId}
                />
            </div>
        );
    };

    const getHospitalStatus = (hospital: any) => {
        if (hospital?.certificateExpiresOn) {
            const days = calculatNumOfDaysBetweenDates(
                new Date(),
                hospital?.certificateExpiresOn
            );
            if (days >= 1 && days <= 50) {
                return "Expiring";
            }
            if (days === 0) {
                return "Expired";
            }
        }
        return `${hospital.approvalStatus
            .charAt(0)
            .toUpperCase()}${hospital.approvalStatus.slice(1).toLowerCase()}`;
    };

    useEffect(() => {
        if (CONSTANTS.WINDOW.AUI.Tooltip) {
            CONSTANTS.WINDOW.AUI.Tooltip.init();
        }
    });

    const removeFacilityConfirmationDismissModal = () => {
        setRemoveFacilityActivateInd(false);
        showModal();
    };
    return (
        <CorporationRegisteredFacilitiesWrapper>
            <h3 className="h4 font-500">
                Organizations Registered with {props.corporationName}
            </h3>
            <div className="corp-facility-table mt-3">
                <TableRowCount
                    isFirst={props.pagination.isFirst}
                    pageNumber={props.pagination.pageNumber}
                    pageSize={props.pagination.pageSize}
                    isLast={props.pagination.isLast}
                    totalCount={props.pagination.totalCount}
                    roleValue={props.roleValue}
                />
                <table className="aui-responsive-status-table mt-3">
                    <thead>
                        <tr>
                            <th scope="col">
                                <div className="aui-th">
                                    Organization Details
                                </div>
                            </th>
                            <th scope="col">
                                <div className="aui-th">Location Details</div>
                            </th>
                            <th scope="col">
                                <div className="aui-th">Program Type</div>
                            </th>
                            <th scope="col">
                                <div className="aui-th">Status</div>
                            </th>
                            <th scope="col">
                                <div className="aui-th justify-content-center">
                                    Action
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.corporationFacilities.length > 0 ? (
                            props.corporationFacilities.map((facility: any) => {
                                return (
                                    <tr
                                        key={`facility-${facility.hospitalId}`}
                                        className={expireDataRowStatus(
                                            facility.certificateExpiresOn,
                                            facility.isActive
                                                ? facility.approvalStatus
                                                : CONSTANTS.DEACTIVATED
                                        )}
                                    >
                                        <td data-title="Organization Details">
                                            <div className="aui-td">
                                                <div>
                                                    <Link
                                                        to={{
                                                            pathname:
                                                                facility.approvalStatus ===
                                                                    CONSTANTS.PENDING ||
                                                                !facility.isActive
                                                                    ? `/admin/organization-management/approve-organization/${facility.hospitalId}/program/${facility.programId}/category/${facility.categoryId}`
                                                                    : `/admin/organization-management/organization-details/${facility.hospitalId}/program/${facility.programId}/category/${facility.categoryId}`,
                                                            state: {
                                                                corporationId:
                                                                    props.corporationId,
                                                                corporationName:
                                                                    props.corporationName,
                                                                facilityName:
                                                                    facility.facilityName,
                                                                hRP: props
                                                                    .pagination
                                                                    .pageNumber,
                                                                ...props.returnPath,
                                                            },
                                                        }}
                                                        className="btn-text-link-uline"
                                                        id={`facility-${facility.hospitalId}`}
                                                    >
                                                        {facility.facilityName}
                                                    </Link>
                                                    <div className="mt-1">
                                                        Organization Code :{" "}
                                                        {facility.facilityCode}
                                                    </div>
                                                    {checkIsExpierSoon(
                                                        facility.certificateExpiresOn,
                                                        facility.programId,
                                                        facility.hospitalId,
                                                        facility.isActive
                                                    )}
                                                </div>
                                            </div>
                                        </td>
                                        <td data-title="Location Details">
                                            <div className="aui-td">
                                                <div className="corp-loc-detail">
                                                    <div>
                                                        <span>Location:</span>
                                                        {facility.countryName}
                                                    </div>
                                                    <div className="mt-1">
                                                        <span>State:</span>
                                                        {facility.state}
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td data-title="Program Type">
                                            <div className="aui-td">
                                                {facility.programName}
                                            </div>
                                        </td>
                                        <td data-title="Status">
                                            <div className="aui-td">
                                                {facility.isActive
                                                    ? getHospitalStatus(
                                                          facility
                                                      )
                                                    : "Deactivated"}
                                            </div>
                                        </td>
                                        <td data-title="Action">
                                            <div className="aui-td justify-content-center">
                                                <button
                                                    className="btn-text-link-uline text-nowrap"
                                                    aria-describedby={`facility-${facility.hospitalId}`}
                                                    onClick={() => {
                                                        setFacilityName(
                                                            facility.facilityName
                                                        );
                                                        setFacilityId(
                                                            facility.hospitalId
                                                        );
                                                        setRemoveFacilityActivateInd(
                                                            true
                                                        );
                                                        showModal();
                                                    }}
                                                >
                                                    Remove
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr className="aui-status-table-emptystate">
                                <td colSpan={5} className="text-center">
                                    <div className="aui-td">
                                        No data to display
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            {/* Remove Facility Confirmation Modal */}
            {removeFacilityActivateInd ? (
                <FocusTrap
                    focusTrapOptions={{
                        escapeDeactivates: false,
                        clickOutsideDeactivates: false,
                    }}
                >
                    <div
                        className="modal fade show aui-modal unmap-modal"
                        id="removeFacilityConfirmationModal"
                        tabIndex={-1}
                        aria-labelledby="removeFacLabel"
                        aria-modal="true"
                        role="dialog"
                        style={{
                            display: removeFacilityActivateInd
                                ? "block"
                                : "none",
                        }}
                    >
                        <div className="modal-dialog modal-dialog-centered modal-sm">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h2 className="h8" id="uploadDocModalLabel">
                                        Remove Organization
                                    </h2>
                                    <button
                                        id="closeUploadModal"
                                        type="button"
                                        className="close"
                                        onClick={
                                            removeFacilityConfirmationDismissModal
                                        }
                                        aria-label="Close remove organization modal"
                                    >
                                        <span
                                            aria-hidden="true"
                                            className="aha-icon-cross"
                                        />
                                    </button>
                                </div>
                                <div className="modal-body py-0">
                                    <div className="text-center">
                                        <span
                                            id="removeFacLabel"
                                            className="sr-only"
                                        >
                                            Confirm Remove Organization from
                                            corporation
                                        </span>
                                        <p className="pb-2">
                                            Are you sure you want to remove{" "}
                                            <span className="font-red">
                                                {facilityName}{" "}
                                            </span>
                                            from the Corporation?
                                        </p>
                                        <div className="my-4 d-flex justify-content-center">
                                            <button
                                                className="btn btn-secondary btn-round btn-sm mx-2"
                                                onClick={
                                                    removeFacilityConfirmationDismissModal
                                                }
                                                aria-label="Cancel removal and close the modal"
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                className="btn btn-primary btn-round btn-sm mx-2"
                                                aria-label="Confirm Removing this Organization"
                                                onClick={unmapFacility}
                                            >
                                                Confirm
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </FocusTrap>
            ) : null}
            {props.pagination && Number(props.pagination.totalPages) > 0 ? (
                <Pagination
                    pageNumber={props.pagination.pageNumber}
                    pageSize={props.pagination.pageSize}
                    totalCount={props.pagination.totalCount}
                    totalPages={props.pagination.totalPages}
                    isFirst={props.pagination.isFirst}
                    isLast={props.pagination.isLast}
                    setPageNumber={props.pagination.setPageNumber}
                    paginationSetLimit={props.paginationSetLimit}
                />
            ) : null}
        </CorporationRegisteredFacilitiesWrapper>
    );
};

export default CorporationRegisteredFacilities;
