import React from "react";
import { DashboardEmptyStateWrapper } from "./styled";

export const DashboardEmptyState = () => {
    return (
        <DashboardEmptyStateWrapper className="dashboard-placeholder d-flex justify-content-center flex-column align-items-center">
            <img
                src="/images/dashboard-image.png"
                alt="no data for q c t or e m s"
            />
            <p className="pt-4">No available data</p>
        </DashboardEmptyStateWrapper>
    );
};

export default DashboardEmptyState;
