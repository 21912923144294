import React, { useState, useRef, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import CONSTANTS from "common/constants";
import {
    localDateToYYYYMMDDFormatString,
    redirectionForErrorCode,
} from "common/utils";
import UploadDocumentContent, {
    IUploadDocForm,
} from "./UploadDocumentModalContent";
import DocumentDetails from "./DocumentDetails";
import store from "../../app/store";
import { setPageLoadingStatus } from "../Loader/loader.slice";
import DocumentServiceAPI from "../../api/documentsAPI";
import { showToast } from "../Toast/toast.slice";

const documentServiceAPI = new DocumentServiceAPI();

interface Props {
    documentDetails: any;
    setDocumentDetails: any;
    selectedProgram: string;
    uploadDocForm: IUploadDocForm;
    setUploadDocForm: any;
    initUploadDocForm: IUploadDocForm;
    uploadProgram: any;
    standards: any;
    setRefreshTableDateNow: any;
    viewDocument: any;
    document: any;
}

const DocumentView = ({
    documentDetails,
    setDocumentDetails,
    selectedProgram,
    uploadDocForm,
    setUploadDocForm,
    initUploadDocForm,
    uploadProgram,
    standards,
    setRefreshTableDateNow,
    viewDocument,
    document,
}: Props) => {
    const params: any = useParams();
    const hospitalId: any = params.hospitalId
        ? params.hospitalId
        : localStorage.getItem("selectedHospitalId");

    const [isResubmit, setIsResubmit] = useState<boolean>(false);
    const [deleteInd, setDeleteInd] = useState<boolean>(false);
    const [deleteDoc, setDeleteDoc] = useState<any>();
    const [dataDismissInd, setDataDismissInd] = useState<boolean>(true);

    const closeDocumentModal = useRef<HTMLButtonElement>(null);
    const history = useHistory();

    const resubmitDocument = (documentDetail: any) => {
        setIsResubmit(true);
        setUploadDocForm({
            ...uploadDocForm,
            title: documentDetail.documentTitle,
            uploadStandard: `${documentDetail.standardId}###${documentDetail.standardName}`,
            uploadDescription: documentDetail.documentDescription,
            referenceId: documentDetail.referenceId,
        });
    };

    const deleteDocument = () => {
        store.dispatch(setPageLoadingStatus({ isPageLoading: true }));
        documentServiceAPI
            .delete(deleteDoc.id, hospitalId, true)
            .then((deleteResponse: any) => {
                store.dispatch(setPageLoadingStatus({ isPageLoading: false }));
                if (deleteResponse.success) {
                    const toast = {
                        message: `${deleteDoc.title} has been deleted.`,
                        code: "Success:",
                        type: "success",
                    };
                    setDeleteInd(false);
                    store.dispatch(showToast(toast));
                    if (dataDismissInd) {
                        setRefreshTableDateNow(Date.now());
                    } else {
                        viewDocument(document.referenceId, document.programId);
                    }
                    setDataDismissInd(true);
                }
            })
            .catch((err: any) => {
                redirectionForErrorCode(
                    CONSTANTS.ROUTES.CORPORATION_DASHBOARD,
                    err,
                    history,
                    2000
                );
                store.dispatch(setPageLoadingStatus({ isPageLoading: false }));
                setDeleteInd(false);
                if (dataDismissInd) {
                    setRefreshTableDateNow(Date.now());
                } else {
                    viewDocument(document.referenceId, document.programId);
                }
                setDataDismissInd(true);
            });
    };

    return (
        <>
            {/* View uploaded data modal - START */}
            <div
                className={`modal fade show aui-modal ${
                    !deleteInd ? "uploadeddata-modal" : ""
                }`}
                id="viewUploadedData"
                tabIndex={-1}
                aria-labelledby="viewUploadedLabel"
                aria-modal="true"
                role="dialog"
                data-backdrop="static"
                data-keyboard="false"
            >
                <div
                    className={
                        !deleteInd
                            ? "modal-dialog modal-dialog-centered modal-lg"
                            : "visuallyhidden"
                    }
                >
                    <div
                        className={
                            isResubmit === false
                                ? "modal-content"
                                : "visuallyhidden"
                        }
                    >
                        <div className="modal-header">
                            <h2 className="h4" id="viewUploadedLabel">
                                Document Uploaded
                            </h2>
                            <button
                                id="closeDocumentModal"
                                ref={closeDocumentModal}
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close Document uploaded modal"
                                onClick={() => {
                                    setDocumentDetails([]);
                                    setRefreshTableDateNow(Date.now());
                                }}
                            >
                                <span
                                    aria-hidden="true"
                                    className="aha-icon-cross"
                                />
                            </button>
                        </div>
                        <div className="modal-body p-0">
                            {documentDetails !== undefined &&
                                documentDetails.length > 0 && (
                                    <DocumentDetails
                                        isEUP
                                        documentDetails={documentDetails}
                                        selectedProgram={selectedProgram}
                                        resubmitDocument={resubmitDocument}
                                        setDeleteInd={setDeleteInd}
                                        setDeleteDoc={setDeleteDoc}
                                        setDataDismissInd={setDataDismissInd}
                                    />
                                )}
                        </div>
                    </div>
                    {isResubmit && (
                        <UploadDocumentContent
                            uploadProgram={uploadProgram}
                            standards={standards}
                            setRefreshTableDateNow={setRefreshTableDateNow}
                            initUploadDocForm={initUploadDocForm}
                            uploadDocForm={uploadDocForm}
                            setUploadDocForm={setUploadDocForm}
                            setIsResubmit={setIsResubmit}
                            isResubmit={isResubmit}
                            closeDocumentModal={closeDocumentModal}
                            isEUP
                        />
                    )}
                </div>
                {deleteInd && (
                    <div className="modal-dialog modal-dialog-centered modal-sm">
                        <div className="modal-content">
                            <div className="modal-body p-0">
                                <div className="text-center">
                                    <p id="modalDeleteLabel">
                                        Are you sure you want to delete
                                        {` ${deleteDoc.title}`} submitted on{" "}
                                        {localDateToYYYYMMDDFormatString(
                                            deleteDoc.createdAt
                                        )}
                                        ?
                                    </p>
                                    <div className="mt-4 d-flex justify-content-center">
                                        <button
                                            className="btn btn-secondary btn-round btn-sm mx-2"
                                            data-dismiss={
                                                deleteInd ? "" : "modal"
                                            }
                                            onClick={() => {
                                                setDeleteInd(false);
                                                setDataDismissInd(true);
                                            }}
                                        >
                                            No
                                        </button>
                                        <button
                                            className="btn btn-primary btn-round btn-sm mx-2"
                                            data-dismiss={
                                                !dataDismissInd ? "" : "modal"
                                            }
                                            onClick={deleteDocument}
                                        >
                                            Yes
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            {/* View uploaded data modal - END */}
        </>
    );
};

export default DocumentView;
