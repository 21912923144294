import styled from "styled-components";

export const CorporationSettingPageWrapper = styled.div`
    .aui-sidenav-plane {
        flex-basis: 250px;
        flex-shrink: 0;
    }
    .setting-container {
        @media only screen and (min-width: 992px) {
            flex-grow: 1;
            padding: 10px 0 0 42px;
        }
    }
`;
