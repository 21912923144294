import {
    APIResponse,
    getNavigationApi,
    userApi,
    userPatchApi,
} from "api/usersApi";
import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import store from "app/store";
import { showToast } from "components/Toast/toast.slice";
import { useDispatch, useSelector } from "react-redux";
import { logError } from "utils/logger.utils";
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import { InactiveUserWrapper } from "./styled";

const InactiveUser = () => {
    const history = useHistory();
    const location = useLocation();
    const userState = useSelector((state: any) => {
        return state.user;
    });
    const dispatch = useDispatch();
    const throwErrorMessage = (message: string) => {
        const toast = {
            message,
        };
        store.dispatch(showToast(toast));
    };

    useEffect(() => {
        getNavigation();
    }, []);

    const getNavigation = async (toastInd = false) => {
        dispatch(setPageLoadingStatus({ isPageLoading: true }));
        await userApi(userState)
            .then((response: APIResponse) => {
                if (
                    response.statusCode === 200 ||
                    response.statusCode === 201
                ) {
                    userPatchApi(userState?.ssoUser).then(
                        (updateUserResponse: APIResponse) => {
                            if (updateUserResponse.statusCode != 200) {
                                history.push("/");
                            }
                        }
                    );
                }
            })
            .catch((error: any) => {
                logError("GET user API Error:", error);
                dispatch(setPageLoadingStatus({ isPageLoading: false }));
            });
        getNavigationApi().then(async (response: any) => {
            if (response.statusCode == 200) {
                const { entity } = response.data.url;
                const { path } = response.data.url;
                if (entity === "registry") {
                    switch (path) {
                        case "registry-dashboard":
                            dispatch(
                                setPageLoadingStatus({
                                    isPageLoading: false,
                                })
                            );
                            history.push("/registry/dashboard");
                            break;
                        default:
                            if (toastInd) {
                                throwErrorMessage(
                                    `Sorry, your account is still inactive. Please contact your Administrator.`
                                );
                            }
                            dispatch(
                                setPageLoadingStatus({
                                    isPageLoading: false,
                                })
                            );
                            history.push("/registry/inactive");
                    }
                }
                if (entity === "corporation") {
                    switch (path) {
                        case "corporation-dashboard":
                            dispatch(
                                setPageLoadingStatus({
                                    isPageLoading: false,
                                })
                            );
                            history.push("/corporation/dashboard");
                            break;
                        default:
                            if (toastInd) {
                                throwErrorMessage(
                                    `Sorry, your account is still inactive. Please contact your Administrator.`
                                );
                            }
                            dispatch(
                                setPageLoadingStatus({
                                    isPageLoading: false,
                                })
                            );
                            history.push("/corporation/inactive");
                    }
                } else if (entity === "null" && path === "null") {
                    dispatch(setPageLoadingStatus({ isPageLoading: false }));
                    if (toastInd) {
                        history.push("/userDashboard", {
                            pageNum: location?.state?.pageNum,
                            search: location?.state?.search,
                        });
                    }
                }
            }
        });
    };

    return (
        <InactiveUserWrapper className="container">
            <div className="inactive-container d-flex flex-column justify-content-center align-items-center">
                <img
                    src="/images/inactive-state.svg"
                    alt="q c t or e m s user inactive"
                />
                <p>
                    <strong>Sorry,</strong> your account is
                    <strong>&nbsp;inactive,&nbsp;</strong>
                    <span className="d-none d-sm-inline">
                        <br />
                    </span>
                    Please contact your<strong>&nbsp;Administrator</strong>
                </p>
                <button
                    className="btn btn-primary btn-round mt-4"
                    onClick={() => {
                        getNavigation(true);
                    }}
                >
                    Go to Dashboard
                </button>
            </div>
        </InactiveUserWrapper>
    );
};

export default InactiveUser;
