import styled from "styled-components";

export const ToastWrapper = styled.div`
    position: fixed;
    left: 50%;
    top: 0;
    transform: translate(-50%, 0);
    z-index: 11000;
    .alert {
        box-shadow: 0 0 1.125rem #00000029;
    }
    .alert-success {
        border: 1px solid #078643;
    }
    .alert-danger {
        border: 1px solid #c10e21;
    }
    .alert-warning {
        border: 1px solid #8e6d00;
    }
`;
