import styled from "styled-components";

export const HospitalCardWrapper = styled.div`
    .dashboard-select-dropdown {
        width: 100%;
        @media only screen and (min-width: 768px) {
            width: 154px;
        }
    }
    .total-hospital-card {
        color: #fff;
        i {
            font-size: 40px;
        }
    }
    .bg-registered {
        background-color: #01769d;
    }
    .bg-certified {
        background-color: #295bc7;
    }
    .bg-awards {
        background-color: #be2919;
    }
    .total-hospital-title {
        font-size: 18px;
    }
    .total-hospital-count {
        font-size: 29px;
        @media only screen and (min-width: 768px) {
            margin-left: 16px;
        }
        @media only screen and (min-width: 1200px) {
            margin-left: 0;
        }
    }
`;

export const RegisteredHospitalsWrapper = styled.div`
    .hospital-reg-table {
        thead {
            @media only screen and (min-width: 992px) {
                th:nth-child(1) {
                    width: 13%;
                }
                th:nth-child(2) {
                    width: 17%;
                }
                th:nth-child(3) {
                    width: 19%;
                }
                th:nth-child(4) {
                    width: 15%;
                }
                th:nth-child(5) {
                    width: 18%;
                }
                th:nth-child(6) {
                    width: 18%;
                }
            }
            @media only screen and (min-width: 1200px) {
                th:nth-child(1) {
                    width: 13%;
                }
                th:nth-child(2) {
                    width: 18%;
                }
                th:nth-child(3) {
                    width: 21%;
                }
                th:nth-child(4) {
                    width: 15%;
                }
                th:nth-child(5) {
                    width: 18%;
                }
                th:nth-child(6) {
                    width: 15%;
                }
            }
        }
        a {
            color: #c10e21;
        }
    }
`;

export const CertifiedHospitalsWrapper = styled.div`
    .hospital-cer-table {
        thead {
            @media only screen and (min-width: 992px) {
                th:nth-child(1) {
                    width: 13%;
                }
                th:nth-child(2) {
                    width: 15%;
                }
                th:nth-child(3) {
                    width: 15%;
                }
                th:nth-child(4) {
                    width: 14%;
                }
                th:nth-child(5) {
                    width: 14%;
                }
                th:nth-child(6) {
                    width: 14%;
                }
                th:nth-child(7) {
                    width: 15%;
                }
            }
            @media only screen and (min-width: 1200px) {
                th:nth-child(1) {
                    width: 13%;
                }
                th:nth-child(2) {
                    width: 15%;
                }
                th:nth-child(3) {
                    width: 15%;
                }
                th:nth-child(4) {
                    width: 14%;
                }
                th:nth-child(5) {
                    width: 14%;
                }
                th:nth-child(6) {
                    width: 14%;
                }
                th:nth-child(7) {
                    width: 15%;
                }
            }
        }
        a {
            color: #c10e21;
        }
    }
`;
