import styled from "styled-components";

export const BulkUploadWrapper = styled.div`
    .bulk-uploadedby {
        background-color: #f2d709;
        color: #000000;
        font-size: 12px;
        padding: 1px 4px;
        font-weight: 500;
        margin-top: 4px;
        display: inline-block;
        @media only screen and (min-width: 992px) {
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            display: -webkit-box;
            -webkit-box-orient: vertical;
        }
    }
`;
