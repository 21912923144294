import store from "app/store";
import { showToast } from "components/Toast/toast.slice";
import APIUtils from "utils/API.utils";
import config from "../config";

const API = new APIUtils();

export const getAllHospitals = async (): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const APIRes = await API.GET(
                `${config[config.env].apiEndpoints.accounts}/hospitals`
            );
            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const getHospitalByStatus = async (status: string): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const APIRes = await API.GET(
                `${
                    config[config.env].apiEndpoints.accounts
                }/hospitals?approvalStatus=${status}`
            );
            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const getHospitalById = async (id: string | number): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const APIRes = await API.GET(
                `${config[config.env].apiEndpoints.accounts}/hospitals/${id}`
            );

            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const createHospital = async (hospitalObj: any): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const reqBody = hospitalObj;

            const APIRes = await API.POST(
                `${config[config.env].apiEndpoints.accounts}/hospitals`,
                reqBody
            );

            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const updateHospital = async (
    hospitalId: string,
    hospitalObj: any
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            delete hospitalObj.hospitalId;
            delete hospitalObj.createdAt;
            delete hospitalObj.updatedAt;
            delete hospitalObj.createdBy;
            delete hospitalObj.updatedBy;
            delete hospitalObj.facilityCode;
            delete hospitalObj.isActive;
            delete hospitalObj.isDeleted;
            delete hospitalObj.approvalStatus;
            delete hospitalObj.approvedDate;
            delete hospitalObj.rejectedDate;
            delete hospitalObj.reviewedBy;
            delete hospitalObj.initialApprovedDate;
            delete hospitalObj.isReactivated;
            delete hospitalObj.lastArchivedAt;
            "countryName" in hospitalObj && delete hospitalObj.countryName;
            "countryId" in hospitalObj && delete hospitalObj.countryId;
            "countryCode" in hospitalObj && delete hospitalObj.countryCode; // user is not allowed to edit any country related data in PATCH

            const reqBody = hospitalObj;

            const APIRes = await API.PATCH(
                `${
                    config[config.env].apiEndpoints.accounts
                }/hospitals/${hospitalId}`,
                reqBody
            );

            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};
