// import 'polyfills'
import "whatwg-fetch";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./app/store";
import App from "./app/App";
import config from "./config";

function render() {
    ReactDOM.render(
        <Provider store={store}>
            <App />
        </Provider>,
        document.getElementById("root")
    );
}

render();

if (process.env.NODE_ENV === "development" && module.hot) {
    module.hot.accept("./app/App", render);
}

if (config.env === "prod") {
    const prodHeadScript = document.createElement("script");
    prodHeadScript.type = "text/javascript";
    prodHeadScript.innerText = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-PHG4QLX');`;
    const prodBodyScript = document.createElement("noscript");
    const prodBodyIframe = document.createElement("iframe");
    prodBodyIframe.src =
        "https://www.googletagmanager.com/ns.html?id=GTM-PHG4QLX";
    prodBodyIframe.height = "0";
    prodBodyIframe.width = "0";
    prodBodyIframe.setAttribute("style", "display: none; visibility: hidden;");
    prodBodyScript.appendChild(prodBodyIframe);

    document.getElementsByTagName("head")[0].appendChild(prodHeadScript);
    document.getElementsByTagName("body")[0].appendChild(prodBodyScript);
} else {
    const headScript = document.createElement("script");
    headScript.type = "text/javascript";
    headScript.innerText = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-PHG4QLX');`;
    const bodyScript = document.createElement("noscript");
    const bodyIframe = document.createElement("iframe");
    bodyIframe.src = "https://www.googletagmanager.com/ns.html?id=GTM-PHG4QLX";
    bodyIframe.height = "0";
    bodyIframe.width = "0";
    bodyIframe.setAttribute("style", "display: none; visibility: hidden;");
    bodyScript.appendChild(bodyIframe);

    document.getElementsByTagName("head")[0].appendChild(headScript);
    document.getElementsByTagName("body")[0].appendChild(bodyScript);
}
