import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { SettingWrapper } from "styled/settings-styled";
import { getCorporationDetailsById } from "api/corporationAPI";
import { parseJwt } from "../../../common/utils";
import { setPageLoadingStatus } from "../../../components/Loader/loader.slice";
import ViewCorporation from "../CorporationDetails/ViewCorporatePage";
import CorporationTabs from "../CorporationMenu/CorporationMenu";
import CorporationSidebar from "./CorporationSidebar";
import { CorporationSettingPageWrapper } from "./styled";

const CorporationProfile = () => {
    const dispatch = useDispatch();
    const [corporationDetails, setCorporationDetails] = useState<any>({});
    const corporationId = parseJwt(
        window.localStorage.getItem("userAccessToken")
    ).corporation_id;
    useEffect(() => {
        dispatch(setPageLoadingStatus({ isPageLoading: true }));
        localStorage.setItem("clearFilter", "true");
        getCorporationDetailsById(corporationId)
            .then((corporationResponse: any) => {
                setCorporationDetails(
                    corporationResponse.data.corporationDetails
                );
                dispatch(setPageLoadingStatus({ isPageLoading: false }));
            })
            .catch((error: any) => {
                dispatch(setPageLoadingStatus({ isPageLoading: false }));
            });
    }, []);
    return (
        <CorporationSettingPageWrapper className="corporation-setting-page">
            <CorporationTabs activeTab="corporationProfileSettings" />
            <SettingWrapper className="container">
                <div className="setting-header">
                    <h1 className="h2 mb-3">Settings</h1>
                </div>
                <div className="d-lg-flex">
                    <CorporationSidebar activeNav="corporationSettingProfile" />
                    <div className="setting-container mb-5 pb-5">
                        <h2 className="h3 mb-5">Profile</h2>
                        <ViewCorporation
                            corporationDetails={corporationDetails}
                            corporationMangangementTab=""
                            corporationMangangementPage=""
                            profileMode
                        />
                    </div>
                </div>
            </SettingWrapper>
        </CorporationSettingPageWrapper>
    );
};

export default CorporationProfile;
