import React from "react";
import { BrowserRouter } from "react-router-dom";
import "styles/global.scss";
import Routes from "routes";

// Kept for future integration with AWS Cloudwatch RUM
/* try {
    const config: AwsRumConfig = {
        sessionSampleRate: 1,
        guestRoleArn:
            "arn:aws:iam::407978031934:role/RUM-Monitor-us-east-1-407978031934-4811806942861-Unauth",
        identityPoolId: "us-east-1:b8667ef6-3d6f-4a55-b3d0-5af6fcbe9586",
        endpoint: "https://dataplane.rum.us-east-1.amazonaws.com",
        telemetries: ["performance", "errors", "http"],
        allowCookies: true,
        enableXRay: false,
    };

    const APPLICATION_ID: string = "71914950-754d-4cdb-9581-592da01bb673";
    const APPLICATION_VERSION: string = "1.0.0";
    const APPLICATION_REGION: string = "us-east-1";

    const awsRum: AwsRum = new AwsRum(
        APPLICATION_ID,
        APPLICATION_VERSION,
        APPLICATION_REGION,
        config
    );
} catch (error) {
    // Ignore errors thrown during CloudWatch RUM web client initialization
} */

function App() {
    return (
        <BrowserRouter>
            <Routes />
        </BrowserRouter>
    );
}

export default App;
