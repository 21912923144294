import React, { useState } from "react";
import { useParams } from "react-router-dom";
import HospitalBanner from "pages/Admin/HospitalBanner/HospitalBanner";
import DocumentResultTable from "components/DocumentSubmission/DocumentResultTable";
import AdminDocumentView from "components/DocumentSubmission/AdminDocumentView";
import { getHospitalProgramDetails } from "api/hospitalProgramsAPI";
import store from "app/store";
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import DocumentServiceAPI from "api/documentsAPI";
import { DocumentSubmissionWrapper } from "../../../styled/documetSubmissionStyled";
import { logger } from "../../../utils/logger.utils";
import "styles/browser-customstyle.scss";

const documentServiceAPI = new DocumentServiceAPI();

interface Props {
    hospital: any;
    programName: string;
    documentsInd: boolean;
}
export const HospitalDocumentsTab = (props: Props) => {
    const params: any = useParams();
    // RefreshTable
    const [refreshTableDateNow, setRefreshTableDateNow] = useState<number>(0);
    // View Dcoument
    const [documentDetails, setDocumentDetails] = useState<any>([]);
    const [selectedProgram, setSelectedProgram] = useState<string>("");
    const [document, setDocument] = useState<any>({});
    const viewDocument = async (referenceId: string, programId: number) => {
        setDocument({ referenceId, programId });
        store.dispatch(setPageLoadingStatus({ isPageLoading: true }));
        Promise.all([
            documentServiceAPI.getByReferenceId(referenceId, params.hospitalId),
            getHospitalProgramDetails(programId, params.hospitalId),
        ])
            .then((responses: any) => {
                if (responses[0].success && responses[1].success) {
                    setDocumentDetails(responses[0].data.hospitalUploadDetails);
                    setSelectedProgram(responses[1].data.programCategoryName);
                }
                store.dispatch(setPageLoadingStatus({ isPageLoading: false }));
            })
            .catch((err) => {
                logger(err);
                store.dispatch(setPageLoadingStatus({ isPageLoading: false }));
            });
    };

    return (
        <DocumentSubmissionWrapper>
            <h3 className="h3 mb-4 d-lg-none">Documents</h3>
            <HospitalBanner
                hospitalName={props.hospital.facilityName}
                programName={props.programName}
                countryName={props.hospital.countryName}
            />
            <DocumentResultTable
                isEUP={false}
                viewDocument={viewDocument}
                refreshTableDateNow={refreshTableDateNow}
                setRefreshTableDateNow={setRefreshTableDateNow}
                documentsInd={props.documentsInd}
            />
            <AdminDocumentView
                documentDetails={documentDetails}
                setDocumentDetails={setDocumentDetails}
                setRefreshTableDateNow={setRefreshTableDateNow}
                selectedProgram={selectedProgram}
                facilityName={props.hospital.facilityName}
                viewDocument={viewDocument}
                document={document}
            />
        </DocumentSubmissionWrapper>
    );
};

export default HospitalDocumentsTab;
