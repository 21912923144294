import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import "../../styles/styles.scss";
import { getHospitalsForAdminManage } from "api/adminDashboardAPI";
import CONSTANTS from "common/constants";
import Pagination, { IPagination } from "components/Pagination";
import EMSAgencyTable from "components/EMSAgencyTable";
import TableRowCount from "components/TableRowCount";
import setNegativeTabIndex from "common/dom";
import { getStates, getCountries } from "api/countriesAPI";
import SearchFilter from "components/SearchFilter";
import {
    getAllProgramCategories,
    getPgmMeasuresExportExcel,
    getProgramFieldsById,
} from "api/hospitalProgramsAPI";
import {
    gobalExportFile,
    showModal,
    wordChange,
    localDateToYYYYMMDDFormatString,
    dateToYYYYMMMDDFormat,
    cstDateFormat,
    dateToYYYYMMDDFormatString,
} from "common/utils";
import FocusTrap from "focus-trap-react";
import Dropdown from "components/Dropdown";
import MultiSelectDropdown from "components/MultiSelectDropdown";
import GroupedMultiSelectDropdown from "components/GroupedMultiSelectDropdown";
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import webWorkerScript from "common/webWorker";
import { showToast } from "components/Toast/toast.slice";
import { updateLockMeasures, getMeasureLockData } from "api/emsAPI";
import store from "app/store";
import Datepicker from "components/Datepicker";
import {
    setSearchKey,
    setStateCode,
    setProgramCategoryId,
} from "../../../components/SearchFilter/searchFilter.slice";
import Sidebar from "../Sidebar/Sidebar";
import { OrganizationManagementWrapper } from "./styled";

const paginationSetLimit: number = 5;

export const OrganizationManagement = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    // REDUX variables
    const region = useSelector((state: any) => {
        return state.region;
    });
    const searchFilter = useSelector((state: any) => {
        return state.searchFilter;
    });
    // RFC STATE variables
    const [tab, setTab] = useState<string>(
        location?.state?.adminOrganizationMangemnetTab || CONSTANTS.PENDING
    );
    const [isActive, setIsActive] = useState<boolean>(tab != "DEACTIVATED");

    const [pendingHospitals, setPendingHospitals] = useState<any>([]);
    const [programCategory, setProgramCategory] = useState<any>(
        searchFilter.programCategory
    );
    const [country, setCountry] = useState<any>(searchFilter.countryCode);
    const [stateCd, setStateCd] = useState<any>(searchFilter.stateCode);
    const [registeredHospitals, setRegisteredHospitals] = useState<any>([]);
    const [sortInd, setSortInd] = useState<any>(location?.state?.pageSort);
    const [deactivatedHospitals, setDeactivatedHospitals] = useState<any>([]);
    const [pageNumber, setPageNumber] = useState<number>(
        location?.state?.adminOrganizationMangemnetPage || 1
    );
    const paginationSetLimit: number = 5;
    const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
    const [roleValue, setRoleValue] = useState<string>("none");
    const [isCountryLoading, setIsCountryLoading] = useState<boolean>(false);
    const [isStateLoading, setIsStateLoading] = useState<boolean>(false);
    const [stateDropdown, setStateDropdown] = useState<any>([]);
    const [mounted, setMounted] = useState<boolean>(false);
    const [clearFilters, setClearFilters] = useState<boolean>(false);
    const [countryDropdown, setCountryDropdown] = useState<any>([]);
    const [programCategories, setProgramCategories] = useState<any>([]);
    const exportBtnRestrict = localStorage.getItem("exportEMSBtnRestrict");
    const exportBtnRestrictStatus = exportBtnRestrict === "true";
    const [exportButtonDisabled, setExportButtonDisabled] = useState<boolean>(
        exportBtnRestrictStatus
    );
    const [lockUnlockActivateInd, setlockUnlockActivateInd] =
        useState<boolean>(false);
    const [measureLockInd, setMeasureLockInd] = useState<boolean>(() => {
        const storedMeasureLockInd = localStorage.getItem("measureLockInd");
        return storedMeasureLockInd ? JSON.parse(storedMeasureLockInd) : false;
    });
    const [measureLockDate, setMeasureLockDate] = useState<any>();
    const [lastYear, setLastYear] = useState("");
    const [presentYear, setPresentYear] = useState<any>("");
    const [modifiedLockDate, setModifiedLockDate] = useState<any>();
    const [minDateForLock, setMinDateForLock] = useState<any>();
    const [lockUpdateButtonState, setLockUpdateButtonState] =
        useState<boolean>(true);

    const [
        exportProgramMeasureActivateInd,
        setExportProgramMeasureActivateInd,
    ] = useState<boolean>(false);
    const [expOrgType, setExpOrgType] = useState<any>([
        CONSTANTS.ORG_TYPE.CERTIFIED,
        CONSTANTS.ORG_TYPE.DEACTIVATED,
        CONSTANTS.ORG_TYPE.REGISTERED,
    ]);
    const currentYear = new Date().getFullYear();
    const getYears = () => {
        const years = [];
        for (let i = 2023; i >= 2023; i--) {
            // setting 2023 because of the requirements. current year variable insert here
            years.push(i);
        }
        const yearsDropdownMap: any = [];
        if (years && years.length > 0) {
            years.forEach((year: any) => {
                yearsDropdownMap.push({ label: year, value: year });
            });
        }
        return yearsDropdownMap;
    };
    const yearsDropdown: Array<object> = getYears();
    const [expYear, setExpYear] = useState<any>(CONSTANTS.EMS_EXPORT_YEAR);
    const [isOrganizationFieldsLoading, setIsOrganizationFieldsLoading] =
        useState<boolean>(false);
    const [isProgramFieldsLoading, setIsProgramFieldsLoading] =
        useState<boolean>(false);
    const [expOrganizationFields, setExpOrganizationFields] = useState<any>([]);
    const [selectedOrganizations, setSelectedOrganizations] = useState<any>([]);
    const [expProgramFields, setExpProgramFields] = useState<any>([]);
    const [selectedPrograms, setSelectedPrograms] = useState<any>([]);
    const [exportButtonState, setExportButtonState] = useState<boolean>(false);
    const [isRequired, setIsRequired] = useState<boolean>(false);
    const [lockUpdateButtonSpinnerState, setLockUpdateButtonSpinnerState] =
        useState<boolean>(false);

    const onChangeExpOrgType = (event: any) => {
        const { value, checked } = event.target;
        checked
            ? setExpOrgType([...expOrgType, value])
            : setExpOrgType(expOrgType.filter((id: any) => id != value));
    };
    const resetModalOnClose = () => {
        setIsOrganizationFieldsLoading(false);
        setSelectedOrganizations([]);
        setSelectedPrograms([]);
        setExpOrgType([
            CONSTANTS.ORG_TYPE.REGISTERED,
            CONSTANTS.ORG_TYPE.CERTIFIED,
            CONSTANTS.ORG_TYPE.DEACTIVATED,
        ]);
        setExpYear("2023");
    };
    useEffect(() => {
        if (expOrgType.length !== 0) {
            setIsRequired(false);
        } else {
            setIsRequired(true);
        }
    }, [expOrgType]);
    const exportProgramMeasureModal = () => {
        setExportProgramMeasureActivateInd(true);
        showModal();
    };
    const exportProgramMeasureDismissModal = () => {
        setExportProgramMeasureActivateInd(false);
        resetModalOnClose();
        showModal();
    };
    const onChangeExpYear = (value: any) => {
        setExpYear(value);
    };
    function organizationFields() {
        const regFields: any = [{ label: "All", value: "All" }];
        setSelectedOrganizations([]);
        CONSTANTS.REGISTRATION.map((regField: any) => {
            regFields.push({
                label: regField.label,
                value: regField.value,
            });
        });
        setExpOrganizationFields(regFields);
        setIsOrganizationFieldsLoading(false);
    }
    useEffect(() => {
        organizationFields();
    }, []);
    useEffect(() => {
        if (!mounted) {
            getProgramFieldsById(
                CONSTANTS.EMS_PROGRAM_CATEGORY_ID,
                CONSTANTS.EMS_PROGRAM_ID
            ).then((response: any) => {
                if (response.success) {
                    const programFields: any = [{ label: "All", value: "All" }];
                    response.data.map((pgmField: any) => {
                        programFields.push({
                            label: wordChange(pgmField.title),
                            value: pgmField.key,
                        });
                    });
                    setExpProgramFields(programFields);
                }
            });
        }
        if (!clearFilters && localStorage.getItem("resetFilter") == "false") {
            getSearchedFacilities(searchFilter.searchKey, false, !mounted);
        }
    }, [sortInd, tab]);

    useEffect(() => {
        if (CONSTANTS.WINDOW.AUI.Popover) {
            CONSTANTS.WINDOW.AUI.Popover.init();
        }
    });
    const isSafariBrowser = () => {
        if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
            return true;
        }
        return false;
    };
    const eventHandler = (e: any) => {
        e.preventDefault();
    };

    const handleExport = (URL: any) => {
        const worker = new Worker(webWorkerScript);
        const WAIT_TIME = CONSTANTS.GLOBAL_EXPORTS_WAIT_TIME;
        setExportButtonDisabled(true);
        worker.addEventListener("message", (e: any) => {
            const type = e?.data?.type;
            const status = e?.data?.status;
            if (type === "apiResponse") {
                if (status === CONSTANTS.EXPORT_RESPONSE.IN_PROGRESS) {
                    localStorage.setItem("exportBtnRestrict", "true");
                    worker.postMessage({
                        type: "start",
                        authToken: localStorage.getItem("userAccessToken"),
                        URL,
                        time: WAIT_TIME,
                    });
                } else if (status === CONSTANTS.EXPORT_RESPONSE.SUCCESS) {
                    localStorage.setItem("exportBtnRestrict", "false");
                    setExportButtonDisabled(false);
                    gobalExportFile(
                        e?.data?.presignedUrl,
                        CONSTANTS.EMS_PROGRAM_NAME
                    );
                    const toast = {
                        message:
                            "Organization dashboard successfully exported as Excel.",
                        code: "Success:",
                        type: "success",
                    };
                    dispatch(showToast(toast));
                } else if (status === CONSTANTS.EXPORT_RESPONSE.FAILED) {
                    localStorage.setItem("exportBtnRestrict", "false");
                    setExportButtonDisabled(false);
                    const alertPopup = document.getElementById("alertPopup");
                    alertPopup?.classList.remove("popupHide");
                }
            }
        });
        const toast = {
            message:
                "Organization measure export is in progress. Please don't refresh this page.",
            code: "Success:",
            type: "warning",
        };
        dispatch(showToast(toast));
        worker.postMessage({
            type: "start",
            authToken: localStorage.getItem("userAccessToken"),
            URL,
            time: WAIT_TIME,
        });
    };
    const handleExportToExcelEvent = (e: any) => {
        setExportButtonState(true);
        let finalOrganizations: any = [];
        let finalPprograms: any = [];

        finalOrganizations = selectedOrganizations
            .filter((itm: any) => itm.value != "All")
            .map((val: any) => val.value);
        finalPprograms = selectedPrograms
            .filter((itm: any) => itm.value != "All")
            .map((val: any) => val.value);
        e.preventDefault();
        const exportPayload: any = {
            year: `${expYear}`,
            type: expOrgType,
            registrationFields: finalOrganizations,
            programFields: finalPprograms,
            benchmarks: "false",
            programCategoryName: CONSTANTS.EMS_PROGRAM_NAME,
            Interval: [CONSTANTS.ANNUAL, CONSTANTS.QUARTERLY],
        };
        const payload = JSON.parse(JSON.stringify(exportPayload));
        const expProgram = {
            programId: CONSTANTS.EMS_PROGRAM_ID,
            categoryId: CONSTANTS.EMS_PROGRAM_CATEGORY_ID,
        };
        getPgmMeasuresExportExcel(expProgram, payload)
            .then((response) => {
                setExportButtonState(false);
                exportProgramMeasureDismissModal();
                const URL = `/admin/adminFacilityExports/${response.data.exportId}`;
                handleExport(URL);
            })
            .catch(() => {
                setExportButtonState(false);
            });
    };
    const defaultPagination: IPagination = {
        pageNumber: 1,
        pageSize: 25,
        isFirst: true,
        isLast: false,
        setPageNumber,
        paginationSetLimit,
    };
    const [certifiedHospitals, setCertifiedHospitals] = useState<any>([]);
    const [pagination, setPagination] =
        useState<IPagination>(defaultPagination);
    const getHospitalData = (mountedInd: any) => {
        getHospitalsForAdminManage({
            status: tab,
            pageNumber,
            region: CONSTANTS.DOMESTIC,
            pageSize: pagination.pageSize,
            isActive,
            categoryId: CONSTANTS.EMS_PROGRAM_CATEGORY_ID,
            country,
            sortInd,
            stateCd,
            searchKey: searchFilter.searchKey,
        }).then((response: any) => {
            if (mountedInd && response.success && response.statusCode === 200) {
                if (tab === CONSTANTS.PENDING) {
                    setNegativeTabIndex(
                        [
                            "certified-tab",
                            "registered-tab",
                            "deactivated-tab",
                            "pending-tab",
                        ],
                        "pending-tab"
                    );
                    setPendingHospitals(response.data.hospitalPrograms);
                } else if (tab === CONSTANTS.REGISTRED) {
                    setNegativeTabIndex(
                        [
                            "certified-tab",
                            "registered-tab",
                            "deactivated-tab",
                            "pending-tab",
                        ],
                        "registered-tab"
                    );
                    setRegisteredHospitals(response.data.hospitalPrograms);
                } else if (tab === CONSTANTS.CERTIFIED) {
                    setNegativeTabIndex(
                        [
                            "certified-tab",
                            "registered-tab",
                            "deactivated-tab",
                            "pending-tab",
                        ],
                        "certified-tab"
                    );
                    setCertifiedHospitals(response.data.hospitalPrograms);
                } else {
                    setNegativeTabIndex(
                        [
                            "certified-tab",
                            "registered-tab",
                            "deactivated-tab",
                            "pending-tab",
                        ],
                        "deactivated-tab"
                    );
                    setDeactivatedHospitals(response.data.hospitalPrograms);
                }
                setPagination({
                    ...response.data._pagination,
                    setPageNumber,
                });
            }
            setIsTableLoading(false);
            setRoleValue("alert");
        });
    };
    useEffect(() => {
        let mountedInd = true;
        setIsTableLoading(true);
        if (mountedInd) {
            getHospitalData(mountedInd);
        }
        window.scrollTo(0, 0);
        return function cleanup() {
            mountedInd = false;
        };
    }, [pageNumber, tab]);
    useEffect(() => {
        const states = [{ label: "All", value: CONSTANTS.ALL }];
        getStates("US").then((response) => {
            if (response?.data) {
                response.data.map((item: any) => {
                    states.push({
                        label: item.name,
                        value: item.name,
                    });
                });
                setStateDropdown(states);
                setIsStateLoading(false);
            }
        });
        window.onbeforeunload = function () {
            history.replace();
            return undefined;
        };
        return () => {
            window.onbeforeunload = null;
        };
    }, []);

    const close = () => {
        history.push("/emsadmin/organization-management");
    };
    const getSearchedFacilities = (
        value: string,
        clearFilterInd?: boolean,
        retainPageInd?: boolean
    ) => {
        setIsTableLoading(true);
        if (!retainPageInd) {
            setPageNumber(1);
            setPagination(defaultPagination);
        }
        getHospitalsForAdminManage({
            status: tab,
            pageNumber: retainPageInd ? pageNumber : 1,
            region: CONSTANTS.DOMESTIC,
            pageSize: pagination.pageSize,
            isActive,
            categoryId: CONSTANTS.EMS_PROGRAM_CATEGORY_ID,
            sortInd,
            stateCd: clearFilterInd ? CONSTANTS.ALL : stateCd,
            searchKey: value,
        }).then((response: any) => {
            if (response.success && response.statusCode === 200) {
                if (tab === CONSTANTS.PENDING) {
                    setNegativeTabIndex(
                        [
                            "certified-tab",
                            "registered-tab",
                            "deactivated-tab",
                            "pending-tab",
                        ],
                        "pending-tab"
                    );
                    setPendingHospitals(response.data.hospitalPrograms);
                } else if (tab === CONSTANTS.REGISTRED) {
                    setNegativeTabIndex(
                        [
                            "certified-tab",
                            "registered-tab",
                            "deactivated-tab",
                            "pending-tab",
                        ],
                        "registered-tab"
                    );
                    setRegisteredHospitals(response.data.hospitalPrograms);
                } else if (tab === CONSTANTS.CERTIFIED) {
                    setNegativeTabIndex(
                        [
                            "certified-tab",
                            "registered-tab",
                            "deactivated-tab",
                            "pending-tab",
                        ],
                        "certified-tab"
                    );
                    setCertifiedHospitals(response.data.hospitalPrograms);
                } else {
                    setNegativeTabIndex(
                        [
                            "certified-tab",
                            "registered-tab",
                            "deactivated-tab",
                            "pending-tab",
                        ],
                        "deactivated-tab"
                    );
                    setDeactivatedHospitals(response.data.hospitalPrograms);
                }
                setPagination({
                    ...response.data._pagination,
                    setPageNumber,
                });
            }
            setIsTableLoading(false);
            setClearFilters(false);
            if (mounted) {
                setRoleValue("alert");
            }
        });
    };
    const onkeyDownCallback = (event: any, name: string, total: number) => {
        let nextTarget;
        if (event.keyCode == 39) {
            // right arrow
            const activeEle: any = document.activeElement;
            if (parseInt(activeEle?.name?.split(name)[1]) == total) {
                nextTarget = 1;
            } else {
                nextTarget = parseInt(activeEle?.name?.split(name)[1]) + 1;
            }
            const ele: any = document.querySelectorAll(
                `button[name="tab_${nextTarget}"]`
            )[0];
            ele.focus();
        } else if (event.keyCode == 37) {
            // left arrow
            const activeEle: any = document.activeElement;
            if (parseInt(activeEle?.name?.split(name)[1]) == 1) {
                nextTarget = 4;
            } else {
                nextTarget = parseInt(activeEle?.name?.split(name)[1]) - 1;
            }
            const ele: any = document.querySelectorAll(
                `button[name="tab_${nextTarget}"]`
            )[0];
            ele.focus();
        }
    };
    const setDefaultPagination = () => {
        setPageNumber(1);
        setPagination(defaultPagination);
    };
    const getFacilitiesResult = ({ stateCode, search, categoryId }: any) => {
        setIsTableLoading(true);
        setPagination(defaultPagination);
        getHospitalsForAdminManage({
            status: tab,
            pageNumber: 1,
            region: CONSTANTS.DOMESTIC,
            categoryId: CONSTANTS.EMS_PROGRAM_CATEGORY_ID,
            pageSize: pagination.pageSize,
            isActive,
            sortInd,
            stateCd: stateCode || stateCd,
            searchKey: search !== undefined ? search : searchFilter.searchKey,
        }).then((response: any) => {
            if (response.success && response.statusCode === 200) {
                switch (tab) {
                    case CONSTANTS.PENDING:
                        setNegativeTabIndex(
                            [
                                "certified-tab",
                                "registered-tab",
                                "deactivated-tab",
                                "pending-tab",
                            ],
                            "pending-tab"
                        );
                        setPendingHospitals(response.data.hospitalPrograms);
                        break;
                    case CONSTANTS.REGISTRED:
                        setNegativeTabIndex(
                            [
                                "certified-tab",
                                "registered-tab",
                                "deactivated-tab",
                                "pending-tab",
                            ],
                            "registered-tab"
                        );
                        setRegisteredHospitals(response.data.hospitalPrograms);
                        break;
                    case CONSTANTS.CERTIFIED:
                        setNegativeTabIndex(
                            [
                                "certified-tab",
                                "registered-tab",
                                "deactivated-tab",
                                "pending-tab",
                            ],
                            "certified-tab"
                        );
                        setCertifiedHospitals(response.data.hospitalPrograms);
                        break;
                    default:
                        setNegativeTabIndex(
                            [
                                "certified-tab",
                                "registered-tab",
                                "deactivated-tab",
                                "pending-tab",
                            ],
                            "deactivated-tab"
                        );
                        setDeactivatedHospitals(response.data.hospitalPrograms);
                        break;
                }
                setPagination({
                    ...response.data._pagination,
                    setPageNumber,
                });
            }
            setIsTableLoading(false);
            setClearFilters(false);
            if (mounted) {
                setRoleValue("alert");
            }
        });
    };

    useEffect(() => {
        if (!mounted) {
            if (localStorage.getItem("resetFilter") == "true") {
                dispatch(
                    setProgramCategoryId(CONSTANTS.EMS_PROGRAM_CATEGORY_ID)
                );
                dispatch(setSearchKey(""));
                dispatch(setStateCode(CONSTANTS.ALL));
                getSearchedFacilities("", true);
            }
        }

        if (!clearFilters && localStorage.getItem("resetFilter") == "false") {
            getSearchedFacilities(searchFilter.searchKey, false, !mounted);
        }
    }, [sortInd, tab]);

    useEffect(() => {
        const currentDate = new Date();
        const cstTimeZone = "America/Chicago";
        const currentCstDate = currentDate.toLocaleDateString("en-US", {
            timeZone: cstTimeZone,
        });
        const Year = new Date(currentCstDate).getFullYear();
        setPresentYear(Year);
        setLastYear((Year - 1).toString());
        const startDate = `${Year - 1}-01-01`;
        const endDate = `${Year - 1}-12-31`;
        const formattedCstDate = new Date(currentCstDate);
        const month = (formattedCstDate.getMonth() + 1)
            .toString()
            .padStart(2, "0");
        const minDate = `${formattedCstDate.getFullYear()}-${month}-${formattedCstDate.getDate()}`;
        setMinDateForLock(minDate);
        getMeasureLockData(startDate, endDate).then((response: any) => {
            if (response.data) {
                const res = response.data;
                const lockDate = res?.measureLockDate
                    ? new Date(res.measureLockDate).toISOString().split("T")[0]
                    : `${Year}-12-31`;
                setMeasureLockDate(lockDate);
                if (res.isMeasureLocked == true) {
                    setMeasureLockInd(true);
                } else {
                    setMeasureLockInd(false);
                }
            }
        });
    }, []);

    useEffect(() => {
        if (clearFilters) {
            getSearchedFacilities("", true);
        }
    }, [clearFilters]);

    const lockUnlockModal = () => {
        setlockUnlockActivateInd(true);
        showModal();
    };
    const lockUnlockDismissModal = () => {
        setlockUnlockActivateInd(false);
        setLockUpdateButtonState(true);
        showModal();
    };

    useEffect(() => {
        if (CONSTANTS.WINDOW.AUI.Tooltip) {
            CONSTANTS.WINDOW.AUI.Tooltip.init();
        }
    });
    const handleLockUnlockMeasures = (event: any, lockInd: boolean) => {
        event.preventDefault();
        let updatedLockDate = modifiedLockDate;
        if (!modifiedLockDate && !lockInd) {
            updatedLockDate = `${presentYear}-12-31`;
        } else if (!modifiedLockDate && lockInd) {
            updatedLockDate = measureLockDate;
        }
        setLockUpdateButtonSpinnerState(true);
        const reqBody = { measureLock: true, lockDate: updatedLockDate };
        updateLockMeasures(reqBody)
            .then((response: any) => {
                if (response.success) {
                    setLockUpdateButtonSpinnerState(false);
                    setMeasureLockDate(updatedLockDate);
                    if (lockInd) {
                        setMeasureLockInd(false);
                    }
                    lockUnlockDismissModal();
                    const toast = {
                        message: `All organizations will be locked to enter measures after ${localDateToYYYYMMDDFormatString(
                            updatedLockDate
                        )}`,
                        code: "Success:",
                        type: "success",
                    };
                    store.dispatch(showToast(toast));
                }
            })
            .catch((error: any) => {
                setLockUpdateButtonSpinnerState(false);
                const tody = new Date();
                let yesterday: any = dateToYYYYMMDDFormatString(
                    tody.setDate(tody.getDate() - 1)
                );
                yesterday = cstDateFormat(yesterday);
                const toast = {
                    message: `Please enter a valid lock date in yyyy-mmm-dd format greater than yesterday's ${yesterday}`,
                    code: "Error:",
                };
                if (error.message === "Invalid Lock Date") {
                    dispatch(showToast(toast));
                } else {
                    dispatch(showToast(error));
                }
            });
    };

    const modifyLockDate = (date: any) => {
        try {
            setLockUpdateButtonState(false);
            setModifiedLockDate(date);
        } catch (error) {
            const tody = new Date();
            let yesterday: any = dateToYYYYMMDDFormatString(
                tody.setDate(tody.getDate() - 1)
            );
            yesterday = cstDateFormat(yesterday);
            const toast = {
                message: `Please enter a valid lock date in yyyy-mmm-dd format greater than yesterday's ${yesterday}`,
                code: "Error:",
            };
            dispatch(showToast(toast));
        }
    };
    const updateBtnInd = (state: any) => {
        setLockUpdateButtonState(state);
    };
    useEffect(() => {
        localStorage.setItem("measureLockInd", JSON.stringify(measureLockInd));
    }, [measureLockInd]);
    return (
        <OrganizationManagementWrapper className="container-fluid p-0 d-flex flex-grow-1">
            <div className="row no-gutters w-100 flex-grow-1">
                <Sidebar activeTab="organizationManagement" />

                <section className="d-flex admin-main-content container">
                    <div className="row no-gutters w-100 admin-main">
                        <div className="col-12">
                            <div className="d-md-flex justify-content-between dashboard-fac-magnt align-items-center">
                                <h1 className="h2 font-400 mb-0">
                                    Organization Management
                                </h1>
                                <div className="d-flex mt-3 mt-md-0">
                                    <div
                                        id="adminExport"
                                        className="export-admin d-flex text-nowrap mr-4"
                                    >
                                        <button
                                            className="btn btn-text font-red global-export-btn"
                                            onClick={() => {
                                                !exportButtonDisabled
                                                    ? exportProgramMeasureModal()
                                                    : "";
                                            }}
                                            aria-describedby="globalExport"
                                        >
                                            <i className="aha-icon-export export-admin-icon mr-1" />
                                            Export
                                        </button>
                                        <div
                                            className="tooltip fade show bs-tooltip-bottom global-export-tooltip"
                                            role="tooltip"
                                            id="globalExport"
                                        >
                                            <div className="arrow" />
                                            <div className="tooltip-inner">
                                                {exportButtonDisabled
                                                    ? "Please wait for the current export to complete"
                                                    : "Export Program Measures"}
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        id="lock-unlock"
                                        className="lock-unlock"
                                    >
                                        <button
                                            className="btn btn-text font-red global-lock-btn"
                                            data-toggle="modal"
                                            data-target="#selctedFacilityModal"
                                            onClick={lockUnlockModal}
                                            aria-describedby="globalLock"
                                        >
                                            {measureLockInd ? (
                                                <i className="aha-icon-locked mr-1 lock-admin-icon" />
                                            ) : (
                                                <i className="aha-icon-unlocked lock-admin-icon mr-1" />
                                            )}
                                            Measure Lock
                                        </button>
                                        <div
                                            className="tooltip fade show bs-tooltip-bottom global-lock-tooltip"
                                            role="tooltip"
                                            id="globalLock"
                                        >
                                            <div className="arrow" />
                                            <div className="tooltip-inner">
                                                {measureLockInd
                                                    ? `Measure locked on ${cstDateFormat(
                                                          measureLockDate
                                                      )}. Update if required.`
                                                    : `Locking Measures on ${cstDateFormat(
                                                          measureLockDate
                                                      )}. Update if required.`}
                                            </div>
                                        </div>
                                        {lockUnlockActivateInd && (
                                            <FocusTrap
                                                focusTrapOptions={{
                                                    escapeDeactivates: false,
                                                    clickOutsideDeactivates:
                                                        false,
                                                }}
                                            >
                                                <div
                                                    className="modal fade show aui-modal lock-unlock-modal"
                                                    id="lockUnlockModal"
                                                    tabIndex={-1}
                                                    aria-labelledby="lockUnlockLabel"
                                                    aria-modal="true"
                                                    role="dialog"
                                                    style={{
                                                        display:
                                                            lockUnlockActivateInd
                                                                ? "block"
                                                                : "none",
                                                    }}
                                                >
                                                    <div className="modal-dialog modal-dialog-centered modal-md">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h2
                                                                    className="h5"
                                                                    id="lockUnlockLabel"
                                                                >
                                                                    Restrict
                                                                    Organization
                                                                    Measures -{" "}
                                                                    {lastYear}
                                                                </h2>
                                                                <button
                                                                    id="closeExportModal"
                                                                    type="button"
                                                                    className="close"
                                                                    onClick={
                                                                        lockUnlockDismissModal
                                                                    }
                                                                    aria-label="Close Restrict Organizations Measures model"
                                                                >
                                                                    <span
                                                                        aria-hidden="true"
                                                                        className="aha-icon-cross"
                                                                    />
                                                                </button>
                                                            </div>
                                                            <div className="modal-body p-0">
                                                                {measureLockInd ? (
                                                                    <form
                                                                        onSubmit={(
                                                                            event
                                                                        ) =>
                                                                            handleLockUnlockMeasures(
                                                                                event,
                                                                                true
                                                                            )
                                                                        }
                                                                    >
                                                                        <p
                                                                            className="lock-unlock-info"
                                                                            role="note"
                                                                        >
                                                                            Update
                                                                            measure
                                                                            lock
                                                                            date
                                                                            for
                                                                            all
                                                                            organizations
                                                                        </p>
                                                                        <div className="form-group d-flex flex-column required">
                                                                            <label
                                                                                htmlFor="unlockdate"
                                                                                className="mb-2"
                                                                                id="lockdate-label"
                                                                            >
                                                                                Update
                                                                                the
                                                                                lock
                                                                                date
                                                                            </label>
                                                                            <Datepicker
                                                                                id="unlockdate"
                                                                                defaultValue={
                                                                                    measureLockDate
                                                                                }
                                                                                onChangeDate={
                                                                                    modifyLockDate
                                                                                }
                                                                                setBtnInd={
                                                                                    updateBtnInd
                                                                                }
                                                                                ariaLabel="lockdate-label"
                                                                                minMaxDate
                                                                                isCstCurrentDate
                                                                            />
                                                                            <div
                                                                                id="lockdateinfo"
                                                                                className="mt-1 font-14"
                                                                            >
                                                                                <i
                                                                                    className="aha-icon-info font-red mr-1 ml-n1"
                                                                                    aria-hidden="true"
                                                                                />
                                                                                Lock
                                                                                will
                                                                                be
                                                                                applied
                                                                                at
                                                                                23:59:59
                                                                                CST
                                                                            </div>
                                                                        </div>
                                                                        <div className="d-flex justify-content-end lock-unlock-btns">
                                                                            <button
                                                                                id="lockCancel"
                                                                                className="btn btn-secondary btn-round"
                                                                                type="button"
                                                                                onClick={
                                                                                    lockUnlockDismissModal
                                                                                }
                                                                            >
                                                                                Cancel
                                                                            </button>
                                                                            <button
                                                                                className={`btn btn-primary btn-round ${
                                                                                    lockUpdateButtonSpinnerState
                                                                                        ? CONSTANTS.BUTTON_SPINNER
                                                                                        : ""
                                                                                }`}
                                                                                type="submit"
                                                                                disabled={
                                                                                    lockUpdateButtonState
                                                                                }
                                                                            >
                                                                                Update
                                                                            </button>
                                                                        </div>
                                                                    </form>
                                                                ) : (
                                                                    <form
                                                                        onSubmit={(
                                                                            event
                                                                        ) =>
                                                                            handleLockUnlockMeasures(
                                                                                event,
                                                                                false
                                                                            )
                                                                        }
                                                                    >
                                                                        <p
                                                                            className="lock-unlock-info"
                                                                            role="note"
                                                                        >
                                                                            Update
                                                                            measure
                                                                            lock
                                                                            date
                                                                            for
                                                                            all
                                                                            organizations
                                                                        </p>
                                                                        <div className="form-group d-flex flex-column required">
                                                                            <label
                                                                                htmlFor="lockdate"
                                                                                className="mb-2"
                                                                                id="lockdate-label"
                                                                            >
                                                                                Update
                                                                                lock
                                                                                date
                                                                            </label>
                                                                            <Datepicker
                                                                                id="lockdate"
                                                                                defaultValue={
                                                                                    measureLockDate
                                                                                }
                                                                                onChangeDate={
                                                                                    modifyLockDate
                                                                                }
                                                                                setBtnInd={
                                                                                    updateBtnInd
                                                                                }
                                                                                ariaLabel="lockdate-label"
                                                                                minMaxDate
                                                                                isCstCurrentDate
                                                                            />
                                                                            <div
                                                                                id="lockdateinfo"
                                                                                className="mt-1 font-14"
                                                                            >
                                                                                <i
                                                                                    className="aha-icon-info font-red mr-1 ml-n1"
                                                                                    aria-hidden="true"
                                                                                />
                                                                                Lock
                                                                                will
                                                                                be
                                                                                applied
                                                                                at
                                                                                23:59:59
                                                                                CST
                                                                            </div>
                                                                        </div>
                                                                        <div className="d-flex justify-content-end lock-unlock-btns">
                                                                            <button
                                                                                className="btn btn-secondary btn-round"
                                                                                type="button"
                                                                                onClick={
                                                                                    lockUnlockDismissModal
                                                                                }
                                                                                aria-label="Cancel Restrict Organization Measures"
                                                                            >
                                                                                Cancel
                                                                            </button>
                                                                            <button
                                                                                className={`btn btn-primary btn-round ${
                                                                                    lockUpdateButtonSpinnerState
                                                                                        ? CONSTANTS.BUTTON_SPINNER
                                                                                        : ""
                                                                                }`}
                                                                                type="submit"
                                                                                disabled={
                                                                                    lockUpdateButtonState
                                                                                }
                                                                            >
                                                                                Update
                                                                            </button>
                                                                        </div>
                                                                    </form>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </FocusTrap>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {exportProgramMeasureActivateInd && (
                                <FocusTrap
                                    focusTrapOptions={{
                                        escapeDeactivates: false,
                                        clickOutsideDeactivates: false,
                                    }}
                                >
                                    <div
                                        className="modal fade show aui-modal"
                                        id="exportProgramMeasuresModal"
                                        tabIndex={-1}
                                        aria-labelledby="exportProgramMeasuresLabel"
                                        aria-modal="true"
                                        role="dialog"
                                        style={{
                                            display:
                                                exportProgramMeasureActivateInd
                                                    ? "block"
                                                    : "none",
                                        }}
                                    >
                                        <div className="modal-dialog modal-dialog-centered modal-md">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h2
                                                        className="h4"
                                                        id="exportProgramMeasuresLabel"
                                                    >
                                                        Export Program Measures
                                                    </h2>
                                                    <button
                                                        id="closeExportModal"
                                                        type="button"
                                                        className="close"
                                                        onClick={
                                                            exportProgramMeasureDismissModal
                                                        }
                                                        aria-label="Close export program measures modal"
                                                    >
                                                        <span
                                                            aria-hidden="true"
                                                            className="aha-icon-cross"
                                                        />
                                                    </button>
                                                </div>
                                                <div className="modal-body p-0">
                                                    <form
                                                        onSubmit={
                                                            handleExportToExcelEvent
                                                        }
                                                    >
                                                        <div className="d-flex flex-row-reverse">
                                                            <div className="pb-3 mand-field">
                                                                <sup>*</sup>
                                                                mandatory fields
                                                            </div>
                                                        </div>
                                                        <div
                                                            role="group"
                                                            aria-labelledby="organization-export"
                                                            className="form-group required"
                                                        >
                                                            <label
                                                                className="pb-2 mb-0 font-600 label-form"
                                                                id="organization-export"
                                                            >
                                                                Select
                                                                organization
                                                                type(s) to
                                                                export
                                                                <sup>*</sup>
                                                            </label>
                                                            <div className="form-check mb-3 mb-sm-2 px-0">
                                                                <ul className="d-sm-flex p-0">
                                                                    <li className="form-check mb-3 mb-sm-2">
                                                                        <input
                                                                            name="registered"
                                                                            type="checkbox"
                                                                            value="Registred"
                                                                            checked={expOrgType.includes(
                                                                                CONSTANTS
                                                                                    .ORG_TYPE
                                                                                    .REGISTERED
                                                                            )}
                                                                            id="registered"
                                                                            onChange={
                                                                                onChangeExpOrgType
                                                                            }
                                                                            required={
                                                                                isRequired
                                                                            }
                                                                        />
                                                                        <label htmlFor="registered">
                                                                            Registered
                                                                        </label>
                                                                    </li>
                                                                    <li className="form-check mb-3 mb-sm-2 ml-sm-4">
                                                                        <input
                                                                            name="certified"
                                                                            type="checkbox"
                                                                            value="Certified"
                                                                            checked={expOrgType.includes(
                                                                                CONSTANTS
                                                                                    .ORG_TYPE
                                                                                    .CERTIFIED
                                                                            )}
                                                                            id="certified"
                                                                            onChange={
                                                                                onChangeExpOrgType
                                                                            }
                                                                            required={
                                                                                isRequired
                                                                            }
                                                                        />
                                                                        <label htmlFor="certified">
                                                                            Awarded
                                                                        </label>
                                                                    </li>
                                                                    <li className="form-check mb-3 mb-sm-2 ml-sm-4">
                                                                        <input
                                                                            name="deactivated"
                                                                            type="checkbox"
                                                                            value="Dectivated"
                                                                            checked={expOrgType.includes(
                                                                                CONSTANTS
                                                                                    .ORG_TYPE
                                                                                    .DEACTIVATED
                                                                            )}
                                                                            id="deactivated"
                                                                            onChange={
                                                                                onChangeExpOrgType
                                                                            }
                                                                            required={
                                                                                isRequired
                                                                            }
                                                                        />
                                                                        <label htmlFor="deactivated">
                                                                            Deactivated
                                                                        </label>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="form-group required flex-column my-4">
                                                            <label
                                                                htmlFor="exportCalendarYear"
                                                                className="mb-1 font-600 label-form"
                                                                id="exportCalendarYear-label"
                                                            >
                                                                Select Calendar
                                                                Year
                                                                <sup>*</sup>
                                                            </label>
                                                            <Dropdown
                                                                id="exportCalendarYear"
                                                                items={
                                                                    yearsDropdown
                                                                }
                                                                selectedValue={
                                                                    expYear
                                                                }
                                                                callParentOnSelect={
                                                                    onChangeExpYear
                                                                }
                                                            />
                                                        </div>
                                                        <div className="form-group flex-column my-4">
                                                            <label
                                                                className="mb-1"
                                                                id="dropdown-label-orgDetailsExport"
                                                            >
                                                                Select the
                                                                organization
                                                                details to
                                                                export
                                                                (optional)
                                                            </label>
                                                            <GroupedMultiSelectDropdown
                                                                hasAllOption
                                                                id="orgDetailsExport"
                                                                items={
                                                                    expOrganizationFields
                                                                }
                                                                selectedValue={
                                                                    selectedOrganizations
                                                                }
                                                                callParentOnSelect={(
                                                                    value: any
                                                                ) => {
                                                                    setSelectedOrganizations(
                                                                        value
                                                                    );
                                                                }}
                                                                isLoading={
                                                                    isOrganizationFieldsLoading
                                                                }
                                                            />
                                                        </div>
                                                        <div className="form-group flex-column my-4">
                                                            <label
                                                                className="mb-1"
                                                                id="dropdown-label-orgProgramsExport"
                                                            >
                                                                Select the
                                                                program details
                                                                to export
                                                                (optional)
                                                            </label>
                                                            <GroupedMultiSelectDropdown
                                                                hasAllOption
                                                                id="orgProgramsExport"
                                                                items={
                                                                    expProgramFields
                                                                }
                                                                selectedValue={
                                                                    selectedPrograms
                                                                }
                                                                callParentOnSelect={(
                                                                    value: any
                                                                ) => {
                                                                    setSelectedPrograms(
                                                                        value
                                                                    );
                                                                }}
                                                                isLoading={
                                                                    isProgramFieldsLoading
                                                                }
                                                            />
                                                        </div>
                                                        <div className="d-flex justify-content-end mt-4 flex-column flex-sm-row ">
                                                            <button
                                                                type="button"
                                                                className="btn btn-secondary btn-round mr-sm-4"
                                                                onClick={
                                                                    exportProgramMeasureDismissModal
                                                                }
                                                                aria-label="cancel and close export program measures modal"
                                                            >
                                                                Cancel
                                                            </button>
                                                            <button
                                                                disabled={
                                                                    exportButtonState
                                                                }
                                                                type="submit"
                                                                className={`btn btn-primary btn-round  mt-4  mt-sm-0 ${
                                                                    exportButtonState
                                                                        ? CONSTANTS.BUTTON_SPINNER
                                                                        : ""
                                                                }`}
                                                            >
                                                                <i className="aha-icon-export export-admin-icon mr-1" />
                                                                Export
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </FocusTrap>
                            )}
                            <div>
                                <ul
                                    className="nav nav-tabs tab-filled"
                                    id="myTab"
                                    role="tablist"
                                >
                                    <li
                                        className="nav-item tab-filled-item"
                                        role="presentation"
                                    >
                                        <button
                                            name="tab_1"
                                            onKeyDown={(event) => {
                                                onkeyDownCallback(
                                                    event,
                                                    "tab_",
                                                    4
                                                );
                                            }}
                                            className={`nav-link ${
                                                tab === CONSTANTS.PENDING
                                                    ? "active"
                                                    : ""
                                            }`}
                                            id="pending-tab"
                                            data-toggle="tab"
                                            data-target="#pending-approval-tab"
                                            type="button"
                                            role="tab"
                                            aria-controls="pending-approval-tab"
                                            aria-selected="true"
                                            onClick={() => {
                                                if (tab !== CONSTANTS.PENDING) {
                                                    setPageNumber(1);
                                                    setPagination(
                                                        defaultPagination
                                                    );
                                                    setTab(CONSTANTS.PENDING);
                                                    setIsActive(true);
                                                }
                                            }}
                                        >
                                            Pending
                                        </button>
                                    </li>
                                    <li
                                        className="nav-item tab-filled-item"
                                        role="presentation"
                                    >
                                        <button
                                            name="tab_2"
                                            onKeyDown={(keyEvent) => {
                                                onkeyDownCallback(
                                                    keyEvent,
                                                    "tab_",
                                                    4
                                                );
                                            }}
                                            className={`nav-link ${
                                                tab === CONSTANTS.REGISTRED
                                                    ? "active"
                                                    : ""
                                            }`}
                                            id="registered-tab"
                                            data-toggle="tab"
                                            data-target="#registered-facilities-tab"
                                            type="button"
                                            role="tab"
                                            aria-controls="registered-facilities-tab"
                                            aria-selected="false"
                                            onClick={() => {
                                                if (
                                                    tab !== CONSTANTS.REGISTRED
                                                ) {
                                                    setPageNumber(1);
                                                    setPagination(
                                                        defaultPagination
                                                    );
                                                    setTab(CONSTANTS.REGISTRED);
                                                    setIsActive(true);
                                                }
                                            }}
                                        >
                                            Registered
                                        </button>
                                    </li>
                                    <li
                                        className="nav-item tab-filled-item"
                                        role="presentation"
                                    >
                                        <button
                                            name="tab_3"
                                            onKeyDown={(keyDownEvent) => {
                                                onkeyDownCallback(
                                                    keyDownEvent,
                                                    "tab_",
                                                    4
                                                );
                                            }}
                                            className={`nav-link ${
                                                tab === CONSTANTS.CERTIFIED
                                                    ? "active"
                                                    : ""
                                            }`}
                                            id="certified-tab"
                                            data-toggle="tab"
                                            data-target="#certified-facilities-tab"
                                            type="button"
                                            role="tab"
                                            aria-controls="certified-facilities-tab"
                                            aria-selected="false"
                                            onClick={() => {
                                                if (
                                                    tab !== CONSTANTS.CERTIFIED
                                                ) {
                                                    setPageNumber(1);
                                                    setPagination(
                                                        defaultPagination
                                                    );
                                                    setTab(CONSTANTS.CERTIFIED);
                                                    setIsActive(true);
                                                }
                                            }}
                                        >
                                            Awarded
                                        </button>
                                    </li>
                                    <li
                                        className="nav-item tab-filled-item"
                                        role="presentation"
                                    >
                                        <button
                                            name="tab_4"
                                            onKeyDown={(
                                                onkeyDownCallbackEvent
                                            ) => {
                                                onkeyDownCallback(
                                                    onkeyDownCallbackEvent,
                                                    "tab_",
                                                    4
                                                );
                                            }}
                                            className={`nav-link ${
                                                tab === CONSTANTS.DEACTIVATED
                                                    ? "active"
                                                    : ""
                                            }`}
                                            id="deactivated-tab"
                                            data-toggle="tab"
                                            data-target="#deactivated-facilities-tab"
                                            type="button"
                                            role="tab"
                                            aria-controls="deactivated-facilities-tab"
                                            aria-selected="false"
                                            onClick={() => {
                                                if (
                                                    tab !==
                                                    CONSTANTS.DEACTIVATED
                                                ) {
                                                    setPageNumber(1);
                                                    setPagination(
                                                        defaultPagination
                                                    );
                                                    setTab(
                                                        CONSTANTS.DEACTIVATED
                                                    );
                                                    setIsActive(false);
                                                }
                                            }}
                                        >
                                            Deactivated
                                        </button>
                                    </li>
                                </ul>
                                <div
                                    className="tab-content admin-main-tabs"
                                    id="myTabContent"
                                >
                                    {tab === CONSTANTS.PENDING && (
                                        <div
                                            className={`tab-pane fade ${
                                                tab === CONSTANTS.PENDING
                                                    ? "show active"
                                                    : ""
                                            }`}
                                            id="pending-approval-tab"
                                            role="tabpanel"
                                            aria-labelledby="pending-tab"
                                            tabIndex={0}
                                        >
                                            <div className="row">
                                                <div className="col-md-11 offset-md-1 col-xl-8 offset-xl-4">
                                                    <SearchFilter
                                                        placeholder="By Organization Name/AHA EMS ID/Code"
                                                        programCategories={
                                                            programCategories
                                                        }
                                                        setDefaultPagination={
                                                            setDefaultPagination
                                                        }
                                                        stateCd={stateCd}
                                                        setStateCd={setStateCd}
                                                        tabName={
                                                            CONSTANTS.PENDING
                                                        }
                                                        setClearFilters={
                                                            setClearFilters
                                                        }
                                                        countryDropdown={
                                                            countryDropdown
                                                        }
                                                        stateDropdown={
                                                            stateDropdown
                                                        }
                                                        isCountryLoading={
                                                            isCountryLoading
                                                        }
                                                        isStateLoading={
                                                            isStateLoading
                                                        }
                                                        getFacilitiesResult={
                                                            getFacilitiesResult
                                                        }
                                                        emsInd
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <TableRowCount
                                                    isFirst={pagination.isFirst}
                                                    pageNumber={
                                                        pagination.pageNumber
                                                    }
                                                    pageSize={
                                                        pagination.pageSize
                                                    }
                                                    isLast={pagination.isLast}
                                                    totalCount={
                                                        pagination.totalCount
                                                    }
                                                    roleValue={roleValue}
                                                />
                                            </div>
                                            {isTableLoading ? (
                                                <div
                                                    role="alert"
                                                    aria-label="pending approval table is loading"
                                                    className="sr-only"
                                                />
                                            ) : (
                                                ""
                                            )}
                                            <EMSAgencyTable
                                                tab={tab}
                                                pageNumber={pageNumber}
                                                hospitals={pendingHospitals}
                                                actionURL="/emsadmin/organization-management/approve-organization/"
                                                isTableLoading={isTableLoading}
                                                isActive={isActive}
                                                isDeactivated={false}
                                                close={close}
                                                setSortInd={setSortInd}
                                                sortInd={sortInd}
                                            />
                                        </div>
                                    )}
                                    {tab === CONSTANTS.REGISTRED && (
                                        <div
                                            className={`tab-pane fade ${
                                                tab === CONSTANTS.REGISTRED
                                                    ? "show active"
                                                    : ""
                                            }`}
                                            id="registered-facilities-tab"
                                            role="tabpanel"
                                            tabIndex={0}
                                            aria-labelledby="registered-tab"
                                        >
                                            <div className="row">
                                                <div className="col-md-11 offset-md-1 col-xl-8 offset-xl-4">
                                                    <SearchFilter
                                                        placeholder="By Organization Name/AHA EMS ID/Code"
                                                        setDefaultPagination={
                                                            setDefaultPagination
                                                        }
                                                        programCategories={
                                                            programCategories
                                                        }
                                                        stateCd={stateCd}
                                                        setStateCd={setStateCd}
                                                        tabName={
                                                            CONSTANTS.REGISTRED
                                                        }
                                                        setClearFilters={
                                                            setClearFilters
                                                        }
                                                        stateDropdown={
                                                            stateDropdown
                                                        }
                                                        isStateLoading={
                                                            isStateLoading
                                                        }
                                                        getFacilitiesResult={
                                                            getFacilitiesResult
                                                        }
                                                        emsInd
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <TableRowCount
                                                    isFirst={pagination.isFirst}
                                                    pageNumber={
                                                        pagination.pageNumber
                                                    }
                                                    pageSize={
                                                        pagination.pageSize
                                                    }
                                                    isLast={pagination.isLast}
                                                    totalCount={
                                                        pagination.totalCount
                                                    }
                                                    roleValue={roleValue}
                                                />
                                            </div>
                                            {isTableLoading ? (
                                                <div
                                                    role="alert"
                                                    aria-label="registered organizations table is loading"
                                                    className="sr-only"
                                                />
                                            ) : (
                                                ""
                                            )}
                                            <EMSAgencyTable
                                                tab={tab}
                                                pageNumber={pageNumber}
                                                hospitals={registeredHospitals}
                                                actionURL="/emsadmin/organization-management/organization-details/"
                                                isTableLoading={isTableLoading}
                                                isActive={isActive}
                                                isDeactivated={false}
                                                close={close}
                                                setSortInd={setSortInd}
                                                sortInd={sortInd}
                                            />
                                        </div>
                                    )}
                                    {tab === CONSTANTS.CERTIFIED && (
                                        <div
                                            className={`tab-pane fade ${
                                                tab === CONSTANTS.CERTIFIED
                                                    ? "show active"
                                                    : ""
                                            }`}
                                            id="certified-facilities-tab"
                                            role="tabpanel"
                                            tabIndex={0}
                                            aria-labelledby="certified-tab"
                                        >
                                            <div className="row">
                                                <div className="col-md-11 offset-md-1 col-xl-8 offset-xl-4">
                                                    <SearchFilter
                                                        placeholder="By Organization Name/AHA EMS ID/Code"
                                                        setDefaultPagination={
                                                            setDefaultPagination
                                                        }
                                                        programCategories={
                                                            programCategories
                                                        }
                                                        stateCd={stateCd}
                                                        setStateCd={setStateCd}
                                                        tabName={
                                                            CONSTANTS.CERTIFIED
                                                        }
                                                        setClearFilters={
                                                            setClearFilters
                                                        }
                                                        stateDropdown={
                                                            stateDropdown
                                                        }
                                                        isStateLoading={
                                                            isStateLoading
                                                        }
                                                        getFacilitiesResult={
                                                            getFacilitiesResult
                                                        }
                                                        emsInd
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <TableRowCount
                                                    isFirst={pagination.isFirst}
                                                    pageNumber={
                                                        pagination.pageNumber
                                                    }
                                                    pageSize={
                                                        pagination.pageSize
                                                    }
                                                    isLast={pagination.isLast}
                                                    totalCount={
                                                        pagination.totalCount
                                                    }
                                                    roleValue={roleValue}
                                                />
                                            </div>
                                            {isTableLoading ? (
                                                <div
                                                    role="alert"
                                                    aria-label="registered organizations table is loading"
                                                    className="sr-only"
                                                />
                                            ) : (
                                                ""
                                            )}
                                            <EMSAgencyTable
                                                tab={tab}
                                                pageNumber={pageNumber}
                                                hospitals={certifiedHospitals}
                                                actionURL="/emsadmin/organization-management/organization-details/"
                                                isTableLoading={isTableLoading}
                                                isActive={isActive}
                                                isDeactivated={false}
                                                close={close}
                                                setSortInd={setSortInd}
                                                sortInd={sortInd}
                                            />
                                        </div>
                                    )}
                                    {tab === CONSTANTS.DEACTIVATED && (
                                        <div
                                            className={`tab-pane fade ${
                                                tab === CONSTANTS.DEACTIVATED
                                                    ? "show active"
                                                    : ""
                                            }`}
                                            id="deactivated-facilities-tab"
                                            role="tabpanel"
                                            tabIndex={0}
                                            aria-labelledby="deactivated-tab"
                                        >
                                            <div className="row">
                                                <div className="col-md-11 offset-md-1 col-xl-8 offset-xl-4">
                                                    <SearchFilter
                                                        placeholder="By Organization Name/AHA EMS ID/Code"
                                                        programCategories={
                                                            programCategories
                                                        }
                                                        setDefaultPagination={
                                                            setDefaultPagination
                                                        }
                                                        stateCd={stateCd}
                                                        setStateCd={setStateCd}
                                                        tabName={
                                                            CONSTANTS.DEACTIVATED
                                                        }
                                                        setClearFilters={
                                                            setClearFilters
                                                        }
                                                        countryDropdown={
                                                            countryDropdown
                                                        }
                                                        stateDropdown={
                                                            stateDropdown
                                                        }
                                                        isCountryLoading={
                                                            isCountryLoading
                                                        }
                                                        isStateLoading={
                                                            isStateLoading
                                                        }
                                                        getFacilitiesResult={
                                                            getFacilitiesResult
                                                        }
                                                        emsInd
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <TableRowCount
                                                    isFirst={pagination.isFirst}
                                                    pageNumber={
                                                        pagination.pageNumber
                                                    }
                                                    pageSize={
                                                        pagination.pageSize
                                                    }
                                                    isLast={pagination.isLast}
                                                    totalCount={
                                                        pagination.totalCount
                                                    }
                                                    roleValue={roleValue}
                                                />
                                            </div>
                                            {isTableLoading ? (
                                                <div
                                                    role="alert"
                                                    aria-label="deactivated organizations table is loading"
                                                    className="sr-only"
                                                />
                                            ) : (
                                                ""
                                            )}
                                            <EMSAgencyTable
                                                tab={tab}
                                                pageNumber={pageNumber}
                                                hospitals={deactivatedHospitals}
                                                actionURL="/emsadmin/organization-management/approve-organization/"
                                                isTableLoading={isTableLoading}
                                                isActive={isActive}
                                                isDeactivated
                                                close={close}
                                                setSortInd={setSortInd}
                                                sortInd={sortInd}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                            {pagination && Number(pagination.totalPages) > 0 ? (
                                <Pagination
                                    pageNumber={pagination.pageNumber}
                                    pageSize={pagination.pageSize}
                                    totalCount={pagination.totalCount}
                                    totalPages={pagination.totalPages}
                                    isFirst={pagination.isFirst}
                                    isLast={pagination.isLast}
                                    setPageNumber={pagination.setPageNumber}
                                    paginationSetLimit={paginationSetLimit}
                                />
                            ) : null}
                        </div>
                    </div>
                </section>
            </div>
        </OrganizationManagementWrapper>
    );
};

export default OrganizationManagement;
