import React from "react";
import { Link, useLocation } from "react-router-dom";
import CONSTANTS from "common/constants";

interface Props {
    activeTabItem: string;
    hospitalId?: number;
    programId?: number;
    categoryId?: number;
}

const SettingSidebar = (props: Props) => {
    const location = useLocation();
    return (
        <div className="aui-sidenav-plane">
            <nav className="navbar-expand-lg overflow-hidden p-lg-1">
                <button
                    className="navbar-toggler float-right my-3 mx-0"
                    type="button"
                    data-toggle="collapse"
                    data-target="#sideNavbar2"
                    aria-controls="sideNavbar2"
                    aria-expanded="false"
                    aria-label="navigation settings"
                >
                    <i
                        className="aha-icon-hamburger-round"
                        aria-hidden="true"
                    />
                </button>
                <aside className="collapse navbar-collapse" id="sideNavbar2">
                    <ul className="w-100" role="menu">
                        <li
                            className={
                                props.activeTabItem == "organizationDetails"
                                    ? "active"
                                    : ""
                            }
                        >
                            <Link
                                to={{
                                    pathname: "/settings/organizationDetails",
                                    state: {
                                        pageNum: location?.state?.pageNum,
                                        search: location?.state?.search,
                                    },
                                }}
                                aria-current={
                                    props.activeTabItem == "organizationDetails"
                                        ? "page"
                                        : false
                                }
                            >
                                Organization Details
                            </Link>
                        </li>
                        <li
                            className={
                                props.activeTabItem == "programDetails"
                                    ? "active"
                                    : ""
                            }
                        >
                            <Link
                                to={{
                                    pathname: "/settings/program",
                                    state: {
                                        pageNum: location?.state?.pageNum,
                                        search: location?.state?.search,
                                    },
                                }}
                                aria-current={
                                    props.activeTabItem == "programDetails"
                                        ? "page"
                                        : false
                                }
                                role="menuitem"
                            >
                                {props.programId == CONSTANTS.EMS_PROGRAM_ID
                                    ? "Program Details"
                                    : "Programs"}
                            </Link>
                        </li>
                        <li
                            className={
                                props.activeTabItem == "agreementDetails"
                                    ? "active"
                                    : ""
                            }
                        >
                            <Link
                                to={{
                                    pathname: `/settings/agreementdetails/organization/${props.hospitalId}/programs/${props.programId}`,
                                    state: {
                                        pageNum: location?.state?.pageNum,
                                        search: location?.state?.search,
                                    },
                                }}
                                aria-current={
                                    props.activeTabItem == "agreementDetails"
                                        ? "page"
                                        : false
                                }
                            >
                                Agreement Details
                            </Link>
                        </li>
                        {localStorage.getItem("selectedHospitalCountryCode") ==
                            CONSTANTS.DOMESTIC_COUNTRY_CODE &&
                            props.programId != CONSTANTS.EMS_PROGRAM_ID && (
                                <li
                                    className={
                                        props.activeTabItem == "billingDetails"
                                            ? "active"
                                            : ""
                                    }
                                >
                                    <Link
                                        to={{
                                            pathname: `/settings/billingdetails/organization/${props.hospitalId}/categories/${props.categoryId}`,
                                            state: {
                                                pageNum:
                                                    location?.state?.pageNum,
                                                search: location?.state?.search,
                                            },
                                        }}
                                        aria-current={
                                            props.activeTabItem ==
                                            "billingDetails"
                                                ? "page"
                                                : false
                                        }
                                    >
                                        Billing Details
                                    </Link>
                                </li>
                            )}
                    </ul>
                </aside>
            </nav>
        </div>
    );
};

export default SettingSidebar;
