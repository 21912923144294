import styled from "styled-components";

export const ProgramCard = styled.div`
    &.program-accordion-card {
        margin: 12px 0;
    }
    .card-program {
        background-color: white;
        border: none;
    }
    .card-button {
        background: none;
        border: none;
        width: 100%;
        text-align: left;
    }
    .accordion > .card {
        overflow: visible;
        border-radius: 2px;
    }
    .arrow-down {
        transform: rotate(180deg);
        transition: all 0.3s ease;
    }
    .program-description {
        padding: 5px 20px 20px 60px;
        font-weight: normal;
    }
    .card-header {
        padding: 13px;
    }
    .custom-icon-position {
        margin-right: 50px;
        font-size: 22px;
        color: #c10e21;
    }
    .program-title {
        font-size: 16px;
    }
    .typecard-footer {
        margin-top: 16px;
    }
    .accordion-header {
        padding: 6px 12px;
        border-radius: 0;
    }
`;
