/** API Utils Class
 * @name API
 * @property {Function} GET
 * @property {Function} DELETE
 * @property {Function} POST
 * @property {Function} PATCH
 */

import { redirectToHomePage } from "common/utils";
import CONSTANTS from "common/constants";
import axios from "axios";

const source = axios.CancelToken.source();

export default class API {
    /**
     * Hit the given API Endpoint and Returns API response.
     * @param {string} endPoint The endpoint to hit the API.
     * @return {JSON} The response from API.
     */
    GET = async (endPoint: any) => {
        try {
            const axiosResponse = await axios({
                method: "get",
                url: endPoint,
                headers: {
                    Authorization:
                        localStorage.getItem("userAccessToken") || "",
                    "Content-Type": "application/json",
                },
                cancelToken: source.token,
            });
            if (axiosResponse.data?.success === true) {
                return axiosResponse.data;
            }
            throw axiosResponse.data?.error;
        } catch (error: any) {
            if (
                (error?.response?.status == 403 &&
                    error?.response?.data?.error?.code !=
                        CONSTANTS.FORBIDDEN) ||
                error?.response?.data?.error?.code ==
                    CONSTANTS.SESSION_EXPIRED ||
                error?.response?.data?.error?.code == CONSTANTS.UNAUTHORIZED
            ) {
                source.cancel(error?.response?.data?.error?.message);
                redirectToHomePage(CONSTANTS.SLEEP_TIME);
            }
            return {
                error:
                    error?.isAxiosError && error?.response?.data?.error
                        ? error.response.data.error
                        : error,
                success: false,
            };
        }
    };

    /**
     * Hit the given API Endpoint and Returns API response.
     * @param {string} endPoint The endpoint to hit the API.
     * @return {JSON} The response from API.
     */
    DELETE = async (endPoint: any) => {
        try {
            const axiosResponse = await axios({
                method: "delete",
                url: endPoint,
                headers: {
                    Authorization:
                        localStorage.getItem("userAccessToken") || "",
                    "Content-Type": "application/json",
                },
                cancelToken: source.token,
            });

            if (axiosResponse.data?.success === true) {
                return axiosResponse.data;
            }
            throw axiosResponse.data?.error;
        } catch (error: any) {
            if (
                (error?.response?.status == 403 &&
                    error?.response?.data?.error?.code !=
                        CONSTANTS.FORBIDDEN) ||
                error?.response?.data?.error?.code ==
                    CONSTANTS.SESSION_EXPIRED ||
                error?.response?.data?.error?.code == CONSTANTS.UNAUTHORIZED
            ) {
                source.cancel(error?.response?.data?.error?.message);
                redirectToHomePage(CONSTANTS.SLEEP_TIME);
            }
            return {
                error:
                    error?.isAxiosError && error?.response?.data?.error
                        ? error.response.data.error
                        : error,
                success: false,
            };
        }
    };

    /**
     * Hit the given API Endpoint and Returns API response.
     * @param {string} endPoint The endpoint to hit the API.
     * @param {JSON} data The data to pass to the API.
     * @return {JSON} The response from API.
     */
    POST = async (endPoint: any, data: any) => {
        try {
            const axiosResponse = await axios({
                method: "post",
                url: endPoint,
                data,
                headers: {
                    Authorization:
                        localStorage.getItem("userAccessToken") || "",
                    "Content-Type": "application/json",
                },
                cancelToken: source.token,
            });
            if (axiosResponse.data?.success === true) {
                return axiosResponse.data;
            }
            throw axiosResponse.data?.error;
        } catch (error: any) {
            if (
                (error?.response?.status == 403 &&
                    error?.response?.data?.error?.code !=
                        CONSTANTS.FORBIDDEN) ||
                error?.response?.data?.error?.code ==
                    CONSTANTS.SESSION_EXPIRED ||
                error?.response?.data?.error?.code == CONSTANTS.UNAUTHORIZED
            ) {
                source.cancel(error?.response?.data?.error?.message);
                redirectToHomePage(CONSTANTS.SLEEP_TIME);
            }
            return {
                error:
                    error?.isAxiosError && error?.response?.data?.error
                        ? error.response.data.error
                        : error,
                success: false,
            };
        }
    };

    /**
     * Hit the given API Endpoint and Returns API response.
     * @param {string} endPoint The endpoint to hit the API.
     * @param {JSON} data The data to pass to the API.
     * @return {JSON} The response from API.
     */
    PATCH = async (endPoint: any, data: any) => {
        try {
            const axiosResponse = await axios({
                method: "patch",
                url: endPoint,
                data,
                headers: {
                    Authorization:
                        localStorage.getItem("userAccessToken") || "",
                    "Content-Type": "application/json",
                },
                cancelToken: source.token,
            });

            if (axiosResponse.data?.success === true) {
                return axiosResponse.data;
            }
            throw axiosResponse.data?.error;
        } catch (error: any) {
            if (
                (error?.response?.status == 403 &&
                    error?.response?.data?.error?.code !=
                        CONSTANTS.FORBIDDEN) ||
                error?.response?.data?.error?.code ==
                    CONSTANTS.SESSION_EXPIRED ||
                error?.response?.data?.error?.code == CONSTANTS.UNAUTHORIZED
            ) {
                source.cancel(error?.response?.data?.error?.message);
                redirectToHomePage(CONSTANTS.SLEEP_TIME);
            }
            return {
                error:
                    error?.isAxiosError && error?.response?.data?.error
                        ? error.response.data.error
                        : error,
                success: false,
            };
        }
    };
}
