import React from "react";
import { CorporationBannerWrapper } from "./styled";
import "../../styles/styles.scss";
import { localDateToYYYYMMDDFormatString, wordSplit } from "common/utils";

interface Props {
    corporationDetails: any;
}

export const CorporationBanner = ({ corporationDetails }: Props) => {
    return (
        <CorporationBannerWrapper className="container-fluid p-0 d-flex flex-grow-1">
            <div className="facility-details-banner">
                <div className="d-flex justify-content-between align-items-start corp-banner-header">
                    <h2 className="h3 corp-banner-title pr-4">
                        {corporationDetails.corporationName || "N/A"}
                    </h2>
                </div>
                <div className="d-flex flex-wrap corp-banner-detail">
                    <div className="corp-banner-item">
                        Corporation Code:
                        <span className="corp-banner-value font-bold abbr-code">
                            {wordSplit(
                                corporationDetails.corporationCode || "N/A"
                            )}
                        </span>
                    </div>
                    <div className="corp-banner-item">
                        Location:
                        <span className="corp-banner-value font-bold">
                            {corporationDetails.countryName || "N/A"}
                        </span>
                    </div>
                    <div className="corp-banner-item">
                        Registered on:
                        <span className="corp-banner-value font-bold">
                            {corporationDetails.createdAt
                                ? localDateToYYYYMMDDFormatString(
                                      corporationDetails.createdAt
                                  )
                                : "N/A"}
                        </span>
                    </div>
                </div>
            </div>
        </CorporationBannerWrapper>
    );
};

export default CorporationBanner;
