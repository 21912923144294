import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import { RegistryFacilitiesTable } from "components/RegistryFacilitiesTable";
import Pagination, { IPagination } from "components/Pagination";
import { getRegistryHospitals } from "api/registryAPI";
import { parseJwt } from "common/utils";
import { Link, useLocation } from "react-router-dom";
import CONSTANTS from "common/constants";
import RegistryTabs from "../RegistryMenu";
import { FacilitiesListingWrapper } from "./styled";

const FacilitiesRegistered = () => {
    const location = useLocation();
    const [facilities, setFacilities] = useState<any>();
    const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
    const [pageNumber, setPageNumber] = useState<number>(
        location?.state?.pageNumber ? location.state.pageNumber : 1
    );
    const paginationSetLimit: number = 5;

    const defaultPagination: IPagination = {
        pageNumber: 1,
        pageSize: 10,
        isFirst: true,
        isLast: false,
        setPageNumber,
        paginationSetLimit,
    };
    const [pagination, setPagination] =
        useState<IPagination>(defaultPagination);
    const [mounted, setMounted] = useState<boolean>(false);
    const [roleValue, setRoleValue] = useState<string>("none");

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setPageLoadingStatus({ isPageLoading: true }));
        setIsTableLoading(true);

        const userAccessToken = parseJwt(
            window.localStorage.getItem("userAccessToken")
        );

        getRegistryHospitals(
            userAccessToken.registry_id,
            pagination.pageSize,
            pageNumber
        )
            .then((registryResponse: any) => {
                dispatch(setPageLoadingStatus({ isPageLoading: false }));
                setIsTableLoading(false);
                if (registryResponse.success) {
                    setFacilities(registryResponse.data.hospitals);
                    setPagination({
                        ...registryResponse.data._pagination,
                        setPageNumber,
                    });
                }
                if (CONSTANTS.WINDOW.AUI.Tooltip) {
                    CONSTANTS.WINDOW.AUI.Tooltip.init();
                }
                if (mounted) {
                    setRoleValue("alert");
                }
                setMounted(true);
            })
            .catch(() => {
                dispatch(setPageLoadingStatus({ isPageLoading: false }));
                setIsTableLoading(false);
            });
    }, [pageNumber]);

    return (
        <FacilitiesListingWrapper>
            <RegistryTabs activeTab="organizationManagement" />
            <div className="container">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb aha-breadcrumb px-0 mb-3">
                        <li className="breadcrumb-item">
                            <Link to="/registry/dashboard">Home</Link>
                        </li>
                        <li className="breadcrumb-item active">
                            <div aria-current="page" role="link" tabIndex={0}>
                                Organizations Registered
                            </div>
                        </li>
                    </ol>
                </nav>
                <div>
                    <h1 className="h3 mb-4">Organizations Registered</h1>
                    <RegistryFacilitiesTable
                        facilities={facilities}
                        actionURL="/registry/measures/organization"
                        isTableLoading={isTableLoading}
                        pagination={pagination}
                        roleValue={roleValue}
                    />
                </div>

                {pagination && Number(pagination.totalPages) > 0 ? (
                    <Pagination
                        pageNumber={pagination.pageNumber}
                        pageSize={pagination.pageSize}
                        totalCount={pagination.totalCount}
                        totalPages={pagination.totalPages}
                        isFirst={pagination.isFirst}
                        isLast={pagination.isLast}
                        setPageNumber={pagination.setPageNumber}
                        paginationSetLimit={paginationSetLimit}
                    />
                ) : null}
            </div>
        </FacilitiesListingWrapper>
    );
};

export default FacilitiesRegistered;
