import React, { useEffect, useState } from "react";
import CONSTANTS from "../../common/constants";
import { SelectAllCheckboxWrapper } from "./styled";

const SelectAllCheckbox = (props: any) => {
    const [currentPageCount, setCurrentPageCount] = useState<number>(0);
    const [selectAllCheckboxFocus, setSelectAllCheckboxFocus] =
        useState<boolean>(false);

    useEffect(() => {
        document.addEventListener("click", function (e: any) {
            if (e.target.id !== props.id) {
                setSelectAllCheckboxFocus(false);
            }
        });
        if (props.totalCount > CONSTANTS.SELECTALL_COUNT && !props.isLast) {
            setCurrentPageCount(CONSTANTS.SELECTALL_COUNT);
        } else if (props.isLast) {
            const count =
                props.totalCount -
                CONSTANTS.SELECTALL_COUNT * (props.pageNumber - 1);
            setCurrentPageCount(count);
        }
    });

    useEffect(() => {
        if (CONSTANTS.WINDOW.AUI.Tooltip) {
            CONSTANTS.WINDOW.AUI.Tooltip.init();
        }
    });

    return (
        /* add class 'select-intermidiate' for intermidiate state */
        props.selectAll[`check.${props.pageNumber}`] == "intermediate" ? (
            <SelectAllCheckboxWrapper className="select-all-checkbox select-intermidiate">
                <div>
                    <div className="form-group form-check mb-0 select-all-check">
                        <input
                            name={`check.${props.pageNumber}`}
                            type="checkbox"
                            value=""
                            id={props.id}
                            data-toggle="modal"
                            data-target="#clearFacilitySelectionModal"
                            checked={
                                props.selectAll[`check.${props.pageNumber}`] ==
                                "intermediate"
                            }
                            className="select-all-input"
                            aria-describedby={`${props.id}-clearIntSelectTooltip`}
                        />
                        <label
                            htmlFor={props.id}
                            className={`select-all-sm-label ${
                                props.totalCount > CONSTANTS.SELECTALL_COUNT
                                    ? ""
                                    : "mt-n1"
                            } ${
                                props.totalCount > CONSTANTS.SELECTALL_COUNT
                                    ? ""
                                    : "select-all-label"
                            }`}
                        >
                            {props.totalCount > CONSTANTS.SELECTALL_COUNT ? (
                                <>Clear current page selection</>
                            ) : (
                                <>Clear current page selection</>
                            )}
                        </label>
                        <div
                            className="tooltip-div"
                            id={`${props.id}-clearIntSelectTooltip`}
                        >
                            Clear Current Page Selection
                        </div>
                    </div>
                </div>
                {props.totalCount > CONSTANTS.SELECTALL_COUNT && (
                    <div className="dropdown checkbox-dropdown">
                        <button
                            className="btn btn-text dropdown-toggle mt-n1"
                            type="button"
                            data-toggle="dropdown"
                            aria-expanded="false"
                            ref={props.dropdownRef}
                        >
                            <span className="select-all-checkbox-label">
                                Select Organization
                            </span>
                        </button>
                        <div className="dropdown-menu" role="menu">
                            <button
                                className="dropdown-item"
                                role="menuitem"
                                type="button"
                                onClick={() => props.selectAllFacilities(true)}
                            >
                                All ({props.totalCount})
                            </button>
                            <button
                                className="dropdown-item"
                                role="menuitem"
                                type="button"
                                onClick={(event) =>
                                    props.selectCurrentPageFacilities({
                                        target: { checked: true },
                                    })
                                }
                            >
                                Current Page ({currentPageCount})
                            </button>
                            {props.count > 0 && (
                                <button
                                    className="dropdown-item"
                                    role="menuitem"
                                    type="button"
                                    data-toggle="modal"
                                    data-target="#clearcurrentPageFacilityModal"
                                >
                                    Clear
                                </button>
                            )}
                        </div>
                    </div>
                )}
            </SelectAllCheckboxWrapper>
        ) : (
            <SelectAllCheckboxWrapper className="select-all-checkbox">
                {props.selectAll[`check.${props.pageNumber}`] == true ? (
                    <>
                        <div className="form-group form-check mb-0 select-all-check">
                            <input
                                name={`check.${props.pageNumber}`}
                                type="checkbox"
                                value=""
                                autoFocus={selectAllCheckboxFocus}
                                className="select-all-input"
                                id={props.id}
                                {...(props.selectAll[
                                    `check.${props.pageNumber}`
                                ] == true
                                    ? {
                                          "data-toggle": "modal",
                                          "data-target":
                                              "#clearFacilitySelectionModal",
                                      }
                                    : {
                                          onChange: (event) =>
                                              props.selectCurrentPageFacilities(
                                                  event
                                              ),
                                      })}
                                checked={
                                    props.selectAll[
                                        `check.${props.pageNumber}`
                                    ] == true
                                }
                                aria-describedby={`${props.id}-clearSelectTooltip`}
                            />
                            <label
                                htmlFor={props.id}
                                className={`select-all-sm-label ${
                                    props.totalCount > CONSTANTS.SELECTALL_COUNT
                                        ? ""
                                        : "mt-n1"
                                }`}
                            >
                                clear selection
                            </label>
                            <div
                                className="tooltip-div"
                                id={`${props.id}-clearSelectTooltip`}
                            >
                                Clear Current Page Selection
                            </div>
                        </div>
                    </>
                ) : (
                    <div className="form-group form-check mb-0 select-all-check">
                        <input
                            name={`check.${props.pageNumber}`}
                            type="checkbox"
                            value=""
                            className="select-all-input"
                            id={props.id}
                            {...(props.selectAll[`check.${props.pageNumber}`] ==
                            true
                                ? {
                                      "data-toggle": "modal",
                                      "data-target":
                                          "#clearFacilitySelectionModal",
                                  }
                                : {
                                      onChange: (selectEvent) => {
                                          props.selectCurrentPageFacilities(
                                              selectEvent
                                          );
                                          setSelectAllCheckboxFocus(true);
                                      },
                                  })}
                            checked={
                                props.selectAll[`check.${props.pageNumber}`] ==
                                true
                            }
                            aria-describedby={`${props.id}-selectAllTooltip`}
                        />
                        <label
                            htmlFor={props.id}
                            className={`select-all-sm-label ${
                                props.totalCount > CONSTANTS.SELECTALL_COUNT
                                    ? ""
                                    : "mt-n1"
                            }`}
                        >
                            Select all Organization
                        </label>
                        <div
                            className="tooltip-div"
                            id={`${props.id}-selectAllTooltip`}
                        >
                            Select All in Current Page
                        </div>
                    </div>
                )}
                {props.totalCount > CONSTANTS.SELECTALL_COUNT && (
                    <div className="dropdown checkbox-dropdown">
                        <button
                            className="btn btn-text dropdown-toggle mt-n1"
                            type="button"
                            data-toggle="dropdown"
                            aria-expanded="false"
                            ref={props.dropdownRef}
                        >
                            <span className="select-all-checkbox-label">
                                Select Organization
                            </span>
                        </button>
                        <div className="dropdown-menu" role="menu">
                            <button
                                className={`dropdown-item ${
                                    props.selectAllOption ? "bulk-active" : ""
                                }`}
                                role="menuitem"
                                type="button"
                                onClick={() => props.selectAllFacilities(true)}
                            >
                                All ({props.totalCount})
                            </button>
                            <button
                                className={`dropdown-item ${
                                    props.selectCurrentOption &&
                                    props.selectAll[
                                        `check.${props.pageNumber}`
                                    ] === true
                                        ? "bulk-active"
                                        : ""
                                }`}
                                role="menuitem"
                                type="button"
                                disabled={
                                    props.selectAll[
                                        `check.${props.pageNumber}`
                                    ] == true
                                }
                                onClick={() =>
                                    props.selectCurrentPageFacilities({
                                        target: { checked: true },
                                    })
                                }
                            >
                                Current Page ({currentPageCount})
                            </button>
                            {props.count > 0 && (
                                <button
                                    className="dropdown-item"
                                    role="menuitem"
                                    type="button"
                                    data-toggle="modal"
                                    data-target="#clearcurrentPageFacilityModal"
                                >
                                    Clear
                                </button>
                            )}
                        </div>
                    </div>
                )}
            </SelectAllCheckboxWrapper>
        )
    );
};
export default SelectAllCheckbox;
