import { getCurrentQuarter } from "common/utils";
import Dropdown from "components/Dropdown";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setYear, setQuarter } from "./measuresDateFilter.slice";

interface Props {
    emsInd?: boolean;
}

export const MeasuresDateFilter = ({ emsInd }: Props) => {
    const dispatch = useDispatch();
    const measuresDateFilter = useSelector((state: any) => {
        return state.measuresDateFilter;
    });
    const currentYear = new Date().getFullYear();
    const currentQuarter = getCurrentQuarter();
    const getYears = () => {
        if (emsInd) {
            dispatch(setYear(2023));
            return [{ label: 2023, value: 2023 }];
        }
        const years = [];
        for (let i = currentYear; i >= 2019; i--) {
            years.push(i);
        }
        const yearsDropdownMap: any = [];
        if (years && years.length > 0) {
            years.forEach((year: any) => {
                yearsDropdownMap.push({ label: year, value: year });
            });
        }
        return yearsDropdownMap;
    };
    const [selectedYear, setSelectedYear] = useState<number>(
        measuresDateFilter.year
    );
    const [selectedQuarter, setSelectedQuarter] = useState<number>(
        measuresDateFilter.quarter
    );
    const yearsDropdown: Array<object> = getYears();

    const [quartersDropdown, setQuartersDropdown] = useState<any>([]);

    const changeYear = (value: any) => {
        setSelectedYear(value);
        setSelectedQuarter(1); // Setting Quarter to 1st Quarter when year is changing.
        dispatch(setYear(value));
        dispatch(setQuarter(1));
    };

    const changeQuarter = (value: any) => {
        setSelectedQuarter(value);
        dispatch(setQuarter(value));
    };

    useEffect(() => {
        const quarterMap = new Map();

        if (currentYear === measuresDateFilter.year) {
            for (let i = 1; i <= currentQuarter; i++) {
                quarterMap.set(`Q${i}`, i);
            }
        } else {
            quarterMap.set("Q1", 1);
            quarterMap.set("Q2", 2);
            quarterMap.set("Q3", 3);
            quarterMap.set("Q4", 4);
        }

        const quartersDropdownMap: any = [];
        quarterMap.forEach((v: any, k: any) => {
            quartersDropdownMap.push({ label: k, value: v });
        });
        setQuartersDropdown(quartersDropdownMap);
    }, [selectedYear]);

    useEffect(() => {
        setSelectedYear(measuresDateFilter.year);
        setSelectedQuarter(measuresDateFilter.quarter);
    }, [measuresDateFilter]);

    useEffect(() => {
        return () => {
            dispatch(setYear(currentYear));
            dispatch(setQuarter(currentQuarter));
        };
    }, []);

    return (
        <div
            className="d-md-flex form-group measure-filters m-0 align-items-center"
            role="group"
            aria-labelledby="filterbydate"
        >
            <label className="sr-only" id="filterbydate">
                Filters
            </label>
            <div className="d-flex">
                {yearsDropdown && (
                    <div className="measure-filter-item">
                        <label
                            className="a"
                            htmlFor="filterYear"
                            id="filterYear-label"
                        >
                            Select Year
                        </label>
                        <Dropdown
                            id="filterYear"
                            items={yearsDropdown}
                            selectedValue={selectedYear}
                            callParentOnSelect={changeYear}
                        />
                    </div>
                )}
                {quartersDropdown && !emsInd && (
                    <div className="measure-filter-item">
                        <label
                            className="a"
                            htmlFor="filterQuarter"
                            id="filterQuarter-label"
                        >
                            Select Quarter
                        </label>
                        <Dropdown
                            id="filterQuarter"
                            items={quartersDropdown}
                            selectedValue={selectedQuarter}
                            callParentOnSelect={changeQuarter}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default MeasuresDateFilter;
