import styled from "styled-components";

export const CorporationFacilitiesTable = styled.div`
    .corp-fac-registered-table {
        thead {
            @media only screen and (min-width: 992px) {
                th:nth-child(1) {
                    width: 35%;
                }
                th:nth-child(2) {
                    width: 33%;
                }
                th:nth-child(3) {
                    width: 32%;
                }
            }
        }
    }
    .corp-fac-certified-table {
        thead {
            @media only screen and (min-width: 992px) {
                th:nth-child(1) {
                    width: 27%;
                }
                th:nth-child(2) {
                    width: 27%;
                }
                th:nth-child(3) {
                    width: 23%;
                }
                th:nth-child(4) {
                    width: 23%;
                }
            }
        }
    }
    .corp-d-location {
        width: 100%;
        display: flex;
        > div:nth-child(1) {
            width: 76px;
        }
    }
`;
