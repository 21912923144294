import React from "react";
import { MaintenanceWrapper } from "./styled";

const Maintanance = () => {
    return (
        <MaintenanceWrapper className="container d-flex align-items-center justify-content-start">
            <div className="maintenence-window mx-auto">
                <div
                    className="aui-association-logo mb-4"
                    role="img"
                    aria-label="American Heart Association logo"
                />
                <h1 className="h1 font-600 mb-4">
                    We&#39;ll be back soon&#33;
                </h1>
                <p className="maintenence-text">
                    Sorry for the inconvenience but we are performing some
                    maintenance at the moment.
                </p>
            </div>
        </MaintenanceWrapper>
    );
};

export default Maintanance;
