import React, { useEffect, useState } from "react";
import HospitalBanner from "pages/Admin/HospitalBanner/HospitalBanner";
import MeasuresDateFilter from "components/MeasuresDateFilter";
import MeasureMetricTable from "components/MeasureTable/MeasureMetricTable";
import EMSMeasuresTable from "pages/Measures/EMSMeasuresTable/EMSMeasuresTable";
import { useParams } from "react-router";
import CONSTANTS from "common/constants";
import { HospitalManagementMearuresWrapper } from "./styled";

interface Props {
    hospital: any;
    programName: string;
    hospitalMeasures: any;
    isMeasureTableLoading: boolean;
    emsAdminInd?: boolean;
    programData?: any;
    certificateData?: any;
}

export const HospitalMeasuresTab = ({
    hospital,
    programName,
    hospitalMeasures,
    isMeasureTableLoading,
    emsAdminInd,
    programData,
    certificateData,
}: Props) => {
    const params: any = useParams();

    return (
        <HospitalManagementMearuresWrapper>
            <h2 className="h3 mb-4 d-lg-none">Measures</h2>
            <HospitalBanner
                hospitalName={hospital.facilityName}
                programName={programName}
                countryName={hospital.countryName}
                programId={params?.programId}
                hospital={hospital}
                emsAdminInd
                programData={programData}
                certificateData={certificateData}
            />
            <div className="d-xl-flex justify-content-between align-items-center measure-block-title">
                <h3 className="h3 mb-xl-0 mb-4">{programName} Measures</h3>
                <MeasuresDateFilter emsInd={emsAdminInd} />
            </div>

            {hospitalMeasures?.userData == true ? (
                <div className="admin-measure-table">
                    {params.programId === CONSTANTS.EMS_PROGRAM_ID ? (
                        <EMSMeasuresTable
                            data={hospitalMeasures}
                            isMeasureTableLoading={isMeasureTableLoading}
                            readOnly
                        />
                    ) : (
                        <MeasureMetricTable
                            data={hospitalMeasures}
                            isMeasureTableLoading={isMeasureTableLoading}
                            readOnly
                        />
                    )}
                </div>
            ) : (
                <span className="mb-xl-0 mb-7 ml-3">No available data</span>
            )}
        </HospitalManagementMearuresWrapper>
    );
};

export default HospitalMeasuresTab;
