import styled from "styled-components";

export const HospitalTableWrapper = styled.div`
    &.hospital-certified-table {
        thead {
            @media only screen and (min-width: 992px) {
                th:nth-child(1) {
                    width: 22%;
                }
                th:nth-child(2) {
                    width: 15%;
                }
                th:nth-child(3) {
                    width: 17%;
                }
                th:nth-child(4) {
                    width: 12%;
                }
                th:nth-child(5) {
                    width: 12%;
                }
                th:nth-child(6) {
                    width: 12%;
                }
            }
            @media only screen and (min-width: 1200px) {
                th:nth-child(1) {
                    width: 27%;
                }
                th:nth-child(2) {
                    width: 16%;
                }
                th:nth-child(3) {
                    width: 16%;
                }
                th:nth-child(4) {
                    width: 15%;
                }
                th:nth-child(5) {
                    width: 13%;
                }
                th:nth-child(6) {
                    width: 13%;
                }
            }
            @media only screen and (min-width: 1400px) {
                th:nth-child(1) {
                    width: 24%;
                }
                th:nth-child(2) {
                    width: 20%;
                }
                th:nth-child(3) {
                    width: 16%;
                }
                th:nth-child(4) {
                    width: 16%;
                }
                th:nth-child(5) {
                    width: 12%;
                }
                th:nth-child(6) {
                    width: 12%;
                }
            }
        }
        a {
            color: #c10e21;
        }
    }
    .expire-info-tooltip {
        width: 160px;
        left: -80px;
        &:before,
        &:after {
            left: 55%;
        }
    }
`;
