import {
    downloadFileFromS3,
    getUploadedCSVFileDetails,
    emsMeasureUploads,
    submitCSV,
    hospitalProgramUploadsPost,
    hospitalProgramUploadsPatch,
    uploadfileToS3,
    hospitalProgramMeasureMetricUpload,
} from "api/measuresCSVUploadAPI";
import {
    getFIleSize,
    getQuarterStartAndEndDate,
    localDateToYYYYMMDDFormatString,
    sleep,
    redirectionForErrorCode,
} from "common/utils";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import store from "app/store";
import { showToast } from "components/Toast/toast.slice";
import Pagination, { IPagination } from "components/Pagination";
import CONSTANTS from "common/constants";
import {
    downloadMeasureTemplate,
    saveEmsHospitalMeasure,
    getHospitalProgramMeasureMetrics,
} from "api/measuresApi";
import { UploadSpreadsheet } from "./styled";
import { logger } from "../../utils/logger.utils";
import "styles/focusable-element.scss";
import TableRowCount from "components/TableRowCount";
import { deleteUploadedDoc } from "api/programAgreementAPI";
import { emsHtmlParser } from "../../utils/common.utils";
import "styles/tooltip.scss";
import { getEMSAwards } from "api/emsAPI";

interface Props {
    isRegistry?: boolean;
    registryHospitalId?: number;
    registryProgramId?: number;
    registryCategoryId?: number;
    measeureSelectedYear?: any;
    countryId?: any;
}

const MeasuresCSVUploadPage = (props: Props) => {
    const params: any = useParams();
    const [roleValue, setRoleValue] = useState<string>("none");

    const hospitalId = props.isRegistry
        ? props.registryHospitalId
        : params.hospitalId;
    const programId = props.isRegistry
        ? props.registryProgramId
        : params.programId;
    const categoryId = props.isRegistry
        ? props.registryCategoryId
        : params.categoryId;

    const measuresDateFilter = useSelector((state: any) => {
        return state.measuresDateFilter;
    });
    const [file, setFile] = useState<any>(null);
    const [uploadId, setUploadId] = useState<number>();
    const [fileUploadError, setFileUploadError] = useState<boolean>(false);
    const [fileUploadSuccess, setFileUploadSuccess] = useState<boolean>(false);
    const [uploadProgress, setUploadProgress] = useState<number>(0);
    const [uploadedCSVFiles, setUploadedCSVFiles] = useState<any>([]);
    const [isSubmitProgress, setIsSubmitProgress] = useState<boolean>(false);
    const [mount, setMount] = useState<boolean>(false);
    const [measeureSelectedYear, setMeaseureSelectedYear] = useState<any>();
    const [fileUploadInd, setFileUploadInd] = useState<boolean>(true);

    /* Pagination - START */
    const [pageNumber, setPageNumber] = useState<number>(1);
    const paginationSetLimit = 5;
    const defaultPagination: IPagination = {
        pageNumber: 1,
        pageSize: 25,
        isFirst: true,
        isLast: false,
        setPageNumber,
        paginationSetLimit,
    };
    const [pagination, setPagination] =
        useState<IPagination>(defaultPagination);
    /* Pagination - END */
    const history = useHistory();

    const onFileChange = (event: any) => {
        setFileUploadError(false);
        setFileUploadSuccess(false);
        setUploadProgress(0);
        setUploadId(undefined);
        setFile(event.target.files[0]);
    };

    useEffect(() => {
        if (file) {
            if (file.size < CONSTANTS.FILE_UPLOAD_SIZE_LIMIT) {
                onFileUpload();
            } else {
                setFileUploadError(true);
            }
        }
    }, [file]);

    useEffect(() => {
        if (props.measeureSelectedYear) {
            setMeaseureSelectedYear(props.measeureSelectedYear);
        }
    }, [props.measeureSelectedYear]);

    useEffect(() => {
        if (mount) {
            setRoleValue("alert");
        }
        getCSVFilesList();
        setMount(true);
    }, [measuresDateFilter, pageNumber]);

    const getCSVFilesList = async () => {
        try {
            const startDateAndEndDate = getQuarterStartAndEndDate(
                measuresDateFilter.year,
                measuresDateFilter.quarter
            );
            if (startDateAndEndDate) {
                const uploadedCSVFileDetails: any =
                    await getUploadedCSVFileDetails(
                        hospitalId,
                        startDateAndEndDate?.split("/")[0],
                        startDateAndEndDate?.split("/")[1],
                        pageNumber
                    );
                if (uploadedCSVFileDetails.success) {
                    setUploadedCSVFiles(
                        uploadedCSVFileDetails.data.hospitalUploads
                    );
                    setPagination({
                        ...uploadedCSVFileDetails.data._pagination,
                        setPageNumber,
                    });
                    if (CONSTANTS.WINDOW.AUI.Tooltip) {
                        CONSTANTS.WINDOW.AUI.Tooltip.init();
                    }
                    if (programId === CONSTANTS?.EMS_PROGRAM_ID) {
                        getEMSAwards(
                            hospitalId,
                            programId,
                            categoryId,
                            measuresDateFilter.year
                        );
                    }
                }
            } else {
                const toast = {
                    message: "Please select Year and Quarter.",
                    code: "Error:",
                };
                store.dispatch(showToast(toast));
            }
        } catch (err: any) {
            redirectionForErrorCode(
                CONSTANTS.ROUTES.CORPORATION_DASHBOARD,
                err,
                history,
                2000
            );
            store.dispatch(showToast(err));
        }
    };

    const onFileUpload = async () => {
        setFileUploadInd(true);
        const startDateAndEndDate = getQuarterStartAndEndDate(
            measuresDateFilter.year,
            measuresDateFilter.quarter
        );
        if (startDateAndEndDate) {
            sleep(10000).then(() => {
                setFileUploadInd(false);
            });
            uploadfileToS3(
                hospitalId,
                programId,
                categoryId,
                startDateAndEndDate?.split("/")[0],
                startDateAndEndDate?.split("/")[1],
                file,
                setUploadId,
                setUploadProgress,
                measuresDateFilter.quarter
            )
                .then((uploadfileToS3Response: any) => {
                    if (uploadfileToS3Response.status === 200) {
                        setFileUploadSuccess(true);
                        setFileUploadError(false);
                    } else {
                        setFileUploadSuccess(false);
                        setFileUploadError(true);
                    }
                })
                .catch((error: any) => {
                    setFileUploadSuccess(false);
                    setFileUploadError(true);
                });
        } else {
            const toast = {
                message: "Please select Year and Quarter.",
                code: "Error:",
            };
            store.dispatch(showToast(toast));
        }
    };

    const submit = async () => {
        if (uploadId) {
            setIsSubmitProgress(true);
            submitCSV(uploadId, hospitalId, programId, categoryId)
                .then((submitResponse: any) => {
                    if (submitResponse.success === true) {
                        if (programId === CONSTANTS?.EMS_PROGRAM_ID) {
                            processS3V(submitResponse);
                        } else {
                            setIsSubmitProgress(false);
                            processQctS3(submitResponse);
                            const toast = {
                                message: `${file.name} has been successfully uploaded.`,
                                code: "Success:",
                                type: "success",
                            };
                            store.dispatch(showToast(toast));
                        }
                    }
                })
                .catch((error: any) => {
                    setIsSubmitProgress(false);
                    logger(error);
                });
        }
    };
    const processQctS3 = async (csv: any) => {
        const data = await hospitalProgramMeasureMetricUpload(
            hospitalId,
            programId,
            categoryId,
            csv.data.upload?.fileName,
            csv.data.upload?.filePath,
            csv.data.upload?.region,
            csv.data.upload?.id,
            props.countryId
        )
            .then((submitResponse: any) => {
                getCSVFilesList();
                resetFileUploadToInitailState();
                waitThenRefreshTable();
                return submitResponse;
            })
            .catch((error: any) => {
                logger(error);
                resetFileUploadToInitailState();
                waitThenRefreshTable();
                return error;
            });
        return data;
    };

    const processS3V = async (data: any) => {
        if (uploadId) {
            const hospitalProgramUploadsresp = await hospitalProgramUploads();
            const processedS3resp = await readS3CSVFile(data.data);
            setIsSubmitProgress(false);
            resetFileUploadToInitailState();
            if (processedS3resp.success === true) {
                await patchHospitalProgramUploads(
                    hospitalProgramUploadsresp.data.hospitalUpload
                        .hospitalProgramUploadsId,
                    CONSTANTS.PROCESSING,
                    undefined
                );
                getCSVFilesList();
                const measureResult = await HospitalProgramMeasureMetrics();
                const measurePostCall = !(
                    measureResult.data.isDraft && measureResult.data.isEditable
                );
                const measureData: any = [];
                await measureResult.data.measuresData.map((item: any) => {
                    item.map(async (measure: any) => {
                        measure.code = await emsHtmlParser(measure.code);
                        measureData.push(measure);
                    });
                });
                measureData.map((item: any) => {
                    processedS3resp.data.measuresData.map((data: any) => {
                        if (data.code == item.code) {
                            item.values.map((id: any) => {
                                data.values.map((id1: any) => {
                                    if (
                                        id.quarter == id1.quarter &&
                                        item.hospitalProgramMeasureId
                                    ) {
                                        id1.hospitalProgramMeasureId =
                                            id.hospitalProgramMeasureId;
                                    }
                                });
                            });
                        }
                    });
                });
                const saveEmsUploadHospitalMeasureResp =
                    await saveEmsUploadHospitalMeasure(
                        processedS3resp.data.measuresData,
                        processedS3resp.data.year,
                        measurePostCall,
                        CONSTANTS.VALIDATION_TYPE
                    );
                if (saveEmsUploadHospitalMeasureResp.success === true) {
                    await patchHospitalProgramUploads(
                        hospitalProgramUploadsresp.data.hospitalUpload
                            .hospitalProgramUploadsId,
                        CONSTANTS.PROCESSED,
                        undefined
                    );
                    getCSVFilesList();
                    resetFileUploadToInitailState();
                    const toast = {
                        message: `${file.name} has been successfully uploaded.`,
                        code: "Success:",
                        type: "success",
                    };
                    store.dispatch(showToast(toast));
                } else {
                    const error = {
                        error: { ...saveEmsUploadHospitalMeasureResp },
                    };
                    await patchHospitalProgramUploads(
                        hospitalProgramUploadsresp.data.hospitalUpload
                            .hospitalProgramUploadsId,
                        CONSTANTS.PROCESSING_FAILED,
                        error
                    );
                    getCSVFilesList();
                    resetFileUploadToInitailState();
                }
            } else {
                const error = {
                    error: { ...processedS3resp },
                };
                await patchHospitalProgramUploads(
                    hospitalProgramUploadsresp.data.hospitalUpload
                        .hospitalProgramUploadsId,
                    CONSTANTS.PROCESSING_FAILED,
                    error
                );
                getCSVFilesList();
                resetFileUploadToInitailState();
            }
        }
    };

    const resetFileUploadToInitailState = () => {
        // On Success - Set Page to Initail  State
        setFile(null);
        setFileUploadError(false);
        setFileUploadSuccess(false);
        setUploadProgress(0);
        setUploadId(undefined);
    };

    const hospitalProgramUploads = async () => {
        const startDateAndEndDate = getQuarterStartAndEndDate(
            measuresDateFilter.year,
            measuresDateFilter.quarter
        );
        if (uploadId) {
            const data = await hospitalProgramUploadsPost(
                uploadId,
                programId,
                categoryId,
                file.name,
                startDateAndEndDate?.split("/")[0],
                startDateAndEndDate?.split("/")[1],
                hospitalId,
                CONSTANTS.INITIATED
            )
                .then((submitResponse: any) => {
                    getCSVFilesList();
                    return submitResponse;
                })
                .catch((error: any) => {
                    logger(error);
                    return error;
                });
            return data;
        }
    };

    const patchHospitalProgramUploads = async (
        hospitalProgramUploadsId: any,
        type: any,
        errorData: any
    ) => {
        await hospitalProgramUploadsPatch(
            hospitalProgramUploadsId,
            type,
            errorData
        );
    };

    const HospitalProgramMeasureMetrics = async () => {
        const startDateAndEndDate = getQuarterStartAndEndDate(
            measuresDateFilter.year,
            measuresDateFilter.quarter,
            true
        );
        if (startDateAndEndDate) {
            const data: any = getHospitalProgramMeasureMetrics(
                hospitalId,
                programId,
                categoryId,
                startDateAndEndDate?.split("/")[0],
                startDateAndEndDate?.split("/")[1]
            )
                .then(async (submitResponse: any) => {
                    if (submitResponse.success === true) {
                        return submitResponse;
                    }
                })
                .catch(async (error: any) => {
                    logger(error);
                    return error;
                });
            return data;
        }
    };

    const readS3CSVFile = async (csv: any) => {
        const data = emsMeasureUploads(
            measeureSelectedYear,
            csv.upload.region,
            csv.upload.filePath,
            hospitalId,
            programId,
            categoryId
        )
            .then(async (submitResponse: any) => {
                return submitResponse;
            })
            .catch(async (error: any) => {
                logger(error);
                return error;
            });
        return data;
    };

    const saveEmsUploadHospitalMeasure = async (
        measuresData: any,
        year: any,
        type: any,
        validationType: any
    ) => {
        const data = saveEmsHospitalMeasure(
            hospitalId,
            programId,
            categoryId,
            measuresData,
            year,
            type,
            validationType
        )
            .then(async (submitResponse: any) => {
                return submitResponse;
            })
            .catch(async (error: any) => {
                logger(error);
                return error;
            });
        return data;
    };

    const waitThenRefreshTable = async () => {
        for (let i = 0; i < 4; i++) {
            await sleep(3000); // Uploaded Measure CSV files takes time to process in Backedn So, Waiting for 3 seconds then triggering refresh table.
            getCSVFilesList();
        }
        setRoleValue("alert");
    };

    const downloadFile = (documentId: string | number) => {
        downloadFileFromS3(documentId, Number(hospitalId));
    };

    const clearFileSeleection = () => {
        if (uploadId) {
            deleteUploadedDoc(uploadId).then((response: any) => {
                if (response.success) {
                    setFile(null);
                    setUploadId(undefined);
                    setFileUploadError(false);
                    setFileUploadSuccess(false);
                    setUploadProgress(0);
                }
            });
        }
    };

    const getStatusText = (
        status: string,
        errorDetails: any,
        documentId: number,
        fileName: string
    ) => {
        switch (status) {
            case "INITIATED":
                return "Initiated";
            case "UPLOAD_IN_PROGRESS":
                return "Upload Inprogress";
            case "UPLOADED":
                return "Uploaded";
            case "UPLOAD_FAILED":
                return "Upload Failed";
            case "PROCESSING":
                return "Processing";
            case "PROCESSED":
                return "Success";
            case "PROCESSING_FAILED":
                return (
                    <div className="d-flex">
                        Failed
                        <div className="cst-tooltip ml-2">
                            <button
                                className="btn-text-link-uline font-red tooltip-item"
                                aria-describedby={`failed-${documentId}`}
                                aria-label={`Failed information for ${fileName}`}
                            >
                                <i
                                    className="aha-icon-alert"
                                    aria-hidden="true"
                                />
                            </button>
                            <div
                                className="tooltip tooltip-bottom failed-info-tooltip"
                                role="tooltip"
                                id={`failed-${documentId}`}
                            >
                                <div className="arrow" />
                                <div className="tooltip-inner">
                                    {errorDetails?.error?.details?.[0]?.message}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            default:
                return "Unknown";
        }
    };

    const measureTemplateDownload = () => {
        downloadMeasureTemplate(
            programId,
            categoryId,
            measeureSelectedYear,
            props.countryId
        ).catch((error: any) => {
            logger("Error Download Measure Template:", error);
        });
    };

    const setCsvStatus = (status: any) => {
        switch (status) {
            case "INITIATED":
                return "aui-table-status-pending";
            case "UPLOAD_IN_PROGRESS":
                return "aui-table-status-pending";
            case "UPLOADED":
                return "aui-table-status-success";
            case "UPLOAD_FAILED":
                return "aui-table-status-danger";
            case "PROCESSING":
                return "aui-table-status-pending";
            case "PROCESSED":
                return "aui-table-status-success";
            case "PROCESSING_FAILED":
                return "aui-table-status-danger";
            default:
                return "aui-table-status-pending";
        }
    };

    return (
        <UploadSpreadsheet>
            <h3 className="h4 mb-4 upload-title">Upload Spreadsheet</h3>
            <div className="aui-drag-section">
                <div className="aui-drag-outline mb-3">
                    <div className="aui-drag-area d-flex align-items-center justify-content-center">
                        {/* File selection area, if file is not selected then show, if file selected then hide */}
                        {file === null && (
                            <div className="d-flex justify-content-center align-items-center flex-column w-100 pb-4">
                                <i
                                    className="aha-icon-cloud-upload aui-drag-upload-icon mb-3 ml-n3"
                                    aria-hidden="true"
                                />
                                <div className="aui-btn-files">
                                    <input
                                        id="upload-file"
                                        type="file"
                                        name="uploadfile"
                                        onChange={onFileChange}
                                        accept=".csv"
                                    />
                                    <label
                                        htmlFor="upload-file"
                                        className="btn btn-round btn-primary m-0"
                                        aria-label="Browse file to upload spreadsheet"
                                    >
                                        Browse Files
                                    </label>
                                </div>
                            </div>
                        )}
                        {/* File uploading view, if file is selected then show */}
                        {file && (
                            <div className="d-flex justify-content-center align-items-center flex-column aui-uploading-files row w-100">
                                <div className="col-12">
                                    <div className="h8 font-600 mb-1">
                                        {file.name}
                                    </div>
                                    <div className="mb-3">
                                        File Size: {getFIleSize(file.size)}
                                    </div>
                                    <div className="aui-progress-block mb-4">
                                        <div className="d-flex w-100">
                                            <div className="progress flex-grow-1">
                                                {/* if failure use class progress-failure  */}
                                                <div
                                                    className={
                                                        fileUploadError
                                                            ? "progress-bar progress-failure"
                                                            : "progress-bar"
                                                    }
                                                    role="progressbar"
                                                    id="inProgess"
                                                    style={{
                                                        width: `${uploadProgress}%`,
                                                    }}
                                                    aria-valuenow={
                                                        uploadProgress
                                                    }
                                                    aria-valuemin={0}
                                                    aria-valuemax={100}
                                                    aria-describedby="progressInfo"
                                                >
                                                    <span
                                                        aria-atomic="true"
                                                        role="alert"
                                                        aria-hidden="true"
                                                    >
                                                        {uploadProgress}%
                                                    </span>
                                                </div>
                                            </div>
                                            {/* if file is selected show Abort file upload button */}
                                            {fileUploadSuccess && (
                                                <button
                                                    type="button"
                                                    className="btn btn-text aha-icon-cross ml-2"
                                                    aria-label={`Remove uploaded spreadsheet ${file.name}`}
                                                    onClick={() =>
                                                        clearFileSeleection()
                                                    }
                                                    disabled={isSubmitProgress}
                                                />
                                            )}
                                        </div>
                                        {/* if fileUploadError is true then show proper error message */}
                                        {fileUploadError && (
                                            <div
                                                className="aui-progress-fail-msg focusable-element"
                                                id="progressInfo"
                                                role="alert"
                                                aria-live="assertive"
                                            >
                                                <span className="sr-only">
                                                    Error:
                                                </span>
                                                {
                                                    CONSTANTS.FILE_UPLOAD_ERROR_MSG
                                                }
                                            </div>
                                        )}
                                    </div>
                                    {/* if fileUploadError is true then show upload-again button */}
                                    {fileUploadError && (
                                        <div className="aui-btn-files">
                                            <input
                                                id="upload-fileagain"
                                                type="file"
                                                name="uploadfile"
                                                onChange={onFileChange}
                                            />
                                            <label
                                                htmlFor="upload-fileagain"
                                                className="btn btn-round btn-primary m-0"
                                                aria-label="Upload spreadsheet again"
                                            >
                                                Upload Again
                                            </label>
                                        </div>
                                    )}
                                    {/* if fileUploadSuccess is true then show submit button */}
                                    {fileUploadSuccess && (
                                        <button
                                            type="button"
                                            className={`btn btn-round btn-primary d-inline-block ${
                                                isSubmitProgress
                                                    ? CONSTANTS.BUTTON_SPINNER
                                                    : ""
                                            }`}
                                            onClick={submit}
                                            disabled={isSubmitProgress}
                                            aria-label="Submit uploaded spreadsheet"
                                        >
                                            Submit
                                        </button>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="drag-custom justify-content-between d-flex flex-sm-row flex-column pt-3">
                    <button
                        onClick={measureTemplateDownload}
                        className="upload-custom mb-2 mb-sm-0 btn-text-link-uline p-0"
                        aria-label="Download Template to upload measures"
                    >
                        {" "}
                        <img
                            src="/images/excel.svg"
                            className="mr-2"
                            alt="excel"
                            aria-hidden="true"
                        />
                        Download Template
                    </button>
                </div>
            </div>
            <div
                aria-atomic="true"
                role="alert"
                aria-live="polite"
                className="sr-only"
            >
                {file === null
                    ? ""
                    : fileUploadInd &&
                      fileUploadSuccess &&
                      `file uploaded ${uploadProgress}%`}
            </div>
            <>
                <h3 className="h4 pt-5 mb-3 pb-3">Uploaded Spreadsheet</h3>
                <TableRowCount
                    isFirst={pagination.isFirst}
                    pageNumber={pagination.pageNumber}
                    pageSize={pagination.pageSize}
                    isLast={pagination.isLast}
                    totalCount={pagination.totalCount}
                    roleValue={roleValue}
                    measureInd
                />
                <div className="uploaded-table mt-3">
                    <table className="aui-responsive-status-table">
                        <thead>
                            <tr>
                                <th scope="col">
                                    <div className="aui-th">Date</div>
                                </th>
                                <th scope="col">
                                    <div className="aui-th">File</div>
                                </th>
                                <th scope="col">
                                    <div className="aui-th">Status</div>
                                </th>
                                <th scope="col">
                                    <div className="aui-th justify-content-center">
                                        Action
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {uploadedCSVFiles.length > 0 &&
                                uploadedCSVFiles.map((csvFile: any) => (
                                    <tr
                                        key={csvFile.documentId}
                                        className={setCsvStatus(csvFile.status)}
                                    >
                                        <td data-title="Date">
                                            <div className="aui-td">
                                                {localDateToYYYYMMDDFormatString(
                                                    csvFile.createdAt
                                                )}
                                            </div>
                                        </td>
                                        <td data-title="File">
                                            <div className="aui-td">
                                                {csvFile.fileName}
                                            </div>
                                        </td>
                                        {/* Use class 'font-green' and 'font-red-darker' for success and failure */}
                                        <td
                                            data-title="Status"
                                            className="uploaded-status"
                                        >
                                            <div className="aui-td">
                                                <div
                                                    className={`${
                                                        csvFile.status ===
                                                        "PROCESSED"
                                                            ? "font-green"
                                                            : ""
                                                    } ${
                                                        csvFile.status ===
                                                        "PROCESSING_FAILED"
                                                            ? "font-red"
                                                            : ""
                                                    }`}
                                                >
                                                    {getStatusText(
                                                        csvFile.status,
                                                        csvFile.errorDetails,
                                                        csvFile.documentId,
                                                        csvFile.fileName
                                                    )}
                                                </div>
                                            </div>
                                        </td>
                                        <td
                                            data-title="Action"
                                            className="uploaded-action"
                                        >
                                            <div className="aui-td justify-content-lg-center">
                                                <div className="cst-tooltip">
                                                    <button
                                                        type="button"
                                                        className="btn-text-link-uline font-red px-0 tooltip-item"
                                                        onClick={() =>
                                                            downloadFile(
                                                                csvFile.documentId
                                                            )
                                                        }
                                                        aria-label={`${csvFile.fileName}`}
                                                        aria-describedby={`download${csvFile.documentId}`}
                                                    >
                                                        <i
                                                            className="aha-icon-download"
                                                            aria-hidden="true"
                                                        />
                                                    </button>
                                                    <div
                                                        className="tooltip tooltip-bottom measure-download-tooltip"
                                                        role="tooltip"
                                                        id={`download${csvFile.documentId}`}
                                                    >
                                                        <div className="arrow" />
                                                        <div className="tooltip-inner">
                                                            Download
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                    <div
                        role="alert"
                        aria-atomic="true"
                        className="table-emptystate-outer"
                    >
                        {uploadedCSVFiles.length < 1 && (
                            <div>No data to display</div>
                        )}
                    </div>
                </div>
                {pagination && Number(pagination.totalPages) > 0 ? (
                    <Pagination
                        pageNumber={pagination.pageNumber}
                        pageSize={pagination.pageSize}
                        totalCount={pagination.totalCount}
                        totalPages={pagination.totalPages}
                        isFirst={pagination.isFirst}
                        isLast={pagination.isLast}
                        setPageNumber={pagination.setPageNumber}
                        paginationSetLimit={paginationSetLimit}
                    />
                ) : null}
            </>
        </UploadSpreadsheet>
    );
};

export default MeasuresCSVUploadPage;
