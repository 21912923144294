import APIUtils from "utils/API.utils";
import config from "../config";

const API = new APIUtils();
export const getArchivalAccordionDatesAPI = async (
    hospitalId: any
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const apiEndpoint = `${
                config[config.env].apiEndpoints.accounts
            }/archives/hospitals/${hospitalId}/archiveDates`;
            const APIRes = await API.GET(apiEndpoint);
            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (errorData) {
            reject(errorData);
        }
    });
};

export const getArchivalDocumentAPI = async (
    hospitalId: any,
    archiveDate: any,
    sortByDate?: any,
    standardId?: any,
    approvalStatus?: any,
    pageNumber: Number = 1
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            let apiEndpoint = `${
                config[config.env].apiEndpoints.accounts
            }/archives/hospitals/${hospitalId}?archiveType=DOCUMENTS&archiveDate=${archiveDate}&pageNumber=${pageNumber}&pageSize=${10}`;
            if (sortByDate && sortByDate !== "") {
                apiEndpoint += `&orderBy=documentCreatedAt,${sortByDate}`;
            }
            if (standardId && standardId !== -1) {
                apiEndpoint += `&standardId=${standardId}`;
            }
            if (approvalStatus && approvalStatus !== "All") {
                apiEndpoint += `&approvalStatus=${approvalStatus}`;
            }
            const APIRes = await API.GET(apiEndpoint);
            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (errorData) {
            reject(errorData);
        }
    });
};

export const getArchivalCertificateAPI = async (
    hospitalId: any,
    archiveDate: any
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const apiEndpoint = `${
                config[config.env].apiEndpoints.accounts
            }/archives/hospitals/${hospitalId}?archiveType=CERTIFICATE&archiveDate=${archiveDate}`;
            const APIRes = await API.GET(apiEndpoint);
            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (errorData) {
            reject(errorData);
        }
    });
};

export const getArchivalDocumentViewApi = async (
    hospitalId: any,
    referenceId: any
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const apiEndpoint = `${
                config[config.env].apiEndpoints.accounts
            }/archives/hospitals/${hospitalId}/reference/${referenceId}`;
            const APIRes = await API.GET(apiEndpoint);
            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (errorData) {
            reject(errorData);
        }
    });
};
