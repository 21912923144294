import React from "react";
import { ProgressWrapper } from "./styled";

export default function Progress() {
    return (
        <ProgressWrapper>
            <div className="container d-flex flex-column">
                <h5 className="text-center h5 font-normal progress-title">
                    Pulling all of your saved details from last login....
                </h5>
                <ul className="progress-list d-flex mx-sm-auto flex-md-nowrap flex-wrap flex-column flex-sm-row align-items-center">
                    {/* Add class 'prg-complete' in li tag for animation */}
                    <li className="progress-item d-flex align-items-center flex-column ml-md-0 mb-md-0 mb-5 ">
                        <div className="prg-icon d-flex justify-content-center align-items-center">
                            <i className="aha-icon-profile-android" />
                        </div>
                        <div className="prg-spin">
                            <span className="prg-checkmark" />
                        </div>
                        <span className="text-center text-nowrap">Sign In</span>
                    </li>
                    <li className="progress-item d-flex align-items-center flex-column mb-md-0 mb-5">
                        <div className="prg-icon d-flex justify-content-center align-items-center">
                            <i className="aha-icon-all" />
                        </div>
                        <div className="prg-spin">
                            <span className="prg-checkmark" />
                        </div>
                        <span className="text-center text-nowrap">
                            Hospital Registration
                        </span>
                    </li>
                    <li className="progress-item d-flex align-items-center flex-column mb-md-0 mb-5">
                        <div className="prg-icon d-flex justify-content-center align-items-center">
                            <i className="aha-icon-instagram" />
                        </div>
                        <div className="prg-spin">
                            <span className="prg-checkmark" />
                        </div>
                        <span className="text-center text-nowrap">
                            Program Selection
                        </span>
                    </li>
                    <li className="progress-item d-flex align-items-center flex-column mr-md-0 mb-md-0 mb-5">
                        <div className="prg-icon d-flex justify-content-center align-items-center">
                            <i className="aha-icon-cart" />
                        </div>
                        <div className="prg-spin">
                            <span className="prg-checkmark" />
                        </div>
                        <span className="text-center text-nowrap">Payment</span>
                    </li>
                </ul>
            </div>
        </ProgressWrapper>
    );
}
