import styled from "styled-components";

export const EMSMeasuresWrapper = styled.div`
    .ems-measure-table {
        tr {
            td {
                .aui-td {
                    height: 100%;
                }
                .ems-measure-label {
                    height: 48px;
                    padding-top: 8px;
                }
                .ems-measure-field {
                    height: 32px;
                    margin-bottom: 14px;
                    @media only screen and (min-width: 992px) {
                        text-align: center;
                    }
                }
                .ems-measure-value {
                    height: 32px;
                    margin-bottom: 14px;
                    padding-top: 4px;
                    @media only screen and (min-width: 992px) {
                        text-align: center;
                    }
                }
            }
        }
        thead {
            @media only screen and (min-width: 992px) {
                th:nth-child(1) {
                    width: 110px;
                }
                th:nth-child(2) {
                    width: 150px;
                }
                th:nth-child(3) {
                    width: auto;
                }
                th:nth-child(4) {
                    width: 66px;
                }
                th:nth-child(5) {
                    width: 90px;
                }
                th:nth-child(6) {
                    width: 90px;
                }
                th:nth-child(7) {
                    width: 90px;
                }
                th:nth-child(8) {
                    width: 90px;
                }
            }
            @media only screen and (min-width: 1200px) {
                th:nth-child(1) {
                    width: 130px;
                }
                th:nth-child(2) {
                    width: 165px;
                }
                th:nth-child(3) {
                    width: auto;
                }
                th:nth-child(4) {
                    width: 66px;
                }
                th:nth-child(5) {
                    width: 95px;
                }
                th:nth-child(6) {
                    width: 95px;
                }
                th:nth-child(7) {
                    width: 95px;
                }
                th:nth-child(8) {
                    width: 95px;
                }
            }
        }
        tbody {
            tr {
                td {
                    .ems-measure-block {
                        height: calc(100% - 16px);
                        padding: 8px 12px;
                        margin-top: 8px;
                    }
                    .ems-measure-bg {
                        background-color: #f8f8f8;
                    }
                }
            }
        }
        .ems-measure-subheading-tr {
            margin: 5px 0;
            border-top: 6px solid #fff;
            @media only screen and (min-width: 992px) {
                margin: 0;
            }
            td {
                padding: 2px 0;
                border: none;
                &:before {
                    display: none;
                }
            }
        }
        .ems-measure-subheading-tr + tr {
            border-top-width: 6px;
        }
        .ems-measure-subheading {
            background-color: #c10e21;
            color: #fff;
            padding: 8px;
            font-weight: 500;
            font-size: 16px;
            width: 100%;
            text-align: center;
        }
        table + table {
            thead {
                background: none;
                tr {
                    th {
                        padding: 0;
                        font-size: 0;
                        .aui-th {
                            padding: 0;
                            border: 0;
                        }
                    }
                }
            }
        }
    }
    .ems-measure-help {
        color: #c10e21;
        font-size: 14px;
        margin: -4px 0 0 2px;
    }
    .ems-measure-star {
        margin-left: 1px;
        font-size: 18px;
    }
    .ems-measure-table-btn {
        margin: 20px 0;
        .ems-measure-btn {
            width: 100%;
            @media only screen and (min-width: 576px) {
                width: 49%;
            }
            @media only screen and (min-width: 768px) {
                width: auto;
            }
        }
        .ems-measure-sub {
            width: 100%;
            margin-bottom: 20px;
            @media only screen and (min-width: 576px) {
                width: 50%;
                margin-bottom: 0;
            }
            @media only screen and (min-width: 768px) {
                width: auto;
            }
        }
    }
    .ems-measure-per {
        font-weight: 600;
        @media only screen and (min-width: 992px) {
            text-align: center;
        }
    }
    .submit-measures-btn {
        width: 100%;
        @media only screen and (min-width: 576px) {
            width: 49%;
        }
        @media only screen and (min-width: 768px) {
            width: auto;
        }
        .btn {
            width: 100%;
        }
        .ems-measure-tooltip {
            width: 300px;
            left: auto;
            right: 0;
            &:before,
            &:after {
                left: 80%;
            }
        }
    }
`;
