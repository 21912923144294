import styled from "styled-components";

export const HospitalDetailWrapper = styled.div`
    .hospital-detail-header {
        background-color: #c10e21;
        padding: 14px 27px 19px 30px;
        margin-bottom: 24px;
        * {
            color: #fff;
        }
        i {
            cursor: pointer;
        }
        @media only screen and (min-width: 768px) {
            margin-bottom: 40px;
        }
    }
    .hospital-detail-table {
        table {
            tbody {
                tr {
                    @media only screen and (min-width: 576px) {
                        .aui-row-th {
                            width: 50%;
                        }
                        .aui-row-td {
                            width: 50%;
                        }
                    }
                    @media only screen and (min-width: 768px) {
                        .aui-row-th {
                            width: 40%;
                        }
                        .aui-row-td {
                            width: 60%;
                        }
                    }
                    @media only screen and (min-width: 992px) {
                        .aui-row-th {
                            width: 45%;
                        }
                        .aui-row-td {
                            width: 55%;
                        }
                    }
                }
                tr {
                    tr:last-child {
                        border-bottom: 0;
                    }
                }
            }
        }
    }
    .hospital-detail-btn {
        padding: 0;
        > *,
        .btn {
            width: 100%;
            margin-bottom: 26px;
            display: block;
            padding-left: 60px;
            padding-right: 60px;
        }
        @media only screen and (min-width: 768px) {
            padding: 60px 0;
            > *,
            .btn {
                width: auto;
                margin-bottom: 0;
                display: inline-block;
            }
        }
        .hospital-dtl-approve {
            @media only screen and (min-width: 768px) {
                margin-right: 36px;
            }
        }
    }
    .hospital-title-border {
        border-bottom: 3px solid #c10e21;
    }
`;
