import store from "app/store";
import { showToast } from "components/Toast/toast.slice";
import APIUtils from "utils/API.utils";
import CONSTANTS from "common/constants";
import config from "../config";

const API = new APIUtils();

export const getAssetDetails = async (
    referenceId: any,
    getPath: any = false
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const APIRes = await API.GET(
                `${
                    config[config.env].apiEndpoints.documents
                }/assets?referenceId=${referenceId}&getPath=${getPath}`
            );
            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const getAwardsAndBrandingMaterials = async (
    programId: any,
    categoryId: any,
    countryId: any
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const APIRes = await API.GET(
                `${
                    config[config.env].apiEndpoints.catalogs
                }/certificates/programs/${programId}/categories/${categoryId}?countryId=${countryId}`
            );

            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const saveCertificate = async (
    certificateInfo: any,
    brandingMaterials: any,
    hospitalId: any,
    programId: any,
    categoryId: any,
    certificateImage: any,
    countryId: any,
    isCertificateYearExists: boolean,
    selectedYear: any
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            brandingMaterials.map((material: any) => {
                delete material.assetName;
            });

            const reqBody: any = {
                facilityName: certificateInfo.facilityName?.slice(
                    0,
                    CONSTANTS.CERTIFICATE_FACILITY_NAME_LIMIT
                ),
                certificateName: certificateInfo.award,
                city: certificateInfo.city,
                state: certificateInfo.state,
                country: certificateInfo.countryName,
                grantedOn: certificateInfo.grantedOn,
                expiresOn: certificateInfo.expiryOn,
                certificateImage,
                brandingMaterials,
                countryId,
            };
            if (isCertificateYearExists) {
                reqBody.certificateYear = selectedYear;
            }

            const method = !certificateInfo.programCertificateId
                ? "POST"
                : "PATCH";
            let APIRes;

            if (method === "POST") {
                APIRes = await API.POST(
                    `${
                        config[config.env].apiEndpoints.accounts
                    }/certificates/hospitals/${hospitalId}/programs/${programId}/categories/${categoryId}`,
                    reqBody
                );
            }

            if (method === "PATCH") {
                APIRes = await API.PATCH(
                    `${
                        config[config.env].apiEndpoints.accounts
                    }/certificates/hospitals/${hospitalId}/programs/${programId}/categories/${categoryId}`,
                    reqBody
                );
            }

            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const getHospitalProgramCertificate = async (
    hospitalId: number,
    programId: number,
    categoryId: number
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const APIRes = await API.GET(
                `${
                    config[config.env].apiEndpoints.accounts
                }/certificates/hospitals/${hospitalId}/programs/${programId}/categories/${categoryId}`
            );
            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};
