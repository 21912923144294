const config: any = {
    env: process.env.REACT_APP_MY_ENV || "dev",
    dev: {
        agreementFilePath: "https://d17zibtpdnrjam.cloudfront.net",
        apiEndpoints: {
            accounts: "https://accounts-qctapis-dev.heartblr.org/accounts",
            catalogs: "https://catalogs-qctapis-dev.heartblr.org/catalogs",
            documents: "https://documents-qctapis-dev.heartblr.org/documents",
            payments: "https://payments-qctapis-dev.heartblr.org/payments",
            strapi: "https://qct-dev-strapi.heartblr.org/api/qct",
        },
        featureConfig: {
            certification: "ON",
            registry: "ON",
        },
    },
    qa: {
        agreementFilePath: "https://d17zibtpdnrjam.cloudfront.net",
        apiEndpoints: {
            accounts: "https://accounts-qctapis-qa.heartblr.org/accounts",
            catalogs: "https://catalogs-qctapis-qa.heartblr.org/catalogs",
            documents: "https://documents-qctapis-qa.heartblr.org/documents",
            payments: "https://payments-qctapis-qa.heartblr.org/payments",
            strapi: "https://qct-dev-strapi.heartblr.org/api/qct",
        },
        featureConfig: {
            certification: "ON",
            registry: "ON",
        },
    },
    test: {
        agreementFilePath: "https://d17zibtpdnrjam.cloudfront.net",
        apiEndpoints: {
            accounts:
                "https://d5wgc2koyd.execute-api.us-east-1.amazonaws.com/test/accounts",
            catalogs:
                "https://mitn8m2px3.execute-api.us-east-1.amazonaws.com/test/catalogs",
            documents:
                "https://nv8iho7010.execute-api.us-east-1.amazonaws.com/test/documents",
            payments:
                "https://f7bdmavp8a.execute-api.us-east-1.amazonaws.com/test/payments",
        },
        featureConfig: {
            certification: "ON",
            registry: "ON",
        },
    },
    stg: {
        agreementFilePath: "https://d17zibtpdnrjam.cloudfront.net",
        apiEndpoints: {
            accounts: "https://accounts-qctapis-stg.heartblr.org/accounts",
            catalogs: "https://catalogs-qctapis-stg.heartblr.org/catalogs",
            documents: "https://documents-qctapis-stg.heartblr.org/documents",
            payments: "https://payments-qctapis-stg.heartblr.org/payments",
            strapi: "https://qct-dev-strapi.heartblr.org/api/qct",
        },
        featureConfig: {
            certification: "ON",
            registry: "ON",
        },
    },
    nfr: {
        agreementFilePath: "https://d17zibtpdnrjam.cloudfront.net",
        apiEndpoints: {
            accounts: "https://accounts-qctapis-nfr.heartblr.org/accounts",
            catalogs: "https://catalogs-qctapis-nfr.heartblr.org/catalogs",
            documents: "https://documents-qctapis-nfr.heartblr.org/documents",
            payments: "https://payments-qctapis-nfr.heartblr.org/payments",
            strapi: "https://qct-dev-strapi.heartblr.org/api/qct",
        },
        featureConfig: {
            certification: "ON",
            registry: "ON",
        },
    },
    preprod: {
        agreementFilePath: "https://d17zibtpdnrjam.cloudfront.net",
        apiEndpoints: {
            accounts: "https://accounts-qctapis-preprod.heart.org/accounts",
            catalogs: "https://catalogs-qctapis-preprod.heart.org/catalogs",
            documents: "https://documents-qctapis-preprod.heart.org/documents",
            payments: "https://payments-qctapis-preprod.heart.org/payments",
            strapi: "https://qct-dev-strapi.heartblr.org/api/qct",
        },
        featureConfig: {
            certification: "ON",
            registry: "ON",
        },
    },
    prod: {
        agreementFilePath: "https://d17zibtpdnrjam.cloudfront.net",
        apiEndpoints: {
            accounts: "https://accounts-api.qct.heart.org/accounts",
            catalogs: "https://catalogs-api.qct.heart.org/catalogs",
            documents: "https://documents-api.qct.heart.org/documents",
            payments: "https://payments-api.qct.heart.org/payments",
            strapi: "https://qct-strapi.heart.org/api/qct",
        },
        featureConfig: {
            certification: "ON",
            registry: "ON",
        },
    },
    dr: {
        agreementFilePath: "https://d17zibtpdnrjam.cloudfront.net",
        apiEndpoints: {
            accounts: "https://accounts-qctapis-dr.heart.org/accounts",
            catalogs: "https://catalogs-qctapis-dr.heart.org/catalogs",
            documents: "https://documents-qctapis-dr.heart.org/documents",
            payments: "https://payments-qctapis-dr.heart.org/payments",
            strapi: "https://qct-strapi.heart.org/api/qct",
        },
        featureConfig: {
            certification: "ON",
            registry: "ON",
        },
    },
    local: {
        agreementFilePath: "https://d17zibtpdnrjam.cloudfront.net",
        apiEndpoints: {
            accounts: "http://localhost:3001/accounts",
            catalogs: "http://localhost:3002/catalogs",
            documents: "http://localhost:3003/documents",
            payments: "http://localhost:3004/payments",
            strapi: "https://qct-dev-strapi.heartblr.org/api/qct",
        },
        featureConfig: {
            certification: "ON",
            registry: "ON",
        },
    },
};

export default config;
