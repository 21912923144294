import React from "react";
import { Link } from "react-router-dom";

interface Props {
    registryActiveNav: string;
}

const RegistrySidebar = ({ registryActiveNav }: Props) => {
    return (
        <div className="aui-sidenav-plane setting-sidebar">
            <nav className="navbar-expand-lg overflow-hidden">
                <button
                    className="navbar-toggler float-right m-3"
                    type="button"
                    data-toggle="collapse"
                    data-target="#sideNavbar2"
                    aria-controls="sideNavbar2"
                    aria-expanded="false"
                    aria-label="navigation menu"
                >
                    <i
                        className="aha-icon-hamburger-round"
                        aria-hidden="true"
                    />
                </button>
                <aside className="collapse navbar-collapse" id="sideNavbar2">
                    <ul className="w-100">
                        <li
                            className={
                                registryActiveNav == "registrySettingProfile"
                                    ? "active"
                                    : ""
                            }
                        >
                            <Link
                                to="/registry/settings/profile"
                                className="active"
                                aria-current={
                                    registryActiveNav ==
                                    "registrySettingProfile"
                                        ? "page"
                                        : false
                                }
                            >
                                Profile
                            </Link>
                        </li>
                    </ul>
                </aside>
            </nav>
        </div>
    );
};

export default RegistrySidebar;
