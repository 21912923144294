import styled from "styled-components";

export const SearchWrapper = styled.div`
    position: relative;
    width: 100%;
    background-color: #ffffff;
    .search-field {
        height: 40px;
        border: 1px solid #777979;
        width: 100%;
        padding-right: 50px;
        padding-left: 16px;
        border-radius: 2px;
        position: relative;
        z-index: 2;
        background-color: transparent;
    }
    input[type="search"]::-ms-clear {
        display: none;
        width: 0;
        height: 0;
    }
    input[type="search"]::-ms-reveal {
        display: none;
        width: 0;
        height: 0;
    }
    input[type="search"]::-webkit-search-decoration,
    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-results-button,
    input[type="search"]::-webkit-search-results-decoration {
        -webkit-appearance: none;
    }
    .search-clear {
        position: absolute;
        right: 16px;
        top: 11px;
        font-size: 14px;
        color: #222328;
        padding: 2px;
        display: none;
        z-index: 2;
    }
    .search-icon {
        position: absolute;
        right: 16px;
        top: 5px;
        font-size: 20px;
        color: #6d6e71;
        z-index: 1;
    }
    .search-field:not(:placeholder-shown) {
        & + .search-clear {
            display: block;
        }
        & + .search-clear + .search-icon {
            display: none;
        }
    }
`;
