import styled from "styled-components";

export const FacilitiesDetailsWrapper = styled.div`
    .aui-dark-nav {
        margin-bottom: 36px;
    }
    .aui-accordion-arrowcontent {
        margin-top: 36px;
    }
    .facility-details-banner {
        border-color: #e3e3e3;
    }
    .more-actions {
        position: relative;
        height: 40px;
        .tooltip {
            transform: none !important;
            top: -38px !important;
            left: auto !important;
            right: 0;
            &:before {
                left: auto !important;
                right: 8px;
            }
            &:after {
                left: auto !important;
                right: 8px;
            }
            .tooltip-inner {
                width: 105px;
            }
        }
        .menu-cst-select-fld {
            height: auto;
        }
        .menu-cst-select-dropdown {
            margin-top: -10px;
        }
        .menu-cst-selected-item {
            padding: 0;
        }
    }
`;
