import React, { useEffect, useState } from "react";
import { HospitalManagementDetailsWrapper } from "pages/Admin/RegisteredHospitalDetails/styled";
import { useParams, useLocation } from "react-router-dom";
import HospitalMeasuresTab from "pages/Admin/HospitalMeasuresTab/HospitalMeasuresTab";
import HospitalCertificationTab from "pages/Admin/HospitalCertificationTab/HospitalCertificationTab";
import { useDispatch, useSelector } from "react-redux";
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import { getHospitalProgramDetails } from "api/hospitalProgramsAPI";
import { getHospitalById } from "api/hospitalApi";
import { getHospitalProgramMeasureMetrics } from "api/measuresApi";
import { getCurrentQuarter, getQuarterStartAndEndDate } from "common/utils";
import CONSTANTS from "common/constants";
import { BreadCrumb } from "components/BreadCrumb";
import {
    setQuarter,
    setYear,
} from "components/MeasuresDateFilter/measuresDateFilter.slice";
import { getHospitalProgramCertificate } from "../../../api/certificateAPI";
import config from "../../../config";
import { logger } from "../../../utils/logger.utils";
import EMSAdminSidebar from "../Sidebar/Sidebar";
import OrganizationDetails from "../OrganizationDetails/OrganizationDetails";
import OrganizationCertificationTab from "../OrganizationCertificationTab/OrganizationCertificationTab";

export interface IMeasure {
    measureId: number;
    measureName: string;
    description: string;
    numerator: number;
    denominator: number;
}

export const RegisteredEMSHospitalDetails = () => {
    // REDUX variables
    const dispatch = useDispatch();
    const location = useLocation();
    const measuresDateFilter = useSelector((state: any) => {
        return state.measuresDateFilter;
    });

    // ROUTE VARIABLES
    const { hospitalId, programId, categoryId }: any = useParams();
    const facilityName = location.state?.facilityName
        ? location.state?.facilityName
        : null;

    // RFC STATE
    const [hospital, setHospital] = useState<any>({});
    const [programName, setProgramName] = useState<any>();
    const [programData, setProgramData] = useState<any>();
    const [programDataCertificationTab, setProgramDataCertificationTab] =
        useState<any>();
    const [certificateData, setCertificateData] = useState<any>();
    const [returnPath, setReturnPath] = useState<any>(null);
    const [isMeasureTableLoading, setIsMeasureTableLoading] =
        useState<boolean>(false);
    const [breadcrumbItems, setBreadcrumbItems] = useState<any>([]);
    const [hospitalMeasureMetrics, setHospitalMeasureMetrics] = useState<any>();
    const [measuresInd, setMeasuresInd] = useState<boolean>(false);
    const [documentsInd, setDocumentsInd] = useState<boolean>(false);
    const [certificateInd, setCertificateInd] = useState<boolean>(false);
    const [detailsInd, setDetailsInd] = useState<boolean>(false);

    useEffect(() => {
        getFacilityDetails();
        const elementsArray: any = [];
        let pathToReturn: any;
        if (location?.state?.backToOrganizationManagement) {
            pathToReturn = {
                pathname: location?.state?.backToOrganizationManagement,
                state: {
                    adminOrganizationMangemnetTab:
                        location?.state?.adminOrganizationMangemnetTab,
                    adminOrganizationMangemnetPage:
                        location?.state?.adminOrganizationMangemnetPage,
                    pageSort: location?.state?.pageSort,
                },
            };
            setReturnPath(pathToReturn);
            elementsArray.push(
                {
                    label:
                        location?.state?.adminOrganizationMangemnetTab ==
                        CONSTANTS.CERTIFIED
                            ? "Awarded"
                            : "Registered",
                    returnPath: pathToReturn,
                },
                {
                    label: facilityName,
                    returnPath: pathToReturn,
                }
            );
            setBreadcrumbItems(elementsArray);
        }
    }, []);

    const getFacilityDetails = () => {
        dispatch(setPageLoadingStatus({ isPageLoading: true }));
        const startDateAndEndDate = getQuarterStartAndEndDate(
            measuresDateFilter.year,
            measuresDateFilter.quarter,
            true
        );
        if (startDateAndEndDate) {
            Promise.all([
                getHospitalById(hospitalId),
                getHospitalProgramDetails(programId, hospitalId),
                getHospitalProgramCertificate(
                    hospitalId,
                    programId,
                    categoryId
                ),
            ])
                .then((response: any) => {
                    if (
                        response[0].success &&
                        response[1].success &&
                        response[2].success
                    ) {
                        setHospital(response[0].data.hospital);
                        setProgramName(response[1].data.programName);
                        setProgramData(response[1].data);
                        const emsData = {
                            emsId: response[1].data.emsId,
                            verifiedBy: response[1].data.verifiedBy,
                            approvedDate: response[1].data.approvedDate,
                        };
                        setProgramDataCertificationTab(emsData);
                        setCertificateData(response[2].data.programCertficate);
                        setDetailsInd(false);
                        dispatch(
                            setPageLoadingStatus({ isPageLoading: false })
                        );
                    }
                })
                .catch(() => {
                    setDetailsInd(false);
                    dispatch(setPageLoadingStatus({ isPageLoading: true }));
                });
        }
    };

    useEffect(() => {
        if (detailsInd) {
            getFacilityDetails();
        }
    }, [detailsInd]);

    useEffect(() => {
        (async () => {
            setIsMeasureTableLoading(true);
            const startDateAndEndDate = getQuarterStartAndEndDate(
                measuresDateFilter.year,
                measuresDateFilter.quarter,
                true
            );
            if (startDateAndEndDate) {
                setHospitalMeasureMetrics({
                    ...hospitalMeasureMetrics,
                    measuresData: [],
                    metricsData: [],
                });
                Promise.all([
                    getHospitalProgramMeasureMetrics(
                        hospitalId,
                        programId,
                        categoryId,
                        startDateAndEndDate?.split("/")[0],
                        startDateAndEndDate?.split("/")[1]
                    ),
                ])
                    .then((response: any) => {
                        setIsMeasureTableLoading(false);
                        if (response[0].statusCode == 200) {
                            setHospitalMeasureMetrics(response[0].data);
                        }
                    })
                    .catch((err) => {
                        logger(err);
                    });
            }
        })();
    }, [measuresDateFilter]);

    useEffect(() => {
        if (measuresInd) {
            dispatch(setPageLoadingStatus({ isPageLoading: true }));
            setIsMeasureTableLoading(true);
            dispatch(setYear(new Date().getFullYear()));
            dispatch(setQuarter(getCurrentQuarter()));
            const startDateAndEndDate = getQuarterStartAndEndDate(
                new Date().getFullYear(),
                getCurrentQuarter(),
                true
            );
            if (startDateAndEndDate) {
                setHospitalMeasureMetrics({
                    ...hospitalMeasureMetrics,
                    measuresData: [],
                    metricsData: [],
                });
                Promise.all([
                    getHospitalById(hospitalId),
                    getHospitalProgramMeasureMetrics(
                        hospitalId,
                        programId,
                        categoryId,
                        startDateAndEndDate?.split("/")[0],
                        startDateAndEndDate?.split("/")[1]
                    ),
                ])
                    .then((response: any) => {
                        setIsMeasureTableLoading(false);
                        setMeasuresInd(false);
                        if (response[0].success && response[1].success) {
                            setHospital(response[0].data.hospital);
                            setHospitalMeasureMetrics(response[1].data);
                        }
                        dispatch(
                            setPageLoadingStatus({ isPageLoading: false })
                        );
                    })
                    .catch(() => {
                        setMeasuresInd(false);
                        dispatch(
                            setPageLoadingStatus({ isPageLoading: false })
                        );
                    });
            }
        }
    }, [measuresInd]);

    useEffect(() => {
        if (documentsInd || certificateInd) {
            dispatch(setPageLoadingStatus({ isPageLoading: true }));
            Promise.all([getHospitalById(hospitalId)])
                .then((response: any) => {
                    setDocumentsInd(false);
                    setCertificateInd(false);
                    if (response[0].success && response[1].success) {
                        setHospital(response[0].data.hospital);
                    }
                    dispatch(setPageLoadingStatus({ isPageLoading: false }));
                })
                .catch(() => {
                    setDocumentsInd(false);
                    setCertificateInd(false);
                    dispatch(setPageLoadingStatus({ isPageLoading: false }));
                });
        }
    }, [documentsInd, certificateInd]);

    return (
        <HospitalManagementDetailsWrapper className="container-fluid p-0 d-flex flex-grow-1">
            <div className="row no-gutters w-100 flex-grow-1">
                <EMSAdminSidebar activeTab="organizationManagement" />

                <section className="d-flex admin-main-content container select-text">
                    <div className="row no-gutters w-100 admin-main">
                        <BreadCrumb breadcrumbItems={breadcrumbItems} />
                        <div className="col-12 navbar-expand-lg">
                            <div className="hospital-mgnt-header d-sm-flex justify-content-between align-items-center mb-4">
                                <h1 className="h2 mb-0 font-400">
                                    Organization Management
                                </h1>
                                <button
                                    type="button"
                                    className="aui-navbar-toggler navbar-toggler btn btn-round btn-secondary"
                                    data-toggle="collapse"
                                    data-target="#navbarManagement"
                                    aria-controls="navbarManagement"
                                    aria-expanded="false"
                                    aria-label="Menu Toggle navigation"
                                >
                                    Menu
                                </button>
                            </div>
                            <div className="aui-p-tabs" role="tablist">
                                <div
                                    id="navbarManagement"
                                    className="collapse navbar-collapse"
                                >
                                    <ul className="nav nav-tabs mb-4">
                                        <li className="nav-item">
                                            <a
                                                className="nav-link active"
                                                data-toggle="tab"
                                                href="#detailstab"
                                                role="tab"
                                                onClick={() =>
                                                    setDetailsInd(true)
                                                }
                                            >
                                                Details
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a
                                                className="nav-link"
                                                data-toggle="tab"
                                                href="#measurestab"
                                                role="tab"
                                                onClick={() =>
                                                    setMeasuresInd(true)
                                                }
                                            >
                                                Measures
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a
                                                className="nav-link"
                                                data-toggle="tab"
                                                href="#certificationtab"
                                                role="tab"
                                            >
                                                Award
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="tab-content">
                                    <div
                                        id="detailstab"
                                        className="tab-pane active py-lg-3 fac-detail-tab"
                                        role="tabpanel"
                                    >
                                        <OrganizationDetails
                                            hospital={hospital}
                                            returnPath={returnPath}
                                            programData={programData}
                                            certificateData={certificateData}
                                            updateHospitalInfo={(
                                                updatedHospitalInfo: any
                                            ) => {
                                                setHospital(
                                                    updatedHospitalInfo
                                                );
                                            }}
                                        />
                                    </div>
                                    <div
                                        id="measurestab"
                                        className="tab-pane fade py-lg-3"
                                        role="tabpanel"
                                    >
                                        <HospitalMeasuresTab
                                            hospital={hospital}
                                            programName={programName}
                                            hospitalMeasures={
                                                hospitalMeasureMetrics
                                            }
                                            isMeasureTableLoading={
                                                isMeasureTableLoading
                                            }
                                            programData={programData}
                                            certificateData={certificateData}
                                            emsAdminInd
                                        />
                                    </div>
                                    <div
                                        id="certificationtab"
                                        className="tab-pane fade py-lg-3"
                                        role="tabpanel"
                                    >
                                        <OrganizationCertificationTab
                                            hospital={hospital}
                                            programName={programName}
                                            programData={
                                                programDataCertificationTab
                                            }
                                            certificateData={certificateData}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </HospitalManagementDetailsWrapper>
    );
};

export default RegisteredEMSHospitalDetails;
