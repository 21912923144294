import styled from "styled-components";

export const CorporationBannerWrapper = styled.div`
    margin-bottom: 40px;
    word-break: break-word;
    flex-direction: column;
    .facility-details-banner {
        position: relative;
        border: 2px solid hsl(354, 86%, 41%);
    }
    .facility-settings {
        position: absolute;
        top: -3px;
        right: -4px;
        z-index: 2;
        line-height: 18px;
        .facility-settings-icon {
            font-size: 22px;
        }
        .dropdown-toggle {
            &:after {
                display: none;
            }
        }
        .dropdown-menu {
            transform: none !important;
            top: 25px !important;
            left: auto !important;
            right: 0 !important;
            box-shadow: 0px 3px 6px #00000029;
            border: none;
        }
    }
    .facility-settings-list {
        width: 185px;
        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
        }
        li {
            .btn {
                padding: 12px 14px;
                width: 100%;
                text-align: left;
            }
            .dropdown-item.active,
            .dropdown-item:active {
                color: #222328;
            }
        }
        li + li {
            border-top: 1px solid #e3e3e3;
        }
    }
    .corp-banner-header {
        padding: 14px 14px 12px;
        margin: 0;
        background: #f8f8f8;
        @media only screen and (min-width: 576px) {
            padding: 14px 26px 12px;
        }
    }
    .corp-banner-title {
        margin: 0;
    }
    .corp-banner-detail {
        padding: 5px 14px 8px;
        @media only screen and (min-width: 576px) {
            padding: 12px 26px 14px;
        }
    }
    .corp-banner-item {
        margin: 6px 26px 6px 0;
    }
    .corp-banner-value {
        margin-left: 6px;
    }
    .hidden {
        display: none !important;
    }
`;
