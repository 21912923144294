import SidebarLayout from "components/SidebarLayout";
import AccordianSidebar from "pages/ArchivedView/AccordianSidebar";
import React, { useState } from "react";
import ArchivedDocuments from "./ArchivedDocuments";
import { ArchivedCertificate } from "./ArchivedCertificate";

const ArchivedView = () => {
    const [page, setPage] = useState<any>(1);
    const [docDate, setDocDate] = useState<string>("");
    const [approvedDate, setApprovedDate] = useState<string>("");
    const archiveDate: any = localStorage.getItem("selectedArchivedDate");
    const facApprovedDate: any = localStorage.getItem("selectedApprovedDate");
    const [firstRenewingDate, setFirstRenewingDate] = useState("");
    const [secondRenewingDate, setSecondRenewingDate] = useState("");

    return (
        <>
            <SidebarLayout
                sidebarNode={
                    <AccordianSidebar
                        activeTab="documents"
                        setContent={setPage}
                        setDocsDate={setDocDate}
                        setFacApprovedDate={setApprovedDate}
                        setFirstRenewingDate={setFirstRenewingDate}
                        setSecondRenewingDate={setSecondRenewingDate}
                    />
                }
                contentNode={
                    page === "documents" ? (
                        <ArchivedDocuments
                            docArcivedDate={docDate || archiveDate}
                            docApprovedDate={approvedDate || facApprovedDate}
                            firstRenewingDate={firstRenewingDate}
                            secondRenewingDate={secondRenewingDate}
                        />
                    ) : (
                        <ArchivedCertificate
                            docArcivedDate={docDate || archiveDate}
                            docApprovedDate={approvedDate || facApprovedDate}
                            firstRenewingDate={firstRenewingDate}
                            secondRenewingDate={secondRenewingDate}
                        />
                    )
                }
            />
        </>
    );
};

export default ArchivedView;
