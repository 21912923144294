import { getPaymentStatus } from "api/paymentAPI";
import { getQueryVariable } from "common/utils";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import PaymentFailurePage from "./PaymentFailurePage";
import PaymentProcessingPage from "./PaymentProcessingPage";
import PaymentSuccessPage from "./PaymentSuccessPage ";
import { logger } from "../../utils/logger.utils";

export const PaymentStatusPage = () => {
    const history = useHistory();
    // Query Params
    const referenceId = getQueryVariable("referenceId");
    const lastSessionPayment = getQueryVariable("lastSessionPayment");

    // CONST
    const paymentStatus = {
        PENDING: "PENDING",
        COMPLETED: "COMPLETED",
        PARTIAL: "PARTIAL",
        FAILED: "FAILED",
        INPROGRESS: "INPROGRESS",
    };

    // STATE Variable
    const [pageStatus, setPageStatus] = useState<string>(
        paymentStatus.INPROGRESS
    );
    const [programId, setProgramId] = useState<any>();
    const [categoryId, setCategoryId] = useState<any>();

    useEffect(() => {
        if (referenceId) {
            getPaymentStatus(referenceId)
                .then((paymentStatusRes: any) => {
                    if (paymentStatusRes.success === true) {
                        if (
                            paymentStatusRes.data.paymentStatus ===
                            paymentStatus.COMPLETED
                        ) {
                            setPageStatus(paymentStatus.COMPLETED);
                        } else {
                            setPageStatus(paymentStatus.FAILED);
                        }
                        setProgramId(paymentStatusRes.data.programId);
                        setCategoryId(paymentStatusRes.data.categoryId);
                    }
                })
                .catch((error: any) => {
                    logger(error);
                });
        }
    }, [referenceId]);

    const gotToDashBoard = () => {
        history.push("/userDashboard");
    };

    const goToPaymentSummaryPage = () => {
        if (programId && categoryId) {
            history.push(
                `/paymentSummary/program/${programId}/category/${categoryId}`
            );
        }
    };

    return (
        <>
            {pageStatus === paymentStatus.INPROGRESS && (
                <PaymentProcessingPage
                    lastSessionPayment={lastSessionPayment}
                />
            )}
            {pageStatus === paymentStatus.COMPLETED && (
                <PaymentSuccessPage gotToDashBoard={gotToDashBoard} />
            )}
            {pageStatus === paymentStatus.FAILED && (
                <PaymentFailurePage
                    goToPaymentSummaryPage={goToPaymentSummaryPage}
                />
            )}
        </>
    );
};

export default PaymentStatusPage;
