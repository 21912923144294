import React, { useEffect, useState } from "react";
import Measures from "pages/Measures/MeasuresPage";
import { useLocation, useParams, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import CorporationTabs from "../CorporationMenu/CorporationMenu";
import { FacilitiesDetailsWrapper } from "./styled";
import FacilitiesNav from "./FacilitiesNav";
import CONSTANTS from "../../../common/constants";
import { setPageLoadingStatus } from "../../../components/Loader/loader.slice";
import { getHospitalById } from "../../../api/hospitalApi";
import { getHospitalProgramDetails } from "../../../api/hospitalProgramsAPI";
import { getHospitalProgramCertificate } from "../../../api/certificateAPI";
import { parseJwt, redirectionForErrorCode } from "../../../common/utils";

export const FacilitiesMeasures = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const { hospitalId, programId, categoryId }: any = useParams();
    const [hospital, setHospital] = useState<any>({});
    const [lastEditedData, setLastEditedData] = useState<any>(null);
    const [programData, setProgramData] = useState<any>();
    const [certificateData, setCertificateData] = useState<any>();
    const userAccessToken = window.localStorage.getItem("userAccessToken");
    const history = useHistory();
    const isCorporation =
        userAccessToken &&
        parseJwt(userAccessToken).role_code ===
            CONSTANTS.USER_ROLES.CORPORATION_ADMIN;

    useEffect(() => {
        dispatch(setPageLoadingStatus({ isPageLoading: true }));
        Promise.all([
            getHospitalById(hospitalId),
            getHospitalProgramDetails(programId, hospitalId),
            getHospitalProgramCertificate(hospitalId, programId, categoryId),
        ])
            .then((response: any) => {
                if (
                    response[0].success &&
                    response[1].success &&
                    response[2].success
                ) {
                    setHospital(response[0].data.hospital);
                    modifyLastEditedData(response[0].data.hospital);
                    setProgramData(response[1].data);
                    setCertificateData(response[2].data.programCertficate);
                    dispatch(setPageLoadingStatus({ isPageLoading: false }));
                }
            })
            .catch((error) => {
                if (isCorporation) {
                    redirectionForErrorCode(
                        CONSTANTS.ROUTES.CORPORATION_DASHBOARD,
                        error,
                        history,
                        2000
                    );
                }
                dispatch(setPageLoadingStatus({ isPageLoading: false }));
            });
    }, []);

    const modifyLastEditedData = (currentData: any) => {
        if (currentData?.updatedBy?.firstName && currentData?.updatedAt) {
            setLastEditedData(currentData);
        }
    };

    const getActiveTab = () => {
        switch (location?.state?.tab) {
            case CONSTANTS.PENDING:
                return "corporationPendingFacilties";
            case CONSTANTS.REGISTRED:
                return "corporationFaciltyRegistered";
            case CONSTANTS.CERTIFIED:
                return "corporationFaciltyCertified";
            case CONSTANTS.DEACTIVATED:
                return "corporationDeactivatedFacilties";
            default:
                return "";
        }
    };

    return (
        <FacilitiesDetailsWrapper className="corporation-container">
            <CorporationTabs activeTab={getActiveTab()} />
            <div className="container pt-lg-3 pb-5">
                <FacilitiesNav
                    activeMenu="corporationFacilityMeasures"
                    returnPath={location?.state}
                    hospital={hospital}
                    programData={programData}
                    certificateData={certificateData}
                    lastEditedData={lastEditedData}
                    isCorporation={isCorporation}
                    exportFacilityData={location.exportMeasureFunc}
                />
                {/* Measures */}
                <Measures isCorporation={isCorporation} />
            </div>
        </FacilitiesDetailsWrapper>
    );
};

export default FacilitiesMeasures;
