import React, { useEffect, useState } from "react";
import CertificationPage from "pages/Certification/CertificationPage";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import CorporationTabs from "../CorporationMenu/CorporationMenu";
import { FacilitiesDetailsWrapper } from "./styled";
import FacilitiesNav from "./FacilitiesNav";
import { getHospitalById } from "../../../api/hospitalApi";
import { setPageLoadingStatus } from "../../../components/Loader/loader.slice";
import { parseJwt } from "../../../common/utils";
import CONSTANTS from "../../../common/constants";

export const FacilitiesCertificate = () => {
    const [hospital, setHospital] = useState<any>({});
    const location = useLocation();
    const dispatch = useDispatch();
    const { hospitalId }: any = useParams();
    const userAccessToken = window.localStorage.getItem("userAccessToken");
    const isCorporation =
        userAccessToken &&
        parseJwt(userAccessToken).role_code ===
            CONSTANTS.USER_ROLES.CORPORATION_ADMIN;

    useEffect(() => {
        dispatch(setPageLoadingStatus({ isPageLoading: true }));
        getHospitalById(hospitalId)
            .then((response: any) => {
                if (response.success) {
                    setHospital(response.data.hospital);
                    dispatch(setPageLoadingStatus({ isPageLoading: false }));
                }
            })
            .catch(() => {
                dispatch(setPageLoadingStatus({ isPageLoading: false }));
            });
    }, []);

    const getActiveTab = () => {
        switch (location?.state?.tab) {
            case CONSTANTS.PENDING:
                return "corporationPendingFacilties";
            case CONSTANTS.REGISTRED:
                return "corporationFaciltyRegistered";
            case CONSTANTS.CERTIFIED:
                return "corporationFaciltyCertified";
            case CONSTANTS.DEACTIVATED:
                return "corporationDeactivatedFacilties";
            default:
                return "";
        }
    };

    return (
        <FacilitiesDetailsWrapper className="corporation-container">
            <CorporationTabs activeTab={getActiveTab()} />
            <div className="container pt-lg-3 pb-5">
                <FacilitiesNav
                    activeMenu="corporationFacilityCertification"
                    returnPath={location?.state}
                    hospital={hospital}
                    isCorporation={isCorporation}
                    exportFacilityData={location.exportCertificateFunc}
                />
                {/* Certificate */}
                <CertificationPage isCorporation={isCorporation} />
            </div>
        </FacilitiesDetailsWrapper>
    );
};

export default FacilitiesCertificate;
