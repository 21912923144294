/* eslint-disable*/
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Route, useHistory, RouteProps } from "react-router-dom";
import DefaultLayout from "pages/_layouts/default";
import AuthLayout from "pages/_layouts/auth";
import AdminLayout from "pages/_layouts/admin";
import { UserManager } from "oidc-client";
import { OidcSettings } from "./OidcSettings";
import { setSSOAccessToken, ssoLogin } from "pages/Verify/user.slice";
import store from "app/store";
import { getTokenPlayload } from "common/utils";
import { getUserHandShake } from "api/usersApi";
import Helmet from "react-helmet";
import { logger } from "../utils/logger.utils";
import CONSTANTS from "common/constants";
import Maintanance from "components/Maintenance/MaintenancePage";
import { getMaintenanceData } from "api/maintenanceAPI";
import config from "../config";
import { getNavigationByHospitalId } from "api/usersApi";

const userManager = new UserManager(OidcSettings);
window["userManager"] = userManager;
userManager.events.addUserLoaded(onUserLoaded);
userManager.events.addUserUnloaded(onUserUnloaded);

interface PrivateRouteProps extends RouteProps {
    // tslint:disable-next-line:no-any
    component: any;
    isSignedIn?: boolean;
    isPrivate?: boolean;
    hideHeader?: boolean;
    adminRoute?: boolean;
    emsAdminRoute?: boolean;
    registryRoute?: boolean;
    hospitalRoute?: boolean;
    corporationRoute?: boolean;
    protectedRoute?: boolean;
    title?: string;
    checkMaintenance?: boolean;
}

export default function RouteWrapper({
    component: Component,
    isPrivate,
    adminRoute,
    emsAdminRoute,
    registryRoute,
    protectedRoute,
    hospitalRoute,
    corporationRoute,
    hideHeader,
    title,
    checkMaintenance,
    ...rest
}: PrivateRouteProps) {
    const history = useHistory();
    const [isUnderMaintenance, setIsUnderMaintenance] = useState<any>(null);
    useEffect(() => {
        getUserHandShake();
        if (checkMaintenance === true) {
            getMaintenanceData()
                .then((maintenanceData: any) => {
                    if (
                        maintenanceData.data &&
                        maintenanceData.data.isUndermaintenance[config.env] ==
                            true
                    ) {
                        setIsUnderMaintenance(true);
                    } else {
                        setIsUnderMaintenance(false);
                    }
                })
                .catch((error) => {
                    setIsUnderMaintenance(false);
                    logger("error inside maintenance catch block :: ", error);
                });
        } else {
            setIsUnderMaintenance(false);
        }
    }, []);

    userManager
        .getUser()
        .then((user) => {
            if (user !== null && user !== undefined) {
                onUserLoaded(user);
                checkRoleAndRedirect();
            } else if (window.location.href.includes("#id_token")) {
                userManager
                    .signinRedirectCallback()
                    .then(() => {
                        window.history.replaceState({}, "", "/");
                    })
                    .catch(function (err) {
                        logger(err);
                    });
            } else {
                if (isPrivate) {
                    history.push("/");
                }
            }
        })
        .catch((error) => {
            logger("error inside catch block :: ", error);
        });
    let Layout = DefaultLayout;
    if (hideHeader) {
        Layout = AuthLayout;
    } else if (adminRoute) {
        Layout = AdminLayout;
    } else if (emsAdminRoute) {
        Layout = AdminLayout;
    };

    const checkRoleAndRedirect = async () => {
        const token: string | null = localStorage.getItem("userAccessToken");
        if (token) {
            const tokenPayload = getTokenPlayload(token as string);
            if (protectedRoute) {
                const hospitalId: any =
                    localStorage.getItem("selectedHospitalId");
                if (hospitalId) {
                    getNavigationByHospitalId(hospitalId).then(
                        (response: any) => {
                            if (response.statusCode == 200) {
                                if (!response.data.isProgramApproved) {
                                    history.push("/");
                                }
                            }
                        }
                    );
                } else {
                    history.push("/");
                }
            }
            if (adminRoute) {
                if (tokenPayload.role_code !== "AHA_ADMIN") {
                    history.push("/location");
                }
            } else if (emsAdminRoute) {
                if (tokenPayload.role_code !== "EMS_ADMIN") {
                    history.push("/location");
                }
            } else if (hospitalRoute) {
                if (tokenPayload.role_code !== "HOSPITAL_ADMIN") {
                    if (tokenPayload.role_code === "AHA_ADMIN") {
                        history.push("/admin/dashboard");
                    }
                }
            } else if (registryRoute) {
                if (tokenPayload.role_code !== "REGISTRY_ADMIN") {
                    history.push("/location");
                }
            } else if (corporationRoute) {
                if (
                    tokenPayload.role_code !==
                    CONSTANTS.USER_ROLES.CORPORATION_ADMIN
                ) {
                    history.push("/location");
                }
            }
        } else {
            window.userManager.signoutRedirect();
            history.push("/");
        }
    };

    /**
     * If not included on both previous cases, redirect user to the desired route.
     */
    return isUnderMaintenance == false ? (
        <Route
            {...rest}
            render={(props) => (
                <Layout>
                    <>
                        <Helmet>
                            <title>{title}</title>
                        </Helmet>
                        <Component {...props} />
                    </>
                </Layout>
            )}
        />
    ) : isUnderMaintenance == true ? (
        <Maintanance />
    ) : (
        <></>
    );
}

async function onUserLoaded(user: any) {
    if (user.state?.userType === CONSTANTS.REGISTRY) {
        user.profile.userType = CONSTANTS.REGISTRY;
    } else if (user.state?.userType === CONSTANTS.CORPORATION) {
        user.profile.userType = CONSTANTS.CORPORATION;
    }
    store.dispatch(ssoLogin(user.profile));
    store.dispatch(setSSOAccessToken(String(user.access_token)));
}

function onUserUnloaded() {
    store.dispatch(ssoLogin({ user: {} }));
}

RouteWrapper.propTypes = {
    isPrivate: PropTypes.bool,
    component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
        .isRequired,
};

RouteWrapper.defaultProps = {
    isPrivate: false,
    hideHeader: false,
    adminRoute: false,
    emsAdminRoute: false,
    registryRoute: false,
    hospitalRoute: false,
    corporationRoute: false,
    protectedRoute: false,
    title: "Quality Certification Tool | American Heart Association",
    checkMaintenance: false,
};
