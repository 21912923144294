import styled from "styled-components";

export const HospitalProgramWrapper = styled.div`
    .prg-registered {
        padding: 40px 0;
    }
    .prg-item {
        border: 1px solid #e3e3e3;
        .aha-type-card {
            box-shadow: none;
        }
        .typecard-header {
            border-width: 3px;
        }
        .typecard-body {
            margin-bottom: 0;
        }
        .typecard-body + .typecard-footer {
            margin-top: 16px;
        }
    }
    .aui-accordion-tab2 .aui-accordion-content {
        z-index: auto;
    }
    .program-detail-modal {
        .modal-header {
            border-bottom: 2px solid #c10e21;
        }
    }
    .programdetail-table {
        tr {
            border-bottom: 0;
            .aui-row-th {
                padding-right: 36px;
            }
            .aui-row-td {
                @media only screen and (max-width: 576px) {
                    padding-right: 10px;
                }
            }
        }
        tr:last-child {
            @media only screen and (min-width: 992px) {
                border-bottom: 0;
            }
        }
        th {
            font-weight: normal;
            vertical-align: top;
        }
        td,
        th {
            font-size: 16px;
        }
        .prm-subtype {
            padding: 0 22px;
            margin-bottom: 8px;
            line-height: 16px;
            display: inline-block;
            border-right: 1px solid #222328;
        }
        .prm-subtype:last-child {
            border-right: none;
        }
        .prm-subtype-list {
            margin-left: -22px;
        }
    }
    .programdetail-table {
        table {
            tbody {
                min-height: 100px;
                tr {
                    @media only screen and (min-width: 576px) {
                        .aui-row-th {
                            width: 50%;
                        }
                        .aui-row-td {
                            width: 50%;
                        }
                    }
                    @media only screen and (min-width: 768px) {
                        .aui-row-th {
                            width: 40%;
                        }
                        .aui-row-td {
                            width: 60%;
                        }
                    }
                }
            }
        }
    }
    .prg-modal-btn {
        width: 100%;
        @media only screen and (min-width: 576px) {
            width: 50%;
            margin-left: 50%;
        }
        @media only screen and (min-width: 768px) {
            width: 60%;
            margin-left: 40%;
        }
        .btn {
            width: 100%;
            @media only screen and (min-width: 576px) {
                width: auto;
            }
        }
    }
    .reg-authorise {
        border: 1px solid #e3e3e3;
        padding: 20px 14px;
        background: #f8f8f8;
        margin-top: 20px;
    }
    #loading-div {
        min-height: 400px;
    }
`;
