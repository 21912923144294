import React from "react";
import "styles/global.scss";
import { AlertPopupContainer, AlertPopupWrapper } from "./styled";

const AlertPopup = (props: any) => {
    const closePopup = () => {
        const popup = document.getElementById("alertPopup");
        popup?.classList.add("popupHide");
    };
    return (
        <AlertPopupWrapper id="alertPopup" className="popupHide">
            <AlertPopupContainer
                style={{
                    background: "#ffffff",
                    position: "fixed",
                    top: "40%",
                    left: "34%",
                    zIndex: 1000,
                    padding: 30,
                    textAlign: "center",
                    boxShadow: "#9f9f9f 0px 0px 10px",
                }}
            >
                <h5 id="faild-message">{props.message}</h5>
                <button className="btn btn-danger mt-4" onClick={closePopup}>
                    OK
                </button>
            </AlertPopupContainer>
            <div className="backdrop" />
        </AlertPopupWrapper>
    );
};

export default AlertPopup;
