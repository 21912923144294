import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import CONSTANTS from "common/constants";

export interface IRegion {
    region: string;
}

export const initialState: IRegion = {
    region: CONSTANTS.ALL,
};

const regionSlice = createSlice({
    name: "region",
    initialState,
    reducers: {
        setRegion: (state, action: PayloadAction<string>) => {
            state.region = action.payload;
        },
    },
});
const { actions, reducer } = regionSlice;

export const { setRegion } = actions;
export default reducer;
