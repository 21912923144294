import { IToast } from "components/Toast/toast.slice";
import CONSTANTS from "../../common/constants";

const ToastHandler = (toast: any) => {
    const visibility = toast.suppress ? !toast.suppress : true;
    const errorResponse: IToast = {};
    errorResponse.message = getDetailedErrorMessage(toast);
    errorResponse.type = toast.type ? toast.type : "danger";
    errorResponse.title =
        toast.code || toast.title ? toast.code || toast.title : "Error";
    errorResponse.visibility =
        errorResponse.message === CONSTANTS.REQUEST_ABORTED ||
        errorResponse.message === CONSTANTS.NETWORK_ERROR
            ? false
            : visibility;
    return errorResponse;
};

const getDetailedErrorMessage = (toast: any) => {
    let message =
        toast.details && toast.details.length > 0 && toast.message
            ? ""
            : toast.message;
    if (toast.details && toast.details.length > 0) {
        if (typeof message === "object") {
            message = "";
        }
        toast.details.map((detail: any) => {
            message += `${message ? ", " : ""}${detail.message}`;
        });
    }
    return message;
};
export default ToastHandler;
