const webWorker = () => {
    const validJSON = (JSONString: string) => {
        try {
            JSON.parse(JSONString);
            return true;
        } catch (e) {
            return false;
        }
    };
    const config: any = {
        env: process.env.REACT_APP_MY_ENV || "dev",
        dev: {
            apiEndpoints: {
                accounts: "https://accounts-qctapis-dev.heartblr.org/accounts",
            },
        },
        qa: {
            apiEndpoints: {
                accounts: "https://accounts-qctapis-qa.heartblr.org/accounts",
            },
        },
        test: {
            apiEndpoints: {
                accounts:
                    "https://d5wgc2koyd.execute-api.us-east-1.amazonaws.com/test/accounts",
            },
        },
        stg: {
            apiEndpoints: {
                accounts: "https://accounts-qctapis-stg.heartblr.org/accounts",
            },
        },
        preprod: {
            apiEndpoints: {
                accounts: "https://accounts-qctapis-preprod.heart.org/accounts",
            },
        },
        prod: {
            apiEndpoints: {
                accounts: "https://accounts-api.qct.heart.org/accounts",
            },
        },
        dr: {
            apiEndpoints: {
                accounts: "https://accounts-api.qct.heartblr.org/accounts",
            },
        },
        nfr: {
            apiEndpoints: {
                accounts: "https://accounts-qctapis-nfr.heartblr.org/accounts",
            },
        },
        local: {
            apiEndpoints: {
                accounts: "http://localhost:3001/accounts",
            },
        },
    };
    const totalTime = 900000;
    let timer = 0;
    onmessage = (e) => {
        if (e.data.type === "start") {
            timer += e.data.time;
            if (timer > totalTime) {
                postMessage({
                    type: "apiResponse",
                    status: "FAILED",
                    presignedUrl: null,
                });
            } else {
                const myHeaders = new Headers();
                myHeaders.append("authorization", e.data.authToken || "");
                myHeaders.append("Content-Type", "application/json");

                const requestOptions = {
                    method: "GET",
                    headers: myHeaders,
                };
                const url = `${config[config.env].apiEndpoints.accounts}${
                    e.data.URL
                }`;
                setTimeout(() => {
                    fetch(url, requestOptions)
                        .then((response) => response.text())
                        .then((result) => {
                            if (validJSON(result)) {
                                const uploadStatus = JSON.parse(result);
                                if (
                                    uploadStatus.data.status === "IN_PROGRESS"
                                ) {
                                    postMessage({
                                        type: "apiResponse",
                                        status: "IN_PROGRESS",
                                        presignedUrl: null,
                                    });
                                } else if (
                                    uploadStatus.data.status === "SUCCESS"
                                ) {
                                    postMessage({
                                        type: "apiResponse",
                                        status: "SUCCESS",
                                        presignedUrl: uploadStatus.data.path,
                                    });
                                } else if (
                                    uploadStatus.data.status === "FAILED"
                                ) {
                                    postMessage({
                                        type: "apiResponse",
                                        status: "FAILED",
                                        presignedUrl: uploadStatus.data.path,
                                    });
                                }
                            }
                        })
                        .catch(() => {
                            postMessage({
                                type: "apiResponse",
                                status: "FAILED",
                                presignedUrl: null,
                            });
                        });
                }, e.data.time);
            }
        }
    };
};

let webWorkerCode = webWorker.toString();
webWorkerCode = webWorkerCode.substring(
    webWorkerCode.indexOf("{") + 1,
    webWorkerCode.lastIndexOf("}")
);

const webWorkerBlob = new Blob([webWorkerCode], {
    type: "application/javascript",
});

const webWorkerScript = URL.createObjectURL(webWorkerBlob);

export default webWorkerScript;
