import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { showToast } from "components/Toast/toast.slice";
import { useDispatch } from "react-redux";
import DashboardEmptyState from "components/DashboardEmptyState/DashboardEmptyState";
import DashboardCharts from "components/DashboardCharts/DashboardCharts";
import CONSTANTS from "common/constants";
import FacilityDetailsBanner from "../HospitalBanner/FacilityDetailsBanner";
import { HospitalDashboardWrapper } from "./styled";
import { getSubscriptionDetails } from "../../../api/adminDashboardAPI";
import "styles/toggle-switch.scss";

interface Props {
    hospital: any;
    returnPath: any;
    programData: any;
    certificateData?: any;
    updateHospitalInfo?: any;
}

export const HospitalDashboard = (props: Props) => {
    const [hospital, setHospital] = useState<any>(props.hospital);
    const [programData, setProgramData] = useState<any>(props.programData);
    const [lastEditedData, setLastEditedData] = useState<any>(null);
    const [orderId, setOrderId] = useState<any>();
    const history = useHistory();
    const dispatch = useDispatch();
    const params: any = useParams();
    const { hospitalId, programId } = params;
    const [dashboardType, setDashboardType] = useState<any>(
        CONSTANTS.DASHBOARD_TYPE.CHART
    );

    useEffect(() => {
        setHospital(props.hospital);
        props.hospital && modifyLastEditedData(props.hospital);
        getSubscriptionDetails(
            params.hospitalId,
            params.programId,
            params.categoryId
        )
            .then((response: any) => {
                if (response.success) {
                    setOrderId(response.data.orderId);
                }
            })
            .catch((error: any) => {
                dispatch(showToast(error));
            });
    }, [props.hospital]);

    useEffect(() => {
        setProgramData(props.programData);
        props.programData && modifyLastEditedData(props.programData);
    }, [props.programData]);

    useEffect(() => {
        props.updateHospitalInfo && props.updateHospitalInfo(hospital);
    }, [hospital]);

    const modifyLastEditedData = (currentData: any) => {
        if (currentData?.updatedBy?.firstName && currentData?.updatedAt) {
            setLastEditedData(currentData);
        }
    };

    const close = () => {
        history.push(props.returnPath || "/admin/hospital-management");
    };
    const setDashBoardType = (event: any) => {
        if (event.target.checked) {
            setDashboardType(CONSTANTS.DASHBOARD_TYPE.TABLE);
        } else {
            setDashboardType(CONSTANTS.DASHBOARD_TYPE.CHART);
        }
    };
    return (
        <HospitalDashboardWrapper>
            <FacilityDetailsBanner
                hospital={hospital}
                programData={programData}
                lastEditedData={lastEditedData}
                exportFacilityDetailsData="false"
                certificateData={props.certificateData}
                close={close}
                orderId={orderId}
                isAdmin
                isExport
                facilityId="dashboardFacility"
                id="dashboardSettings"
                confirmDeactivateModalId="dashboardConfirmDeactiveModal"
                confirmRemoveModalId="dashboardConfirmRemoveModal"
                detailsTitleBannerId="dashboardTitleBanner"
            />
            <div className="main-pg-heading d-flex justify-content-between align-items-center">
                <h2 className="h3 mb-n3">Dashboard</h2>
                {programId !== "16" && (
                    <div className="view-toggle-switch mb-1">
                        <input
                            type="checkbox"
                            className="view-toggle-input"
                            onChange={setDashBoardType}
                            id="chartTableView"
                            role="switch"
                        />
                        {dashboardType == CONSTANTS.DASHBOARD_TYPE.TABLE ? (
                            <label
                                htmlFor="chartTableView"
                                className="view-toggle-control"
                            >
                                table view selected, click to change to chart
                                view
                            </label>
                        ) : (
                            <label
                                htmlFor="chartTableView"
                                className="view-toggle-control"
                            >
                                chart view selected, click to change to table
                                view
                            </label>
                        )}
                        <span className="view-toggle-slider">
                            <span
                                className="toggle-graph-icon1 toggle-graph-icon"
                                aria-hidden="true"
                            >
                                Chart
                            </span>
                            <span
                                className="toggle-graph-icon2 toggle-graph-icon"
                                aria-hidden="true"
                            >
                                Table
                            </span>
                        </span>
                    </div>
                )}
            </div>
            {programData ? (
                programId !== "16" ? (
                    <DashboardCharts
                        programCategoryId={programData?.programCategoryId}
                        hospitalId={hospitalId}
                        programId={programId}
                        programName={programData?.programName}
                        programData={programData}
                        hospital={hospital}
                        certificateData={props.certificateData}
                        dashboardType={dashboardType}
                    />
                ) : (
                    <DashboardEmptyState />
                )
            ) : null}
        </HospitalDashboardWrapper>
    );
};

export default HospitalDashboard;
