import Dropdown from "components/Dropdown";
import MultiSelectDropdown from "components/MultiSelectDropdown";
import React, { useEffect, useState } from "react";
import { wordChange } from "../../common/utils";
import "styles/global.scss";
import CONSTANTS from "common/constants";
import "styles/popover.scss";

const DynamicForm = (props: any) => {
    const [formData, setFormData] = useState<any>(false);
    const [randomNumber, setRandomNumber] = useState<any>(Math.random());
    const [checkboxGroupRequired, setCheckboxGroupRequired] =
        useState<any>(false);

    useEffect(() => {
        setFormData(props.model);
        if (props != undefined) {
            const dataObj = props.model.filter((data: any) => {
                return data.type == "checkbox";
            });
            if (
                dataObj.length !== 0 &&
                dataObj[0].props &&
                dataObj[0].props.required == true &&
                dataObj[0].value.length == 0
            ) {
                setCheckboxGroupRequired(true);
            } else {
                setCheckboxGroupRequired(false);
            }
        }
    }, [props.model]);

    const onChange = (
        e: any,
        key: string,
        type: string = "single",
        childKey: string = ""
    ) => {
        let dataObj = formData.filter((data: any) => {
            return data.key == key;
        });

        if (childKey && dataObj[0] && dataObj[0].options) {
            dataObj = dataObj[0].options.filter((data: any) => {
                return data.key == childKey;
            });
        }

        if (dataObj && dataObj.length > 0) {
            if (type == "single") {
                dataObj[0].value = ["textarea", "text"].includes(e.target.type)
                    ? e.target.value.replace(/[><]/g, "")
                    : e.target.value;
            } else if (type == "multiselect") {
                dataObj[0].value = e.target.value;
            } else {
                if (e.target.checked === true) {
                    dataObj[0].value.push(e.target.value);
                } else {
                    const currIndex = dataObj[0].value.indexOf(e.target.value);
                    dataObj[0].value.splice(currIndex, 1);
                }
                if (
                    dataObj[0].props &&
                    dataObj[0].props.required == true &&
                    dataObj[0].value.length == 0
                ) {
                    setCheckboxGroupRequired(true);
                } else {
                    setCheckboxGroupRequired(false);
                }
            }
        }
        const changedFormData = formData;
        setFormData(undefined);
        setFormData(changedFormData);
        if (type == "multiple") {
            renderForm();
        }

        props.onChange(changedFormData);
    };
    useEffect(() => {
        if (CONSTANTS.WINDOW.AUI.Tooltip) {
            CONSTANTS.WINDOW.AUI.Tooltip.init();
        }
    });
    const renderForm = () => {
        const model = formData || props.model;
        const formUI = model.map((object: any, i: any) => {
            if (!object.customData && object.hideElement != true) {
                const { key } = object;
                const type = object.type || "text";
                const prop = object.props || {};
                const { name } = object;
                const { value } = object;
                const target = key;
                let input = (
                    <input
                        {...prop}
                        className="form-control"
                        type={type}
                        key={key + randomNumber}
                        id={key}
                        name={name}
                        defaultValue={value}
                        onChange={(e) => {
                            onChange(e, target);
                        }}
                    />
                );

                if (type == "textarea") {
                    input = (
                        <textarea
                            {...prop}
                            className="form-control"
                            key={key}
                            id={key}
                            name={name}
                            defaultValue={value}
                            onChange={(e) => {
                                onChange(e, target);
                            }}
                        />
                    );
                }

                if (type == "parent") {
                    input = object.options.map((option: any) => {
                        if (
                            option.type == "select" ||
                            option.type == "dropdown"
                        ) {
                            input = option.options.map((opt: any) => {
                                return { label: opt.value, value: opt.value };
                            });

                            input = (
                                <div className="w-100">
                                    <Dropdown
                                        id={key}
                                        items={input}
                                        selectedValue={value}
                                        callParentOnSelect={(
                                            selectedValue: any
                                        ) =>
                                            onChange(
                                                {
                                                    target: {
                                                        value: selectedValue,
                                                    },
                                                },
                                                object.key,
                                                "single",
                                                option.key
                                            )
                                        }
                                        disabled={prop.disabled}
                                    />
                                </div>
                            );
                            return (
                                <React.Fragment key={`fr${option.key}`}>
                                    <div className="st-field pr-0 mr-3">
                                        <label
                                            htmlFor={option.key}
                                            className="visuallyhidden"
                                        />
                                        {input}
                                    </div>
                                </React.Fragment>
                            );
                        }

                        if (object.fullWidth && object.fullWidth == true) {
                            return (
                                <React.Fragment key={`fr${option.key}`}>
                                    <div className="col-12 d-sm-flex mb-3">
                                        <div className="col-sm-2 px-0 form-label">
                                            <label
                                                key={`ll${option.key}`}
                                                htmlFor={`ll${option.key}`}
                                            >
                                                {option.label}
                                            </label>
                                        </div>
                                        <div className=" col-sm-10 px-0 pl-sm-3">
                                            <input
                                                {...option.props}
                                                className="form-control"
                                                type={option.type}
                                                key={option.key}
                                                name={option.name}
                                                defaultValue={option.value}
                                                id={`ll${option.key}`}
                                                onChange={(e: any) => {
                                                    onChange(
                                                        e,
                                                        object.key,
                                                        "single",
                                                        option.key
                                                    );
                                                }}
                                            />
                                        </div>
                                    </div>
                                </React.Fragment>
                            );
                        }

                        return (
                            <React.Fragment key={`fr${option.key}`}>
                                <div className="col-sm-6 col-md-12 col-xl-6 d-sm-flex mb-3">
                                    {option.label ? (
                                        <div className="col-md-4 col-xl-6 px-0 form-label">
                                            <label
                                                key={`ll${option.key}`}
                                                htmlFor={`ll${option.key}`}
                                            >
                                                {option.label}
                                            </label>
                                        </div>
                                    ) : null}
                                    <div className="col-xl-6 col-md-8 px-0 pl-sm-3">
                                        <input
                                            {...option.props}
                                            className="form-control"
                                            type={option.type}
                                            key={option.key}
                                            name={option.name}
                                            defaultValue={option.value}
                                            id={`ll${option.key}`}
                                            onChange={(event: any) => {
                                                onChange(
                                                    event,
                                                    object.key,
                                                    "single",
                                                    option.key
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                            </React.Fragment>
                        );
                    });
                    input = <div className="row pr-0 flex-grow-1">{input}</div>;
                }

                if (type == "radio") {
                    input = object.options.map((option: any, index: any) => {
                        return (
                            <React.Fragment key={`fr${option.key}`}>
                                <div className="mb-3 ml-5">
                                    <div className="form-radio">
                                        <input
                                            {...prop}
                                            id={option.key}
                                            className="form-input"
                                            type={type}
                                            key={option.key}
                                            name={object.key}
                                            defaultValue={option.value}
                                            defaultChecked={
                                                option.value == object.value
                                            }
                                            onChange={(e) => {
                                                if (
                                                    object.actionElements &&
                                                    object.actionElements
                                                        .length > 0
                                                ) {
                                                    model.map((item: any) => {
                                                        object.actionElements.map(
                                                            (element: any) => {
                                                                if (
                                                                    element ==
                                                                    item.key
                                                                ) {
                                                                    if (
                                                                        e.target.value.toLocaleLowerCase() ==
                                                                        "yes"
                                                                    ) {
                                                                        delete item.hideElement;
                                                                    } else {
                                                                        item.hideElement =
                                                                            true;
                                                                        item.value =
                                                                            "";
                                                                    }
                                                                    setRandomNumber(
                                                                        Math.random()
                                                                    );
                                                                }
                                                            }
                                                        );
                                                    });
                                                }
                                                onChange(e, object.key);
                                            }}
                                        />
                                        <label
                                            htmlFor={option.key}
                                            key={`ll${option.key}`}
                                        >
                                            {option.label}
                                        </label>
                                    </div>
                                </div>
                            </React.Fragment>
                        );
                    });
                    input = (
                        <div
                            className="row w-100 flex-grow-1 mb-n3 ml-n5"
                            role="radiogroup"
                        >
                            {input}
                        </div>
                    );
                }

                if (type == "select" || type == "dropdown") {
                    input = object.options.map((option: any) => {
                        return { label: option.value, value: option.value };
                    });
                    input = (
                        <div className="w-100">
                            <Dropdown
                                id={key}
                                items={input}
                                selectedValue={value}
                                defaultDropdownValueIndicator
                                callParentOnSelect={(selectedValue: any) =>
                                    onChange(
                                        {
                                            target: {
                                                value: selectedValue,
                                            },
                                        },
                                        object.key
                                    )
                                }
                                disabled={prop.disabled}
                            />
                        </div>
                    );
                }

                if (type == "multiSelect") {
                    input = object.options.map((option: any) => {
                        return { label: option.value, value: option.value };
                    });
                    input = (
                        <div className="w-100">
                            <MultiSelectDropdown
                                {...object.props}
                                required
                                id={key}
                                items={input || []}
                                selectedValue={value?.length > 0 ? value : []}
                                callParentOnSelect={(selectedValue: any) => {
                                    onChange(
                                        {
                                            target: {
                                                value: selectedValue,
                                            },
                                        },
                                        object.key,
                                        "multiselect"
                                    );
                                }}
                                disabled={prop.disabled}
                            />
                        </div>
                    );
                }

                if (type == "checkbox") {
                    input = object.options.map((option: any) => {
                        let checked = false;
                        const childProps = option.props || {};
                        if (value && value.length > 0) {
                            checked = value.indexOf(option.value) > -1;
                        }
                        return (
                            <React.Fragment key={`cfr${option.key}`}>
                                <div className="col-lg-6">
                                    <div className="form-group form-check">
                                        <input
                                            {...childProps}
                                            id={option.key}
                                            type={type}
                                            key={option.key}
                                            name={option.name}
                                            required={checkboxGroupRequired}
                                            defaultChecked={checked}
                                            defaultValue={option.value}
                                            onChange={(e) => {
                                                onChange(
                                                    e,
                                                    object.key,
                                                    "multiple"
                                                );
                                            }}
                                        />
                                        <label
                                            key={`ll${option.key}`}
                                            htmlFor={option.key}
                                        >
                                            {option.label}
                                        </label>
                                    </div>
                                </div>
                            </React.Fragment>
                        );
                    });

                    input = (
                        <div className="row flex-grow-1" role="group">
                            {input}
                        </div>
                    );
                }

                return (
                    <div key={object.key}>
                        {type == "parent" ||
                        type == "radio" ||
                        type == "checkbox" ? (
                            <div
                                key={key}
                                className={
                                    object.props && object.props.required
                                        ? "form-group row required"
                                        : "form-group row"
                                }
                                role="group"
                                aria-labelledby={key}
                            >
                                <div className="col-md-5 form-label">
                                    <label id={key} className="label-form">
                                        {wordChange(object.label)}
                                        {object.props &&
                                            object.props.required && (
                                                <sup>*</sup>
                                            )}
                                    </label>
                                </div>
                                <div className="col-md-7 d-flex align-items-center">
                                    {input}
                                </div>
                            </div>
                        ) : (
                            <div
                                key={key}
                                className={
                                    object.props && object.props.required
                                        ? "form-group row required"
                                        : "form-group row"
                                }
                            >
                                <div
                                    className="col-md-5 form-label dynamic-tooltip"
                                    id={`${key}-tipid`}
                                >
                                    <label
                                        htmlFor={key}
                                        className="label-form d-inline"
                                        id={`${key}-label`}
                                    >
                                        {wordChange(object.label)}
                                        {object.props &&
                                            object.props.required && (
                                                <sup>*</sup>
                                            )}
                                    </label>
                                    {object?.helpText && (
                                        <button
                                            data-container={`#${key}-tipid`}
                                            type="button"
                                            className="aha-icon-help measure-help btn btn-text"
                                            data-toggle="tooltip"
                                            data-html="true"
                                            title={object.helpText}
                                        >
                                            <div className="sr-only">
                                                {wordChange(object.label)} Help
                                                text
                                            </div>
                                        </button>
                                    )}
                                </div>
                                <div className="col-md-7 d-flex align-items-center">
                                    {input}
                                </div>
                            </div>
                        )}
                    </div>
                );
            }
        });
        return formUI;
    };

    return (
        <div className={props.className}>
            {formData.length ? (
                <fieldset disabled={props.disabled}>{renderForm()}</fieldset>
            ) : null}
        </div>
    );
};
DynamicForm.defaultProps = {
    model: [],
};
export default DynamicForm;
