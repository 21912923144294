import styled from "styled-components";

export const SettingWrapper = styled.div`
    .aui-sidenav-plane {
        flex-basis: 250px;
        flex-shrink: 0;
    }
    .setting-header {
        border-bottom: 2px solid #c10e21;
        margin: 32px 0 30px;
    }
    .setting-container {
        @media only screen and (min-width: 992px) {
            width: 78%;
            padding: 10px 0 70px 42px;
        }
    }
`;
