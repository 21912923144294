import styled from "styled-components";

export const RegistryMeasuresWrapper = styled.div`
    .container {
        .container {
            padding: 0;
        }
    }
    .registry-banner {
        margin-bottom: 36px;
    }
    .registry-banner-header {
        background: #c20f21;
        padding: 14px 26px 12px;
    }
    .registry-banner-title {
        color: #fff;
    }
    .registry-banner-facility {
        width: 100%;
        margin-bottom: 24px;
        @media only screen and (min-width: 576px) {
            width: 48%;
            margin-bottom: 0;
        }
        @media only screen and (min-width: 768px) {
            width: 200px;
        }
        @media only screen and (min-width: 992px) {
            width: 300px;
        }
    }
    .registry-banner-pgm {
        width: 100%;

        @media only screen and (min-width: 576px) {
            width: 48%;
            margin-left: 4%;
        }
        @media only screen and (min-width: 768px) {
            width: 200px;
            margin-left: 24px;
        }
    }
    .cst-select-fld {
        border-radius: 4px;
    }
    .registry-banner-body {
        border: 3px solid #e3e3e3;
        border-top: 0;
        > .registry-banner-label {
            padding: 12px 26px;
            background: #f8f8f8;
        }
    }
    .registry-banner-detail {
        padding: 12px 26px 14px;
    }
    .aui-block-loader {
        min-height: 650px;
        &:before {
            animation-duration: 1s;
        }
    }
`;
