import React, { useState, useEffect } from "react";
import Dropdown from "components/Dropdown";
import Measures from "pages/Measures/MeasuresPage";
import LocalStore from "common/localStore";
import {
    getRegistryHospitalPrograms,
    getRegistryHospitals,
} from "api/registryAPI";
import { useParams, Link, useLocation } from "react-router-dom";
import { logError } from "utils/logger.utils";
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import { useDispatch } from "react-redux";
import { getCurrentQuarter, wordSplit } from "common/utils";
import {
    setQuarter,
    setYear,
} from "components/MeasuresDateFilter/measuresDateFilter.slice";
import { RegistryMeasuresWrapper } from "./styled";
import RegistryTabs from "../RegistryMenu";

const RegistryMeasures = () => {
    const { hospitalId }: any = useParams();
    const dispatch = useDispatch();
    const [allHospitals, setAllHospitals] = useState<any>([]);
    const [selectedHospitalId, setSelectedHospitalId] = useState<number>(
        Number(hospitalId)
    );
    const [selectedHospitalName, setSelectedHospitalName] = useState<any>("");
    const [hospitalMap, setHospitalMap] = useState<any>([]);

    const [allCategories, setAllCategories] = useState<any>([]);
    const [categoriesMap, setCategoriesMap] = useState<any>([]);
    const [selectedCategoryIndex, setSelectedCategoryIndex] =
        useState<number>(0);
    const [isProgramsLoading, setIsProgramsLoading] = useState<boolean>(false);
    const [facilityCode, setFacilityCode] = useState<any>("");
    const location = useLocation();

    useEffect(() => {
        dispatch(setPageLoadingStatus({ isPageLoading: true }));
        LocalStore.get("registryId").then((info: any) => {
            if (info.success && selectedHospitalId) {
                Promise.all([
                    getRegistryHospitals(info.data, 100, 1),
                    getPrograms(),
                ])
                    .then((response: any) => {
                        const registryHospitals = response[0];
                        const hospitalDropdown: any = [];
                        registryHospitals.data.hospitals.map(
                            (hospital: any) => {
                                hospitalDropdown.push({
                                    label: hospital.hospitalName,
                                    value: hospital.hospitalId,
                                });
                            }
                        );
                        setAllHospitals(registryHospitals.data.hospitals);
                        setHospitalMap(hospitalDropdown);
                        dispatch(
                            setPageLoadingStatus({ isPageLoading: false })
                        );
                    })
                    .catch((error: any) => {
                        logError(error);
                        dispatch(
                            setPageLoadingStatus({ isPageLoading: false })
                        );
                    });
            }
        });
    }, []);

    useEffect(() => {
        getPrograms();
        if (allHospitals?.length > 0) {
            let hospitalName: any = "";
            for (let i = 0; i < allHospitals.length; i++) {
                if (selectedHospitalId == allHospitals[i].hospitalId) {
                    hospitalName = allHospitals[i].hospitalName;
                    setFacilityCode(allHospitals[i].facilityCode);
                    break;
                }
            }
            setSelectedHospitalName(hospitalName);
        }
    }, [selectedHospitalId, allHospitals]);

    const getPrograms = () => {
        return new Promise((resolve, reject) => {
            if (selectedHospitalId) {
                setIsProgramsLoading(true);
                getRegistryHospitalPrograms(selectedHospitalId)
                    .then((programs: any) => {
                        setAllCategories(programs.data.categories);
                        const categoriesDropdown: any = [];
                        programs.data.categories.map(
                            (category: any, index: number) => {
                                categoriesDropdown.push({
                                    label: category.categoryName,
                                    value: index,
                                });
                            }
                        );
                        setCategoriesMap(categoriesDropdown);
                        setSelectedCategoryIndex(0);
                        setIsProgramsLoading(false);
                        resolve(true);
                    })
                    .catch((error: any) => {
                        logError(error);
                        setIsProgramsLoading(false);
                        reject(error);
                    });
            }
        });
    };

    return (
        <RegistryMeasuresWrapper>
            <RegistryTabs activeTab="organizationManagement" />
            <div className="container">
                {selectedHospitalName && (
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb aha-breadcrumb px-0 mb-3">
                            <li className="breadcrumb-item">
                                <a href="/registry/dashboard">Home</a>
                            </li>
                            <li className="breadcrumb-item">
                                <Link
                                    to={{
                                        pathname:
                                            "/registry/organization-management",
                                        state: {
                                            pageNumber: location?.state?.bP,
                                        },
                                    }}
                                >
                                    Organizations Registered
                                </Link>
                            </li>
                            <li className="breadcrumb-item active">
                                <div
                                    aria-current="page"
                                    role="link"
                                    tabIndex={0}
                                >
                                    {selectedHospitalName}
                                </div>
                            </li>
                        </ol>
                    </nav>
                )}
                <div className="registry-banner">
                    <div
                        className="d-md-flex justify-content-between align-items-center registry-banner-header"
                        role="group"
                        aria-labelledby="changeRegFacility"
                    >
                        <div
                            className="h4 mb-0 font-300 registry-banner-title"
                            id="changeRegFacility"
                        >
                            Change Organization
                        </div>
                        <div className="d-sm-flex my-3 my-md-0">
                            <div className="registry-banner-facility">
                                <Dropdown
                                    id="facilityRegistry"
                                    items={hospitalMap}
                                    selectedValue={selectedHospitalId}
                                    callParentOnSelect={(value: any) => {
                                        setSelectedHospitalId(value);
                                        dispatch(
                                            setYear(new Date().getFullYear())
                                        );
                                        dispatch(
                                            setQuarter(getCurrentQuarter())
                                        );
                                    }}
                                    disabled={false}
                                    ariaInnerLabelValue="facilities"
                                />
                            </div>
                            <div className="registry-banner-pgm">
                                <Dropdown
                                    id="programRegistry"
                                    items={categoriesMap}
                                    selectedValue={selectedCategoryIndex}
                                    callParentOnSelect={(value: any) => {
                                        if (value != selectedCategoryIndex) {
                                            setSelectedCategoryIndex(value);
                                        }
                                    }}
                                    disabled={isProgramsLoading}
                                    isLoading={isProgramsLoading}
                                    ariaInnerLabelValue="Programs"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="registry-banner-body">
                        <h2 className="registry-banner-label h3 mb-2">
                            {selectedHospitalName}
                        </h2>
                        <div className="d-xl-flex registry-banner-detail">
                            <div className="mb-2 mb-xl-0">
                                Registered Program(s):{" "}
                                <span className="font-bold">
                                    {allCategories?.length > 0
                                        ? allCategories[selectedCategoryIndex]
                                              .categoryName
                                        : ""}
                                </span>
                            </div>
                            <div className="ml-xl-5 mb-2 mb-xl-0">
                                Organization Code:{" "}
                                <span className="font-bold abbr-code">
                                    {wordSplit(facilityCode || "N/A")}
                                </span>
                            </div>
                            <div className="ml-xl-5 mb-2 mb-xl-0">
                                <i />
                            </div>
                        </div>
                    </div>
                </div>
                {/* Measures */}
                <div className={isProgramsLoading ? "d-none" : ""}>
                    {allCategories[selectedCategoryIndex]?.programId &&
                    allCategories[selectedCategoryIndex]?.categoryId &&
                    selectedHospitalId ? (
                        <Measures
                            isRegistry
                            selectedCategoryIndex={selectedCategoryIndex}
                            registryHospitalId={Number(selectedHospitalId)}
                            registryProgramId={Number(
                                allCategories[selectedCategoryIndex]?.programId
                            )}
                            registryCategoryId={Number(
                                allCategories[selectedCategoryIndex]?.categoryId
                            )}
                        />
                    ) : (
                        <></>
                    )}
                </div>
                {isProgramsLoading && (
                    <div
                        className="aui-block-loader"
                        role="alert"
                        aria-live="assertive"
                        aria-label="Measures page is loading"
                        tabIndex={0}
                    />
                )}
            </div>
        </RegistryMeasuresWrapper>
    );
};

export default RegistryMeasures;
