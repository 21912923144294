import styled from "styled-components";

export const BulkDocumentSubmissionWrapper = styled.div`
    padding-bottom: 36px;
    .bulk-filter {
        border: 1px solid #e3e3e3;
        background: #f8f8f8;
        position: relative;
        padding: 14px;
        @media only screen and (min-width: 768px) {
            padding: 10px 16px;
        }
        @media only screen and (min-width: 992px) {
            padding: 10px 28px 8px;
        }
    }
    .bulk-doc-filter-field {
        margin-bottom: 20px;
        flex-shrink: 0;
        @media only screen and (min-width: 768px) {
            padding: 12px 15px 12px 0;
            margin-bottom: 0;
        }
        @media only screen and (min-width: 1200px) {
            padding: 8px 40px 8px 0;
        }
        &:last-child {
            padding-right: 0;
            margin-bottom: 5px;
            @media only screen and (min-width: 768px) {
                margin-bottom: 0;
            }
        }
        label {
            white-space: nowrap;
        }
    }
    .bulk-doc-filter-1 {
        flex-basis: 100%;
        @media only screen and (min-width: 768px) {
            flex-basis: 34%;
        }
        @media only screen and (min-width: 992px) {
            flex-basis: auto;
            flex-grow: 1;
        }
        @media only screen and (min-width: 1200px) {
            flex-basis: 380px;
            flex-grow: initial;
        }
    }
    .bulk-doc-filter-2 {
        flex-basis: 100%;
        @media only screen and (min-width: 768px) {
            flex-basis: auto;
            flex-grow: 1;
        }
    }
    .bulk-doc-filter-3 {
        flex-basis: 100%;
        @media only screen and (min-width: 768px) {
            flex-basis: 20%;
        }
        @media only screen and (min-width: 992px) {
            flex-basis: auto;
            flex-grow: 1;
        }
        @media only screen and (min-width: 1200px) {
            flex-basis: 240px;
            flex-grow: initial;
        }
    }
    .bulk-doc-table {
        thead {
            @media only screen and (min-width: 992px) {
                th:nth-child(1) {
                    width: 6%;
                    min-width: 64px;
                    max-width: 64px;
                }
                th:nth-child(2) {
                    width: 18%;
                }
                th:nth-child(3) {
                    width: 30%;
                }
                th:nth-child(4) {
                    width: 23%;
                }
                th:nth-child(5) {
                    width: 23%;
                }
            }
        }
        .user-loc-title {
            width: 75px;
        }
        .form-check input[type="checkbox"] {
            z-index: 1;
        }
    }
    .bulk-pagination {
        .pagination-block {
            margin-top: 0;
            nav > ul {
                margin: 0;
            }
        }
    }
    .tag-count {
        border: 1px solid #006f9a;
        background: #e6f0f6;
        color: #024c69;
        font-size: 14px;
        padding: 4px 10px;
        display: flex;
        align-items: center;
        border-radius: 4px;
        font-weight: 500;
    }
    .bulk-left-content {
        margin-top: 20px;
        flex-direction: row;
        display: flex;
        align-items: flex-end;
        @media only screen and (min-width: 992px) {
            margin-top: 0;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 8px;
        }
    }
    .bulk-table-count {
        margin-top: 16px;
        @media only screen and (min-width: 992px) {
            margin-top: 0;
        }
        > * {
            line-height: 31px;
        }
    }
    .table-rowcount {
        font-size: 14px;
        @media only screen and (min-width: 992px) {
            font-size: inherit;
        }
    }
    .aui-modal .modal-header {
        border-bottom: 2px solid #c10e21;
    }
    .bulk-docselected-table {
        thead {
            @media only screen and (min-width: 992px) {
                th:nth-child(1) {
                    width: 7%;
                }
                th:nth-child(2) {
                    width: 33%;
                }
                th:nth-child(3) {
                    width: 60%;
                }
            }
        }
        .bulk-docselected-table-col1 {
            font-size: 1px;
            width: 1px;
            height: 1px;
            overflow: hidden;
        }
    }
    table.aui-responsive-status-table {
        .form-check {
            padding-left: 22px;
        }
    }
    .btn-sort {
        .tooltip-div {
            @media only screen and (max-width: 991.98px) {
                &:before,
                &:after {
                    display: none;
                }
            }
        }
        &:focus,
        &:hover {
            .tooltip-div {
                width: 300px;
                @media only screen and (max-width: 991.98px) {
                    left: 0;
                    transform: translate(0, -10px);
                }
            }
        }
        &:focus,
        &:hover {
            & + .tooltip-div {
                @media only screen and (max-width: 991.98px) {
                    width: 320px;
                    left: -50px;
                    top: 24px;
                    padding: 5px 8px;
                    right: auto;
                    transform: none;
                    max-width: calc(100vw - 18px);
                    font-size: 13px;
                    line-height: 1.2;
                }
                @media only screen and (max-width: 575.98px) {
                    left: 10px;
                    padding: 10px 8px;
                    line-height: 1.5;
                }
                &:before,
                &:after {
                    @media only screen and (max-width: 991.98px) {
                        display: none;
                    }
                }
            }
        }
    }
`;
