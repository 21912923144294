import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { SuccessPaymentWrapper } from "./styled";

export const SuccessPayment = () => {
    const history = useHistory();
    const location = useLocation();

    const gotToDashBoard = () => {
        history.push("/userDashboard", {
            pageNum: location?.state?.pageNum,
            search: location?.state?.search,
        });
    };
    return (
        <SuccessPaymentWrapper>
            <div className="container text-center">
                <h1 className="h5 font-600 pb-2 pb-md-4 mb-2">
                    Thank you for completing your registration details.
                </h1>
                <p className="mb-3 mx-auto thankyou-details">
                    We will review your information and get back as soon as
                    possible. You will receive an email verifying your status
                    and next steps.
                </p>
                {/* <Link to={`/dashboard`}> */}
                <button
                    type="button"
                    onClick={gotToDashBoard}
                    className="btn btn-round btn-primary mt-3"
                    role="link"
                >
                    Go to User Dashboard
                </button>
                {/* </Link> */}
            </div>
        </SuccessPaymentWrapper>
    );
};

export default SuccessPayment;
