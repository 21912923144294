import React from "react";
import { localDateToYYYYMMDDFormatString, wordSplit } from "../../common/utils";
import { EUPBannerWrapper } from "./styled";
import CONSTANTS from "../../common/constants";

interface Props {
    hospital: any;
    programData: any;
    certificateData: any;
}

export const EUPBanner = (props: Props) => {
    const registryName = () => {
        const programDetails = props.programData?.programDetails
            ? JSON.parse(props.programData?.programDetails)
            : "";
        const jsonData = programDetails ? programDetails.jsonForm : [];
        if (
            jsonData.length > 0 &&
            jsonData[jsonData.length - 1].customData === true
        ) {
            return jsonData[jsonData.length - 1].registryName;
        }
        return "";
    };

    return (
        <EUPBannerWrapper className="facility-banner">
            <div className="d-md-flex justify-content-between facility-banner-header">
                <h2 className="h3 facility-banner-title">
                    {props.hospital?.facilityName}
                </h2>
                {props.certificateData?.programCertificateGrantedOn && (
                    <div className="facility-banner-certified text-nowrap mt-2 mt-md-0">
                        <i
                            className="aha-icon-certified font-red ml-n1 ml-md-0"
                            aria-hidden="true"
                        />

                        <span className="font-bold">
                            {props.programData.programCategoryId ===
                            CONSTANTS.EMS_PROGRAM_CATEGORY_ID
                                ? "Awarded On:"
                                : "Certified On:"}{" "}
                            {localDateToYYYYMMDDFormatString(
                                props.certificateData
                                    ?.programCertificateGrantedOn
                            )}
                        </span>
                    </div>
                )}
            </div>
            <div className="d-flex flex-wrap facility-banner-detail">
                {props.programData?.programCategoryId ==
                    CONSTANTS.EMS_PROGRAM_CATEGORY_ID && (
                    <div className="facility-banner-item">
                        <span className="abbr-code">
                            {wordSplit("AHA EMS ID:")}
                        </span>
                        <span className="facility-banner-value font-bold">
                            <span>
                                {props.programData.emsId?.split("_")[0]}
                            </span>
                        </span>
                    </div>
                )}
                <div className="facility-banner-item">
                    Organization Code:&nbsp;
                    <span className="facility-banner-value font-bold">
                        <span className="abbr-code">
                            {wordSplit(props.hospital?.facilityCode)}
                        </span>
                    </span>
                </div>
                <div className="facility-banner-item">
                    Program:&nbsp;
                    <span className="facility-banner-value font-bold">
                        {props.programData?.programCategoryName}
                    </span>
                </div>
                <div className="facility-banner-item">
                    Location:&nbsp;
                    <span className="facility-banner-value font-bold">
                        {props.hospital?.countryName}
                    </span>
                </div>
                {props.hospital?.createdAt && (
                    <div className="facility-banner-item">
                        Registered on:&nbsp;
                        <span className="facility-banner-value font-bold">
                            {localDateToYYYYMMDDFormatString(
                                props.hospital?.createdAt
                            )}
                        </span>
                    </div>
                )}
                {props.programData?.corporationName && (
                    <div className="facility-banner-item">
                        Corporation:&nbsp;
                        <span className="facility-banner-value font-bold">
                            {props.programData?.corporationName}
                        </span>
                    </div>
                )}
                {registryName() && (
                    <div className="facility-banner-item">
                        Registry:&nbsp;
                        <span className="facility-banner-value font-bold">
                            {registryName()}
                        </span>
                    </div>
                )}
            </div>
        </EUPBannerWrapper>
    );
};

export default EUPBanner;
