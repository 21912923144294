import styled from "styled-components";

export const TabsWrapper = styled.nav`
    &.aui-global-nav {
        &.corporate-nav {
            .aui-global-menu {
                > li {
                    @media only screen and (min-width: 992px) {
                        margin-right: 42px;
                    }
                    a {
                        &:hover,
                        &:focus {
                            text-decoration: none;
                        }
                    }
                }
            }
        }
        .aui-global-menu {
            ul {
                padding: 0;
                @media only screen and (min-width: 992px) {
                    padding: 6px 0;
                }
            }
            li {
                position: relative;
                margin: 0;
                .dropdown-menu {
                    a {
                        font-size: 16px;
                        padding: 12px 14px;
                        display: block;
                        text-align: left;
                        font-weight: normal;
                    }
                }
            }
        }
        .aui-header-sm-dropdown {
            width: 100%;
            @media only screen and (min-width: 992px) {
                width: 140px;
                left: auto !important;
                right: 0;
            }
        }
        .aui-header-dropdown {
            width: 100%;
            @media only screen and (min-width: 992px) {
                width: 240px;
            }
        }
        .dropdown-menu {
            transform: none !important;
            top: auto !important;
            position: static !important;
            background: #f8f8f8;
            border: none;
            padding: 0;
            float: none;
            @media only screen and (min-width: 992px) {
                position: absolute !important;
                box-shadow: 0px 3px 15px #00000029;
                background: #ffffff;
                margin-top: -5px;
                border: 1px solid rgba(0, 0, 0, 0.15);
                padding: 8px 0;
            }
            &:before {
                position: absolute;
                border-style: solid;
                border-width: 0 12px 12px;
                border-color: rgba(0, 0, 0, 0.15) transparent;
                display: block;
                width: 0;
                z-index: 0;
                top: -13px;
                left: 98px;
                @media only screen and (min-width: 992px) {
                    content: "";
                }
            }
            &:after {
                position: absolute;
                border-style: solid;
                border-width: 0 12px 12px;
                border-color: #ffffff transparent;
                display: block;
                width: 0;
                z-index: 1;
                top: -12px;
                left: 98px;
                @media only screen and (min-width: 992px) {
                    content: "";
                }
            }
            li {
                border-bottom: 1px solid #e3e3e3;
                @media only screen and (min-width: 992px) {
                    border: none;
                }
                &:last-child {
                    border: none;
                }
                a {
                    &:hover {
                        color: #c10e21;
                        text-decoration: underline;
                    }
                }
            }
            li.active {
                a {
                    border: none;
                    color: #c10e21;
                }
            }
            li:not(active) {
                a {
                    color: #222328;
                    border: none;
                }
            }
        }
    }
`;
