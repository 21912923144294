/**
 * util to convert given string to title Case
 * eg: online to Online
 * @param inputString
 * @returns
 */
export const titleCase = (inputString: any) => {
    if (!inputString) {
        return inputString;
    }
    return (
        inputString.charAt(0).toUpperCase() + inputString.slice(1).toLowerCase()
    );
};

/**
 * util to convert given html to string
 * eg: `<div><span aria-hidden="true">AHAEMS2</span><span class="sr-only">A H A E M S 2</span></div>` to AHAEMS2
 * @param tags
 * @returns
 */
export const emsHtmlParser = async (tags: any) => {
    // regular expression to extract the text between the <span> tags with aria-hidden="true"
    const regex = /<span aria-hidden="true">(.*?)<\/span>/;
    const match = tags.match(regex);

    // If a match is found, extract the captured group (text inside the span) and remove any extra spaces
    const code = match ? match[1].replace(/\s+/g, "") : null;

    return code;
};
