import styled from "styled-components";

export const HeaderWrapper = styled.header`
    .qct-logo {
        padding-right: 10px;
        img {
            width: 200px;
            @media only screen and (min-width: 768px) {
                width: 220px;
            }
            @media only screen and (min-width: 768px) {
                width: 260px;
            }
            @media only screen and (min-width: 992px) {
                width: 305px;
            }
        }
    }
    .navbar-toggler {
        color: #222328;
    }
`;
