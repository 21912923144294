import {
    wordSplit,
    calculatNumOfDaysBetweenDates,
    localDateToYYYYMMDDFormatString,
    expireDataRowStatus,
} from "common/utils";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import CONSTANTS from "common/constants";
import { FacilitiesListingWrapper } from "./styled";
import "styles/expire-tooltip.scss";
import ExpireIndicator from "components/ExpireIndicator";

interface Props {
    facilities: any;
    baseActionURL: string;
    registryId: any;
    pageNumber: number;
    returnPath: any;
    registryName: string;
}

export const AdminRegistryFacilitiesTable = ({
    facilities,
    baseActionURL,
    registryId,
    pageNumber,
    returnPath,
    registryName,
}: Props) => {
    const checkIsExpierSoon = (
        expiresOn: any,
        programId: any,
        hospitalId: any
    ) => {
        if (!expiresOn) {
            return "";
        }
        return (
            <div>
                <ExpireIndicator
                    expiresOn={expiresOn}
                    programId={programId}
                    hospitalId={hospitalId}
                />
            </div>
        );
    };

    useEffect(() => {
        if (CONSTANTS.WINDOW.AUI.Tooltip) {
            CONSTANTS.WINDOW.AUI.Tooltip.init();
        }
    });
    return (
        <FacilitiesListingWrapper className="mt-3 w-100">
            <div className="registry-facilitieslist-table">
                <table className="aui-responsive-status-table">
                    <thead>
                        <tr>
                            <th scope="col">
                                <div className="aui-th">Organization Code</div>
                            </th>
                            <th scope="col">
                                <div className="aui-th">Organization Name</div>
                            </th>
                            <th scope="col">
                                <div className="aui-th">Program Type</div>
                            </th>
                            <th scope="col">
                                <div className="aui-th">State</div>
                            </th>
                            <th scope="col">
                                <div className="aui-th">Location</div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {facilities && facilities.length > 0 ? (
                            facilities.map((facility: any) => {
                                return (
                                    <tr
                                        key={facility.facilityCode}
                                        className={expireDataRowStatus(
                                            facility.expiresOn,
                                            CONSTANTS.REGISTRED
                                        )}
                                    >
                                        <td data-title="Organization Code">
                                            <div className="aui-td">
                                                <div>
                                                    <div className="mt-1">
                                                        <span className="abbr-code">
                                                            {wordSplit(
                                                                facility.facilityCode
                                                            )}
                                                        </span>
                                                    </div>
                                                    {checkIsExpierSoon(
                                                        facility.expiresOn,
                                                        facility.programId,
                                                        facility.hospitalId
                                                    )}
                                                </div>
                                            </div>
                                        </td>
                                        <td data-title="Organization Name">
                                            <div className="aui-td">
                                                <Link
                                                    className="btn-text-link-uline"
                                                    to={{
                                                        pathname: `${baseActionURL}/${facility.hospitalId}/program/${facility.programId}/category/${facility.categoryId}`,
                                                        state: {
                                                            registryId,
                                                            registryName,
                                                            facilityName:
                                                                facility.hospitalName,
                                                            hRP: pageNumber,
                                                            ...returnPath,
                                                        },
                                                    }}
                                                    aria-label={`view ${facility.hospitalName} details`}
                                                >
                                                    {facility.hospitalName}
                                                </Link>
                                            </div>
                                        </td>
                                        <td data-title="Program">
                                            <div className="aui-td">
                                                {facility.categoryName}
                                            </div>
                                        </td>
                                        <td data-title="State">
                                            <div className="aui-td">
                                                {facility.stateName}
                                            </div>
                                        </td>
                                        <td data-title="Country">
                                            <div className="aui-td">
                                                {facility.countryName}
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr className="aui-status-table-emptystate">
                                <td colSpan={5} className="text-center">
                                    <div className="aui-td">
                                        No data to display
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </FacilitiesListingWrapper>
    );
};

export default AdminRegistryFacilitiesTable;
