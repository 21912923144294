import styled from "styled-components";

export const RegistryManagementDetailsWrapper = styled.div`
    .registry-mgnt-table {
        tbody {
            tr {
                th,
                td {
                    font-size: 16px;
                }
                @media only screen and (min-width: 576px) {
                    .aui-row-th {
                        width: 50%;
                    }
                    .aui-row-td {
                        width: 50%;
                    }
                }
                @media only screen and (min-width: 768px) {
                    .aui-row-th {
                        width: 40%;
                    }
                    .aui-row-td {
                        width: 60%;
                    }
                }
                @media only screen and (min-width: 992px) {
                    .aui-row-th {
                        width: 45%;
                    }
                    .aui-row-td {
                        width: 55%;
                    }
                }
            }
        }
        .aui-tag-list {
            margin-left: -4px;
        }
    }
    .registry-mgnt-btns {
        padding: 48px 0;
        @media only screen and (min-width: 576px) {
            width: 100%;
        }
        @media only screen and (min-width: 768px) {
            width: 60%;
            margin-left: 40%;
        }
        @media only screen and (min-width: 992px) {
            width: 55%;
            margin-left: 45%;
        }
        .btn + .btn {
            margin-top: 24px;
            @media only screen and (min-width: 576px) {
                margin-left: 2%;
                margin-top: 0;
            }
            @media only screen and (min-width: 768px) {
                margin-left: 24px;
            }
        }
        .btn {
            width: 100%;
            @media only screen and (min-width: 576px) {
                width: 49%;
            }
            @media only screen and (min-width: 768px) {
                width: auto;
            }
        }
    }
    .confirmEmail-modal {
        &.aui-modal .modal-header {
            border-bottom: 2px solid #c10e21;
        }
        .confirmEmail-title {
            @media only screen and (min-width: 576px) {
                font-size: 0.9em;
            }
        }
    }
    .confirmEmail-btns {
        .btn {
            width: 100%;
            @media only screen and (min-width: 576px) {
                width: auto;
            }
        }
    }
`;

export const NewRegistryWrapper = styled.div`
    .registry-submit-btns {
        border-top: 1px solid #e3e3e3;
        margin-top: 32px;
        padding-top: 32px;
        padding-bottom: 72px;
        .btn + .btn {
            margin-top: 24px;
            @media only screen and (min-width: 768px) {
                margin-left: 34px;
                margin-top: 0;
            }
        }
        .btn {
            width: 100%;
            @media only screen and (min-width: 768px) {
                width: auto;
            }
        }
    }
`;
