import React from "react";
import { Link } from "react-router-dom";

interface Props {
    activeNav: string;
}

const CorporationSidebar = ({ activeNav }: Props) => {
    return (
        <aside className="aui-sidenav-plane setting-sidebar">
            <nav className="navbar-expand-lg overflow-hidden">
                <button
                    className="navbar-toggler float-right my-3 mx-0"
                    type="button"
                    data-toggle="collapse"
                    data-target="#corporationSideNavbar"
                    aria-controls="corporationSideNavbar"
                    aria-expanded="false"
                    aria-label="navigation menu"
                >
                    <i
                        className="aha-icon-hamburger-round"
                        aria-hidden="true"
                    />
                </button>
                <div
                    className="collapse navbar-collapse"
                    id="corporationSideNavbar"
                >
                    <ul className="w-100">
                        <li
                            className={
                                activeNav == "corporationSettingProfile"
                                    ? "active"
                                    : ""
                            }
                        >
                            <Link
                                to="/corporation/settings/profile"
                                className="active"
                                role="menuitem"
                                aria-current={
                                    activeNav == "corporationSettingProfile"
                                        ? "page"
                                        : false
                                }
                            >
                                Profile
                            </Link>
                        </li>
                    </ul>
                </div>
            </nav>
        </aside>
    );
};

export default CorporationSidebar;
