import styled from "styled-components";

export const DocumentSubmissionWrapper = styled.div`
    .btn-href {
        font-size: 14px;
    }
    .aui-modal {
        .modal-header {
            border-bottom: 2px solid #c10e21;
        }
        .msg-facility {
            border: 1px solid #e3e3e3;
            background-color: #f8f8f8;
        }
    }
    .uploadeddata-modal {
        text-align: left;
        .modal-body {
            min-height: 242px;
        }
    }
    .doc-uploaded-review {
        border: 1px solid #e3e3e3;
        background: #f8f8f8;
        word-break: break-word;
        img {
            width: 28px;
        }
    }
    .document-filters {
        color: #000;
        select {
            width: 100%;
            color: #000;
            border-radius: 4px;
        }
    }
    .upload-doc-button {
        width: 100%;
        @media only screen and (min-width: 768px) {
            width: auto;
        }
    }
    .docs-btns {
        margin-top: 10px;
        @media only screen and (min-width: 576px) {
            margin-top: 0;
        }
        .btn {
            width: 100%;
            @media only screen and (min-width: 576px) {
                width: 48%;
            }
            @media only screen and (min-width: 768px) {
                width: auto;
            }
        }
        .btn + .btn {
            margin-top: 24px;
            @media only screen and (min-width: 576px) {
                margin-left: 4%;
                margin-top: 0;
            }
            @media only screen and (min-width: 768px) {
                margin-left: 24px;
            }
        }
    }
    .view-doc-submit-table.aui-responsive-table {
        tbody {
            tr {
                @media only screen and (min-width: 992px) {
                    border: none;
                }
                th {
                    @media only screen and (min-width: 768px) {
                        width: 35%;
                    }
                    @media only screen and (min-width: 992px) {
                        width: 25%;
                    }
                }
                th,
                td {
                    @media only screen and (min-width: 992px) {
                        padding-top: 12px;
                        padding-bottom: 12px;
                    }
                }
            }
        }
    }
    .doc-log-table {
        max-height: 370px;
        overflow: auto;
        .aui-responsive-status-table {
            * {
                font-size: 14px;
            }
            thead {
                background: #fff;
                th {
                    .aui-th {
                        padding: 10px 12px;
                        font-weight: normal;
                    }
                }
                @media only screen and (min-width: 992px) {
                    th:nth-child(1) {
                        width: 30%;
                    }
                    th:nth-child(2) {
                        width: 30%;
                    }
                    th:nth-child(3) {
                        width: 20%;
                    }
                    th:nth-child(4) {
                        width: 20%;
                    }
                }
            }
            tbody {
                tr {
                    .aui-td {
                        padding: 10px 12px;
                    }
                    td:nth-child(odd) {
                        background-color: #fff;
                    }
                    td:nth-child(even) {
                        background-color: #f8f8f8;
                    }
                    td:nth-child(3) {
                        .cst-select {
                            width: 150px;
                            @media only screen and (min-width: 992px) {
                                width: 100%;
                            }
                            .cst-select-fld {
                                height: 30px;
                                .cst-arrow {
                                    height: 25px;
                                    &:before {
                                        line-height: 28px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .doc-log-detail {
                font-weight: 600;
                margin-top: 8px;
                width: 100%;
                line-height: 16px;
                @media only screen and (min-width: 768px) {
                    display: flex;
                    align-items: flex-end;
                    justify-content: space-between;
                }
            }
            .log-date {
                white-space: nowrap;
                @media only screen and (min-width: 768px) {
                    text-align: right;
                }
            }
            .log-actions {
                .log-heading {
                    font-weight: 600;
                    line-height: 1;
                    margin-bottom: 4px;
                }
                a,
                button {
                    font-weight: normal;
                    border: none;
                    display: block;
                    line-height: 1;
                }
            }
        }
        .doc-action-items {
            > * + * {
                margin-left: 10px;
            }
            i {
                font-size: 18px;
            }
        }
    }
    .aui-modal.doc-action-modal {
        .modal-content {
            padding: 0;
        }
        .modal-header {
            background-color: #f8f8f8;
            border: none;
            padding: 12px 18px 12px 22px;
            margin-bottom: 20px;
            .close {
                font-size: 12px;
            }
        }
        .modal-body {
            padding: 0 22px 18px;
            font-size: 16px;
            min-height: auto;
        }
        .doc-action-content {
            line-height: 28px;
        }
        textarea.form-control {
            height: 90px;
        }
    }
`;
