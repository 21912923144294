import styled from "styled-components";

export const Wrapper = styled.div`
    min-height: 100vh;
`;
export const CardWrapper = styled.div`
    background-color: #f8f8f8;
`;

export const BannerWrapper = styled.div`
    padding-top: 40px;
    padding-bottom: 53px;
    @media only screen and (min-width: 992px) {
        padding-top: 16px;
        padding-bottom: 80px;
    }
`;
export const HospitalDetail = styled.div`
    .measure-help {
        color: #c10e21;
        font-size: 15px;
        background-color: transparent;
        border: none;
        margin: -2px 10px 0 10px;
    }

    .pg-header {
        padding-top: 28px;
        border-bottom: 2px solid #c10e21;
    }
    .agr-cond-check,
    .agr-cond-form {
        margin-bottom: 36px;
    }
    .reg-btn {
        margin: 0;
        padding: 30px 0 150px;
        border-top: 0;
        button {
            width: 100%;
        }
        @media only screen and (min-width: 576px) {
            padding: 20px 0 93px;
        }
        @media only screen and (min-width: 768px) {
            padding: 47px 0;
            margin-top: 43px;
            border-top: 1px solid #e3e3e3;
            button {
                width: auto;
            }
        }
        @media only screen and (min-width: 1200px) {
            padding: 56px 0;
            margin-top: 62px;
        }
    }
    .st-field {
        width: 100px;
    }
    .ehr {
        border: 1px solid #e3e3e3;
        background-color: #f8f8f8;
        input {
            height: 34px;
        }
    }
    .download-wrapper {
        border: 1px solid #e3e3e3;
        background-color: #ffffff;
        padding: 14px;
        @media only screen and (min-width: 576px) {
            padding: 24px;
        }
        .upload-agreement-btn {
            @media only screen and (min-width: 768px) {
                position: absolute;
                right: 0;
                top: calc(50% - 21px);
            }
        }
        .upload-agr-btn {
            width: 100%;
            @media only screen and (min-width: 576px) {
                width: auto;
            }
            @media only screen and (max-width: 575px) {
                padding-left: 16px;
                padding-right: 16px;
            }
        }
    }
    .agmt-title {
        color: #c10e21;
        text-transform: uppercase;
        cursor: pointer;
        font-weight: 400;
        border-radius: 0;
        text-align: left;
        word-break: break-all;
        word-break: break-word;
        font-size: 16px;
    }
    .font-black {
        color: #000;
    }
    .font-red-dark {
        color: #ff0000;
    }
    .agreement-process {
        border: 1px solid #e3e3e3;
        background: #f8f8f8;
        margin-bottom: 44px;
        padding: 16px;
        @media only screen and (min-width: 576px) {
            padding: 24px;
        }
        @media only screen and (min-width: 768px) {
            padding: 24px 33px;
        }
    }
    .agreement-mand {
        color: #c10e21;
        font-size: 16px;
        vertical-align: sub;
        margin-left: 3px;
        @media only screen and (min-width: 768px) {
            font-size: 18px;
        }
    }
    .agreement-steps-header {
        border-bottom: 2px solid #c10e21;
    }
    .agreement-steps {
        padding-left: 18px;
        li {
            span {
                padding-left: 8px;
            }
        }
        li + li {
            margin-top: 8px;
        }
    }
    .contract-details {
        list-style-type: none;
        padding: 0;
        margin: 0;
        @media only screen and (min-width: 768px) {
            position: relative;
            padding: 0 260px 0 0;
        }
        .contract-details-uploaded {
            border-top: 1px solid #e3e3e3;
            padding-top: 10px;
            margin-top: 10px;
        }
        .contract-details-item {
            order: 1;
        }
        .upload-agreement-btn {
            order: 3;
        }
        .contract-details-uploaded {
            order: 2;
        }
    }
    .reg-authorise {
        border: 1px solid #e3e3e3;
        padding: 20px 14px;
        background: #f8f8f8;
        margin-top: 20px;
    }
    .tooltip-item {
        &:focus,
        &:hover {
            & + .tooltip-div {
                display: block;
                top: 50px;
            }
        }
    }
    .benchmark-icon {
        font-size: 15px;
        background-color: transparent;
        border: none;
        margin: -2px 10px 0 10px;
    }
    .title-dropdown {
        .cst-select-dropdown {
            width: 125px;
        }
    }
`;
