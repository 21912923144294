import CONSTANTS from "common/constants";
import Dropdown from "components/Dropdown";
import {
    setCountryCode,
    setStateCode,
} from "components/SearchFilter/searchFilter.slice";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setRegion } from "./region.slice.";

interface Props {
    setDefaultPagination?: any;
    clearFilterInd?: boolean;
    id?: string;
    getFacilitiesResult?: any;
}

export const SelectDomesticInternational = (props: Props) => {
    const dispatch = useDispatch();
    const region = useSelector((state: any) => {
        return state.region;
    });

    const [mounted, setMounted] = useState<boolean>(false);

    useEffect(() => {
        if (mounted) {
            changeRegion(CONSTANTS.ALL);
        }
        setMounted(true);
    }, [props.clearFilterInd]);

    const changeRegion = (value: any) => {
        if (region.region !== value) {
            dispatch(setRegion(value));
            dispatch(setCountryCode(CONSTANTS.ALL));
            dispatch(setStateCode(CONSTANTS.ALL));
            if (typeof props.setDefaultPagination === "function") {
                props.setDefaultPagination();
            }
            if (typeof props.getFacilitiesResult === "function") {
                props.getFacilitiesResult({ regionName: value });
            }
        }
    };

    return (
        <Dropdown
            id={props.id ? props.id : "locationType"}
            items={[
                { label: "All", value: CONSTANTS.ALL },
                { label: "Domestic", value: CONSTANTS.DOMESTIC },
                { label: "International", value: CONSTANTS.INTERNATIONAL },
            ]}
            selectedValue={region.region}
            callParentOnSelect={(value: any) => changeRegion(value)}
        />
    );
};

export default SelectDomesticInternational;
