import styled from "styled-components";

export const MeasureDataTable = styled.div`
    .measure-group-table {
        margin-bottom: 26px;
    }
    .measure-table {
        .measure-subheading-tr {
            margin: 5px 0;
            border-top: 6px solid #fff;
            @media only screen and (min-width: 992px) {
                margin: 0;
            }
            td {
                padding: 2px 0;
                border: none;
                &:before {
                    display: none;
                }
            }
        }
        .measure-subheading-tr + tr {
            border-top-width: 6px;
        }
        .measure-subheading {
            background-color: #c10e21;
            color: #fff;
            padding: 8px;
            font-weight: 500;
            font-size: 16px;
            width: 100%;
            text-align: center;
        }
        table {
        }
        tr {
            td {
                div.aui-td {
                    height: 100%;
                }
                input {
                    height: 40px;
                    @media only screen and (min-width: 992px) {
                        height: 62px;
                        text-align: center;
                    }
                }
            }
            td:first-child {
                @media only screen and (min-width: 992px) {
                    text-align: center;
                }
            }
        }
        thead {
            @media only screen and (min-width: 992px) {
                th:nth-child(1) {
                    width: 12%;
                }
                th:nth-child(2) {
                    width: 17%;
                }
                th:nth-child(3) {
                    width: 29%;
                }
                th:nth-child(4) {
                    width: 16%;
                }
                th:nth-child(5) {
                    width: 16%;
                }
                th:nth-child(6) {
                    width: 10%;
                }
                th:nth-child(4),
                th:nth-child(5),
                th:nth-child(6) {
                    .aui-th {
                        justify-content: center;
                    }
                }
            }
            @media only screen and (min-width: 1200px) {
                th:nth-child(1) {
                    width: 12%;
                }
                th:nth-child(2) {
                    width: 17%;
                }
                th:nth-child(3) {
                    width: 33%;
                }
                th:nth-child(4) {
                    width: 14%;
                }
                th:nth-child(5) {
                    width: 14%;
                }
                th:nth-child(6) {
                    width: 10%;
                }
            }
        }
        tbody {
            @media only screen and (min-width: 992px) {
                td:nth-child(1),
                td:nth-child(2),
                td:nth-child(3) {
                    .aui-td {
                        align-items: flex-start;
                    }
                }
                td:nth-child(4),
                td:nth-child(5),
                td:nth-child(6) {
                    .aui-td {
                        justify-content: center;
                    }
                }
            }
            td:nth-child(6) {
                .form-field {
                    min-height: 24px;
                }
            }
        }
        .measure-table-value {
            vertical-align: middle;
        }
    }
    .measure-table {
        table + table {
            thead {
                background: none;
                tr {
                    th {
                        padding: 0;
                        font-size: 0;
                        .aui-th {
                            padding: 0;
                            border: 0;
                        }
                    }
                }
            }
        }
    }
    .measure-table-btn {
        margin: 40px 0;
        .btn {
            width: 100%;
            @media only screen and (min-width: 768px) {
                width: auto;
            }
        }
    }
    .measure-input {
        position: relative;
        min-height: 40px;
        width: 100%;
        @media only screen and (min-width: 992px) {
            min-height: 62px;
        }
        input[type="number"] {
            padding-right: 36px;
            @media only screen and (min-width: 992px) {
                padding-right: 12px;
            }
        }
    }
    .measure-help {
        margin-right: 10px;
        color: #c10e21;
        font-size: 14px;
        @media only screen and (min-width: 992px) {
            position: absolute;
            left: 4px;
            bottom: 6px;
        }
    }
    .subMeasure-help {
        margin-right: 10px;
        color: #c10e21;
        font-size: 14px;
        @media only screen and (min-width: 992px) {
            position: absolute;
            left: 4px;
            bottom: 10px;
        }
    }
    .readonly-metric {
        width: 100%;
        height: 34px;
    }
    .metric-table {
        .metric-subheading-tr {
            margin: 0 -1px -1px;
            padding: 4px 0 16px;
            caption-side: top;
            @media only screen and (min-width: 992px) {
                margin: 0;
            }
            td {
                padding: 2px 0;
                @media only screen and (min-width: 576px) {
                    &:before {
                        display: none;
                    }
                }
            }
        }
        .metric-subheading {
            background-color: #222328;
            color: #fff;
            padding: 8px;
            font-weight: 500;
            font-size: 16px;
            width: 100%;
            text-align: center;
        }
        tr {
            td {
                .aui-td {
                    height: 100%;
                }
                input {
                    height: 40px;
                    @media only screen and (min-width: 992px) {
                        height: 62px;
                        text-align: center;
                    }
                }
            }
            td:first-child {
                @media only screen and (min-width: 992px) {
                    text-align: center;
                }
            }
        }
        thead {
            @media only screen and (min-width: 992px) {
                th:nth-child(1) {
                    width: 140px;
                }
                th:nth-child(2) {
                    width: 230px;
                }
                th:nth-child(3) {
                    width: auto;
                }
                th:nth-child(4) {
                    width: 160px;
                    .aui-th {
                        justify-content: center;
                    }
                }
            }
        }
        tbody {
            td:nth-child(1),
            td:nth-child(2),
            td:nth-child(3) {
                .aui-td {
                    align-items: flex-start;
                }
            }
        }
        .measure-table-value {
            vertical-align: middle;
        }
        .form-field {
            position: relative;
            input {
                padding-right: 36px;
                font-size: 14px;
                @media only screen and (min-width: 992px) {
                    padding-left: 22px;
                }
            }
            .form-field-per {
                position: absolute;
                right: 22px;
                top: 6px;
            }
            .metric-form-field-per {
                position: absolute;
                right: 16px;
                top: 10px;
                @media only screen and (min-width: 992px) {
                    right: 22px;
                    top: 21px;
                }
            }
        }
        .metric-sub-col1 {
            @media only screen and (min-width: 992px) {
            }
        }
    }
    .metric-subtable {
        padding: 0;
        border: none;
        &:before {
            width: 0;
            padding: 0;
        }
    }
    .metric-sub-col2 {
        width: auto;
        border-right: none !important;
        .aui-td {
            border-right: none !important;
            border-bottom: none !important;
        }
    }
    .metric-sub-desc {
        width: auto;
    }
    .metric-subtable .metric-inner-table {
        margin-top: -1px;
        position: relative;
        @media only screen and (min-width: 992px) {
            &:before {
                width: 1px;
                height: calc(100% - 16px);
                border-left: 1px solid #707070;
                top: 8px;
                position: absolute;
                left: 0;
                content: "";
            }
        }
        thead {
            tr {
                border: none;
            }
            th {
                padding: 0;
                border: 0;
            }
            th:nth-child(1) {
                width: 110px;
            }
            th:nth-child(2) {
                width: 120px;
            }
            th:nth-child(3) {
                width: auto;
            }
            th:nth-child(4) {
                width: 160px;
            }
        }
        tr td {
            input {
                height: 34px;
            }
        }
        tbody {
            tr {
                margin-bottom: 0;
                @media only screen and (min-width: 992px) {
                    display: table-row;
                }
                td:nth-child(2) {
                    flex-grow: 1;
                    @media only screen and (min-width: 992px) {
                        flex-grow: unset;
                    }
                }
                div.aui-td {
                    border-left: none;
                    text-align: left;
                    @media only screen and (min-width: 992px) {
                        border-bottom-width: 0;
                        border-left: 1px solid #e3e3e3;
                        border-left: 0;
                    }
                }
                td:first-child {
                    &:before,
                    .aui-td {
                        @media only screen and (max-width: 991.98px) {
                            border-top: none;
                        }
                    }
                }
                td:last-child {
                    &:before {
                        border-bottom: none;
                    }
                }
            }
            tr + tr {
                border-top: 0;
            }
            tr:last-child {
                border-bottom: none;
                .aui-td {
                    border-bottom-width: 1px;
                }
                td:last-child {
                    .aui-td {
                        @media only screen and (min-width: 992px) {
                            border-bottom: 1px solid #e3e3e3;
                        }
                    }
                }
            }
            td + td {
                @media only screen and (min-width: 992px) {
                    border-left-width: 1px;
                }
            }
        }
        .submetric-tr {
            @media only screen and (max-width: 991.98px) {
                overflow: hidden;
                margin: 16px;
                border: 1px solid #e3e3e3;
                border-bottom: 1px solid #e3e3e3 !important;
                td:before {
                    display: none;
                }
                td {
                    float: left;
                    border: none;
                }
                td:nth-child(1) {
                    width: 20%;
                    padding-bottom: 10px;
                }
                td:nth-child(2) {
                    width: 80%;
                    padding-bottom: 0;
                }
                td:nth-child(3) {
                    width: 80%;
                    margin-left: 20%;
                    padding-top: 0;
                    padding-left: 0;
                    .form-field {
                        width: 104px;
                    }
                }
            }
            @media only screen and (max-width: 767.98px) {
                td:nth-child(1) {
                    width: 30%;
                    padding: 10px;
                }
                td:nth-child(2) {
                    width: 70%;
                    padding: 10px;
                }
                td:nth-child(3) {
                    width: 70%;
                    margin-left: 30%;
                    padding-top: 0;
                    padding-left: 10px;
                    .form-field {
                        width: 104px;
                    }
                }
            }
            .metric-sub-input {
                border-right: none;
                .aui-td {
                    border-right: none;
                    border-bottom: none;
                    border-left: none;
                }
            }
            .metric-sub-no,
            .metric-sub-desc {
                .aui-td {
                    border-right: none;
                    width: 100%;
                    border-bottom: none;
                }
            }
            .metric-sub-input {
                .aui-td {
                    padding-top: 0;
                    min-height: 33px;
                    @media only screen and (min-width: 992px) {
                        padding-top: 12px;
                    }
                }
            }
            .metric-sub-desc {
                font-weight: 600;
                @media only screen and (min-width: 992px) {
                    font-weight: normal;
                }
            }
        }
        .submetric-tr + .submetric-tr {
            @media only screen and (max-width: 991.98px) {
                margin-top: -16px;
                border-top: none;
                background-color: #f8f8f8;
            }
        }
        .submetric-tr:last-child {
            .metric-sub-no,
            .metric-sub-desc {
                .aui-td {
                    @media only screen and (min-width: 992px) {
                        border-bottom: 1px solid #e3e3e3;
                    }
                }
            }
        }
    }
    .submetric-outer {
        @media only screen and (max-width: 991.98px) {
            border-left: 1px solid #e3e3e3;
            border-right: 1px solid #e3e3e3;
            > td:last-child {
                border: none;
                padding: 0;
                height: 0;
            }
            td {
                border-left: 0;
                border-right: 0;
                border-bottom: 1px solid #e3e3e3;
            }
            .aui-td {
                width: 65%;
            }
        }
        @media only screen and (max-width: 575.98px) {
            .aui-td {
                width: 100%;
            }
        }
        .submetric-outer-tdlast {
            &:before {
                display: none;
            }
            .aui-td {
                display: none;
                @media only screen and (min-width: 992px) {
                    display: block;
                }
            }
        }
    }
    .submetric-outer:nth-child(odd),
    .submetric-outer:nth-child(even) {
        background-color: #fff;
    }
    .metric-table {
        tr:not(.submetric-outer) {
            .aui-td {
                .form-field {
                    width: 200px;
                    @media only screen and (min-width: 992px) {
                        width: auto;
                    }
                }
            }
        }
    }
`;
