import React, { useEffect, useState } from "react";
import {
    createHospital,
    getHospitalById,
    updateHospital,
} from "api/hospitalApi";
import { getNavigationByHospitalId } from "api/usersApi";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { getCountries, getStates } from "api/countriesAPI";
import LocalStore from "common/localStore";
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import { useDispatch } from "react-redux";
import CONSTANTS from "common/constants";
import Dropdown from "components/Dropdown";
import { logError } from "utils/logger.utils";
import { BreadCrumb } from "components/BreadCrumb";
import SearchDropdown from "components/SearchDropdown";
import { HospitalDetail } from "../../styled";
import { showToast } from "../../components/Toast/toast.slice";

const HospitalInfo = (props: any) => {
    const history = useHistory();
    const params: any = useParams();
    const location = useLocation();
    const [hospitalInfo, setHospitalInfo] = useState<any>({
        countryCode: CONSTANTS.DOMESTIC_COUNTRY_CODE,
        countryId: "",
        primaryContactTitle: "Mr.",
        signatoryNameTitle: "Mr.",
        medicalDirectorTitle: "Mr.",
        state: "",
    });
    const [hospitalId, setHospitalId] = useState<any>("");
    const [countries, setCountries] = useState<[]>([]);
    const [states, setStates] = useState<[]>([]);
    const [selectedCountryValue, setSelectedCountryValue] = useState<string>();
    const dispatch = useDispatch();
    const [isRegistrationInProgress, setIsRegistrationInProgress] =
        useState<boolean>(false);
    const [facilityTypes, setFacilityTypes] = useState<any>(null);
    const [breadcrumbItems, setBreadcrumbItems] = useState<any>([]);

    const getCountriesList = () => {
        getCountries().then((response) => {
            if (response.data && response.data.countries) {
                const countryList = response.data.countries;

                if (countryList.length) {
                    const countryMap: any = [];
                    countryList.map((country: any) => {
                        countryMap.push({
                            label: country.countryName,
                            value: `${country.countryId}:${country.countryName}:${country.countryCode}`,
                        });
                    });
                    setCountries(countryMap);
                    setHospitalId(parseInt(params.hospitalId));
                    if (!Number.isNaN(parseInt(params.hospitalId))) {
                        getHospitalById(params.hospitalId)
                            .then((hospitalInfoResponse: any) => {
                                if (hospitalInfoResponse.success) {
                                    setHospitalInfo({
                                        ...hospitalInfoResponse.data.hospital,
                                    });
                                }
                                setSelectedCountryValue(
                                    `${hospitalInfoResponse.data.hospital.countryId}:${hospitalInfoResponse.data.hospital.countryName}:${hospitalInfoResponse.data.hospital.countryCode}`
                                );
                                dispatch(
                                    setPageLoadingStatus({
                                        isPageLoading: false,
                                    })
                                );
                            })
                            .catch((error: any) => {
                                dispatch(
                                    setPageLoadingStatus({
                                        isPageLoading: false,
                                    })
                                );
                                logError(error);
                            });
                    } else {
                        const defaultCountry: any = countryMap.filter(
                            (c: any) => {
                                return (
                                    c.label == CONSTANTS.DOMESTIC_COUNTRY_NAME
                                );
                            }
                        )[0];
                        const selectedCountry =
                            defaultCountry.value?.split(":");
                        setHospitalInfo({
                            ...hospitalInfo,
                            ...{
                                countryId: selectedCountry[0],
                                countryName: selectedCountry[1],
                                countryCode: selectedCountry[2],
                            },
                        });
                        setSelectedCountryValue(
                            `${selectedCountry[0]}:${selectedCountry[1]}:${selectedCountry[2]}`
                        );
                        dispatch(
                            setPageLoadingStatus({ isPageLoading: false })
                        );
                    }
                }
            }
        });
    };

    const getStatesList = () => {
        getStates(hospitalInfo.countryCode).then((response) => {
            if (response.statusCode === 200) {
                const stateList = response.data;

                if (stateList.length) {
                    const stateMap: any = [];
                    stateList.map((country: any) => {
                        stateMap.push({
                            label: country.name,
                            value: country.name,
                        });
                    });
                    setStates(stateMap);
                }
            }
        });
    };

    useEffect(
        () => {
            dispatch(setPageLoadingStatus({ isPageLoading: true }));
            const elementsArray = [];
            elementsArray.push(
                {
                    label: "Home",
                    returnPath: {
                        pathname: CONSTANTS.ROUTES.USER_DASHBOARD,
                        state: {
                            pageNum: location?.state?.pageNum,
                            search: location?.state?.search,
                        },
                    },
                },
                {
                    label: "Organization Details",
                    returnPath: "",
                }
            );
            setBreadcrumbItems(elementsArray);

            if (!Number.isNaN(parseInt(params.hospitalId))) {
                getNavigationByHospitalId(params.hospitalId).then(
                    (navigationResponse: any) => {
                        const { entity, path } = navigationResponse.data.url;
                        if (
                            entity === CONSTANTS.HOSPITAL &&
                            path === CONSTANTS.THANKYOU_PAGE
                        ) {
                            dispatch(
                                setPageLoadingStatus({ isPageLoading: false })
                            );
                            history.push("/successpayment");
                        } else if (
                            entity === CONSTANTS.HOSPITAL &&
                            path === CONSTANTS.PATHS.DASHBOARD
                        ) {
                            dispatch(
                                setPageLoadingStatus({ isPageLoading: false })
                            );
                            history.push("/userDashboard");
                        } else {
                            getCountriesList();
                            getStatesList();
                        }
                    }
                );
            } else {
                getCountriesList();
                getStatesList();
            }
        },
        [
            /* props.location.pathname */
        ]
    );

    const handleSubmit = (event: any) => {
        let info: any = { ...hospitalInfo };
        event.preventDefault();
        setIsRegistrationInProgress(true);
        if (!info.countryCode || !info.countryId || !info.countryName) {
            const toast = {
                message: "Please select location.",
                code: "Error:",
            };
            dispatch(showToast(toast));
            setIsRegistrationInProgress(false);
            return;
        }
        if (
            hospitalInfo.medicalDirectorName == "" ||
            hospitalInfo.medicalDirectorName == null
        ) {
            info = {
                ...hospitalInfo,
                medicalDirectorTitle: null,
                medicalDirectorName: null,
            };
        }
        if (
            (hospitalInfo.medicalDirectorName != "" ||
                hospitalInfo.medicalDirectorName != null) &&
            hospitalInfo.medicalDirectorTitle == null
        ) {
            info = {
                ...hospitalInfo,
                medicalDirectorTitle: "Mr.",
            };
        }
        if (!hospitalInfo.healthRecord) {
            delete info.erhName;
        }
        if (hospitalId) {
            updateHospital(hospitalId, info)
                .then((response: any) => {
                    if (response.success) {
                        history.push(`/programs`);
                    }
                })
                .catch((error) => {
                    setIsRegistrationInProgress(false);
                });
        } else {
            // POST API CALL
            createHospital(info)
                .then(async (response: any) => {
                    if (response.success) {
                        localStorage.setItem(
                            "selectedHospitalId",
                            response.data.hospital.hospitalId
                        );
                        localStorage.setItem(
                            "selectedHospitalCountryCode",
                            response.data.hospital.countryCode
                        );
                        localStorage.setItem(
                            "selectedHospitalCountryId",
                            response.data.hospital.countryId
                        );
                        history.replace(
                            `/hospital/register/${response.data.hospital.hospitalId}`
                        );
                        history.push(`/programs`);
                        LocalStore.set(
                            "hospitalId",
                            response.data.hospital.hospitalId
                        );
                        LocalStore.set(
                            "countryId",
                            response.data.hospital.countryId
                        );
                    }
                })
                .catch((error) => {
                    setIsRegistrationInProgress(false);
                });
        }
    };

    const handleChange = (event: any) => {
        if (event.target.name === "website") {
            setHospitalInfo({
                ...hospitalInfo,
                [event.target.name]: event.target.value.toLowerCase(),
            });
        } else {
            setHospitalInfo({
                ...hospitalInfo,
                [event.target.name]:
                    event.target.value.trim() == "healthRecord_yes"
                        ? true
                        : event.target.value.trim() == "healthRecord_no"
                        ? false
                        : event.target.type == "textarea" ||
                          event.target.name == "city" ||
                          event.target.name == "facilityName" ||
                          event.target.name == "state"
                        ? event.target.value.replace(/[><]/g, "")
                        : event.target.value,
            });
        }
    };

    const changeCountry = (event: any) => {
        const value: any =
            typeof event === "object" ? event.target.value : event;
        let selectedCountry: any = [];
        if (value == "") {
            selectedCountry = ["", "", ""];
        } else if (value == CONSTANTS.DOMESTIC_COUNTRY_CODE) {
            const defaultCountry: any = countries.filter((c: any) => {
                return c.label == CONSTANTS.DOMESTIC_COUNTRY_NAME;
            })[0];
            selectedCountry = defaultCountry.value?.split(":");
        } else {
            selectedCountry = value?.split(":");
        }
        setSelectedCountryValue(
            `${selectedCountry[0]}:${selectedCountry[1]}:${selectedCountry[2]}`
        );
        setHospitalInfo({
            ...hospitalInfo,
            ...{
                countryId: selectedCountry[0],
                countryName: selectedCountry[1],
                countryCode: selectedCountry[2],
                state: "",
            },
        });
    };

    return (
        <HospitalDetail>
            <div className="container reg-container">
                <div className="mb-5 pg-header">
                    <BreadCrumb breadcrumbItems={breadcrumbItems} />
                    <h1 className="h2 mb-2">Registration</h1>
                    <p>Please complete the following Registration Form</p>
                </div>
                <div aria-labelledby="reg-info" role="group">
                    <div className="row">
                        <div className="col-lg-11 d-block d-sm-flex justify-content-sm-between mb-4 align-items-center">
                            <h2 className="h3 mb-0" id="reg-info">
                                Information
                            </h2>
                            <div className="pt-3 pt-sm-0 mand-field">
                                <sup>*</sup>
                                mandatory fields
                            </div>
                        </div>
                    </div>
                    <form className="reg-form" onSubmit={handleSubmit}>
                        <fieldset disabled={isRegistrationInProgress}>
                            <div className="form-group row required">
                                <div className="col-md-5 form-label">
                                    <label
                                        htmlFor="facilityName"
                                        className="label-form"
                                    >
                                        Organization Name
                                        <sup>*</sup>
                                    </label>
                                </div>
                                <div className="col-md-7 col-lg-6">
                                    <input
                                        minLength={3}
                                        maxLength={200}
                                        value={hospitalInfo.facilityName || ""}
                                        onChange={handleChange}
                                        type="text"
                                        className="form-control"
                                        name="facilityName"
                                        id="facilityName"
                                        required
                                        aria-describedby="fac-name"
                                        aria-required="true"
                                    />
                                    <div className="form-help" id="fac-name">
                                        (Enter 3 to 200 characters)
                                    </div>
                                </div>
                            </div>
                            <div
                                className="form-group row required"
                                role="group"
                                aria-labelledby="chooseLocation"
                            >
                                <div className="col-md-5 form-label">
                                    <label
                                        id="chooseLocation"
                                        className="label-form"
                                    >
                                        Choose Region
                                        <sup>*</sup>
                                    </label>
                                </div>
                                <div className="col-md-7 col-lg-6">
                                    <div
                                        className="d-flex flex-wrap"
                                        role="radiogroup"
                                        aria-labelledby="chooseLocation"
                                    >
                                        <div className="form-group form-radio pr-md-5 pr-lg-4 pr-xl-4 mb-1 mb-lg-3 mb-xl-0 mr-lg-0 mr-5">
                                            <input
                                                type="radio"
                                                name="countryName"
                                                defaultValue={
                                                    CONSTANTS.DOMESTIC_COUNTRY_CODE
                                                }
                                                checked={
                                                    hospitalInfo.countryCode ===
                                                    CONSTANTS.DOMESTIC_COUNTRY_CODE
                                                }
                                                disabled={hospitalId || false}
                                                onChange={changeCountry}
                                                id="domestic"
                                                required
                                            />
                                            <label htmlFor="domestic">
                                                US and US Territories
                                            </label>
                                        </div>
                                        <div className="form-group form-radio pr-md-5 mb-0 ml-xl-5 mr-md-5 mr-lg-0">
                                            <input
                                                type="radio"
                                                name="countryName"
                                                defaultValue=""
                                                checked={
                                                    hospitalInfo.countryCode !==
                                                    CONSTANTS.DOMESTIC_COUNTRY_CODE
                                                }
                                                disabled={hospitalId || false}
                                                onChange={changeCountry}
                                                id="international"
                                                required
                                            />
                                            <label htmlFor="international">
                                                International
                                            </label>
                                        </div>
                                    </div>

                                    {hospitalInfo.countryCode !==
                                    CONSTANTS.DOMESTIC_COUNTRY_CODE ? (
                                        <div className="row mt-2 pt-1">
                                            <div className="col-12">
                                                <SearchDropdown
                                                    disabled={!!hospitalId}
                                                    items={countries.filter(
                                                        (country: any) =>
                                                            country.label !=
                                                            CONSTANTS.DOMESTIC_COUNTRY_NAME
                                                    )}
                                                    selectedValue={
                                                        selectedCountryValue
                                                    }
                                                    callParentOnSelect={
                                                        changeCountry
                                                    }
                                                    id="country"
                                                    label="international"
                                                    placeholder="Select Location"
                                                    mandatoryInd
                                                    ariaInnerLabelValue="countries"
                                                />
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="form-group row required">
                                <div className="col-md-5 form-label">
                                    <label
                                        htmlFor="streetAddress"
                                        className="label-form"
                                    >
                                        Street Address
                                        <sup>*</sup>
                                    </label>
                                </div>
                                <div className="col-md-7 col-lg-6">
                                    <textarea
                                        value={hospitalInfo.streetAddress}
                                        onChange={handleChange}
                                        className="form-control"
                                        name="streetAddress"
                                        id="streetAddress"
                                        rows={3}
                                        required
                                        minLength={2}
                                        maxLength={300}
                                        aria-describedby="fac-streetAddress"
                                    />
                                    <div
                                        className="form-help"
                                        id="fac-streetAddress"
                                    >
                                        (Enter 2 to 300 characters)
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row required">
                                <div className="col-md-5 form-label">
                                    <label
                                        htmlFor="city"
                                        className="label-form"
                                    >
                                        City<sup>*</sup>
                                    </label>
                                </div>
                                <div className="col-md-7 col-lg-6">
                                    <input
                                        value={hospitalInfo.city}
                                        type="text"
                                        onChange={handleChange}
                                        className="form-control"
                                        name="city"
                                        id="city"
                                        required
                                        minLength={2}
                                        maxLength={30}
                                        aria-describedby="fac-city"
                                    />
                                    <div className="form-help" id="fac-city">
                                        (Enter 2 to 30 characters)
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row required">
                                <div className="col-md-5 form-label">
                                    <label
                                        htmlFor="state-registration"
                                        id="state-registration-label"
                                        className="label-form"
                                    >
                                        State / Province
                                        <sup>*</sup>
                                    </label>
                                </div>
                                <div className="col-md-7 col-lg-6">
                                    {hospitalInfo.countryCode ==
                                    CONSTANTS.DOMESTIC_COUNTRY_CODE ? (
                                        <SearchDropdown
                                            id="state-registration"
                                            items={states}
                                            selectedValue={hospitalInfo.state}
                                            callParentOnSelect={(value: any) =>
                                                setHospitalInfo({
                                                    ...hospitalInfo,
                                                    state: value,
                                                })
                                            }
                                            disabled={isRegistrationInProgress}
                                            label="State / Province"
                                            placeholder="Select State"
                                            mandatoryInd
                                        />
                                    ) : (
                                        <>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="state"
                                                id="state"
                                                onChange={handleChange}
                                                value={hospitalInfo.state}
                                                required
                                                minLength={2}
                                                maxLength={30}
                                                aria-describedby="fac-state"
                                            />
                                            <div
                                                className="form-help"
                                                id="fac-state"
                                            >
                                                (Enter 2 to 30 characters)
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className="form-group row required">
                                <div className="col-md-5 form-label">
                                    <label htmlFor="zipCode">
                                        Zip Code / Postal Code
                                    </label>
                                </div>
                                <div className="col-md-7 col-lg-6">
                                    <input
                                        minLength={2}
                                        maxLength={9}
                                        pattern="\d*"
                                        onChange={handleChange}
                                        value={hospitalInfo.zipCode}
                                        type="text"
                                        className="form-control"
                                        name="zipCode"
                                        id="zipCode"
                                        required
                                        aria-describedby="postalCode"
                                    />
                                    <div className="form-help" id="postalCode">
                                        (Enter 2 to 9 characters)
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-md-5 form-label">
                                    <label htmlFor="website">Website</label>
                                </div>
                                <div className="col-md-7 col-lg-6">
                                    <input
                                        pattern={CONSTANTS.REGEX_ALLOW_WEBSITE}
                                        onChange={handleChange}
                                        value={hospitalInfo.website}
                                        type="text"
                                        className="form-control"
                                        name="website"
                                        id="website"
                                        minLength={2}
                                        maxLength={30}
                                        aria-describedby="fac-website"
                                    />
                                    <div className="form-help" id="fac-website">
                                        (Enter 2 to 30 characters)
                                    </div>
                                </div>
                            </div>

                            <div
                                className="form-group row  required"
                                role="group"
                                aria-labelledby="pricontact"
                            >
                                <div className="col-md-5 form-label">
                                    <label
                                        id="pricontact"
                                        className="label-form"
                                    >
                                        Primary Contact Name
                                        <sup>*</sup>
                                    </label>
                                </div>
                                <div className="col-md-7 col-lg-6 d-flex">
                                    <div className="st-field pr-0 mr-3 title-dropdown">
                                        <Dropdown
                                            id="primaryContactNameTitle"
                                            items={CONSTANTS.TITLE_DROPDOWN}
                                            selectedValue={
                                                hospitalInfo.primaryContactTitle
                                            }
                                            callParentOnSelect={(value: any) =>
                                                setHospitalInfo({
                                                    ...hospitalInfo,
                                                    primaryContactTitle: value,
                                                })
                                            }
                                            disabled={isRegistrationInProgress}
                                            ariaInnerLabelValue="title"
                                        />
                                    </div>
                                    <div className="flex-grow-1">
                                        <input
                                            pattern={
                                                CONSTANTS.REGEX_ALLOW_ALPHABHET_SPACE
                                            }
                                            onChange={handleChange}
                                            value={
                                                hospitalInfo.primaryContactName
                                            }
                                            type="text"
                                            className="form-control"
                                            name="primaryContactName"
                                            id="primaryContactName"
                                            required
                                            minLength={2}
                                            maxLength={30}
                                            aria-describedby="fac-priName"
                                            aria-label="Name"
                                        />
                                        <div
                                            className="form-help"
                                            id="fac-priName"
                                        >
                                            (Enter 2 to 30 characters)
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group row required">
                                <div className="col-md-5 form-label">
                                    <label
                                        htmlFor="primaryContactPhoneNumber"
                                        className="label-form"
                                    >
                                        Primary Contact Phone Number
                                        <sup>*</sup>
                                    </label>
                                </div>
                                <div className="col-md-7 col-lg-6">
                                    <input
                                        minLength={10}
                                        maxLength={20}
                                        pattern="\+?\d*"
                                        onChange={handleChange}
                                        value={
                                            hospitalInfo.primaryContactPhoneNumber
                                        }
                                        type="text"
                                        className="form-control"
                                        name="primaryContactPhoneNumber"
                                        id="primaryContactPhoneNumber"
                                        required
                                        aria-describedby="primaryPhone"
                                    />
                                    <div
                                        className="form-help"
                                        id="primaryPhone"
                                    >
                                        (Enter 10 to 20 characters)
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row required">
                                <div className="col-md-5 form-label">
                                    <label
                                        htmlFor="primaryEmail"
                                        className="label-form"
                                    >
                                        Primary Contact Email
                                        <sup>*</sup>
                                    </label>
                                </div>
                                <div className="col-md-7 col-lg-6">
                                    <input
                                        pattern="^[A-Za-z0-9._%+-]+@([A-Za-z0-9]+\.)+[A-Za-z]{2,3}$"
                                        onChange={handleChange}
                                        value={hospitalInfo.primaryEmail}
                                        type="email"
                                        className="form-control"
                                        name="primaryEmail"
                                        id="primaryEmail"
                                        required
                                    />
                                </div>
                            </div>
                            <div
                                className="form-group row  required"
                                role="group"
                                aria-labelledby="signatory"
                            >
                                <div className="col-md-5 form-label">
                                    <label
                                        id="signatory"
                                        className="label-form"
                                    >
                                        Signatory Name
                                    </label>
                                    <sup>*</sup>
                                </div>
                                <div className="col-md-7 col-lg-6 d-flex">
                                    <div className="st-field pr-0 mr-3 title-dropdown">
                                        <Dropdown
                                            id="signatoryNameTitle"
                                            items={CONSTANTS.TITLE_DROPDOWN}
                                            selectedValue={
                                                hospitalInfo.signatoryNameTitle
                                            }
                                            callParentOnSelect={(value: any) =>
                                                setHospitalInfo({
                                                    ...hospitalInfo,
                                                    signatoryNameTitle: value,
                                                })
                                            }
                                            disabled={isRegistrationInProgress}
                                            ariaInnerLabelValue="title"
                                        />
                                    </div>
                                    <div className="flex-grow-1">
                                        <input
                                            pattern={
                                                CONSTANTS.REGEX_ALLOW_ALPHABHET_SPACE
                                            }
                                            onChange={handleChange}
                                            value={hospitalInfo.signatoryName}
                                            type="text"
                                            className="form-control"
                                            name="signatoryName"
                                            id="signatoryName"
                                            required
                                            minLength={2}
                                            maxLength={30}
                                            aria-describedby="fac-sigName"
                                            aria-label="Name"
                                        />
                                        <div
                                            className="form-help"
                                            id="fac-sigName"
                                        >
                                            (Enter 2 to 30 characters)
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row required">
                                <div className="col-md-5 form-label">
                                    <label
                                        htmlFor="signatoryEmail"
                                        className="label-form"
                                    >
                                        Signatory Email
                                        <sup>*</sup>
                                    </label>
                                </div>
                                <div className="col-md-7 col-lg-6 d-flex">
                                    <input
                                        pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$"
                                        onChange={handleChange}
                                        value={hospitalInfo.signatoryEmail}
                                        type="email"
                                        className="form-control"
                                        name="signatoryEmail"
                                        id="signatoryEmail"
                                        required
                                    />
                                </div>
                            </div>
                            <div
                                className="form-group row"
                                role="group"
                                aria-labelledby="medicalDirector"
                            >
                                <div className="col-md-5 form-label">
                                    <label id="medicalDirector">
                                        Medical Director Name (If applicable)
                                    </label>
                                </div>
                                <div className="col-md-7 col-lg-6 d-flex">
                                    <div className="st-field pr-0 mr-3 title-dropdown ">
                                        <Dropdown
                                            id="medicalDirectorTitle"
                                            items={CONSTANTS.TITLE_DROPDOWN}
                                            selectedValue={
                                                hospitalInfo.medicalDirectorTitle
                                            }
                                            callParentOnSelect={(value: any) =>
                                                setHospitalInfo({
                                                    ...hospitalInfo,
                                                    medicalDirectorTitle: value,
                                                })
                                            }
                                            disabled={isRegistrationInProgress}
                                            ariaInnerLabelValue="title"
                                        />
                                    </div>
                                    <div className="flex-grow-1">
                                        <input
                                            pattern={
                                                CONSTANTS.REGEX_ALLOW_ALPHABHET_SPACE
                                            }
                                            onChange={handleChange}
                                            value={
                                                hospitalInfo.medicalDirectorName
                                            }
                                            type="text"
                                            className="form-control"
                                            name="medicalDirectorName"
                                            id="medicalDirectorName"
                                            minLength={2}
                                            maxLength={30}
                                            aria-describedby="fac-medName"
                                            aria-label="Name"
                                        />
                                        <div
                                            className="form-help"
                                            id="fac-medName"
                                        >
                                            (Enter 2 to 30 characters)
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-md-5 form-label">
                                    <label htmlFor="medicalDirectorEmail">
                                        Medical Director Email (If applicable)
                                    </label>
                                </div>
                                <div className="col-md-7 col-lg-6 d-flex">
                                    <input
                                        pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$"
                                        onChange={handleChange}
                                        value={
                                            hospitalInfo.medicalDirectorEmail
                                        }
                                        type="email"
                                        className="form-control"
                                        name="medicalDirectorEmail"
                                        id="medicalDirectorEmail"
                                    />
                                </div>
                            </div>
                            <div
                                className="form-group row required"
                                aria-labelledby="ehrrecord"
                                role="radiogroup"
                            >
                                <div className="col-md-5 form-label">
                                    <label
                                        id="ehrrecord"
                                        aria-label="Does your site use an Electronic Health Record(E H R)?"
                                        className="label-form"
                                    >
                                        <span className="d-block">
                                            Does your site use an Electronic
                                            Health Record(EHR)?
                                            <sup>*</sup>
                                        </span>
                                        <i className="label-helptext">
                                            (Select NO if you are an EMS
                                            organization.)
                                        </i>
                                    </label>
                                </div>
                                <div className="col-md-7 col-lg-6">
                                    <div className="d-flex flex-row flex-wrap">
                                        <div className="form-radio mr-4">
                                            <input
                                                checked={
                                                    hospitalInfo.healthRecord ===
                                                    true
                                                }
                                                onChange={handleChange}
                                                type="radio"
                                                name="healthRecord"
                                                value="healthRecord_yes"
                                                id="recored-yes"
                                                required
                                            />
                                            <label
                                                htmlFor="recored-yes"
                                                id="yes-record"
                                            >
                                                Yes
                                            </label>
                                        </div>
                                        <div className="form-radio ml-4">
                                            <input
                                                checked={
                                                    hospitalInfo.healthRecord ===
                                                    false
                                                }
                                                onChange={handleChange}
                                                value="healthRecord_no"
                                                type="radio"
                                                name="healthRecord"
                                                id="recored-no"
                                                required
                                            />
                                            <label htmlFor="recored-no">
                                                No
                                            </label>
                                        </div>
                                    </div>
                                    {hospitalInfo.healthRecord === true ? (
                                        <div className="ehr form-group row p-2 mt-3 mx-0 align-items-center d-block d-md-flex">
                                            <label
                                                className=" p-0 pr-3"
                                                htmlFor="erhName"
                                                aria-label="Name of E H R"
                                            >
                                                Name of EHR
                                            </label>
                                            <div className="flex-grow-1">
                                                <input
                                                    pattern={
                                                        CONSTANTS.REGEX_ALLOW_ALPHANUMERIC_AND_SPACE_ONLY
                                                    }
                                                    onChange={handleChange}
                                                    value={hospitalInfo.erhName}
                                                    type="text"
                                                    className="form-control"
                                                    name="erhName"
                                                    id="erhName"
                                                    required={
                                                        hospitalInfo.healthRecord ===
                                                        true
                                                    }
                                                />
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="reg-btn row mx-0">
                                <div className="col-md-6 offset-md-5 px-0 px-md-3">
                                    <button
                                        disabled={isRegistrationInProgress}
                                        type="submit"
                                        className={`btn btn-round btn-primary ${
                                            isRegistrationInProgress
                                                ? CONSTANTS.BUTTON_SPINNER
                                                : ""
                                        }`}
                                        aria-label="Continue to Program selection page"
                                    >
                                        Continue
                                    </button>
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </div>
            </div>
        </HospitalDetail>
    );
};

export default HospitalInfo;
