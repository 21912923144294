import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setSortOrganizations } from "components/SearchFilter/searchFilter.slice";
import CONSTANTS from "common/constants";
import config from "../../../config";
import { SidebarWrapper } from "./styled";

interface Props {
    activeTab: string;
}
export const Sidebar = ({ activeTab }: Props) => {
    const dispatch = useDispatch();
    useEffect(() => {
        if (activeTab !== "hospitalManagement") {
            dispatch(setSortOrganizations(CONSTANTS.ALL));
        }
    }, []);
    return (
        <SidebarWrapper className="aui-sidenav">
            <nav className="navbar-expand-lg">
                <button
                    className="navbar-toggler btn-mainmenu-sm collapsed m-0"
                    type="button"
                    data-toggle="collapse"
                    data-target="#adminNavbar"
                    aria-controls="adminNavbar"
                    aria-expanded="false"
                    aria-label="main menu navigation"
                >
                    Menu
                    <i className="aha-icon-arrow-down" aria-hidden="true" />
                </button>
                <div className="collapse navbar-collapse" id="adminNavbar">
                    <ul className="w-100">
                        <li>
                            <Link
                                to="/admin/dashboard"
                                className={
                                    activeTab == "dashboard" ? "active" : ""
                                }
                                aria-current={
                                    activeTab == "dashboard" ? "page" : false
                                }
                            >
                                Dashboard
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/admin/organization-management"
                                className={
                                    activeTab == "hospitalManagement"
                                        ? "active"
                                        : ""
                                }
                                aria-current={
                                    activeTab == "hospitalManagement"
                                        ? "page"
                                        : false
                                }
                            >
                                Organization Management
                            </Link>
                        </li>
                        {config[config.env].featureConfig.registry === "ON" && (
                            <li>
                                <Link
                                    to="/registry/registry-management"
                                    className={
                                        activeTab == "registryManagement"
                                            ? "active"
                                            : ""
                                    }
                                    aria-current={
                                        activeTab == "registryManagement"
                                            ? "page"
                                            : false
                                    }
                                >
                                    Registry Management
                                </Link>
                            </li>
                        )}
                        <li>
                            <Link
                                to="/corporation/corporation-management"
                                className={
                                    activeTab == "corporateManagement"
                                        ? "active"
                                        : ""
                                }
                                aria-current={
                                    activeTab == "corporateManagement"
                                        ? "page"
                                        : false
                                }
                            >
                                Corporation Management
                            </Link>
                        </li>
                    </ul>
                </div>
            </nav>
        </SidebarWrapper>
    );
};

export default Sidebar;
