import axios from "axios";
import config from "../config";

export const getAboutAhaData = async () => {
    return new Promise((resolve, reject) => {
        axios({
            method: "GET",
            url: `${config[config.env].apiEndpoints.strapi}/about-ahas`,
        })
            .then((response: any) => {
                if (response) {
                    if (response.status == 200) {
                        resolve(response);
                    } else {
                        throw response.error;
                    }
                }
            })
            .catch((error: any) => {
                reject(error);
            });
    });
};
