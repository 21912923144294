import styled from "styled-components";

export const CorporationDashboardWrapper = styled.div`
    .total-fac-card {
        color: #fff;
        padding: 20px;
        i {
            font-size: 40px;
        }
    }
    .bg-facilities {
        background-color: #6d6e71;
    }
    .bg-fac-active {
        background-color: #53756d;
    }
    .bg-total-registry {
        background-color: #0c7590;
    }
    .total-hospital-count {
        font-size: 29px;
    }
    .facility-details-banner {
        border-color: #e3e3e3;
    }
`;
