import styled from "styled-components";

export const PaymentSummaryWrapper = styled.div`
    padding: 28px;
    .payment-title {
        border-bottom: 3px solid #c10e21;
    }
    .payment-cost {
        border-top: 1px solid #bcc3ca;
        border-bottom: 1px solid #bcc3ca;
    }
    .billing-detail {
        margin-top: 36px;
        @media only screen and (min-width: 992px) {
            border-top: 2px solid #e3e3e3;
        }
    }
    .billing-title {
        padding: 30px 0 10px;
        @media only screen and (min-width: 992px) {
            border-bottom: 2px solid #e3e3e3;
            margin-bottom: 20px;
        }
    }
    .summary-container {
        @media only screen and (min-width: 992px) {
            padding-left: 60px;
        }
    }
    .summary-header {
        background-color: #c10e21;
        color: #ffffff;
        padding: 9px 26px;
        @media only screen and (min-width: 992px) {
            margin-top: 86px;
        }
    }
    .summary-body {
        background-color: #f8f8f8;
        padding: 26px 26px 30px;
    }
    .summary-item {
        margin-bottom: 18px;
    }
    .summary-final {
        border-top: 2px solid #707070;
    }
    .billing-btns {
        @media only screen and (min-width: 768px) {
            border-top: 2px solid #e3e3e3;
            padding: 40px 0;
        }
        .btn {
            width: 100%;
            @media only screen and (min-width: 576px) {
                width: 48%;
            }
            @media only screen and (min-width: 768px) {
                width: auto;
            }
        }
    }
    .billing-block1 {
        order: 2;
        @media only screen and (min-width: 992px) {
            order: 1;
        }
    }
    .billing-block2 {
        order: 4;
        @media only screen and (min-width: 992px) {
            order: 2;
        }
    }
    .billing-btn1 {
        order: 2;
        @media only screen and (min-width: 576px) {
            margin-left: 2%;
        }
        @media only screen and (min-width: 992px) {
            order: 1;
            margin-left: 0;
        }
    }
    .billing-btn2 {
        width: 48%;
        @media only screen and (min-width: 576px) {
            margin-right: 2%;
        }
        @media only screen and (min-width: 768px) {
            margin-right: 0;
            width: auto;
        }
    }
    .billing-online {
        order: 3;
        width: 100%;
        @media only screen and (min-width: 768px) {
            order: 1;
            width: auto;
        }
    }
    .billing-or {
        order: 2;
        margin-bottom: 20px;
        @media only screen and (min-width: 768px) {
            order: 2;
            margin-bottom: 0;
        }
    }
    .billing-offline {
        order: 1;
        width: 100%;
        margin-bottom: 20px;
        @media only screen and (min-width: 768px) {
            order: 3;
            width: auto;
            margin-bottom: 0;
        }
    }
    .billing-offline-btn {
        width: 48%;
    }
`;
export const PaymentResultWrapper = styled.div`
    p {
        font-size: 16px;
        @media only screen and (min-width: 768px) {
            font-size: 18px;
        }
    }
    .payment-success-detail {
        @media only screen and (min-width: 992px) {
            width: 92%;
        }
        @media only screen and (min-width: 1200px) {
            width: 74%;
        }
    }
    .payment-failed-detail {
        @media only screen and (min-width: 768px) {
            width: 85%;
        }
        @media only screen and (min-width: 1200px) {
            width: 58%;
        }
    }
    .payment-failed-content {
        line-height: 1.8;
    }
`;
