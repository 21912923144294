import React, { useEffect, useState } from "react";
import { getRegistryById, getRegistryHospitals } from "api/registryAPI";
import { useDispatch } from "react-redux";
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import { parseJwt } from "common/utils";
import { SettingWrapper } from "styled/settings-styled";
import ViewRegistryPage from "../RegistryDetails/ViewRegistryPage";
import RegistrySidebar from "./RegistrySidebar";
import RegistryTabs from "../RegistryMenu";

const RegistryProfile = () => {
    const [registry, setRegistry] = useState<any>();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setPageLoadingStatus({ isPageLoading: true }));

        const userAccessToken = parseJwt(
            window.localStorage.getItem("userAccessToken")
        );

        Promise.all([
            getRegistryById(userAccessToken.registry_id),
            getRegistryHospitals(userAccessToken.registry_id, 25, 1),
        ])
            .then((registryResponse: any) => {
                dispatch(setPageLoadingStatus({ isPageLoading: false }));
                if (
                    registryResponse[0].success &&
                    registryResponse[1].success
                ) {
                    const RegistryData = registryResponse[0].data.registry;
                    RegistryData.totalHospitalsRegistered =
                        registryResponse[1].data._pagination.totalCount;
                    setRegistry(RegistryData);
                }
            })
            .catch(() => {
                dispatch(setPageLoadingStatus({ isPageLoading: false }));
            });
    }, []);
    return (
        <div>
            <RegistryTabs activeTab="profileSettings" />
            <SettingWrapper className="container">
                <div className="setting-header">
                    <h1 className="h2 mb-3">Settings</h1>
                </div>
                <div className="d-lg-flex">
                    <RegistrySidebar registryActiveNav="registrySettingProfile" />
                    <div className="setting-container">
                        <h2 className="h3 mb-5">Profile</h2>

                        {registry && (
                            <ViewRegistryPage
                                setEditMode={false}
                                registry={registry}
                                profileMode
                            />
                        )}
                    </div>
                </div>
            </SettingWrapper>
        </div>
    );
};

export default RegistryProfile;
