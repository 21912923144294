import store from "app/store";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { hideToast } from "./toast.slice";
import { ToastWrapper } from "./styled";

export const Toast = () => {
    const toast = useSelector((state: any) => {
        return state.toast;
    });
    const [visibility, setVisibility] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [type, setType] = useState<string>("success");
    let timeOut: any = false;
    useEffect(() => {
        setVisibility(toast.visibility);
        setMessage(toast.message);
        setType(toast.type);
        autoHide();
    }, [toast]);

    const autoHide = () => {
        if (toast.visibility) {
            if (!timeOut) {
                timeOut = setTimeout(() => {
                    store.dispatch(hideToast(false));
                    clearTimeout(timeOut);
                    timeOut = false;
                }, 6000);
            }
        }
    };

    return (
        <>
            <div role="alert" aria-atomic="true">
                {visibility ? (
                    <ToastWrapper className="container">
                        <div className={`alert alert-${type} fade show`}>
                            {type === "warning" ? (
                                <i
                                    className="aha-icon-warning fs-1 mr-2"
                                    aria-hidden="true"
                                />
                            ) : (
                                <i
                                    className="alert-check alert-icon aha-icon-tick-circle me-2"
                                    aria-hidden="true"
                                />
                            )}

                            <div
                                className="alert-cross alert-icon"
                                aria-hidden="true"
                            >
                                <i className="aha-icon-cross" />
                            </div>
                            {message}
                        </div>
                    </ToastWrapper>
                ) : null}
            </div>
        </>
    );
};
