import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import HospitalDetails from "pages/Admin/FacilityDetails/HospitalDetails";
import ProgramsDetails from "pages/Admin/FacilityDetails/ProgramDetails";
import BillingDetails from "pages/Admin/FacilityDetails/BillingDetails";
import PaymentDetails from "pages/Admin/FacilityDetails/PaymentDetails";
import AgreementDetails from "pages/Admin/FacilityDetails/AgreementDetails";
import { useDispatch } from "react-redux";
import HospitalProgramAgreementPage from "pages/HospitalProgramAgreement/HospitalProgramAgreementPage";
import PaymentSummaryPage from "pages/Payment/PaymentSummaryPage";
import axios from "axios";
import { FacilitiesDetailsWrapper } from "./styled";
import CorporationTabs from "../CorporationMenu/CorporationMenu";
import FacilitiesNav from "./FacilitiesNav";
import { getHospitalById } from "../../../api/hospitalApi";
import { setPageLoadingStatus } from "../../../components/Loader/loader.slice";
import CONSTANTS from "../../../common/constants";
import { getHospitalProgramDetails } from "../../../api/hospitalProgramsAPI";
import {
    getAssetDetails,
    getHospitalProgramCertificate,
} from "../../../api/certificateAPI";
import {
    exportDetails,
    parseJwt,
    redirectionForErrorCode,
} from "../../../common/utils";
import { getSubscriptionDetails } from "../../../api/adminDashboardAPI";
import { getBillingDetails, getProratePrice } from "../../../api/paymentAPI";
import { showToast } from "../../../components/Toast/toast.slice";

export const FacilitiesDetails = () => {
    const history = useHistory();
    const { hospitalId, programId, categoryId }: any = useParams();
    const [hospital, setHospital] = useState<any>({});
    const [lastEditedData, setLastEditedData] = useState<any>(null);
    const [activeTab, setActiveTab] = useState<any>(1);
    const [registryInfo, setRegistryInfo] = useState<any>({});
    const [registryQuestionData, setRegistryQuestionData] = useState<any>({});
    const [agreementInfo, setAgreementInfo] = useState<any>({});
    const [paymentInfo, setPaymentInfo] = useState<any>({});
    const [programData, setProgramData] = useState<any>();
    const [billingInfo, setBillingInfo] = useState<any>({});
    const [certificateData, setCertificateData] = useState<any>();
    const [agreementUploaded, setAgreementUploaded] = useState<boolean>(false);
    const [billingComplete, setBillingComplete] = useState<boolean>(false);
    const [paymentComplete, setPaymentComplete] = useState<boolean>(false);
    const [billingContactName, setBillingContactName] = useState<any>("");
    const [price, setPrice] = useState<number>();
    const [orderId, setOrderId] = useState<any>();
    const [mounted, setMounted] = useState<boolean>(false);
    const [isPaymentTabReq, setIsPaymentTabReq] = useState<boolean>(false);
    const dispatch = useDispatch();
    const location = useLocation();
    const userAccessToken = window.localStorage.getItem("userAccessToken");
    const isCorporation =
        userAccessToken &&
        parseJwt(userAccessToken).role_code ===
            CONSTANTS.USER_ROLES.CORPORATION_ADMIN;

    useEffect(() => {
        dispatch(setPageLoadingStatus({ isPageLoading: true }));
        if (!mounted) {
            Promise.all([
                getHospitalById(hospitalId),
                getHospitalProgramDetails(programId, hospitalId),
                getProratePrice(programId, categoryId),
            ])
                .then((response: any) => {
                    if (
                        response[0].success &&
                        response[1].success &&
                        response[2].success
                    ) {
                        if (location?.state?.tab != CONSTANTS.DEACTIVATED) {
                            getHospitalProgramCertificate(
                                hospitalId,
                                programId,
                                categoryId
                            )
                                .then((res: any) => {
                                    setCertificateData(
                                        res.data.programCertficate
                                    );
                                    localStorage.setItem(
                                        "selectedHospitalExpiryDate",
                                        res.data.programCertficate
                                            ?.programCertificateExpiryDate
                                    );
                                })
                                .catch((error: any) => {
                                    if (isCorporation) {
                                        redirectionForErrorCode(
                                            CONSTANTS.ROUTES
                                                .CORPORATION_DASHBOARD,
                                            error,
                                            history,
                                            2000
                                        );
                                    }
                                });
                        }
                        localStorage.setItem(
                            "selectedHospitalCountryId",
                            response[0].data.hospital.countryId
                        );
                        setHospital(response[0].data.hospital);
                        modifyLastEditedData(response[0].data.hospital);
                        setProgramData(response[1].data);
                        if (
                            response[2].statusCode == 200 &&
                            response[2].data &&
                            response[2].data.programCategoryPrice
                        ) {
                            setPrice(
                                response[2].data.programCategoryPrice.price
                            );
                        }
                        dispatch(
                            setPageLoadingStatus({ isPageLoading: false })
                        );
                        setMounted(true);
                    }
                })
                .catch(() => {
                    dispatch(setPageLoadingStatus({ isPageLoading: false }));
                });
        }
    }, []);
    useEffect(() => {
        if (Object.keys(hospital).length !== 0) {
            if (hospital.countryCode !== CONSTANTS.DOMESTIC_COUNTRY_CODE)
                setIsPaymentTabReq(true);
        }
    }, [hospital]);

    useEffect(() => {
        if (mounted) {
            dispatch(setPageLoadingStatus({ isPageLoading: true }));
            getHospitalProgramDetails(programId, hospitalId)
                .then((response: any) => {
                    if (response.success) {
                        setProgramData(response.data);
                        dispatch(
                            setPageLoadingStatus({ isPageLoading: false })
                        );
                    }
                })
                .catch(() => {
                    dispatch(setPageLoadingStatus({ isPageLoading: false }));
                });
        }
    }, [agreementUploaded, mounted]);

    useEffect(() => {
        if (mounted) {
            dispatch(setPageLoadingStatus({ isPageLoading: true }));
            getBillingDetails(hospitalId, categoryId)
                .then((response: any) => {
                    if (response.success) {
                        setBillingContactName(
                            response.data?.billingDetails.billingContactName
                        );
                        dispatch(
                            setPageLoadingStatus({ isPageLoading: false })
                        );
                    }
                })
                .catch(() => {
                    dispatch(setPageLoadingStatus({ isPageLoading: false }));
                });
        }
    }, [billingComplete, mounted]);

    useEffect(() => {
        getSubscriptionDetails(hospitalId, programId, categoryId)
            .then(async (response: any) => {
                if (response.success) {
                    setOrderId(response.data.orderId);
                }
            })
            .catch(() => {
                dispatch(setPageLoadingStatus({ isPageLoading: false }));
            });
    }, [paymentComplete]);

    useEffect(() => {
        programData && modifyLastEditedData(programData);
    }, [programData]);

    useEffect(() => {
        paymentInfo && modifyLastEditedData(paymentInfo);
    }, [paymentInfo]);

    const close = () => {
        history.push(getReturnPath());
    };

    const exportFacilityData = () => {
        dispatch(setPageLoadingStatus({ isPageLoading: true }));
        getAssetDetails(CONSTANTS.EXPORT_FACILITY_TEMPLATE, true).then(
            async (presignedURL: any) => {
                if (
                    presignedURL.data?.assets?.[0].isDownloadable &&
                    presignedURL.data?.assets?.[0].presignedUrl
                ) {
                    const iframe: any = document.createElement("iframe");
                    iframe.setAttribute("height", "0");
                    iframe.setAttribute("width", "0");
                    iframe.className = "hiddenFrame";

                    try {
                        const axiosResponse = await axios({
                            method: "get",
                            url: presignedURL.data?.assets?.[0].presignedUrl,
                        });

                        if (axiosResponse.data) {
                            const templateHtml = axiosResponse.data;
                            /* add iframe to the main document for canvas creation and print */
                            document.body.appendChild(iframe);

                            iframe.onload = async () => {
                                await exportDetails(
                                    hospital,
                                    programData,
                                    registryQuestionData,
                                    registryInfo,
                                    agreementInfo,
                                    billingInfo,
                                    paymentInfo
                                );
                                const toast = {
                                    message:
                                        "Organization details successfully exported.",
                                    code: "Success:",
                                    type: "success",
                                };
                                dispatch(showToast(toast));

                                dispatch(
                                    setPageLoadingStatus({
                                        isPageLoading: false,
                                    })
                                );
                            };

                            iframe.contentWindow.document.open();
                            iframe.contentWindow.document.write(templateHtml);
                            iframe.contentWindow.document.close();
                        }
                    } catch {
                        const toast = {
                            message: "Export template doesn't exist.",
                        };

                        dispatch(showToast(toast));
                        dispatch(
                            setPageLoadingStatus({ isPageLoading: false })
                        );
                    }
                }
            }
        );
    };

    const modifyLastEditedData = (currentData: any) => {
        if (currentData?.updatedBy?.firstName && currentData?.updatedAt) {
            setLastEditedData(currentData);
        }
    };

    const handleHospitalDataChange = (modifiedData: any) => {
        setHospital(modifiedData);
        modifyLastEditedData(modifiedData);
    };

    const handleProgramDataChange = (modifiedData: any) => {
        setProgramData(modifiedData);
        modifyLastEditedData(modifiedData);
    };

    const handleAgreementDataChange = (modifiedData: any) => {
        setAgreementInfo(modifiedData);
        modifyLastEditedData(modifiedData);
    };

    const handlePaymentDataChange = (modifiedData: any) => {
        setPaymentInfo(modifiedData);
        modifyLastEditedData(modifiedData);
    };

    const handleBillingDataChange = (modifiedData: any) => {
        setBillingInfo(modifiedData);
        modifyLastEditedData(modifiedData);
    };

    const getActiveTab = () => {
        switch (location?.state?.tab) {
            case CONSTANTS.PENDING:
                return "corporationPendingFacilties";
            case CONSTANTS.REGISTRED:
                return "corporationFaciltyRegistered";
            case CONSTANTS.RENEWING:
                return "corporationRenewingFacilties";
            case CONSTANTS.CERTIFIED:
                return "corporationFaciltyCertified";
            case CONSTANTS.DEACTIVATED:
                return "corporationDeactivatedFacilties";
            default:
                return "";
        }
    };

    const getReturnPath = () => {
        switch (location?.state?.tab) {
            case CONSTANTS.PENDING:
                return "/corporation/pending-approval";
            case CONSTANTS.REGISTRED:
                return "/corporation/registered-organization";
            case CONSTANTS.CERTIFIED:
                return "/corporation/certified-organization";
            case CONSTANTS.RENEWING:
                return "/corporation/renewed-organizations";
            case CONSTANTS.DEACTIVATED:
                return "/corporation/deactivated-organization";
            case CONSTANTS.BULKDOCUMENT:
                return "/corporation/bulk-document-submission";
            default:
                return "";
        }
    };

    return (
        <FacilitiesDetailsWrapper className="corporation-container">
            <CorporationTabs activeTab={getActiveTab()} />
            <div className="container pt-lg-3 pb-5">
                <FacilitiesNav
                    returnPath={location?.state}
                    hospital={hospital}
                    programData={programData}
                    certificateData={certificateData}
                    lastEditedData={lastEditedData}
                    isCorporation={isCorporation}
                    exportFacilityData={exportFacilityData}
                    activeMenu="corporationFacilityDetails"
                />
                <div
                    className="d-flex flex-column flex-md-row flex-wrap aui-accordion-arrowtab mt-5"
                    id="corpArrowTabs"
                    role="tablist"
                >
                    <button
                        onClick={() => setActiveTab(1)}
                        className="aui-acc-arrowtab-item aui-acc-arrtab-z1 aui-accordion-arrowtab1"
                        data-toggle="collapse"
                        data-target="#tabItem1"
                        aria-expanded={activeTab == 1}
                        role="tab"
                        id="admin-facilitydetails"
                    >
                        <span className="aui-acc-arrowtab-btn">
                            <span className="btn btn-text aui-acc-arrowtab-text">
                                Organization Details
                                <i
                                    className="aha-icon-arrow-down aui-acc-dropicon mx-2 d-inline-block d-md-none"
                                    aria-hidden="true"
                                />
                            </span>
                        </span>
                    </button>
                    <button
                        onClick={() => setActiveTab(2)}
                        className="aui-acc-arrowtab-item aui-acc-arrtab-z2 aui-accordion-arrowtab2"
                        data-toggle="collapse"
                        data-target="#tabItem2"
                        aria-expanded={activeTab == 2}
                        role="tab"
                        id="admin-programdetails"
                    >
                        <span className="aui-acc-arrowtab-btn">
                            <span className="btn btn-text aui-acc-arrowtab-text">
                                Program Details
                                <i
                                    className="aha-icon-arrow-down aui-acc-dropicon mx-2 d-inline-block d-md-none"
                                    aria-hidden="true"
                                />
                            </span>
                        </span>
                    </button>
                    <button
                        onClick={() => setActiveTab(3)}
                        className="aui-acc-arrowtab-item aui-acc-arrtab-z3 aui-accordion-arrowtab3"
                        data-toggle="collapse"
                        data-target="#tabItem3"
                        aria-expanded={activeTab == 3}
                        role="tab"
                        id="admin-agreementdetails"
                    >
                        <span className="aui-acc-arrowtab-btn">
                            <span className="btn btn-text aui-acc-arrowtab-text">
                                Agreement Details
                                <i
                                    className="aha-icon-arrow-down aui-acc-dropicon mx-2 d-inline-block d-md-none"
                                    aria-hidden="true"
                                />
                            </span>
                        </span>
                    </button>
                    <button
                        onClick={() => setActiveTab(4)}
                        className="aui-acc-arrowtab-item aui-acc-arrtab-z4 aui-accordion-arrowtab4"
                        data-toggle="collapse"
                        data-target="#tabItem4"
                        aria-expanded={activeTab == 4}
                        role="tab"
                        disabled={!programData?.agreementReferenceId}
                        id="admin-billingdetails"
                    >
                        <span className="aui-acc-arrowtab-btn">
                            <span className="btn btn-text aui-acc-arrowtab-text">
                                Billing Details
                                <i
                                    className="aha-icon-arrow-down aui-acc-dropicon mx-2 d-inline-block d-md-none"
                                    aria-hidden="true"
                                />
                            </span>
                        </span>
                    </button>
                    {isPaymentTabReq && (
                        <button
                            onClick={() => setActiveTab(5)}
                            className="aui-acc-arrowtab-item aui-acc-arrtab-z5 aui-accordion-arrowtab5"
                            data-toggle="collapse"
                            data-target="#tabItem5"
                            aria-expanded={activeTab == 5}
                            role="tab"
                            id="admin-paymentdetails"
                            disabled={!programData?.agreementReferenceId}
                        >
                            <span className="aui-acc-arrowtab-btn">
                                <span className="btn btn-text aui-acc-arrowtab-text">
                                    Payment Details
                                    <i
                                        className="aha-icon-arrow-down aui-acc-dropicon mx-2 d-inline-block d-md-none"
                                        aria-hidden="true"
                                    />
                                </span>
                            </span>
                        </button>
                    )}

                    <div
                        id="tabItem1"
                        className={`collapse aui-accordion-arrowcontent mt-4 aui-acc-arrc1 ${
                            activeTab == 1 ? "show" : "hide"
                        }`}
                        data-parent="#corpArrowTabs"
                        role="tabpanel"
                    >
                        <HospitalDetails
                            hospital={hospital}
                            setHospital={handleHospitalDataChange}
                            setActiveTab={setActiveTab}
                            activeTab={activeTab}
                            close={close}
                            isCorporation={isCorporation}
                        />
                    </div>
                    <div
                        id="tabItem2"
                        className={`collapse aui-accordion-arrowcontent mt-4 aui-acc-arrc2 ${
                            activeTab == 2 ? "show" : "hide"
                        }`}
                        data-parent="#corpArrowTabs"
                        role="tabpanel"
                    >
                        <ProgramsDetails
                            setActiveTab={setActiveTab}
                            activeTab={activeTab}
                            programData={programData}
                            close={close}
                            setRegistryInfo={setRegistryInfo}
                            setRegistryQuestionData={setRegistryQuestionData}
                            setProgramData={handleProgramDataChange}
                            hospital={hospital}
                            isCorporation={isCorporation}
                        />
                    </div>
                    <div
                        id="tabItem3"
                        className={`collapse aui-accordion-arrowcontent mt-4 aui-acc-arrc3 ${
                            activeTab == 3 ? "show" : "hide"
                        }`}
                        data-parent="#corpArrowTabs"
                        role="tabpanel"
                    >
                        {orderId ? (
                            <AgreementDetails
                                hospital={hospital}
                                setActiveTab={setActiveTab}
                                activeTab={activeTab}
                                close={close}
                                setAgreementInfo={handleAgreementDataChange}
                                isCorporation={isCorporation}
                            />
                        ) : (
                            <HospitalProgramAgreementPage
                                history={history}
                                isCorporation={isCorporation}
                                close={close}
                                setActiveTab={setActiveTab}
                                activeTab={activeTab}
                                setAgreementUploaded={setAgreementUploaded}
                                agreementUploaded={agreementUploaded}
                                handleAgreementDataChange={
                                    handleAgreementDataChange
                                }
                            />
                        )}
                    </div>
                    <div
                        id="tabItem4"
                        className={`collapse aui-accordion-arrowcontent mt-4 aui-acc-arrc4 ${
                            activeTab == 4 ? "show" : "hide"
                        }`}
                        data-parent="#corpArrowTabs"
                        role="tabpanel"
                    >
                        {orderId || paymentComplete ? (
                            <BillingDetails
                                hospital={hospital}
                                setActiveTab={setActiveTab}
                                activeTab={activeTab}
                                close={close}
                                setBillingInfo={handleBillingDataChange}
                                orderId={orderId}
                                isCorporation={isCorporation}
                            />
                        ) : (
                            <PaymentSummaryPage
                                history={undefined}
                                isCorporation={isCorporation}
                                setActiveTab={setActiveTab}
                                activeTab={activeTab}
                                close={close}
                                setBillingComplete={setBillingComplete}
                                setPaymentComplete={setPaymentComplete}
                                paymentComplete={paymentComplete}
                            />
                        )}
                    </div>
                    <div
                        id="tabItem5"
                        className={`collapse aui-accordion-arrowcontent mt-4 aui-acc-arrc5 ${
                            activeTab == 5 ? "show" : "hide"
                        }`}
                        data-parent="#corpArrowTabs"
                        role="tabpanel"
                    >
                        <PaymentDetails
                            setActiveTab={setActiveTab}
                            activeTab={activeTab}
                            close={close}
                            setPaymentInfo={handlePaymentDataChange}
                            hospital={hospital}
                            price={price}
                            isCorporation={isCorporation}
                            setPaymentComplete={setPaymentComplete}
                            paymentComplete={paymentComplete}
                        />
                    </div>
                </div>
            </div>
        </FacilitiesDetailsWrapper>
    );
};

export default FacilitiesDetails;
