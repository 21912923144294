import styled from "styled-components";

export const AdminHeaderWrapper = styled.header`
    &.aui-pri-header {
        .aui-header-content {
            width: 100%;
        }
        .aui-pri-header-t {
            padding-left: 15px;
            padding-right: 15px;
            @media (min-width: 576px) {
                padding-left: 20px;
                padding-right: 20px;
            }
            @media (min-width: 768px) {
                padding-left: 30px;
                padding-right: 30px;
            }
            @media (min-width: 992px) {
                padding-left: 30px;
                padding-right: 30px;
            }
        }
    }
    .qct-logo {
        padding-right: 2px;
        img {
            width: 200px;
            @media only screen and (min-width: 768px) {
                width: 220px;
            }
            @media only screen and (min-width: 768px) {
                width: 260px;
            }
            @media only screen and (min-width: 992px) {
                width: 310px;
            }
        }
    }
    .navbar-toggler {
        color: #222328;
    }
`;
