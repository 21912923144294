import CONSTANTS from "common/constants";

declare global {
    interface Window {
        google: any;
    }
}
const loadCharts = () => {
    const { google } = window;
    google.charts.load("50", { packages: ["corechart"] });
    return google;
};

const preloadCharts = async (
    callback: any,
    chartData?: any,
    details?: string
) => {
    const google = await loadCharts();
    google.charts.setOnLoadCallback(async () => {
        callback(google, chartData, details);
    });
};

// get measure or metric values for each quarter
const getMeasureMetricValuesForQuarter = (
    measureMetric: any,
    quarter: string,
    codes: any,
    zeroValueInd: boolean,
    benchmarkInd?: boolean
) => {
    const measureMetricValues: any = [];
    const quarterSelectedForYear = quarter.split("-")[1];
    const measureMetricForQuarter = measureMetric[quarterSelectedForYear];
    for (
        let measureMetricCodeIndex = 0;
        measureMetricCodeIndex <= codes.length - 1;
        measureMetricCodeIndex++
    ) {
        const measureMetricValue = measureMetricForQuarter.filter(
            (measureMetric: any) =>
                measureMetric.code === codes[measureMetricCodeIndex] &&
                measureMetric.quarterDetails === quarter
        );
        zeroValueInd = setZeroIndForQuarter(measureMetricValue, zeroValueInd);
        if (benchmarkInd) {
            measureMetricValues.push({
                measureMetricId: measureMetricValue[0]?.code
                    ? measureMetricValue[0].code
                    : codes[measureMetricCodeIndex],
                value:
                    measureMetricValue[0]?.BenchmarkValue &&
                    measureMetricValue[0]?.BenchmarkValue !== "NaN"
                        ? parseFloat(
                              measureMetricValue[0].BenchmarkValue.toFixed(2)
                          )
                        : 0,
                actualValue: measureMetricValue[0]?.BenchmarkValue,
            });
        } else {
            measureMetricValues.push({
                measureMetricId: measureMetricValue[0]?.code
                    ? measureMetricValue[0].code
                    : codes[measureMetricCodeIndex],
                value:
                    measureMetricValue[0]?.value &&
                    measureMetricValue[0]?.value !== "NaN"
                        ? parseFloat(measureMetricValue[0].value)
                        : 0,
                numerator:
                    measureMetricValue[0]?.numerator ||
                    measureMetricValue[0]?.numerator === 0
                        ? measureMetricValue[0].numerator
                        : null,
                denominator:
                    measureMetricValue[0]?.denominator ||
                    measureMetricValue[0]?.denominator === 0
                        ? measureMetricValue[0].denominator
                        : null,
                actualValue: measureMetricValue[0]?.value,
            });
        }
    }
    return { measureMetricValues, zeroValueInd };
};

// get measure or metric values for each year
const getMeasureMetricValuesForYear = (
    measureMetric: any,
    year: string,
    codes: any,
    zeroValueInd: boolean,
    benchmarkInd?: boolean
) => {
    const measureMetricValues: any = [];
    const measureMetricForYear = measureMetric[year] ? measureMetric[year] : [];
    for (
        let measureMetricCodeIndex = 0;
        measureMetricCodeIndex <= codes.length - 1;
        measureMetricCodeIndex++
    ) {
        const measureMetricValue = measureMetricForYear.filter(
            (measureMetric: any) =>
                measureMetric.code === codes[measureMetricCodeIndex] &&
                measureMetric.year === year
        );
        zeroValueInd = setZeroIndForAnnual(measureMetricValue, zeroValueInd);
        if (benchmarkInd) {
            measureMetricValues.push({
                measureMetricId: measureMetricValue[0]?.code
                    ? measureMetricValue[0].code
                    : codes[measureMetricCodeIndex],
                value:
                    measureMetricValue[0]?.annualBenchmark &&
                    measureMetricValue[0]?.annualBenchmark !== "NaN"
                        ? parseFloat(
                              measureMetricValue[0].annualBenchmark.toFixed(2)
                          )
                        : 0,
                actualValue: measureMetricValue[0]?.annualBenchmark,
            });
        } else {
            measureMetricValues.push({
                measureMetricId: measureMetricValue[0]?.code
                    ? measureMetricValue[0].code
                    : codes[measureMetricCodeIndex],
                value:
                    measureMetricValue[0]?.annualCount &&
                    measureMetricValue[0]?.annualCount !== "NaN"
                        ? parseFloat(measureMetricValue[0].annualCount)
                        : 0,
                numerator:
                    measureMetricValue[0]?.numerator ||
                    measureMetricValue[0]?.numerator === 0
                        ? measureMetricValue[0].numerator
                        : null,
                denominator:
                    measureMetricValue[0]?.denominator ||
                    measureMetricValue[0]?.denominator === 0
                        ? measureMetricValue[0].denominator
                        : null,
                actualValue: measureMetricValue[0]?.annualCount,
            });
        }
    }
    return { measureMetricValues, zeroValueInd };
};

// get segregated values based on each quarter or year for the selected interval
const getQuarterOrYearValues = (
    measureMetric: any,
    quartersOrYearList: any,
    codes: any,
    interval: string,
    benchmarkInd?: boolean
) => {
    const quarterOrAnnualValues: any = [];
    let zeroValueInd = true;
    for (
        let quarterYearIndex = 0;
        quarterYearIndex <= quartersOrYearList.length - 1;
        quarterYearIndex++
    ) {
        let measureMetrics;
        // get measure or metric values for each quarter or year
        if (interval === CONSTANTS.QUARTERLY) {
            measureMetrics = getMeasureMetricValuesForQuarter(
                measureMetric,
                quartersOrYearList[quarterYearIndex],
                codes,
                zeroValueInd,
                benchmarkInd
            );
        } else {
            measureMetrics = getMeasureMetricValuesForYear(
                measureMetric,
                quartersOrYearList[quarterYearIndex],
                codes,
                zeroValueInd,
                benchmarkInd
            );
        }
        const { measureMetricValues } = measureMetrics;
        zeroValueInd = measureMetrics.zeroValueInd;
        quarterOrAnnualValues.push({
            quarterOrYear: quartersOrYearList[quarterYearIndex],
            measureMetricValues,
        });
    }
    return { quarterOrAnnualValues, zeroValueInd };
};

// get list of all quarters for selected interval
const getAllQuarterList = (
    fromYear: number,
    fromQuarter: number,
    toYear: number,
    toQuarter: number
) => {
    const quartersList = [];
    const quarters = ["Q1", "Q2", "Q3", "Q4"];
    if (fromYear === toYear) {
        for (
            let formquarterIndex = fromQuarter;
            formquarterIndex <= toQuarter;
            formquarterIndex++
        ) {
            quartersList.push(`${fromYear}-${quarters[formquarterIndex - 1]}`);
        }
    } else {
        for (let yearIndex = fromYear; yearIndex <= toYear; yearIndex++) {
            if (yearIndex === fromYear) {
                for (
                    let formquarterIndex = fromQuarter;
                    formquarterIndex <= 4;
                    formquarterIndex++
                ) {
                    quartersList.push(
                        `${yearIndex}-${quarters[formquarterIndex - 1]}`
                    );
                }
            } else if (yearIndex === toYear) {
                for (
                    let toquarterIndex = 1;
                    toquarterIndex <= toQuarter;
                    toquarterIndex++
                ) {
                    quartersList.push(
                        `${yearIndex}-${quarters[toquarterIndex - 1]}`
                    );
                }
            } else {
                for (let quarterIndex = 1; quarterIndex <= 4; quarterIndex++) {
                    quartersList.push(
                        `${yearIndex}-${quarters[quarterIndex - 1]}`
                    );
                }
            }
        }
    }
    return quartersList;
};

const getMeasureMetricList = (listItems: any) => {
    const allListItems = [
        {
            label: CONSTANTS.ALL_VALUE,
            value: CONSTANTS.ALL_VALUE,
        },
    ];
    for (let itemIndex = 0; itemIndex <= listItems.length - 1; itemIndex++) {
        allListItems.push({
            label: listItems[itemIndex],
            value: listItems[itemIndex],
        });
    }
    return allListItems;
};

const getMeasureMetricData = (
    chartResponse: any,
    quarterOrYears: any,
    measureMetricData: any,
    interval: string,
    benchmarkInd?: boolean
) => {
    if (chartResponse.measures?.length) {
        const measureData: any = [];
        chartResponse.measures.map((measure: any) => {
            // get segregated values based on each quarter or year for the selected interval
            const measureCodes = getMeasureCodes(interval, measure);
            const measures = getMeasures(interval, measure);
            const { quarterOrAnnualValues, zeroValueInd } =
                getQuarterOrYearValues(
                    measures,
                    quarterOrYears,
                    measureCodes,
                    interval,
                    benchmarkInd
                );
            measureData.push({
                quarterOrAnnualValues,
                groupName: measure.measureName,
                codes: measureCodes,
                filterLabel: "Measure ID",
                filterItems: getMeasureMetricList(measureCodes),
                zeroValueInd,
                benchmarkValue: false,
            });
        });
        measureMetricData.measures = measureData;
    }
    if (chartResponse.metrics?.length) {
        const metricData: any = [];
        chartResponse.metrics.map((metric: any) => {
            // get segregated values based on each quarter or year for the selected interval
            const metricCodes =
                interval === CONSTANTS.QUARTERLY
                    ? metric.metricsCodes
                    : metric.codes;
            const metrics =
                interval === CONSTANTS.QUARTERLY ? metric : metric.metricData;
            const { quarterOrAnnualValues, zeroValueInd } =
                getQuarterOrYearValues(
                    metrics,
                    quarterOrYears,
                    metricCodes,
                    interval
                );
            metricData.push({
                quarterOrAnnualValues,
                groupName: metric.metricName,
                codes: metricCodes,
                metricType: metric.metricType,
                filterLabel: metric.isParentMetric
                    ? "Sub-metric ID"
                    : "Metric ID",
                filterItems: getMeasureMetricList(metricCodes),
                zeroValueInd,
                filterNoShowInd: interval === CONSTANTS.ANNUAL,
                hideBenchMarkInd: true,
            });
        });
        measureMetricData.metrics = metricData;
    }
    return measureMetricData;
};

function createCustomHTMLContent(
    desc: string,
    measureMetricId: string,
    value: any,
    period: string,
    filterLabel: string,
    valueType: any,
    numerator: any,
    denominator: any,
    actualValue: any
) {
    let numden = "";
    let valueToDisplay;
    let valueSymbol = "";
    if (filterLabel === "Measure ID") {
        valueToDisplay = value.toFixed(2);
        if (
            numerator !== null &&
            denominator !== null &&
            actualValue !== null
        ) {
            numden = `(N: ${numerator} / D: ${denominator})`;
        } else {
            numden = "(Null)";
        }
    }
    if (filterLabel !== "Measure ID") {
        if (actualValue !== null && actualValue !== undefined) {
            valueToDisplay = actualValue.toFixed(2);
        } else {
            valueToDisplay = "Null";
        }
    }
    if (valueType !== "Number" && valueToDisplay !== "Null") {
        valueSymbol = "%";
    }
    return (
        '<div style="font-weight: 500; width: 320px; border-radius: 6px; padding: 0 10px 0 12px; color: #222222; font-family: Montserrat, sans-serif; line-height: 1.2; box-shadow: 0px 1px 2px #00000029; background-color: #ffffff;box-sizing: border-box; text-align: left;">' +
        '<p style="font-size: 14px; margin: 0 0 10px;">' +
        `${desc}` +
        "</p>" +
        '<div style="margin-top: 15px; margin-bottom: -5; display: flex;">' +
        '<div style="width: 33%; display: inline-block; position: relative;">' +
        "<div>" +
        '<span style="font-size: 12px; width: 100%; display: block;font-weight: 600;">' +
        `${filterLabel}` +
        "</span>" +
        '<span style="font-size: 14px;font-weight: 500; display: block;">' +
        `${measureMetricId}` +
        "</span>" +
        '<br style="position: absolute; width:1px; height: 1px; overflow: hidden; font-size: 0; line-height: 1;"/>' +
        "</div>" +
        "</div>" +
        '<div style="width: 29%; display: inline-block; position: relative;">' +
        "<div>" +
        '<span style="font-size: 12px; width: 100%; display: block;font-weight: 600;">Period</span>' +
        '<span style="font-size: 14px;font-weight: 500; display: block;">' +
        `${period}` +
        "</span>" +
        '<br style="position: absolute; width:1px; height: 1px; overflow: hidden; font-size: 0; line-height: 1;"/>' +
        "</div>" +
        "</div>" +
        '<div style="width: 35%; display: inline-block; position: relative;">' +
        "<div>" +
        '<span style="font-size: 12px; width: 100%; display: block;font-weight: 600;">Value</span>' +
        '<span style="font-size: 14px;font-weight: 500; display: block;">' +
        `${valueToDisplay}${valueSymbol}` +
        "</span>" +
        '<span style="font-size: 12px;font-weight: 500; display: block; white-space: nowrap;">' +
        `${numden}` +
        "</span>" +
        '<br style="position: absolute; width:1px; height: 1px; overflow: hidden; font-size: 0; line-height: 1;"/>' +
        "</div>" +
        "</div>" +
        "</div>" +
        "</div>"
    );
}

// Get structured response to contruct the chart columns and rows for Quarterly Interval
export const getChartDataToDisplay = (
    chartResponse: any,
    fromYear: number,
    fromQuarter: number,
    toYear: number,
    toQuarter: number,
    benchmarkInd?: boolean
) => {
    const quarters = ["Q1", "Q2", "Q3", "Q4"];
    const measureMetricData = {
        measures: [],
        metrics: [],
        title: `${fromYear} ${quarters[fromQuarter - 1]} - ${toYear} ${
            quarters[toQuarter - 1]
        }`,
        measureMetricTooltip: chartResponse.measureMetricTooltip,
    };
    // get list of all quarters for selected interval
    const allQuarters = getAllQuarterList(
        fromYear,
        fromQuarter,
        toYear,
        toQuarter
    );
    return getMeasureMetricData(
        chartResponse,
        allQuarters,
        measureMetricData,
        CONSTANTS.QUARTERLY,
        benchmarkInd
    );
};

// Get structured response to contruct the chart columns and rows for Annual Interval
export const getAnnualChartDataToDisplay = (
    chartResponse: any,
    fromYear: number,
    toYear: number,
    yearList: any,
    benchmarkInd?: boolean
) => {
    const measureMetricData = {
        measures: [],
        metrics: [],
        title: `${fromYear} - ${toYear}`,
        measureMetricTooltip: chartResponse.measureMetricTooltip,
    };
    return getMeasureMetricData(
        chartResponse,
        yearList,
        measureMetricData,
        CONSTANTS.ANNUAL,
        benchmarkInd
    );
};

// get the chart data columns and rows
export const getMeasureMetricDashboardData = (
    google: any,
    chartDataToDisplay: any,
    measureMetricTooltip: any,
    columnName: string,
    benchmarkInd?: boolean
) => {
    const { codes, quarterOrAnnualValues, filterLabel, metricType } =
        chartDataToDisplay;
    const chartData = new google.visualization.DataTable();
    chartData.addColumn("string", columnName);
    for (let codeIndex = 0; codeIndex <= codes.length - 1; codeIndex++) {
        chartData.addColumn("number", codes[codeIndex]);
        if (!benchmarkInd) {
            chartData.addColumn({
                type: "string",
                role: "tooltip",
                p: { html: true },
            });
        }
    }
    const chartRows = [];
    for (
        let quarterValueIndex = 0;
        quarterValueIndex <= quarterOrAnnualValues.length - 1;
        quarterValueIndex++
    ) {
        const rowData: any = [];
        const { quarterOrYear, measureMetricValues } =
            quarterOrAnnualValues[quarterValueIndex];
        rowData.push(quarterOrYear.toString());
        for (
            let valueIndex = 0;
            valueIndex <= measureMetricValues.length - 1;
            valueIndex++
        ) {
            const measureMetricValue = measureMetricValues[valueIndex];
            rowData.push(measureMetricValue.value);
            if (!benchmarkInd) {
                rowData.push(
                    createCustomHTMLContent(
                        measureMetricTooltip[
                            measureMetricValue.measureMetricId
                        ],
                        measureMetricValue.measureMetricId,
                        measureMetricValue.value,
                        quarterOrYear,
                        filterLabel,
                        metricType,
                        measureMetricValue.numerator,
                        measureMetricValue.denominator,
                        measureMetricValue.actualValue
                    )
                );
            }
        }
        chartRows.push(rowData);
    }
    chartData.addRows(chartRows);
    return chartData;
};

export const getColorForSeries = () => {
    const colorList = [
        { color: "#6E398A" },
        { color: "#454F99" },
        { color: "#2C72AF" },
        { color: "#0896BA" },
        { color: "#008D5B" },
        { color: "#78A136" },
        { color: "#9E9600" },
        { color: "#6B8C02" },
        { color: "#EF8D21" },
        { color: "#E96224" },
        { color: "#E12526" },
        { color: "#C21A7E" },
    ];
    return colorList;
};

export const getColumnsToShow = (
    chartdata: any,
    filteredIds: any,
    benchmarkInd?: boolean
) => {
    const columnsToShow = [0];
    const colorIndex = [];
    let loopTime = 0;
    let chartDataIndex = 1;
    if (benchmarkInd) {
        chartDataIndex = 2;
    }
    for (
        let chartIndex = chartDataIndex;
        chartIndex <= chartdata.cf.length - 1;
        chartIndex += 2
    ) {
        for (let idIndex = 0; idIndex <= filteredIds.length - 1; idIndex++) {
            if (filteredIds[idIndex].label === chartdata.cf[chartIndex].label) {
                colorIndex.push(loopTime);
                if (benchmarkInd) {
                    columnsToShow.push(chartIndex - 1);
                }
                columnsToShow.push(chartIndex);
                if (!benchmarkInd) {
                    columnsToShow.push(chartIndex + 1);
                }
            }
        }
        loopTime += 1;
    }
    return { columnsToShow, colorIndex };
};

// get chart options
export const getOptions = (
    title: string,
    metricValueType: string,
    baseLineInd: boolean,
    colorList: any,
    width: any
) => {
    const options: any = {
        title,
        titleTextStyle: {
            bold: true,
            color: "#464646",
        },
        tooltip: { isHtml: true },
        legend: {
            position: "top",
            alignment: "end",
        },
        width,
        height: 375,
        hAxis: {
            textStyle: { color: "#222328", fontSize: 16 },
            title: "Time",
            titleTextStyle: {
                italic: false,
            },
        },
        vAxis: {
            textStyle: { color: "#222328", fontSize: 16 },
            gridlineColor: "#e3e3e3",
            viewWindow: {
                min: 0,
                max: baseLineInd ? 100 : undefined,
            },
            title:
                metricValueType !== "Value" ? "Percentage(%)" : metricValueType,
            titleTextStyle: {
                italic: false,
            },
            baselineColor: "transparent",
            baseline: 0,
        },
        chartArea: {
            width: "90%",
            height: "350",
            left: 80,
            top: 80,
            bottom: 70,
            right: 30,
        },
        series: colorList,
        diff: {
            oldData: {
                tooltip: {
                    prefix: "Benchmark Value (%):",
                },
                color: "#cfcfcf",
            },
            newData: {
                tooltip: {
                    prefix: "Measure Value (%):",
                },
                widthFactor: 0.4,
            },
        },
    };
    return options;
};

const getCustomLabel = (
    type: string,
    measureMetricType: string,
    programName: string,
    title: string,
    chartdata: any,
    interval: string,
    metricValueType: string,
    benchmarkInd?: boolean
) => {
    const ariaLabel = `${type} chart for ${measureMetricType} values of ${programName} program from ${
        title.split("-")[0]
    } to ${title.split("-")[1]}`;
    return ariaLabel;
};

export const getChartArialabel = (
    measureMetricType: string,
    programName: string,
    title: string,
    data: any,
    interval: string,
    metricValueType: string,
    chart: any,
    chartType: string,
    benchmarkInd?: boolean
) => {
    const customLabel = getCustomLabel(
        chartType,
        measureMetricType.split(" ")[0],
        programName,
        title,
        data,
        interval,
        metricValueType,
        benchmarkInd
    );
    const svg = chart.getContainer().getElementsByTagName("svg");
    if (svg.length > 0) {
        if (benchmarkInd) {
            const benchmarkDiv = document.createElement("div");
            benchmarkDiv.className = "benchmark-legend";
            benchmarkDiv.ariaHidden = "true";
            svg[0].parentElement.appendChild(benchmarkDiv);
        }
        svg[0].setAttribute("aria-label", customLabel);
    }
};

// create and draw the chart view
export const drawLineOrColumnChart = (
    google: any,
    data: any,
    documentId: string,
    title: string,
    metricValueType: string,
    chartData: any,
    programName: string,
    interval: string,
    dashboardType: string,
    width: any
) => {
    const { zeroValueInd, filterLabel, groupName, filterItems } = chartData;
    const chartURIs: any = {
        lineChartType: {},
        columnChartType: {},
        groupName,
        filteredCodes: filterItems,
    };
    if (
        (filterLabel === "Metric ID" || filterLabel === "Sub-metric ID") &&
        interval === CONSTANTS.INTERVAL.ANNUAL
    ) {
        const columnChartDiv: any = document.getElementById(
            `column-${documentId}`
        );
        const lineChartDiv: any = document.getElementById(`line-${documentId}`);
        const message =
            dashboardType === CONSTANTS.DASHBOARD_TYPE.CHART
                ? "Annual chart is currently unavailable for the selected metrics."
                : "Annual values are currently unavailable for the selected metrics.";
        const htmlMessage = `<div class="graph-emptystate-container d-flex flex-column justify-content-center"><div class="graph-emptystate-img"><img src="/images/graph-emptystate.png" alt="no data" /></div><p>${message}</p></div>`;
        columnChartDiv.innerHTML = htmlMessage;
        lineChartDiv.innerHTML = htmlMessage;
    } else {
        // get chart options
        const options = getOptions(
            title,
            metricValueType === "Number" ? "Value" : metricValueType,
            zeroValueInd || filterLabel === CONSTANTS.CHART_ID_LABELS.MEASURE,
            getColorForSeries(),
            width
        );
        const columnChart = new google.visualization.ColumnChart(
            document.getElementById(`column-${documentId}`)
        );
        const selectedChartType: any = document.getElementById(
            `chart-input-${documentId}`
        );
        google.visualization.events.addListener(
            columnChart,
            "ready",
            function () {
                const imageData: any = {};
                imageData.id = `column-${documentId}`;
                imageData.URI = columnChart.getImageURI();
                imageData.groupName = groupName;
                imageData.filteredCodes = filterItems;
                chartURIs.columnChartType = imageData;
                if (!selectedChartType?.checked) {
                    chartURIs.URI = columnChart.getImageURI();
                }
                getChartArialabel(
                    filterLabel,
                    programName,
                    title,
                    data,
                    interval,
                    metricValueType,
                    columnChart,
                    CONSTANTS.CHART_TYPE.COLUMN
                );
            }
        );
        columnChart.draw(data, options);
        const lineChart = new google.visualization.LineChart(
            document.getElementById(`line-${documentId}`)
        );
        google.visualization.events.addListener(
            lineChart,
            "ready",
            function () {
                const imageData: any = {};
                imageData.id = `line-${documentId}`;
                imageData.URI = lineChart.getImageURI();
                imageData.groupName = groupName;
                imageData.filteredCodes = filterItems;
                chartURIs.lineChartType = imageData;
                if (selectedChartType?.checked) {
                    chartURIs.URI = lineChart.getImageURI();
                }
                getChartArialabel(
                    filterLabel,
                    programName,
                    title,
                    data,
                    interval,
                    metricValueType,
                    lineChart,
                    CONSTANTS.CHART_TYPE.LINE
                );
            }
        );
        lineChart.draw(data, options);
        return chartURIs;
    }
};

const getFilteredValues = (measureMetricValue: any, selectedIds: any) => {
    const measureMetric = measureMetricValue.filter(
        (value: any) => selectedIds.indexOf(value.measureMetricId) > -1
    );
    return measureMetric;
};

// check if all values in the chart are zero or null
const getZeroValueInd = (chartValues: any) => {
    let zeroValueInd = true;
    for (
        let quarterIndex = 0;
        quarterIndex <= chartValues.quarterOrAnnualValues.length - 1;
        quarterIndex++
    ) {
        const quarterValues = chartValues.quarterOrAnnualValues[quarterIndex];
        for (
            let measureMetricIndex = 0;
            measureMetricIndex <= quarterValues.measureMetricValues.length - 1;
            measureMetricIndex++
        ) {
            if (quarterValues.measureMetricValues[measureMetricIndex].value) {
                zeroValueInd = false;
                break;
            }
        }
    }
    return zeroValueInd;
};

// returns deleted or added rows and columns based on the ID filter
export const getFilteredChart = (measureMetricChart: any, filteredIds: any) => {
    const newChart = JSON.parse(JSON.stringify(measureMetricChart));
    const selectedIds = filteredIds.map((item: any) => item.value);
    for (
        let quarterIndex = 0;
        quarterIndex <= newChart.quarterOrAnnualValues.length - 1;
        quarterIndex++
    ) {
        newChart.quarterOrAnnualValues[quarterIndex].measureMetricValues =
            getFilteredValues(
                newChart.quarterOrAnnualValues[quarterIndex]
                    .measureMetricValues,
                selectedIds
            );
    }
    newChart.codes = newChart.codes.filter(
        (code: any) => selectedIds.indexOf(code) > -1
    );
    newChart.zeroValueInd = getZeroValueInd(newChart);
    return newChart;
};

// get group names and headings
export const getChartGroups = (measures: any, metrics: any) => {
    const measureMetricList: any = [];
    const measureList = measures;
    const metricList = metrics;
    const headings = [];
    if (measureList?.length) {
        headings.push("Measures");
        measureList.map((measure: any) => {
            measureMetricList.push({
                label: measure.measureName,
                value: measure.measureName,
                category: "Measures",
            });
        });
    }
    if (metricList?.length) {
        headings.push("Metrics");
        metricList.map((metric: any) => {
            measureMetricList.push({
                label: metric.metricName,
                value: metric.metricName,
                category: "Metrics",
            });
        });
    }
    return { headings, measureMetricList };
};

// get measures and metrics for only the selected groups
export const getFilteredMeasureMetric = (
    measuresList: any,
    metricsList: any,
    selectedCharts: any
) => {
    const measures = [];
    const metrics = [];
    for (
        let measureIndex = 0;
        measureIndex <= measuresList.length - 1;
        measureIndex++
    ) {
        for (
            let chartIndex = 0;
            chartIndex <= selectedCharts.length - 1;
            chartIndex++
        ) {
            if (
                measuresList[measureIndex].measureName ===
                selectedCharts[chartIndex].value
            ) {
                measures.push(measuresList[measureIndex]);
            }
        }
    }
    for (
        let metricIndex = 0;
        metricIndex <= metricsList.length - 1;
        metricIndex++
    ) {
        for (
            let chartIndex = 0;
            chartIndex <= selectedCharts.length - 1;
            chartIndex++
        ) {
            if (
                metricsList[metricIndex].metricName ===
                selectedCharts[chartIndex].value
            ) {
                metrics.push(metricsList[metricIndex]);
            }
        }
    }
    return { measures, metrics };
};

const setZeroIndForQuarter = (
    measureMetricValue: any,
    zeroValueInd: boolean
) => {
    if (
        measureMetricValue[0]?.value &&
        measureMetricValue[0]?.value !== "NaN" &&
        parseFloat(measureMetricValue[0]?.value)
    ) {
        return false;
    }
    return zeroValueInd;
};

const setZeroIndForAnnual = (
    measureMetricValue: any,
    zeroValueInd: boolean
) => {
    if (
        measureMetricValue[0]?.annualCount &&
        measureMetricValue[0]?.annualCount !== "NaN" &&
        parseFloat(measureMetricValue[0]?.annualCount)
    ) {
        return false;
    }
    return zeroValueInd;
};

const getMeasureCodes = (interval: string, measure: any) => {
    return interval === CONSTANTS.QUARTERLY
        ? measure.measureCodes
        : measure.codes;
};

const getMeasures = (interval: string, measure: any) => {
    return interval === CONSTANTS.QUARTERLY ? measure : measure.measureData;
};
export default preloadCharts;
