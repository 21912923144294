import React, { useEffect, useReducer, useState } from "react";
import { getPrograms } from "api/programs";
import { useDispatch } from "react-redux";
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import CONSTANTS from "common/constants";
import { getAboutAhaData } from "api/strapiAPI";
import CONTENTS from "common/strapiContents";
import { logger } from "utils/logger.utils";
import { BreadCrumb } from "components/BreadCrumb";
import { IPagination } from "components/Pagination";
import TableRowCount from "components/TableRowCount";
import { useLocation } from "react-router-dom";
import DOMPurify from "dompurify";
import Tab from "components/Tab";
import { CardWrapper, BannerWrapper } from "../../styled";
import Card from "../../components/Card";
import Banner from "../../components/Banner";
import { getNavigationByHospitalId } from "../../api/usersApi";

interface Props {
    history: any;
}

export const ProgramsPage = ({ history }: Props) => {
    const location = useLocation();
    const [programs, setPrograms] = useState<any>([]);
    const [aboutAha, setAboutAha] = useState<any>(CONTENTS.ABOUT_AHA);
    const [certificationProcess, setCertificationProcess] = useState<any>(
        CONTENTS.CERTIFICATION_PROCESS
    );
    const [selectedTab, setSelectedTab] = useState<any>(1);
    const dispatch = useDispatch();
    const [breadcrumbItems, setBreadcrumbItems] = useState<any>([]);
    const paginationSetLimit: number = 5;
    const hospitalId: any = localStorage.getItem("selectedHospitalId");
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [mounted, setMounted] = useState<boolean>(false);
    const [roleValue, setRoleValue] = useState<string>("none");
    const defaultPagination: IPagination = {
        pageNumber: 1,
        pageSize: 4,
        isFirst: true,
        isLast: false,
        setPageNumber,
        paginationSetLimit,
    };
    const countryCode: any = localStorage.getItem(
        "selectedHospitalCountryCode"
    );
    const [pagination, setPagination] =
        useState<IPagination>(defaultPagination);

    const handleToggle = (state: any, action: any) => {
        switch (action.type) {
            case "certificate":
                return {
                    ...state,
                    certificateExpandedIndex:
                        state.certificateExpandedIndex === action.index
                            ? null
                            : action.index,
                };
            case "quality":
                return {
                    ...state,
                    qualityExpandedIndex:
                        state.qualityExpandedIndex === action.index
                            ? null
                            : action.index,
                };
            default:
                return state;
        }
    };

    const [expandedIndex, sendAction] = useReducer(handleToggle, {
        certificateExpandedIndex: 0,
        qualityExpandedIndex: 0,
    });
    useEffect(() => {
        dispatch(setPageLoadingStatus({ isPageLoading: true }));
        getNavigationByHospitalId(hospitalId).then(
            (navigationResponse: any) => {
                const { entity, path } = navigationResponse.data.url;
                if (
                    entity === CONSTANTS.HOSPITAL &&
                    path === CONSTANTS.THANKYOU_PAGE
                ) {
                    dispatch(setPageLoadingStatus({ isPageLoading: false }));
                    history.push("/successpayment");
                } else if (
                    entity === CONSTANTS.HOSPITAL &&
                    path === CONSTANTS.PATHS.DASHBOARD
                ) {
                    dispatch(setPageLoadingStatus({ isPageLoading: false }));
                    history.push("/userDashboard");
                } else {
                    const elementsArray = [];
                    elementsArray.push(
                        {
                            label: "Home",
                            returnPath: {
                                pathname: CONSTANTS.ROUTES.USER_DASHBOARD,
                                state: {
                                    pageNum: location?.state?.pageNum,
                                    search: location?.state?.search,
                                },
                            },
                        },
                        {
                            label: "Organization Details",
                            returnPath: {
                                pathname: `${CONSTANTS.ROUTES.HOSPITAL_REGISTRATION}/${hospitalId}`,
                                state: {
                                    pageNum: location?.state?.pageNum,
                                    search: location?.state?.search,
                                },
                            },
                        },
                        {
                            label: "Programs",
                            returnPath: "",
                        }
                    );
                    setBreadcrumbItems(elementsArray);
                    getProgramsList();
                    getAboutAhaData()
                        .then((responses: any) => {
                            if (responses?.data?.data[0]?.about_description) {
                                setAboutAha(
                                    responses.data.data[0].about_description
                                );
                            }
                        })
                        .catch((error) => {
                            logger("Strapi Server Error", error);
                        });

                    setMounted(true);
                }
            }
        );
    }, []);
    useEffect(() => {
        dispatch(setPageLoadingStatus({ isPageLoading: true }));
        getProgramsList();
        if (mounted) {
            setRoleValue("alert");
            window.scrollTo(0, 800);
        }
    }, [pageNumber, selectedTab]);

    const handleSelectedTab = (value: any) => {
        setSelectedTab(value);
    };
    const getProgramsList = () => {
        getPrograms(countryCode, true).then((response: any) => {
            dispatch(setPageLoadingStatus({ isPageLoading: false }));
            if (response.statusCode == 200) {
                setPrograms(response.data);
            }
        });
    };
    const filteredTabsOnContent = [
        programs?.qualityPrograms?.length > 0 && {
            id: 1,
            title: "Quality",
            content: (
                <div
                    className="pt-3 justify-content-start program-list flex-column"
                    id="programList"
                >
                    <ul className="tabContent">
                        {programs?.qualityPrograms?.map(
                            (program: any, i: number) => (
                                <li>
                                    <Card
                                        key={`card_${i}`}
                                        title={program.programName}
                                        description={program.programDescription}
                                        id={program.programId}
                                        icon=""
                                        index={i}
                                        isExpanded={
                                            expandedIndex.qualityExpandedIndex ===
                                            i
                                        }
                                        onToggle={() =>
                                            sendAction({
                                                type: "quality",
                                                index: i,
                                            })
                                        }
                                    />
                                </li>
                            )
                        )}
                    </ul>
                </div>
            ),
        },
        programs?.certificationPrograms?.length > 0 && {
            id: 2,
            title: "Certification",
            content: (
                <div
                    className="pt-3 justify-content-start program-list flex-column"
                    id="programList"
                >
                    <ul className="tabContent">
                        {programs?.certificationPrograms?.map(
                            (program: any, i: number) => (
                                <li>
                                    <Card
                                        key={`card_${i}`}
                                        title={program.programName}
                                        description={program.programDescription}
                                        id={program.programId}
                                        icon=""
                                        index={i}
                                        isExpanded={
                                            expandedIndex.certificateExpandedIndex ===
                                            i
                                        }
                                        onToggle={() =>
                                            sendAction({
                                                type: "certificate",
                                                index: i,
                                            })
                                        }
                                    />
                                </li>
                            )
                        )}
                    </ul>
                </div>
            ),
        },
    ].filter(Boolean);

    return (
        <div>
            <BannerWrapper>
                <div className="container">
                    <BreadCrumb breadcrumbItems={breadcrumbItems} />
                    <Banner />
                </div>
            </BannerWrapper>
            <CardWrapper>
                <div className="container pt-lg-3 pb-5">
                    <h2 className="h2 mt-3 mb-2">Our Programs</h2>
                    <Tab
                        handleSelectedTab={handleSelectedTab}
                        tabTitle={filteredTabsOnContent}
                    />
                </div>
            </CardWrapper>
            <div>
                <div className="container py-5">
                    <h2 className="h2 mt-5">
                        About American Heart Association
                    </h2>
                    <div className="row pt-3">
                        <div className="col-lg-6 d-flex align-items-start">
                            <img
                                src="../images/aha-association.png"
                                alt="Doctor with patient"
                                className="mb-4 w-100 img-fluid"
                            />
                        </div>
                        <div className="col-lg-6">
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(aboutAha),
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProgramsPage;
