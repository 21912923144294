import styled from "styled-components";

export const SelectAllCheckboxWrapper = styled.div`
    &.select-all-checkbox {
        height: 22px;
        display: flex;
        @media only screen and (min-width: 992px) {
            min-width: 22px;
        }
        .select-all-check {
            z-index: 1;
            font-size: 0.1px;
            .select-all-sm-label {
                @media only screen and (min-width: 992px) {
                    font-size: 0.5px;
                    color: #f8f8f8;
                }
            }
        }
        .select-all-input {
            &:hover + label,
            &:focus + label {
                + .tooltip-div {
                    display: block;
                    top: 31px;
                    width: 240px;
                    text-align: center;
                    @media only screen and (max-width: 1299px) {
                        right: -96px;
                        &:before,
                        &:after {
                            display: none;
                        }
                    }
                    @media only screen and (max-width: 991px) {
                        right: -130px;
                        top: 26px;
                    }
                    @media only screen and (max-width: 767px) {
                        right: auto;
                        left: -24px;
                        top: 35px;
                    }
                }
            }
        }
    }
    &.select-intermidiate {
        input[type="checkbox"]:checked + label:after {
            transform: none;
            border-left: none;
            width: 10px;
        }
    }
    .checkbox-dropdown {
        .dropdown-toggle {
            @media only screen and (min-width: 992px) {
                width: 100%;
                height: 30px;
                margin-left: 2px;
            }
            &:after {
                margin-left: 12px;
                @media only screen and (min-width: 992px) {
                    margin-left: 6px;
                    margin-right: 6px;
                }
            }
        }
        .dropdown-item {
            font-size: 14px;
            &.bulk-active {
                color: #c10e21;
            }
        }
    }
    .select-all-checkbox-label {
        font-size: 14px;
        @media only screen and (min-width: 992px) {
            position: absolute;
            width: 1px;
            height: 1px;
            overflow: hidden;
        }
    }
    .select-all-checkbox-mob {
        font-size: 14px;
        font-weight: 500;
        margin-left: -4px;
        @media only screen and (min-width: 992px) {
            width: 1px;
            height: 1px;
            overflow: hidden;
            display: block;
        }
    }
    .select-all-label {
        font-size: 14px;
        font-weight: 500;
        @media only screen and (min-width: 992px) {
            width: 1px;
            height: 1px;
            overflow: hidden;
            display: block;
        }
    }
`;
