import store from "app/store";
import { showToast } from "components/Toast/toast.slice";
import APIUtils from "utils/API.utils";
import config from "../config";

const API = new APIUtils();

export const getRegisteredProgram = async (hospitalId: any): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const APIRes = await API.GET(
                `${
                    config[config.env].apiEndpoints.accounts
                }/hospitals/${hospitalId}/programs`
            );
            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};
