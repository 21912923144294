import styled from "styled-components";

export const DashboardEmptyStateWrapper = styled.div`
    .total-fac-card {
        color: #fff;
        padding: 20px;
        i {
            font-size: 40px;
        }
    }
    .bg-facilities {
        background-color: #6d6e71;
    }
    .bg-fac-active {
        background-color: #53756d;
    }
    .bg-total-registry {
        background-color: #0c7590;
    }
    .total-hospital-count {
        font-size: 29px;
    }
    &.dashboard-placeholder {
        background-color: #f8f8f8;
        margin-bottom: 234px;
        font-size: 22px;
        padding: 30px;
        text-align: center;
        @media (min-width: 768px) {
            padding: 60px;
        }
        img {
            max-width: 100%;
        }
    }
`;
