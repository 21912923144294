import styled from "styled-components";

export const DatepickerWrapper = styled.div`
    .date-wrapper input[type="text"] {
        border: none;
        border-color: none;
        outline: none;
        box-shadow: none;
    }
    .date-field {
        width: 30px;
        padding-left: 4px;
        padding-right: 4px;
        padding-top: 2px;
        margin-top: 5px;
        margin-right: 3px;
        border: 1px solid rgb(153, 153, 153);
    }
    .date-year {
        width: 42px;
        padding: 0 1px;
        text-align: center;
        color: #495057 !important;
    }
    .date-month {
        width: 35px;
        padding: 0 1px;
        text-align: center;
        color: #495057 !important;
    }
    .date-day::placeholder,
    .date-month::placeholder,
    .date-year::placeholder {
        font: 0.8rem/3 sans-serif;
        letter-spacing: -1.8px;
        white-space: pre-wrap;
    }
    .date-day {
        width: 23px;
        padding: 0 1px;
        text-align: center;
        border-right: solid 1px #dee2e6;
        color: #495057 !important;
    }
    .dateFieldWrapper {
        border: "none";
    }

    .date-wrapper {
        display: flex;
        justify-content: space-between !important;
        width: 100% !important;
        height: 37px !important;
        border: 1px solid #777979 !important;
        padding: 0px 3px;
        background: #fff;
        border-radius: 2px;
    }
    .date-wrapper input[type="text"]:focus {
        outline: none !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
    }
    .datepicker-error {
        margin-top: -14px;
        color: red;
        font-size: 0.8rem;
    }
    .date-wrapper legend {
        opacity: 0;
    }
    .date-wrapper:focus-within {
        outline: 2px solid #d6700a !important;
        -webkit-box-shadow: 0 0 4px 4px #d6700a !important;
        box-shadow: 0 0 4px 4px #d6700a !important;
    }

    .date-wrapper:focus-within legend {
        opacity: 1;
    }

    input[type="date"]::-moz-inner-spin-button,
    input[type="date"]::-moz-calendar-picker-indicator {
        margin: 0;
    }

    input[type="date"]::-moz-datetime-edit {
        display: none !important;
    }
    input[type="date"]::-webkit-inner-spin-button,
    input[type="date"]::-webkit-calendar-picker-indicator {
        margin: 0;
    }
    input[type="date"]::-webkit-datetime-edit {
        display: none !important;
    }
    .date-field input[type="date"]::-moz-date-picker-indicator {
        font-size: inherit;
        padding-left: 10px;
        padding-right: 10px;
    }
    input[type="date"]::-moz-date- {
        display: none !important;
    }
    input[type="date"] {
        visibility: hidden;
    }
    .date-btn {
        background: transparent;
        padding: 1px 3px;
        border: none;
        margin: 2px;
    }
    .aui-datepicker {
        content: "";
        display: block;
        width: 20px;
        height: 20px;
        background-image: url(/images/datepicker.png);
        background-size: cover;
        background-repeat: no-repeat;
        border: none;
    }
`;
