import { combineReducers } from "@reduxjs/toolkit";
import userReducer from "pages/Verify/user.slice";
import loaderReducer from "components/Loader/loader.slice";
import toastReducer from "components/Toast/toast.slice";
import measuresDateFilterReducer from "components/MeasuresDateFilter/measuresDateFilter.slice";
import regionReducer from "components/SelectDomesticInternational/region.slice.";
import searchFilterReducer from "components/SearchFilter/searchFilter.slice";

const rootReducer = combineReducers({
    user: userReducer,
    loader: loaderReducer,
    toast: toastReducer,
    measuresDateFilter: measuresDateFilterReducer,
    region: regionReducer,
    searchFilter: searchFilterReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
