import styled from "styled-components";

export const UploadSpreadsheet = styled.div`
    border-top: 1px solid #e3e3e3;
    .upload-title {
        padding-top: 40px;
        @media only screen and (min-width: 768px) {
            padding-top: 60px;
        }
    }
    .aui-drag-section {
        padding: 16px;
        @media only screen and (min-width: 768px) {
            padding: 36px;
        }
    }
    .uploaded-table {
        thead {
            @media only screen and (min-width: 992px) {
                th:nth-child(1) {
                    width: 20%;
                }
                th:nth-child(2) {
                    width: 50%;
                }
                th:nth-child(3) {
                    width: 15%;
                }
                th:nth-child(4) {
                    width: 15%;
                }
            }
        }
        a {
            color: #c10e21;
        }
        .uploaded-status {
            font-size: 16px;
        }
        .uploaded-action {
            button {
                font-size: 14px;
                padding: 0;
                @media only screen and (min-width: 992px) {
                    padding: 0 2px;
                }
            }
        }
        .aui-responsive-table {
            tbody {
                tr {
                    td {
                        &:before {
                            @media only screen and (min-width: 576px) {
                                width: 25%;
                            }
                        }
                        .aui-td {
                            @media only screen and (min-width: 576px) {
                                width: 75%;
                            }
                            @media only screen and (min-width: 992px) {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
        .measure-download-tooltip {
            left: -20px;
            &:before,
            &:after {
                left: 30%;
            }
        }
        .failed-info-tooltip {
            width: 150px;
            left: -80px;
            &:before,
            &:after {
                left: 55%;
            }
        }
    }
    .aui-drag-upload-icon {
        font-size: 76px;
    }
`;
