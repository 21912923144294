import CONSTANTS from "common/constants";
import React from "react";
import { useDispatch } from "react-redux";
import { hospitalApprovalStatus } from "common/utils";
import { HospitalTableWrapper } from "components/AdminHospitalTable/styled";
import { Link } from "react-router-dom";

interface Props {
    tab: string;
    pageNumber: number;
    hospitals: any;
    actionURL: string;
    isTableLoading: boolean;
    isActive: boolean;
    isDeactivated: boolean;
    setSortInd: any;
    sortInd: any;
    close: any;
}

export const EMSAgencyTable = ({
    tab,
    pageNumber,
    hospitals,
    actionURL,
    isTableLoading,
    isActive,
    isDeactivated,
    setSortInd,
    sortInd,
    close,
}: Props) => {
    const dispatch = useDispatch();

    return (
        <HospitalTableWrapper className="hospital-status-table w-100">
            <table
                className={`aui-responsive-status-table ${
                    isTableLoading ? CONSTANTS.TABLE_SPINNER : ""
                }`}
            >
                <thead>
                    <tr>
                        <th scope="col">
                            <div className="aui-th justify-content-between">
                                Organization Details
                            </div>
                        </th>
                        <th scope="col">
                            <div className="aui-th justify-content-between">
                                Location Details
                            </div>
                        </th>
                        <th scope="col">
                            <div className="aui-th justify-content-between">
                                Program Type
                            </div>
                        </th>
                        <th scope="col">
                            <div className="aui-th justify-content-between">
                                Status
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {hospitals.length > 0 ? (
                        hospitals.map((hospital: any) => {
                            return (
                                <tr
                                    key={hospital.hospitalId}
                                    className={`${hospitalApprovalStatus(tab)}`}
                                >
                                    <td data-title="Agency Details">
                                        <div className="aui-td">
                                            <div>
                                                <Link
                                                    to={{
                                                        pathname: `${actionURL}${hospital.hospitalId}/program/${hospital.programsId}/category/${hospital.categoryId}`,
                                                        state: {
                                                            backToOrganizationManagement:
                                                                "/emsadmin/organization-management",
                                                            adminOrganizationMangemnetTab:
                                                                tab,
                                                            adminOrganizationMangemnetPage:
                                                                pageNumber,
                                                            pageSort: sortInd,
                                                            facilityName:
                                                                hospital.hospitalName,
                                                        },
                                                    }}
                                                    onClick={() => {
                                                        localStorage.setItem(
                                                            "resetFilter",
                                                            "false"
                                                        );
                                                    }}
                                                    className="btn-text-link-uline"
                                                >
                                                    {hospital.hospitalName}
                                                </Link>
                                                {hospital?.emsId && (
                                                    <div className="mt-1">
                                                        AHA EMS ID :{" "}
                                                        <span>
                                                            {hospital?.emsId}
                                                        </span>
                                                    </div>
                                                )}
                                                <div className="mt-1">
                                                    Organization Code :{" "}
                                                    <span>
                                                        {hospital.facilityCode}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td data-title="Location Details">
                                        <div className="aui-td">
                                            <div className="corp-loc-detail">
                                                <div>
                                                    <span>Location: </span>
                                                    {hospital.countryName}
                                                </div>
                                                <div className="mt-1">
                                                    <span>State: </span>
                                                    {hospital.stateName}
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td data-title="Program Type">
                                        <div className="aui-td">
                                            {hospital.categoryName}
                                        </div>
                                    </td>
                                    <td data-title="Status">
                                        <div className="aui-td">
                                            {isDeactivated
                                                ? "Deactivated"
                                                : hospital.programApprovalStatus}
                                        </div>
                                    </td>
                                </tr>
                            );
                        })
                    ) : (
                        <tr className="aui-status-table-emptystate">
                            <td colSpan={4}>
                                <div className="aui-td" role="alert">
                                    No organization match your search.
                                    <br />
                                    Please try modifying your search criteria
                                </div>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </HospitalTableWrapper>
    );
};

export default EMSAgencyTable;
