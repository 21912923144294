import store from "app/store";
import { showToast } from "components/Toast/toast.slice";
import APIUtils from "utils/API.utils";
import config from "../config";

const API = new APIUtils();

export const getPrograms = async (
    countryCode: string = "",
    isPrograms: boolean = false
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            countryCode = countryCode ? `/${countryCode}` : "";
            if (isPrograms) {
                countryCode = countryCode ? `${countryCode}` : "";
            }
            const APIRes = await API.GET(
                `${
                    config[config.env].apiEndpoints.catalogs
                }/programs${countryCode}`
            );

            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const getCategoriesForPrograms = async (
    programIds: any
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const apiEndpoint = `${
                config[config.env].apiEndpoints.catalogs
            }/categories/corporation/getCategoryDetails?programId=${JSON.stringify(
                programIds
            )}`;
            const APIRes = await API.GET(apiEndpoint);

            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (errorData) {
            const err: any = errorData;
            store.dispatch(showToast(err));
            reject(errorData);
        }
    });
};
