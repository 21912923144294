import React, { useEffect, useState } from "react";
import { PaginationStyle } from "./styled";

export interface IPagination {
    pageNumber: number;
    pageSize: number;
    totalCount?: number;
    totalPages?: number;
    isFirst: boolean;
    isLast: boolean;
    setPageNumber: any;
    paginationSetLimit: number;
}

const Pagination = ({
    pageNumber,
    pageSize,
    totalCount,
    totalPages,
    isFirst,
    isLast,
    setPageNumber,
    paginationSetLimit,
}: IPagination) => {
    const [paginationRangeLimit, setPaginationRangeLimit] = useState<any>();
    const [paginationSetIndex, setPaginationSetIndex] = useState<any>(0);

    useEffect(() => {
        let paginationSetMaxLimit = paginationSetLimit;
        if (totalPages && totalPages < paginationSetLimit) {
            paginationSetMaxLimit = totalPages;
        } else if (totalPages === 0) {
            paginationSetMaxLimit = 1;
        }
        if (pageNumber > paginationSetLimit + paginationSetIndex) {
            setPaginationSetIndex(
                Math.floor((pageNumber - 1) / paginationSetLimit) *
                    paginationSetLimit
            );
            if (
                Number(totalPages) - pageNumber < paginationSetLimit &&
                Number(totalPages) % paginationSetLimit !== 0
            ) {
                if (
                    pageNumber % paginationSetLimit < 5 &&
                    (Number(totalPages) % paginationSetLimit) + pageNumber <=
                        Number(totalPages)
                ) {
                    setPaginationRangeLimit(paginationSetLimit);
                } else {
                    setPaginationRangeLimit(
                        Number(totalPages) % paginationSetLimit
                    );
                }
            } else {
                setPaginationRangeLimit(paginationSetMaxLimit);
            }
        } else {
            setPaginationRangeLimit(paginationSetMaxLimit);
        }
    }, [paginationSetLimit, totalPages]);

    const modifyNextPaginationRange = () => {
        setPageNumber(pageNumber + 1);
        const nextPage = pageNumber + 1;

        if (nextPage > paginationSetLimit + paginationSetIndex) {
            setPaginationSetIndex(
                Math.floor(nextPage / paginationSetLimit) * paginationSetLimit
            );
            if (Number(totalPages) - nextPage < paginationSetLimit) {
                setPaginationRangeLimit(Number(totalPages) - nextPage + 1);
            }
        }
    };

    const modifyPreviousPaginationRange = () => {
        setPageNumber(pageNumber - 1);
        const prevPage = pageNumber - 1;

        if (prevPage <= paginationSetIndex) {
            setPaginationSetIndex(prevPage - paginationSetLimit);
            setPaginationRangeLimit(paginationSetLimit);
        }
    };

    return (
        <PaginationStyle>
            <div className="d-flex pagination-block flex-row-reverse">
                <nav aria-label="Pagination">
                    <ul className="pagination aui-pagination">
                        <li className="page-item">
                            <button
                                className={
                                    isFirst
                                        ? "page-link page-disabled"
                                        : "page-link"
                                }
                                aria-label="go to previous page"
                                disabled={isFirst}
                                onClick={() => modifyPreviousPaginationRange()}
                            >
                                <span aria-hidden="true">&laquo;</span>
                            </button>
                        </li>
                        {[...Array(paginationRangeLimit)].map((x, i) => (
                            <li
                                key={i}
                                className={
                                    1 + i + paginationSetIndex === pageNumber
                                        ? "page-item active"
                                        : "page-item"
                                }
                            >
                                <button
                                    className="page-link"
                                    onClick={() => {
                                        setPageNumber(
                                            1 + i + paginationSetIndex
                                        );
                                    }}
                                    {...(1 + i + paginationSetIndex ===
                                    pageNumber
                                        ? { "aria-current": "page" }
                                        : {})}
                                >
                                    {1 + i + paginationSetIndex ===
                                    pageNumber ? (
                                        <span className="sr-only">Page</span>
                                    ) : (
                                        <span className="sr-only">
                                            Go to page
                                        </span>
                                    )}
                                    {1 + i + paginationSetIndex}
                                </button>
                            </li>
                        ))}
                        <li className="page-item">
                            <button
                                className={
                                    isLast
                                        ? "page-link page-disabled"
                                        : "page-link"
                                }
                                aria-label="go to next page"
                                disabled={isLast}
                                onClick={() => {
                                    modifyNextPaginationRange();
                                }}
                            >
                                <span aria-hidden="true">&raquo;</span>
                            </button>
                        </li>
                    </ul>
                </nav>
            </div>
        </PaginationStyle>
    );
};

export default Pagination;
