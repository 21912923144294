import React, { useEffect, useState } from "react";
import { CorporationDetailWrapper } from "./styled";
import Sidebar from "../../Admin/Sidebar/Sidebar";
import "../../styles/styles.scss";
import CorporationBanner from "../CorporateBanner/CorporationBanner";
import { Link, useLocation, useParams } from "react-router-dom";
import CONSTANTS from "common/constants";
import ViewCorporation from "./ViewCorporatePage";
import AddOrEditCorporation from "./AddOrEditCorporationPage";
import CorporationRegisteredFacilities from "./CorporationFacilitiesRegistered";
import {
    getAllFacilitiesByCorpId,
    getCorporationDetailsById,
} from "api/corporationAPI";
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import { useDispatch } from "react-redux";
import { logError } from "../../../utils/logger.utils";
import { IPagination } from "../../../components/Pagination";
import { BreadCrumb } from "components/BreadCrumb";
import "styles/toggle-switch.scss";

export const CorporationDetails = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const page = location.state?.p;
    const [editMode, setEditMode] = useState<boolean>(false);
    const [facilityRemovedInd, setFacilityRemovedInd] =
        useState<boolean>(false);
    const [corporationDetails, setCorporationDetails] = useState<any>({});
    const [corporationFacilities, setCorporationFacilities] = useState<any>([]);
    const [selectedPrograms, setSelectedPrograms] = useState<any>([]);
    const [pageNumber, setPageNumber] = useState<number>(
        page ? Number(page) : 1
    );
    const [breadcrumbItems, setBreadcrumbItems] = useState<any>([]);
    const paginationSetLimit: number = 5;
    const defaultPagination: IPagination = {
        pageNumber: 1,
        pageSize: 10,
        isFirst: true,
        isLast: false,
        setPageNumber,
        paginationSetLimit,
    };
    const [pagination, setPagination] =
        useState<IPagination>(defaultPagination);
    const [roleValue, setRoleValue] = useState<string>("none");
    const [mounted, setMounted] = useState<boolean>(false);
    const { corporationId }: any = useParams();
    const NEW_CORPORATION = "new-corporation";
    const corporationMangangementTab = location.state?.bT
        ? location.state?.bT
        : CONSTANTS.ACTIVE;
    const corporationMangangementPage = location.state?.bP
        ? Number(location.state?.bP)
        : 1;
    const facilitiesRegisteredPage = location.state?.hRP
        ? Number(location.state?.hRP)
        : null;
    const returnPath: Object = corporationMangangementTab
        ? { bT: corporationMangangementTab, bP: corporationMangangementPage }
        : {};
    const backToCorporationManagement: Object = {
        pathname: `/corporation/corporation-management`,
        state: {
            t: corporationMangangementTab,
            p: corporationMangangementPage,
        },
    };
    useEffect(() => {
        if (corporationId !== NEW_CORPORATION) {
            dispatch(setPageLoadingStatus({ isPageLoading: true }));
            Promise.all([
                getCorporationDetailsById(corporationId),
                getAllFacilitiesByCorpId(
                    corporationId,
                    pageNumber,
                    pagination.pageSize
                ),
            ])
                .then((corporationResponse: any) => {
                    if (
                        corporationResponse[0].success &&
                        corporationResponse[1].success
                    ) {
                        if (
                            corporationResponse[0].data &&
                            corporationResponse[0].data.corporationDetails
                        ) {
                            setCorporationDetails(
                                corporationResponse[0].data.corporationDetails
                            );
                        }
                        if (
                            corporationResponse[1]?.data?.facilities?.length > 0
                        ) {
                            setCorporationFacilities(
                                corporationResponse[1].data.facilities
                            );
                        } else {
                            setCorporationFacilities([]);
                        }
                        if (mounted) {
                            setRoleValue("alert");
                        }
                        setMounted(true);
                        if (
                            corporationResponse[0]?.data?.corporationDetails
                                ?.corporationProgramDetails.length > 0
                        ) {
                            const selectedProgramsMap: any = [];
                            corporationResponse[0].data.corporationDetails.corporationProgramDetails.map(
                                (program: any) => {
                                    selectedProgramsMap.push({
                                        label: program.programName,
                                        value: program.programId,
                                    });
                                }
                            );
                            setSelectedPrograms(selectedProgramsMap);
                        }
                        setPagination({
                            ...corporationResponse[1].data.paginationBody
                                ._pagination,
                            setPageNumber,
                        });
                    }
                    dispatch(setPageLoadingStatus({ isPageLoading: false }));
                })
                .catch((error: any) => {
                    logError(error);
                    dispatch(setPageLoadingStatus({ isPageLoading: false }));
                });
        }
    }, [editMode, pageNumber, facilityRemovedInd]);
    useEffect(() => {
        const elementsArray = [];
        if (corporationId) {
            if (corporationMangangementTab == CONSTANTS.ACTIVE) {
                elementsArray.push(
                    {
                        label: "Corporation Management",
                        returnPath: backToCorporationManagement,
                    },
                    {
                        label: "Active Corporation",
                        returnPath: backToCorporationManagement,
                    },
                    {
                        label: location.state.corporationName,
                    }
                );
            } else if (corporationMangangementTab == CONSTANTS.INACTIVE) {
                elementsArray.push(
                    {
                        label: "Corporation Management",
                        returnPath: backToCorporationManagement,
                    },
                    {
                        label: "Inactive Corporation",
                        returnPath: backToCorporationManagement,
                    },
                    {
                        label: location.state.corporationName,
                    }
                );
            }
            setBreadcrumbItems(elementsArray);
        }
    }, []);
    return (
        <CorporationDetailWrapper className="container-fluid p-0 d-flex flex-grow-1">
            <div className="row no-gutters w-100 flex-grow-1">
                <Sidebar activeTab="corporateManagement" />
                <section className="d-flex admin-main-content container">
                    <div className="row no-gutters w-100 admin-main">
                        {corporationId !== NEW_CORPORATION ? (
                            <div className="col-12 navbar-expand-lg">
                                <div className="registry-mgnt-header d-sm-flex justify-content-between align-items-center mb-4">
                                    <div className="pr-2">
                                        <BreadCrumb
                                            breadcrumbItems={breadcrumbItems}
                                        />
                                        <h1 className="h2 font-400">
                                            Corporation Management
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <nav aria-label="breadcrumb" className="px-2 mb-3">
                                <ol className="breadcrumb aha-breadcrumb px-0">
                                    <li className="breadcrumb-item">
                                        <Link
                                            to={backToCorporationManagement}
                                            className="ml-n2"
                                        >
                                            Corporation Management
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item active">
                                        <div
                                            aria-current="page"
                                            role="link"
                                            tabIndex={0}
                                        >
                                            Add Corporation
                                        </div>
                                    </li>
                                </ol>
                            </nav>
                        )}
                        {corporationId !== NEW_CORPORATION && (
                            <CorporationBanner
                                corporationDetails={corporationDetails}
                            />
                        )}
                        <div className="col-12 navbar-expand-lg">
                            {corporationId !== NEW_CORPORATION && (
                                <div className="d-flex d-lg-none justify-content-end mt-n3">
                                    <button
                                        type="button"
                                        className="aui-navbar-toggler navbar-toggler btn btn-round btn-secondary"
                                        data-toggle="collapse"
                                        data-target="#corporationNavbarManagement"
                                        aria-controls="corporationNavbarManagement"
                                        aria-expanded="false"
                                        aria-label="navigation menu"
                                    >
                                        Menu
                                    </button>
                                </div>
                            )}
                            <div className="aui-p-tabs">
                                {corporationId !== NEW_CORPORATION && (
                                    <div
                                        id="corporationNavbarManagement"
                                        className="collapse navbar-collapse"
                                    >
                                        <ul
                                            className="nav nav-tabs mb-4"
                                            role="tablist"
                                        >
                                            <li
                                                className="nav-item"
                                                role="presentation"
                                            >
                                                <a
                                                    className={`nav-link ${
                                                        facilitiesRegisteredPage
                                                            ? ""
                                                            : "active"
                                                    }`}
                                                    data-toggle="tab"
                                                    href="#corporationDetailsTab"
                                                    role="tab"
                                                >
                                                    Details
                                                </a>
                                            </li>
                                            <li
                                                className="nav-item"
                                                role="presentation"
                                            >
                                                <a
                                                    className={`nav-link ${
                                                        facilitiesRegisteredPage
                                                            ? "active"
                                                            : ""
                                                    }`}
                                                    data-toggle="tab"
                                                    href="#corporationFacilitiesTab"
                                                    role="tab"
                                                >
                                                    Organizations Registered
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                )}
                                <div className="tab-content">
                                    <div
                                        id="corporationDetailsTab"
                                        className={`tab-pane py-md-0 pt-3 pb-5 ${
                                            facilitiesRegisteredPage
                                                ? ""
                                                : "active"
                                        }`}
                                        role="tabpanel"
                                    >
                                        <section aria-label="Corporation Detail tab Content">
                                            <div className="corp-title-border d-flex justify-content-between">
                                                <h3 className="h4 font-500">
                                                    {corporationId !==
                                                    NEW_CORPORATION
                                                        ? "Details"
                                                        : "Add Corporation"}
                                                </h3>

                                                {corporationId !==
                                                    NEW_CORPORATION && (
                                                    <div className="edit-btn-toggle">
                                                        <input
                                                            type="checkbox"
                                                            id="editCorporationDetails"
                                                            className="edit-btn-checkbox"
                                                            role="button"
                                                            aria-pressed={
                                                                editMode
                                                            }
                                                            onClick={() => {
                                                                setEditMode(
                                                                    !editMode
                                                                );
                                                            }}
                                                        />
                                                        {editMode ? (
                                                            <label
                                                                htmlFor="editCorporationDetails"
                                                                className="toggle-label"
                                                            >
                                                                Cancel Edit
                                                            </label>
                                                        ) : (
                                                            <label
                                                                htmlFor="editCorporationDetails"
                                                                className="toggle-label"
                                                            >
                                                                <i
                                                                    className="aha-icon-edit toggle-edit"
                                                                    title="Edit Corporation Details"
                                                                    aria-hidden="true"
                                                                />
                                                                <span className="sr-only">
                                                                    Edit
                                                                </span>
                                                            </label>
                                                        )}
                                                        {editMode ? (
                                                            <div
                                                                aria-live="polite"
                                                                className="d-block d-md-none sr-only"
                                                            >
                                                                Cancel edit
                                                            </div>
                                                        ) : (
                                                            <div
                                                                aria-live="polite"
                                                                className="d-block d-md-none sr-only"
                                                            >
                                                                Edit
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            </div>

                                            {corporationId !==
                                                NEW_CORPORATION && !editMode ? (
                                                <ViewCorporation
                                                    corporationDetails={
                                                        corporationDetails
                                                    }
                                                    corporationMangangementTab={
                                                        corporationMangangementTab
                                                    }
                                                    corporationMangangementPage={
                                                        corporationMangangementPage
                                                    }
                                                    profileMode={false}
                                                />
                                            ) : (
                                                <AddOrEditCorporation
                                                    corporation={
                                                        corporationDetails
                                                    }
                                                    backToCorporationManagement={
                                                        backToCorporationManagement
                                                    }
                                                    editMode={editMode}
                                                    setEditMode={setEditMode}
                                                    selectedPrograms={
                                                        selectedPrograms
                                                    }
                                                />
                                            )}
                                        </section>
                                    </div>
                                    {corporationId !== NEW_CORPORATION && (
                                        <div
                                            id="corporationFacilitiesTab"
                                            className={`tab-pane py-lg-3 ${
                                                facilitiesRegisteredPage
                                                    ? "active"
                                                    : ""
                                            }`}
                                            role="tabpanel"
                                        >
                                            <CorporationRegisteredFacilities
                                                corporationFacilities={
                                                    corporationFacilities
                                                }
                                                corporationName={
                                                    corporationDetails.corporationName
                                                }
                                                corporationId={corporationId}
                                                returnPath={returnPath}
                                                pagination={pagination}
                                                paginationSetLimit={
                                                    paginationSetLimit
                                                }
                                                setFacilityRemovedInd={
                                                    setFacilityRemovedInd
                                                }
                                                facilityRemovedInd={
                                                    facilityRemovedInd
                                                }
                                                roleValue={roleValue}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Remove Facility Confirmation Modal */}
                    <div
                        className="modal fade show aui-modal"
                        id="deactivateFacilityConfirmationModal"
                        tabIndex={-1}
                        aria-labelledby="deactivateFacLabel"
                        aria-modal="true"
                        role="dialog"
                    >
                        <div className="modal-dialog modal-dialog-centered modal-sm">
                            <div className="modal-content">
                                <div className="modal-body p-0">
                                    <div className="text-center">
                                        <span
                                            id="deactivateFacLabel"
                                            className="sr-only"
                                        >
                                            Confirm deactivating Organization
                                            from corporation
                                        </span>
                                        <p className="pb-2">
                                            Are you sure you want to deactivate
                                            this Corporation?
                                        </p>
                                        <div className="mt-4 d-flex justify-content-center">
                                            <button
                                                className="btn btn-secondary btn-round btn-sm mx-2"
                                                data-dismiss="modal"
                                                aria-label="Cancel removal and close the modal"
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                className="btn btn-primary btn-round btn-sm mx-2"
                                                data-dismiss="modal"
                                                aria-label="Confirm Removing this Organization"
                                            >
                                                Confirm
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </CorporationDetailWrapper>
    );
};

export default CorporationDetails;
