import CONSTANTS from "common/constants";
import React, { useEffect } from "react";
import { MeasureDataTable } from "./MeasureMetricTableStyled";
import "styles/focusable-element.scss";
import "styles/popover.scss";
import "styles/browser-customstyle.scss";
import ReactHtmlParser from "react-html-parser";

interface Props {
    data: any;
    isMeasureTableLoading?: boolean;
    readOnly?: any;
    handleMeasureChange?: any;
    handleMetricChange?: any;
    submit?: any;
    disabled?: any;
    isSaveInProgress?: boolean;
}

const MeasureMetricTable = (props: Props) => {
    useEffect(() => {
        if (CONSTANTS.WINDOW.AUI.Popover) {
            CONSTANTS.WINDOW.AUI.Popover.init();
        }
    }, [props.data]);
    const isSafariBrowser = () => {
        if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
            return true;
        }
        return false;
    };
    const getCode = (code: any) => {
        const div = document.createElement("div") as HTMLElement;
        div.innerHTML = code;
        const firstSpan = div.querySelector("span");
        return firstSpan?.textContent?.trim();
    };
    return (
        <MeasureDataTable>
            <form onSubmit={props.submit}>
                <div
                    role="group"
                    aria-labelledby="tables"
                    className="measure-group-table"
                >
                    <label id="tables" className="sr-only">
                        Measures Tables
                    </label>
                    <div className="measure-table">
                        {props.data?.measuresData?.map((measures: any) => {
                            return (
                                <table
                                    key={measures[0].code}
                                    className={`aui-responsive-status-table ${
                                        props.isMeasureTableLoading
                                            ? CONSTANTS.TABLE_SPINNER
                                            : ""
                                    }`}
                                    aria-label={measures[0].groupName}
                                >
                                    <thead>
                                        <tr>
                                            <th scope="col">
                                                <div className="aui-th">
                                                    Measures ID
                                                </div>
                                            </th>
                                            <th scope="col">
                                                <div className="aui-th">
                                                    Measures Name
                                                </div>
                                            </th>
                                            <th scope="col">
                                                <div className="aui-th">
                                                    Description
                                                </div>
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-center"
                                            >
                                                <div className="aui-th">
                                                    Numerator
                                                </div>
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-center"
                                            >
                                                <div className="aui-th">
                                                    Denominator
                                                </div>
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-center"
                                            >
                                                <div className="aui-th">
                                                    <span aria-hidden>%</span>
                                                    <span className="sr-only">
                                                        Percentage Calculated
                                                    </span>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="measure-subheading-tr">
                                            <td colSpan={6}>
                                                <div className="measure-subheading">
                                                    <span>
                                                        <span className="sr-only">
                                                            Sub heading{" "}
                                                        </span>
                                                        {measures[0].groupName}
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>
                                        {measures.map((measure: any) => {
                                            const percentage =
                                                measure.numerator != null &&
                                                measure.denominator != null
                                                    ? (measure.numerator /
                                                          measure.denominator) *
                                                      100
                                                    : -1;
                                            return (
                                                <tr
                                                    key={measure.code}
                                                    className="aui-table-status-normal"
                                                >
                                                    <td data-title="Measures ID">
                                                        <div className="aui-td">
                                                            {ReactHtmlParser(
                                                                measure.code
                                                            )}
                                                        </div>
                                                    </td>
                                                    <td data-title="Measures Name">
                                                        <div className="aui-td">
                                                            {ReactHtmlParser(
                                                                measure.name
                                                            )}
                                                        </div>
                                                    </td>
                                                    <td data-title="Description">
                                                        <div className="aui-td">
                                                            {ReactHtmlParser(
                                                                measure.description
                                                            )}
                                                        </div>
                                                    </td>
                                                    <td
                                                        data-title="Numerator"
                                                        className="measure-table-value"
                                                    >
                                                        <div className="aui-td">
                                                            <div
                                                                className="form-field m-0 d-flex align-items-center justify-content-lg-center measure-input"
                                                                id={`num-${measure.propertyId}`}
                                                            >
                                                                {measure?.numeratorHelptext && (
                                                                    <>
                                                                        <button
                                                                            type="button"
                                                                            className="aha-icon-help measure-help btn btn-text"
                                                                            data-toggle="popover"
                                                                            {...(!isSafariBrowser()
                                                                                ? {
                                                                                      "data-trigger":
                                                                                          "focus",
                                                                                  }
                                                                                : {})}
                                                                            data-html="true"
                                                                            data-container={`#num-${measure.propertyId}`}
                                                                            data-content={
                                                                                measure.numeratorHelptext
                                                                            }
                                                                        >
                                                                            <div className="sr-only">
                                                                                {" "}
                                                                                measure
                                                                                id
                                                                                {ReactHtmlParser(
                                                                                    measure.code
                                                                                )}{" "}
                                                                                numerator
                                                                                help
                                                                                text
                                                                            </div>
                                                                        </button>
                                                                    </>
                                                                )}
                                                                {props.readOnly ? (
                                                                    <span>
                                                                        {measure.numerator !==
                                                                            null &&
                                                                        measure.numerator >=
                                                                            0
                                                                            ? parseInt(
                                                                                  measure.numerator
                                                                              )
                                                                            : ""}
                                                                    </span>
                                                                ) : (
                                                                    <>
                                                                        <input
                                                                            aria-label="numerator"
                                                                            aria-describedby={`measure-numerator-${measure.propertyId}`}
                                                                            type="number"
                                                                            className="form-control"
                                                                            name={`${measure.code}num`}
                                                                            min="0"
                                                                            max="9999"
                                                                            onChange={
                                                                                props.handleMeasureChange
                                                                            }
                                                                            defaultValue={
                                                                                measure.numerator ===
                                                                                    null ||
                                                                                measure.numerator ===
                                                                                    "" ||
                                                                                isNaN(
                                                                                    measure.numerator
                                                                                )
                                                                                    ? ""
                                                                                    : measure.numerator
                                                                            }
                                                                        />
                                                                        <div
                                                                            className="sr-only"
                                                                            id={`measure-numerator-${measure.propertyId}`}
                                                                        >
                                                                            {" "}
                                                                            measure
                                                                            id
                                                                            {ReactHtmlParser(
                                                                                measure.code
                                                                            )}{" "}
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td
                                                        data-title="Denominator"
                                                        className="measure-table-value"
                                                    >
                                                        <div className="aui-td">
                                                            <div
                                                                className="form-field m-0 d-flex align-items-center justify-content-lg-center measure-input"
                                                                id={`den-${measure.propertyId}`}
                                                            >
                                                                {measure?.denominatorHelptext && (
                                                                    <button
                                                                        type="button"
                                                                        className="aha-icon-help measure-help btn btn-text"
                                                                        data-toggle="popover"
                                                                        {...(!isSafariBrowser()
                                                                            ? {
                                                                                  "data-trigger":
                                                                                      "focus",
                                                                              }
                                                                            : {})}
                                                                        data-html="true"
                                                                        data-container={`#den-${measure.propertyId}`}
                                                                        data-content={
                                                                            measure.denominatorHelptext
                                                                        }
                                                                    >
                                                                        <div className="sr-only">
                                                                            {" "}
                                                                            measure
                                                                            id
                                                                            {ReactHtmlParser(
                                                                                measure.code
                                                                            )}{" "}
                                                                            denominator
                                                                            help
                                                                            text
                                                                        </div>
                                                                    </button>
                                                                )}
                                                                {props.readOnly ? (
                                                                    <span>
                                                                        {measure.denominator !==
                                                                            null &&
                                                                        measure.denominator >=
                                                                            0
                                                                            ? parseInt(
                                                                                  measure.denominator
                                                                              )
                                                                            : ""}
                                                                    </span>
                                                                ) : (
                                                                    <>
                                                                        <input
                                                                            aria-label="denominator"
                                                                            aria-describedby={`measure-denominator-${measure.propertyId}`}
                                                                            type="number"
                                                                            className="form-control"
                                                                            name={`${measure.code}den`}
                                                                            min="0"
                                                                            max="9999"
                                                                            onChange={
                                                                                props.handleMeasureChange
                                                                            }
                                                                            defaultValue={
                                                                                measure.denominator ===
                                                                                    null ||
                                                                                measure.denominator ===
                                                                                    "" ||
                                                                                isNaN(
                                                                                    measure.denominator
                                                                                )
                                                                                    ? ""
                                                                                    : measure.denominator
                                                                            }
                                                                        />
                                                                        <div
                                                                            className="sr-only"
                                                                            id={`measure-denominator-${measure.propertyId}`}
                                                                        >
                                                                            {" "}
                                                                            measure
                                                                            id
                                                                            {ReactHtmlParser(
                                                                                measure.code
                                                                            )}
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td
                                                        data-title="Percentage"
                                                        className="measure-table-value"
                                                    >
                                                        <div className="aui-td">
                                                            <div
                                                                className="form-field m-0 d-flex align-items-center justify-content-lg-center"
                                                                {...(!props.readOnly
                                                                    ? {
                                                                          role: "alert",
                                                                      }
                                                                    : {})}
                                                            >
                                                                {props.readOnly ? (
                                                                    <span>
                                                                        {isNaN(
                                                                            percentage
                                                                        ) ||
                                                                        percentage >
                                                                            100 ||
                                                                        percentage <
                                                                            0
                                                                            ? ""
                                                                            : `${percentage.toFixed(
                                                                                  2
                                                                              )}%`}
                                                                    </span>
                                                                ) : (
                                                                    <>
                                                                        <span className="sr-only">
                                                                            percentage
                                                                            calculated
                                                                        </span>
                                                                        {measure.numerator !==
                                                                            null &&
                                                                            measure.denominator !==
                                                                                null && (
                                                                                <span>
                                                                                    <span className="sr-only">
                                                                                        measure
                                                                                        id
                                                                                        {ReactHtmlParser(
                                                                                            measure.code
                                                                                        )}{" "}
                                                                                        percentage
                                                                                    </span>
                                                                                    {isNaN(
                                                                                        percentage
                                                                                    ) ||
                                                                                    percentage >
                                                                                        100 ||
                                                                                    percentage <
                                                                                        0
                                                                                        ? ""
                                                                                        : `${percentage.toFixed(
                                                                                              2
                                                                                          )}%`}
                                                                                </span>
                                                                            )}
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            );
                        })}
                    </div>
                </div>
                <div className="metric-table">
                    {props.data?.metricsData?.map((metrics: any) => {
                        return (
                            <table
                                key={metrics[0]?.code}
                                aria-label={metrics[0]?.groupName}
                                className={`aui-responsive-status-table ${
                                    props.isMeasureTableLoading
                                        ? CONSTANTS.TABLE_SPINNER
                                        : ""
                                }`}
                            >
                                <caption className="metric-subheading-tr">
                                    <div className="metric-subheading">
                                        {metrics[0]?.groupName}
                                    </div>
                                </caption>
                                <thead>
                                    <tr>
                                        <th scope="col" className="text-center">
                                            <div className="aui-th">
                                                Metrics ID
                                            </div>
                                        </th>
                                        <th scope="col">
                                            <div className="aui-th">
                                                Metrics Name
                                            </div>
                                        </th>
                                        <th scope="col">
                                            <div className="aui-th">
                                                Description
                                            </div>
                                        </th>
                                        <th scope="col" className="text-center">
                                            <div className="aui-th">Value</div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {metrics.map((metric: any) => {
                                        return !metric.children ? (
                                            <tr
                                                key={metric.code}
                                                className="aui-table-status-normal"
                                            >
                                                <td data-title="Metrics ID">
                                                    <div className="aui-td">
                                                        {ReactHtmlParser(
                                                            metric.code
                                                        )}
                                                    </div>
                                                </td>
                                                <td data-title="Metrics Name">
                                                    <div className="aui-td">
                                                        {ReactHtmlParser(
                                                            metric.name
                                                        )}
                                                    </div>
                                                </td>
                                                <td data-title="Description">
                                                    <div className="aui-td">
                                                        {ReactHtmlParser(
                                                            metric.description
                                                        )}
                                                    </div>
                                                </td>
                                                <td data-title="Value">
                                                    <div className="aui-td">
                                                        <div
                                                            className="form-field m-0 d-flex align-items-center justify-content-lg-center measure-input w-100"
                                                            id={`metric-${metric.propertyId}`}
                                                        >
                                                            {metric.metricHelptext && (
                                                                <>
                                                                    <button
                                                                        type="button"
                                                                        className="aha-icon-help measure-help btn btn-text"
                                                                        data-toggle="popover"
                                                                        {...(!isSafariBrowser()
                                                                            ? {
                                                                                  "data-trigger":
                                                                                      "focus",
                                                                              }
                                                                            : {})}
                                                                        data-html="true"
                                                                        data-container={`#metric-${metric.propertyId}`}
                                                                        data-content={
                                                                            metric.metricHelptext
                                                                        }
                                                                    >
                                                                        <div className="sr-only">
                                                                            {" "}
                                                                            metric
                                                                            id
                                                                            {ReactHtmlParser(
                                                                                metric.code
                                                                            )}{" "}
                                                                            metric
                                                                            help
                                                                            text
                                                                        </div>
                                                                    </button>
                                                                </>
                                                            )}
                                                            {props.readOnly ? (
                                                                <span>
                                                                    {metric.metricValue
                                                                        ? typeof metric.metricValue ===
                                                                              "string" &&
                                                                          metric.metricValue.replace(
                                                                              /^0+/,
                                                                              ""
                                                                          ) ===
                                                                              ""
                                                                            ? parseInt(
                                                                                  metric.metricValue
                                                                              )
                                                                            : typeof metric.metricValue ===
                                                                              "string"
                                                                            ? metric.metricValue.replace(
                                                                                  /^0+/,
                                                                                  ""
                                                                              )
                                                                            : metric.metricValue
                                                                        : metric.metricValue ===
                                                                          0
                                                                        ? 0
                                                                        : ""}
                                                                    {metric.valueType ==
                                                                        CONSTANTS.PERCENTAGE &&
                                                                        metric.metricValue &&
                                                                        "%"}
                                                                </span>
                                                            ) : (
                                                                <div>
                                                                    <input
                                                                        aria-label={`Metric ID ${metric.code}`}
                                                                        type="number"
                                                                        className="form-control"
                                                                        name={
                                                                            metric.code
                                                                        }
                                                                        min="0"
                                                                        step=".000000000000001"
                                                                        max={
                                                                            metric?.valueType ==
                                                                            CONSTANTS.PERCENTAGE
                                                                                ? "100"
                                                                                : "9999"
                                                                        }
                                                                        defaultValue={
                                                                            metric.metricValue
                                                                        }
                                                                        onChange={
                                                                            props.handleMetricChange
                                                                        }
                                                                    />
                                                                    {metric.valueType ==
                                                                        CONSTANTS.PERCENTAGE && (
                                                                        <span className="metric-form-field-per">
                                                                            %
                                                                        </span>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ) : (
                                            <tr
                                                key={metric.code}
                                                className="submetric-outer aui-table-status-normal"
                                            >
                                                <td data-title="Metrics ID">
                                                    <div className="aui-td">
                                                        {ReactHtmlParser(
                                                            metric.code
                                                        )}
                                                    </div>
                                                </td>
                                                <td
                                                    className="metric-subtable"
                                                    colSpan={2}
                                                >
                                                    <table
                                                        key={metric.code}
                                                        className="metric-inner-table aui-responsive-table"
                                                        aria-label={`Sub metric for ${getCode(
                                                            metric.code
                                                        )}`}
                                                    >
                                                        <thead aria-hidden="true">
                                                            <tr>
                                                                <th />
                                                                <th />
                                                                <th />
                                                                <th />
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td
                                                                    className="metric-sub-col1"
                                                                    colSpan={2}
                                                                    data-title="Metrics Name"
                                                                >
                                                                    <div className="aui-td">
                                                                        <span className="sr-only">
                                                                            Metrics
                                                                            Name
                                                                        </span>
                                                                        {ReactHtmlParser(
                                                                            metric.name
                                                                        )}
                                                                    </div>
                                                                </td>
                                                                <td
                                                                    className="metric-sub-col2"
                                                                    colSpan={2}
                                                                    data-title="Description"
                                                                >
                                                                    <div className="aui-td">
                                                                        <span className="sr-only">
                                                                            Metrics
                                                                            Description
                                                                        </span>
                                                                        {ReactHtmlParser(
                                                                            metric.description
                                                                        )}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            {metric.children.map(
                                                                (
                                                                    metricChildren: any
                                                                ) => {
                                                                    return (
                                                                        <tr
                                                                            key={
                                                                                metricChildren.code
                                                                            }
                                                                            className="submetric-tr"
                                                                        >
                                                                            <td
                                                                                className="metric-sub-no"
                                                                                data-title="Submetric ID"
                                                                            >
                                                                                <div className="aui-td">
                                                                                    <span className="sr-only">
                                                                                        Sub
                                                                                        Metric
                                                                                        I
                                                                                        D,
                                                                                    </span>
                                                                                    {ReactHtmlParser(
                                                                                        metricChildren.code
                                                                                    )}
                                                                                </div>
                                                                            </td>
                                                                            <td
                                                                                colSpan={
                                                                                    2
                                                                                }
                                                                                className="metric-sub-desc"
                                                                                data-title="Submetric Name"
                                                                            >
                                                                                <div className="aui-td">
                                                                                    <span className="sr-only">
                                                                                        Sub
                                                                                        Metric
                                                                                        Name
                                                                                    </span>
                                                                                    {ReactHtmlParser(
                                                                                        metricChildren.name
                                                                                    )}
                                                                                </div>
                                                                            </td>
                                                                            <td
                                                                                className="metric-sub-input"
                                                                                data-title="Submetric Percentage"
                                                                            >
                                                                                <div className="aui-td align-items-center">
                                                                                    <div
                                                                                        className={`form-field m-0 d-flex align-items-center justify-content-lg-center w-100 ${
                                                                                            props.readOnly
                                                                                                ? "readonly-metric"
                                                                                                : ""
                                                                                        }`}
                                                                                        id={`metric-${metricChildren.propertyId}`}
                                                                                    >
                                                                                        {metricChildren.metricHelptext && (
                                                                                            <>
                                                                                                <button
                                                                                                    type="button"
                                                                                                    className="aha-icon-help subMeasure-help btn btn-text"
                                                                                                    data-toggle="popover"
                                                                                                    {...(!isSafariBrowser()
                                                                                                        ? {
                                                                                                              "data-trigger":
                                                                                                                  "focus ",
                                                                                                          }
                                                                                                        : {})}
                                                                                                    data-html="true"
                                                                                                    data-container={`#metric-${metricChildren.propertyId}`}
                                                                                                    data-content={
                                                                                                        metricChildren.metricHelptext
                                                                                                    }
                                                                                                >
                                                                                                    <div className="sr-only">
                                                                                                        {" "}
                                                                                                        metric
                                                                                                        id
                                                                                                        {ReactHtmlParser(
                                                                                                            metricChildren.code
                                                                                                        )}{" "}
                                                                                                        metric
                                                                                                        help
                                                                                                        text
                                                                                                    </div>
                                                                                                </button>
                                                                                            </>
                                                                                        )}
                                                                                        {props.readOnly ? (
                                                                                            <span>
                                                                                                <span className="sr-only">
                                                                                                    Percentage
                                                                                                    for
                                                                                                    Submetric{" "}
                                                                                                    {ReactHtmlParser(
                                                                                                        metricChildren.code
                                                                                                    )}
                                                                                                </span>
                                                                                                {metricChildren.metricValue ||
                                                                                                metricChildren.metricValue ==
                                                                                                    0
                                                                                                    ? `${
                                                                                                          typeof metricChildren.metricValue ===
                                                                                                              "string" &&
                                                                                                          metricChildren.metricValue.replace(
                                                                                                              /^0+/,
                                                                                                              ""
                                                                                                          ) ===
                                                                                                              ""
                                                                                                              ? parseInt(
                                                                                                                    metricChildren.metricValue
                                                                                                                )
                                                                                                              : typeof metricChildren.metricValue ===
                                                                                                                "string"
                                                                                                              ? metricChildren.metricValue.replace(
                                                                                                                    /^0+/,
                                                                                                                    ""
                                                                                                                )
                                                                                                              : metricChildren.metricValue
                                                                                                      }%`
                                                                                                    : ""}
                                                                                            </span>
                                                                                        ) : (
                                                                                            <div>
                                                                                                <input
                                                                                                    aria-label={`Sub metric ID ${metricChildren.code} `}
                                                                                                    type="number"
                                                                                                    className="form-control"
                                                                                                    name={
                                                                                                        metricChildren.code
                                                                                                    }
                                                                                                    min="0"
                                                                                                    step=".000000000000001"
                                                                                                    max={
                                                                                                        metricChildren?.valueType ==
                                                                                                        CONSTANTS.PERCENTAGE
                                                                                                            ? "100"
                                                                                                            : "9999"
                                                                                                    }
                                                                                                    defaultValue={
                                                                                                        metricChildren.metricValue
                                                                                                    }
                                                                                                    onChange={
                                                                                                        props.handleMetricChange
                                                                                                    }
                                                                                                />
                                                                                                {metricChildren.valueType ==
                                                                                                    CONSTANTS.PERCENTAGE && (
                                                                                                    <span className="form-field-per">
                                                                                                        %
                                                                                                    </span>
                                                                                                )}
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                }
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </td>
                                                <td
                                                    aria-hidden="true"
                                                    className="submetric-outer-tdlast"
                                                >
                                                    <div className="aui-td" />
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        );
                    })}
                </div>
                {!props.readOnly && props.submit && (
                    <div className="d-md-flex flex-row-reverse pb-4 mb-1 measure-table-btn">
                        <button
                            disabled={props.disabled || props.isSaveInProgress}
                            type="submit"
                            aria-label="Submit Measure data"
                            className={`btn btn-round btn-primary ${
                                props.isSaveInProgress
                                    ? CONSTANTS.BUTTON_SPINNER
                                    : ""
                            }`}
                        >
                            Submit
                        </button>
                    </div>
                )}
            </form>
        </MeasureDataTable>
    );
};
export default MeasureMetricTable;
