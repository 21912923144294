import React, { useEffect, useRef, useState } from "react";
import { TabWrapper } from "./styled";

const Tab = (props: any) => {
    const [selectedTab, setSelectedTab] = useState<any>(
        props?.tabTitle[0]?.id || null
    );
    const tabsRef = useRef<any>([]);
    const [focusedTabIndex, setFocusedTabIndex] = useState<number>(0);
    useEffect(() => {
        if (props.tabTitle.length > 0 && selectedTab === null) {
            setSelectedTab(props.tabTitle[0].id);
        }
    }, [props.tabTitle, selectedTab]);

    useEffect(() => {
        const focusedElement = document.getElementById(
            `tab-${focusedTabIndex}`
        );
        if (focusedElement) {
            focusedElement.focus();
        }
    }, [focusedTabIndex]);
    useEffect(() => {
        tabsRef.current[selectedTabIndex]?.focus();
    }, [selectedTab]);

    const selectedTabIndex = props?.tabTitle.findIndex(
        (tab: any) => tab.id === selectedTab
    );

    const handleTabClick = (tabId: any, index: any) => {
        setSelectedTab(tabId);
        setFocusedTabIndex(index);
        props.handleSelectedTab(tabId);
    };
    useEffect(() => {
        tabsRef.current[focusedTabIndex]?.focus();
    }, [focusedTabIndex]);

    const handleKeyDown = (event: any, index: any) => {
        switch (event.key) {
            case "ArrowRight":
                event.preventDefault();
                setFocusedTabIndex((index + 1) % props.tabTitle.length);
                break;
            case "ArrowLeft":
                event.preventDefault();
                setFocusedTabIndex(
                    (index - 1 + props.tabTitle.length) % props.tabTitle.length
                );
                break;
            case "Home":
                event.preventDefault();
                setFocusedTabIndex(0);
                break;
            case "End":
                event.preventDefault();
                setFocusedTabIndex(props.tabTitle.length - 1);
                break;
            default:
                break;
        }
    };

    return (
        <TabWrapper className="tab-bordered">
            <div className="tab-bordered">
                <ul className="nav nav-tabs" role="tablist">
                    {props?.tabTitle.map((tab: any, index: any) => (
                        <li
                            key={tab.id}
                            className="nav-item"
                            role="presentation"
                        >
                            <button
                                aria-live="polite"
                                className={`nav-link ${
                                    selectedTab === tab.id ? "active" : ""
                                }`}
                                id={`tab-${index}`}
                                data-toggle="tab"
                                type="button"
                                role="tab"
                                aria-controls={tab.id}
                                aria-selected={selectedTab === tab.id}
                                onClick={() => handleTabClick(tab.id, index)}
                                onKeyDown={(e) => handleKeyDown(e, index)}
                                tabIndex={focusedTabIndex === index ? 0 : -1}
                            >
                                {tab.title}
                            </button>
                        </li>
                    ))}
                </ul>
                <div className="tab-content">
                    {props?.tabTitle.map((tab: any) => (
                        <div
                            key={tab.id}
                            className={`tab-pane fade ${
                                selectedTab === tab.id ? "show active" : ""
                            }`}
                            id={tab.id}
                            role="tabpanel"
                            aria-labelledby={`${tab.id}-tab`}
                        >
                            {tab.content}
                        </div>
                    ))}
                </div>
            </div>
        </TabWrapper>
    );
};

export default Tab;
