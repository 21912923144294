import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import "../../styles/styles.scss";
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import { activateOrDeactivateRegistry } from "api/registryAPI";
import { showToast } from "components/Toast/toast.slice";
import { useDispatch } from "react-redux";
import FocusTrap from "focus-trap-react";
import { showModal, wordSplit } from "common/utils";

interface Props {
    registry: any;
    setEditMode: any;
    profileMode: any;
    registryMangangementTab?: any;
    registryMangangementPage?: any;
}

export const ViewRegistry = ({
    registry,
    setEditMode,
    profileMode,
    registryMangangementTab,
    registryMangangementPage,
}: Props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { registryId }: any = useParams();
    const [registeredEmailId, setregisteredEmailId] = useState<string>("");
    const [registryActive, setRegistryActive] = useState<boolean>(
        !!registry?.isActivated
    );
    const [modifiedRegisteredMailId, setModifiedRegisteredMailId] =
        useState<boolean>(false);
    const [activateInd, setActivateInd] = useState<boolean>(false);
    const [deactivateActivateInd, setDeactivateActivateInd] =
        useState<boolean>(false);
    let closeRegistryActivateModalElement: any = null;
    let closeRegistryDeactivateModalElement: any = null;

    const handleRegistryActivateSubmit = (event: any) => {
        event.preventDefault();
        dispatch(setPageLoadingStatus({ isPageLoading: true }));
        activateOrDeactivateRegistry({
            registryId,
            registeredEmailId:
                !registeredEmailId && !modifiedRegisteredMailId
                    ? registry.primaryContactEmailAddress
                    : registeredEmailId,
            activationStatus: "ACTIVE",
        })
            .then((data: any) => {
                dispatch(setPageLoadingStatus({ isPageLoading: false }));
                if (data.success == true) {
                    registry.registeredEmailId = registeredEmailId;
                    dispatch(
                        showToast({
                            visibility: true,
                            title: "Registry Activated:",
                            message: `Registry successfully activated.`,
                            type: "success",
                        })
                    );

                    closeRegistryActivateModalElement.click();
                    setRegistryActive(true);
                    history.push("/registry/registry-management");
                }
            })
            .catch(() => {
                dispatch(setPageLoadingStatus({ isPageLoading: false }));
            });
    };
    const deactivateRegistry = () => {
        dispatch(setPageLoadingStatus({ isPageLoading: true }));
        activateOrDeactivateRegistry({
            registryId,
            activationStatus: "INACTIVE",
        })
            .then((data: any) => {
                closeRegistryDeactivateModalElement.click();
                dispatch(setPageLoadingStatus({ isPageLoading: false }));
                if (data.success == true) {
                    setRegistryActive(false);
                    dispatch(
                        showToast({
                            visibility: true,
                            title: "Registry Deactivated:",
                            message: `Registry has been deactivated.`,
                            type: "success",
                        })
                    );
                    history.push("/registry/registry-management");
                }
            })
            .catch(() => {
                closeRegistryDeactivateModalElement.click();
                dispatch(setPageLoadingStatus({ isPageLoading: false }));
            });
    };
    const handleRegisteredEmailIdChange = (event: any) => {
        setModifiedRegisteredMailId(true);
        setregisteredEmailId(event.target.value.trim());
    };
    const resetActivateRegistryPopupModal = () => {
        setActivateInd(true);
        showModal();
        setregisteredEmailId("");
        setModifiedRegisteredMailId(false);
    };
    const dismissModal = () => {
        setActivateInd(false);
        showModal();
    };
    const deactivateRegistryPopupModal = () => {
        setDeactivateActivateInd(true);
        showModal();
    };
    const deactivateRegistryDismissModal = () => {
        setDeactivateActivateInd(false);
        showModal();
    };

    return (
        <div>
            <div className="registry-mgnt-table">
                <table className="aui-responsive-table aui-table-cols">
                    <tbody>
                        <tr>
                            <th scope="row" className="aui-row-th">
                                Registry Code
                            </th>
                            <td className="aui-row-td">
                                <span className="abbr-code">
                                    {wordSplit(registry.registryCode)}
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row" className="aui-row-th">
                                Registry Name
                            </th>
                            <td className="aui-row-td" id="reg-name">
                                {registry.registryName}
                            </td>
                        </tr>
                        <tr>
                            <th scope="row" className="aui-row-th">
                                Street Address
                            </th>
                            <td className="aui-row-td">
                                {registry.registryStreetAddress || "N/A"}
                            </td>
                        </tr>
                        <tr>
                            <th scope="row" className="aui-row-th">
                                Location
                            </th>
                            <td className="aui-row-td">
                                {registry.countryName || "N/A"}
                            </td>
                        </tr>
                        <tr>
                            <th scope="row" className="aui-row-th">
                                Description
                            </th>
                            <td className="aui-row-td">
                                {registry.description || "N/A"}
                            </td>
                        </tr>
                        <tr>
                            <th scope="row" className="aui-row-th">
                                Website
                            </th>
                            <td className="aui-row-td">
                                {registry.website || "N/A"}
                            </td>
                        </tr>
                        <tr>
                            <th scope="row" className="aui-row-th">
                                Primary Contact Name
                            </th>
                            <td className="aui-row-td">
                                {registry.primaryContactName || "N/A"}
                            </td>
                        </tr>
                        <tr>
                            <th scope="row" className="aui-row-th">
                                Primary Contact Phone Number
                            </th>
                            <td className="aui-row-td">
                                {registry.primaryContactPhoneNumber || "N/A"}
                            </td>
                        </tr>
                        <tr>
                            <th scope="row" className="aui-row-th">
                                Primary Contact Email Address
                            </th>
                            <td className="aui-row-td">
                                {registry.primaryContactEmailAddress || "N/A"}
                            </td>
                        </tr>
                        {registryActive && (
                            <tr>
                                <th scope="row" className="aui-row-th">
                                    Registered Email Address
                                </th>
                                <td className="aui-row-td">
                                    {registry.registeredEmailId || "N/A"}
                                </td>
                            </tr>
                        )}
                        <tr>
                            <th scope="row" className="aui-row-th">
                                Organizations Authorizing Registry
                            </th>
                            <td className="aui-row-td">
                                {!registry.totalHospitalsRegistered
                                    ? 0
                                    : registry.totalHospitalsRegistered}
                            </td>
                        </tr>
                        <tr>
                            <th scope="row" className="aui-row-th">
                                Programs Associated with
                            </th>
                            <td className="aui-row-td">
                                <ul className="aui-tag-list">
                                    {registry.programs?.length > 0 &&
                                        registry.programs.map(
                                            (catgory: any, index: any) => (
                                                <li
                                                    key={index}
                                                    className="aui-tag-item"
                                                >
                                                    {catgory.categoryName}
                                                    <span className="sr-only">
                                                        &nbsp;,
                                                    </span>
                                                </li>
                                            )
                                        )}
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {profileMode !== true && (
                <div className="registry-mgnt-btns d-flex flex-column flex-sm-row">
                    <button
                        type="button"
                        className="btn btn-secondary btn-round"
                        onClick={(event: any) => {
                            event.preventDefault();
                            history.push("/registry/registry-management", {
                                t: registryMangangementTab,
                                p: registryMangangementPage,
                            });
                        }}
                        aria-label="Close registry"
                    >
                        Close
                    </button>
                    {registryActive === false && (
                        <button
                            className="btn btn-primary btn-round"
                            type="button"
                            onClick={resetActivateRegistryPopupModal}
                            aria-describedby="reg-name"
                        >
                            Activate
                        </button>
                    )}
                    {registryActive === true && (
                        <button
                            type="button"
                            className="btn btn-primary btn-round"
                            aria-describedby="reg-name"
                            onClick={deactivateRegistryPopupModal}
                        >
                            Deactivate
                        </button>
                    )}
                </div>
            )}
            {!registryActive
                ? activateInd && (
                      <>
                          <FocusTrap
                              focusTrapOptions={{
                                  escapeDeactivates: false,
                                  clickOutsideDeactivates: false,
                              }}
                          >
                              <div
                                  className="modal fade show aui-modal confirmEmail-modal"
                                  id="confirmEmailModal"
                                  tabIndex={-1}
                                  aria-labelledby="confirmEmailLabel"
                                  aria-modal="true"
                                  role="dialog"
                                  style={{
                                      display: activateInd ? "block" : "none",
                                  }}
                              >
                                  <div className="modal-dialog modal-dialog-centered modal-md">
                                      <div className="modal-content">
                                          <div className="modal-header mb-4">
                                              <h2
                                                  className="h4"
                                                  id="confirmEmailLabel"
                                              >
                                                  Confirm Email Address
                                              </h2>
                                              <button
                                                  type="button"
                                                  className="close"
                                                  data-dismiss="modal"
                                                  aria-label="Close Confirm email address modal"
                                                  onClick={dismissModal}
                                              >
                                                  <span
                                                      aria-hidden="true"
                                                      className="aha-icon-cross"
                                                  />
                                              </button>
                                          </div>
                                          <form
                                              className="reg-form"
                                              onSubmit={
                                                  handleRegistryActivateSubmit
                                              }
                                          >
                                              <div className="modal-body mb-0">
                                                  <div className="d-sm-flex justify-content-between">
                                                      <p className="confirmEmail-title">
                                                          Please enter the SSO
                                                          registered email
                                                          address
                                                      </p>
                                                      <div className="pb-3 font-14 mand-field">
                                                          <sup>*</sup>
                                                          mandatory fields
                                                      </div>
                                                  </div>
                                                  <div className="form-group row required mb-4">
                                                      <div className="col-md-5 col-lg-4 form-label">
                                                          <label
                                                              htmlFor="registeredEmailId"
                                                              className="label-form"
                                                          >
                                                              Registered Email
                                                              ID<sup>*</sup>
                                                          </label>
                                                      </div>
                                                      <div className="col-md-7 col-lg-8">
                                                          <input
                                                              type="email"
                                                              className="form-control"
                                                              id="registeredEmailId"
                                                              required
                                                              pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$"
                                                              value={
                                                                  !registeredEmailId &&
                                                                  !modifiedRegisteredMailId
                                                                      ? registry.primaryContactEmailAddress ||
                                                                        ""
                                                                      : registeredEmailId
                                                              }
                                                              onChange={
                                                                  handleRegisteredEmailIdChange
                                                              }
                                                          />
                                                          <span
                                                              className="sr-only"
                                                              id="registeredEmailIdHelpText"
                                                          >
                                                              Only valid email
                                                              address allowed
                                                          </span>
                                                      </div>
                                                  </div>
                                                  <div className="d-flex flex-sm-row flex-column w-100 justify-content-end align-items-center confirmEmail-btns">
                                                      <button
                                                          type="button"
                                                          className="btn btn-secondary btn-round"
                                                          data-dismiss="modal"
                                                          ref={(input) => {
                                                              closeRegistryActivateModalElement =
                                                                  input;
                                                          }}
                                                          onClick={dismissModal}
                                                      >
                                                          Cancel
                                                      </button>
                                                      <button
                                                          type="submit"
                                                          className="btn btn-primary btn-round ml-sm-3 mt-3 mt-sm-0"
                                                          aria-label="Confirm Email address"
                                                      >
                                                          Confirm
                                                      </button>
                                                  </div>
                                              </div>
                                          </form>
                                      </div>
                                  </div>
                              </div>
                          </FocusTrap>
                      </>
                  )
                : deactivateActivateInd && (
                      <FocusTrap
                          focusTrapOptions={{
                              escapeDeactivates: false,
                              clickOutsideDeactivates: false,
                          }}
                      >
                          <div
                              className="modal fade show aui-modal"
                              id="confirmDeactivateModal"
                              tabIndex={-1}
                              aria-labelledby="deactivateModalLabel"
                              aria-modal="true"
                              role="dialog"
                              style={{
                                  display: deactivateActivateInd
                                      ? "block"
                                      : "none",
                              }}
                          >
                              <div className="modal-dialog modal-dialog-centered modal-sm">
                                  <div className="modal-content">
                                      <div className="modal-body p-0">
                                          <div className="text-center">
                                              <p id="deactivateModalLabel">
                                                  Are you sure you want to
                                                  deactivate the registry?
                                              </p>
                                              <div className="mt-4 d-block justify-content-center">
                                                  <button
                                                      type="button"
                                                      className="btn btn-secondary btn-round btn-sm mx-2"
                                                      onClick={
                                                          deactivateRegistryDismissModal
                                                      }
                                                      ref={(input) => {
                                                          closeRegistryDeactivateModalElement =
                                                              input;
                                                      }}
                                                  >
                                                      Cancel
                                                  </button>
                                                  <button
                                                      onClick={
                                                          deactivateRegistry
                                                      }
                                                      type="button"
                                                      className="btn btn-primary btn-round btn-sm mx-2"
                                                  >
                                                      Confirm
                                                  </button>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </FocusTrap>
                  )}
        </div>
    );
};

export default ViewRegistry;
