import styled from "styled-components";

export const FacilitiesListingWrapper = styled.div`
  .registry-facilitieslist-table {
    thead {
        @media only screen and (min-width: 992px) {
          th:nth-child(1) {
            width: 15%;
          }
          th:nth-child(2) {
            width: 25%;
          }
          th:nth-child(3) {
            width: 25%;
          }
          th:nth-child(4) {
            width: 15%;
          }
          th:nth-child(5) {
            width: 20%;
          }
        }
      }
    }
    thead,
    tbody {
      th:nth-child(7),
      td:nth-child(7) {
        @media only screen and (min-width: 992px) {
          text-align: center;
        }
      }
    }
    a {
      color: #c10e21;
    }
  }
`;
