import React, { useEffect, useState } from "react";
import CONSTANTS from "common/constants";
import store from "app/store";
import Dropdown from "components/Dropdown";
import "styles/popover.scss";
import SearchDropdown from "components/SearchDropdown";
import DynamicForm from "components/DynamicForm";
import { getStates } from "api/countriesAPI";
import {
    updateHospitalProgramDetails,
    getProgramCategories,
    getProgramResgistries,
    saveProgramRegistry,
    deleteOrGetProgramRegistry,
} from "api/hospitalProgramsAPI";
import { useHistory, useParams } from "react-router-dom";
import { logger } from "utils/logger.utils";
import {
    localDateToYYYYMMDDFormatString,
    redirectionForErrorCode,
    parseJwt,
    wordChange,
    showModal,
    validateSpecialCharGreaterLessThan,
} from "common/utils";
import { useDispatch } from "react-redux";
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import { showToast } from "components/Toast/toast.slice";
import { createAgencyEmsId, getAllAhaEmsIds } from "api/emsAPI";
import FocusTrap from "focus-trap-react";
import { FacilityDetailsWrapper } from "./styled";
import "styles/toggle-switch.scss";

interface Props {
    setActiveTab?: any;
    activeTab?: any;
    close?: any;
    programData: any;
    setRegistryInfo?: any;
    setRegistryQuestionData?: any;
    setProgramData: any;
    hospital: any;
    isCorporation?: any;
    orderId?: any;
    isEmsAdmin?: any;
    isEMSEUP?: any;
}

export const ProgramsDetails = (props: Props) => {
    const [editMode, setEditMode] = useState<boolean>(false);
    const [states, setStates] = useState<[]>([]);
    const [registryData, setRegistryData] = useState<any>(null);
    const [modifiedRegistryData, setModifiedRegistryData] = useState<any>("");
    const [savedProgramDetails, setSavedProgramDetails] = useState<any>([]);
    const [categories, setCategories] = useState<any>();
    const [registryQuestion, setRegistryQuestion] = useState<any>("");
    const [categoryName, setCategoryName] = useState<any>("");
    const [emsId, setEmsId] = useState<any>("");
    const [agencyStateNumber, setAgencyStateNumber] = useState<any>("");
    const [stateID, setStateID] = useState<any>("");
    const [programInfo, setProgramInfo] = useState<any>({});
    const [modifiedProgramDetails, setModifiedProgramDetails] = useState<any>(
        []
    );
    const [ahaEMSIdLoadingInd, setAhaEMSIdLoadingInd] =
        useState<boolean>(false);
    const [allEmsIds, setAllEmsIds] = useState<any>([]);
    const [disabled, setDisabled] = useState<any>(false);
    const [registryId, setRegistryId] = useState<any>("");
    const [buttonState, setButtonState] = useState<boolean>(true);
    const [priorYearAward, setPriorYearAward] = useState<any>("");
    const [allEmsData, setAllEmsData] = useState<any>([]);
    const params: any = useParams();
    const dispatch = useDispatch();
    const userAccessToken = window.localStorage.getItem("userAccessToken");
    const isCorporation =
        userAccessToken &&
        parseJwt(userAccessToken).role_code ===
            CONSTANTS.USER_ROLES.CORPORATION_ADMIN;
    const history = useHistory();
    const [emsIdActivateInd, setEmsIdActivateInd] = useState<boolean>(false);
    const programId: any = props.isEMSEUP
        ? localStorage.getItem("selectedProgramId")
        : params.programId;

    const [ahaEmsId, setAhaEmsId] = useState<any>("");
    const [organizationName, setOrganizationName] = useState<any>("");
    const [city, setCity] = useState<any>("");
    const [emsIdState, setEmsIdState] = useState<any>("");

    const hospitalId = props.isEMSEUP
        ? localStorage.getItem("selectedHospitalId")
        : params.hospitalId;

    const categoryId = props.isEMSEUP
        ? CONSTANTS.EMS_PROGRAM_CATEGORY_ID
        : params.categoryId;

    const getEMSIdInDropdown = () => {
        setAhaEMSIdLoadingInd(true);
        const emsIds: any = [];
        getAllAhaEmsIds(dispatch, setPageLoadingStatus)
            .then((emsResponse: any) => {
                if (emsResponse?.data) {
                    setAllEmsData(emsResponse.data);
                    for (let i = 0; i < emsResponse.data.length; i++) {
                        emsIds.push({
                            label: `${emsResponse.data[i]["AHA EMS ID"]}_${emsResponse.data[i]["Agency Name"]}_${emsResponse.data[i].City}_${emsResponse.data[i].State}`,
                            value: `${emsResponse.data[i]["AHA EMS ID"]}_${emsResponse.data[i]["Agency Name"]}_${emsResponse.data[i].City}_${emsResponse.data[i].State}`,
                            id: `${emsResponse.data[i]["AHA EMS ID"]}`,
                        });
                    }
                    setAllEmsIds([
                        ...emsIds,
                        {
                            label: "Not listed",
                            value: "Not listed",
                            id: "",
                        },
                    ]);
                    setAhaEMSIdLoadingInd(false);
                }
            })
            .catch(() => {
                setAhaEMSIdLoadingInd(false);
            });
    };
    useEffect(() => {
        if (savedProgramDetails) {
            setProgramInfo(props.programData);
            setStateID(props.programData?.emsAgencyStateId);
            setAgencyStateNumber(props.programData?.emsAgencyStateIdNumber);
            setEmsId(props.programData?.emsId);
            setPriorYearAward(props.programData?.priorYearAward);
            if (props.programData?.emsId) {
                getEMSIdInDropdown();
            }
            let jsonInput: any = [];
            if (props.programData && props.programData.programDetails) {
                setCategoryName(props.programData.programCategoryName);
                const parsedJsonFormData = JSON.parse(
                    props.programData.programDetails
                );

                jsonInput = parsedJsonFormData.jsonForm;
                if (programId !== CONSTANTS.EMS_PROGRAM_ID) {
                    getProgramResgistries(
                        Number(props.programData.programCategoryId)
                    )
                        .then((registryRes: any) => {
                            deleteOrGetProgramRegistry(
                                Number(params.hospitalId),
                                Number(params.programId),
                                Number(params.categoryId),
                                "GET"
                            )
                                .then((getProgramRegistryResponse: any) => {
                                    let registryInfo: any;
                                    const registryArr: any = [
                                        {
                                            label: "Select",
                                            value: "",
                                        },
                                    ];

                                    registryRes.data.registries.forEach(
                                        (item: any) => {
                                            registryArr.push({
                                                label: item.registryName,
                                                value: item.registryName,
                                                id: item.registryId,
                                            });
                                        }
                                    );

                                    if (
                                        getProgramRegistryResponse.success ==
                                            true ||
                                        (jsonInput &&
                                            jsonInput.length > 0 &&
                                            jsonInput[jsonInput.length - 1]
                                                .customData == true)
                                    ) {
                                        registryInfo =
                                            jsonInput[jsonInput.length - 1];
                                    }
                                    setRegistryId(
                                        getProgramRegistryResponse.data
                                            .registryId
                                    );

                                    registryArr.push({
                                        label: CONSTANTS.REGISTRY_OTHER,
                                        value: CONSTANTS.REGISTRY_OTHER,
                                        id: CONSTANTS.REGISTRY_OTHER,
                                    });
                                    const regData = {
                                        isRegistryUsing:
                                            registryInfo &&
                                            registryInfo.isRegistryUsing
                                                ? registryInfo.isRegistryUsing
                                                : "",
                                        registries: registryArr,
                                        registryName:
                                            registryInfo &&
                                            registryInfo.registryName
                                                ? registryInfo.registryName
                                                : "",
                                        customRegistryName:
                                            registryInfo &&
                                            registryInfo.customRegistryName
                                                ? registryInfo.customRegistryName
                                                : "",
                                    };

                                    setRegistryData(regData);
                                    props.setRegistryInfo(regData);
                                    setModifiedRegistryData(
                                        JSON.parse(JSON.stringify(regData))
                                    );
                                })
                                .catch((error) => {
                                    logger("error is :: ", error);
                                });
                        })
                        .catch((error: any) => {
                            logger("error is :: ", error);
                        });
                }
                setSavedProgramDetails(jsonInput);
                setModifiedProgramDetails(
                    JSON.parse(JSON.stringify(jsonInput))
                );
                if (!props.isEMSEUP) {
                    getProgramCategories(params.programId)
                        .then((categoryIds) => {
                            dispatch(
                                setPageLoadingStatus({ isPageLoading: false })
                            );
                            if (
                                categoryIds.data &&
                                categoryIds.data.length >= 1
                            ) {
                                const selectedCategory =
                                    categoryIds.data.filter((category: any) => {
                                        return (
                                            category.categoryId ==
                                            props.programData.programCategoryId
                                        );
                                    });
                                selectedCategory[0] &&
                                    selectedCategory[0].programDetails &&
                                    selectedCategory[0].programDetails
                                        .registry &&
                                    setRegistryQuestion(
                                        selectedCategory[0].programDetails
                                            .registry.registryQuestion
                                    );
                                props.setRegistryQuestionData(
                                    selectedCategory[0].programDetails.registry
                                        .registryQuestion
                                );

                                setCategories(categoryIds.data);
                            }
                        })
                        .catch((error) => {
                            dispatch(
                                setPageLoadingStatus({ isPageLoading: false })
                            );
                        });
                }
            }
        }
    }, [props.programData]);

    useEffect(() => {
        getStatesList();
    }, []);

    useEffect(() => {
        if (CONSTANTS.WINDOW.AUI.Tooltip) {
            CONSTANTS.WINDOW.AUI.Tooltip.init();
        }
    });

    const getStatesList = () => {
        getStates(CONSTANTS.DOMESTIC_COUNTRY_CODE).then((response) => {
            if (response.statusCode === 200) {
                const stateList = response.data;

                if (stateList.length) {
                    const stateMap: any = [];
                    stateList.map((country: any) => {
                        stateMap.push({
                            label: country.name,
                            value: country.name,
                        });
                    });
                    setStates(stateMap);
                }
            }
        });
    };
    const onSubmit = (event: any) => {
        event.preventDefault();
        if (event.target.id !== "ahaemsIdForm") {
            let formError: boolean = false;
            const errArr: any = [];
            setDisabled(true);
            let isRegistryData: boolean = false;
            const registryInfo: any = {
                customData: true,
                isRegistryUsing: modifiedRegistryData?.isRegistryUsing,
                registryName: modifiedRegistryData?.registryName,
                customRegistryName: modifiedRegistryData?.customRegistryName,
            };
            const selectedRegistryObj = modifiedRegistryData?.registries.filter(
                (registry: any) => {
                    return registry.value == modifiedRegistryData?.registryName;
                }
            );
            if (
                (modifiedRegistryData?.isRegistryUsing === "YES" &&
                    selectedRegistryObj.length == 0) ||
                (modifiedRegistryData?.isRegistryUsing === "YES" &&
                    selectedRegistryObj.length > 0 &&
                    selectedRegistryObj[0].value == "") ||
                (modifiedRegistryData?.registryName ===
                    CONSTANTS.REGISTRY_OTHER &&
                    !modifiedRegistryData?.customRegistryName)
            ) {
                const toast = {
                    message: "Please select the Registry.",
                    code: "Error:",
                };
                setDisabled(false);
                setButtonState(false);
                store.dispatch(showToast(toast));
                return false;
            }
            const model = modifiedProgramDetails;
            model.map((item: any) => {
                if (item.type == "select") {
                    item.type = "dropdown";
                }
                if (
                    item.type == "dropdown" &&
                    !item.value &&
                    item.props.required &&
                    !item.hideElement
                ) {
                    errArr.push(item.label);
                    formError = true;
                }
                if (item.customData == true) {
                    isRegistryData = true;
                }

                if (
                    item.type == "multiSelect" &&
                    item.props.required &&
                    (!item.value || item.value.length == 0)
                ) {
                    errArr.push(item.label);
                    formError = true;
                }
            });

            if (formError) {
                setDisabled(false);
                const toast = {
                    message: `Please select ${errArr.join(",")}`,
                    code: "Error:",
                };
                store.dispatch(showToast(toast));
                return false;
            }

            if (!props.isCorporation) {
                if (isRegistryData) {
                    model[model.length - 1] = registryInfo;
                } else {
                    model.push(registryInfo);
                }
            }

            // PATCH CALL
            const innerData = { jsonForm: model };
            const updatedFormData = {
                programDetails: JSON.stringify(innerData),
                programCategoryId: categoryId,
                emsId,
                emsAgencyStateIdNumber: agencyStateNumber,
                emsAgencyStateId: stateID,
                priorYearAward: priorYearAward || undefined,
            };
            if (hospitalId) {
                updateHospitalProgramDetails(
                    updatedFormData,
                    programId,
                    hospitalId
                )
                    .then((data) => {
                        if (data.success == true) {
                            const modifiedData = programInfo;
                            modifiedData.programDetails = JSON.stringify({
                                jsonForm: modifiedProgramDetails,
                            });
                            modifiedData.updatedAt = null;
                            modifiedData.updatedBy = null;

                            if (
                                data &&
                                data.data &&
                                data.data.updatedResult &&
                                data.data.updatedResult.updatedAt
                            ) {
                                modifiedData.updatedAt =
                                    data.data.updatedResult.updatedAt;
                            }

                            if (
                                data &&
                                data.data &&
                                data.data.updatedResult &&
                                data.data.updatedResult.updatedBy
                            ) {
                                modifiedData.updatedBy =
                                    data.data.updatedResult.updatedBy;
                            }
                            props.setProgramData(modifiedData);
                            if (programId !== CONSTANTS.EMS_PROGRAM_ID) {
                                if (
                                    modifiedRegistryData?.isRegistryUsing ==
                                        "YES" &&
                                    modifiedRegistryData?.registryName !=
                                        CONSTANTS.REGISTRY_OTHER
                                ) {
                                    const selectedCategoryObj =
                                        categories.filter((category: any) => {
                                            return (
                                                category.categoryId ==
                                                params.categoryId
                                            );
                                        });

                                    if (
                                        registryData.isRegistryUsing ===
                                            "YES" &&
                                        !registryData?.registryName
                                    ) {
                                        const toast = {
                                            message:
                                                "Please select the Registry.",
                                            code: "Error:",
                                        };
                                        store.dispatch(showToast(toast));
                                        setDisabled(false);
                                        return false;
                                    }

                                    const registryObj = {
                                        programName:
                                            categories[0].program.programName,
                                        categoryName:
                                            selectedCategoryObj[0].categoryName,
                                        registryId: selectedRegistryObj[0]
                                            ? selectedRegistryObj[0].id
                                            : "",
                                        programId: Number(params.programId),
                                        categoryId: params.categoryId,
                                        hospitalId: params.hospitalId,
                                    };
                                    if (isCorporation == true) {
                                        registryObj.registryId = registryId;
                                    }

                                    saveProgramRegistry(registryObj)
                                        .then((response) => {
                                            setDisabled(false);
                                            if (response.success) {
                                                setRegistryData(
                                                    JSON.parse(
                                                        JSON.stringify(
                                                            modifiedRegistryData
                                                        )
                                                    )
                                                );
                                                props.setRegistryInfo(
                                                    modifiedRegistryData
                                                );
                                                setSavedProgramDetails(
                                                    JSON.parse(
                                                        JSON.stringify(
                                                            modifiedProgramDetails
                                                        )
                                                    )
                                                );
                                                setEditMode(!editMode);
                                                const toast = {
                                                    message:
                                                        "Data successfully updated.",
                                                    code: "Success:",
                                                    type: "success",
                                                };
                                                dispatch(showToast(toast));
                                            } else {
                                                throw response.error;
                                            }
                                        })
                                        .catch((error: any) => {
                                            setDisabled(false);
                                            throw error;
                                        });
                                } else {
                                    deleteOrGetProgramRegistry(
                                        params.hospitalId,
                                        Number(params.programId),
                                        params.categoryId,
                                        "DELETE"
                                    )
                                        .then((deleteResponse: any) => {
                                            const registryDataToModifyParentData =
                                                {
                                                    ...modifiedRegistryData,
                                                };
                                            registryDataToModifyParentData.registryName =
                                                registryDataToModifyParentData.customRegistryName;
                                            if (
                                                params.categoryId !=
                                                CONSTANTS.EMS_PROGRAM_CATEGORY_ID
                                            ) {
                                                props.setRegistryInfo(
                                                    registryDataToModifyParentData
                                                );
                                            }
                                            setDisabled(false);
                                            setSavedProgramDetails(
                                                JSON.parse(
                                                    JSON.stringify(
                                                        modifiedProgramDetails
                                                    )
                                                )
                                            );
                                            setRegistryData(
                                                JSON.parse(
                                                    JSON.stringify(
                                                        modifiedRegistryData
                                                    )
                                                )
                                            );
                                            setEditMode(!editMode);
                                            const toast = {
                                                message:
                                                    "Data successfully updated.",
                                                code: "Success:",
                                                type: "success",
                                            };
                                            dispatch(showToast(toast));
                                        })
                                        .catch((error) => {
                                            setDisabled(false);
                                            throw error;
                                        });
                                }
                            } else {
                                setDisabled(false);
                                setSavedProgramDetails(
                                    JSON.parse(
                                        JSON.stringify(modifiedProgramDetails)
                                    )
                                );
                                setEditMode(!editMode);
                                const toast = {
                                    message: "Data successfully updated.",
                                    code: "Success:",
                                    type: "success",
                                };
                                dispatch(showToast(toast));
                            }
                        } else {
                            setDisabled(false);
                        }
                    })
                    .catch((err) => {
                        redirectionForErrorCode(
                            CONSTANTS.ROUTES.CORPORATION_DASHBOARD,
                            err,
                            history,
                            2000
                        );
                        setDisabled(false);
                        logger("error is :: ", err);
                        dispatch(showToast(err));
                    });
            }
        }
    };

    const jumpToNextTab = () => {
        props.setActiveTab(props.activeTab + 1);
        window.scrollTo(0, CONSTANTS.SCROLLTO_VALUE);
    };

    const jumpToPrevTab = () => {
        props.setActiveTab(props.activeTab - 1);
        window.scrollTo(0, CONSTANTS.SCROLLTO_VALUE);
    };

    const updateProgramDetails = (updatedData: any) => {
        setModifiedProgramDetails(updatedData);
        setButtonState(false);
    };
    const handleStateChange = (event: any) => {
        setProgramInfo({
            ...programInfo,
            emsAgencyStateIdNumber: event.target.value.replace(/[><]/g, ""),
        });
        setAgencyStateNumber(event.target.value.replace(/[><]/g, ""));
        setButtonState(false);
    };
    const handleRegistryChange = (event: any) => {
        setButtonState(false);
        if (
            event.target.name == "isRegistryUsing" &&
            event.target.value.trim() == "YES"
        ) {
            setModifiedRegistryData({
                ...modifiedRegistryData,
                [event.target.name]: event.target.value.trim(),
                registryName: modifiedRegistryData?.registries[0].value,
            });
        } else {
            setModifiedRegistryData({
                ...modifiedRegistryData,
                [event.target.name]: event.target.value.trim(),
            });
        }
    };

    const setEMSData = (value: any) => {
        setEmsId(value);
        setButtonState(false);
        let selectedAward;
        const splitEmsId = value.split("_");
        const selectedEMSDataIndex = allEmsData.findIndex(
            (emsDataObj: any) => emsDataObj["AHA EMS ID"] == splitEmsId[0]
        );
        if (selectedEMSDataIndex > -1) {
            const award =
                allEmsData[selectedEMSDataIndex][CONSTANTS.EMS_AWARD_YEAR] ===
                undefined
                    ? CONSTANTS.NO_AWARD
                    : allEmsData[selectedEMSDataIndex][
                          CONSTANTS.EMS_AWARD_YEAR
                      ];
            selectedAward = award;
            setPriorYearAward(award);
        } else {
            selectedAward = CONSTANTS.NO_AWARD;
            setPriorYearAward(CONSTANTS.NO_AWARD);
        }
        setProgramInfo({
            ...programInfo,
            emsId: value,
            priorYearAward: selectedAward,
        });
    };

    const setStateIDabove = (value: any) => {
        setStateID(value);
        setButtonState(false);
        setProgramInfo({
            ...programInfo,
            emsAgencyStateId: value,
        });
    };

    const resetEMSIDPopupOnCLose = () => {
        setAhaEmsId("");
        setOrganizationName("");
        setCity("");
        setEmsIdState("");
    };
    const emsIdActivateIndPopupModal = () => {
        setEmsIdActivateInd(true);
        showModal();
    };
    const emsIdDismissPopupModal = () => {
        setEmsIdActivateInd(false);
        showModal();
        resetEMSIDPopupOnCLose();
    };

    const handleEMSISModal = () => {
        const emsIdDropdown = document.getElementById("emsId") as HTMLElement;
        resetEMSIDPopupOnCLose();
        emsIdDropdown.click();
        resetEMSIDPopupOnCLose();
        emsIdActivateIndPopupModal();
    };

    const handleEMSIDOnSubmit = (e: any) => {
        e.preventDefault();
        dispatch(setPageLoadingStatus({ isPageLoading: true }));
        const EMSIDdata: any = {
            emsId: ahaEmsId,
            agencyState: emsIdState,
            agencyCity: city,
            agencyName: organizationName,
        };
        const agencyIdPayload = EMSIDdata;
        const newEMSId = `${ahaEmsId}_${organizationName}_${city}_${emsIdState}`;
        createAgencyEmsId(agencyIdPayload)
            .then((response: any) => {
                if (response.success) {
                    dispatch(setPageLoadingStatus({ isPageLoading: false }));
                    getEMSIdInDropdown();
                    setEMSData(
                        `${ahaEmsId}_${organizationName}_${city}_${emsIdState}`
                    );
                    const toast = {
                        message: "AHA EMS ID created successfully.",
                        code: "Success:",
                        type: "success",
                    };
                    store.dispatch(showToast(toast));
                }
            })
            .catch((err) => {
                dispatch(setPageLoadingStatus({ isPageLoading: false }));
                dispatch(showToast(err));
            });
        emsIdDismissPopupModal();
        resetEMSIDPopupOnCLose();
    };

    return (
        <FacilityDetailsWrapper aria-label="Program Detail tab Content">
            <div className="fd-title-border d-flex justify-content-between">
                <h3 className="h4 font-500">Program Details</h3>
                {(props.orderId || props.isCorporation || props.isEMSEUP) &&
                    props.hospital?.isActive == true && (
                        <div className="edit-btn-toggle">
                            <input
                                type="checkbox"
                                id="editProgramDetails"
                                className="edit-btn-checkbox"
                                role="button"
                                aria-pressed={editMode}
                                onClick={() => {
                                    setEditMode(!editMode);
                                    setEmsId(props.programData?.emsId);
                                    setStateID(
                                        props.programData?.emsAgencyStateId
                                    );
                                    setPriorYearAward(
                                        props.programData?.priorYearAward
                                    );
                                    setModifiedProgramDetails(
                                        JSON.parse(
                                            JSON.stringify(savedProgramDetails)
                                        )
                                    );
                                    setModifiedRegistryData(
                                        JSON.parse(JSON.stringify(registryData))
                                    );
                                    setButtonState(true);
                                }}
                            />
                            {editMode ? (
                                <label
                                    htmlFor="editProgramDetails"
                                    className="toggle-label"
                                >
                                    Cancel Edit
                                </label>
                            ) : (
                                <label
                                    htmlFor="editProgramDetails"
                                    className="toggle-label"
                                >
                                    <i
                                        className="aha-icon-edit toggle-edit"
                                        title="Edit Program Details"
                                        aria-hidden="true"
                                    />
                                    <span className="sr-only">Edit</span>
                                </label>
                            )}
                            {editMode ? (
                                <div
                                    aria-live="polite"
                                    className="d-block d-md-none sr-only"
                                >
                                    Cancel editing program details
                                </div>
                            ) : (
                                <div
                                    aria-live="polite"
                                    className="d-block d-md-none sr-only"
                                >
                                    Edit program details
                                </div>
                            )}
                        </div>
                    )}
            </div>
            {!editMode ? (
                <div className="fd-table">
                    <table className="aui-responsive-table aui-table-cols">
                        <tbody>
                            <tr>
                                <th scope="row" className="aui-row-th">
                                    Selected Program
                                </th>
                                <td className="aui-row-td">{categoryName}</td>
                            </tr>

                            {(props.isEmsAdmin || props.isEMSEUP) && (
                                <>
                                    <tr>
                                        <th scope="row" className="aui-row-th">
                                            American Heart Association
                                            <span aria-hidden="true">
                                                (AHA) EMS ID
                                            </span>
                                            <span className="sr-only">
                                                A H A E M S I D
                                            </span>
                                        </th>
                                        <td className="aui-row-td">
                                            {props.programData?.emsId}
                                        </td>
                                    </tr>

                                    <tr>
                                        <th scope="row" className="aui-row-th">
                                            Prior Year Award (2023)
                                        </th>
                                        <td className="aui-row-td">
                                            {props.programData
                                                ?.priorYearAward ||
                                                CONSTANTS.NO_AWARD}
                                        </td>
                                    </tr>

                                    <tr>
                                        <th scope="row" className="aui-row-th">
                                            <span aria-hidden="true">EMS </span>
                                            <span className="sr-only">
                                                E M S{" "}
                                            </span>{" "}
                                            Agency State Identification Number
                                        </th>
                                        <td className="aui-row-td">
                                            {
                                                props.programData
                                                    ?.emsAgencyStateIdNumber
                                            }
                                        </td>
                                    </tr>

                                    <tr>
                                        <th scope="row" className="aui-row-th">
                                            State associated with State ID above
                                        </th>
                                        <td className="aui-row-td">
                                            {
                                                props.programData
                                                    ?.emsAgencyStateId
                                            }
                                        </td>
                                    </tr>
                                </>
                            )}
                            {savedProgramDetails.map((program: any) => {
                                if (
                                    program.hideElement &&
                                    program.value === ""
                                ) {
                                    return null;
                                }
                                const multiSelectData: any = [];
                                if (program.type == "multiSelect") {
                                    program.value.forEach((pgm: any) => {
                                        multiSelectData.push(pgm.label);
                                    });
                                }
                                return (
                                    program.label && (
                                        <tr
                                            key={`${program.key}-${program.value}`}
                                        >
                                            <th
                                                scope="row"
                                                className="aui-row-th"
                                            >
                                                {wordChange(program.label)}
                                            </th>
                                            <td className="aui-row-td overflow-hidden">
                                                {program.type == "parent" &&
                                                program.options ? (
                                                    program.options.map(
                                                        (item: any) => {
                                                            return (
                                                                <div
                                                                    className="d-flex multi-td"
                                                                    key={`${item.key}-${item.value}`}
                                                                >
                                                                    <div className="multi-td-label py-3 font-500">
                                                                        {item.label ||
                                                                            item.key}
                                                                    </div>
                                                                    <div className="flex-grow-1 py-3">
                                                                        {
                                                                            item.value
                                                                        }
                                                                    </div>
                                                                </div>
                                                            );
                                                        }
                                                    )
                                                ) : program.type ==
                                                      "checkbox" &&
                                                  program.options ? (
                                                    <div>
                                                        {program.value.join(
                                                            ", "
                                                        )}
                                                    </div>
                                                ) : program.type ==
                                                  "multiSelect" ? (
                                                    <div>
                                                        {multiSelectData.join(
                                                            " | "
                                                        )}
                                                    </div>
                                                ) : (
                                                    <div>
                                                        {program.value || "N/A"}
                                                    </div>
                                                )}
                                            </td>
                                        </tr>
                                    )
                                );
                            })}
                            {!props.isEmsAdmin &&
                                registryQuestion &&
                                registryData &&
                                registryData.isRegistryUsing && (
                                    <tr>
                                        <th scope="row" className="aui-row-th">
                                            {wordChange(registryQuestion)}
                                        </th>
                                        <td className="aui-row-td">
                                            {registryData.isRegistryUsing ==
                                            "YES"
                                                ? "Yes"
                                                : "No"}
                                        </td>
                                    </tr>
                                )}
                            {registryQuestion &&
                                registryData &&
                                registryData.isRegistryUsing &&
                                registryData.isRegistryUsing == "YES" && (
                                    <tr>
                                        <th scope="row" className="aui-row-th">
                                            Registry Name
                                        </th>
                                        <td className="aui-row-td">
                                            {registryData.registryName &&
                                            registryData.registryName !=
                                                CONSTANTS.REGISTRY_OTHER
                                                ? registryData.registryName
                                                : registryData.customRegistryName
                                                ? registryData.customRegistryName
                                                : "N/A"}
                                        </td>
                                    </tr>
                                )}
                            {!props.isEmsAdmin && !props.isEMSEUP && (
                                <tr>
                                    <th scope="row" className="aui-row-th">
                                        Link your site to a corporate parent
                                        account?
                                    </th>
                                    <td className="aui-row-td">
                                        {" "}
                                        {programInfo &&
                                        programInfo.corporationId
                                            ? "Yes"
                                            : "No"}
                                    </td>
                                </tr>
                            )}
                            {programInfo && programInfo.corporationId && (
                                <tr>
                                    <th scope="row" className="aui-row-th">
                                        Corporation Name
                                    </th>
                                    <td className="aui-row-td">
                                        {" "}
                                        {programInfo &&
                                        programInfo.corporationName
                                            ? programInfo.corporationName
                                            : "N/A"}
                                    </td>
                                </tr>
                            )}
                            {!props.isEmsAdmin && !props.isEMSEUP && (
                                <tr>
                                    <th scope="row" className="aui-row-th">
                                        Program Registered on
                                    </th>
                                    <td className="aui-row-td">
                                        {" "}
                                        {programInfo && programInfo.createdAt
                                            ? localDateToYYYYMMDDFormatString(
                                                  programInfo.createdAt
                                              )
                                            : "N/A"}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    {!props.isEMSEUP && (
                        <div className="fb-table-btn d-flex justify-content-center py-4 flex-wrap">
                            <button
                                onClick={props.close}
                                type="button"
                                className="btn btn-secondary btn-round fd-btn-1"
                                aria-describedby="admin-programdetails"
                            >
                                Close
                            </button>
                            <button
                                onClick={jumpToPrevTab}
                                type="button"
                                className="btn btn-secondary btn-round fd-btn-2"
                                aria-describedby="admin-facilitydetails"
                            >
                                Previous
                            </button>
                            <button
                                className="btn btn-primary btn-round fd-btn-3"
                                onClick={jumpToNextTab}
                                aria-describedby="admin-agreementdetails"
                                disabled={
                                    isCorporation &&
                                    props?.programData?.approvalStatus ===
                                        CONSTANTS.PENDING
                                        ? false
                                        : !props.isEmsAdmin &&
                                          !props.programData
                                              ?.agreementReferenceId
                                }
                            >
                                Next
                            </button>
                        </div>
                    )}
                </div>
            ) : (
                <div className="row">
                    <div className="fd-form col-lg-10">
                        <form
                            id="programDetailsForm"
                            className="dynamic-form"
                            onSubmit={onSubmit}
                            aria-label="program detail"
                        >
                            <div className="d-flex flex-row-reverse">
                                <div className="mand-field pb-3">
                                    <sup>*</sup>
                                    mandatory fields
                                </div>
                            </div>
                            {(props.isEmsAdmin || props.isEMSEUP) && (
                                <>
                                    <div className="form-group row required">
                                        <div className="col-md-5 form-label">
                                            <label
                                                htmlFor="emsId"
                                                id="emsId-label"
                                                className="d-inline"
                                            >
                                                American Heart Association
                                                <span aria-hidden="true">
                                                    (AHA) EMS ID
                                                </span>
                                                <span className="sr-only">
                                                    A H A E M S I D
                                                </span>
                                                <button
                                                    type="button"
                                                    className="aha-icon-help measure-help btn btn-text"
                                                    data-toggle="tooltip"
                                                    data-html="true"
                                                    title="<div class='emsprogram-popover'> <p>Please enter the <span aria-hidden='true'>AHAEMS</span>&nbsp;<span class='sr-only'>A H A E M S</span><span aria-hidden='true'>ID</span>&nbsp;<span class='sr-only'>I D</span>for your agency. If your agency’s <span aria-hidden='true'>AHAEMS</span>&nbsp;<span class='sr-only'>A H A E M S</span><span aria-hidden='true'>ID</span>&nbsp;<span class='sr-only'>I D</span>is not found, or you do not know your agency’s <span aria-hidden='true'>AHAEMS</span>&nbsp;<span class='sr-only'>A H A E M S</span><span aria-hidden='true'>ID</span><span class='sr-only'>I D</span>, please select <b>‘Not Listed’</b>.</p> </div>"
                                                />
                                                {emsIdActivateInd ? (
                                                    <FocusTrap
                                                        focusTrapOptions={{
                                                            escapeDeactivates:
                                                                false,
                                                            clickOutsideDeactivates:
                                                                false,
                                                        }}
                                                    >
                                                        <div
                                                            className="modal fade show aui-modal create-ems-id-modal"
                                                            id="createEmsId"
                                                            tabIndex={-1}
                                                            aria-labelledby="createEmsIdLabel"
                                                            aria-modal="true"
                                                            role="dialog"
                                                            style={{
                                                                display:
                                                                    emsIdActivateInd
                                                                        ? "block"
                                                                        : "none",
                                                            }}
                                                        >
                                                            <div className="modal-dialog modal-dialog-centered modal-sm">
                                                                <div className="modal-content">
                                                                    <div className="modal-header mb-4">
                                                                        <h2
                                                                            className="h4"
                                                                            id="createEmsIdLabel"
                                                                        >
                                                                            Create
                                                                            New{" "}
                                                                            <span aria-hidden="true">
                                                                                AHA
                                                                            </span>
                                                                            <span className="sr-only">
                                                                                A
                                                                                H
                                                                                A
                                                                            </span>{" "}
                                                                            EMS
                                                                            ID
                                                                        </h2>
                                                                        <button
                                                                            type="button"
                                                                            className="close"
                                                                            aria-label="Close create ems id modal"
                                                                            onClick={
                                                                                emsIdDismissPopupModal
                                                                            }
                                                                        >
                                                                            <span
                                                                                aria-hidden="true"
                                                                                className="aha-icon-cross"
                                                                            />
                                                                        </button>
                                                                    </div>
                                                                    <form
                                                                        onSubmit={
                                                                            handleEMSIDOnSubmit
                                                                        }
                                                                        id="ahaemsIdForm"
                                                                    >
                                                                        <div className="modal-body mb-0">
                                                                            <div className="form-group required">
                                                                                <label
                                                                                    htmlFor="ahaEmsId"
                                                                                    className="form-label"
                                                                                >
                                                                                    <span aria-hidden="true">
                                                                                        AHA
                                                                                    </span>
                                                                                    <span className="sr-only">
                                                                                        A
                                                                                        H
                                                                                        A
                                                                                    </span>{" "}
                                                                                    EMS
                                                                                    ID
                                                                                </label>
                                                                                <div>
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        id="ahaEmsId"
                                                                                        name="ahaEmsId"
                                                                                        maxLength={
                                                                                            10
                                                                                        }
                                                                                        required
                                                                                        pattern={
                                                                                            CONSTANTS.REGEX_ALLOW_ALPHANUMERIC_AND_ONE_TO_TEN_CHAR
                                                                                        }
                                                                                        value={
                                                                                            ahaEmsId
                                                                                        }
                                                                                        onChange={(
                                                                                            e
                                                                                        ) =>
                                                                                            setAhaEmsId(
                                                                                                e
                                                                                                    .target
                                                                                                    .value
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-group required">
                                                                                <label
                                                                                    htmlFor="organizationName"
                                                                                    className="form-label"
                                                                                >
                                                                                    Organization
                                                                                    Name
                                                                                </label>
                                                                                <div>
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        id="organizationName"
                                                                                        name="organizationName"
                                                                                        maxLength={
                                                                                            200
                                                                                        }
                                                                                        required
                                                                                        value={
                                                                                            organizationName
                                                                                        }
                                                                                        onChange={(
                                                                                            e
                                                                                        ) =>
                                                                                            validateSpecialCharGreaterLessThan(
                                                                                                e
                                                                                                    .target
                                                                                                    .value
                                                                                            )
                                                                                                ? setOrganizationName(
                                                                                                      e
                                                                                                          .target
                                                                                                          .value
                                                                                                  )
                                                                                                : false
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-group required">
                                                                                <label
                                                                                    htmlFor="city"
                                                                                    className="form-label"
                                                                                >
                                                                                    City
                                                                                </label>
                                                                                <div>
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        id="city"
                                                                                        name="city"
                                                                                        maxLength={
                                                                                            30
                                                                                        }
                                                                                        required
                                                                                        value={
                                                                                            city
                                                                                        }
                                                                                        onChange={(
                                                                                            e
                                                                                        ) =>
                                                                                            validateSpecialCharGreaterLessThan(
                                                                                                e
                                                                                                    .target
                                                                                                    .value
                                                                                            )
                                                                                                ? setCity(
                                                                                                      e
                                                                                                          .target
                                                                                                          .value
                                                                                                  )
                                                                                                : false
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-group required">
                                                                                <label
                                                                                    htmlFor="state"
                                                                                    className="form-label"
                                                                                >
                                                                                    State
                                                                                </label>
                                                                                <div>
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        id="state"
                                                                                        name="state"
                                                                                        maxLength={
                                                                                            30
                                                                                        }
                                                                                        required
                                                                                        value={
                                                                                            emsIdState
                                                                                        }
                                                                                        onChange={(
                                                                                            e
                                                                                        ) =>
                                                                                            validateSpecialCharGreaterLessThan(
                                                                                                e
                                                                                                    .target
                                                                                                    .value
                                                                                            )
                                                                                                ? setEmsIdState(
                                                                                                      e
                                                                                                          .target
                                                                                                          .value
                                                                                                  )
                                                                                                : false
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex justify-content-end mt-4">
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-secondary btn-round"
                                                                                    onClick={
                                                                                        emsIdDismissPopupModal
                                                                                    }
                                                                                    aria-label="Cancel creating EMS ID"
                                                                                >
                                                                                    Cancel
                                                                                </button>
                                                                                <button
                                                                                    id="ahaemsIdForm"
                                                                                    type="submit"
                                                                                    className="btn btn-primary btn-round ml-4"
                                                                                >
                                                                                    Save
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </FocusTrap>
                                                ) : null}
                                            </label>
                                        </div>
                                        <div className="col-md-7">
                                            <SearchDropdown
                                                id="emsId"
                                                items={allEmsIds}
                                                selectedValue={emsId}
                                                disabled={
                                                    props.isEMSEUP
                                                        ? true
                                                        : ahaEMSIdLoadingInd
                                                }
                                                isLoading={ahaEMSIdLoadingInd}
                                                callParentOnSelect={(
                                                    value: any
                                                ) => {
                                                    setEMSData(value);
                                                }}
                                                label="Select EMS ID"
                                                mandatoryInd
                                                fixedBtn
                                                handleModal={handleEMSISModal}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row required">
                                        <div className="col-md-5 form-label">
                                            <label
                                                htmlFor="priorYearAward"
                                                id="priorYearAward-label"
                                                className="d-inline"
                                            >
                                                Prior Year Award (2023)
                                            </label>
                                        </div>
                                        <div className="col-md-7">
                                            <input
                                                readOnly
                                                type="text"
                                                className="form-control"
                                                id="priorYearAward"
                                                name="priorYearAward"
                                                value={priorYearAward}
                                                pattern={
                                                    CONSTANTS.REGEX_ALLOW_ALPHANUMERIC_AND_SPACE_ONLY
                                                }
                                                maxLength={
                                                    CONSTANTS.CERTIFICATE_FACILITY_NAME_LIMIT
                                                }
                                                required
                                                defaultValue={priorYearAward}
                                                aria-describedby="priorYearAward"
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row required">
                                        <div className="col-md-5 form-label">
                                            <label
                                                htmlFor="zipCode"
                                                id="zipCode-label"
                                                className="d-inline"
                                            >
                                                <span aria-hidden="true">
                                                    EMS{" "}
                                                </span>
                                                <span className="sr-only">
                                                    E M S{" "}
                                                </span>{" "}
                                                Agency State Identification
                                                Number
                                            </label>
                                            <button
                                                type="button"
                                                className="aha-icon-help measure-help btn btn-text"
                                                data-toggle="tooltip"
                                                title="Please enter the state licensure number of your agency."
                                            >
                                                <span className="sr-only">
                                                    <span aria-hidden="true">
                                                        EMS{" "}
                                                    </span>
                                                    <span className="sr-only">
                                                        E M S{" "}
                                                    </span>{" "}
                                                    Agency State Identification
                                                    Number help text
                                                </span>
                                            </button>
                                        </div>
                                        <div className="col-md-7">
                                            <input
                                                minLength={2}
                                                maxLength={50}
                                                type="text"
                                                className="form-control"
                                                name="zipCode"
                                                id="zipCode"
                                                pattern={
                                                    CONSTANTS.REGEX_ALLOW_ALPHANUMERIC_SPECIALCHARS
                                                }
                                                defaultValue={
                                                    props.programData
                                                        ?.emsAgencyStateIdNumber ||
                                                    ""
                                                }
                                                disabled={disabled}
                                                onChange={handleStateChange}
                                                aria-describedby="facilityZipCode"
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row required">
                                        <div className="col-md-5 form-label">
                                            <label
                                                htmlFor="state-facilityDetails"
                                                id="state-facilityDetails-label"
                                                className="d-inline"
                                            >
                                                State associated with State ID
                                                above
                                            </label>
                                        </div>
                                        <div className="col-md-7">
                                            <SearchDropdown
                                                id="state-facilityDetails"
                                                items={states}
                                                selectedValue={stateID}
                                                callParentOnSelect={(
                                                    value: any
                                                ) => {
                                                    setStateIDabove(value);
                                                }}
                                                disabled={disabled}
                                                label="State / Province"
                                                mandatoryInd
                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                            <DynamicForm
                                disabled={disabled}
                                onChange={updateProgramDetails}
                                className=""
                                model={modifiedProgramDetails}
                                onSubmit={onSubmit}
                            />
                            <fieldset disabled={disabled}>
                                {/* common itesm start */}
                                {!props.isCorporation &&
                                    registryQuestion &&
                                    savedProgramDetails.length > 0 && (
                                        <div
                                            className="form-group row required"
                                            role="radiogroup"
                                            aria-labelledby="isRegistryUsing"
                                        >
                                            <div className="col-md-5 form-label">
                                                <label
                                                    id="isRegistryUsing"
                                                    className="col-form-label label-form"
                                                >
                                                    {wordChange(
                                                        registryQuestion
                                                    )}
                                                    <sup>*</sup>
                                                </label>
                                            </div>
                                            <div className="col-md-7 d-flex align-items-center">
                                                <div className="row w-100 flex-grow-1 mb-n3 ml-n5">
                                                    {/* <div className="col-5 col-sm-3 col-md-4 col-lg-5 col-xl-4 mb-3 mb-md-0"> */}
                                                    <div className="mb-3 ml-5">
                                                        <div className="form-radio">
                                                            <input
                                                                name="isRegistryUsing"
                                                                onChange={
                                                                    handleRegistryChange
                                                                }
                                                                defaultChecked={
                                                                    modifiedRegistryData &&
                                                                    modifiedRegistryData.isRegistryUsing ==
                                                                        "YES"
                                                                }
                                                                defaultValue="YES"
                                                                type="radio"
                                                                className="form-control"
                                                                id="registryYes"
                                                                required
                                                            />
                                                            <label
                                                                htmlFor="registryYes"
                                                                id="yes-record"
                                                            >
                                                                Yes
                                                            </label>
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-5 col-sm-3 col-md-4 col-lg-5 col-xl-4 mb-3 mb-md-0"> */}
                                                    <div className="mb-3 ml-5">
                                                        <div className="form-radio">
                                                            <input
                                                                name="isRegistryUsing"
                                                                onChange={
                                                                    handleRegistryChange
                                                                }
                                                                defaultChecked={
                                                                    modifiedRegistryData &&
                                                                    modifiedRegistryData.isRegistryUsing ==
                                                                        "NO"
                                                                }
                                                                defaultValue="NO"
                                                                type="radio"
                                                                className="form-control"
                                                                id="registryNo"
                                                                required
                                                            />
                                                            <label
                                                                htmlFor="registryNo"
                                                                id="no-record"
                                                            >
                                                                No
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                {!props.isCorporation &&
                                    registryQuestion &&
                                    modifiedRegistryData &&
                                    modifiedRegistryData.isRegistryUsing ==
                                        "YES" && (
                                        <div>
                                            <div className="form-group row required">
                                                <div className="col-md-5 form-label">
                                                    <label
                                                        htmlFor="registryName"
                                                        id="registryName-label"
                                                        className="col-form-label label-form"
                                                    >
                                                        Please select the
                                                        Registry name
                                                        <sup>*</sup>
                                                    </label>
                                                </div>
                                                <div className="col-md-7 d-flex align-items-center flex-column">
                                                    <div className="w-100">
                                                        <Dropdown
                                                            id="registryName"
                                                            items={
                                                                modifiedRegistryData
                                                                    ? modifiedRegistryData.registries
                                                                    : []
                                                            }
                                                            selectedValue={
                                                                modifiedRegistryData &&
                                                                modifiedRegistryData.registryName
                                                            }
                                                            callParentOnSelect={(
                                                                value: any
                                                            ) => {
                                                                setModifiedRegistryData(
                                                                    {
                                                                        ...modifiedRegistryData,
                                                                        registryName:
                                                                            value.trim(),
                                                                    }
                                                                );
                                                                setButtonState(
                                                                    false
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                    {modifiedRegistryData &&
                                                        modifiedRegistryData.registryName !==
                                                            CONSTANTS.REGISTRY_OTHER && (
                                                            <div className="w-100 reg-authorise">
                                                                <div className="form-group form-check m-0">
                                                                    <input
                                                                        required
                                                                        type="checkbox"
                                                                        value=""
                                                                        id="reg-authorise"
                                                                        defaultChecked
                                                                    />
                                                                    <label htmlFor="reg-authorise">
                                                                        I
                                                                        authorize
                                                                        this
                                                                        registry
                                                                        to
                                                                        submit
                                                                        required
                                                                        data on
                                                                        the
                                                                        organization&#39;s
                                                                        behalf
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        )}
                                                </div>
                                            </div>
                                            {modifiedRegistryData &&
                                                modifiedRegistryData.registryName ==
                                                    CONSTANTS.REGISTRY_OTHER && (
                                                    <div className="form-group row required">
                                                        <div className="col-md-5 form-label">
                                                            <label
                                                                htmlFor="customRegistryName"
                                                                className="col-form-label"
                                                            >
                                                                Please specify
                                                                the Registry
                                                                name
                                                            </label>
                                                        </div>
                                                        <div className="col-md-7 d-flex align-items-center">
                                                            <input
                                                                title={
                                                                    CONSTANTS.ENTER_ALPHANUMERIC_TEXT
                                                                }
                                                                name="customRegistryName"
                                                                onChange={
                                                                    handleRegistryChange
                                                                }
                                                                defaultValue={
                                                                    modifiedRegistryData &&
                                                                    modifiedRegistryData.customRegistryName
                                                                }
                                                                type="text"
                                                                className="form-control"
                                                                id="customRegistryName"
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                        </div>
                                    )}
                                {/* common itesm ends */}

                                <div className="fb-table-btn row mx-0 fb-btn-edit">
                                    <div className="col-md-8 offset-md-4 px-0 px-md-3 mb-4 d-flex justify-content-start flex-column flex-sm-row">
                                        <button
                                            type="button"
                                            onClick={() =>
                                                setEditMode(!editMode)
                                            }
                                            className="btn btn-round btn-secondary fd-btn-1"
                                            aria-label="Cancel editing program details"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="submit"
                                            id="programDetailsForm"
                                            aria-label="Save Program Details"
                                            disabled={buttonState}
                                            className={`btn btn-round btn-primary fd-btn-2 ${
                                                disabled
                                                    ? CONSTANTS.BUTTON_SPINNER
                                                    : ""
                                            }`}
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </div>
            )}
        </FacilityDetailsWrapper>
    );
};

export default ProgramsDetails;
