import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
    APIResponse,
    getNavigationApi,
    userApi,
    userPatchApi,
    userSessionTokenApi,
} from "api/usersApi";
import { ProgressWrapper } from "pages/Progress/styled";
import store from "app/store";
import { showToast } from "components/Toast/toast.slice";
import { redirectToHomePage, sleep } from "common/utils";
import { logError, logger } from "../../utils/logger.utils";
import CONSTANTS from "../../common/constants";

export const UserVerificationPage = (props: any) => {
    const history: any = useHistory();
    const dispatch = useDispatch();

    const [user, setUser] = useState(false);
    const [userUpdated, setUserUpdated] = useState(false);
    const userState = useSelector((state: any) => {
        return state.user;
    });
    const signout = async () => {
        redirectToHomePage(CONSTANTS.SLEEP_TIME);
    };
    const throwErrorMessage = (message: string) => {
        const toast = {
            message,
        };
        store.dispatch(showToast(toast));
    };
    useEffect(() => {
        let mounted = true;
        if (!user && userState?.ssoAccessToken && userState?.ssoUser?.UID) {
            userApi(userState)
                .then((response: APIResponse) => {
                    if (
                        response.statusCode === 200 ||
                        response.statusCode === 201
                    ) {
                        if (mounted) {
                            setUser(userState.ssoUser);
                            logger(
                                "user role",
                                response.data.tokenPayload.role_code
                            );
                        }
                        userPatchApi(userState?.ssoUser).then(
                            (updateUserResponse: APIResponse) => {
                                if (updateUserResponse.statusCode === 200) {
                                    if (mounted) {
                                        setUserUpdated(true);
                                    }
                                    handleUserNavigate(
                                        response.data.tokenPayload.role_code
                                    );
                                } else {
                                    history.push("/");
                                }
                            }
                        );
                    } else {
                        history.push("/");
                    }
                })
                .catch((error: any) => {
                    if (error.code == "ACCESS_DENIED") {
                        throwErrorMessage(error.message);
                        signout();
                    }
                    logError("GET user API Error:", error);
                });
        }
        return function cleanup() {
            mounted = false;
        };
    }, [
        props.location.pathname,
        user,
        userUpdated,
        userState?.ssoAccessToken,
        userState?.ssoUser,
    ]);

    const handleUserNavigate = (roleCode: string) => {
        // admin route
        if (roleCode === CONSTANTS.USER_ROLES.AHA_ADMIN) {
            history.push("/admin/dashboard");
        }
        // EMS admin route
        if (roleCode === CONSTANTS.USER_ROLES.EMS_ADMIN) {
            history.push("/emsadmin/organization-management");
        }
        // hosptial route
        if (roleCode === CONSTANTS.USER_ROLES.HOSPITAL_ADMIN) {
            history.push("/userDashboard");
        }

        // registry or corporation route
        if (
            roleCode === CONSTANTS.USER_ROLES.REGISTRY_ADMIN ||
            roleCode === CONSTANTS.USER_ROLES.CORPORATION_ADMIN
        ) {
            getNavigationApi().then(async (navResponse: any) => {
                if (navResponse.statusCode === 200) {
                    const { entity } = navResponse.data.url;
                    const { path } = navResponse.data.url;

                    // registry route
                    if (entity === "registry") {
                        switch (path) {
                            case "registry-dashboard":
                                history.push("/registry/dashboard");
                                break;
                            default:
                                history.push("/registry/inactive");
                        }
                    }

                    // corporation route
                    if (entity === "corporation") {
                        switch (path) {
                            case "corporation-dashboard":
                                history.push("/corporation/dashboard");
                                break;
                            default:
                                history.push("/corporation/inactive");
                        }
                    }
                }
            });
        }
    };

    return (
        <ProgressWrapper>
            <div className="container">
                <div className="d-flex flex-column flex-sm-row justify-content-center align-items-center progress-welcome">
                    <h1 className="h2 mb-0 mr-sm-3 mt-3 font-600  progress-welcome-title">
                        Welcome to <span className="font-700">QCT</span>
                    </h1>
                    <img
                        src="/images/progress_logo_animation.gif"
                        alt="logo"
                        className="progress-img"
                    />
                </div>
            </div>
        </ProgressWrapper>
    );
};

export default UserVerificationPage;
