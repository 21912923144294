import React, { Component } from "react";

// eslint-disable-next-line react/prefer-stateless-function
export default class Banner extends Component {
    render() {
        return (
            <div className="aui-banner mx-auto clearfix">
                <div
                    className="aui-banner-img aui-dr-img"
                    role="img"
                    aria-label="Doctors Smiling"
                />
                <div className="aui-banner-content">
                    <div
                        className="aui-association-logo"
                        role="img"
                        aria-label="American Heart Association logo"
                    />
                    <h1 className="h1 my-2">
                        An Integrated Approach to Care &amp; Quality Improvement
                    </h1>
                </div>
            </div>
        );
    }
}
