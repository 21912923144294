import React, { useState, useEffect, useRef } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
    getHospitalProgramDetails,
    updateHospitalProgramDetails,
    saveHospitalProgramDetails,
    getProgramResgistries,
    saveProgramRegistry,
    deleteOrGetProgramRegistry,
    getProgramCategoriesByCountryId,
} from "api/hospitalProgramsAPI";
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import CONSTANTS from "common/constants";
import Dropdown from "components/Dropdown";
import store from "app/store";
import { showToast } from "components/Toast/toast.slice";
import { BreadCrumb } from "components/BreadCrumb";
import { callProgramCoprporaions } from "api/corporationAPI";
import SearchDropdown from "components/SearchDropdown";
import { getStates } from "api/countriesAPI";
import DynamicForm from "../../components/DynamicForm";
import { HospitalDetail } from "../../styled";
import { logger } from "../../utils/logger.utils";
import { getNavigationByHospitalId } from "../../api/usersApi";
import { wordChange } from "../../common/utils";
import "styles/popover.scss";
import { getAllAhaEmsIds } from "api/emsAPI";

interface Props {
    history: any;
}

interface State {
    jsonFormData: Array<object>;
    className: string;
}

const HospitalProgramPage = ({ history }: Props) => {
    const location = useLocation();
    const [programDetails, setProgramDetails] = useState<any>([]);
    const [programDetailsAvailable, setProgramDetailsAvailable] =
        useState<any>(false);
    const [registryCheckboxStatus, setRegistryCheckboxStatus] =
        useState<any>(false);
    const [corporationCheckboxStatus, setCorporationCheckboxStatus] =
        useState<any>(false);
    const [disabled, setDisabled] = useState<any>(false);
    const [showCategories, setShowCategories] = useState<any>(false);
    const [categories, setCategories] = useState<any>([]);
    const [selectedCategoryId, setSelectedCategoryId] = useState<any>("");
    const [programName, setProgramName] = useState<any>("");
    const [subscriptionModel, setSubscriptionModel] = useState<any>("");
    const [measureSubmissionModel, setMeasureSubmissionModel] =
        useState<any>("");
    const [subscriptionPeriod, setSubscriptionPeriod] = useState<any>("");
    const [registryData, setRegistryData] = useState<any>(null);
    const [hideFormInputs, setHideFormInputs] = useState<boolean>(true);
    const [registryQuestion, setRegistryQuestion] = useState<any>("");
    const [loadingInd, setLoadingInd] = useState<boolean>(false);
    const [ahaEMSIdLoadingInd, setAhaEMSIdLoadingInd] =
        useState<boolean>(false);
    const [allEmsIds, setAllEmsIds] = useState<any>([]);
    const [priorYearAward, setPriorYearAward] = useState<any>("");
    const [allEmsData, setAllEmsData] = useState<any>([]);
    const params: any = useParams();
    const dispatch = useDispatch();
    const [breadcrumbItems, setBreadcrumbItems] = useState<any>([]);
    const hospitalId: any = localStorage.getItem("selectedHospitalId");
    const countryId: any = localStorage.getItem("selectedHospitalCountryId");
    const [corporationQuestion, setCorporationQuestion] = useState<any>();
    const [corporationData, setCorporationData] = useState<any>({
        corporationName: "",
    });
    const [selectedEMSId, setSelectedEMSId] = useState<any>("");
    const [states, setStates] = useState<[]>([]);
    const [isCorporationUsing, setIsCorporationUsing] = useState<any>("");
    const [corpQuestionInd, setCorpQuestionInd] = useState<boolean>(false);
    const [isRegistrationInProgress, setIsRegistrationInProgress] =
        useState<boolean>(false);
    const [hospitalProgramInfo, setHospitalProgramInfo] = useState<any>({
        countryCode: CONSTANTS.DOMESTIC_COUNTRY_CODE,
        state: "",
    });
    useEffect(() => {
        dispatch(setPageLoadingStatus({ isPageLoading: true }));
        const elementsArray = [];
        elementsArray.push(
            {
                label: "Home",
                returnPath: {
                    pathname: CONSTANTS.ROUTES.USER_DASHBOARD,
                    state: {
                        pageNum: location?.state?.pageNum,
                        search: location?.state?.search,
                    },
                },
            },
            {
                label: "Organization Details",
                returnPath: {
                    pathname: `${CONSTANTS.ROUTES.HOSPITAL_REGISTRATION}/${hospitalId}`,
                    state: {
                        pageNum: location?.state?.pageNum,
                        search: location?.state?.search,
                    },
                },
            },
            {
                label: "Programs",
                returnPath: {
                    pathname: CONSTANTS.ROUTES.PROGRAMS,
                    state: {
                        pageNum: location?.state?.pageNum,
                        search: location?.state?.search,
                    },
                },
            },
            {
                label: "Program Details",
                returnPath: "",
            }
        );
        setBreadcrumbItems(elementsArray);
        getNavigationByHospitalId(hospitalId).then(
            (navigationResponse: any) => {
                const { entity, path } = navigationResponse.data.url;
                if (
                    entity === CONSTANTS.HOSPITAL &&
                    path === CONSTANTS.THANKYOU_PAGE
                ) {
                    dispatch(setPageLoadingStatus({ isPageLoading: false }));
                    history.push("/successpayment");
                } else if (
                    entity === CONSTANTS.HOSPITAL &&
                    path === CONSTANTS.PATHS.DASHBOARD
                ) {
                    dispatch(setPageLoadingStatus({ isPageLoading: false }));
                    history.push("/userDashboard");
                } else if (
                    programDetails &&
                    programDetails.length == 0 &&
                    showCategories == false
                ) {
                    dispatch(setPageLoadingStatus({ isPageLoading: true }));
                    getStatesList();
                    getHospitalProgramDetails(params.programId, hospitalId)
                        .then((response: any) => {
                            let jsonInput: any = [];
                            const jsonFormData = response;
                            const corporationInfo: any = {
                                corporationName: "",
                                corporationId: null,
                            };
                            const corporationArr: any = [];
                            const emsIds: any = [];
                            setLoadingInd(true);
                            setIsCorporationUsing(
                                response?.data?.corporationId
                                    ? "YES"
                                    : response?.data &&
                                      response?.data?.corporationId == null
                                    ? "NO"
                                    : ""
                            );
                            if (params.programId != 22) {
                                callProgramCoprporaions(
                                    params.programId,
                                    setCorpQuestionInd,
                                    dispatch,
                                    setPageLoadingStatus
                                )
                                    .then((corpResponse: any) => {
                                        if (corpResponse?.data) {
                                            for (
                                                let i = 0;
                                                i < corpResponse.data.length;
                                                i++
                                            ) {
                                                if (
                                                    corpResponse.data[i]
                                                        .corporationId ==
                                                    response?.data
                                                        ?.corporationId
                                                ) {
                                                    corporationInfo.corporationName =
                                                        corpResponse.data[
                                                            i
                                                        ].corporationName;
                                                    corporationInfo.corporationId =
                                                        corpResponse.data[
                                                            i
                                                        ].corporationId;
                                                    setCorporationCheckboxStatus(
                                                        true
                                                    );
                                                }
                                                corporationArr.push({
                                                    label: corpResponse.data[i]
                                                        .corporationName,
                                                    value: corpResponse.data[i]
                                                        .corporationName,
                                                    id: corpResponse.data[i]
                                                        .corporationId,
                                                });
                                            }
                                            setCorporationData({
                                                corporations: corporationArr,
                                                corporationName:
                                                    corporationInfo &&
                                                    corporationInfo.corporationName
                                                        ? corporationInfo.corporationName
                                                        : "",
                                            });
                                            setLoadingInd(false);
                                        }
                                    })
                                    .catch(() => {
                                        setLoadingInd(false);
                                    });
                            } else {
                                setAhaEMSIdLoadingInd(true);
                                getAllAhaEmsIds(dispatch, setPageLoadingStatus)
                                    .then((emsResponse: any) => {
                                        if (emsResponse?.data) {
                                            setAllEmsData(emsResponse.data);
                                            for (
                                                let i = 0;
                                                i < emsResponse.data.length;
                                                i++
                                            ) {
                                                const emsIdToDisplay = `${emsResponse.data[i]["AHA EMS ID"]}_${emsResponse.data[i]["Agency Name"]}_${emsResponse.data[i].City}_${emsResponse.data[i].State}`;
                                                if (
                                                    emsIdToDisplay ==
                                                    response?.data?.emsId
                                                ) {
                                                    setSelectedEMSId(
                                                        emsIdToDisplay
                                                    );
                                                }
                                                emsIds.push({
                                                    label: `${emsResponse.data[i]["AHA EMS ID"]}_${emsResponse.data[i]["Agency Name"]}_${emsResponse.data[i].City}_${emsResponse.data[i].State}`,
                                                    value: `${emsResponse.data[i]["AHA EMS ID"]}_${emsResponse.data[i]["Agency Name"]}_${emsResponse.data[i].City}_${emsResponse.data[i].State}`,
                                                    id: `${emsResponse.data[i]["AHA EMS ID"]}`,
                                                });
                                            }
                                            setAllEmsIds([
                                                ...emsIds,
                                                {
                                                    label: "Not listed",
                                                    value: "Not listed",
                                                    id: "",
                                                },
                                            ]);
                                            if (
                                                response.success &&
                                                response.data?.emsId ===
                                                    "Not listed"
                                            ) {
                                                setSelectedEMSId("Not listed");
                                            }
                                            setAhaEMSIdLoadingInd(false);
                                        }
                                    })
                                    .catch(() => {
                                        setAhaEMSIdLoadingInd(false);
                                    });
                                setHospitalProgramInfo({
                                    ...hospitalProgramInfo,
                                    stateNumber:
                                        response?.data?.emsAgencyStateIdNumber,
                                    state: response?.data?.emsAgencyStateId,
                                    emsId: response?.data?.emsId,
                                });
                                setPriorYearAward(
                                    response?.data?.priorYearAward
                                );
                                dispatch(
                                    setPageLoadingStatus({
                                        isPageLoading: false,
                                    })
                                );
                            }
                            if (
                                jsonFormData &&
                                jsonFormData.data &&
                                jsonFormData.data.programDetails
                            ) {
                                dispatch(
                                    setPageLoadingStatus({
                                        isPageLoading: true,
                                    })
                                );
                                setHideFormInputs(false);
                                const parsedJsonFormData = JSON.parse(
                                    jsonFormData.data.programDetails
                                );

                                jsonInput = parsedJsonFormData.jsonForm;
                                if (params.programId != 22) {
                                    getProgramResgistries(
                                        jsonFormData.data.programCategoryId
                                    )
                                        .then((registryRes: any) => {
                                            deleteOrGetProgramRegistry(
                                                Number(hospitalId),
                                                Number(params.programId),
                                                Number(
                                                    jsonFormData.data
                                                        .programCategoryId
                                                ),
                                                "GET"
                                            )
                                                .then(
                                                    (
                                                        getProgramRegistryResponse: any
                                                    ) => {
                                                        let registryInfo: any;
                                                        const registryArr: any =
                                                            [
                                                                {
                                                                    label: "Select",
                                                                    value: "",
                                                                },
                                                            ];
                                                        let prevSelectedRegistryName: any =
                                                            "";

                                                        registryRes.data.registries.forEach(
                                                            (item: any) => {
                                                                if (
                                                                    getProgramRegistryResponse.data &&
                                                                    item.registryId ==
                                                                        getProgramRegistryResponse
                                                                            .data
                                                                            .registryId
                                                                ) {
                                                                    setRegistryCheckboxStatus(
                                                                        true
                                                                    );
                                                                    prevSelectedRegistryName =
                                                                        item.registryName;
                                                                }
                                                                if (
                                                                    getProgramRegistryResponse.data &&
                                                                    item.registryId ==
                                                                        getProgramRegistryResponse
                                                                            .data
                                                                            .registryId
                                                                ) {
                                                                    setRegistryCheckboxStatus(
                                                                        true
                                                                    );
                                                                }
                                                                registryArr.push(
                                                                    {
                                                                        label: item.registryName,
                                                                        value: item.registryName,
                                                                        id: item.registryId,
                                                                    }
                                                                );
                                                            }
                                                        );

                                                        if (
                                                            getProgramRegistryResponse.success ==
                                                            true
                                                        ) {
                                                            registryInfo = {
                                                                isRegistryUsing:
                                                                    "YES",
                                                                registryName:
                                                                    prevSelectedRegistryName,
                                                                customRegistryName:
                                                                    "",
                                                            };
                                                        } else if (
                                                            jsonInput &&
                                                            jsonInput.length >
                                                                0 &&
                                                            jsonInput[
                                                                jsonInput.length -
                                                                    1
                                                            ].customData == true
                                                        ) {
                                                            registryInfo =
                                                                jsonInput[
                                                                    jsonInput.length -
                                                                        1
                                                                ];
                                                        }

                                                        registryArr.push({
                                                            label: CONSTANTS.REGISTRY_OTHER,
                                                            value: CONSTANTS.REGISTRY_OTHER,
                                                            id: CONSTANTS.REGISTRY_OTHER,
                                                        });

                                                        setRegistryData({
                                                            isRegistryUsing:
                                                                registryInfo &&
                                                                registryInfo.isRegistryUsing
                                                                    ? registryInfo.isRegistryUsing
                                                                    : "",
                                                            registries:
                                                                registryArr,
                                                            registryName:
                                                                registryInfo &&
                                                                registryInfo.registryName
                                                                    ? registryInfo.registryName
                                                                    : "",
                                                            customRegistryName:
                                                                registryInfo &&
                                                                registryInfo.customRegistryName
                                                                    ? registryInfo.customRegistryName
                                                                    : "",
                                                        });
                                                    }
                                                )
                                                .catch((error) => {
                                                    logger(
                                                        "error is :: ",
                                                        error
                                                    );
                                                });
                                        })
                                        .catch((error: any) => {
                                            logger("error is :: ", error);
                                        });
                                }
                                setSelectedCategoryId(
                                    jsonFormData.data.programCategoryId
                                );
                                setProgramDetails(jsonInput);
                                setProgramDetailsAvailable(true);
                                getProgramCategoriesByCountryId(
                                    params.programId,
                                    countryId
                                )
                                    .then((categoryIds) => {
                                        if (
                                            categoryIds.data &&
                                            categoryIds.data.length >= 1
                                        ) {
                                            const selectedCategory =
                                                categoryIds.data.filter(
                                                    (category: any) => {
                                                        return (
                                                            category.categoryId ==
                                                            jsonFormData.data
                                                                .programCategoryId
                                                        );
                                                    }
                                                );
                                            selectedCategory[0] &&
                                                selectedCategory[0]
                                                    .programDetails &&
                                                selectedCategory[0]
                                                    .programDetails.registry &&
                                                setRegistryQuestion(
                                                    selectedCategory[0]
                                                        .programDetails.registry
                                                        .registryQuestion
                                                );
                                            selectedCategory[0] &&
                                                selectedCategory[0]
                                                    .programDetails &&
                                                selectedCategory[0]
                                                    .programDetails
                                                    .corporation &&
                                                setCorporationQuestion(
                                                    selectedCategory[0]
                                                        .programDetails
                                                        .corporation
                                                        .corporationQuestion
                                                );
                                            setSubscriptionModel(
                                                categoryIds.data[0]
                                                    .subscriptionModel
                                            );
                                            setMeasureSubmissionModel(
                                                categoryIds.data[0]
                                                    .measureSubmissionModel
                                            );
                                            setProgramName(
                                                categoryIds.data[0].program &&
                                                    categoryIds.data[0].program
                                                        .programName
                                                    ? categoryIds.data[0]
                                                          .program.programName
                                                    : null
                                            );
                                            setCategories(categoryIds.data);
                                            if (categoryIds.data.length > 1) {
                                                setShowCategories(true);
                                            }
                                        }
                                    })
                                    .catch((error) => {
                                        dispatch(
                                            setPageLoadingStatus({
                                                isPageLoading: false,
                                            })
                                        );
                                        store.dispatch(showToast(error));
                                    });
                            } else {
                                dispatch(
                                    setPageLoadingStatus({
                                        isPageLoading: true,
                                    })
                                );
                                getProgramCategoriesByCountryId(
                                    params.programId,
                                    countryId
                                )
                                    .then((categoryIds) => {
                                        if (
                                            categoryIds.data &&
                                            categoryIds.data.length >= 1 &&
                                            categoryIds.data[0].programDetails
                                        ) {
                                            setSubscriptionModel(
                                                categoryIds.data[0]
                                                    .subscriptionModel
                                            );
                                            setMeasureSubmissionModel(
                                                categoryIds.data[0]
                                                    .measureSubmissionModel
                                            );
                                            setSubscriptionPeriod(
                                                categoryIds.data[0]
                                                    .subscriptionPeriod
                                            );
                                            setProgramName(
                                                categoryIds.data[0].program &&
                                                    categoryIds.data[0].program
                                                        .programName
                                                    ? categoryIds.data[0]
                                                          .program.programName
                                                    : null
                                            );
                                            setCategories(categoryIds.data);
                                            const registryArr: any = [];

                                            setRegistryData({
                                                isRegistryUsing: "",
                                                registries: [
                                                    {
                                                        label: CONSTANTS.REGISTRY_OTHER,
                                                        value: CONSTANTS.REGISTRY_OTHER,
                                                        id: CONSTANTS.REGISTRY_OTHER,
                                                    },
                                                ],
                                                registryName: "",
                                                customRegistryName: "",
                                            });

                                            categoryIds.data[0].programDetails
                                                .registry &&
                                                setRegistryQuestion(
                                                    categoryIds.data[0]
                                                        .programDetails.registry
                                                        .registryQuestion
                                                );
                                            categoryIds.data[0].programDetails
                                                .corporation &&
                                                setCorporationQuestion(
                                                    categoryIds.data[0]
                                                        .programDetails
                                                        .corporation
                                                        .corporationQuestion
                                                );

                                            if (categoryIds.data.length == 1) {
                                                const dataToRender = categoryIds
                                                    .data[0].programDetails
                                                    .jsonForm
                                                    ? categoryIds.data[0]
                                                          .programDetails
                                                          .jsonForm
                                                    : [];
                                                setProgramDetails(dataToRender);
                                                setSelectedCategoryId(
                                                    categoryIds.data[0]
                                                        .categoryId
                                                );
                                                setHideFormInputs(false);
                                                if (params.programId != 22) {
                                                    getProgramResgistries(
                                                        categoryIds.data[0]
                                                            .categoryId
                                                    )
                                                        .then(
                                                            (
                                                                registryRes: any
                                                            ) => {
                                                                registryRes.data.registries.forEach(
                                                                    (
                                                                        item: any
                                                                    ) => {
                                                                        addToregistryArr(
                                                                            registryArr,
                                                                            item
                                                                        );
                                                                    }
                                                                );
                                                                registryArr.push(
                                                                    {
                                                                        label: CONSTANTS.REGISTRY_OTHER,
                                                                        value: CONSTANTS.REGISTRY_OTHER,
                                                                        id: CONSTANTS.REGISTRY_OTHER,
                                                                    }
                                                                );
                                                                setRegistryData(
                                                                    {
                                                                        ...registryData,
                                                                        registries:
                                                                            registryArr,
                                                                    }
                                                                );
                                                            }
                                                        )
                                                        .catch((error: any) => {
                                                            logger(error);
                                                        });
                                                }
                                            } else {
                                                setShowCategories(true);
                                            }
                                        }
                                    })
                                    .catch((error) => {
                                        dispatch(
                                            setPageLoadingStatus({
                                                isPageLoading: false,
                                            })
                                        );
                                    });
                            }
                        })
                        .catch((error) => {
                            dispatch(
                                setPageLoadingStatus({ isPageLoading: false })
                            );
                            store.dispatch(showToast(error));
                        });
                }
            }
        );
    }, []);

    function parseJwt(token: any) {
        const base64Url = token?.split(".")[1];
        const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        const jsonPayload = decodeURIComponent(
            atob(base64)
                ?.split("")
                .map(function (c) {
                    return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
                })
                .join("")
        );

        return JSON.parse(jsonPayload);
    }

    const updateProgramDetails = (updatedData: any) => {
        setProgramDetails(updatedData);
    };
    const filterRegistries = (registries: any) => {
        return registries.filter((registry: any) => {
            return registry.value == registryData?.registryName;
        });
    };

    const addToregistryArr = (registryArr: any, item: any) => {
        registryArr.push({
            label: item.registryName,
            value: item.registryName,
            id: item.registryId,
        });
    };

    const onSubmit = (event: any) => {
        event.preventDefault();
        setIsRegistrationInProgress(true);
        if (isCorporationUsing === "YES" && !corporationData.corporationName) {
            const toast = {
                message: "Please select the Corporation.",
                code: "Error:",
            };
            store.dispatch(showToast(toast));
            return false;
        }
        if (
            registryData?.isRegistryUsing === "YES" &&
            !registryData.registryName
        ) {
            const toast = {
                message: "Please select the Registry.",
                code: "Error:",
            };
            store.dispatch(showToast(toast));
            return false;
        }
        let formError: boolean = false;
        const errArr: any = [];
        dispatch(setPageLoadingStatus({ isPageLoading: true }));
        let registryDataExist: boolean = false;
        const registryInfo: any = {
            customData: true,
            isRegistryUsing: registryData?.isRegistryUsing,
            registryName: registryData?.registryName?.trim(),
            customRegistryName: registryData?.customRegistryName?.trim(),
        };

        let selectedCorporationId: any = "";
        if (isCorporationUsing == "YES") {
            const selectedCorporationObj = corporationData.corporations.filter(
                (corporation: any) => {
                    return corporation.value == corporationData.corporationName;
                }
            );
            selectedCorporationId =
                selectedCorporationObj.length > 0 &&
                selectedCorporationObj[0].id;
        } else if (
            isCorporationUsing == "NO" ||
            params.programId == CONSTANTS.EMS_PROGRAM_ID ||
            !corpQuestionInd
        ) {
            selectedCorporationId = null;
        }
        const model = programDetails;
        model.forEach((item: any) => {
            if (item.type == "select") {
                // added for old program details support
                item.type = "dropdown";
            }
            if (
                item.type == "dropdown" &&
                !item.value &&
                item.props.required &&
                !item.hideElement
            ) {
                errArr.push(item.label);
                formError = true;
            }
            if (item.customData == true) {
                registryDataExist = true;
            }

            if (
                item.type == "multiSelect" &&
                item.props.required &&
                (!item.value || item.value.length == 0)
            ) {
                errArr.push(item.label);
                formError = true;
            }
        });

        if (formError) {
            dispatch(setPageLoadingStatus({ isPageLoading: false }));
            const toast = {
                message: `Please select ${errArr.join(",")}.`,
                code: "Error:",
            };
            store.dispatch(showToast(toast));
            return false;
        }

        const modelItem = model.length - 1;
        if (registryDataExist) {
            model[modelItem] = registryInfo;
        } else {
            model.push(registryInfo);
        }

        setDisabled(true);
        if (programDetailsAvailable) {
            // PATCH CALL
            const innerData = { jsonForm: model };
            const updatedFormData = {
                priorYearAward: priorYearAward || undefined,
                programDetails: JSON.stringify(innerData),
                corporationId: selectedCorporationId,
                programCategoryId: selectedCategoryId,
                emsId: hospitalProgramInfo.emsId,
                emsAgencyStateIdNumber: hospitalProgramInfo.stateNumber,
                emsAgencyStateId: hospitalProgramInfo.state,
            };
            updateHospitalProgramDetails(
                updatedFormData,
                params.programId,
                hospitalId
            )
                .then((data) => {
                    if (data.success == true) {
                        if (
                            registryData?.isRegistryUsing == "YES" &&
                            registryData?.registryName !=
                                CONSTANTS.REGISTRY_OTHER
                        ) {
                            const selectedRegistryObj = filterRegistries(
                                registryData?.registries
                            );
                            const selectedCategoryObj = categories.filter(
                                (category: any) => {
                                    return (
                                        category.categoryId ==
                                        selectedCategoryId
                                    );
                                }
                            );
                            const registryObj = {
                                programName: categories[0].program.programName,
                                categoryName:
                                    selectedCategoryObj[0].categoryName,
                                registryId: selectedRegistryObj[0].id,
                                programId: Number(params.programId),
                                categoryId: selectedCategoryId,
                                hospitalId,
                            };

                            saveProgramRegistry(registryObj)
                                .then((saveResponse: any) => {
                                    history.push(
                                        `/programs/${params.programId}/category/${selectedCategoryId}/agreement`
                                    );
                                })
                                .catch((error: any) => {
                                    logger(
                                        "error in saveProgramRegistry :: ",
                                        error
                                    );
                                    setDisabled(false);
                                    dispatch(
                                        setPageLoadingStatus({
                                            isPageLoading: false,
                                        })
                                    );
                                });
                        } else {
                            deleteOrGetProgramRegistry(
                                hospitalId,
                                Number(params.programId),
                                selectedCategoryId,
                                "DELETE"
                            )
                                .then((deleteResponse: any) => {
                                    history.push(
                                        `/programs/${params.programId}/category/${selectedCategoryId}/agreement`
                                    );
                                })
                                .catch((error) => {
                                    logger(
                                        "error in deleteOrGetProgramRegistry :: ",
                                        error
                                    );
                                    setDisabled(false);
                                    dispatch(
                                        setPageLoadingStatus({
                                            isPageLoading: false,
                                        })
                                    );
                                });
                        }
                    } else {
                        dispatch(
                            setPageLoadingStatus({ isPageLoading: false })
                        );
                        setDisabled(false);
                    }
                })
                .catch((err) => {
                    dispatch(setPageLoadingStatus({ isPageLoading: false }));
                    setDisabled(false);
                    logger("error is :: ", err);
                });
        } else {
            // POST CALL
            const selectedCategoryObj = categories.filter((category: any) => {
                return category.categoryId == selectedCategoryId;
            });
            const innerData = { jsonForm: model };
            const updatedFormData = {
                programCategoryId: selectedCategoryId,
                programDetails: JSON.stringify(innerData),
                programName: categories[0].program.programName,
                programCategoryName: selectedCategoryObj[0].categoryName,
                subscriptionModel,
                measureSubmissionModel,
                subscriptionPeriod,
                corporationId: selectedCorporationId,
                emsId: hospitalProgramInfo.emsId,
                emsAgencyStateIdNumber: hospitalProgramInfo.stateNumber,
                emsAgencyStateId: hospitalProgramInfo.state,
                priorYearAward: priorYearAward || undefined,
            };
            saveHospitalProgramDetails(
                updatedFormData,
                hospitalId,
                params.programId
            )
                .then((data) => {
                    if (data.success == true) {
                        if (
                            registryData?.isRegistryUsing == "YES" &&
                            registryData?.registryName !=
                                CONSTANTS.REGISTRY_OTHER
                        ) {
                            const selectedRegistryObj = filterRegistries(
                                registryData?.registries
                            );
                            const registryObj = {
                                programName: categories[0].program.programName,
                                categoryName:
                                    selectedCategoryObj[0].categoryName,
                                registryId: selectedRegistryObj[0].id,
                                programId: Number(params.programId),
                                categoryId: selectedCategoryId,
                                hospitalId,
                            };

                            saveProgramRegistry(registryObj)
                                .then((response) => {
                                    history.push(
                                        `/programs/${params.programId}/category/${selectedCategoryId}/agreement`
                                    );
                                })
                                .catch((error: any) => {
                                    logger(
                                        "error is saveProgramRegistry ",
                                        error
                                    );
                                    setDisabled(false);
                                    dispatch(
                                        setPageLoadingStatus({
                                            isPageLoading: false,
                                        })
                                    );
                                });
                        } else {
                            history.push(
                                `/programs/${params.programId}/category/${selectedCategoryId}/agreement`
                            );
                        }
                    } else {
                        dispatch(
                            setPageLoadingStatus({ isPageLoading: false })
                        );
                        setDisabled(false);
                    }
                })
                .catch((err) => {
                    logger("error is :: ", err);
                    setDisabled(false);
                    dispatch(setPageLoadingStatus({ isPageLoading: false }));
                });
        }
    };

    const changeCategory = (event: any) => {
        setProgramDetails([]);
        if (event.target.id == "category_1") {
            setSelectedCategoryId(event.target.value);
            setTimeout(() => {
                setProgramDetails(categories[0].programDetails.jsonForm);
                categories[0].programDetails.registry &&
                    setRegistryQuestion(
                        categories[0].programDetails.registry.registryQuestion
                    );
                categories[0].programDetails.corporation &&
                    setCorporationQuestion(
                        categories[0].programDetails.corporation
                            .corporationQuestion
                    );
            }, 0);
        } else {
            setSelectedCategoryId(event.target.value);
            setTimeout(() => {
                setProgramDetails(categories[1].programDetails.jsonForm);
                categories[1].programDetails.registry &&
                    setRegistryQuestion(
                        categories[1].programDetails.registry.registryQuestion
                    );
                categories[1].programDetails.corporation &&
                    setCorporationQuestion(
                        categories[1].programDetails.corporation
                            .corporationQuestion
                    );
            }, 0);
        }
        getProgramResgistries(event.target.value)
            .then((registryRes: any) => {
                const registryArr: any = [];
                registryRes.data.registries.forEach((item: any) => {
                    addToregistryArr(registryArr, item);
                });
                registryArr.push({
                    label: CONSTANTS.REGISTRY_OTHER,
                    value: CONSTANTS.REGISTRY_OTHER,
                    id: CONSTANTS.REGISTRY_OTHER,
                });
                setRegistryData({ ...registryData, registries: registryArr });
            })
            .catch((error: any) => {
                logger(error);
            });
        hideFormInputs == true && setHideFormInputs(false);
    };

    const goBack = () => {
        history.push(`/programs`);
    };

    const handleRegistryChange = (event: any) => {
        if (
            event.target.name == "isRegistryUsing" &&
            event.target.value.trim() == "YES"
        ) {
            setRegistryData({
                ...registryData,
                [event.target.name]: event.target.value.trim(),
                registryName: "",
            });
        } else {
            setRegistryData({
                ...registryData,
                [event.target.name]: event.target.value,
            });
        }
    };

    const handleCorporationChange = (event: any) => {
        if (
            event.target.name == "isCorporationUsing" &&
            event.target.value.trim() == "YES"
        ) {
            setIsCorporationUsing(event.target.value.trim());
            setCorporationData({
                ...corporationData,
                corporationName: "",
            });
        } else {
            setIsCorporationUsing(event.target.value.trim());
        }
    };
    const getStatesList = () => {
        getStates(hospitalProgramInfo.countryCode).then((response) => {
            if (response.statusCode === 200) {
                const stateList = response.data;

                if (stateList.length) {
                    const stateMap: any = [];
                    stateList.map((country: any) => {
                        stateMap.push({
                            label: country.name,
                            value: country.name,
                        });
                    });
                    setStates(stateMap);
                }
            }
        });
    };
    useEffect(() => {
        if (CONSTANTS.WINDOW.AUI.Tooltip) {
            CONSTANTS.WINDOW.AUI.Tooltip.init();
        }
    });
    const handleChange = (event: any) => {
        setHospitalProgramInfo({
            ...hospitalProgramInfo,
            [event.target.name]:
                event.target.name == "stateNumber"
                    ? event.target.value.replace(/[><]/g, "")
                    : event.target.value,
        });
    };

    const setEMSData = (value: any) => {
        setHospitalProgramInfo({
            ...hospitalProgramInfo,
            emsId: value,
        });
        setSelectedEMSId(value);
        const splitEmsId = value.split("_");
        const selectedEMSDataIndex = allEmsData.findIndex(
            (emsDataObj: any) => emsDataObj["AHA EMS ID"] == splitEmsId[0]
        );
        if (selectedEMSDataIndex > -1) {
            setPriorYearAward(
                allEmsData[selectedEMSDataIndex][CONSTANTS.EMS_AWARD_YEAR] ===
                    undefined
                    ? CONSTANTS.NO_AWARD
                    : allEmsData[selectedEMSDataIndex][CONSTANTS.EMS_AWARD_YEAR]
            );
        } else {
            setPriorYearAward(CONSTANTS.NO_AWARD);
        }
    };

    return (
        <HospitalDetail>
            <div className="container reg-container">
                <div className="mb-5 pg-header">
                    <BreadCrumb breadcrumbItems={breadcrumbItems} />
                    <h1 className="h2 mb-2">Registration</h1>
                    <p>Please complete the following Registration Form</p>
                </div>
                <div aria-labelledby="program-info" role="group">
                    <div className="row">
                        <div className="col-lg-12 d-block d-sm-flex justify-content-sm-between mb-4 align-items-center">
                            <h2 className="h3 mb-0" id="program-info">
                                Program Details
                            </h2>
                            <div className="pt-3 pt-sm-0 mand-field">
                                <sup>*</sup>
                                mandatory fields
                            </div>
                        </div>
                    </div>

                    <div
                        key="category-label"
                        className="form-group row required"
                        id={`${hospitalId}-facility`}
                    >
                        <div className="col-md-5 form-label">
                            <label
                                className="col-form-label label-form"
                                htmlFor="programName"
                            >
                                Selected Program
                                <sup>*</sup>
                            </label>
                        </div>
                        <div className="col-md-7">
                            <div className="position-relative">
                                <input
                                    readOnly
                                    className="form-control tooltip-item"
                                    type="text"
                                    name="programName"
                                    value={programName}
                                    id="programName"
                                />
                                <div className="tooltip-div" aria-hidden="true">
                                    {programName}
                                </div>
                            </div>
                        </div>
                    </div>
                    {showCategories === true && params.programId != 22 ? (
                        <div
                            key="category-name"
                            className="form-group row required"
                            role="group"
                        >
                            <div className="col-md-5 form-label">
                                <label
                                    id="prgApplicable"
                                    className="label-form"
                                >
                                    Please select which certification program
                                    your organization is applying for
                                    <sup>*</sup>
                                </label>
                            </div>
                            <div
                                className="col-md-7 d-lg-flex align-items-center"
                                role="radiogroup"
                                aria-labelledby="prgApplicable"
                            >
                                {categories && categories[0] ? (
                                    <div className="form-group form-radio mr-lg-4">
                                        <input
                                            id="category_1"
                                            type="radio"
                                            name="category_name"
                                            value={
                                                categories && categories[0]
                                                    ? categories[0].categoryId
                                                    : ""
                                            }
                                            checked={
                                                selectedCategoryId ==
                                                categories[0].categoryId
                                            }
                                            disabled={programDetailsAvailable}
                                            onChange={changeCategory}
                                            required
                                        />
                                        <label htmlFor="category_1">
                                            {categories && categories[0]
                                                ? categories[0].categoryName
                                                : ""}
                                        </label>
                                    </div>
                                ) : null}

                                {categories && categories[1] ? (
                                    <div className="form-group form-radio">
                                        <input
                                            id="category_2"
                                            type="radio"
                                            name="category_name"
                                            defaultValue={
                                                categories && categories[1]
                                                    ? categories[1].categoryId
                                                    : ""
                                            }
                                            checked={
                                                selectedCategoryId ==
                                                categories[1].categoryId
                                            }
                                            disabled={programDetailsAvailable}
                                            onChange={changeCategory}
                                            required
                                        />
                                        <label htmlFor="category_2">
                                            {categories && categories[1]
                                                ? categories[1].categoryName
                                                : ""}
                                        </label>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    ) : null}
                    {hideFormInputs == false && (
                        <div className="form-group row required">
                            <div className="reg-form col-12">
                                <form
                                    className="dynamic-form"
                                    onSubmit={onSubmit}
                                >
                                    {params.programId == 22 ? (
                                        <>
                                            <div className="form-group row ">
                                                <div
                                                    className="col-md-5 form-label emsprolabel"
                                                    id="ems-id"
                                                >
                                                    <label
                                                        htmlFor="emsId"
                                                        id="emsId-label"
                                                        className="d-inline"
                                                    >
                                                        American Heart
                                                        Association (
                                                        <span aria-hidden="true">
                                                            AHA
                                                        </span>
                                                        <span className="sr-only">
                                                            A H A
                                                        </span>
                                                        ){" "}
                                                        <span aria-hidden="true">
                                                            EMS{" "}
                                                        </span>
                                                        <span className="sr-only">
                                                            E M S{" "}
                                                        </span>{" "}
                                                        ID
                                                    </label>
                                                    <button
                                                        type="button"
                                                        className="aha-icon-help measure-help btn btn-text"
                                                        data-container="#ems-id"
                                                        data-toggle="tooltip"
                                                        data-html="true"
                                                        title="<div class='emsprogram-popover'> <p>Please enter the <span aria-hidden='true'>AHA EMS</span>&nbsp;<span class='sr-only'>A H A E M S</span><span aria-hidden='true'>ID</span>&nbsp;<span class='sr-only'>I D</span>for your agency. If your agency’s <span aria-hidden='true'>AHA EMS</span>&nbsp;<span class='sr-only'>A H A E M S</span><span aria-hidden='true'>ID</span>&nbsp;<span class='sr-only'>I D</span>is not found, or you do not know your agency’s <span aria-hidden='true'>AHAEMS</span>&nbsp;<span class='sr-only'>A H A E M S</span><span aria-hidden='true'>ID</span><span class='sr-only'>I D</span>, please select <b>‘Not Listed’</b>.</p> </div>"
                                                    >
                                                        <span className="sr-only">
                                                            American Heart
                                                            Association ( A H A
                                                            ) E M S ID Help text
                                                        </span>
                                                    </button>
                                                </div>
                                                <div className="col-md-7">
                                                    <SearchDropdown
                                                        id="emsId"
                                                        items={allEmsIds}
                                                        selectedValue={
                                                            selectedEMSId
                                                        }
                                                        disabled={
                                                            ahaEMSIdLoadingInd
                                                        }
                                                        isLoading={
                                                            ahaEMSIdLoadingInd
                                                        }
                                                        callParentOnSelect={(
                                                            value: any
                                                        ) => {
                                                            setEMSData(value);
                                                        }}
                                                        label="Select EMS ID"
                                                        mandatoryInd
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group row required">
                                                <div
                                                    className="col-md-5 form-label emsprolabel"
                                                    id="state-number"
                                                >
                                                    <label
                                                        htmlFor="stateNumber"
                                                        id="stateNumber-label"
                                                        className="d-inline"
                                                    >
                                                        <span aria-hidden="true">
                                                            EMS{" "}
                                                        </span>
                                                        <span className="sr-only">
                                                            E M S{" "}
                                                        </span>{" "}
                                                        Agency State
                                                        Identification Number
                                                    </label>
                                                    <button
                                                        data-container="#state-number"
                                                        type="button"
                                                        className="aha-icon-help measure-help btn btn-text"
                                                        data-toggle="tooltip"
                                                        data-html="true"
                                                        title="<div class='emsprogram-popover'> <p>Please enter the state licensure number of your agency.</p> </div>"
                                                    >
                                                        <div className="sr-only">
                                                            <span aria-hidden="true">
                                                                EMS{" "}
                                                            </span>
                                                            <span className="sr-only">
                                                                E M S{" "}
                                                            </span>{" "}
                                                            Agency State
                                                            Identification
                                                            Number Help text
                                                        </div>
                                                    </button>
                                                </div>
                                                <div className="col-md-7">
                                                    <input
                                                        minLength={2}
                                                        maxLength={50}
                                                        defaultValue={
                                                            hospitalProgramInfo.stateNumber ||
                                                            ""
                                                        }
                                                        onChange={handleChange}
                                                        type="String"
                                                        pattern={
                                                            CONSTANTS.REGEX_ALLOW_ALPHANUMERIC_SPECIALCHARS
                                                        }
                                                        className="form-control"
                                                        name="stateNumber"
                                                        id="stateNumber"
                                                        required
                                                        aria-describedby="stateNumber"
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group row required">
                                                <div className="col-md-5 form-label">
                                                    <label
                                                        htmlFor="state-associated-ID"
                                                        id="state-associated-ID-label"
                                                    >
                                                        State associated with
                                                        State ID above
                                                    </label>
                                                </div>
                                                <div className="col-md-7">
                                                    {hospitalProgramInfo.countryCode ==
                                                    CONSTANTS.DOMESTIC_COUNTRY_CODE ? (
                                                        <SearchDropdown
                                                            id="state-associated-ID"
                                                            items={states}
                                                            selectedValue={
                                                                hospitalProgramInfo.state
                                                            }
                                                            callParentOnSelect={(
                                                                value: any
                                                            ) =>
                                                                setHospitalProgramInfo(
                                                                    {
                                                                        ...hospitalProgramInfo,
                                                                        state: value,
                                                                    }
                                                                )
                                                            }
                                                            label="State associated with State ID"
                                                            mandatoryInd
                                                        />
                                                    ) : (
                                                        <>
                                                            <input
                                                                pattern={
                                                                    CONSTANTS.REGEX_ALLOW_ALPHABHET_SPACE
                                                                }
                                                                type="text"
                                                                className="form-control"
                                                                name="stateID"
                                                                id="stateID"
                                                                onChange={
                                                                    handleChange
                                                                }
                                                                value={
                                                                    hospitalProgramInfo.state
                                                                }
                                                                required
                                                                aria-describedby="state-ID"
                                                            />
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </>
                                    ) : null}
                                    <DynamicForm
                                        disabled={disabled}
                                        goBack={goBack}
                                        onChange={updateProgramDetails}
                                        className=""
                                        model={programDetails}
                                        onSubmit={onSubmit}
                                    />

                                    {/* common itesm start */}
                                    {registryQuestion &&
                                        params.programId != 22 &&
                                        hideFormInputs == false &&
                                        programDetails.length > 0 && (
                                            <div
                                                className="form-group row required"
                                                role="radiogroup"
                                                aria-labelledby="isRegistryUsing"
                                            >
                                                <div className="col-md-5 form-label">
                                                    <label
                                                        id="isRegistryUsing"
                                                        className="col-form-label label-form"
                                                    >
                                                        {wordChange(
                                                            registryQuestion
                                                        )}
                                                        <sup>*</sup>
                                                    </label>
                                                </div>
                                                <div className="col-md-7 d-flex align-items-center">
                                                    <div
                                                        className="row w-100 flex-grow-1 mb-n3 ml-n5"
                                                        role="radiogroup"
                                                    >
                                                        <div className="mb-3 ml-5">
                                                            <div className="form-radio">
                                                                <input
                                                                    name="isRegistryUsing"
                                                                    onChange={
                                                                        handleRegistryChange
                                                                    }
                                                                    defaultChecked={
                                                                        registryData &&
                                                                        registryData?.isRegistryUsing ==
                                                                            "YES"
                                                                    }
                                                                    defaultValue="YES"
                                                                    type="radio"
                                                                    className="form-control"
                                                                    id="registryYes"
                                                                    required
                                                                />
                                                                <label
                                                                    htmlFor="registryYes"
                                                                    id="yes-record"
                                                                >
                                                                    Yes
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="mb-3 ml-5">
                                                            <div className="form-radio">
                                                                <input
                                                                    name="isRegistryUsing"
                                                                    onChange={
                                                                        handleRegistryChange
                                                                    }
                                                                    defaultChecked={
                                                                        registryData &&
                                                                        registryData?.isRegistryUsing ==
                                                                            "NO"
                                                                    }
                                                                    defaultValue="NO"
                                                                    type="radio"
                                                                    className="form-control"
                                                                    id="registryNo"
                                                                    required
                                                                />
                                                                <label
                                                                    htmlFor="registryNo"
                                                                    id="no-record"
                                                                >
                                                                    No
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    {registryQuestion &&
                                        params.programId != 22 &&
                                        registryData &&
                                        registryData.isRegistryUsing ==
                                            "YES" && (
                                            <div>
                                                <div className="form-group row required">
                                                    <div className="col-md-5 form-label">
                                                        <label
                                                            htmlFor="registryName"
                                                            id="registryName-label"
                                                            className="col-form-label label-form"
                                                        >
                                                            Please select the
                                                            Registry name
                                                            <sup>*</sup>
                                                        </label>
                                                    </div>
                                                    <div className="col-md-7 d-flex align-items-center flex-column">
                                                        <div className="w-100">
                                                            <SearchDropdown
                                                                id="registryName"
                                                                items={
                                                                    registryData
                                                                        ? registryData.registries
                                                                        : []
                                                                }
                                                                selectedValue={
                                                                    registryData &&
                                                                    registryData.registryName
                                                                }
                                                                callParentOnSelect={(
                                                                    value: any
                                                                ) => {
                                                                    setRegistryData(
                                                                        {
                                                                            ...registryData,
                                                                            registryName:
                                                                                value,
                                                                        }
                                                                    );
                                                                }}
                                                                label="Select Registry"
                                                                placeholder="Select"
                                                                mandatoryInd
                                                            />
                                                        </div>
                                                        {registryData &&
                                                            registryData.registryName &&
                                                            registryData.registryName !==
                                                                CONSTANTS.REGISTRY_OTHER && (
                                                                <div className="w-100 reg-authorise">
                                                                    <div className="form-group form-check m-0">
                                                                        <input
                                                                            required
                                                                            type="checkbox"
                                                                            value=""
                                                                            id="reg-authorise"
                                                                            defaultChecked={
                                                                                registryCheckboxStatus
                                                                            }
                                                                        />
                                                                        <label htmlFor="reg-authorise">
                                                                            I
                                                                            authorize
                                                                            this
                                                                            registry
                                                                            to
                                                                            submit
                                                                            required
                                                                            data
                                                                            on
                                                                            the
                                                                            organization&#39;s
                                                                            behalf
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            )}
                                                    </div>
                                                </div>
                                                {registryData &&
                                                    registryData.registryName ==
                                                        CONSTANTS.REGISTRY_OTHER && (
                                                        <div className="form-group row required">
                                                            <div className="col-md-5 form-label">
                                                                <label
                                                                    htmlFor="customRegistryName"
                                                                    className="col-form-label label-form"
                                                                >
                                                                    Please
                                                                    specify the
                                                                    Registry
                                                                    name
                                                                    <sup>*</sup>
                                                                </label>
                                                            </div>
                                                            <div className="col-md-7 d-flex align-items-center">
                                                                <input
                                                                    title={
                                                                        CONSTANTS.ENTER_ALPHANUMERIC_TEXT
                                                                    }
                                                                    name="customRegistryName"
                                                                    onChange={
                                                                        handleRegistryChange
                                                                    }
                                                                    defaultValue={
                                                                        registryData &&
                                                                        registryData.customRegistryName
                                                                    }
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="customRegistryName"
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                            </div>
                                        )}
                                    {corpQuestionInd && corporationQuestion && (
                                        <div
                                            className="form-group row required"
                                            role="group"
                                            aria-labelledby="isCorporationUsing"
                                        >
                                            <div className="col-md-5 form-label">
                                                <label
                                                    id="isCorporationUsing"
                                                    className="col-form-label label-form"
                                                >
                                                    {corporationQuestion}
                                                    <sup>*</sup>
                                                </label>
                                            </div>
                                            <div className="col-md-7 d-flex align-items-center">
                                                <div
                                                    className="row w-100 flex-grow-1 mb-n3 ml-n5"
                                                    role="radiogroup"
                                                >
                                                    <div className="mb-3 ml-5">
                                                        <div className="form-radio">
                                                            <input
                                                                name="isCorporationUsing"
                                                                onChange={
                                                                    handleCorporationChange
                                                                }
                                                                checked={
                                                                    isCorporationUsing ==
                                                                    "YES"
                                                                }
                                                                defaultValue="YES"
                                                                type="radio"
                                                                className="form-control"
                                                                id="corporationYes"
                                                                required
                                                            />
                                                            <label
                                                                htmlFor="corporationYes"
                                                                id="yes-record"
                                                            >
                                                                Yes
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="mb-3 ml-5">
                                                        <div className="form-radio">
                                                            <input
                                                                name="isCorporationUsing"
                                                                onChange={
                                                                    handleCorporationChange
                                                                }
                                                                defaultChecked={
                                                                    isCorporationUsing ==
                                                                    "NO"
                                                                }
                                                                defaultValue="NO"
                                                                type="radio"
                                                                className="form-control"
                                                                id="corporationNo"
                                                                required
                                                            />
                                                            <label
                                                                htmlFor="corporationNo"
                                                                id="no-record"
                                                            >
                                                                No
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {corpQuestionInd &&
                                        corporationQuestion &&
                                        corporationData &&
                                        isCorporationUsing == "YES" && (
                                            <div>
                                                <div className="form-group row required">
                                                    <div className="col-md-5 form-label">
                                                        <label
                                                            htmlFor="corporationName"
                                                            id="corporationName-label"
                                                            className="col-form-label label-form"
                                                        >
                                                            If Yes, Please
                                                            select the
                                                            Corporation
                                                            <sup>*</sup>
                                                        </label>
                                                    </div>
                                                    <div className="col-md-7 d-flex align-items-center flex-column">
                                                        <div className="w-100">
                                                            <SearchDropdown
                                                                id="corporationName"
                                                                items={
                                                                    corporationData?.corporations
                                                                        ? corporationData.corporations
                                                                        : []
                                                                }
                                                                selectedValue={
                                                                    corporationData &&
                                                                    corporationData.corporationName
                                                                }
                                                                disabled={
                                                                    loadingInd
                                                                }
                                                                isLoading={
                                                                    loadingInd
                                                                }
                                                                callParentOnSelect={(
                                                                    value: any
                                                                ) => {
                                                                    setCorporationData(
                                                                        {
                                                                            ...corporationData,
                                                                            corporationName:
                                                                                value,
                                                                        }
                                                                    );
                                                                }}
                                                                label="Select Corporation"
                                                                placeholder="Select"
                                                                mandatoryInd
                                                            />
                                                        </div>
                                                        {corporationData &&
                                                            corporationData.corporationName && (
                                                                <div className="w-100 reg-authorise">
                                                                    <div className="form-group form-check m-0">
                                                                        <input
                                                                            required
                                                                            type="checkbox"
                                                                            value=""
                                                                            id="corporation-authorise"
                                                                            defaultChecked={
                                                                                corporationCheckboxStatus
                                                                            }
                                                                        />
                                                                        <label htmlFor="corporation-authorise">
                                                                            I
                                                                            authorize
                                                                            this
                                                                            Corporation
                                                                            to
                                                                            submit
                                                                            documents,
                                                                            data,
                                                                            and
                                                                            make
                                                                            payments
                                                                            on
                                                                            the
                                                                            organization&#39;s
                                                                            behalf
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            )}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    {/* common itesm ends */}

                                    <div className="reg-btn row mx-0">
                                        <div className="col-md-6 offset-md-5 px-0 px-md-3 d-flex flex-column flex-md-row">
                                            <button
                                                type="button"
                                                onClick={goBack}
                                                className="btn btn-round btn-secondary mr-0 mr-md-3 mb-4 mb-md-0"
                                                aria-label="back to Program selection page"
                                            >
                                                Back
                                            </button>
                                            <button
                                                type="submit"
                                                className="btn btn-round btn-primary ml-0 ml-md-3}"
                                                aria-label="Continue to Agreement page"
                                            >
                                                Continue
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </HospitalDetail>
    );
};

export default HospitalProgramPage;
