import styled from "styled-components";

export const ProgressWrapper = styled.div`
    .progress-welcome {
        padding: 120px 0;
        @media only screen and (min-width: 576px) {
            padding: 180px 0;
        }
        * {
            color: #222328;
        }
        .progress-welcome-title {
            order: 2;
            @media only screen and (min-width: 576px) {
                order: 1;
            }
        }
        .progress-img {
            width: 95px;
            order: 1;
            @media only screen and (min-width: 576px) {
                width: 100px;
                order: 2;
            }
            @media only screen and (min-width: 768px) {
                width: 150px;
            }
        }
    }
    .progress-title {
        margin: 71px 0;
    }
    .progress-list {
        list-style-type: none;
        padding: 0;
        margin-bottom: 199px;
        .progress-item:last-child {
            .prg-icon {
                &:before {
                    display: none;
                }
            }
        }
    }
    .progress-item {
        width: 108px;
        font-size: 20px;
        margin: 0 20%;
        position: relative;
        @media only screen and (min-width: 576px) {
            margin: 0 10%;
        }
        @media only screen and (min-width: 768px) {
            margin: 0 45px;
        }
        @media only screen and (min-width: 992px) {
            margin: 0 79px;
        }
        @media only screen and (min-width: 1024px) {
            margin: 0 89px;
        }
    }
    .prg-icon {
        width: 108px;
        height: 108px;
        border: 1px solid #bcc3ca;
        border-radius: 100%;
        color: #6d6e71;
        margin-bottom: 18px;
        background-color: #fff;
        position: relative;
        &:before {
            display: none;
            content: "";
            height: 2px;
            width: 180px;
            background-color: #c10e21;
            position: absolute;
            left: 107px;
            top: 50%;
            @media only screen and (min-width: 769px) {
                display: block;
            }
        }
        i {
            font-size: 34px;
        }
    }
    .prg-spin {
        &:before,
        &:after {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            border-radius: 100%;
            border: 3px solid transparent;
        }
    }
    .prg-complete {
        .prg-spin {
            background-color: rgba(2, 138, 11, 0.73);
            transition: background-color 0.5s ease 0.3s;
            position: absolute;
            height: 108px;
            width: 108px;
            border-radius: 100%;
            top: 0;
            left: 0;
            &:before {
                border-top-color: #c10e21;
                border-right-color: #c10e21;
                border-bottom-color: #c10e21;
                transition: border-top-color 0.15s linear,
                    border-right-color 0.15s linear 0.1s,
                    border-bottom-color 0.15s linear 0.2s;
            }
            &:after {
                border-top: 3px solid #c10e21;
                border-left-width: 3px;
                border-right-width: 3px;
                transform: rotate(270deg);
                transition: transform 0.4s linear 0s,
                    border-left-width 0s linear 0.35s,
                    -webkit-transform 0.4s linear 0s;
            }
            .prg-checkmark {
                display: block;
            }
        }
    }

    .prg-checkmark {
        display: none;
        &:after {
            animation-duration: 1400ms;
            animation-timing-function: ease;
            animation-name: checkmark;
            transform: scaleX(-1) rotate(135deg);
            opacity: 1;
            height: 50px;
            width: 25px;
            transform-origin: left top;
            border-right: 7px solid #fff;
            border-top: 7px solid #fff;
            content: "";
            left: 28px;
            top: 58px;
            position: absolute;
        }
    }
    @keyframes checkmark {
        0% {
            height: 0;
            width: 0;
            opacity: 0;
        }
        60% {
            height: 0;
            width: 0;
            opacity: 0;
        }
        70% {
            height: 0;
            width: 25px;
            opacity: 1;
        }
        90% {
            height: 50px;
            width: 25px;
            opacity: 1;
        }
        100% {
            height: 50px;
            width: 25px;
            opacity: 1;
        }
    }
`;
