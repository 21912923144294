import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import CONSTANTS from "common/constants";

export interface ISearchFilter {
    searchKey: string;
    programCategory: any;
    countryCode: any;
    stateCode: any;
    sortOrganizations: any;
}

export const initialState: ISearchFilter = {
    searchKey: "",
    programCategory: CONSTANTS.ALL,
    countryCode: CONSTANTS.ALL,
    stateCode: CONSTANTS.ALL,
    sortOrganizations: CONSTANTS.ALL,
};

const searchKeySlice = createSlice({
    name: "searchKey",
    initialState,
    reducers: {
        setSearchKey: (state, action: PayloadAction<string>) => {
            state.searchKey = action.payload;
        },
        setProgramCategoryId: (state, action: PayloadAction<string>) => {
            state.programCategory = action.payload;
        },
        setCountryCode: (state, action: PayloadAction<string>) => {
            state.countryCode = action.payload;
        },
        setStateCode: (state, action: PayloadAction<string>) => {
            state.stateCode = action.payload;
        },
        setSortOrganizations: (state, action: PayloadAction<string>) => {
            state.sortOrganizations = action.payload;
        },
    },
});
const { actions, reducer } = searchKeySlice;

export const {
    setSearchKey,
    setProgramCategoryId,
    setCountryCode,
    setStateCode,
    setSortOrganizations,
} = actions;
export default reducer;
