import React from "react";
import { Link } from "react-router-dom";

interface Props {
    activeTab: string;
}

const RegistryTabs = ({ activeTab }: Props) => {
    return (
        <div className="aui-global-nav">
            <div className="aui-menu-tabs">
                <div className="aui-menu-tabs-container">
                    <div className="navbar-expand-lg">
                        <div className="d-flex flex-row-reverse aui-global-menubtn">
                            <button
                                type="button"
                                className="navbar-toggler btn-mainmenu-sm collapsed"
                                data-toggle="collapse"
                                data-target="#dashboardItems"
                                aria-controls="navbarDashboard"
                                aria-expanded="false"
                                aria-label="main menu navigation"
                            >
                                Menu
                                <i
                                    className="aha-icon-arrow-down"
                                    aria-hidden="true"
                                />
                            </button>
                        </div>
                        <nav id="navbarDashboard">
                            <ul
                                id="dashboardItems"
                                className="collapse navbar-collapse aui-global-menu"
                            >
                                <li
                                    className={
                                        activeTab == "registryDashboard"
                                            ? "active"
                                            : ""
                                    }
                                >
                                    <Link
                                        to="/registry/dashboard"
                                        className="btn btn-text"
                                        role="menuitem"
                                        aria-current={
                                            activeTab == "registryDashboard"
                                                ? "page"
                                                : false
                                        }
                                    >
                                        Dashboard
                                    </Link>
                                </li>
                                <li
                                    className={
                                        activeTab == "organizationManagement"
                                            ? "active"
                                            : ""
                                    }
                                >
                                    <Link
                                        to="/registry/organization-management"
                                        className="btn btn-text"
                                        role="menuitem"
                                        aria-current={
                                            activeTab ==
                                            "organizationManagement"
                                                ? "page"
                                                : false
                                        }
                                    >
                                        Organizations Registered
                                    </Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RegistryTabs;
