import React, { createRef, useEffect, useState } from "react";
import { DropdownMenuWrapper } from "./styled";

interface Props {
    items: any;
    selectedValue: any;
    callParentOnSelect: any;
    id: string;
    disabled?: boolean;
    isLoading?: boolean;
    approveRejectInd?: boolean;
    emsInd?: boolean;
    ariaInnerLabelValue?: any;
    dropdownArrow?: any;
    dropDownItem?: any;
    hospital?: any;
}

const DropdownMenu = (props: Props) => {
    const {
        items,
        selectedValue,
        callParentOnSelect,
        id,
        disabled,
        isLoading,
        approveRejectInd,
        emsInd,
        ariaInnerLabelValue,
        dropdownArrow,
        dropDownItem,
    } = props;
    const [selectedItemName, setSelectedItemName] = useState<any>("");
    const [showItems, setShowItems] = useState<boolean>(false);
    const [focusOptionIndex, setFocusOptionIndex] = useState<number>(0);
    const refsArray = items?.map(() => createRef());
    const dropDownRef = createRef<HTMLButtonElement>();

    const updateSelectedValue = (value: any) => {
        if (value) {
            if (items && items.length > 0) {
                items.forEach((element: any, index: number) => {
                    if (element.value === value) {
                        setSelectedItemName(element.label);
                        setFocusOptionIndex(index);
                    }
                });
            }
        }
    };

    useEffect(() => {
        if (!emsInd) {
            setSelectedItemName(items[0]?.label);
        }
        setFocusOptionIndex(0);
        updateSelectedValue(selectedValue);
        const handleClickEvent = (e: any) => {
            if (
                !document
                    ?.getElementById(`dropdown-wrapper-${id}`)
                    ?.contains(e.target)
            ) {
                setShowItems(false); // Clicked outside the box - Close Dropdown
            }
        };
        document.addEventListener("click", handleClickEvent);
        return () => {
            document.removeEventListener("click", handleClickEvent);
        };
    }, [items, selectedValue, id]);

    useEffect(() => {
        if (showItems) {
            refsArray[focusOptionIndex].current.focus();
        }
    }, [showItems]);

    const toggleDropDown = () => {
        if (disabled) return;
        setShowItems(!showItems);
    };

    const selectOption = (event: any, value: any, index: number) => {
        let optionIndex: any = -1;
        const evt = document.getElementById(event.target.id);
        if (event.target.id !== null && evt !== null) {
            optionIndex =
                evt.getAttribute("data-index") !== null
                    ? evt.getAttribute("data-index")
                    : -1;
        }
        if (parseInt(optionIndex, 10) > -1 || index > -1) {
            !approveRejectInd && setFocusOptionIndex(index);
            dropDownRef.current?.focus();
        }
        if (!value) {
            items.forEach((item: any, i: number) => {
                if (i === focusOptionIndex) {
                    value = item.value;
                    setFocusOptionIndex(i);
                }
            });
        }
        callParentOnSelect(value);
        !approveRejectInd && updateSelectedValue(value); // To discplay in component
        setShowItems(false);
    };

    const upAndDownOption = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        if (event.keyCode === 27 || event.keyCode === 9) {
            // On Escape Toggle Dropdown
            dropDownRef && dropDownRef.current && dropDownRef.current.focus();
            toggleDropDown();
            return;
        }

        // On Key UP Arrow
        if (event.which === 38 && focusOptionIndex > 0) {
            const setIndex = focusOptionIndex - 1;
            setFocusOptionIndex(setIndex);
            refsArray[setIndex].current.focus();
        }
        // On Key Down Arrow
        if (event.which === 40 && focusOptionIndex < items.length - 1) {
            const setIndex = focusOptionIndex + 1;
            setFocusOptionIndex(setIndex);
            refsArray[setIndex].current.focus();
        }
        // On Enter
        if (event.which === 13) {
            selectOption(event, undefined, -1);
        }

        // On Home
        if (event.keyCode === 36) {
            setFocusOptionIndex(0);
            refsArray[0].current.focus();
        }

        // On End
        if (event.keyCode === 35) {
            setFocusOptionIndex(refsArray.length - 1);
            refsArray[refsArray.length - 1].current.focus();
        }
    };

    return (
        <DropdownMenuWrapper>
            <div
                id={`dropdown-wrapper-${id}`}
                className={`menu-cst-select ${
                    disabled ? "menu-cst-select-disabled" : ""
                } ${isLoading ? "menu-cst-select-loading" : ""}`}
            >
                {" "}
                {props.ariaInnerLabelValue && (
                    <span id={`${id}-label`} className="sr-only">
                        {ariaInnerLabelValue}
                    </span>
                )}
                <div className="menu-cst-select-fld" id={`${id}-box`}>
                    <button
                        ref={dropDownRef}
                        type="button"
                        // className="menu-cst-selected-item"
                        className={`menu-cst-selected-item ${
                            dropdownArrow ? "menu-cst-selected-arrow" : ""
                        }`}
                        id={id}
                        aria-expanded={!!showItems}
                        onClick={() => toggleDropDown()}
                        aria-labelledby={`${id}-label ${id}`}
                        {...(disabled ? { disabled: true } : {})}
                        aria-roledescription="dropdown"
                    >
                        {dropDownItem}
                        <span className="menu-cst-select-required" />
                    </button>
                    {dropdownArrow && (
                        <div
                            className={`menu-cst-arrow aha-icon-arrow-down ${
                                showItems
                                    ? "menu-cst-arrow-up"
                                    : "menu-cst-arrow-down"
                            }`}
                            aria-hidden="true"
                        />
                    )}
                </div>
                <ul
                    style={{ display: showItems ? "block" : "none" }}
                    className="menu-cst-select-dropdown"
                    tabIndex={-1}
                    role="listbox"
                    aria-labelledby={`${id}-label`}
                    aria-expanded={!!showItems}
                    id={`${id}-select-dropdown`}
                    aria-activedescendant={
                        items[0]?.label
                            ? `${items[focusOptionIndex]?.label
                                  .toString()
                                  .replaceAll(" ", "")}-${id}`
                            : ``
                    }
                >
                    {items.map((item: any, index: number) => (
                        <li
                            key={item.label}
                            data-index={index}
                            id={`${item.label
                                .toString()
                                .replaceAll(" ", "")}-${id}`}
                            onClick={(event) =>
                                selectOption(event, item.value, index)
                            }
                            className={`menu-cst-item ${
                                approveRejectInd && item.disableInd
                                    ? "menu-cst-item-disabled"
                                    : focusOptionIndex === index
                                    ? "menu-cst-item-selected"
                                    : ""
                            }`}
                            aria-selected={focusOptionIndex === index}
                            onKeyDown={upAndDownOption}
                            ref={refsArray[index]}
                            role="option"
                            tabIndex={0}
                            aria-label={item?.ariaLabel ? item.ariaLabel : ""}
                            aria-disabled={
                                approveRejectInd && item.disableInd
                                    ? "true"
                                    : "false"
                            }
                        >
                            <i className={item.className} aria-hidden="true" />
                            {approveRejectInd ? item.dropdownLabel : item.label}
                        </li>
                    ))}
                </ul>
            </div>
        </DropdownMenuWrapper>
    );
};

export default DropdownMenu;
