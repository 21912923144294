import CONSTANTS from "common/constants";
import { Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
    calculatNumOfDaysBetweenDates,
    expireDataRowStatus,
    getCurrentQuarter,
    hospitalApprovalStatus,
    localDateToYYYYMMDDFormatString,
    wordSplit,
} from "common/utils";
import SortTableColumnButton from "components/SortTableColumnButton";
import React, { useState } from "react";
import ExpireIndicator from "components/ExpireIndicator";
import { HospitalTableWrapper } from "./styled";
import {
    setQuarter,
    setYear,
} from "../MeasuresDateFilter/measuresDateFilter.slice";
import "styles/expire-tooltip.scss";

interface Props {
    tab: string;
    pageNumber: number;
    hospitals: any;
    actionURL: string;
    isTableLoading: boolean;
    isActive: boolean;
    isDeactivated: boolean;
    setSortInd: any;
    sortInd: any;
    close: any;
}

export const HospitalCertifiedTable = ({
    tab,
    pageNumber,
    hospitals,
    actionURL,
    isTableLoading,
    isActive,
    isDeactivated,
    setSortInd,
    sortInd,
    close,
}: Props) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const checkIsExpierSoon = (
        expiresOn: any,
        programId: any,
        hospitalId: any
    ) => {
        if (!expiresOn) {
            return "";
        }
        return (
            <div>
                <ExpireIndicator
                    expiresOn={expiresOn}
                    programId={programId}
                    hospitalId={hospitalId}
                />
            </div>
        );
    };

    return (
        <HospitalTableWrapper className="hospital-certified-table mt-3 w-100">
            <table
                className={`aui-responsive-status-table ${
                    isTableLoading ? CONSTANTS.TABLE_SPINNER : ""
                }`}
            >
                <thead>
                    <tr>
                        <th scope="col">
                            <div className="aui-th justify-content-between">
                                Organization Details
                                <div className="ml-2" />
                            </div>
                        </th>
                        <th scope="col">
                            <div className="aui-th">Program Type</div>
                        </th>
                        <th scope="col">
                            <div className="aui-th">Location</div>
                        </th>
                        <th scope="col">
                            <div className="aui-th">State</div>
                        </th>
                        <th scope="col">
                            <div className="aui-th">Granted On</div>
                        </th>
                        <th scope="col">
                            <div className="aui-th">Expires On</div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {hospitals.length > 0 &&
                        hospitals.map((hospital: any) => {
                            return (
                                <tr
                                    key={hospital.hospitalId}
                                    className={expireDataRowStatus(
                                        hospital.expiresOn,
                                        tab
                                    )}
                                >
                                    <td data-title="Organization Details">
                                        <div className="aui-td">
                                            <div>
                                                {location?.state
                                                    ?.deactivateInd === true &&
                                                location?.state?.hospitalInd ===
                                                    hospital.hospitalId ? (
                                                    hospital.hospitalName
                                                ) : (
                                                    <Link
                                                        to={{
                                                            pathname: `${actionURL}${hospital.hospitalId}/program/${hospital.programsId}/category/${hospital.categoryId}`,
                                                            state: {
                                                                backToFaciclityManagement:
                                                                    "/admin/organization-management",
                                                                adminfacilityMangemnetTab:
                                                                    tab,
                                                                adminfacilityMangemnetPage:
                                                                    pageNumber,
                                                                pageSort:
                                                                    sortInd,
                                                                facilityName:
                                                                    hospital.hospitalName,
                                                                deactivateInd:
                                                                    false,
                                                                hospitalInd:
                                                                    hospital.hospitalId,
                                                            },
                                                        }}
                                                        onClick={() => {
                                                            dispatch(
                                                                setYear(
                                                                    new Date().getFullYear()
                                                                )
                                                            );
                                                            dispatch(
                                                                setQuarter(
                                                                    getCurrentQuarter()
                                                                )
                                                            );
                                                            localStorage.setItem(
                                                                "resetFilter",
                                                                "false"
                                                            );
                                                            localStorage.setItem(
                                                                "certificateExpireOn",
                                                                hospital.expiresOn
                                                            );
                                                        }}
                                                        className="btn-text-link-uline"
                                                        aria-label={`View ${hospital.hospitalName} details`}
                                                    >
                                                        {hospital.hospitalName}
                                                    </Link>
                                                )}
                                                <div className="mt-1">
                                                    Organization Code:{" "}
                                                    <span className="abbr-code">
                                                        {wordSplit(
                                                            hospital.facilityCode
                                                        )}
                                                    </span>
                                                </div>
                                                {checkIsExpierSoon(
                                                    hospital.expiresOn,
                                                    hospital.programsId,
                                                    hospital.hospitalId
                                                )}
                                            </div>
                                        </div>
                                    </td>
                                    <td data-title="Program Type">
                                        <div className="aui-td">
                                            {hospital.categoryName}
                                        </div>
                                    </td>
                                    <td data-title="Country">
                                        <div className="aui-td">
                                            {hospital.countryName}
                                        </div>
                                    </td>
                                    <td data-title="State">
                                        <div className="aui-td">
                                            {hospital.stateName}
                                        </div>
                                    </td>
                                    <td data-title="Granted On">
                                        <div className="aui-td">
                                            {localDateToYYYYMMDDFormatString(
                                                hospital.grantedOn
                                            )}
                                        </div>
                                    </td>
                                    <td data-title="Expires On">
                                        <div className="aui-td">
                                            {localDateToYYYYMMDDFormatString(
                                                hospital.expiresOn
                                            )}
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                </tbody>
            </table>
            <div
                role="alert"
                aria-atomic="true"
                className="table-emptystate-outer"
            >
                {hospitals.length < 1 && (
                    <div>
                        No organizations match your search.
                        <br />
                        Please try modifying your search criteria
                    </div>
                )}
            </div>
        </HospitalTableWrapper>
    );
};

export default HospitalCertifiedTable;
