import React, { useEffect, useState } from "react";
import RegistryBanner from "components/RegistryBanner";
import { useDispatch } from "react-redux";
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import { AdminRegistryFacilitiesTable } from "components/AdminRegistryFacilitiesTable";
import Pagination, { IPagination } from "components/Pagination";
import { getRegistryById, getRegistryHospitals } from "api/registryAPI";
import TableRowCount from "components/TableRowCount";
import { logger } from "../../../utils/logger.utils";
import { FacilitiesListingWrapper } from "./styled";

interface Props {
    registryId: any;
    pageNumber: number | null;
    returnPath: any;
    editMode: boolean;
}

const ProgramFacilitiesRegistered = (props: Props) => {
    const [registry, setRegistry] = useState<any>({});
    const [facilities, setFacilities] = useState<any>([]);
    const [pageNumber, setPageNumber] = useState<number>(
        props.pageNumber ? props.pageNumber : 1
    );
    const paginationSetLimit: number = 5;

    const defaultPagination: IPagination = {
        pageNumber: 1,
        pageSize: 10,
        isFirst: true,
        isLast: false,
        setPageNumber,
        paginationSetLimit,
    };
    const [pagination, setPagination] =
        useState<IPagination>(defaultPagination);
    const [roleValue, setRoleValue] = useState<string>("none");
    const [mounted, setMounted] = useState<boolean>(false);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setPageLoadingStatus({ isPageLoading: true }));
        getRegistryById(props.registryId)
            .then((registryResponse: any) => {
                dispatch(setPageLoadingStatus({ isPageLoading: false }));
                if (registryResponse.success) {
                    setRegistry(registryResponse.data.registry);
                }
            })
            .catch((error) => {
                logger(error);
                dispatch(setPageLoadingStatus({ isPageLoading: false }));
            });

        getRegistryHospitals(
            props.registryId,
            pagination.pageSize,
            pageNumber,
            false,
            "createdAt,DESC"
        )
            .then((registryResponse: any) => {
                if (registryResponse.success) {
                    setFacilities(registryResponse.data.hospitals);
                    setPagination({
                        ...registryResponse.data._pagination,
                        setPageNumber,
                    });
                }
                if (mounted) {
                    setRoleValue("alert");
                }
                setMounted(true);
            })
            .catch((error) => {
                logger(error);
            });
    }, [pageNumber, props.editMode]);

    return (
        <FacilitiesListingWrapper>
            <div>
                <h3 className="h3 mb-4">Organizations Registered</h3>
                <TableRowCount
                    isFirst={pagination.isFirst}
                    pageNumber={pagination.pageNumber}
                    pageSize={pagination.pageSize}
                    isLast={pagination.isLast}
                    totalCount={pagination.totalCount}
                    roleValue={roleValue}
                />
                <AdminRegistryFacilitiesTable
                    facilities={facilities}
                    baseActionURL="/admin/organization-management/organization-details"
                    registryId={props.registryId}
                    pageNumber={pageNumber}
                    returnPath={props.returnPath}
                    registryName={registry.registryName}
                />
            </div>

            {pagination && Number(pagination.totalPages) > 0 ? (
                <Pagination
                    pageNumber={pagination.pageNumber}
                    pageSize={pagination.pageSize}
                    totalCount={pagination.totalCount}
                    totalPages={pagination.totalPages}
                    isFirst={pagination.isFirst}
                    isLast={pagination.isLast}
                    setPageNumber={pagination.setPageNumber}
                    paginationSetLimit={paginationSetLimit}
                />
            ) : null}
        </FacilitiesListingWrapper>
    );
};

export default ProgramFacilitiesRegistered;
