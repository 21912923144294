import store from "app/store";
import { validJSON } from "common/utils";
import { showToast } from "components/Toast/toast.slice";
import CONSTANTS from "common/constants";
import APIUtils from "utils/API.utils";
import config from "../config";

const API = new APIUtils();

export const getRegistryById = async (id: number): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const APIRes = await API.GET(
                `${
                    config[config.env].apiEndpoints.accounts
                }/registries/${Number(id)}`
            );

            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const getRegistries = async (
    tab: string,
    pageNumber: number,
    pageSize: number
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            let orderBy: string = "activatedDate";
            if (tab == CONSTANTS.INACTIVE) {
                tab = `${CONSTANTS.INACTIVE},${CONSTANTS.PENDING}`;
                orderBy = "updatedAt";
            }
            const APIRes = await API.GET(
                `${
                    config[config.env].apiEndpoints.accounts
                }/registries?activationStatus=${tab}&orderBy=${orderBy},DESC&pageSize=${pageSize}&pageNumber=${pageNumber}`
            );

            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const activateOrDeactivateRegistry = async (
    registeryObj: any
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const reqBody = registeryObj;

            const APIRes = await API.PATCH(
                `${
                    config[config.env].apiEndpoints.accounts
                }/admin/verify/registry`,
                reqBody
            );

            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const getRegistryHospitals = async (
    registryId: number,
    pageSize: number,
    pageNumber: number,
    distinctHospital: boolean = true,
    orderBy: string = "hospitalName,ASC"
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const APIRes = await API.GET(
                `${
                    config[config.env].apiEndpoints.accounts
                }/hospitalRegistryPrograms/registries/${registryId}/hospitals?distinctHospital=${distinctHospital}&orderBy=${orderBy},ASC&pageSize=${pageSize}&pageNumber=${pageNumber}`
            );

            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const getRegistryHospitalPrograms = async (
    hospitalId: number
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const APIRes = await API.GET(
                `${
                    config[config.env].apiEndpoints.accounts
                }/hospitalRegistryPrograms/hospitals/${hospitalId}/categories`
            );

            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const saveRegistry = async (registry: any, isUpdate: boolean) => {
    if (!localStorage.getItem("userAccessToken")) {
        return false;
    }
    const myHeaders = new Headers();
    myHeaders.append(
        "authorization",
        localStorage.getItem("userAccessToken") || ""
    );
    myHeaders.append("Content-Type", "application/json");
    const { id } = registry;
    delete registry.id;
    const raw = JSON.stringify(registry);
    const requestOptions = {
        method: isUpdate ? "PATCH" : "POST",
        headers: myHeaders,
        body: raw,
    };
    let url = `${config[config.env].apiEndpoints.accounts}/registries`;
    if (isUpdate) {
        url = `${url}/${id}`;
    }

    return new Promise((resolve, reject) => {
        fetch(url, requestOptions)
            .then((response) => response.text())
            .then((result) => {
                if (validJSON(result)) {
                    const addRegistryResponse = JSON.parse(result);
                    if (addRegistryResponse.success) {
                        resolve(addRegistryResponse);
                    } else {
                        throw addRegistryResponse.error;
                    }
                } else {
                    throw new Error("Invalid json string in the response");
                }
            })
            .catch((error) => {
                const err: any = error;
                store.dispatch(showToast(err));
                reject(error);
            });
    });
};
