import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Sidebar from "../../Admin/Sidebar/Sidebar";
import { CorporateManagementWrapper } from "./styled";
import "../../styles/styles.scss";
import CorporationTable from "components/CorporationTable";
import { getCorporations } from "../../../api/corporationAPI";
import CONSTANTS from "../../../common/constants";
import Pagination, { IPagination } from "../../../components/Pagination";

export const CorporationManagement = () => {
    const location = useLocation();
    const tabType = location.state?.t;
    const page = location.state?.p;
    const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
    const [tab, setTab] = useState<string>(tabType || CONSTANTS.ACTIVE);
    const [pageNumber, setPageNumber] = useState<number>(
        page ? Number(page) : 1
    );
    const paginationSetLimit: number = 5;
    const defaultPagination: IPagination = {
        pageNumber: 1,
        pageSize: 25,
        isFirst: true,
        isLast: false,
        setPageNumber,
        paginationSetLimit,
    };
    const [pagination, setPagination] =
        useState<IPagination>(defaultPagination);
    const [activeCorporations, setActiveCorporations] = useState<any>([]);
    const [inactiveCorporations, setInactiveCorporations] = useState<any>([]);
    const [roleValue, setRoleValue] = useState<string>("none");
    const [mounted, setMounted] = useState<boolean>(false);

    useEffect(() => {
        setIsTableLoading(true);
        localStorage.setItem("resetFilter", "true");
        getCorporations(tab, pageNumber, pagination.pageSize).then(
            (response: any) => {
                if (response.success && response.statusCode === 200) {
                    if (tab === CONSTANTS.ACTIVE) {
                        setActiveCorporations(response.data.corporations);
                    } else {
                        setInactiveCorporations(response.data.corporations);
                    }
                    setPagination({
                        ...response.data._pagination,
                        setPageNumber,
                    });
                }
                setIsTableLoading(false);
                if (mounted) {
                    setRoleValue("alert");
                }
                setMounted(true);
            }
        );
        window.scrollTo(0, 0);
    }, [tab, pageNumber]);

    return (
        <CorporateManagementWrapper className="row no-gutters w-100 flex-grow-1">
            <Sidebar activeTab="corporateManagement" />
            <section className="d-flex admin-main-content container">
                <div className="row no-gutters w-100 admin-main">
                    <div className="col-12">
                        <div className="d-md-flex justify-content-md-between corp-magnt">
                            <h1 className="h2 font-400 mb-0">
                                Corporation Management
                            </h1>
                            <Link
                                to={{
                                    pathname:
                                        "/corporation/corporation-management/corporation-details/add-corporation/new-corporation",
                                    state: { bT: tab, bP: pageNumber },
                                }}
                                className="btn btn-primary btn-round mt-4 mt-md-0"
                                aria-label="Add New Corporation"
                            >
                                <span aria-hidden="true">Add +</span>
                                <span className="sr-only">
                                    Add new Corporation
                                </span>
                            </Link>
                        </div>
                        <div className="corporation-tabs">
                            <div
                                className="d-flex flex-wrap aui-accordion-tab"
                                id="corporatetabs"
                                role="tablist"
                            >
                                <button
                                    role="tab"
                                    onClick={() => {
                                        setPageNumber(1);
                                        setPagination(defaultPagination);
                                        setTab(CONSTANTS.ACTIVE);
                                    }}
                                    className="aui-acc-tab-item aui-accordion-tab1 btn btn-text"
                                    data-toggle="collapse"
                                    data-target="#activeCorporation"
                                    aria-expanded={tab === CONSTANTS.ACTIVE}
                                >
                                    Active Corporations
                                    <i
                                        className="aha-icon-arrow-down mx-2 d-inline-block d-md-none"
                                        aria-hidden="true"
                                    />
                                </button>
                                <button
                                    role="tab"
                                    onClick={() => {
                                        setPageNumber(1);
                                        setPagination(defaultPagination);
                                        setTab(CONSTANTS.INACTIVE);
                                    }}
                                    className="aui-acc-tab-item aui-accordion-tab2 btn btn-text"
                                    data-toggle="collapse"
                                    data-target="#inactiveCorporation"
                                    aria-expanded={tab === CONSTANTS.INACTIVE}
                                >
                                    Inactive Corporations
                                    <i
                                        className="aha-icon-arrow-down mx-2 d-inline-block d-md-none"
                                        aria-hidden="true"
                                    />
                                </button>
                                <div
                                    id="activeCorporation"
                                    role="tabpanel"
                                    className={`collapse ${
                                        tab === CONSTANTS.ACTIVE ? "show" : ""
                                    } row w-100 no-gutters aui-accordion-content aui-accordion-content1`}
                                    data-parent="#corporatetabs"
                                >
                                    {isTableLoading ? (
                                        <div
                                            role="alert"
                                            aria-live="assertive"
                                            aria-label="Active corporations table is loading"
                                            className="sr-only"
                                        />
                                    ) : (
                                        ""
                                    )}
                                    <CorporationTable
                                        corporations={activeCorporations}
                                        actionURL="/corporation/corporation-management/corporation-details"
                                        isTableLoading={isTableLoading}
                                        tab={tab}
                                        pageNumber={pageNumber}
                                        pagination={pagination}
                                        tabType={CONSTANTS.FACILITY_TYPE.ACTIVE}
                                        roleValue={roleValue}
                                    />
                                </div>

                                <div
                                    id="inactiveCorporation"
                                    role="tabpanel"
                                    className={`collapse ${
                                        tab === CONSTANTS.INACTIVE
                                            ? " show"
                                            : ""
                                    } row w-100 no-gutters aui-accordion-content aui-accordion-content2`}
                                    data-parent="#corporatetabs"
                                >
                                    {isTableLoading ? (
                                        <div
                                            role="alert"
                                            aria-live="assertive"
                                            aria-label="inactive corporations table is loading"
                                            className="sr-only"
                                        />
                                    ) : (
                                        ""
                                    )}
                                    <CorporationTable
                                        corporations={inactiveCorporations}
                                        isTableLoading={isTableLoading}
                                        tab={tab}
                                        pageNumber={pageNumber}
                                        actionURL="/corporation/corporation-management/corporation-details"
                                        pagination={pagination}
                                        tabType={
                                            CONSTANTS.FACILITY_TYPE.INACTIVE
                                        }
                                        roleValue={roleValue}
                                    />
                                </div>
                            </div>
                        </div>
                        {pagination && Number(pagination.totalPages) > 0 ? (
                            <Pagination
                                pageNumber={pagination.pageNumber}
                                pageSize={pagination.pageSize}
                                totalCount={pagination.totalCount}
                                totalPages={pagination.totalPages}
                                isFirst={pagination.isFirst}
                                isLast={pagination.isLast}
                                setPageNumber={pagination.setPageNumber}
                                paginationSetLimit={paginationSetLimit}
                            />
                        ) : null}
                    </div>
                </div>
            </section>
        </CorporateManagementWrapper>
    );
};

export default CorporationManagement;
