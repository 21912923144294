import React, { useEffect, useRef, useState } from "react";
import {
    dateToYYYYMMDDFormatString,
    cstDateFormat,
    dateToYYYYMMDDPlaceholderFormatStringISO,
    wordSplit,
} from "common/utils";
import { DatepickerWrapper } from "./styled";

export const daysBetweenTwoDates = (currentDate1: any, expireDate: any) => {
    const year: any = currentDate1.getFullYear();
    const month: any = `0${currentDate1.getMonth() + 1}`.slice(-2);
    const day: any = currentDate1.getDate();
    const currentDate = `${year}-${month}-${day}`;

    const timeDifference =
        new Date(
            dateToYYYYMMDDPlaceholderFormatStringISO(currentDate)
        ).getTime() -
        new Date(dateToYYYYMMDDFormatString(expireDate)).getTime();
    const daysDiffererence = Math.round(timeDifference / (1000 * 3600 * 24));
    return daysDiffererence;
};

export const getSysTimeZoneCurrentDate = () => {
    const cstOptions: any = {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true,
    };
    const cstFormatter = new Intl.DateTimeFormat("en-US", cstOptions);
    const fullDate = cstFormatter.format(new Date()).split(",");
    const [m, d, y] = fullDate[0].split("/");
    return `${y}-${m}-${d}`;
};
const Datepicker = (props: any) => {
    const months: any = {
        Jan: "01",
        Feb: "02",
        Mar: "03",
        Apr: "04",
        May: "05",
        Jun: "06",
        Jul: "07",
        Aug: "08",
        Sep: "09",
        Oct: "10",
        Nov: "11",
        Dec: "12",
    };
    const monthArray = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ];
    const [dateLabel, setDateLabel] = useState<string>("Open datepicker");
    const [propsYear, propsMonth, propsDay] = props.defaultValue.split("-");
    const [actualDate, setactualDate] = useState<any>(null);
    const [dummyDate, setDummyDate] = useState<any>(null);
    const key = Object.keys(months).filter((key) => {
        return propsMonth === months[key];
    })[0];
    const [textYear, setTextYear] = useState<any>(propsYear);
    const [textMonth, setTextMonth] = useState<any>(key);
    const [textDay, setTextDay] = useState<any>(propsDay);
    const yearValue = useRef<HTMLInputElement>(null);
    const monthValue = useRef<HTMLInputElement>(null);
    const dayValue = useRef<HTMLInputElement>(null);
    const actualDateRef = useRef<HTMLInputElement>(null);
    const datePickerBtnRef = useRef<HTMLButtonElement>(null);
    const [dateError, setDateError] = useState<any>("");
    const [presentYear, setPresentYear] = useState<any>("");
    const [isPickerOpen, setIsPickerOpen] = useState<boolean>(false);
    const [minDateForLock, setMinDateForLock] = useState<any>("");
    const idLabels: any = `${props.ariaLabel} date-error`;
    const getCSTDateFormat = (date: any) => {
        const cstOptions: any = {
            timeZone: "America/Chicago",
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour12: true,
        };
        const cstFormatter = new Intl.DateTimeFormat("en-US", cstOptions);
        const diff = daysBetweenTwoDates(new Date(), date);
        if (diff === 0 && props?.isCstCurrentDate) {
            const fullDate = cstFormatter.format(new Date()).split(",");
            const [m, d, y] = fullDate[0].split("/");
            return `${y}-${m}-${d}`;
        }
        return date;
    };
    const validateYears = (year: any) => {
        if (props?.minMaxDate) {
            const { Year, currentCstDate } = cstYear();
            if (`${year}` === `${Year}`) return false;
            return true;
        }
        return false;
    };
    const validateDays = (days: any) => {
        const month = months[`${monthValue.current?.value}`];
        const numberOfDays = getDaysInMonth(yearValue.current?.value, month);
        if (days > numberOfDays || days === "00" || days === "0") {
            return true;
        }
        return false;
    };
    const handleDateChange = (event: any) => {
        if (event.target.value) {
            const cstDate: any = getCSTDateFormat(event.target.value);
            props.onChangeDate(cstDate);
            setactualDate(cstDate);
            const [year, month, day] = cstDate.split("-");
            const key = Object.keys(months).filter((key) => {
                return month === months[key];
            })[0];
            setDummyDate(`${year}-${key}-${day}`);
            setTextYear(year);
            setTextMonth(key);
            setTextDay(day);
            actualDateRef.current?.focus();
            dayValue?.current?.focus();
        } else {
            setTextYear("");
            setTextMonth("");
            setTextDay("");
        }
    };
    const updateTextDate = (y: any, m: any, d: any) => {
        const mnth = monthArray.indexOf(`${m}`);
        const mnt = (mnth + 1).toString().padStart(2, "0");
        const day = Number(d);
        props.onChangeDate(`${y}-${mnt}-${`0${d}`.slice(-2)}`);
    };

    const triggerCallendar = (event: any) => {
        if (event.which === 9) {
            document.getElementById("lockCancel")?.focus();
        }
    };
    const handleTextDateChange = (event: any) => {
        event.target.parentNode.title = "";
        event.target.setCustomValidity("");
        if (!yearValue.current?.value) {
            setTextYear("");
        }
        if (!monthValue.current?.value) {
            setTextMonth("");
        }
        if (!dayValue.current?.value) {
            setTextDay("");
        }
        let val: any = "";
        if (monthValue.current?.id === "monthValue") {
            val = event.target.value.replace("[A-Za-z]+", "");
        }
        if (dayValue.current?.id === "dayValue") {
            setTextDay(`${`0${dayValue.current?.value}`.slice(-2)}`);
        }
        props.setBtnInd(false);
        setTextYear(dayValue.current?.value);
        setTextYear(yearValue.current?.value);
        setTextMonth(monthValue.current?.value);
        updateTextDate(
            yearValue.current?.value,
            monthValue.current?.value,
            dayValue.current?.value
        );
    };
    const cstYear = () => {
        const currentDate = new Date();
        const cstTimeZone = "America/Chicago";
        const currentCstDate = currentDate.toLocaleDateString("en-US", {
            timeZone: cstTimeZone,
        });
        const Year = new Date(currentCstDate).getFullYear();
        return { Year, currentCstDate };
    };
    useEffect(() => {
        const { Year, currentCstDate } = cstYear();
        const formattedCstDate = new Date(currentCstDate);
        const month = (formattedCstDate.getMonth() + 1)
            .toString()
            .padStart(2, "0");
        const day = formattedCstDate.getDate().toString().padStart(2, "0");
        const minDate = `${formattedCstDate.getFullYear()}-${month}-${day}`;
        setPresentYear(Year);
        setMinDateForLock(minDate);
    }, []);

    useEffect(() => {
        if (textYear || textMonth || textDay) {
            const datePattern =
                /^\d{4}-(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)-\d{2}$/;
            setTextDay(`0${textDay}`.slice(-2));
            const dateString = `${textYear}-${textMonth}-${`0${textDay}`.slice(
                -2
            )}`;
            if (
                datePattern.test(dateString) &&
                !validateDays(textDay) &&
                !validateYears(textYear)
            ) {
                setactualDate(
                    `${textYear}-${months[textMonth]}-${`0${textDay}`.slice(
                        -2
                    )}`
                );
                setDummyDate(
                    `${textYear}-${textMonth}-${`0${textDay}`.slice(-2)}`
                );
                setDateError("");
            } else {
                props?.setBtnInd(true);
                const tody = new Date();
                let yesterday: any = dateToYYYYMMDDFormatString(
                    tody.setDate(tody.getDate() - 1)
                );
                yesterday = cstDateFormat(yesterday);
                if (props?.errorMessage) {
                    setDateError(props?.errorMessage);
                } else {
                    setDateError(
                        `Enter a valid date after ${yesterday} in the current year.`
                    );
                }
            }
            if (
                dayValue.current?.value === "aN" ||
                monthValue.current?.value === "" ||
                yearValue.current?.value === ""
            ) {
                setTextDay((preDay: any) => preDay);
            }
        }
    }, [textYear, textMonth, textDay]);
    const getDaysInMonth = (year: any, month: any) =>
        new Date(year, month, 0).getDate();
    const handleYearKeyEvent = (event: any) => {
        const selectedYear = yearValue.current?.value;
        if (event.which === 38) {
            setTextYear((preVal: any) =>
                preVal === 2050 ? preVal : Number(preVal) + 1
            );
            updateTextDate(textYear, textMonth, textDay);
        }
        if (event.which === 40) {
            setTextYear((preVal: any) =>
                preVal === 1920 ? preVal : Number(preVal) - 1
            );
            updateTextDate(textYear, textMonth, textDay);
        }
    };
    const handleMonthKeyEvent = (event: any) => {
        const selected = monthArray.indexOf(`${monthValue.current?.value}`);
        if (event.which === 38) {
            setTextMonth(() =>
                selected === 11
                    ? monthArray[selected]
                    : monthArray[selected + 1]
            );
            updateTextDate(
                textYear,
                monthArray[selected === 11 ? selected : selected + 1],
                textDay
            );
        }
        if (event.which === 40) {
            setTextMonth(() =>
                selected === 0 ? monthArray[selected] : monthArray[selected - 1]
            );
            updateTextDate(
                textYear,
                monthArray[selected === 0 ? selected : selected - 1],
                textDay
            );
        }
    };
    const handleDayKeyEvent = (event: any) => {
        const month = months[`${monthValue.current?.value}`];
        const numberOfDays = getDaysInMonth(yearValue.current?.value, month);
        if (textDay > numberOfDays) {
            setTextDay(numberOfDays);
        }
        if (event.which === 38) {
            setTextDay((preVal: any) =>
                preVal >= numberOfDays ? numberOfDays : Number(preVal) + 1
            );
            updateTextDate(
                textYear,
                textMonth,
                textDay >= numberOfDays ? numberOfDays : Number(textDay) + 1
            );
        }
        if (event.which === 40) {
            setTextDay((preVal: any) =>
                preVal <= 1 ? preVal : Number(preVal) - 1
            );
            updateTextDate(
                textYear,
                textMonth,
                textDay <= 1 ? textDay : Number(textDay) - 1
            );
        }
    };
    const showCallendar = (event: any) => {
        event.preventDefault();
        const dt: any = document.querySelector(
            "#actualDate"
        ) as HTMLAnchorElement;
        dt.showPicker();
        setIsPickerOpen(true);
    };

    useEffect(() => {
        const propsDate =
            props.defaultValue === ""
                ? dateToYYYYMMDDFormatString(new Date())
                : props.defaultValue;
        const [propsYear, propsMonth, propsDay] = propsDate.split("-");
        const key = Object.keys(months).filter((key) => {
            return propsMonth === months[key];
        })[0];
        setTextYear(propsYear);
        setTextMonth(key);
        setTextDay(propsDay);
    }, [props.defaultValue]);
    return (
        <DatepickerWrapper>
            <div
                className="input-group mb-3 border border-default date-wrapper d-flex"
                role="group"
                aria-labelledby={idLabels}
            >
                <div style={{ paddingTop: "3px", paddingLeft: "5px" }}>
                    <input
                        type="text"
                        className="border-0 date-year custom-error-tooltip select-text"
                        id="yearValue"
                        ref={yearValue}
                        placeholder={wordSplit("YYYY")}
                        value={textYear}
                        min={2000}
                        max={2090}
                        maxLength={4}
                        pattern="[0-9]+"
                        onChange={handleTextDateChange}
                        onKeyDown={handleYearKeyEvent}
                        title=""
                        required
                        onFocus={(e) => e.target.select()}
                        autoComplete="off"
                    />
                    <span className="mt-1  h5">-</span>
                    <input
                        type="text"
                        className="border-0 date-month"
                        id="monthValue"
                        ref={monthValue}
                        placeholder={wordSplit("MMM")}
                        value={textMonth}
                        maxLength={3}
                        onChange={handleTextDateChange}
                        onKeyDown={handleMonthKeyEvent}
                        onFocus={(e) => e.target.select()}
                        required
                        autoComplete="off"
                    />
                    <span className="mt-1  h5">-</span>
                    <input
                        type="text"
                        className="date-day select-all-on-touch"
                        id="dayValue"
                        ref={dayValue}
                        placeholder={wordSplit("DD")}
                        autoComplete="off"
                        value={textDay !== "" ? `0${textDay}`.slice(-2) : ""}
                        onChange={handleTextDateChange}
                        onKeyDown={handleDayKeyEvent}
                        onFocus={(e) => e.target.select()}
                        required
                    />
                </div>
                <div id="basic-addon2" className="dateFieldWrapper">
                    <input
                        type="date"
                        id="actualDate"
                        ref={actualDateRef}
                        className="date-field"
                        min={props?.minMaxDate ? `${minDateForLock}` : ""}
                        max={props?.minMaxDate ? `${presentYear}-12-31` : ""}
                        aria-label="calendar picker"
                        onChange={handleDateChange}
                        onKeyDown={triggerCallendar}
                    />
                    <button
                        ref={datePickerBtnRef}
                        onClick={showCallendar}
                        className="date-btn"
                        aria-label="date Picker"
                        aria-describedby="yearValue monthValue dayValue"
                    >
                        <span className="aui-datepicker" />
                    </button>
                </div>
            </div>
            <div
                className="datepicker-error"
                id="date-error"
                aria-live="assertive"
            >
                <span>{dateError}</span>
            </div>
        </DatepickerWrapper>
    );
};
export default Datepicker;
