import styled from "styled-components";

export const CorporateTableWrapper = styled.div`
    .corporate-search {
        align-items: center;
        margin: 16px 0 24px;
        input {
            border-radius: 6px;
            padding-right: 50px;
        }
    }
    .corporate-search-field {
        position: relative;
        .corporate-search-icon {
            position: absolute;
            right: 10px;
            top: 5px;
            font-size: 20px;
        }
    }
    .corporate-filter {
        margin: 10px 0 30px;
    }
    .cst-select-fld {
        border-radius: 6px;
    }
    .corporate-table {
        thead {
            @media only screen and (min-width: 992px) {
                th:nth-child(1) {
                    width: 25%;
                }
                th:nth-child(2) {
                    width: 28%;
                }
                th:nth-child(3) {
                    width: 29%;
                }
                th:nth-child(4) {
                    width: 18%;
                }
            }
        }
        tbody {
            td:nth-child(5) {
                .aui-td {
                    .btn {
                        font-size: 14px;
                    }
                }
            }
            .corp-d-location {
                width: 100%;
                display: flex;
                > div:nth-child(1) {
                    flex: 0 0 76px;
                }
            }
        }
        .corp-sort {
            white-space: nowrap;
            i {
                font-size: 10px;
            }

            .corp-sort-up {
                transform: rotate(-90deg);
                display: inline-block;
                margin-right: -5px;
            }
            .corp-sort-down {
                transform: rotate(90deg);
                display: inline-block;
            }
            &.corp-sort-asc {
                .corp-sort-up {
                    color: #c10e21;
                }
                .corp-sort-down {
                    color: #bcc3ca;
                }
            }
            &.corp-sort-desc {
                .corp-sort-up {
                    color: #bcc3ca;
                }
                .corp-sort-down {
                    color: #c10e21;
                }
            }
        }
    }
`;
