import styled from "styled-components";

export const SortTableColumnDropdownWrapper = styled.div`
    @media only screen and (min-width: 576px) {
        display: flex;
        align-items: center;
    }
    > label {
        margin: 0 20px 0 0;
    }
    .cst-select {
        width: 228px;
    }
`;
