import React, { useEffect, useRef, useState } from "react";
import DocumentServiceAPI from "api/documentsAPI";
import store from "app/store";
import {
    getFIleSize,
    parseJwt,
    redirectionForErrorCode,
    showModal,
    sleep,
} from "common/utils";
import { showToast } from "components/Toast/toast.slice";
import { useParams, useHistory } from "react-router-dom";
import CONSTANTS from "common/constants";
import { deleteUploadedDoc } from "api/programAgreementAPI";
import SearchDropdown from "components/SearchDropdown";
import { logger } from "../../utils/logger.utils";
import { UploadDocumentModalContentWrapper } from "./UploadDocumentModalStyled";

const documentServiceAPI = new DocumentServiceAPI();

// Upload Document Form
export interface IUploadDocForm {
    title: string;
    programId: string | number;
    programName: string | number;
    uploadStandard: string;
    uploadDescription: string;
    file: any;
    facilityMessage: string;
    fileUploadError: boolean;
    setFileUploadSuccess: boolean;
    referenceId?: string;
}

interface Props {
    uploadProgram: any;
    standards: any;
    setRefreshTableDateNow: any;
    initUploadDocForm: IUploadDocForm;
    uploadDocForm: IUploadDocForm;
    setUploadDocForm: any;
    setuploadDocumentInd?: any;
    setIsResubmit?: any;
    isResubmit?: boolean;
    closeDocumentModal?: any;
    isEUP: boolean;
    facilityCount?: any;
    facilityIds?: any;
    setCount?: any;
    setSelectAll?: any;
    setSelection?: any;
    setFacilityIds?: any;
}

const UploadDocumentContent = ({
    uploadProgram,
    standards,
    setRefreshTableDateNow,
    initUploadDocForm,
    uploadDocForm,
    setUploadDocForm,
    setIsResubmit,
    isResubmit,
    closeDocumentModal,
    isEUP,
    facilityCount,
    facilityIds,
    setCount,
    setSelectAll,
    setSelection,
    setFacilityIds,
    setuploadDocumentInd,
}: Props) => {
    const params: any = useParams();
    // STATE Variables META DATA
    const closeUploadModal = useRef<HTMLButtonElement>(null);
    const userAccessToken = window.localStorage.getItem("userAccessToken");
    const isCorporation =
        userAccessToken &&
        parseJwt(userAccessToken).role_code ===
            CONSTANTS.USER_ROLES.CORPORATION_ADMIN;

    const [uploadId, setUploadId] = useState<number | undefined>(undefined);
    const [uploadProgressPercentage, setUploadProgressPercentage] =
        useState<number>(0);
    const [isFileUploadProgress, setIsFileUploadProgress] =
        useState<boolean>(false);
    const [isFormSubmitInProgress, setIsFormSubmitInProgress] =
        useState<boolean>(false);
    const [fileUploadInd, setFileUploadInd] = useState<boolean>(true);

    // Dropdown Values
    const [standardsDropdown, setStandardsDropdown] = useState<any>([
        { label: "Standards", value: "" },
    ]);
    const history = useHistory();
    useEffect(() => {
        // Populate Standars Dropdown
        const standardsDropdownMap: any = [];
        standards.map((standard: any) => {
            standardsDropdownMap.push({
                label: standard.standardName,
                value: `${standard.standardId}###${standard.standardName}`,
            });
        });
        setStandardsDropdown(standardsDropdownMap);
    }, [standards]);

    useEffect(() => {
        logger("Load UploadDOC");
    }, []);

    const onChangeUploadForm = (event: any) => {
        setUploadDocForm({
            ...uploadDocForm,
            [event.target.name]:
                event.target.type == "textarea"
                    ? event.target.value.replace(/[><]/g, "")
                    : event.target.value.replace(/[><]/g, ""),
        });
    };

    const deletePreviousDoc = () => {
        if (uploadId) {
            deleteUploadedDoc(uploadId).then((response: any) => {
                if (response.success) {
                    setUploadId(undefined);
                    setUploadProgressPercentage(0);
                }
            });
        }
    };
    const dismissUploadDocument = (isResubmit: any) => {
        if (!isResubmit) {
            setuploadDocumentInd(false);
            showModal();
        }
    };

    const clearFileSelection = () => {
        setUploadDocForm({
            ...uploadDocForm,
            file: null,
            fileUploadError: false,
            setFileUploadSuccess: false,
        });
        deletePreviousDoc();
    };

    const onFileChange = (event: any) => {
        setUploadDocForm({
            ...uploadDocForm,
            fileUploadError: false,
            setFileUploadSuccess: false,
            file: event.target.files[0],
        });
        setUploadId(undefined);
        setUploadProgressPercentage(0);
    };

    const setFileUploadSuccess = () => {
        setUploadDocForm({
            ...uploadDocForm,
            setFileUploadSuccess: true,
            fileUploadError: false,
        });
    };

    const setFileUploadError = () => {
        setUploadDocForm({
            ...uploadDocForm,
            setFileUploadSuccess: false,
            fileUploadError: true,
        });
        setUploadId(undefined);
        setUploadProgressPercentage(0);
    };

    const validateUploadForm = () => {
        if (!uploadDocForm.title.trim()) {
            showFileUploadErrorMessage("Please enter document title");
            return false;
        }
        if (uploadDocForm.uploadStandard === "###") {
            showFileUploadErrorMessage("Please select a standard.");
            return false;
        }
        if (!uploadDocForm.uploadDescription.trim()) {
            showFileUploadErrorMessage(
                "Please enter the document description."
            );
            return false;
        }
        if (!uploadDocForm.file) {
            showFileUploadErrorMessage("Please upload a file.");
            return false;
        }
        if (!uploadId) {
            showFileUploadErrorMessage("Please wait until upload completes.");
            return false;
        }
        return true;
    };

    const submitDocument = async (event: any) => {
        event.preventDefault();
        const validate = validateUploadForm();
        const hospitalId =
            !isEUP || isCorporation
                ? params.hospitalId
                : localStorage.getItem("selectedHospitalId");
        if (validate && uploadId) {
            setIsFormSubmitInProgress(true);
            let selectedIds: any;
            if (facilityCount) {
                selectedIds = facilityIds.map(
                    (facility: any) => facility.hospitalId
                );
            }
            documentServiceAPI
                .submitUploadedDocument(
                    uploadDocForm,
                    uploadProgram,
                    uploadId,
                    facilityCount ? selectedIds : [hospitalId]
                )
                .then((response: any) => {
                    if (response.success === true) {
                        documentServiceAPI
                            .moveFileFromS3TempToMainBucket(
                                uploadId,
                                params.hospitalId
                                    ? params.hospitalId
                                    : selectedIds,
                                params.programId
                                    ? params.programId
                                    : uploadProgram.programId,
                                params.categoryId
                                    ? params.categoryId
                                    : uploadProgram.programCategoryId
                            )
                            .then((moveFileFromS3: any) => {
                                if (moveFileFromS3.success === true) {
                                    setUploadId(undefined);
                                    const toast = {
                                        message: `${uploadDocForm.file.name} has been successfully uploaded.`,
                                        code: "Success:",
                                        type: "success",
                                    };
                                    store.dispatch(showToast(toast));
                                    setUploadDocForm(initUploadDocForm);
                                    setRefreshTableDateNow &&
                                        setRefreshTableDateNow(Date.now()); // This will Refresh The Table Result
                                    setIsFormSubmitInProgress(false);
                                    closeUploadModal &&
                                        closeUploadModal.current &&
                                        closeUploadModal.current.click();
                                    closeDocumentModal &&
                                        closeDocumentModal.current &&
                                        closeDocumentModal.current.click();
                                    if (facilityCount) {
                                        setSelection({});
                                        setSelectAll({});
                                        setFacilityIds([]);
                                        setCount(0);
                                    }
                                }
                            })
                            .catch((error: any) => {
                                logger(error);
                                setIsFormSubmitInProgress(false);
                            });
                    }
                })
                .catch((error: any) => {
                    redirectionForErrorCode(
                        CONSTANTS.ROUTES.CORPORATION_DASHBOARD,
                        error,
                        history,
                        2000,
                        true
                    );
                    logger(error);
                    setIsFormSubmitInProgress(false);
                });
        }
    };

    const showFileUploadErrorMessage = (msg: string) => {
        const toast = {
            message: msg,
            code: "Error:",
        };
        store.dispatch(showToast(toast));
    };

    useEffect(() => {
        if (uploadDocForm.file) {
            logger("File Size:", uploadDocForm.file.size);
            if (uploadDocForm.file.size < CONSTANTS.FILE_UPLOAD_SIZE_LIMIT) {
                onFileUpload();
            } else {
                setFileUploadError();
            }
        }
    }, [uploadDocForm.file]);

    const onFileUpload = async () => {
        setIsFileUploadProgress(true);
        setFileUploadInd(true);
        let selectedIds: any;
        if (facilityCount) {
            selectedIds = facilityIds.map(
                (facility: any) => facility.hospitalId
            );
        }
        documentServiceAPI
            .upload(
                params.hospitalId ? [params.hospitalId] : selectedIds,
                params.programId ? params.programId : uploadProgram.programId,
                params.categoryId
                    ? params.categoryId
                    : uploadProgram.programCategoryId,
                uploadDocForm.file,
                setUploadId,
                setUploadProgressPercentage
            )
            .then((uploadfileToS3Response: any) => {
                if (uploadfileToS3Response.status === 200) {
                    setFileUploadSuccess();
                    sleep(5000).then(() => {
                        setFileUploadInd(false);
                    });
                } else {
                    setFileUploadError();
                }
                setIsFileUploadProgress(false);
            })
            .catch((error: any) => {
                logger(error);
                setFileUploadError();
                setIsFileUploadProgress(false);
            });
    };

    return (
        <UploadDocumentModalContentWrapper className="modal-content">
            <div className="modal-header">
                <h2 className="h4" id="uploadDocModalLabel">
                    Upload Document
                </h2>
                <button
                    id="closeUploadModal"
                    ref={closeUploadModal}
                    type="button"
                    className="close"
                    aria-label="Close upload document modal"
                    onClick={() => {
                        dismissUploadDocument(isResubmit);
                        setUploadDocForm(initUploadDocForm);
                        deletePreviousDoc();
                        if (setIsResubmit) setIsResubmit(false);
                    }}
                    disabled={isFileUploadProgress || isFormSubmitInProgress}
                >
                    <span aria-hidden="true" className="aha-icon-cross" />
                </button>
            </div>
            <div className="modal-body p-0">
                <form onSubmit={submitDocument}>
                    <div className="d-flex flex-row-reverse">
                        <div className="pb-3 mand-field">
                            <sup>*</sup>
                            mandatory fields
                        </div>
                    </div>
                    <fieldset
                        aria-label="Upload Document"
                        disabled={
                            isFileUploadProgress || isFormSubmitInProgress
                        }
                    >
                        {facilityCount && (
                            <div className="form-group row">
                                <div className="col-md-5 form-label">
                                    <label htmlFor="facilitiesIncluded">
                                        Organizations Included
                                    </label>
                                </div>
                                <div className="col-md-7">
                                    <input
                                        className="form-control"
                                        max={200}
                                        type="text"
                                        id="facilitiesIncluded"
                                        name="facilitiesIncluded"
                                        value={facilityCount}
                                        readOnly
                                        aria-describedby="facIncluded"
                                    />
                                </div>
                            </div>
                        )}

                        <div className="form-group row required">
                            <div className="col-md-5 form-label">
                                <label htmlFor="title" className="label-form">
                                    Title<sup>*</sup>
                                </label>
                            </div>
                            <div className="col-md-7">
                                <input
                                    required
                                    className="form-control"
                                    maxLength={200}
                                    type="text"
                                    id="title"
                                    name="title"
                                    pattern={
                                        CONSTANTS.REGEX_ALLOW_ALPHANUMERIC_SPACE
                                    }
                                    value={uploadDocForm.title}
                                    onChange={onChangeUploadForm}
                                    aria-describedby="docTitle"
                                />
                                <div className="form-help" id="docTitle">
                                    (Enter 1 to 200 characters)
                                </div>
                            </div>
                        </div>
                        <div className="form-group row required">
                            <div className="col-md-5 form-label">
                                <label
                                    htmlFor="uploadProgramId"
                                    className="label-form"
                                >
                                    Selected Program
                                    <sup>*</sup>
                                </label>
                            </div>
                            <div className="col-md-7">
                                <input
                                    required
                                    className="form-control"
                                    type="text"
                                    id="uploadProgramId"
                                    name="uploadProgramId"
                                    value={uploadProgram.programCategoryName}
                                    readOnly
                                />
                            </div>
                        </div>

                        <div className="form-group row required">
                            <div className="col-md-5 form-label">
                                <label
                                    htmlFor="selectStandards"
                                    id="selectStandards-label"
                                    className="label-form"
                                >
                                    Select Standard
                                    <sup>*</sup>
                                </label>
                            </div>
                            <div className="col-md-7">
                                {standardsDropdown && (
                                    <SearchDropdown
                                        id="selectStandards"
                                        items={standardsDropdown}
                                        selectedValue={
                                            uploadDocForm.uploadStandard
                                        }
                                        callParentOnSelect={(value: any) => {
                                            setUploadDocForm({
                                                ...uploadDocForm,
                                                uploadStandard: value,
                                            });
                                        }}
                                        disabled={
                                            isFileUploadProgress ||
                                            isFormSubmitInProgress ||
                                            isResubmit
                                        }
                                        label="Select Standard"
                                        placeholder="Select Standard"
                                        mandatoryInd
                                    />
                                )}
                            </div>
                        </div>
                        <div className="form-group row required">
                            <div className="col-md-5 form-label">
                                <label
                                    htmlFor="uploadDescription"
                                    className="label-form"
                                >
                                    Description
                                    <sup>*</sup>
                                </label>
                            </div>
                            <div className="col-md-7">
                                <textarea
                                    className="form-control"
                                    id="uploadDescription"
                                    name="uploadDescription"
                                    maxLength={500}
                                    rows={3}
                                    value={uploadDocForm.uploadDescription}
                                    onChange={onChangeUploadForm}
                                    required
                                    aria-describedby="docDescription"
                                />
                                <div className="form-help" id="docDescription">
                                    (Enter 1 to 500 characters)
                                </div>
                            </div>
                        </div>
                        <div className="form-group row required">
                            <div className="col-md-5 form-group required">
                                <label
                                    className="mb-0 label-form"
                                    id="uploaddocs"
                                >
                                    <span className="d-block">
                                        Upload Documents
                                        <sup>*</sup>
                                    </span>
                                    <i className="label-helptext">
                                        (Supported file types : JPEG, JPG, XLS,
                                        XLSX, CSV, PNG, PDF, PPT, PPTX, DOC,
                                        DOCX)
                                    </i>
                                </label>
                                <br />
                            </div>
                            <div className="col-md-7">
                                <div className="aui-drag-section p-3">
                                    <div className="aui-drag-outline">
                                        <div className="aui-drag-area d-flex align-items-center justify-content-center">
                                            <div
                                                className="d-flex justify-content-center align-items-center flex-column w-100"
                                                id="files"
                                            >
                                                {/* File selection area, if file is not selected then show, if file selected then hide */}
                                                {uploadDocForm.file ===
                                                    null && (
                                                    <div className="d-flex justify-content-center align-items-center flex-column w-100 pb-4">
                                                        <i
                                                            className="aha-icon-cloud-upload aui-drag-upload-icon mb-3 ml-n3"
                                                            aria-hidden="true"
                                                        />
                                                        <div className="aui-btn-files">
                                                            <input
                                                                id="uploadfiles"
                                                                type="file"
                                                                name="uploadfile"
                                                                onChange={
                                                                    onFileChange
                                                                }
                                                                accept=".JPEG, .JPG, .XLS, .XLSX, .CSV, .PNG, .PDF, .PPT, .PPTX, .DOC, .DOCX"
                                                                required
                                                                aria-labelledby="uploaddocs uploaddocs-label uploadfile-size"
                                                                aria-label="Browse Files"
                                                            />
                                                            <label
                                                                htmlFor="uploadfiles"
                                                                className="btn btn-round btn-primary m-0"
                                                                aria-label="Browse Files to upload document"
                                                                id="uploaddocs-label"
                                                            >
                                                                Browse Files
                                                            </label>
                                                        </div>
                                                        <div
                                                            className="mt-2"
                                                            id="uploadfile-size"
                                                        >
                                                            Maximum File size{" "}
                                                            {CONSTANTS.FILE_UPLOAD_SIZE_LIMIT /
                                                                1048576}
                                                            MB
                                                        </div>{" "}
                                                        {/* Bytes to MB Coversion */}
                                                    </div>
                                                )}
                                                {/* File uploading view, if file is selected then show */}
                                                {uploadDocForm.file && (
                                                    <div className="d-flex justify-content-center align-items-center flex-column aui-uploading-files row w-100">
                                                        <div className="col-12">
                                                            <div className="h8 font-600 mb-1">
                                                                {
                                                                    uploadDocForm
                                                                        .file
                                                                        .name
                                                                }
                                                            </div>
                                                            <div className="mb-3">
                                                                File Size:{" "}
                                                                {getFIleSize(
                                                                    uploadDocForm
                                                                        .file
                                                                        .size
                                                                )}
                                                            </div>
                                                            <div className="aui-progress-block mb-4">
                                                                <div className="d-flex w-100">
                                                                    <div className="progress flex-grow-1">
                                                                        {/* if failure use class progress-failure  */}
                                                                        <div
                                                                            className={
                                                                                uploadDocForm.fileUploadError
                                                                                    ? "progress-bar progress-failure"
                                                                                    : "progress-bar"
                                                                            }
                                                                            role="progressbar"
                                                                            id="inProgess"
                                                                            style={{
                                                                                width: `${uploadProgressPercentage}%`,
                                                                            }}
                                                                            aria-valuenow={
                                                                                uploadProgressPercentage
                                                                            }
                                                                            aria-valuemin={
                                                                                0
                                                                            }
                                                                            aria-valuemax={
                                                                                100
                                                                            }
                                                                            aria-describedby="progressInfo"
                                                                            aria-labelledby="progressPercent"
                                                                        >
                                                                            <div id="progressPercent">
                                                                                {
                                                                                    uploadProgressPercentage
                                                                                }

                                                                                %
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {/* if file upload success show Abort file upload button */}
                                                                    {uploadDocForm.setFileUploadSuccess && (
                                                                        <button
                                                                            className="btn btn-text aha-icon-cross ml-2"
                                                                            aria-label={`Remove uploaded file ${uploadDocForm.file.name}`}
                                                                            onClick={() =>
                                                                                clearFileSelection()
                                                                            }
                                                                        />
                                                                    )}
                                                                </div>
                                                                {/* if fileUploadError is true then show proper error message */}
                                                                <div
                                                                    className="aui-progress-fail-msg focusable-element"
                                                                    id="progressInfo"
                                                                    role="alert"
                                                                    aria-live="assertive"
                                                                >
                                                                    {uploadDocForm.fileUploadError &&
                                                                        `${CONSTANTS.FILE_UPLOAD_ERROR_MSG}`}
                                                                </div>
                                                            </div>
                                                            {/* if fileUploadError is true then show upload-again button */}
                                                            {uploadDocForm.fileUploadError && (
                                                                <div className="aui-btn-files">
                                                                    <input
                                                                        id="uploadfilesagain"
                                                                        type="file"
                                                                        name="uploadfile"
                                                                        onChange={
                                                                            onFileChange
                                                                        }
                                                                        required
                                                                    />
                                                                    <label
                                                                        htmlFor="uploadfilesagain"
                                                                        className="btn btn-round btn-primary m-0"
                                                                        aria-label="upload document again"
                                                                    >
                                                                        Upload
                                                                        Again
                                                                    </label>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {(isEUP || isCorporation) && (
                            <div className="form-group row">
                                <div className="col-md-5 form-label">
                                    <label htmlFor="facilityMessage">
                                        Message (If any)
                                    </label>
                                </div>
                                <div className="col-md-7">
                                    <textarea
                                        className="form-control"
                                        id="facilityMessage"
                                        name="facilityMessage"
                                        maxLength={500}
                                        rows={3}
                                        value={uploadDocForm.facilityMessage}
                                        onChange={onChangeUploadForm}
                                        aria-describedby="docMessage"
                                    />
                                    <div className="form-help" id="docMessage">
                                        (Enter 0 to 500 characters)
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="row mt-4">
                            <div className="col-md-7 offset-md-5 doc-submit-btn">
                                <button
                                    type="submit"
                                    className={`btn btn-primary btn-round ${
                                        isFormSubmitInProgress
                                            ? CONSTANTS.BUTTON_SPINNER
                                            : ""
                                    }`}
                                    disabled={uploadDocForm.fileUploadError}
                                    aria-label="Submit uploaded document"
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </fieldset>
                </form>
            </div>
            <div
                aria-atomic="true"
                role="alert"
                aria-live="polite"
                className="sr-only"
            >
                {uploadDocForm.setFileUploadSuccess &&
                    fileUploadInd &&
                    "File uploaded 100%"}
            </div>
        </UploadDocumentModalContentWrapper>
    );
};

export default UploadDocumentContent;
