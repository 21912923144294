import styled from "styled-components";

export const InactiveUserWrapper = styled.div`
    .inactive-container {
        background: #f8f8f8;
        border: 1px solid #e3e3e3;
        padding-top: 76px;
        padding-bottom: 76px;
        margin: 36px 0;
        @media only screen and (min-width: 768px) {
            padding-top: 100px;
            padding-bottom: 100px;
        }
        @media only screen and (min-width: 992px) {
            padding-top: 136px;
            padding-bottom: 136px;
            margin: 76px 0;
        }
    }
    img {
        margin: 0 auto 22px;
        display: block;
    }
    p {
        margin: 0 auto;
        line-height: 32px;
        text-align: center;
        font-size: 22px;
        @media only screen and (min-width: 576px) {
            font-size: 24px;
            line-height: 40px;
        }
        @media only screen and (min-width: 768px) {
            font-size: 26px;
            line-height: 45px;
        }
    }
`;
