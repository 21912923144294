import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import DashboardEmptyState from "components/DashboardEmptyState/DashboardEmptyState";
import RegistryTabs from "../RegistryMenu";
import RegistryBanner from "../../../components/RegistryBanner";
import { parseJwt } from "../../../common/utils";
import { getRegistryById } from "../../../api/registryAPI";
import { RegistryDashboardWrapper } from "./styled";

const RegistryDashboard = () => {
    const dispatch = useDispatch();
    const [registry, setRegistry] = useState<any>({});

    useEffect(() => {
        dispatch(setPageLoadingStatus({ isPageLoading: true }));

        const userAccessToken = parseJwt(
            window.localStorage.getItem("userAccessToken")
        );

        getRegistryById(userAccessToken.registry_id)
            .then((registryResponse: any) => {
                dispatch(setPageLoadingStatus({ isPageLoading: false }));
                if (registryResponse.success) {
                    setRegistry(registryResponse.data.registry);
                }
            })
            .catch(() => {
                dispatch(setPageLoadingStatus({ isPageLoading: false }));
            });
    }, []);

    return (
        <RegistryDashboardWrapper>
            <RegistryTabs activeTab="registryDashboard" />
            <div className="container pt-3 ">
                <RegistryBanner
                    registryName={registry.registryName}
                    registryCode={registry.registryCode}
                    countryName={registry.countryName}
                />
                <DashboardEmptyState />
            </div>
        </RegistryDashboardWrapper>
    );
};

export default RegistryDashboard;
