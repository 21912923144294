import React, { ReactNode } from "react";
import PropTypes from "prop-types";
import Header from "components/Header";
import Footer from "components/Footer";
import { Wrapper } from "styled";
import Loader from "components/Loader";
import ScrollToTop from "components/scrollToTop";
import { Toast } from "components/Toast";

interface Props {
    children: ReactNode;
}

export default function DefaultLayout({ children }: Props) {
    return (
        <Wrapper className="d-flex flex-column">
            <Header />
            <ScrollToTop />
            <main
                className="d-flex flex-column flex-grow-1"
                id="maincontent"
                tabIndex={-1}
            >
                <Toast />
                {children}
            </main>
            <Loader />
            <Footer />
        </Wrapper>
    );
}
DefaultLayout.propTypes = {
    children: PropTypes.element.isRequired,
};
