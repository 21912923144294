import styled from "styled-components";

export const UserManagementWrapper = styled.div`
    .usr-add-search {
        background-color: #f8f8f8;
        padding: 12px 26px;
    }
    .usr-search {
        position: relative;
        width: 100%;
        background-color: #ffffff;
        @media only screen and (min-width: 576px) {
            width: auto;
        }
        .smart-search-block {
            width: 100%;
            @media only screen and (min-width: 576px) {
                width: 275px;
            }
            @media only screen and (min-width: 768px) {
                width: 350px;
            }
            @media only screen and (min-width: 992px) {
                width: 450px;
            }
        }
        .search-field {
            height: 42px;
        }
        input[type="search"]::-ms-clear {
            display: none;
            width: 0;
            height: 0;
        }
        input[type="search"]::-ms-reveal {
            display: none;
            width: 0;
            height: 0;
        }
        input[type="search"]::-webkit-search-decoration,
        input[type="search"]::-webkit-search-cancel-button,
        input[type="search"]::-webkit-search-results-button,
        input[type="search"]::-webkit-search-results-decoration {
            -webkit-appearance: none;
        }
        .user-search-field + .usr-search-clear {
            position: absolute;
            right: 45px;
            top: 10px;
            font-size: 14px;
            color: #222328;
            padding: 2px;
        }
        .usr-search-icon {
            position: absolute;
            right: 10px;
            top: 10px;
            font-size: 20px;
            color: #222328;
        }
    }
    .usr-search {
        .user-search-field:valid + .usr-search-clear {
            display: block;
        }
        .user-search-field + .usr-search-clear {
            display: none;
        }
    }
    .usr-table {
        thead {
            @media only screen and (min-width: 992px) {
                th:nth-child(1) {
                    width: 20%;
                }
                th:nth-child(2) {
                    width: 20%;
                }
                th:nth-child(3) {
                    width: 32%;
                }
                th:nth-child(4) {
                    width: 18%;
                }
                th:nth-child(5) {
                    width: 10%;
                    text-align: center;
                }
            }
        }
    }
    .usr-action-icon {
        font-size: 20px;
        color: #c10e21;
    }
    .adduser-modal {
        &.aui-modal {
            .modal-header {
                border-bottom: 2px solid #c10e21;
            }
        }
    }
    .add-usermgnt-btn {
        .btn {
            width: 100%;
            @media only screen and (min-width: 576px) {
                width: auto;
            }
        }
        .popover {
            max-width: 96vw;
        }
    }
`;
