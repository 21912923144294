import styled from "styled-components";

export const HospitalDashboardWrapper = styled.div`
    .tooltip-div {
        right: 38px;
        transform: translate(38px, -10px);
        @media only screen and (min-width: 768px) {
            right: 38px;
            transform: translate(38px, 0);
        }
        @media only screen and (min-width: 992px) {
            right: 38px;
            transform: translate(38px, 0);
        }
        &:before,
        &:after {
            left: 76%;
        }
    }
`;
