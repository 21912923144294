import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getCurrentQuarter } from "common/utils";

export interface IMeasuresDateFilter {
    year: number;
    quarter: number;
}

export const initialState: IMeasuresDateFilter = {
    year: new Date().getFullYear(),
    quarter: getCurrentQuarter(),
};

const measuresDateFilterSlice = createSlice({
    name: "toast",
    initialState,
    reducers: {
        setYear: (state, action: PayloadAction<number>) => {
            state.year = Number(action.payload);
        },
        setQuarter: (state, action: PayloadAction<number>) => {
            state.quarter = Number(action.payload);
        },
    },
});
const { actions, reducer } = measuresDateFilterSlice;

export const { setYear, setQuarter } = actions;
export default reducer;
