import React, { ReactNode } from "react";
import ScrollToTop from "components/scrollToTop";
import { LayoutWrapper, SidebarWrapper, ContentWrapper } from "./styled";

interface Props {
    sidebarNode: ReactNode;
    contentNode: ReactNode;
}
const SidebarLayout = ({ sidebarNode, contentNode }: Props) => {
    return (
        <LayoutWrapper>
            <ScrollToTop />
            <SidebarWrapper className="aui-sidenav">
                {sidebarNode}
            </SidebarWrapper>
            <ContentWrapper className="container">{contentNode}</ContentWrapper>
        </LayoutWrapper>
    );
};

export default SidebarLayout;
