import styled from "styled-components";

export const GraphCardsWrapper = styled.div`
    margin-bottom: 50px;
    .graph-sub-heading {
        margin: 30px 0 16px 0;
        flex-grow: 1;
        padding-right: 36px;
        @media only screen and (min-width: 576px) {
            margin: 30px 0 0 0;
        }
    }
    .measureid-dropdown {
        width: 158px;
        .cst-select {
            height: 32px;
            .cst-arrow {
                height: 32px;
                &:before {
                    line-height: 30px;
                }
            }
            .cst-multiselect-fld {
                height: 32px;
                .cst-selected-item {
                    line-height: 1;
                }
                .cst-grouped-selected-item {
                    height: 32px;
                    font-size: 14px;
                }
            }
        }
        .cst-multiselect-fld {
            > span {
                display: none;
            }
        }
    }
    .graph-toggle-slider {
        display: flex;
        width: 73px;
        height: 32px;
        background-color: #ffffff;
        -webkit-transition: 0.2s;
        transition: 0.2s;
        border: 1px solid #cccccc;
        border-radius: 20px;
        position: relative;
        cursor: pointer;
        &:before {
            position: absolute;
            content: "";
            height: 28px;
            width: 35px;
            left: 1px;
            bottom: 1px;
            background-color: white;
            -webkit-transition: 0.2s;
            transition: 0.2s;
            background-color: #c10e21;
            border-radius: 20px;
        }
        .toggle-graph-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-grow: 1;
            height: 28px;
            width: 35px;
            z-index: 5;
            font-size: 15px;
        }
    }
    .graph-toggle-input {
        opacity: 0;
        width: 0;
        height: 0;
        &:checked {
            + .graph-toggle-slider {
                &:before {
                    -webkit-transform: translateX(34px);
                    -ms-transform: translateX(34px);
                    transform: translateX(34px);
                }
                .toggle-graph-icon1 {
                    color: #777979;
                }
                .toggle-graph-icon2 {
                    color: #ffffff;
                }
            }
        }
        &:not(:checked) {
            + .graph-toggle-slider {
                .toggle-graph-icon2 {
                    color: #777979;
                }
                .toggle-graph-icon1 {
                    color: #ffffff;
                }
            }
        }
        &:focus {
            & + .graph-toggle-slider {
                outline: 2px solid #f7983a;
                box-shadow: 0 0 4px 4px #f7983a;
            }
        }
        &:focus,
        &:hover,
        &:active {
            & + .graph-toggle-slider + .tooltip-div {
                display: block;
                width: 230px;
                width: max-content;
                margin-top: 16px;
                text-align: center;
                max-width: 200px;
                @media only screen and (max-width: 767.9px) {
                    &:before,
                    &:after {
                        display: none;
                    }
                }
            }
        }
    }
    .graph-container {
        border: 1px solid #e0e0e0;
        overflow-y: hidden;
        overflow-x: auto;
        position: relative;
        .dashboard-chart {
            > div > div > div > svg + div[aria-label] {
                display: none;
            }
        }
        .benchmark {
            position: absolute;
            left: 250px;
            top: 16px;
        }
        .benchmark-legend {
            width: 28px;
            height: 14px;
            background-color: #cfcfcf;
            position: absolute;
            right: 120px;
            top: 45px;
            z-index: 1;
        }
        .graph-emptystate-container {
            height: 100%;
            min-height: 375px;
        }
        *,
        text {
            font-size: 14px;
            font-family: "Montserrat";
        }
        min-height: 375px;
    }
    .graph-toggle-switch {
        position: relative;
    }
    .cst-select-dropdown {
        z-index: 98;
    }
    .graph-emptystate-img {
        margin-bottom: 30px;
        height: 120px;
        @media only screen and (min-width: 576px) {
            height: 150px;
        }
        @media only screen and (min-width: 768px) {
            height: 210px;
        }
        img {
            height: 100%;
        }
    }
    .dashboard-chart-table {
        thead {
            th {
                width: auto;
            }
            th:nth-child(1) {
                width: 110px;
            }
        }
        .legend-symbol {
            width: 10px;
            height: 10px;
            display: inline-block;
            margin-right: 8px;
            margin-top: -2px;
        }
        .dashboard-table {
            tbody {
                tr {
                    td:nth-child(1) {
                        font-weight: 600;
                    }
                }
            }
        }
        .aui-td {
            min-height: 40px;
            @media only screen and (min-width: 992px) {
                min-height: auto;
            }
        }
    }
`;
