import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import DashboardEmptyState from "components/DashboardEmptyState/DashboardEmptyState";
import { getCorporationDetailsById } from "api/corporationAPI";
import { CorporationDashboardWrapper } from "./styled";
import CorporationTabs from "../CorporationMenu/CorporationMenu";
import CorporationBanner from "../CorporateBanner/CorporationBanner";
import { parseJwt } from "../../../common/utils";
import { setPageLoadingStatus } from "../../../components/Loader/loader.slice";
import { logError } from "../../../utils/logger.utils";

export const CorporationDashboard = () => {
    const dispatch = useDispatch();
    const [corporationDetails, setCorporationDetails] = useState<any>({});
    const corporationId = parseJwt(
        window.localStorage.getItem("userAccessToken")
    ).corporation_id;
    useEffect(() => {
        dispatch(setPageLoadingStatus({ isPageLoading: true }));
        localStorage.setItem("clearFilter", "true");
        getCorporationDetailsById(corporationId)
            .then((corporationResponse: any) => {
                setCorporationDetails(
                    corporationResponse.data.corporationDetails
                );
                dispatch(setPageLoadingStatus({ isPageLoading: false }));
            })
            .catch((error: any) => {
                logError(error);
                dispatch(setPageLoadingStatus({ isPageLoading: false }));
            });
    }, []);
    return (
        <CorporationDashboardWrapper className="corporation-container">
            <CorporationTabs activeTab="corporationDashboard" />
            <div className="container pt-3 ">
                <CorporationBanner corporationDetails={corporationDetails} />
                <div className="row d-none">
                    <div className="col-md-4 d-flex">
                        <div className="d-flex justify-content-between mb-4 total-fac-card bg-facilities flex-grow-1">
                            <i
                                className="aha-icon-user-plus mr-3 ml-n2"
                                aria-hidden="true"
                            />
                            <div className="flex-grow-1 d-block d-md-block align-items-center">
                                <section className="total-hospital-title">
                                    Total Number of Organizations
                                </section>
                                <section className="total-hospital-count font-bold">
                                    51
                                </section>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 d-flex">
                        <div className="d-flex justify-content-between mb-4 total-fac-card bg-fac-active flex-grow-1">
                            <i
                                className="aha-icon-certified mr-3 ml-n2"
                                aria-hidden="true"
                            />
                            <div className="flex-grow-1 d-block d-md-block align-items-center">
                                <section className="total-hospital-title">
                                    Active Organizations
                                </section>
                                <section className="total-hospital-count font-bold">
                                    30
                                </section>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 d-flex">
                        <div className="d-flex justify-content-between mb-4 total-fac-card bg-total-registry flex-grow-1">
                            <i
                                className="aha-icon-certified mr-3 ml-n2"
                                aria-hidden="true"
                            />
                            <div className="flex-grow-1 d-block d-md-block align-items-center">
                                <section className="total-hospital-title">
                                    Total Registries
                                </section>
                                <section className="total-hospital-count font-bold">
                                    7
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="new-corp-table mt-2 mb-5 d-none">
                    <div className="d-flex justify-content-between mb-4 align-items-center">
                        <h2 className="h4 font-500 mb-0">
                            Newly Registered Organizations
                        </h2>
                        <a href="#" className="btn-text-link-uline">
                            View All
                        </a>
                    </div>
                    {/* <FacilitiesRegisteredTable source="registeredFacility" /> */}
                </div>
                <div className="new-corp-table mt-2 mb-5 d-none">
                    <div className="d-flex justify-content-between mb-4 align-items-center">
                        <h2 className="h4 font-500 mb-0">
                            Newly Certified Organizations
                        </h2>
                        <a href="#" className="btn-text-link-uline">
                            View All
                        </a>
                    </div>
                    {/* <FacilitiesRegisteredTable source="certifiedFacility" /> */}
                </div>
                <DashboardEmptyState />
            </div>
        </CorporationDashboardWrapper>
    );
};

export default CorporationDashboard;
