import React from "react";
import { HospitalBannerWrapper } from "./styled";
import "../../styles/styles.scss";
import CONSTANTS from "common/constants";
import { localDateToYYYYMMDDFormatString, wordSplit } from "common/utils";
import moment from "moment-timezone";

interface Props {
    hospitalName: string;
    programName: string;
    countryName: string;
    ahaEmsId?: string;
    programId?: string;
    hospital?: any;
    emsAdminInd?: any;
    programData?: any;
    certificateData?: any;
}
export const HospitalBanner = ({
    hospitalName,
    programName,
    countryName,
    ahaEmsId,
    programId,
    hospital,
    emsAdminInd,
    programData,
    certificateData,
}: Props) => {
    return (
        <HospitalBannerWrapper className="hospital-banner">
            <h2 className="h3 hospital-banner-title">{hospitalName}</h2>
            <div className="d-md-flex hospital-banner-detail flex-wrap">
                {programId === CONSTANTS?.EMS_PROGRAM_ID
                    ? emsAdminInd && (
                          <div className="hospital-banner-item">
                              <span className="abbr-code mx-1">
                                  {wordSplit("AHA EMS ID:")}
                              </span>
                              <span className="font-bold hospital-banner-value">
                                  {programData?.emsId.split("_")[0]}
                              </span>
                          </div>
                      )
                    : null}
                {programId == CONSTANTS?.EMS_PROGRAM_ID && !emsAdminInd && (
                    <div className="hospital-banner-item">
                        AHA EMS ID:{" "}
                        <span className="font-bold hospital-banner-value">
                            {ahaEmsId?.split("_")[0]}
                        </span>
                    </div>
                )}
                <div className="hospital-banner-item">
                    Registered Program(s):
                    <span className="font-bold hospital-banner-value">
                        {programName}
                    </span>
                </div>
                {programId == CONSTANTS?.EMS_PROGRAM_ID ? (
                    <>
                        {emsAdminInd && (
                            <>
                                <div className="hospital-banner-item">
                                    Organization Code:
                                    <span className="font-bold hospital-banner-value ">
                                        {hospital?.facilityCode}
                                    </span>{" "}
                                </div>
                                {hospital.isActive &&
                                    certificateData?.programCertificateName && (
                                        <div className="hospital-banner-item">
                                            Award:
                                            <span className="hospital-banner-value font-bold">
                                                {
                                                    certificateData?.programCertificateName
                                                }
                                            </span>
                                        </div>
                                    )}
                                {hospital.isActive &&
                                    certificateData?.programCertificateGrantedOn && (
                                        <div className="hospital-banner-item">
                                            Granted on:
                                            <span className="hospital-banner-value font-bold">
                                                {localDateToYYYYMMDDFormatString(
                                                    certificateData?.programCertificateGrantedOn
                                                )}
                                            </span>
                                        </div>
                                    )}
                                <div className="hospital-banner-item">
                                    Approved By:
                                    <span className="font-bold hospital-banner-value">
                                        {programData?.verifiedBy?.firstName}{" "}
                                        {programData?.verifiedBy?.lastName},{" "}
                                        {localDateToYYYYMMDDFormatString(
                                            programData?.approvedDate
                                        )}{" "}
                                        {moment
                                            .utc(programData?.approvedDate)
                                            .local()
                                            .format("hh:mm:ss A")}{" "}
                                    </span>
                                </div>
                                <div className="hospital-banner-item">
                                    Last Edited By:
                                    <span className="font-bold hospital-banner-value">
                                        {hospital?.updatedBy?.firstName}{" "}
                                        {hospital?.updatedBy?.lastName},{" "}
                                        {localDateToYYYYMMDDFormatString(
                                            hospital?.updatedAt
                                        )}{" "}
                                        {moment
                                            .utc(hospital?.updatedAt)
                                            .local()
                                            .format("hh:mm:ss A")}
                                    </span>
                                </div>
                            </>
                        )}
                    </>
                ) : (
                    <div className="hospital-banner-item">
                        Location:{" "}
                        <span className="font-bold hospital-banner-value">
                            {countryName}
                        </span>
                    </div>
                )}
            </div>
        </HospitalBannerWrapper>
    );
};

export default HospitalBanner;
