import styled from "styled-components";

export const UserDashboardWrapper = styled.div`
    padding-top: 30px;
    padding-bottom: 30px;
    .user-titlebox {
        background-color: #e6e6e6;
        padding: 14px 30px;
        margin-bottom: 20px;
    }
    .user-fac-reg {
        font-weight: 600;
    }
    .user-search-field {
        position: relative;
        width: 100%;
        @media only screen and (min-width: 576px) {
            width: 416px;
        }
        input {
            padding-right: 34px;
        }
        .user-search-icon {
            position: absolute;
            right: 10px;
            top: 5px;
            font-size: 20px;
        }
    }
    .user-dashboard-table {
        thead {
            @media only screen and (min-width: 992px) {
                th:nth-child(1) {
                    width: 28%;
                }
                th:nth-child(2) {
                    width: 28%;
                }
                th:nth-child(3) {
                    width: 29%;
                }
                th:nth-child(4) {
                    width: 15%;
                }
            }
        }
    }
    .user-loc-title {
        width: 75px;
    }
    .user-emptystate {
        img {
            max-width: 100%;
        }
    }
    .user-emptystate-text {
        font-size: 18px;
        text-align: center;
        @media only screen and (min-width: 992px) {
            font-size: 22px;
        }
    }
    .smart-search-block {
        width: 100%;
        @media only screen and (min-width: 768px) {
            width: 400px;
        }
        @media only screen and (min-width: 992px) {
            width: 500px;
        }
    }
    .fac-search {
        margin: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        @media only screen and (min-width: 768px) {
            margin: 10px 0 0;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;
        }
        .table-rowcount {
            margin-top: 24px;
            @media only screen and (min-width: 768px) {
                margin-top: 0;
            }
        }
    }
    .facility-confirm-modal {
        .modal-content {
            padding: 20px;
            @media only screen and (min-width: 768px) {
                padding: 42px 72px;
            }
        }
        .modal-body {
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
        }
        .register-header {
            line-height: 1.2;
            padding-bottom: 11px;
            margin-bottom: 28px;
            position: relative;
            align-self: center;
            @media only screen and (min-width: 768px) {
                line-height: 1;
            }
            &:before {
                content: "";
                width: calc(100% - 48px);
                height: 2px;
                background: #c10e21;
                position: absolute;
                bottom: 0;
                left: 24px;
            }
        }
        .facility-confirm-btns {
            .btn {
                display: block;
                width: 100%;
                @media only screen and (min-width: 576px) {
                    display: inline-block;
                    width: auto;
                }
            }
        }
    }
`;
