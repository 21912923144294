import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Dropdown from "components/Dropdown";
import CONSTANTS from "common/constants";
import { getCountries } from "api/countriesAPI";
import { saveRegistry } from "api/registryAPI";
import { logError } from "utils/logger.utils";
import { showToast } from "components/Toast/toast.slice";
import "../../styles/styles.scss";
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import MultiSelectDropdown from "components/MultiSelectDropdown";
import { getAllProgramCategories } from "api/hospitalProgramsAPI";
import SearchDropdown from "components/SearchDropdown";
import { NewRegistryWrapper } from "./styled";

interface Props {
    registry: any;
    selectedCategories: any;
    backToRegistryManagement: any;
    editMode: boolean;
    setEditMode: any;
}

export const AddOrEditRegistry = (props: Props) => {
    const [registry, setRegistry] = useState<any>(props.registry);
    const [countriesMap, setCountriesMap] = useState<any>([
        {
            label: CONSTANTS.DOMESTIC_COUNTRY_NAME,
            value: CONSTANTS.DOMESTIC_COUNTRY_NAME,
        },
    ]);
    const [disabled, setDisabled] = useState<boolean>(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const [selectedCategories, setSelectedCategories] = useState<any>(
        props.selectedCategories
    );
    const [categoriesMap, setCategoriesMap] = useState<any>([]);
    const [allCategories, setAllCategories] = useState<any>([]);
    const [buttonState, setButtonState] = useState<boolean>(true);
    useEffect(() => {
        dispatch(setPageLoadingStatus({ isPageLoading: true }));
        Promise.all([getCountries(true), getAllProgramCategories()]).then(
            (responses: any) => {
                const countriesResponse = responses[0];
                const categoriesResponse = responses[1];
                if (countriesResponse.success) {
                    const countries: any = [];
                    countriesResponse.data.countries.map((country: any) => {
                        countries.push({
                            label: country.countryName,
                            value: `${country.countryId}#${country.countryName}`,
                        });
                    });
                    setCountriesMap(countries);
                    if (categoriesResponse.success) {
                        const catgories: any = [];
                        setAllCategories(categoriesResponse.data.categories);
                        categoriesResponse.data.categories.map(
                            (category: any) => {
                                catgories.push({
                                    label: category.categoryName,
                                    value: category.categoryId,
                                });
                            }
                        );
                        registry?.programs?.length &&
                            catgories.map((category: any) => {
                                registry.programs.map(
                                    (registryProgram: any) => {
                                        if (
                                            category.value ===
                                                registryProgram.categoryId &&
                                            registryProgram.hospitalCount > 0
                                        ) {
                                            category.disableInd = true;
                                        }
                                    }
                                );
                            });
                        setCategoriesMap(catgories);
                    }
                    dispatch(setPageLoadingStatus({ isPageLoading: false }));
                }
            }
        );
    }, []);

    const handleChange = (event: any) => {
        setRegistry({
            ...registry,
            [event.target.name]:
                event.target.name === "website"
                    ? event.target.value.toLowerCase()
                    : event.target.type == "textarea" ||
                      event.target.name === "registryName"
                    ? event.target.value.replace(/[><]/g, "")
                    : event.target.value,
        });
        setButtonState(false);
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        setDisabled(true);
        const isUpdate = !!props.registry?.id;
        const updateRegistry = registry;

        if (!updateRegistry.countryId) {
            delete updateRegistry.countryId;
            delete updateRegistry.countryName;
        }

        if (isUpdate) {
            delete updateRegistry.updatedBy; // delete updated by
            [
                // "id",
                "registeredEmailId",
                "isActivated",
                "isDeleted",
                "activatedDate",
                "deactivatedDate",
                "activatedBy",
                "deactivatedBy",
                "createdBy",
                "activationStatus",
                "createdAt",
                "updatedAt",
                "hospitalCount",
            ].forEach((attribute) => delete updateRegistry[attribute]);
            Object.keys(registry).forEach((attribute: any) => {
                if (
                    registry[attribute] === null ||
                    attribute == "totalHospitalsRegistered"
                ) {
                    delete updateRegistry[attribute];
                }
            });
            setRegistry(updateRegistry);
        }
        if (selectedCategories?.length > 0) {
            const selectedPrograms: any = [];
            selectedCategories.map((selectedCategory: any) => {
                allCategories.map((category: any) => {
                    if (selectedCategory.value == category.categoryId) {
                        selectedPrograms.push({
                            programId: category.programId,
                            programName: category.program.programName,
                            categoryId: category.categoryId,
                            categoryName: category.categoryName,
                        });
                    }
                });
            });
            setRegistry({ ...registry, programs: selectedPrograms });
            updateRegistry.programs = selectedPrograms;
        } else {
            const error = {
                error: "Error",
                message: "Please select program(s).",
                code: "Mandatory",
            };
            dispatch(showToast(error));
            setDisabled(false);
            return false; // Stop network call
        }
        saveRegistry(updateRegistry, isUpdate)
            .then((response: any) => {
                if (response.success) {
                    const toast = {
                        message: `Registry successfully ${
                            isUpdate ? "updated" : "created"
                        }.`,
                        code: "Success:",
                        type: "success",
                    };
                    dispatch(showToast(toast));
                    if (props.editMode) {
                        props.setEditMode(!props.editMode);
                    } else {
                        history.push(props.backToRegistryManagement);
                    }
                }
            })
            .catch((error: any) => {
                logError(error);
                setDisabled(false);
            });
    };

    return (
        <NewRegistryWrapper>
            <form onSubmit={handleSubmit}>
                <fieldset className="row" disabled={disabled}>
                    <div className="col-lg-11">
                        <div className="d-flex flex-row-reverse">
                            <div className="pb-3 mand-field">
                                <sup>*</sup>
                                mandatory fields
                            </div>
                        </div>
                        <div className="form-group row required">
                            <div className="col-md-4 form-label">
                                <label
                                    htmlFor="registryCode"
                                    className="label-form"
                                >
                                    Registry Code
                                    <sup>*</sup>
                                </label>
                            </div>
                            <div className="col-md-8">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="registryCode"
                                    name="registryCode"
                                    value={
                                        registry?.registryCode
                                            ? registry.registryCode
                                            : ""
                                    }
                                    onChange={handleChange}
                                    pattern={CONSTANTS.REGEX_ALLOW_ALPHANUMERIC}
                                    maxLength={30}
                                    required
                                    aria-describedby="regCode"
                                />
                                <div className="form-help" id="regCode">
                                    (Enter 1 to 30 characters)
                                </div>
                            </div>
                        </div>
                        <div className="form-group row required">
                            <div className="col-md-4 form-label">
                                <label
                                    htmlFor="registryName"
                                    className="label-form"
                                >
                                    Registry Name
                                    <sup>*</sup>
                                </label>
                            </div>
                            <div className="col-md-8">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="registryName"
                                    name="registryName"
                                    value={registry?.registryName}
                                    onChange={handleChange}
                                    maxLength={100}
                                    required
                                    aria-describedby="regName"
                                />
                                <div className="form-help" id="regName">
                                    (Enter 2 to 100 characters)
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-4 form-label">
                                <label htmlFor="registryStreetAddress">
                                    Street Address
                                </label>
                            </div>
                            <div className="col-md-8">
                                <textarea
                                    className="form-control"
                                    id="registryStreetAddress"
                                    name="registryStreetAddress"
                                    value={
                                        registry?.registryStreetAddress
                                            ? registry.registryStreetAddress
                                            : ""
                                    }
                                    onChange={handleChange}
                                    maxLength={200}
                                    aria-describedby="regAddress"
                                />
                                <div className="form-help" id="regAddress">
                                    (Enter 1 to 200 characters)
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-4 form-label">
                                <label
                                    htmlFor="countryName"
                                    id="countryName-label"
                                >
                                    Location
                                </label>
                            </div>
                            <div className="col-md-8">
                                {countriesMap && (
                                    <SearchDropdown
                                        disabled={disabled}
                                        items={countriesMap}
                                        selectedValue={`${registry?.countryId}#${registry?.countryName}`}
                                        callParentOnSelect={(value: any) => {
                                            const countryIdAndName =
                                                value?.split("#");
                                            setRegistry({
                                                ...registry,
                                                countryId: countryIdAndName[0],
                                                countryName:
                                                    countryIdAndName[1],
                                            });
                                            setButtonState(false);
                                        }}
                                        id="countryName"
                                        label="Select Location"
                                        placeholder="Select"
                                        ariaInnerLabelValue="countries"
                                    />
                                )}
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-4 form-label">
                                <label htmlFor="description">Description</label>
                            </div>
                            <div className="col-md-8">
                                <textarea
                                    className="form-control"
                                    id="description"
                                    name="description"
                                    value={
                                        registry?.description
                                            ? registry?.description
                                            : ""
                                    }
                                    onChange={handleChange}
                                    maxLength={300}
                                    aria-describedby="regDescription"
                                />
                                <div className="form-help" id="regDescription">
                                    (Enter 1 to 300 characters)
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-4 form-label">
                                <label htmlFor="website">Website</label>
                            </div>
                            <div className="col-md-8">
                                <input
                                    type="text"
                                    className="form-control"
                                    maxLength={100}
                                    id="website"
                                    name="website"
                                    value={
                                        registry?.website
                                            ? registry?.website
                                            : ""
                                    }
                                    onChange={handleChange}
                                    pattern={CONSTANTS.REGEX_ALLOW_WEBSITE}
                                    aria-describedby="regWebsite"
                                />
                                <div className="form-help" id="regWebsite">
                                    (Enter 3 to 100 characters)
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-4 form-label">
                                <label htmlFor="primaryContactName">
                                    Primary Contact Name
                                </label>
                            </div>
                            <div className="col-md-8">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="primaryContactName"
                                    name="primaryContactName"
                                    value={
                                        registry?.primaryContactName
                                            ? registry.primaryContactName
                                            : ""
                                    }
                                    onChange={handleChange}
                                    pattern={
                                        CONSTANTS.REGEX_ALLOW_ALPHABHET_SPACE
                                    }
                                    maxLength={100}
                                    aria-describedby="regPriName"
                                />
                                <div className="form-help" id="regPriName">
                                    (Enter 2 to 100 characters)
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-4 form-label">
                                <label htmlFor="primaryContactPhoneNumber">
                                    Primary Contact Phone Number
                                </label>
                            </div>
                            <div className="col-md-8">
                                <input
                                    minLength={10}
                                    maxLength={20}
                                    pattern="\+?\d*"
                                    type="text"
                                    className="form-control"
                                    id="primaryContactPhoneNumber"
                                    name="primaryContactPhoneNumber"
                                    value={
                                        registry?.primaryContactPhoneNumber
                                            ? registry.primaryContactPhoneNumber
                                            : ""
                                    }
                                    onChange={handleChange}
                                    aria-describedby="regPriPhone"
                                />
                                <div className="form-help" id="regPriPhone">
                                    (Enter 10 to 20 characters)
                                </div>
                            </div>
                        </div>
                        <div className="form-group row required">
                            <div className="col-md-4 form-label">
                                <label
                                    htmlFor="primaryContactEmailAddress"
                                    className="label-form"
                                >
                                    Primary Contact Email Address
                                    <sup>*</sup>
                                </label>
                            </div>
                            <div className="col-md-8">
                                <input
                                    type="email"
                                    className="form-control"
                                    id="primaryContactEmailAddress"
                                    name="primaryContactEmailAddress"
                                    value={
                                        registry?.primaryContactEmailAddress
                                            ? registry.primaryContactEmailAddress
                                            : ""
                                    }
                                    onChange={handleChange}
                                    pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$"
                                    maxLength={100}
                                    required
                                    aria-describedby="regPriEmail"
                                />
                                <div className="form-help" id="regPriEmail">
                                    (Enter 3 to 100 characters)
                                </div>
                            </div>
                        </div>
                        <div className="form-group row required">
                            <div className="col-md-4 form-label">
                                <label
                                    htmlFor="programAssociated"
                                    id="dropdown-label-programAssociated"
                                    className="label-form"
                                >
                                    Programs Associated with
                                    <sup>*</sup>
                                </label>
                            </div>
                            <div className="col-md-8">
                                <MultiSelectDropdown
                                    id="programAssociated"
                                    items={categoriesMap}
                                    selectedValue={selectedCategories}
                                    callParentOnSelect={(value: any) => {
                                        setSelectedCategories(value);
                                        setButtonState(false);
                                    }}
                                    disabled={disabled}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="registry-submit-btns col-lg-11 px-0">
                            <div className="col-md-8 offset-md-4 px-0 px-md-3 mb-4 d-flex justify-content-start flex-column flex-sm-row">
                                <button
                                    type="button"
                                    className="btn btn-secondary btn-round"
                                    disabled={disabled}
                                    onClick={(event: any) => {
                                        event.preventDefault();
                                        if (props.editMode) {
                                            props.setEditMode(!props.editMode);
                                        } else {
                                            history.push(
                                                props.backToRegistryManagement
                                            );
                                        }
                                    }}
                                    aria-label="Cancel registry"
                                >
                                    Cancel
                                </button>
                                <button
                                    disabled={buttonState}
                                    type="submit"
                                    className={`btn btn-round btn-primary ${
                                        disabled ? CONSTANTS.BUTTON_SPINNER : ""
                                    }`}
                                    aria-label="Save Registry"
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </form>
        </NewRegistryWrapper>
    );
};

export default AddOrEditRegistry;
