import styled from "styled-components";

export const AccordionWrapper = styled.nav``;
export const ArchivedDocumentWrapper = styled.div``;
export const ArchivedCertificateWrapper = styled.div`
    word-break: break-word;
    .year-period {
        font-weight: 400;
        font-size: 18px;
    }
    .org-heading {
        padding: 10px 0 10px 0;
        border-bottom: 4px solid #c10e21;
    }
    .org-status {
        border-radius: 4px;
        border: 1px solid #222328;
        font-size: 12px;
        font-weight: 500;
        padding: 4px 6px 4px 6px;
    }
    .cert-info {
        background: #f8f8f8;
        @media only screen and (min-width: 768px) {
            width: 260px;
        }
        @media only screen and (min-width: 992px) {
            width: 320px;
        }
    }
    .cert-info-facility {
        padding: 26px 20px 20px;
        border-bottom: 1px solid #e3e3e3;
        @media only screen and (min-width: 768px) {
            padding: 47px 20px 20px;
        }
    }
    .cert-info-detail {
        padding: 20px;
    }
    .cert-info-item {
        margin-bottom: 20px;
    }
    .cert-container {
        padding-bottom: 50px;
        @media only screen and (min-width: 768px) {
            width: calc(100% - 260px);
            padding: 0 0 0 48px;
        }
        @media only screen and (min-width: 992px) {
            width: calc(100% - 320px);
            padding-bottom: 50px;
        }
    }
    .cert-prm {
        color: #555555;
    }
    .cert-img {
        width: 100%;
    }
    .cert-tags {
        margin-bottom: 100px;
        .cert-tag-btn {
            display: inline-block;
            background: #ffffff;
            border: 1px solid #c10e21;
            line-height: 20px;
            padding: 10px 14px;
            font-size: 18px;
            border-radius: 21px;
            margin: 0 22px 20px 0;
            color: #222328;
            &:hover {
                text-decoration: none;
                color: #222328;
            }
        }
    }
`;
