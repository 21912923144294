import React from "react";
import { MeasuresDateFilter } from "components/MeasuresDateFilter";
import CONSTANTS from "common/constants";
import { MeasuresEmptyStateWrapper } from "./styled";

interface Props {
    add: any;
    isEditable: boolean;
    isAddMeasuresInProgress: boolean;
    measureLock?: boolean;
}

const MeasureEmptyState = (props: Props) => {
    return (
        <MeasuresEmptyStateWrapper className="container measure-emptystate-container">
            {props.isEditable ? (
                <div className="measure-emptystate d-flex justify-content-center flex-column text-center align-items-center py-4">
                    <img
                        src="/images/folder-icon.svg"
                        alt="folder icon"
                        className="mb-5 folder-icon"
                    />
                    <p className="pt-4 mb-0">
                        No measures available
                        <br /> Click below to add
                    </p>
                    <button
                        className={`btn btn-primary btn-round create-measure-btn ${
                            props.isAddMeasuresInProgress
                                ? CONSTANTS.BUTTON_SPINNER
                                : ""
                        }`}
                        onClick={props.add}
                        disabled={
                            props.isAddMeasuresInProgress || props.measureLock
                        }
                    >
                        Create Measures
                    </button>
                </div>
            ) : (
                <div className="measure-emptystate d-flex justify-content-center flex-column text-center align-items-center py-4">
                    <img
                        src="/images/edit-block-icon.svg"
                        alt="folder icon"
                        className="mb-5 block-edit-icon"
                    />
                    <p className="pt-4">
                        Timeline for creating measures for the this quarter has
                        expired
                    </p>
                </div>
            )}
        </MeasuresEmptyStateWrapper>
    );
};

export default MeasureEmptyState;
