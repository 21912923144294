import React, { useEffect, useState } from "react";
import "styles/global.scss";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { getArchivalAccordionDatesAPI } from "api/archivalDocumentIntegrationAPI";
import { logger } from "utils/logger.utils";
import { localDateToYYYYMMDDFormatString } from "common/utils";
import { AccordionWrapper } from "./styled";

interface Props {
    activeTab: string;
}
const AccordionSidebar = ({ activeTab }: Props) => {
    const params: any = useParams();
    const [accordianDate, setAccordianDate] = useState<any>();
    const location = useLocation();

    useEffect(() => {
        getArchivalAccordionDatesAPI(params?.hospitalId)
            .then((res: any) => {
                if (res.statusCode === 200) {
                    setAccordianDate(res?.data?.archiveDates);
                }
            })
            .catch((err: any) => {
                logger(err);
            });
    }, []);
    return (
        <AccordionWrapper className="navbar-expand-lg" id="accordian-sidebar">
            <div className="d-flex justify-content-between align-items-center back-button">
                <div className=" mb-0 mb-lg-3">
                    <Link
                        to={{
                            pathname: `/admin/organization-management/organization-details/${params.hospitalId}/program/${params.programId}/category/${params.categoryId}`,
                            state: {
                                backToFaciclityManagement:
                                    "/admin/hospital-management",
                                adminfacilityMangemnetTab:
                                    location?.state?.customState?.state
                                        ?.adminfacilityMangemnetTab,
                                facilityName:
                                    location?.state?.customState?.state
                                        ?.facilityName,
                            },
                        }}
                        className="previous-page-link d-inline-block"
                    >
                        <i
                            className="aha-icon-arrow-left d-inline-block "
                            aria-hidden="true"
                        />
                        Back
                    </Link>
                </div>

                <button
                    className="navbar-toggler float-right divider"
                    type="button"
                    data-toggle="collapse"
                    data-target="#sideNavbar"
                    aria-controls="sideNavbar"
                    aria-expanded="false"
                    aria-label="Toggle navigation archive dates"
                >
                    <i className="aha-icon-hamburger-round" />
                </button>
            </div>
            <div className="collapse navbar-collapse" id="sideNavbar">
                <ul className="w-100 dropdown-ul">
                    {accordianDate &&
                        accordianDate.map((date: any, index: any) => (
                            <li
                                key={index}
                                id={`sidenav-accordion-dropdown-${index}`}
                                className="accordion-dropdown"
                            >
                                <button
                                    className="btn btn-text aui-sidenav-acc-header font-700"
                                    id={`sidenavHeading-${index}`}
                                    data-toggle="collapse"
                                    data-target={`#sidenavcollapse-${index}`}
                                    aria-expanded="false"
                                    aria-controls={`sidenavcollapse-${index}`}
                                >
                                    {localDateToYYYYMMDDFormatString(date)}
                                    <i className="acc-btn-arrow aha-icon-arrow-down" />
                                </button>
                                <ul
                                    id={`sidenavcollapse-${index}`}
                                    className="aui-sidenav-acc-body collapse show"
                                    aria-labelledby={`sidenavHeading-${index}`}
                                    data-parent={`#sidenav-accordion-dropdown-${index}`}
                                >
                                    <li
                                        className={
                                            activeTab === "documents"
                                                ? "activeSideNav"
                                                : ""
                                        }
                                        aria-current={
                                            activeTab === "documents"
                                                ? "page"
                                                : false
                                        }
                                    >
                                        <Link
                                            to={{
                                                pathname: `/admin/organization-management/archived-documents/${params.hospitalId}/program/${params.programId}/category/${params.categoryId}/date/${date}`,
                                            }}
                                            className="listing-items"
                                        >
                                            Documents
                                        </Link>
                                    </li>
                                    <li
                                        className={
                                            activeTab === "certification"
                                                ? "activeSideNav"
                                                : ""
                                        }
                                        aria-current={
                                            activeTab === "certification"
                                                ? "page"
                                                : false
                                        }
                                    >
                                        <Link
                                            to={{
                                                pathname: `/admin/organization-management/archived-certificate/${params.hospitalId}/program/${params.programId}/category/${params.categoryId}/date/${date}`,
                                            }}
                                            className="listing-items"
                                        >
                                            Certification
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                        ))}
                </ul>
            </div>
        </AccordionWrapper>
    );
};

export default AccordionSidebar;
