import store from "app/store";
import { showToast } from "components/Toast/toast.slice";
import APIUtils from "utils/API.utils";
import CONSTANTS from "common/constants";
import config from "../config";
import { paymentMadeBy } from "../common/utils";

const API = new APIUtils();
const userAccessToken = window.localStorage.getItem("userAccessToken");
const accountBaseUrl = `${config[config.env].apiEndpoints.accounts}`;
export const getProgramPrice = async (
    programId: any,
    categoryId: any
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const APIRes = await API.GET(
                `${
                    config[config.env].apiEndpoints.catalogs
                }/programPrices/${programId}/categories/${categoryId}`
            );

            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const getBillingDetails = async (
    hospitalId: number,
    categoryId: number
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const APIRes = await API.GET(
                `${
                    config[config.env].apiEndpoints.accounts
                }/billingDetails/hospitals/${hospitalId}/categories/${categoryId}`
            );

            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const updateBillingDetails = async (
    hospitalId: number,
    categoryId: number,
    billingDetails: any,
    isEUP?: any
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const reqBody = {
                billingDetails: await billingDetails,
            };
            const APIRes = await API.PATCH(
                `${
                    config[config.env].apiEndpoints.accounts
                }/billingDetails/hospitals/${hospitalId}/categories/${categoryId}`,
                reqBody
            );
            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            err.details[0] = await paymentMadeBy(
                err.code,
                err.details[0],
                isEUP
            );
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const getProratePrice = async (
    programId: number,
    categoryId: number
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const APIRes = await API.GET(
                `${
                    config[config.env].apiEndpoints.catalogs
                }/programPrices/${programId}/categories/${categoryId}/prorate`
            );
            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const programSubscription = async (
    hospitalId: number,
    programId: number,
    categoryId: number,
    modeOfPayment: string = CONSTANTS.PAYMENT_ONLINE,
    isEUP?: any
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const reqBody = {
                hospitalId,
                programId,
                categoryId,
                modeOfPayment,
            };

            const APIRes = await API.POST(
                `${
                    config[config.env].apiEndpoints.accounts
                }/programSubscription`,
                reqBody
            );
            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;

            err.details[0] = await paymentMadeBy(
                err.code,
                err.details[0],
                isEUP
            );
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const getPaymentStatus = async (referenceId: string): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const APIRes = await API.GET(
                `${
                    config[config.env].apiEndpoints.accounts
                }/programSubscription/${referenceId}`
            );
            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const getOrder = async (orderId: string): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const APIRes = await API.GET(
                `${config[config.env].apiEndpoints.payments}/orders/${orderId}`
            );
            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const skipPayment = async (
    hospitalId: number,
    programId: number,
    categoryId: number,
    isEUP?: any
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const reqBody = {
                hospitalId,
                programId,
                categoryId,
                paymentMode: "OFFLINE",
            };

            const APIRes = await API.PATCH(
                `${accountBaseUrl}/hospitalPrograms/paymentMode`,
                reqBody
            );

            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            err.details[0] = await paymentMadeBy(
                err.code,
                err.details[0],
                isEUP
            );
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};
