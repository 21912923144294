import React from "react";
import UploadDocumentContent, {
    IUploadDocForm,
} from "./UploadDocumentModalContent";

interface Props {
    uploadProgram: any;
    standards: any;
    setRefreshTableDateNow: any;
    initUploadDocForm: IUploadDocForm;
    uploadDocForm: IUploadDocForm;
    setUploadDocForm: any;
    setuploadDocumentInd: any;
    uploadDocumentInd: any;
    isEUP: boolean;
    facilityCount?: any;
    facilityIds?: any;
    setCount?: any;
    setSelectAll?: any;
    setSelection?: any;
    setFacilityIds?: any;
}

const UploadDocument = (props: Props) => {
    return (
        <div
            className="modal fade show aui-modal"
            style={{ display: props.uploadDocumentInd ? "block" : "none" }}
            id="uploadDocModal"
            tabIndex={-1}
            aria-labelledby="uploadDocModalLabel"
            aria-modal="true"
            role="dialog"
            data-backdrop="static"
            data-keyboard="false"
        >
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <UploadDocumentContent
                    uploadProgram={props.uploadProgram}
                    standards={props.standards}
                    setRefreshTableDateNow={props.setRefreshTableDateNow}
                    initUploadDocForm={props.initUploadDocForm}
                    uploadDocForm={props.uploadDocForm}
                    setUploadDocForm={props.setUploadDocForm}
                    isEUP={props.isEUP}
                    facilityCount={props.facilityCount}
                    facilityIds={props.facilityIds}
                    setCount={props.setCount}
                    setSelectAll={props.setSelectAll}
                    setSelection={props.setSelection}
                    setFacilityIds={props.setFacilityIds}
                    setuploadDocumentInd={props.setuploadDocumentInd}
                />
            </div>
        </div>
    );
};

export default UploadDocument;
