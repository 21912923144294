import React from "react";
import HomePage from "components/Home/HomePage";

interface Props {}

const EUPHomePage = () => {
    return <HomePage userType="EUPPortal" className="eup-Portal" />;
};

export default EUPHomePage;
