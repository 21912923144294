import React, { createRef, useState } from "react";
import { SearchWrapper } from "./styled";

interface Props {
    searchKey: string;
    setSearchKey: any;
    getSearchedItems: any;
    placeholder?: string;
    arialabel: any;
    id: string;
    setSearchTimer?: any;
}

export const SmartSearch = (props: Props) => {
    const [localtimer, setLocalTimer] = useState<any>();
    const dropDownRef = createRef<HTMLInputElement>();
    const handleSearch = (value: any) => {
        const timeOutTimer = setTimeout(() => {
            props.getSearchedItems(value);
        }, 1000);
        setLocalTimer(timeOutTimer);
        if (props.setSearchTimer) {
            props.setSearchTimer(timeOutTimer);
        }
    };

    return (
        <SearchWrapper className="smart-search-block">
            <form>
                <input
                    type="search"
                    className="search-field"
                    id={props.id}
                    ref={dropDownRef}
                    placeholder={
                        props.placeholder
                            ? props.placeholder
                            : "Search Organization"
                    }
                    onKeyPress={(event) => {
                        if (event.charCode === 13) {
                            event.preventDefault();
                            clearTimeout(localtimer);
                            props.getSearchedItems(props.searchKey);
                        }
                    }}
                    onChange={({ target }) => {
                        clearTimeout(localtimer);
                        props.setSearchKey(target.value.replace(/[><]/g, ""));
                        target.value.replace(/[><]/g, "")
                            ? handleSearch(target.value.replace(/[><]/g, ""))
                            : props.getSearchedItems("");
                    }}
                    value={props.searchKey}
                    aria-label={props.arialabel}
                />
                <button
                    type="button"
                    className="btn btn-text aha-icon-cross search-clear"
                    aria-label="Clear Search"
                    onClick={() => {
                        clearTimeout(localtimer);
                        props.setSearchKey("");
                        props.getSearchedItems("");
                        dropDownRef &&
                            dropDownRef.current &&
                            dropDownRef.current.focus();
                    }}
                />
                <i className="aha-icon-search search-icon" aria-hidden="true" />
            </form>
        </SearchWrapper>
    );
};

export default SmartSearch;
