import styled from "styled-components";

export const HospitalManagementWrapper = styled.div`
    .fac-search {
        margin: 30px 0 16px;
        width: 100%;
        display: flex;
        flex-direction: column;
        @media only screen and (min-width: 768px) {
            margin: 30px 0 24px;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;
        }
        .table-rowcount {
            order: 2;
            margin-top: 24px;
            @media only screen and (min-width: 768px) {
                order: 1;
                margin-top: 0;
            }
        }
        .smart-search-block {
            width: 100%;
            order: 1;
            @media only screen and (min-width: 768px) {
                width: 300px;
                order: 2;
            }
            @media only screen and (min-width: 1200px) {
                width: 500px;
            }
        }
    }
    .dashboard-select-dropdown {
        width: 100%;
        @media only screen and (min-width: 768px) {
            width: 154px;
        }
    }
    .dashboard-fac-magnt {
        margin-bottom: 24px;
    }
    .fac-accordion-tab1 {
        order: 1;
        @media only screen and (min-width: 768px) {
            order: 1;
        }
    }
    .fac-accordion-tab2 {
        order: 3;
        @media only screen and (min-width: 768px) {
            order: 2;
        }
    }
    .fac-accordion-tab3 {
        order: 5;
        @media only screen and (min-width: 768px) {
            order: 3;
        }
    }
    .fac-accordion-tab4 {
        order: 7;
        @media only screen and (min-width: 768px) {
            order: 4;
        }
    }
    .fac-accordion-content1 {
        order: 2;
        @media only screen and (min-width: 768px) {
            order: 5;
        }
    }
    .fac-accordion-content2 {
        order: 4;
        @media only screen and (min-width: 768px) {
            order: 6;
        }
    }
    .fac-accordion-content3 {
        order: 6;
        @media only screen and (min-width: 768px) {
            order: 7;
        }
    }
    .fac-accordion-content4 {
        order: 8;
        @media only screen and (min-width: 768px) {
            order: 8;
        }
    }
    .aui-accordion-tab .aui-accordion-content {
        z-index: auto;
    }
    .tab-filled {
        .tab-filled-item {
            border-radius: unset;
            @media only screen and (min-width: 768px) {
                margin-right: 14px;
                border-radius: 4px;
            }
            button {
                padding: 12px 6px;
                line-height: 1;
                border: 1px solid #e3e3e3;
                font-weight: 500;
                background-color: #fff;
                color: #222328;
                @media only screen and (min-width: 576px) {
                    padding: 12px;
                }
                @media only screen and (min-width: 768px) {
                    padding: 15px 20px;
                }
                &.active {
                    border: 1px solid #c10e21;
                    background: #c10e21;
                    color: #fff;
                }
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }
    .export-admin {
        position: relative;
        .export-admin-icon {
            font-size: 18px;
        }
    }
    .export-export-btn {
        .btn {
            width: 100%;
            @media only screen and (min-width: 576px) {
                width: auto;
            }
        }
    }
    .aui-modal .modal-header {
        border-bottom: 2px solid rgb(193, 14, 33);
    }
    .global-export-tooltip {
        position: absolute;
        top: 100%;
        right: 0;
        display: none;
    }
    .global-export-btn {
        &:focus,
        &:hover {
            & + .global-export-tooltip {
                &:before,
                &:after {
                    left: auto;
                    right: 30px;
                }
                display: block;
                .tooltip-inner {
                    @media only screen and (min-width: 992px) {
                        width: 190px;
                    }
                }
            }
        }
    }
`;
