import { wordSplit } from "common/utils";
import React from "react";
import { RegistryBannerWrapper } from "./styled";

interface Props {
    registryName: string;
    registryCode: string;
    countryName?: string;
}
export const RegistryBanner = (props: Props) => {
    return (
        <RegistryBannerWrapper className="hospital-banner">
            <h2 className="h3 hospital-banner-title">{props.registryName}</h2>
            <div className="d-md-flex hospital-banner-detail">
                <div className="hospital-banner-program">
                    Registry Code:{" "}
                    <span className="font-bold abbr-code">
                        {wordSplit(props.registryCode)}
                    </span>
                </div>
                <div className="ml-md-5 mt-2 mt-md-0">
                    Location:{" "}
                    <span className="font-bold">
                        {props.countryName || "N/A"}
                    </span>
                </div>
            </div>
        </RegistryBannerWrapper>
    );
};

export default RegistryBanner;
