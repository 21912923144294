import styled from "styled-components";

export const FacilitiesListingWrapper = styled.div`
    .hospital-banner {
        border-color: #e3e3e3;
    }
    .registry-facilities-table {
        thead {
            @media only screen and (min-width: 992px) {
                th:nth-child(1) {
                    width: 40%;
                }
                th:nth-child(2) {
                    width: 40%;
                }
                th:nth-child(3) {
                    width: 20%;
                }
            }
        }
        thead,
        tbody {
            th:nth-child(3),
            td:nth-child(3) {
                @media only screen and (min-width: 992px) {
                    text-align: center;
                }
            }
        }
        a {
            color: #c10e21;
        }
        tbody {
            tr {
                td:last-child {
                    i {
                        font-size: 20px;
                    }
                }
            }
        }
    }
`;
