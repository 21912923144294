import styled from "styled-components";

export const SidebarWrapper = styled.aside`
    .aha-icon-arrow-down:before {
        font-size: 8px;
    }
    .aui-sidenav .navbar-toggler {
        margin: 0;
    }
`;
