import React, { useEffect, useState } from "react";
import DocumentServiceAPI from "api/documentsAPI";
import CONSTANTS from "common/constants";
import Dropdown from "components/Dropdown";
import { useLocation, useParams } from "react-router-dom";
import { localDateToYYYYMMDDFormatString } from "../../common/utils";
import { BulkUploadWrapper } from "./Styled";

const documentServiceAPI = new DocumentServiceAPI();

interface Props {
    documentDetails: any;
    selectedProgram: string;
    resubmitDocument?: any;
    isEUP?: boolean;
    setStatus?: any;
    setDeleteInd: any;
    setDeleteDoc: any;
    setDataDismissInd: any;
}

const DocumentDetails = ({
    documentDetails,
    selectedProgram,
    resubmitDocument,
    isEUP,
    setStatus,
    setDeleteInd,
    setDeleteDoc,
    setDataDismissInd,
}: Props) => {
    const params: any = useParams();
    const location = useLocation();
    const hospitalId: any = params.hospitalId
        ? params.hospitalId
        : localStorage.getItem("selectedHospitalId");
    const [statusDropdown, setStatusDropdown] = useState<any>([
        {
            label: "Approved",
            dropdownLabel: "Approve",
            value: "APPROVED",
            disableInd: false,
        },
        {
            label: "Pending",
            dropdownLabel: "Pending",
            value: "PENDING",
            disableInd: false,
        },
        {
            label: "Rejected",
            dropdownLabel: "Reject",
            value: "REJECTED",
            disableInd: false,
        },
    ]);

    useEffect(() => {
        statusDropdown.map((dropItem: any) => {
            if (
                (["APPROVED", "REJECTED"].includes(
                    documentDetails[0].approvalStatus
                ) &&
                    dropItem.value !== "PENDING") ||
                documentDetails[0].approvalStatus === dropItem.value
            ) {
                dropItem.disableInd = true;
            } else {
                dropItem.disableInd = false;
            }
        });
        setStatusDropdown(statusDropdown);
        if (CONSTANTS.WINDOW.AUI.Tooltip) {
            CONSTANTS.WINDOW.AUI.Tooltip.init();
        }
    }, [documentDetails]);

    const downloadFile = (downloadId: number) => {
        documentServiceAPI.download(downloadId, hospitalId);
    };

    const getStatusText = (status: string) => {
        if (status === "PENDING") {
            return "Pending";
        }
        if (status === "APPROVED") {
            return "Approved";
        }
        if (status === "REJECTED") {
            return "Rejected";
        }
    };

    const setDocumentDetailStatus = (approvalStatus: any) => {
        switch (approvalStatus) {
            case CONSTANTS.REGISTRED:
                return "aui-table-status-success";
            case CONSTANTS.PENDING:
                return "aui-table-status-pending";
            case CONSTANTS.REJECTED:
                return "aui-table-status-danger";
            default:
                return "aui-table-status-pending";
        }
    };

    const getUploadedBy = (uploadedBy: string) => {
        switch (uploadedBy) {
            case CONSTANTS.USER_ROLES.HOSPITAL_ADMIN:
                return CONSTANTS.UPLOADED_BY.FACILITY;
            case CONSTANTS.USER_ROLES.CORPORATION_ADMIN:
                return CONSTANTS.UPLOADED_BY.CORPORATION;
            default:
                return "N/A";
        }
    };

    return (
        <>
            <table className="aui-responsive-table aui-table-cols view-doc-submit-table mb-5">
                <tbody>
                    {isEUP && (
                        <tr>
                            <th scope="row" className="aui-row-th">
                                Status
                            </th>
                            <td className="aui-row-td font-red" id="status">
                                {getStatusText(
                                    documentDetails[0].approvalStatus
                                )}
                            </td>
                        </tr>
                    )}
                    <tr>
                        <th scope="row" className="aui-row-th">
                            Title
                        </th>
                        <td className="aui-row-td" id="Title">
                            {documentDetails[0].documentTitle}
                        </td>
                    </tr>
                    <tr>
                        <th scope="row" className="aui-row-th">
                            Selected Program
                        </th>
                        <td className="aui-row-td" id="selected-program">
                            {selectedProgram}
                        </td>
                    </tr>
                    <tr>
                        <th scope="row" className="aui-row-th">
                            Selected Standard
                        </th>
                        <td className="aui-row-td" id="standard-no">
                            {documentDetails[0].standardName}
                        </td>
                    </tr>
                    <tr>
                        <th scope="row" className="aui-row-th">
                            Description
                        </th>
                        <td className="aui-row-td" id="descriptn">
                            {documentDetails[0].documentDescription}
                        </td>
                    </tr>
                    {isEUP === undefined && (
                        <tr>
                            <th scope="row" className="aui-row-th">
                                Type
                            </th>
                            <td className="aui-row-td" id="typeoffile">
                                {`${documentDetails[0].fileType}`.toUpperCase()}
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
            <h3 className="h7">Log of Uploaded Files</h3>
            <BulkUploadWrapper className="doc-log-table">
                <table className="aui-responsive-status-table">
                    <thead>
                        <tr>
                            <th scope="col">
                                <div className="aui-th">User Messages</div>
                            </th>
                            <th scope="col">
                                <div className="aui-th">Reviewer Messages</div>
                            </th>
                            <th scope="col">
                                <div className="aui-th">Status</div>
                            </th>
                            <th scope="col">
                                <div className="aui-th justify-content-center">
                                    Action
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {documentDetails.map((documentDetail: any) => (
                            <tr
                                key={documentDetail.hospitalProgramUploadsId}
                                className={setDocumentDetailStatus(
                                    documentDetail.approvalStatus
                                )}
                            >
                                <td data-title="User Messages">
                                    <div
                                        className="aui-td flex-column align-items-start"
                                        id={`doc-usermessage-${documentDetail.documentId}`}
                                    >
                                        <div className="flex-grow-1">
                                            {documentDetail.facilityMessage ||
                                                "Not Available"}
                                        </div>
                                        {(documentDetail[
                                            "createdUserDetails.role.role_code"
                                        ]
                                            ? documentDetail[
                                                  "createdUserDetails.role.role_code"
                                              ]
                                            : documentDetail[
                                                  "userDetailsArchive.role.role_code"
                                              ]) !==
                                            CONSTANTS.USER_ROLES.AHA_ADMIN && (
                                            <div className="doc-log-detail">
                                                <div className="flex-grow-1">{`${getUploadedBy(
                                                    documentDetail[
                                                        "createdUserDetails.role.role_code"
                                                    ]
                                                        ? documentDetail[
                                                              "createdUserDetails.role.role_code"
                                                          ]
                                                        : documentDetail[
                                                              "userDetailsArchive.role.role_code"
                                                          ]
                                                )} - ${
                                                    documentDetail[
                                                        "createdUserDetails.user_first_name"
                                                    ]
                                                        ? documentDetail[
                                                              "createdUserDetails.user_first_name"
                                                          ]
                                                        : documentDetail[
                                                              "userDetailsArchive.user_first_name"
                                                          ]
                                                }  ${
                                                    documentDetail[
                                                        "createdUserDetails.user_last_name"
                                                    ]
                                                        ? documentDetail[
                                                              "createdUserDetails.user_last_name"
                                                          ]
                                                        : documentDetail[
                                                              "userDetailsArchive.user_last_name"
                                                          ]
                                                }`}</div>
                                                <div className="log-date ml-md-2 mt-1 mt-md-0">
                                                    {localDateToYYYYMMDDFormatString(
                                                        documentDetail.createdAt
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                        {documentDetail.bulkUploadId ? (
                                            <div
                                                className="bulk-uploadedby px-2 py-1 mt-2"
                                                data-toggle="tooltip"
                                                data-placement="bottom"
                                                data-container={`#doc-usermessage-${documentDetail.documentId}`}
                                                title={
                                                    documentDetail[
                                                        "createdUserDetails.corporation_users.corpUser.corporationName"
                                                    ]
                                                        ? documentDetail[
                                                              "createdUserDetails.corporation_users.corpUser.corporationName"
                                                          ]
                                                        : documentDetail[
                                                              "userDetailsArchive.corporation_users.corpUser.corporationName"
                                                          ]
                                                }
                                                aria-label={`Document uploaded by: ${documentDetail["createdUserDetails.corporation_users.corpUser.corporationName"]}`}
                                                role="note"
                                            >
                                                Document uploaded by:{" "}
                                                {documentDetail[
                                                    "createdUserDetails.corporation_users.corpUser.corporationName"
                                                ]
                                                    ? documentDetail[
                                                          "createdUserDetails.corporation_users.corpUser.corporationName"
                                                      ]
                                                    : documentDetail[
                                                          "userDetailsArchive.corporation_users.corpUser.corporationName"
                                                      ]}
                                            </div>
                                        ) : null}
                                    </div>
                                </td>
                                <td data-title="Reviewer Messages">
                                    <div className="aui-td flex-column align-items-start">
                                        <div className="flex-grow-1 d-flex align-items-center">
                                            {documentDetail.approvalStatus ==
                                            CONSTANTS.PENDING
                                                ? "Review Pending"
                                                : documentDetail.reviewComment ||
                                                  "Not Available"}
                                        </div>
                                        <div className="doc-log-detail">
                                            <div className="flex-grow-1">
                                                {(documentDetail[
                                                    "reviewedUserDetails.user_first_name"
                                                ]
                                                    ? documentDetail[
                                                          "reviewedUserDetails.user_first_name"
                                                      ]
                                                    : documentDetail[
                                                          "reviewerDetailsArchive.user_first_name"
                                                      ]) &&
                                                    (documentDetail[
                                                        "reviewedUserDetails.user_last_name"
                                                    ]
                                                        ? documentDetail[
                                                              "reviewedUserDetails.user_last_name"
                                                          ]
                                                        : documentDetail[
                                                              "reviewerDetailsArchive.user_last_name"
                                                          ]) &&
                                                    `${
                                                        documentDetail[
                                                            "reviewedUserDetails.user_first_name"
                                                        ]
                                                            ? documentDetail[
                                                                  "reviewedUserDetails.user_first_name"
                                                              ]
                                                            : documentDetail[
                                                                  "reviewerDetailsArchive.user_first_name"
                                                              ]
                                                    }  ${
                                                        documentDetail[
                                                            "reviewedUserDetails.user_last_name"
                                                        ]
                                                            ? documentDetail[
                                                                  "reviewedUserDetails.user_last_name"
                                                              ]
                                                            : documentDetail[
                                                                  "reviewerDetailsArchive.user_last_name"
                                                              ]
                                                    }`}
                                            </div>
                                            <div className="log-date ml-md-2 mt-1 mt-md-0">
                                                {documentDetail.approvalStatus !==
                                                CONSTANTS.PENDING
                                                    ? localDateToYYYYMMDDFormatString(
                                                          documentDetail.updatedAt
                                                      )
                                                    : ""}
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td data-title="Status">
                                    <div className="aui-td justify-content-start doc-status-col">
                                        <div className="log-actions w-100">
                                            {statusDropdown &&
                                            !isEUP &&
                                            documentDetail.isLatest ? (
                                                <>
                                                    <Dropdown
                                                        id="statusSelected"
                                                        items={statusDropdown}
                                                        selectedValue={
                                                            documentDetail.approvalStatus
                                                        }
                                                        callParentOnSelect={(
                                                            value: any
                                                        ) => {
                                                            setStatus(value);
                                                        }}
                                                        disabled={
                                                            (documentDetail[
                                                                "createdUserDetails.role.role_code"
                                                            ]
                                                                ? documentDetail[
                                                                      "createdUserDetails.role.role_code"
                                                                  ]
                                                                : documentDetail[
                                                                      "userDetailsArchive.role.role_code"
                                                                  ]) ===
                                                                CONSTANTS
                                                                    .USER_ROLES
                                                                    .AHA_ADMIN ||
                                                            documentDetail[
                                                                "userDetailsArchive.role.role_code"
                                                            ] ===
                                                                CONSTANTS
                                                                    .USER_ROLES
                                                                    .HOSPITAL_ADMIN ||
                                                            documentDetail[
                                                                "userDetailsArchive.role.role_code"
                                                            ] ===
                                                                CONSTANTS
                                                                    .USER_ROLES
                                                                    .CORPORATION_ADMIN
                                                        }
                                                        approveRejectInd
                                                        ariaInnerLabelValue="Change Status"
                                                    />
                                                </>
                                            ) : (
                                                <div>
                                                    {getStatusText(
                                                        documentDetail.approvalStatus
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </td>
                                <td data-title="Action">
                                    <div
                                        className="aui-td align-items-center justify-content-center doc-action-items"
                                        id={`view-doc-${documentDetail.documentId}`}
                                    >
                                        <button
                                            className="btn-text-link-uline"
                                            onClick={() =>
                                                downloadFile(
                                                    documentDetail.documentId
                                                )
                                            }
                                            data-toggle="tooltip"
                                            data-placement="left"
                                            data-container={`#view-doc-${documentDetail.documentId}`}
                                            title="Download"
                                            aria-label={`download document ${documentDetails[0].documentTitle}`}
                                        >
                                            <i
                                                className="aha-icon-download font-red"
                                                aria-hidden="true"
                                            />
                                        </button>
                                        {(documentDetail.approvalStatus ===
                                            CONSTANTS.PENDING ||
                                            (!isEUP &&
                                                !documentDetail.isLatest &&
                                                documentDetail.approvalStatus ===
                                                    CONSTANTS.REJECTED &&
                                                !location?.state?.isArchived) ||
                                            documentDetail[
                                                "createdUserDetails.role.role_code"
                                            ] ===
                                                CONSTANTS.USER_ROLES
                                                    .AHA_ADMIN) && (
                                            <button
                                                className="btn-text-link-uline"
                                                onClick={() => {
                                                    setDeleteDoc({
                                                        id: documentDetail.documentId,
                                                        title: documentDetail.documentTitle,
                                                        createdAt:
                                                            documentDetail.createdAt,
                                                    });
                                                    setDeleteInd(true);
                                                    if (
                                                        documentDetails.length >
                                                        1
                                                    ) {
                                                        setDataDismissInd(
                                                            false
                                                        );
                                                    }
                                                }}
                                                data-toggle="tooltip"
                                                data-placement="left"
                                                data-container={`#view-doc-${documentDetail.documentId}`}
                                                title="Delete"
                                                aria-label={`delete document ${documentDetails[0].documentTitle}`}
                                            >
                                                <i
                                                    className="aha-icon-delete font-red"
                                                    aria-hidden="true"
                                                />
                                            </button>
                                        )}
                                        {isEUP &&
                                            documentDetail.isLatest &&
                                            documentDetail.approvalStatus ===
                                                "REJECTED" && (
                                                <button
                                                    className="btn-text-link-uline"
                                                    onClick={() =>
                                                        resubmitDocument(
                                                            documentDetail
                                                        )
                                                    }
                                                    data-toggle="tooltip"
                                                    data-placement="left"
                                                    data-container={`#view-doc-${documentDetail.documentId}`}
                                                    title="Resubmit"
                                                    aria-label={`${documentDetails[0].documentTitle} Resubmit`}
                                                >
                                                    <i
                                                        className="aha-icon-resubmit font-red"
                                                        aria-hidden="true"
                                                    />
                                                </button>
                                            )}
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </BulkUploadWrapper>
        </>
    );
};

export default DocumentDetails;
