import styled from "styled-components";

export const GraphFilterWrapper = styled.div`
    &.graph-filter {
        background-color: #f8f8f8;
        padding: 14px 18px 20px;
        border: 1px solid #e3e3e3;
        margin-bottom: 40px;
        @media only screen and (min-width: 992px) {
            position: -webkit-sticky;
            position: sticky;
            top: 0;
            z-index: 10500;
        }
        label {
            margin-bottom: 1px;
        }
        .cst-select-fld {
            .cst-selected-item:disabled {
                font-size: 24px;
                line-height: 16px;
                color: #464646;
            }
        }
    }
    .chart-filter-btn {
        padding-left: 16px;
        padding-right: 16px;
        flex-grow: 1;
        width: 100%;
        @media only screen and (min-width: 576px) {
            flex-grow: 0;
            width: auto;
        }
    }
    .chart-filter-export {
        padding: 0;
        width: 100%;
        @media only screen and (min-width: 576px) {
            width: auto;
        }
        > button {
            width: 100%;
            border: 2px solid #c10e21;
            padding: 4px 16px;
            font-size: 14px;
            background-color: #ffffff;
            border-radius: 10em;
            @media only screen and (min-width: 576px) {
                width: auto;
                font-size: 20px;
                border: none;
                padding: 0;
                border-radius: 2px;
                background-color: transparent;
            }
        }
        .popover {
            width: 210px;
            right: -12px;
            left: auto !important;
            transform: none !important;
            top: calc(100% + 2px) !important;
            .popover-body {
                padding: 12px;
            }
        }
    }
    .chart-prog {
        margin-bottom: 16px;
        width: 100%;
        margin-right: 0;
        @media only screen and (min-width: 576px) {
            width: 49%;
            margin-right: 1%;
        }
        @media only screen and (min-width: 768px) {
            width: min-content;
            flex-grow: 1;
            margin-right: 16px;
            margin-bottom: 0;
        }
    }
    .chart-interval {
        width: 100%;
        margin-left: 0;
        margin-bottom: 16px;
        @media only screen and (min-width: 576px) {
            width: 49%;
            margin-left: 1%;
            margin-right: 0;
        }
        @media only screen and (min-width: 768px) {
            margin-bottom: 0;
            margin-left: 0;
            margin-right: 16px;
            width: 128px;
        }
    }
    .chart-from-duration {
        width: 100%;
        margin-right: 0;
        margin-bottom: 16px;
        @media only screen and (min-width: 576px) {
            width: 49%;
            margin-right: 1%;
            margin-bottom: 0;
        }
        @media only screen and (min-width: 768px) {
            margin-right: 16px;
            width: auto;
        }
    }
    .chart-to-duration:last-child {
        width: 100%;
        margin-left: 0;
        @media only screen and (min-width: 576px) {
            width: 49%;
            margin-right: 0;
            margin-left: 1%;
        }
        @media only screen and (min-width: 768px) {
            margin-left: 0;
            margin-right: 16px;
            width: auto;
        }
    }
    .duration-year {
        width: 48%;
        @media only screen and (min-width: 768px) {
            width: 85px;
        }
        @media only screen and (min-width: 992px) {
            width: 100px;
        }
    }
    .duration-q {
        width: 48%;
        margin-left: 4%;
        @media only screen and (min-width: 768px) {
            width: 66px;
            margin-left: 8px;
        }
        @media only screen and (min-width: 992px) {
            width: 70px;
        }
    }
    .graph-filter-dowpdown {
        list-style-type: none;
        margin: 10px 0 0;
        padding: 0;
        width: 100%;
        left: auto !important;
        right: 0;
        @media only screen and (min-width: 576px) {
            width: 150px;
            right: -12px;
            transform: translate3d(-12px, 30px, 0px) !important;
        }
        li {
            .btn {
                padding: 12px 14px;
                width: 100%;
                text-align: left;
                .aha-icon-group-4513 {
                    margin-right: 12px;
                    line-height: 22px;
                    font-size: 13px;
                }
            }
            .dropdown-item.active,
            .dropdown-item:active {
                color: #222328;
            }
        }
        li + li {
            border-top: 1px solid #e3e3e3;
        }
    }
    .export-dropdown {
        width: 100%;
        @media only screen and (min-width: 576px) {
            width: auto;
        }
        .menu-cst-selected-item {
            text-align: center;
            border-radius: 10em;
            border: 2px solid #c10e21;
            background: #ffffff;
            @media only screen and (min-width: 576px) {
                border: none;
                border-radius: unset;
                background: none;
            }
            .export-text {
                font-weight: 500;
            }
            > i {
                font-size: 14px;
                @media only screen and (min-width: 576px) {
                    font-size: 20px;
                }
            }
        }
        .menu-cst-select-dropdown {
            width: 100%;
            @media only screen and (min-width: 576px) {
                width: 174px;
            }
        }
    }
`;
