import CONSTANTS from "common/constants";
import Dropdown from "components/Dropdown";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    setCountryCode,
    setStateCode,
    setSortOrganizations,
} from "components/SearchFilter/searchFilter.slice";
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import { sleep } from "common/utils";
import { SortTableColumnDropdownWrapper } from "./styled";

interface Props {
    getHospitalsForAdminManage?: any;
    status?: any;
    pageNumber?: any;
    pageSize?: any;
    isActive?: any;
    categoryId?: any;
    searchKey?: any;
    setPendingHospitals?: any;
    setRenewingHospitals?: any;
    sortIndValue?: any;
    setCertifiedHospitals?: any;
    setRegisteredHospitals?: any;
    setDeactivatedHospitals?: any;
    getCorporationFacilities?: any;
    corporationId?: any;
    setFacilities?: any;
    id: string;
    stateCd?: any;
    country?: any;
}

const SortTableColumnDropdown = (props: Props) => {
    const [message, setMessage] = useState("");
    const sortDropdown = [
        { label: "Default", value: CONSTANTS.DEFAULT },
        { label: "Name Ascending", value: CONSTANTS.ASCENDING },
        { label: "Name Descending", value: CONSTANTS.DESCENDING },
        { label: "Expiry Date Ascending", value: CONSTANTS.EXPIRY_ASCENDING },
        { label: "Expiry Date Descending", value: CONSTANTS.EXPIRY_DESCENDING },
    ];
    const dispatch = useDispatch();
    const searchFilter = useSelector((state: any) => {
        return state.searchFilter;
    });
    const region = useSelector((state: any) => {
        return state.region;
    });

    const filteredSortDropdown = () => {
        if (props.status !== CONSTANTS.CERTIFIED) {
            return sortDropdown.filter(
                (option) =>
                    option.value === CONSTANTS.DEFAULT ||
                    option.value === CONSTANTS.ASCENDING ||
                    option.value === CONSTANTS.DESCENDING
            );
        }
        return sortDropdown;
    };
    const handleChangeSortOrganizations = (value: any) => {
        if (searchFilter.sortOrganizations !== value) {
            if (
                props.status === CONSTANTS.CERTIFIED ||
                props.status === CONSTANTS.DEACTIVATED
            ) {
                dispatch(setSortOrganizations(CONSTANTS.ALL));
            }
            dispatch(setSortOrganizations(value));
            dispatch(setCountryCode(CONSTANTS.ALL));
            dispatch(setStateCode(CONSTANTS.ALL));
            switch (value) {
                case CONSTANTS.ASCENDING:
                    setMessage(
                        `Table sorted in name ${CONSTANTS.ASCENDING} order.`
                    );
                    break;
                case CONSTANTS.DESCENDING:
                    setMessage(
                        `Table sorted in name ${CONSTANTS.DESCENDING} order.`
                    );
                    break;
                case CONSTANTS.DEFAULT:
                    setMessage(`Table sorted in ${CONSTANTS.DEFAULT} order.`);
                    break;
                case CONSTANTS.EXPIRY_ASCENDING:
                    setMessage(
                        `Table sorted in ${CONSTANTS.EXPIRY_DATE_ASCENDING} order.`
                    );
                    break;
                case CONSTANTS.EXPIRY_DESCENDING:
                    setMessage(
                        `Table sorted in ${CONSTANTS.EXPIRY_DATE_DESCENDING} order.`
                    );
                    break;
                default:
                    setMessage("");
                    break;
            }
            if (
                value === CONSTANTS.DESCENDING ||
                value === CONSTANTS.EXPIRY_DESCENDING
            ) {
                props.sortIndValue(false);
            }
            if (
                value === CONSTANTS.ASCENDING ||
                value === CONSTANTS.EXPIRY_ASCENDING
            ) {
                props.sortIndValue(true);
            }
            if (value === CONSTANTS.DEFAULT) {
                props.sortIndValue(undefined);
                dispatch(setSortOrganizations(CONSTANTS.ALL));
            }
            if (typeof props.getHospitalsForAdminManage === "function") {
                props
                    .getHospitalsForAdminManage({
                        region: region.region,
                        sortOrganizations:
                            value === CONSTANTS.EXPIRY_ASCENDING ||
                            value === CONSTANTS.EXPIRY_DESCENDING
                                ? value
                                : CONSTANTS.ALL,
                        status: props.status,
                        pageNumber: props.pageNumber,
                        isActive: props.isActive,
                        pageSize: props.pageSize,
                        categoryId: props.categoryId,
                        sortInd:
                            value === CONSTANTS.DEFAULT
                                ? undefined
                                : !!(
                                      value === CONSTANTS.ASCENDING ||
                                      value === CONSTANTS.EXPIRY_ASCENDING
                                  ),
                        searchKey: props.searchKey,
                        stateCd: props.stateCd,
                        country: props.country,
                    })
                    .then((res: any) => {
                        if (res.statusCode === 200) {
                            if (props.status === CONSTANTS.PENDING) {
                                props.setPendingHospitals(
                                    res.data.hospitalPrograms
                                );
                            }
                            if (props.status === CONSTANTS.CERTIFIED) {
                                props.setCertifiedHospitals(
                                    res.data.hospitalPrograms
                                );
                            }
                            if (props.status === CONSTANTS.RENEWING) {
                                props.setRenewingHospitals(
                                    res.data.hospitalPrograms
                                );
                            }
                            if (props.status === CONSTANTS.REGISTRED) {
                                props.setRegisteredHospitals(
                                    res.data.hospitalPrograms
                                );
                            }
                            if (props.status === CONSTANTS.DEACTIVATED) {
                                props.setDeactivatedHospitals(
                                    res.data.hospitalPrograms
                                );
                            }
                        }
                    });
            }
            if (typeof props.getCorporationFacilities === "function") {
                props
                    .getCorporationFacilities({
                        region: region.region,
                        corporationId: props.corporationId,
                        pageNumber: props.pageNumber,
                        pageSize: props.pageSize,
                        status: props.status,
                        sortOrganizations:
                            value === CONSTANTS.EXPIRY_ASCENDING ||
                            value === CONSTANTS.EXPIRY_DESCENDING
                                ? value
                                : CONSTANTS.ALL,
                        categoryId: props.categoryId,
                        sortInd:
                            value === CONSTANTS.DEFAULT
                                ? undefined
                                : !!(
                                      value === CONSTANTS.ASCENDING ||
                                      value === CONSTANTS.EXPIRY_ASCENDING
                                  ),
                        searchKey: props.searchKey,
                        stateCd: props.stateCd,
                        country: props.country,
                    })
                    .then((res: any) => {
                        if (res.statusCode === 200) {
                            if (props.status === CONSTANTS.CERTIFIED) {
                                props.setFacilities(
                                    res.data.certifiedFacilityDetails
                                );
                            } else if (
                                props.status === CONSTANTS.DEACTIVATED ||
                                props.status === CONSTANTS.REGISTRED ||
                                props.status === CONSTANTS.PENDING ||
                                props.status === CONSTANTS.RENEWING
                            ) {
                                props.setFacilities(res.data.facilityDetails);
                            }
                        }
                        dispatch(
                            setPageLoadingStatus({ isPageLoading: false })
                        );
                        localStorage.setItem("resetFilter", "false");
                    });
                localStorage.setItem("resetFilter", "true");
            }
        }
    };

    useEffect(() => {
        if (message !== "") {
            const timer: any = sleep(6000).then(() => {
                setMessage("");
            });
            return () => clearTimeout(timer);
        }
    }, [message]);

    return (
        <SortTableColumnDropdownWrapper>
            <label htmlFor={props.id} id={`${props.id}-label`}>
                Sort by:
            </label>
            <Dropdown
                id={props.id}
                items={filteredSortDropdown()}
                selectedValue={searchFilter.sortOrganizations}
                callParentOnSelect={(value: any) =>
                    handleChangeSortOrganizations(value)
                }
            />
            <div className="sr-only" role="alert" aria-atomic="true">
                {message}
            </div>
        </SortTableColumnDropdownWrapper>
    );
};
export default SortTableColumnDropdown;
