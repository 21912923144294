import React from "react";

interface Props {
    isFirst: boolean;
    pageNumber: number;
    pageSize: number;
    isLast: boolean;
    totalCount?: number;
    facilityType?: string;
    registryInd?: boolean;
    corporationInd?: boolean;
    roleValue?: string;
    userManagementInd?: boolean;
    documentInd?: boolean;
    programInd?: boolean;
    measureInd?: boolean;
}

export const TableRowCount = (props: Props) => {
    const getType = () => {
        switch (true) {
            case props.registryInd:
                return "Registries";
            case props.corporationInd:
                return "Corporations";
            case props.userManagementInd:
                return "Users";
            case props.documentInd:
                return "Documents";
            case props.programInd:
                return "Programs";
            case props.measureInd:
                return "Files";
            default:
                return "Organizations";
        }
    };

    return (
        <div
            className="table-rowcount"
            role={props.roleValue}
            aria-live="assertive"
        >
            {props.totalCount && props.totalCount > 0
                ? `Showing ${
                      props.isFirst
                          ? 1
                          : (props.pageNumber - 1) * props.pageSize + 1
                  }`
                : ""}
            {props.totalCount && props.totalCount > 0 ? (
                <span aria-hidden="true" className="mx-1">
                    -
                </span>
            ) : (
                ""
            )}
            {props.totalCount && props.totalCount > 0 ? (
                <span className="sr-only">to</span>
            ) : (
                ""
            )}
            {props.totalCount && props.totalCount > 0
                ? `${
                      props.isLast
                          ? props.totalCount
                          : props.pageNumber * props.pageSize
                  } of ${props.totalCount} ${
                      props.facilityType ? props.facilityType : ""
                  } ${getType()}`
                : ""}
        </div>
    );
};

export default TableRowCount;
