import React, { ReactNode } from "react";
import PropTypes from "prop-types";
import Header from "components/Header";
import Footer from "components/Footer";

interface Props {
    children: ReactNode;
}
export default function AuthLayout({ children }: Props) {
    return (
        <>
            <Header hideMenu />
            <div>{children}</div>
            <Footer />
        </>
    );
}
AuthLayout.propTypes = {
    children: PropTypes.element.isRequired,
};
