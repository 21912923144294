import React from "react";
import { PaymentSummaryWrapper } from "./styled";

interface Props {
    lastSessionPayment: string | undefined;
}

export const PaymentProcessingPage = (props: Props) => {
    return (
        <PaymentSummaryWrapper className="py-5 my-4">
            <div className="container d-flex flex-column align-items-center py-5 mb-4 text-center">
                <img
                    src="/images/sandslide.png"
                    alt="Loading"
                    className="mb-5 pt-4"
                />
                {props.lastSessionPayment ? (
                    <h6 className="h6 font-600 mb-4">
                        Please Wait - We are fetching your last payment
                        status...
                    </h6>
                ) : (
                    <>
                        <h6 className="h6 font-600 mb-4">
                            Please Wait - We are processing your request...
                        </h6>
                        <p>
                            Please do not close this window or click the Back
                            button on your browser
                        </p>
                    </>
                )}
            </div>
        </PaymentSummaryWrapper>
    );
};

export default PaymentProcessingPage;
