import styled from "styled-components";

export const CertificationWrapper = styled.div`
    .cert-title {
        border-bottom: 3px solid #c10e21;
        margin-bottom: 60px;
    }
    .cert-btns {
        background: #f8f8f8;
        border: 1px solid #e3e3e3;
        text-align: center;
        .btn {
            width: auto;
        }
        @media only screen and (min-width: 1200px) {
            text-align: left;
        }
    }
    .cert-checkbox {
        list-style-type: none;
        padding: 0;
    }
    #imagePreviewContainer {
        font-family: "Lubdub-bold";
    }
    .hiddenFontElementLubdubLight {
        font-family: "Lubdub-light";
    }
    .hiddenFontElementLubdubMedium {
        font-family: "Lubdub-medium";
    }
    .hiddenFontElementLubdubBold {
        font-family: "Lubdub-bold";
    }
    #certificateCanvasParent {
        font-family: "Lubdub-bold";
    }
`;
