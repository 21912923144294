import styled from "styled-components";

export const EUPBannerWrapper = styled.div`
    &.facility-banner {
        border: 2px solid #e3e3e3;
        font-size: 18px;
        word-break: break-word;
    }
    .facility-banner-header {
        padding: 14px 14px 12px;
        background: #f8f8f8;
        margin: 0;
        @media only screen and (min-width: 576px) {
            padding: 14px 18px 12px 26px;
        }
    }
    .facility-banner-title {
        margin: 0;
    }
    .facility-banner-certified {
        display: flex;
        align-items: center;
        i {
            font-size: 26px;
            margin-right: 6px;
        }
        span {
            font-size: 16px;
        }
    }
    .facility-banner-detail {
        padding: 5px 14px 8px;
        @media only screen and (min-width: 576px) {
            padding: 12px 26px 14px;
        }
    }
    .facility-banner-item {
        margin: 6px 26px 6px 0;
    }
    .facility-banner-value {
        margin-left: 6px;
    }
`;
