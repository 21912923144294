import React, { useEffect, useState } from "react";
import { FooterWrapper } from "./styled";

export default function Footer() {
    const [currentYear, setCurrentYear] = useState(0);
    useEffect(() => {
        const copyRightYear = new Date().getFullYear();
        setCurrentYear(copyRightYear);
    }, []);
    return (
        <FooterWrapper className="aui-footer">
            <div className="container">
                <p className="aui-footer-title">
                    *All health/medical information on this website has been
                    reviewed and approved by the American Heart Association,
                    based on scientific research and American Heart Association
                    guidelines.&nbsp;
                    <a
                        href="https://www.heart.org/en/about-us/statements-and-policies/content-editorial-process"
                        className="font-red btn-text-link-uline"
                        aria-label="Use this link for content editorial process Opens in new tab"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Use this link
                    </a>
                    &nbsp;for more information on our content editorial process.
                </p>
                <div className="row">
                    <div className="col-md-3">
                        <div
                            className="aui-footer-logo aui-footer-logo-100 mx-auto mx-md-0"
                            role="img"
                            aria-label="American Heart Association logo"
                        />
                        <div className="aui-footer-accordion">
                            <div
                                className="d-md-none d-flex justify-content-between align-items-center aui-footer-acc-h"
                                id="contactaddress"
                                data-toggle="collapse"
                                data-target="#contactinfo"
                                aria-expanded="false"
                                aria-controls="contactinfo"
                                role="button"
                            >
                                <h2 className="h8 mb-0 font-600">Contact Us</h2>
                                <i
                                    className="aha-icon-arrow-down mr-2"
                                    aria-hidden="true"
                                />
                            </div>
                            <div className="collapse" id="contactinfo">
                                <address className="aui-footer-address d-flex d-md-block flex-wrap mb-0">
                                    <p>
                                        <strong className="h8 mb-0 font-600 d-block">
                                            National Center
                                        </strong>
                                        7272 Greenville Ave.
                                        <br />
                                        Dallas, TX 75231
                                        <br />
                                    </p>
                                    <p className="aui-footer-service">
                                        <strong className="h8 mb-0 font-600 d-block">
                                            Customer service
                                        </strong>
                                        <span aria-hidden="true">
                                            1-800-AHA-USA-1
                                        </span>
                                        <span className="sr-only">
                                            1 8 0 0 A H A U S A 1
                                        </span>
                                        <br />
                                        <span aria-hidden="true">
                                            1-800-242-8721
                                        </span>
                                        <span className="sr-only">
                                            1 8 0 0 2 4 2 8 7 2 1
                                        </span>
                                    </p>
                                    <div className="aui-footer-contact-btn">
                                        <a
                                            href="https://www.heart.org/en/about-us/contact-us"
                                            target="_blank"
                                            className="btn btn-secondary btn-round"
                                            aria-label="Contact Us Opens in new tab"
                                            rel="noreferrer"
                                        >
                                            Contact Us
                                        </a>
                                    </div>
                                    <div className="aui-footer-hours">
                                        <div className="h8 mb-0 d-block font-600">
                                            Hours
                                        </div>
                                        <div className="d-block">
                                            Monday{" "}
                                            <span aria-hidden="true">-</span>
                                            &nbsp;
                                            <span className="sr-only">
                                                to
                                            </span>{" "}
                                            Friday: 7AM{" "}
                                            <span aria-hidden="true">-</span>
                                            &nbsp;
                                            <span className="sr-only">
                                                to
                                            </span>{" "}
                                            9PM CST
                                        </div>
                                        <div className="d-block">
                                            Saturday: 9AM{" "}
                                            <span aria-hidden="true">-</span>
                                            &nbsp;
                                            <span className="sr-only">
                                                to
                                            </span>{" "}
                                            5PM CST
                                        </div>
                                        <div className="d-block">
                                            Closed on Sundays
                                        </div>
                                    </div>
                                </address>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-9">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 px-xl-4">
                                <div className="aui-footer-accordion">
                                    <h2 className="h8 mb-0 font-600 d-none d-md-block">
                                        About Us
                                    </h2>
                                    <div
                                        className="d-flex d-md-none justify-content-between align-items-center aui-footer-acc-h"
                                        id="ftrCol1"
                                        data-toggle="collapse"
                                        data-target="#ftrCol1Content"
                                        aria-controls="ftrCol1Content"
                                        aria-expanded="false"
                                        role="button"
                                    >
                                        <h2 className="h8 mb-0 font-600">
                                            About Us
                                        </h2>
                                        <i
                                            className="aha-icon-arrow-down mr-2 d-md-none"
                                            aria-hidden="true"
                                        />
                                    </div>
                                    <div
                                        className="collapse aui-footer-acc-b"
                                        id="ftrCol1Content"
                                    >
                                        <ul className="mb-0">
                                            <li>
                                                <a
                                                    href="https://www.heart.org/en/about-us"
                                                    target="_blank"
                                                    aria-label="About the American Heart Association / American Stroke Association Opens in new tab"
                                                    rel="noreferrer"
                                                >
                                                    <span>
                                                        About the{" "}
                                                        <span aria-hidden="true">
                                                            AHA/ASA
                                                        </span>
                                                        <span className="sr-only">
                                                            American Heart
                                                            Association /
                                                            American Stroke
                                                            Association
                                                        </span>
                                                    </span>
                                                    <i
                                                        className="aha-icon-arrow-right"
                                                        aria-hidden="true"
                                                    />
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    href="https://www.heart.org/en/about-us/annual-report"
                                                    target="_blank"
                                                    aria-label="Annual Report Opens in new tab"
                                                    rel="noreferrer"
                                                >
                                                    <span>Annual Report</span>
                                                    <i
                                                        className="aha-icon-arrow-right"
                                                        aria-hidden="true"
                                                    />
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    href="https://www.heart.org/en/about-us/aha-financial-information"
                                                    target="_blank"
                                                    aria-label="American Heart Association Financial Information Opens in new tab"
                                                    rel="noreferrer"
                                                >
                                                    <span>
                                                        <span aria-hidden="true">
                                                            AHA
                                                        </span>
                                                        <span className="sr-only">
                                                            American Heart
                                                            Association
                                                        </span>{" "}
                                                        Financial Information
                                                    </span>
                                                    <i
                                                        className="aha-icon-arrow-right"
                                                        aria-hidden="true"
                                                    />
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    href="https://heart.jobs/?utm_campaign=heart.org-Footer&vs=2896&utm_medium=Other&utm_source=heart.org-Footer"
                                                    target="_blank"
                                                    aria-label="Careers Opens in new tab"
                                                    rel="noreferrer"
                                                >
                                                    <span>Careers</span>
                                                    <i
                                                        className="aha-icon-arrow-right"
                                                        aria-hidden="true"
                                                    />
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    href="https://www.heart.org/en/about-us/international-programs"
                                                    target="_blank"
                                                    aria-label="International Programs Opens in new tab"
                                                    rel="noreferrer"
                                                >
                                                    <span>
                                                        International Programs
                                                    </span>
                                                    <i
                                                        className="aha-icon-arrow-right"
                                                        aria-hidden="true"
                                                    />
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    href="https://www.heart.org/en/news"
                                                    target="_blank"
                                                    aria-label="Latest Heart and stroke news Opens in new tab"
                                                    rel="noreferrer"
                                                >
                                                    <span>
                                                        Latest Heart and Stroke
                                                        News
                                                    </span>
                                                    <i
                                                        className="aha-icon-arrow-right"
                                                        aria-hidden="true"
                                                    />
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    href="https://newsroom.heart.org/"
                                                    target="_blank"
                                                    aria-label="American Heart Association / American Stroke Association Media Newsroom Opens in new tab"
                                                    rel="noreferrer"
                                                >
                                                    <span>
                                                        <span aria-hidden="true">
                                                            AHA/ASA
                                                        </span>
                                                        <span className="sr-only">
                                                            American Heart
                                                            Association /
                                                            American Stroke
                                                            Association
                                                        </span>{" "}
                                                        Media Newsroom
                                                    </span>
                                                    <i
                                                        className="aha-icon-arrow-right"
                                                        aria-hidden="true"
                                                    />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 px-xl-4">
                                <div className="aui-footer-accordion">
                                    <h2 className="h8 mb-0 font-600 d-none d-md-block">
                                        Get Involved
                                    </h2>
                                    <div
                                        className="d-flex d-md-none justify-content-between align-items-center aui-footer-acc-h"
                                        id="ftrCol2"
                                        data-toggle="collapse"
                                        data-target="#ftrCol2Content"
                                        aria-expanded="false"
                                        aria-controls="ftrCol2Content"
                                        role="button"
                                    >
                                        <h2 className="h8 mb-0 font-600">
                                            Get Involved
                                        </h2>
                                        <i
                                            className="aha-icon-arrow-down mr-2 d-md-none"
                                            aria-hidden="true"
                                        />
                                    </div>
                                    <div
                                        className="collapse aui-footer-acc-b"
                                        id="ftrCol2Content"
                                    >
                                        <ul className="mb-0">
                                            <li>
                                                <a
                                                    href="https://www2.heart.org/site/SPageNavigator/donatenow_heart.html?s_src=20U2W1UEMG&s_subsrc=footer_donatenow"
                                                    target="_blank"
                                                    aria-label="Donate Now Opens in new tab"
                                                    rel="noreferrer"
                                                >
                                                    <span>Donate Now</span>
                                                    <i
                                                        className="aha-icon-arrow-right"
                                                        aria-hidden="true"
                                                    />
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    href="https://www2.heart.org/site/SPageNavigator/donatenow_legacy.html?s_src=20U2W1EEMM&sub_src=footer_memorial_gift"
                                                    target="_blank"
                                                    aria-label="Make a Memorial Gift Opens in new tab"
                                                    rel="noreferrer"
                                                >
                                                    <span>
                                                        Make a Memorial Gift
                                                    </span>
                                                    <i
                                                        className="aha-icon-arrow-right"
                                                        aria-hidden="true"
                                                    />
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    href="https://www.heart.org/en/get-involved/ways-to-give"
                                                    target="_blank"
                                                    aria-label="Ways to Give Opens in new tab"
                                                    rel="noreferrer"
                                                >
                                                    <span>Ways to Give</span>
                                                    <i
                                                        className="aha-icon-arrow-right"
                                                        aria-hidden="true"
                                                    />
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    href="https://www.heart.org/en/get-involved/advocate"
                                                    target="_blank"
                                                    aria-label="Advocate Opens in new tab"
                                                    rel="noreferrer"
                                                >
                                                    <span>Advocate</span>
                                                    <i
                                                        className="aha-icon-arrow-right"
                                                        aria-hidden="true"
                                                    />
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    href="https://www.heart.org/en/volunteer/opportunities"
                                                    target="_blank"
                                                    aria-label="Volunteer Opens in new tab"
                                                    rel="noreferrer"
                                                >
                                                    <span>Volunteer</span>
                                                    <i
                                                        className="aha-icon-arrow-right"
                                                        aria-hidden="true"
                                                    />
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    href="https://www.goredforwomen.org/en"
                                                    target="_blank"
                                                    aria-label="Go Red for women Opens in new tab"
                                                    rel="noreferrer"
                                                >
                                                    <span>
                                                        Go Red For Women
                                                    </span>
                                                    <i
                                                        className="aha-icon-arrow-right"
                                                        aria-hidden="true"
                                                    />
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    href="https://www.shopheart.org/?a=aha-heart.org-bottom-navigation&utm_source=heart.org&utm_medium=referral&utm_campaign=aha-heart.org-bottom-navigation"
                                                    target="_blank"
                                                    aria-label="Shop Opens in new tab"
                                                    rel="noreferrer"
                                                >
                                                    <span>SHOP</span>
                                                    <i
                                                        className="aha-icon-arrow-right"
                                                        aria-hidden="true"
                                                    />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 mt-md-5 mt-lg-0 px-xl-4">
                                <div className="aui-footer-accordion">
                                    <h2 className="h8 mb-0 font-600 d-none d-md-block">
                                        Customer Support
                                    </h2>
                                    <div
                                        className="d-flex d-md-none justify-content-between align-items-center aui-footer-acc-h"
                                        id="ftrCol3"
                                        data-toggle="collapse"
                                        data-target="#ftrCol3Content"
                                        aria-controls="ftrCol3Content"
                                        aria-expanded="false"
                                        role="button"
                                    >
                                        <h2 className="h8 mb-0 font-600">
                                            Customer Support
                                        </h2>
                                        <i
                                            className="aha-icon-arrow-down mr-2 d-md-none"
                                            aria-hidden="true"
                                        />
                                    </div>
                                    <div
                                        className="collapse aui-footer-acc-b"
                                        id="ftrCol3Content"
                                    >
                                        <ul className="mb-0">
                                            <li>
                                                <a
                                                    href="https://www.stroke.org/en"
                                                    target="_blank"
                                                    aria-label="American Stroke Association Opens in new tab"
                                                    rel="noreferrer"
                                                >
                                                    <span>
                                                        American Stroke
                                                        Association
                                                    </span>
                                                    <i
                                                        className="aha-icon-arrow-right"
                                                        aria-hidden="true"
                                                    />
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    href="https://cpr.heart.org/en"
                                                    target="_blank"
                                                    aria-label="Cardiopulmonary resuscitation and Emergency Cardiovascular Care Opens in new tab"
                                                    rel="noreferrer"
                                                >
                                                    <span>
                                                        <span aria-hidden="true">
                                                            CPR &amp; ECC
                                                        </span>
                                                        <span className="sr-only">
                                                            Cardiopulmonary
                                                            resuscitation and
                                                            Emergency
                                                            Cardiovascular Care
                                                        </span>
                                                    </span>
                                                    <i
                                                        className="aha-icon-arrow-right"
                                                        aria-hidden="true"
                                                    />
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    href="https://professional.heart.org/en/"
                                                    target="_blank"
                                                    aria-label="Professional Heart Daily Opens in new tab"
                                                    rel="noreferrer"
                                                >
                                                    <span>
                                                        Professional Heart Daily
                                                    </span>
                                                    <i
                                                        className="aha-icon-arrow-right"
                                                        aria-hidden="true"
                                                    />
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    href="https://www.heart.org/en/about-us/aha-asa-website-directory"
                                                    target="_blank"
                                                    aria-label="more sites Opens in new tab"
                                                    rel="noreferrer"
                                                >
                                                    <span>More Sites</span>
                                                    <i
                                                        className="aha-icon-arrow-right"
                                                        aria-hidden="true"
                                                    />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="aui-footer-inline">
                <div className="container">
                    <div className="d-flex flex-column flex-md-row justify-content-center justify-content-md-between align-items-center py-md-4">
                        <ul className="aui-footer-media p-0 m-0">
                            <li>
                                <a
                                    href="https://twitter.com/American_Heart"
                                    target="_blank"
                                    aria-label="connect to twitter Opens in new tab"
                                    rel="noreferrer"
                                >
                                    <i
                                        className="aha-icon-twitter"
                                        aria-hidden="true"
                                    />
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.facebook.com/AmericanHeart"
                                    target="_blank"
                                    aria-label="connect to facebook Opens in new tab"
                                    rel="noreferrer"
                                >
                                    <i
                                        className="aha-icon-facebook"
                                        aria-hidden="true"
                                    />
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.instagram.com/american_heart/"
                                    target="_blank"
                                    aria-label="connect to instagram Opens in new tab"
                                    rel="noreferrer"
                                >
                                    <i
                                        className="aha-icon-instagram"
                                        aria-hidden="true"
                                    />
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.youtube.com/user/americanheartassoc"
                                    target="_blank"
                                    aria-label="connect to youtube Opens in new tab"
                                    rel="noreferrer"
                                >
                                    <i
                                        className="aha-icon-youtube"
                                        aria-hidden="true"
                                    />
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://in.pinterest.com/americanheart/_created/"
                                    target="_blank"
                                    aria-label="connect to pinterest Opens in new tab"
                                    rel="noreferrer"
                                >
                                    <i
                                        className="aha-icon-pin"
                                        aria-hidden="true"
                                    />
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.linkedin.com/company/american-heart-association/"
                                    target="_blank"
                                    aria-label="connect to linkedin Opens in new tab"
                                    rel="noreferrer"
                                >
                                    <i
                                        className="aha-icon-linkedin"
                                        aria-hidden="true"
                                    />
                                </a>
                            </li>
                        </ul>
                        <ul className="aui-footer-ref p-0 mb-0 mt-5 mt-md-0">
                            <li>
                                <a
                                    href="https://nationalhealthcouncil.org/blog/introduction-to-the-standards-of-excellence-certification-program/"
                                    target="_blank"
                                    className="d-block aui-extlink aui-council"
                                    aria-label="National Health Council Opens in new tab"
                                    rel="noreferrer"
                                />
                            </li>
                            <li>
                                <a
                                    href="https://www.give.org/charity-reviews/national/health/american-heart-association-in-dallas-tx-173"
                                    target="_blank"
                                    className="d-block aui-extlink aui-bbb"
                                    aria-label="Better Business Bureau Opens in new tab"
                                    rel="noreferrer"
                                />
                            </li>
                            <li>
                                <a
                                    href="https://www.charitynavigator.org/index.cfm?bay=search.summary&orgid=3260"
                                    target="_blank"
                                    className="d-block aui-extlink aui-navigator"
                                    aria-label="Charity Navigator Opens in new tab"
                                    rel="noreferrer"
                                />
                            </li>
                            <li>
                                <a
                                    href="https://ssl.comodo.com/"
                                    target="_blank"
                                    className="d-block aui-extlink aui-secureseal"
                                    aria-label="Comodo Secure Opens in new tab"
                                    rel="noreferrer"
                                />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="container aui-footer-link">
                <div className="row">
                    <div className="col-12">
                        <ul className="aui-footer-linkitem list-inline text-center mb-4 d-flex flex-wrap justify-content-center">
                            <li>
                                <a
                                    target="_blank"
                                    href="https://heart.jobs/?utm_campaign=heart.org-Footer&vs=2896&utm_medium=Other&utm_source=heart.org-Footer"
                                    aria-label="Careers Opens in new tab"
                                    rel="noreferrer"
                                >
                                    Careers
                                </a>
                            </li>
                            <li>
                                <a
                                    target="_blank"
                                    href="https://www.heart.org/en/about-us/statements-and-policies/privacy-statement"
                                    aria-label="Privacy policy Opens in new tab"
                                    rel="noreferrer"
                                >
                                    Privacy Policy
                                </a>
                            </li>
                            <li>
                                <a
                                    target="_blank"
                                    href="https://www.heart.org/en/about-us/statements-and-policies/medical-advice"
                                    aria-label="medical advice disclaimer Opens in new tab"
                                    rel="noreferrer"
                                >
                                    Medical Advice Disclaimer
                                </a>
                            </li>
                            <li>
                                <a
                                    target="_blank"
                                    href="https://www.heart.org/en/about-us/statements-and-policies/copyright"
                                    aria-label="copyright policy Opens in new tab"
                                    rel="noreferrer"
                                >
                                    Copyright Policy
                                </a>
                            </li>
                            <li>
                                <a
                                    target="_blank"
                                    href="https://www.heart.org/en/about-us/statements-and-policies/accessibility-statement"
                                    aria-label="accessibility statement Opens in new tab"
                                    rel="noreferrer"
                                >
                                    Accessibility Statement
                                </a>
                            </li>
                            <li>
                                <a
                                    target="_blank"
                                    href="https://www.heart.org/en/about-us/statements-and-policies/ethics-policy"
                                    aria-label="ethics policy Opens in new tab"
                                    rel="noreferrer"
                                >
                                    Ethics Policy
                                </a>
                            </li>
                            <li>
                                <a
                                    target="_blank"
                                    href="https://www.heart.org/en/about-us/statements-and-policies/conflict-of-interest-policy"
                                    aria-label="conflict of interest policy Opens in new tab"
                                    rel="noreferrer"
                                >
                                    Conflict of Interest Policy
                                </a>
                            </li>
                            <li>
                                <a
                                    target="_blank"
                                    href="https://www.heart.org/en/about-us/statements-and-policies/linking-policy"
                                    aria-label="linking policy Opens in new tab"
                                    rel="noreferrer"
                                >
                                    Linking Policy
                                </a>
                            </li>
                            <li>
                                <a
                                    target="_blank"
                                    href="https://www.heart.org/en/about-us/editorial-guidelines"
                                    aria-label="content editorial guidelines Opens in new tab"
                                    rel="noreferrer"
                                >
                                    Content Editorial Guidelines
                                </a>
                            </li>
                            <li>
                                <a
                                    target="_blank"
                                    href="https://www.heart.org/en/about-us/diversity-inclusion"
                                    aria-label="diversity Opens in new tab"
                                    rel="noreferrer"
                                >
                                    Diversity
                                </a>
                            </li>
                            <li>
                                <a
                                    target="_blank"
                                    href="https://www.heart.org/en/about-us/procurement-services/procurement-services-department"
                                    aria-label="suppliers & providers Opens in new tab"
                                    rel="noreferrer"
                                >
                                    Suppliers &amp; Providers
                                </a>
                            </li>
                            <li>
                                <a
                                    target="_blank"
                                    href="https://www.heart.org/en/about-us/statements-and-policies/state-fundraising-notices"
                                    aria-label="state fundraising policy Opens in new tab"
                                    rel="noreferrer"
                                >
                                    State Fundraising Policy
                                </a>
                            </li>
                            <li>
                                <a
                                    className="ot-sdk-show-settings"
                                    rel="noreferrer"
                                >
                                    Cookie Settings
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <p className="text-center mb-0">
                            &copy;{currentYear} American Heart Association, Inc.
                            All rights reserved. Unauthorized use prohibited.
                        </p>
                        <p className="text-center mb-0">
                            The American Heart Association is a qualified
                            501(c)(3) tax-exempt organization.
                        </p>
                        <p className="text-center mb-2">
                            *Red Dress<sup>TM</sup> DHHS, Go Red
                            <sup>TM</sup> <span aria-hidden="true">AHA</span>
                            <span className="sr-only">
                                American heart association
                            </span>{" "}
                            ; National Wear Red Day&reg; is a registered
                            trademark.
                        </p>
                    </div>
                </div>
            </div>
        </FooterWrapper>
    );
}
