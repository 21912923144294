import styled from "styled-components";

export const LayoutWrapper = styled.div`
    @media only screen and (min-width: 992px) {
        display: flex;
        width: 100%;
        max-width: 100%;
    }
`;

export const SidebarWrapper = styled.aside`
    background-color: #f2f2f2;
    padding: 16px;
    margin-bottom: 20px;
    @media only screen and (min-width: 576px) {
        padding: 16px 24px;
    }
    @media only screen and (min-width: 992px) {
        width: 310px;
        flex-shrink: 0;
        padding: 16px 24px;
        margin-bottom: 0;
    }
    .dropdown-ul {
        padding: 0;
        li {
            .aui-sidenav-acc-header {
                padding: 8px;
                .acc-btn-arrow {
                    margin: 2px 8px 0;
                }
            }
        }
    }
    .back-button {
        border-bottom: 1px solid #e3e3e3;
        padding-bottom: 10px;
        @media only screen and (min-width: 992px) {
            padding-bottom: 0;
            padding-left: 8px;
            padding-right: 8px;
        }
    }
    .previous-page-link {
        color: #c10e21;
        border-bottom: 1px solid #c10e21;
        &:hover,
        &:focus,
        &:active {
            text-decoration: none;
            color: #9d0b1b;
        }
        i {
            font-size: 14px;
            &:before {
                margin-left: 0;
                text-align: left;
            }
        }
    }
    .navbar-toggler {
        padding: 0;
        margin: 0;
    }
    .accordion-dropdown {
        border-bottom: 1px solid #e3e3e3;
        font-size: 18px;
        font-weight: bold;
        padding: 10px 0;
    }
    .aui-sidenav-acc-body {
        > li {
            > button,
            > a {
                padding: 8px;
                cursor: pointer;
                background-color: transparent;
                border: none;
                width: 100%;
                font-weight: 400;
                text-align: left;
            }
        }
    }
    .activeSideNav {
        a,
        button {
            color: #c10e21;
        }
    }
`;

export const ContentWrapper = styled.section`
    &.container {
        margin: 0;
        max-width: none;
        @media only screen and (min-width: 992px) {
            width: calc(100% - 310px);
            padding: 30px;
        }
    }
    .archived-main-content {
        height: 100%;
    }
    .year-period {
        font-weight: 400;
        font-size: 18px;
    }
    .org-heading {
        padding: 10px 0 10px 0;
        border-bottom: 4px solid #c10e21;
    }
    .org-status {
        border-radius: 4px;
        border: 1px solid #222328;
        font-size: 12px;
        font-weight: 500;
        padding: 4px 6px 4px 6px;
    }
    .archived-main {
        .docs-container {
            width: 100%;
            max-width: 100%;
            padding: 0;
        }
    }
    .archive-inner-container {
        .container {
            padding: 0;
            max-width: unset;
        }
    }
`;
