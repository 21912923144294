import styled from "styled-components";

export const SuccessPaymentWrapper = styled.div`
    padding: 50px 0;
    @media only screen and (min-width: 577px) {
        padding: 132px 0;
    }
    .thankyou-details {
        font-size: 16px;
        @media only screen and (min-width: 992px) {
            width: 75%;
        }
        @media only screen and (min-width: 768px) {
            font-size: 18px;
        }
        @media only screen and (min-width: 1200px) {
            width: 60%;
        }
    }
`;
