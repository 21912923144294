import styled from "styled-components";

export const HospitalBannerWrapper = styled.div`
    margin-bottom: 40px;
    word-break: break-word;
    .facility-details-banner {
        position: relative;
        border: 2px solid hsl(354, 86%, 41%);
    }
    .facility-details-fold {
        position: absolute;
        width: 56px;
        height: 82px;
        top: -2px;
        right: -2px;
        overflow: hidden;
        z-index: 1;
        &:before {
            content: "";
            position: absolute;
            top: -18px;
            right: 14px;
            border-width: 0 27px 88px 0;
            border-style: solid;
            border-color: #fff #fff #c10e21 #c10e21;
            background: #c10e21;
            display: block;
            width: 0;
            transform: rotate(-24deg) skewy(-35deg);
        }
        &:after {
            // display: none;
            content: "";
            position: absolute;
            top: -30px;
            right: -11px;
            width: 46px;
            height: 79px;
            transform: rotate(-44deg);
            background-color: #fff;
            transform: rotate(-45deg) skewy(-11deg);
        }
    }
    .facility-settings {
        z-index: 2;
        line-height: 18px;
        .facility-settings-icon {
            font-size: 22px;
            color: #c10e21;
            margin-top: 4px;
        }
        .dropdown-toggle {
            &:after {
                display: none;
            }
        }
        .dropdown-menu {
            transform: none !important;
            top: 25px !important;
            left: auto !important;
            right: 0 !important;
            box-shadow: 0px 3px 6px #00000029;
            border: none;
        }
    }
    .facility-settings-list {
        width: 185px;
        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
        }
        li {
            .btn {
                padding: 12px 14px;
                width: 100%;
                text-align: left;
                .aha-icon-group-4513 {
                    margin-right: 12px;
                    line-height: 22px;
                    font-size: 13px;
                }
            }
            .dropdown-item.active,
            .dropdown-item:active {
                color: #222328;
            }
        }
        li + li {
            border-top: 1px solid #e3e3e3;
        }
    }
    .hospital-banner-header {
        padding: 14px 14px 12px;
        margin: 0;
        background: #f8f8f8;
        @media only screen and (min-width: 576px) {
            padding: 14px 18px 12px 26px;
        }
    }
    .hospital-banner-title {
        margin: 0;
    }
    .hospital-banner-detail {
        padding: 5px 14px 8px;
        @media only screen and (min-width: 576px) {
            padding: 12px 26px 14px;
        }
    }
    .hospital-banner-item {
        margin: 6px 26px 6px 0;
        span {
            display: inline;
        }
    }
    .hospital-banner-value {
        margin-left: 6px;
    }
    .hidden {
        display: none !important;
    }
`;
