import styled from "styled-components";

export const MoreActionsWrapper = styled.div`
    .menu-cst-select-fld {
        height: auto;
    }
    .menu-cst-selected-item {
        height: 24px;
        display: flex;
        align-items: center;
    }
`;
