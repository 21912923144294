import styled from "styled-components";

export const SignInWrapper = styled.main`
    padding-top: 15px;
    padding-bottom: 15px;
    @media only screen and (min-width: 576px) {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    @media only screen and (min-width: 768px) {
        padding-top: 30px;
        padding-bottom: 30px;
    }
    @media only screen and (min-width: 992px) {
        padding-top: 30px;
        padding-bottom: 30px;
    }
    @media only screen and (min-width: 1200px) {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .l-block-bg {
        background: url("../images/qct-hm.jpg") no-repeat;
        background-size: cover;
        background-position-x: 42%;
        @media only screen and (min-width: 576px) {
            background-position-x: 30%;
        }
        @media only screen and (min-width: 1200px) {
            background-position-x: 100%;
        }
    }
    .aha-certification-flex-logo {
        margin-bottom: 32px;
        @media only screen and (min-width: 992px) {
            margin-bottom: 49px;
        }
    }
    .l-block {
        position: relative;
        margin-bottom: 280px;
        @media only screen and (min-width: 360px) {
            margin-bottom: 240px;
        }
        @media only screen and (min-width: 480px) {
            margin-bottom: 160px;
        }
        @media only screen and (min-width: 576px) {
            margin-bottom: 210px;
        }
        @media only screen and (min-width: 992px) {
            margin-bottom: 0;
        }
        > div {
            min-height: auto;
        }
        .l-block-bg {
            height: 508px;
            @media only screen and (min-width: 768px) {
                height: 654px;
            }
            @media only screen and (min-width: 992px) {
                height: auto;
            }
        }
    }
    .l-form {
        width: 92%;
        padding-top: 43px;
        padding-bottom: 36px;
        margin-bottom: 40px;
        background: #fff;
        border-radius: 8px;
        box-shadow: 0px 0px 18px #00000029;
        p {
            font-size: 16px;
            color: #6d6e71;
        }
        > div {
            padding: 0 18px;
        }
        @media only screen and (min-width: 576px) {
            width: 88%;
            padding-top: 45px;
            padding-bottom: 45px;
            > div {
                padding: 0 32px;
            }
            p {
                font-size: 18px;
            }
        }
        @media only screen and (min-width: 768px) {
            width: 82%;
            padding-top: 77px;
            padding-bottom: 92px;
            margin-bottom: 70px;
            > div {
                padding: 0 40px;
            }
            p {
                font-size: 18px;
            }
        }
        @media only screen and (min-width: 992px) {
            width: 100%;
            padding: 0;
            margin-bottom: 0;
            box-shadow: none;
            > div {
                padding: 0 0 0 40px;
            }
            p {
                font-size: 22px;
            }
        }
    }
    .l-container {
        position: absolute;
        left: 0;
        top: 227px;
        @media only screen and (min-width: 576px) {
            top: 227px;
        }
        @media only screen and (min-width: 768px) {
            top: 302px;
        }
        @media only screen and (min-width: 992px) {
            position: static;
        }
    }
    .btn {
        width: 100%;
        @media only screen and (min-width: 768px) {
            width: auto;
        }
    }
    .aui-association-logo {
        width: auto;
        @media only screen and (min-width: 576px) {
            width: 320px;
        }
    }
    &.corporation-Portal {
        .l-block-bg {
            background-image: url("../images/corporation-home.png");
        }
    }
    &.registry-Portal {
        .l-block-bg {
            background: url("../images/registry-home.jpg") no-repeat;
            background-size: cover;
            background-position-x: 42%;
            @media only screen and (min-width: 576px) {
                background-position-x: 60%;
            }
            @media only screen and (min-width: 1200px) {
                background-position-x: 100%;
            }
        }
    }
`;
