import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IUser {
    qctUser: any;
    ssoUser: any;
    ssoAccessToken: null | string;
    totalFacilitiesRegistered: number;
}

export const initialState: any = {
    qctUser: {},
    ssoUser: {},
    ssoAccessToken: null,
    totalFacilitiesRegistered: 0,
};

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setSSOAccessToken: (state, action: PayloadAction<string>) => {
            state.ssoAccessToken = action.payload;
        },
        ssoLogin: (state, action: PayloadAction<any>) => {
            state.ssoUser = { ...action.payload };
        },
        userLogin: (state, action: PayloadAction<any>) => {
            state.qctUser = { ...action.payload };
        },
        setTotalFacilitiesRegistered: (
            state,
            action: PayloadAction<string>
        ) => {
            state.totalFacilitiesRegistered = action.payload;
        },
    },
});
const { actions, reducer } = userSlice;

export const {
    setSSOAccessToken,
    ssoLogin,
    userLogin,
    setTotalFacilitiesRegistered,
} = actions;
export default reducer;
