import React, { createRef, useEffect, useState } from "react";
import { deleteUploadedDoc, uploadPdfToS3 } from "api/programAgreementAPI";
import { useParams } from "react-router-dom";
import { getFIleSize, sleep } from "common/utils";
import CONSTANTS from "common/constants";
import "styles/focusable-element.scss";

interface Props {
    onSave: any;
    uploadId: any;
}

const UploadAgreement = (props: any) => {
    const params: any = useParams();
    const [file, setFile] = useState<any>(null);
    const hospitalId: any = params.hospitalId
        ? params.hospitalId
        : localStorage.getItem("selectedHospitalId");
    const [uploadedDetails, setUploadedDetails] = useState<any>("");
    const [fileUploadError, setFileUploadError] = useState<boolean>(false);
    const [uploadProgress, setUploadProgress] = useState<number>(0);
    const errorMsgRef = createRef<HTMLDivElement>();
    const [fileUploadInd, setFileUploadInd] = useState<boolean>(true);

    useEffect(() => {
        if (file && file.type == "application/pdf") {
            file.size < CONSTANTS.FILE_UPLOAD_SIZE_LIMIT
                ? onFileUpload()
                : setFileUploadError(true);
        } else {
            setFileUploadError(true);
        }
    }, [file]);

    const onFileUpload = async () => {
        setFileUploadInd(true);
        uploadPdfToS3(
            hospitalId,
            params.programId,
            params.categoryId,
            file,
            setUploadProgress
        )
            .then((result: any) => {
                setUploadedDetails(result);
                sleep(5000).then(() => {
                    setFileUploadInd(false);
                });
            })
            .catch((error: any) => {
                setFileUploadError(true);
            });
    };

    const onFileChange = (event: any) => {
        setFileUploadError(false);
        setUploadProgress(0);
        setFile(event.target.files[0]);
    };

    const deleteDocument = (uploadId: any) => {
        deleteUploadedDoc(uploadId).then((response: any) => {
            if (response.success) {
                setUploadedDetails("");
            }
        });
    };

    const clearFileSelection = (saveInd: boolean) => {
        setFile(null);
        setFileUploadError(false);
        setUploadProgress(0);
        if (!saveInd && uploadedDetails.uploadId) {
            deleteDocument(uploadedDetails.uploadId);
        } else if (saveInd && props.uploadId) {
            deleteDocument(props.uploadId);
        }
    };

    const saveFileSelection = () => {
        clearFileSelection(true);
        props.onSave && props.onSave(uploadedDetails);
        setUploadedDetails("");
    };

    useEffect(() => {
        if (fileUploadError) {
            errorMsgRef?.current?.focus();
        }
    }, [fileUploadError]);
    return (
        <div
            className="modal fade show aui-modal"
            tabIndex={-1}
            aria-labelledby="uploadAgreementModalLabel"
            aria-modal="true"
            role="dialog"
            id="uploadAgreementModal"
            data-backdrop="static"
            data-keyboard="false"
        >
            <div className="modal-dialog modal-dialog-centered modal-md">
                <div className="modal-content">
                    <div
                        aria-atomic="true"
                        role="alert"
                        aria-live="polite"
                        className="sr-only"
                    >
                        {file === null
                            ? ""
                            : fileUploadInd &&
                              uploadProgress == 100 &&
                              `file uploaded ${uploadProgress}%`}
                    </div>
                    <div className="modal-header mb-3">
                        <h2 className="h4" id="uploadAgreementModalLabel">
                            Upload Agreement
                        </h2>
                        <button
                            id="closeUploadDocsModal"
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close Upload agreement modal"
                            onClick={() => clearFileSelection(false)}
                        >
                            <span
                                aria-hidden="true"
                                className="aha-icon-cross"
                            />
                        </button>
                    </div>
                    <div className="modal-body">
                        <p>
                            Please fill and upload the agreement in .pdf format
                        </p>

                        <div className="aui-drag-section">
                            <div className="aui-drag-outline">
                                <div className="aui-drag-area d-flex align-items-center justify-content-center">
                                    {/* on page load, File selection area, if file is not selected then 'd-flex', if file selected then 'd-none' */}
                                    {!file ? (
                                        <div className="d-flex justify-content-center align-items-center flex-column">
                                            <i
                                                className="aha-icon-cloud-upload aui-drag-upload-icon mb-3"
                                                aria-hidden="true"
                                            />
                                            <div className="aui-btn-files">
                                                <input
                                                    id="uploadfile"
                                                    type="file"
                                                    name="uploadfile"
                                                    accept=".pdf"
                                                    onChange={onFileChange}
                                                />
                                                <label
                                                    htmlFor="uploadfile"
                                                    className="btn btn-round btn-primary m-0"
                                                    aria-label="Browse files to upload agreement"
                                                >
                                                    Browse Files
                                                </label>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="d-flex justify-content-center align-items-center flex-column aui-uploading-files row w-100">
                                            <div className="col-12">
                                                <div className="h8 font-600 mb-1">
                                                    {file.name}
                                                </div>
                                                <div className="mb-3">
                                                    File Size:{" "}
                                                    {getFIleSize(file.size)}
                                                </div>
                                                <div className="aui-progress-block mb-4">
                                                    <div className="d-flex w-100">
                                                        <div className="progress flex-grow-1">
                                                            {/* if failure use extra class 'progress-failure'  */}
                                                            <div
                                                                className="progress-bar"
                                                                role="progressbar"
                                                                id="inProgess"
                                                                style={{
                                                                    width: `${uploadProgress}%`,
                                                                }}
                                                                aria-valuemin={
                                                                    0
                                                                }
                                                                aria-valuemax={
                                                                    100
                                                                }
                                                                aria-describedby="progressInfo"
                                                                aria-labelledby="progressPercent"
                                                                aria-valuenow={
                                                                    uploadProgress
                                                                }
                                                                aria-valuetext="uploading files"
                                                            >
                                                                <span id="progressPercent">
                                                                    {
                                                                        uploadProgress
                                                                    }
                                                                    %
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <button
                                                            type="button"
                                                            onClick={() =>
                                                                clearFileSelection(
                                                                    false
                                                                )
                                                            }
                                                            className="btn btn-text aha-icon-cross ml-2"
                                                            aria-label={`Remove uploaded file ${file.name}`}
                                                        />
                                                    </div>
                                                    {/* if fileUploadError is true then 'd-block' with proper error message, else 'd-none' */}
                                                    <div className="">
                                                        {fileUploadError && (
                                                            <div
                                                                className="aui-progress-fail-msg d-block focusable-element"
                                                                id="progressInfo"
                                                                ref={
                                                                    errorMsgRef
                                                                }
                                                                role="alert"
                                                                aria-live="assertive"
                                                            >
                                                                <span className="sr-only">
                                                                    Error:
                                                                </span>
                                                                {
                                                                    CONSTANTS.PDF_FILE_UPLOAD_ERROR_MSG
                                                                }
                                                            </div>
                                                        )}
                                                        {/* <div className="aui-btn-files">
                                                            <input
                                                                id="uploadfileagain"
                                                                type="file"
                                                                name="uploadfileagain"
                                                                accept=".csv"
                                                            />
                                                            <label
                                                                htmlFor="uploadfileagain"
                                                                className="btn btn-round btn-primary mb-0 mt-4"
                                                            >
                                                                Upload again
                                                            </label>
                                                        </div> */}
                                                    </div>
                                                    {/* if no error, add class 'd-inline-block' else add 'd-none' */}
                                                    {/* <button className="btn btn-round btn-primary d-inline-block mt-4">
                                                        Submit
                                                    </button> */}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    {file && uploadProgress == 100 && (
                        <div className="d-flex flex-row-reverse mt-4">
                            <button
                                onClick={saveFileSelection}
                                type="button"
                                data-dismiss="modal"
                                className="btn btn-primary btn-round"
                                aria-label="Save uploaded agreement"
                            >
                                Save
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default UploadAgreement;
