import React, { useEffect, useState } from "react";
import Navigator from "components/Navigator";
import UserManagement from "components/UserManagement";
import RegistryTabs from "pages/Registry/RegistryMenu";
import { getUserList } from "api/usersApi";
import { parseJwt } from "common/utils";
import constants from "common/constants";
import CorporationTabs from "pages/Corporation/CorporationMenu/CorporationMenu";
import { logger } from "../../utils/logger.utils";

const UserManagementPage = () => {
    const [users, setUsers] = useState<any>("");
    const [inputUser, setInputUser] = useState<any>("");
    const [search, setSearch] = useState<boolean>(false);
    const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
    const [searchFilter, setSearchFilter] = useState<boolean>(false);
    const [isUsersResponseAvailable, setIsUsersResponseAvailable] =
        useState<boolean>(false);
    const searchkeywordLength: number = 2;
    const userAccessToken = window.localStorage.getItem("userAccessToken");
    const hospitalId: any = localStorage.getItem("selectedHospitalId");
    const isEUP =
        userAccessToken &&
        parseJwt(userAccessToken).role_code ===
            constants.USER_ROLES.HOSPITAL_ADMIN;
    const [roleValue, setRoleValue] = useState<string>("none");
    const [mounted, setMounted] = useState<boolean>(false);
    const [pagination, setPagination] = useState<any>();

    useEffect(() => {
        setIsTableLoading(true);
        setIsUsersResponseAvailable(false);
        getUserList(hospitalId, 1, 5, isEUP, inputUser)
            .then((response: any) => {
                if (response.success && response.statusCode === 200) {
                    setUsers(response.data.users);
                    if (inputUser) {
                        inputUser.length == 0
                            ? setSearchFilter(false)
                            : setSearchFilter(true);
                    } else {
                        setSearchFilter(false);
                    }
                }
                if (response.data._pagination) {
                    setPagination(response.data._pagination);
                }
                setIsUsersResponseAvailable(true);
                setIsTableLoading(false);
                if (mounted) {
                    setRoleValue("alert");
                }
                setMounted(true);
            })
            .catch((err) => {
                logger(err);
                setIsUsersResponseAvailable(true);
                setIsTableLoading(false);
            });
    }, [search]);

    const searchUser = () => {
        setSearch(!search);
    };
    return (
        <>
            {userAccessToken &&
            parseJwt(userAccessToken).role_code ==
                constants.USER_ROLES.REGISTRY_ADMIN ? (
                <RegistryTabs activeTab="" />
            ) : (
                window.localStorage.getItem("isProgramApproved") == "true" &&
                window.localStorage.getItem("isHospitalDeActiveOrDeleted") ==
                    "false" &&
                window.localStorage.getItem("userAccessToken") &&
                parseJwt(window.localStorage.getItem("userAccessToken"))
                    .role_code == "HOSPITAL_ADMIN" && <Navigator tabName="" />
            )}
            {userAccessToken &&
                parseJwt(userAccessToken).role_code ==
                    constants.USER_ROLES.CORPORATION_ADMIN && (
                    <CorporationTabs activeTab="" />
                )}
            <UserManagement
                users={users}
                isTableLoading={isTableLoading}
                searchUser={searchUser}
                inputUser={inputUser}
                setInputUser={setInputUser}
                searchFilter={searchFilter}
                searchkeywordLength={searchkeywordLength}
                isUsersResponseAvailable={isUsersResponseAvailable}
                roleValue={roleValue}
                pagination={pagination}
            />
        </>
    );
};

export default UserManagementPage;
