import { validJSON } from "common/utils";
import config from "../config";

export const getMaintenanceData = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
    };

    return new Promise((resolve, reject) => {
        fetch(
            `${config[config.env].apiEndpoints.strapi}/maintenances`,
            requestOptions
        )
            .then((response) => response.text())
            .then((result) => {
                if (validJSON(result)) {
                    const maintenanceResponse = JSON.parse(result);
                    if (maintenanceResponse.statusCode == 200) {
                        resolve(maintenanceResponse);
                    } else {
                        throw maintenanceResponse.error;
                    }
                } else {
                    throw new Error("Invalid json string in the response");
                }
            })
            .catch((error) => {
                reject(error);
            });
    });
};
