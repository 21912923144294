import CONSTANTS from "common/constants";
import React from "react";
import { Link } from "react-router-dom";
import { localDateToYYYYMMDDFormatString, wordSplit } from "../../common/utils";
import TableRowCount from "../TableRowCount";
import { CorporateTableWrapper } from "./styled";

interface Props {
    corporations: any;
    isTableLoading: boolean;
    tab: string;
    pageNumber: number;
    actionURL: string;
    pagination: any;
    tabType: string;
    roleValue: string;
}

export const CorporationTable = ({
    corporations,
    isTableLoading,
    tab,
    pageNumber,
    actionURL,
    pagination,
    tabType,
    roleValue,
}: Props) => {
    return (
        <CorporateTableWrapper className="mt-3 w-100">
            {/* <div
        className="corporate-search d-sm-flex"
        aria-labelledby="corportate-searchfield"
      >
        <h2 className="h6 mb-2 mb-sm-0 pr-4" id="corportate-searchfield">
          Search
        </h2>
        <div className="d-md-flex flex-grow-1 corporate-search-field">
          <input
            type="search"
            aria-label="search corporate"
            className="form-control"
            placeholder="Search By Corporation Name or Code"
          />
          <i className="aha-icon-search corporate-search-icon" />
        </div>
      </div> */}
            {/* <div
        className="corporate-filter d-xl-flex align-items-center"
        role="group"
        aria-labelledby="corportate-filter"
      >
        <h2 className="h6 mb-2 mb-xl-0 pr-4" id="corportate-filter">
          Filter By
        </h2>
        <div className="d-md-flex flex-grow-1">
          <div className="d-sm-flex flex-grow-1">
            <div className="d-md-flex flex-grow-1 align-items-center pr-sm-3">
              <label htmlFor="corporate-program" className="mr-3 mb-0">
                Program
              </label>
              <div className="flex-grow-1">
                <Dropdown
                  id="corporate-program"
                  items={[{ value: "Approved" }]}
                  selectedValue=""
                  callParentOnSelect=""
                />
              </div>
            </div>
            <div className="d-md-flex flex-grow-1 align-items-center pl-sm-3 mt-3 mt-sm-0">
              <label htmlFor="corporate-country" className="mr-3 mb-0">
                State
              </label>
              <div className="flex-grow-1">
                <Dropdown
                  id="corporate-country"
                  items={[{ value: "Country" }]}
                  selectedValue=""
                  callParentOnSelect=""
                />
              </div>
            </div>
          </div>
        </div>
      </div> */}
            <TableRowCount
                isFirst={pagination.isFirst}
                pageNumber={pagination.pageNumber}
                pageSize={pagination.pageSize}
                isLast={pagination.isLast}
                totalCount={pagination.totalCount}
                facilityType={tabType}
                corporationInd
                roleValue={roleValue}
            />
            <div className="corporate-table mt-3 w-100">
                <table
                    className={`aui-responsive-status-table ${
                        isTableLoading ? CONSTANTS.TABLE_SPINNER : ""
                    }`}
                >
                    <thead>
                        <tr>
                            <th scope="col">
                                <div className="aui-th justify-content-between">
                                    <div>Corporation Details</div>
                                    <button
                                        className="btn btn-text corp-sort pb-1 corp-sort-asc d-none"
                                        aria-label="sort by corporation details"
                                    >
                                        <i
                                            className="aha-icon-dash-arrow-right corp-sort-up"
                                            aria-hidden="true"
                                        />
                                        <i
                                            className="aha-icon-dash-arrow-right corp-sort-down"
                                            aria-hidden="true"
                                        />
                                    </button>
                                </div>
                            </th>
                            <th scope="col">
                                <div className="aui-th justify-content-between">
                                    <div>Location</div>
                                    <button
                                        className="btn btn-text corp-sort pb-1 corp-sort-desc d-none"
                                        aria-label="sort by Location"
                                    >
                                        <i
                                            className="aha-icon-dash-arrow-right corp-sort-up"
                                            aria-hidden="true"
                                        />
                                        <i
                                            className="aha-icon-dash-arrow-right corp-sort-down"
                                            aria-hidden="true"
                                        />
                                    </button>
                                </div>
                            </th>
                            <th scope="col">
                                <div className="aui-th justify-content-between">
                                    <div>Email Address</div>
                                    <button
                                        className="btn btn-text corp-sort pb-1 corp-sort-desc d-none"
                                        aria-label="sort by location"
                                    >
                                        <i
                                            className="aha-icon-dash-arrow-right corp-sort-up"
                                            aria-hidden="true"
                                        />
                                        <i
                                            className="aha-icon-dash-arrow-right corp-sort-down"
                                            aria-hidden="true"
                                        />
                                    </button>
                                </div>
                            </th>
                            <th scope="col">
                                <div className="aui-th justify-content-between">
                                    <div>Registered On</div>
                                    <button
                                        className="btn btn-text corp-sort pb-1 corp-sort-desc d-none"
                                        aria-label="sort by Registered on"
                                    >
                                        <i
                                            className="aha-icon-dash-arrow-right corp-sort-up"
                                            aria-hidden="true"
                                        />
                                        <i
                                            className="aha-icon-dash-arrow-right corp-sort-down"
                                            aria-hidden="true"
                                        />
                                    </button>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {corporations.length > 0 ? (
                            corporations.map((corporation: any) => {
                                return (
                                    <tr
                                        key={`corporation-${corporation.id}`}
                                        className={`${
                                            tab === "ACTIVE"
                                                ? "aui-table-status-success"
                                                : "aui-table-status-pending"
                                        }`}
                                    >
                                        <td data-title="Corporation Details">
                                            <div className="aui-td">
                                                <div>
                                                    <Link
                                                        className="btn-text-link-uline"
                                                        aria-label={`View ${corporation.corporationName} details`}
                                                        to={{
                                                            pathname: `${actionURL}/${corporation.id}`,
                                                            state: {
                                                                bT: tab,
                                                                bP: pageNumber,
                                                                corporationName:
                                                                    corporation.corporationName,
                                                            },
                                                        }}
                                                    >
                                                        {
                                                            corporation.corporationName
                                                        }
                                                    </Link>
                                                    <div className="mt-1">
                                                        Corporation Code:{" "}
                                                        <span className="abbr-code">
                                                            {wordSplit(
                                                                corporation.corporationCode
                                                            )}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td data-title="Location">
                                            <div className="aui-td d-flex flex-column">
                                                <div className="corp-d-location">
                                                    <div>Location:</div>
                                                    <div>
                                                        {
                                                            corporation.countryName
                                                        }
                                                    </div>
                                                </div>
                                                <div className="corp-d-location">
                                                    <div>State:</div>
                                                    <div>
                                                        {corporation.state}
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td data-title="Email Address">
                                            <div className="aui-td">
                                                {tab === CONSTANTS.ACTIVE
                                                    ? corporation.registeredEmailId
                                                    : corporation.primaryContactEmailAddress}
                                            </div>
                                        </td>
                                        <td data-title="Registered On">
                                            <div className="aui-td">
                                                {localDateToYYYYMMDDFormatString(
                                                    corporation.createdAt
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr className="aui-status-table-emptystate">
                                <td colSpan={4} className="text-center">
                                    <div className="aui-td">
                                        No data to display
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </CorporateTableWrapper>
    );
};

export default CorporationTable;
