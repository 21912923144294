import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import RegistryTable from "components/RegistryTable";
import Pagination, { IPagination } from "components/Pagination";
import CONSTANTS from "common/constants";
import { getRegistries } from "api/registryAPI";
import Sidebar from "../../Admin/Sidebar/Sidebar";
import { RegistryManagementWrapper } from "./styled";
import "../../styles/styles.scss";

export const RegistryManagement = () => {
    const location = useLocation();
    const tabType = location.state?.t;
    const page = location.state?.p;
    const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
    const [tab, setTab] = useState<string>(tabType || CONSTANTS.ACTIVE);
    const [activeRegistries, setActiveRegistries] = useState<any>([]);
    const [inactiveRegistries, setInactiveRegistries] = useState<any>([]);
    const [pageNumber, setPageNumber] = useState<number>(
        page ? Number(page) : 1
    );
    const paginationSetLimit: number = 5;
    const defaultPagination: IPagination = {
        pageNumber: 1,
        pageSize: 25,
        isFirst: true,
        isLast: false,
        setPageNumber,
        paginationSetLimit,
    };
    const [pagination, setPagination] =
        useState<IPagination>(defaultPagination);
    const [roleValue, setRoleValue] = useState<string>("none");
    const [mounted, setMounted] = useState<boolean>(false);

    useEffect(() => {
        setIsTableLoading(true);
        localStorage.setItem("resetFilter", "true");
        getRegistries(tab, pageNumber, pagination.pageSize).then(
            (response: any) => {
                if (response.success && response.statusCode === 200) {
                    if (tab === CONSTANTS.ACTIVE) {
                        setActiveRegistries(response.data.registries);
                    } else {
                        setInactiveRegistries(response.data.registries);
                    }
                    setPagination({
                        ...response.data._pagination,
                        setPageNumber,
                    });
                }
                setIsTableLoading(false);
                if (mounted) {
                    setRoleValue("alert");
                }
                setMounted(true);
            }
        );
        window.scrollTo(0, 0);
    }, [tab, pageNumber]);

    return (
        <RegistryManagementWrapper className="row no-gutters w-100 flex-grow-1">
            <Sidebar activeTab="registryManagement" />
            <section className="d-flex admin-main-content container">
                <div className="row no-gutters w-100 admin-main">
                    <div className="col-12">
                        <div className="d-md-flex justify-content-md-between reg-magnt">
                            <h1 className="h2 font-400 mb-0 mt-4 mt-md-0">
                                Registry Management
                            </h1>
                            <Link
                                to={{
                                    pathname:
                                        "/registry/registry-management/registry-details/add-registry/new-registry",
                                    state: { bT: tab, bP: pageNumber },
                                }}
                                className="btn btn-primary btn-round"
                            >
                                <span aria-hidden="true">Add +</span>
                                <span className="sr-only">
                                    Add new Registry
                                </span>
                            </Link>
                        </div>
                        <div className="registry-tabs">
                            <div
                                className="d-flex flex-wrap aui-accordion-tab"
                                id="registrytabs"
                                role="tablist"
                            >
                                <button
                                    role="tab"
                                    onClick={() => {
                                        setPageNumber(1);
                                        setPagination(defaultPagination);
                                        setTab(CONSTANTS.ACTIVE);
                                    }}
                                    className="aui-acc-tab-item aui-accordion-tab1 btn btn-text"
                                    data-toggle="collapse"
                                    data-target="#activeRegistry"
                                    aria-expanded={tab === CONSTANTS.ACTIVE}
                                >
                                    Active Registries
                                    <i
                                        className="aha-icon-arrow-down mx-2 d-inline-block d-md-none"
                                        aria-hidden="true"
                                    />
                                </button>
                                <button
                                    role="tab"
                                    onClick={() => {
                                        setPageNumber(1);
                                        setPagination(defaultPagination);
                                        setTab(CONSTANTS.INACTIVE);
                                    }}
                                    className="aui-acc-tab-item aui-accordion-tab2 btn btn-text"
                                    data-toggle="collapse"
                                    data-target="#inactiveRegistry"
                                    aria-expanded={tab === CONSTANTS.INACTIVE}
                                >
                                    Inactive Registries
                                    <i
                                        className="aha-icon-arrow-down mx-2 d-inline-block d-md-none"
                                        aria-hidden="true"
                                    />
                                </button>
                                <div
                                    id="activeRegistry"
                                    role="tabpanel"
                                    className={`collapse ${
                                        tab === CONSTANTS.ACTIVE ? "show" : ""
                                    } row w-100 no-gutters aui-accordion-content aui-accordion-content1`}
                                    data-parent="#registrytabs"
                                >
                                    {isTableLoading ? (
                                        <div
                                            role="alert"
                                            aria-live="assertive"
                                            aria-label="active registry table is loading"
                                            className="sr-only"
                                        />
                                    ) : (
                                        ""
                                    )}
                                    <RegistryTable
                                        registryTab={
                                            CONSTANTS.FACILITY_TYPE.ACTIVE
                                        }
                                        pagination={pagination}
                                        registries={activeRegistries}
                                        actionURL="/registry/registry-management/registry-details"
                                        isTableLoading={isTableLoading}
                                        tab={tab}
                                        pageNumber={pageNumber}
                                        roleValue={roleValue}
                                    />
                                </div>

                                <div
                                    id="inactiveRegistry"
                                    role="tabpanel"
                                    className={`collapse ${
                                        tab === CONSTANTS.INACTIVE ? "show" : ""
                                    } row w-100 no-gutters aui-accordion-content aui-accordion-content2`}
                                    data-parent="#registrytabs"
                                >
                                    {isTableLoading ? (
                                        <div
                                            role="alert"
                                            aria-live="assertive"
                                            aria-label="inactive registry table is loading"
                                            className="sr-only"
                                        />
                                    ) : (
                                        ""
                                    )}
                                    <RegistryTable
                                        registryTab={
                                            CONSTANTS.FACILITY_TYPE.INACTIVE
                                        }
                                        pagination={pagination}
                                        registries={inactiveRegistries}
                                        actionURL="/registry/registry-management/registry-details"
                                        isTableLoading={isTableLoading}
                                        tab={tab}
                                        pageNumber={pageNumber}
                                        roleValue={roleValue}
                                    />
                                </div>
                            </div>
                        </div>

                        {pagination && Number(pagination.totalPages) > 0 ? (
                            <Pagination
                                pageNumber={pagination.pageNumber}
                                pageSize={pagination.pageSize}
                                totalCount={pagination.totalCount}
                                totalPages={pagination.totalPages}
                                isFirst={pagination.isFirst}
                                isLast={pagination.isLast}
                                setPageNumber={pagination.setPageNumber}
                                paginationSetLimit={paginationSetLimit}
                            />
                        ) : null}
                    </div>
                </div>
            </section>
        </RegistryManagementWrapper>
    );
};

export default RegistryManagement;
