import styled from "styled-components";

export const DashboardChartsWrapper = styled.div`
    .benchmark {
        z-index: 1;
        .benchmark-icon {
            font-size: 15px;
            background-color: transparent;
            border: none;
            margin: -2px 10px 0 -2px;
        }
        .bm-help {
            width: 200px;
            left: -88px;
            &:before,
            &:after {
                left: 49%;
            }
        }
        .form-check {
            padding-left: 26px;
            white-space: nowrap;
            input[type="checkbox"] {
                width: 18px;
                height: 18px;
                &:checked + label:before {
                    width: 18px;
                    height: 18px;
                }
            }
            label {
                line-height: 1.4;
                &:after {
                    top: 4px;
                    left: 3px;
                    width: 11px;
                }
                &:before {
                    width: 18px;
                    height: 18px;
                }
            }
        }
    }
`;
