import styled from "styled-components";

export const NewCorporationWrapper = styled.div``;

export const CorporationDetailWrapper = styled.div`
    .corp-title-border {
        border-bottom: 2px solid #c10e21;
        margin-bottom: 26px;
        .corp-edit-btn {
            color: #c10e21;
            .corp-edit {
                font-size: 24px;
            }
            .corp-canceledit {
                font-size: 16px;
            }
        }
    }
`;

export const CorporationFormWrapper = styled.div`
    .confirmEmail-modal {
        &.aui-modal .modal-header {
            border-bottom: 2px solid #c10e21;
        }
        .confirmEmail-title {
            font-size: 14px;
        }
    }
    .corp-table-btn:not(.corp-btn-edit) {
        padding-left: 0;
        @media only screen and (min-width: 768px) {
            padding-left: 40%;
        }
        @media only screen and (min-width: 992px) {
            padding-left: 45%;
        }
    }
    .corp-table-btn {
        &.corp-btn-edit {
            border-top: 1px solid #e3e3e3;
            margin-top: 36px;
            padding-top: 24px;
            padding-bottom: 24px;
        }
        .corp-btn-1,
        .corp-btn-2 {
            width: 100%;
            margin-bottom: 24px;
            @media only screen and (min-width: 576px) {
                width: 48%;
                margin-bottom: 0;
            }
            @media only screen and (min-width: 768px) {
                width: auto;
            }
        }
        .corp-btn-2 {
            @media only screen and (min-width: 576px) {
                margin-left: 2%;
            }
            @media only screen and (min-width: 768px) {
                margin-left: 24px;
            }
        }
    }
    .confirmEmail-btns {
        .btn {
            width: 100%;
            @media only screen and (min-width: 576px) {
                width: auto;
            }
        }
    }
`;

export const CorporationRegisteredFacilitiesWrapper = styled.section`
    .corp-loc-detail {
        span {
            display: inline-block;
            width: 78px;
        }
    }
    .corp-facility-table {
        .btn {
            font-size: 14px;
            padding: 0;
        }
    }
    thead {
        @media only screen and (min-width: 992px) {
            th:nth-child(1) {
                width: 25%;
            }
            th:nth-child(2) {
                width: 22%;
            }
            th:nth-child(3) {
                width: 23%;
            }
            th:nth-child(4) {
                width: 18%;
            }
            th:nth-child(5) {
                width: 12%;
            }
        }
    }
`;
