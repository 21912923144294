import React from "react";
import { Link } from "react-router-dom";

interface Props {
    breadcrumbItems: any;
}

export const BreadCrumb = (props: Props) => {
    const renderBreadcrumbItems = () => {
        if (props.breadcrumbItems?.length > 0) {
            const elements = props.breadcrumbItems.map(
                (item: any, index: any) => {
                    return (
                        <React.Fragment key={item.label}>
                            <li
                                className={`breadcrumb-item hide ${
                                    index == props.breadcrumbItems.length - 1 &&
                                    "active"
                                }`}
                            >
                                {index == props.breadcrumbItems.length - 1 ? (
                                    <div
                                        aria-current="page"
                                        role="link"
                                        tabIndex={0}
                                    >
                                        {item.label}
                                    </div>
                                ) : (
                                    <Link to={item.returnPath}>
                                        {item.label}
                                    </Link>
                                )}
                            </li>
                        </React.Fragment>
                    );
                }
            );
            return elements;
        }
    };

    return (
        <nav aria-label="breadcrumb" className="mb-3">
            <ol className="breadcrumb aha-breadcrumb px-0">
                {renderBreadcrumbItems()}
            </ol>
        </nav>
    );
};
