import React, { useEffect, useRef, useState } from "react";
import HospitalBanner from "pages/Admin/HospitalBanner/HospitalBanner";
import Dropdown from "components/Dropdown";
import CONSTANTS from "common/constants";
import { useParams } from "react-router";
import store from "app/store";
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import {
    getAwardsAndBrandingMaterials,
    getHospitalProgramCertificate,
    getAssetDetails,
    saveCertificate,
} from "api/certificateAPI";
import "styles/tooltip.scss";
import { logger } from "utils/logger.utils";
import { showToast } from "components/Toast/toast.slice";
import { getEMSAwards } from "api/emsAPI";
import "styles/model-red.scss";
import { sleep, showModal, dateToYYYYMMDDFormatString } from "common/utils";
import FocusTrap from "focus-trap-react";
import html2canvas from "html2canvas";
import Datepicker, { getSysTimeZoneCurrentDate } from "components/Datepicker";
import { CertificationTabWrapper } from "./styled";

interface Props {
    hospital: any;
    programName: any;
    programData: any;
    certificateData: any;
}

export const OrganizationCertificationTab = (props: Props) => {
    const [awardYear, setAwardYear] = useState<any>(new Date().getFullYear());
    const [currentAwardYear, setCurrentAwardYear] = useState<any>(
        new Date().getFullYear()
    );
    const [awardsDropdown, setAwardsDropdown] = useState<any>([]);
    const sysTimeZoneCurrDate = getSysTimeZoneCurrentDate();
    const [certificateInfo, setCertificateInfo] = useState<any>({
        grantedOn: dateToYYYYMMDDFormatString(sysTimeZoneCurrDate),
    });
    const [awards, setAwards] = useState<any>([]);
    const [generateCertificateButtonLabel, setGenerateCertificateButtonLabel] =
        useState<any>("Generate Award");
    const [selectedYear, setSelectedYear] = useState<any>(
        new Date().getFullYear()
    );
    const [eligibleAward, setEligibleAward] = useState<any>("");
    const [awardQuarter, setAwardQuarter] = useState<any>("");
    const [targetAward, setTargetAward] = useState<any>("");
    const [certificates, setCertificates] = useState<any>({});
    const grantedDateRef = useRef<any>(null);
    const [brandingMaterials, setBrandingMaterials] = useState<any>([]);
    const { programId, categoryId }: any = useParams();
    const [notApplicableDisable, setNotApplicableDisable] = useState<any>("");
    const [isCertificateYearExists, setisCertificateYearExists] =
        useState<boolean>(false);
    const formRef = useRef<HTMLFormElement>(null);
    const [selectedAward, setSelectedAward] = useState<any>("");
    const previewCertificateRef = useRef<HTMLButtonElement>(null);
    const [
        exportProgramMeasureActivateInd,
        setExportProgramMeasureActivateInd,
    ] = useState<boolean>(false);
    const [showBrandingMaterials, setShowBrandingMaterials] =
        useState<boolean>(true);
    const [targetState, setTargetState] = useState<any>("");
    const [awardInd, setAwardInd] = useState<boolean>(false);
    const [isAwarded, setIsAwarded] = useState<boolean>(false);
    const params: any = useParams();

    const getYears = () => {
        const years = [];
        for (let i = CONSTANTS.INIT_AWARD_YEAR; i <= currentAwardYear; i++) {
            years.push(i);
        }
        const yearsDropdownMap: any = [];
        if (years && years.length > 0) {
            years.forEach((year: any) => {
                yearsDropdownMap.push({ label: year, value: year });
            });
        }
        return yearsDropdownMap;
    };
    const yearsDropdown: Array<object> = getYears();
    const onChangeAwardYear = (value: any) => {
        setAwardYear(value);
    };

    // On Change certificateInfo
    useEffect(() => {
        const currentDate = getSysTimeZoneCurrentDate();
        if (certificateInfo.grantedOn === "") {
            setCertificateInfo({
                ...certificateInfo,
                grantedOn: dateToYYYYMMDDFormatString(currentDate),
            });
        }
        validateCertificateForm();
        if (
            certificateInfo.award &&
            certificateInfo.award !== "No Award" &&
            certificateInfo.award !== "N/A"
        ) {
            setShowBrandingMaterials(true);
        }
        if (
            certificateInfo.award === "No Award" ||
            certificateInfo.award === "N/A"
        ) {
            setSelectedAward(certificateInfo.award);
            setShowBrandingMaterials(false);
        }
    }, [certificateInfo]);

    useEffect(() => {
        if (awards?.length === 1) {
            const awardsDropdownMap = awards.map((award: any) => {
                return { label: award.awardName, value: award.awardName };
            });
            setAwardsDropdown(awardsDropdownMap);
        }
        if (awards?.length > 1) {
            if (eligibleAward === "N/A") {
                setShowBrandingMaterials(false);
                const awardsDropdownMap = awards.map((award: any) => {
                    return { label: "N/A", value: "N/A" };
                });
                setAwardsDropdown(awardsDropdownMap);
                setSelectedAward("N/A");
            } else {
                setShowBrandingMaterials(false);
                const awardsDropdownMap: any = [];
                awards.map((award: any) => {
                    const words = award.awardName?.split(" ");
                    if (words?.length > 0 && words[1] != "Target") {
                        awardsDropdownMap.push({
                            label: award.awardName,
                            value: award.awardName,
                        });
                    }
                });
                awardsDropdownMap.push({
                    label: "No Award",
                    value: "No Award",
                });
                setAwardsDropdown(awardsDropdownMap);
            }
        }
    }, [awards]);

    const onChangeTarget = (event: any) => {
        const { value } = event.target;
        setTargetState(value);
        if (value === "Yes") {
            loadBrandingMaterials(certificates, `${selectedAward} Target`);
        } else if (value === "No") {
            loadBrandingMaterials(certificates, selectedAward);
        }
    };

    const validateCertificateForm = () => {
        if (selectedAward !== "No Award" && selectedAward !== "N/A") {
            if (
                !certificateInfo.award ||
                !certificateInfo.city ||
                !certificateInfo.state ||
                !certificateInfo.countryName
            ) {
                return false;
            }

            if (!certificateInfo.grantedOn) {
                if (grantedDateRef && grantedDateRef.current) {
                    grantedDateRef.current.valueAsDate = null;
                }
                return false;
            }
            return true;
        }
        return true;
    };

    const createCanvas = async () => {
        return new Promise((resolve, reject) => {
            const iframe: any = document.createElement("iframe");

            iframe.setAttribute("height", "0");
            iframe.setAttribute("width", "0");
            iframe.className = "hiddenFrame";
            const imagePreviewContainer: any = document.getElementById(
                "imagePreviewContainer"
            );
            imagePreviewContainer.innerHTML = "";
            const selectedValue =
                targetState === `Yes`
                    ? `${selectedAward} Target`
                    : selectedAward;
            let userMaterial;
            certificates.map((certificateDeatils: any) => {
                if (
                    certificateDeatils?.certificateCategoryName ===
                    selectedValue
                ) {
                    userMaterial =
                        certificateDeatils.certificateTemplateReference;
                }
            });
            getAssetDetails(userMaterial, true).then((result) => {
                let templateHtml: any;
                fetch(`${result.data.assets[0].presignedUrl}`)
                    .then((response) => {
                        return response.text();
                    })
                    .then((template: any) => {
                        templateHtml = template;
                        document.body.appendChild(iframe);

                        iframe.onload = () => {
                            const hiddenFrame: any =
                                document.getElementsByClassName(
                                    "hiddenFrame"
                                )[0];
                            const hiddenFrameDoc =
                                hiddenFrame.contentWindow.document;

                            modifyCertificateTemplate(hiddenFrameDoc);

                            html2canvas(hiddenFrameDoc.body, {
                                scale: 2,
                                useCORS: true,
                                allowTaint:
                                    true /* logging: true, foreignObjectRendering: true */,
                            }).then((canvas) => {
                                if (hiddenFrame && hiddenFrame.parentNode) {
                                    hiddenFrame.parentNode.removeChild(
                                        hiddenFrame
                                    );
                                }

                                const certificateDataUrl = canvas.toDataURL(
                                    "image/png",
                                    1.0
                                ); // toDataURL('image/png', 1.0)

                                imagePreviewContainer.appendChild(canvas);
                                resolve(certificateDataUrl);
                            });
                        };

                        iframe.contentWindow.document.open();
                        iframe.contentWindow.document.write(templateHtml);
                        iframe.contentWindow.document.close();
                    });
            });
        });
    };

    const modifyCertificateTemplate = (template: any) => {
        if (!certificateInfo.award.includes("Performance")) {
            if (template.getElementsByClassName("cer-title")[0]) {
                if (template.getElementsByClassName("cer-title")[0].innerHTML) {
                    template.getElementsByClassName("cer-title")[0].innerHTML =
                        certificateInfo.award;
                } else {
                    template.getElementsByClassName("cer-title")[0].innerHTML =
                        null;
                }
            }
            template.getElementById(
                "facilityname"
            ).innerHTML = `${certificateInfo.facilityName
                ?.slice(0, CONSTANTS.CERTIFICATE_FACILITY_NAME_LIMIT)
                ?.split(" ")
                .join(" ")}`;
            if (props.hospital.countryCode == CONSTANTS.DOMESTIC_COUNTRY_CODE) {
                template.getElementById(
                    "citystate"
                ).innerHTML = `${certificateInfo.city
                    ?.split(" ")
                    .join("&nbsp;")}, ${certificateInfo.state
                    ?.split(" ")
                    .join("&nbsp;")}`;
            } else {
                template.getElementById(
                    "citystate"
                ).innerHTML = `${certificateInfo.city
                    ?.split(" ")
                    .join("&nbsp;")}, ${certificateInfo.countryName
                    ?.split(" ")
                    .join("&nbsp;")}`;
            }

            if (template.getElementById("heading-year")) {
                if (isCertificateYearExists) {
                    template.getElementById("heading-year").innerHTML =
                        selectedYear;
                } else {
                    template.getElementById("heading-year").innerHTML =
                        new Date().getFullYear();
                }
            }
            if (template.getElementById("badge-year")) {
                if (isCertificateYearExists) {
                    template.getElementById("badge-year").innerHTML =
                        selectedYear;
                } else {
                    template.getElementById("badge-year").innerHTML =
                        new Date().getFullYear();
                }
            }
        }
    };
    const setPreviewModal = async () => {
        store.dispatch(setPageLoadingStatus({ isPageLoading: true }));
        previewCertificateRef &&
            previewCertificateRef.current &&
            previewCertificateRef.current.click();
        await createCanvas();
        store.dispatch(setPageLoadingStatus({ isPageLoading: false }));
    };

    const dismissAwardGenModal = () => {
        setExportProgramMeasureActivateInd(false);
        showModal();
    };

    const confirmAwardGenerate = async (e: any) => {
        dismissAwardGenModal();
        store.dispatch(setPageLoadingStatus({ isPageLoading: true }));
        const generatedImage =
            selectedAward === "No Award"
                ? "No Award"
                : selectedAward === "N/A"
                ? "N/A"
                : await createCanvas();
        sleep(1000).then(() => {
            const brandingMaterialsSelectedInd = brandingMaterials.some(
                (item: any) => {
                    return item.isAllowed === true;
                }
            );
            const materials: any = JSON.parse(
                JSON.stringify(brandingMaterials)
            );
            saveCertificate(
                certificateInfo,
                materials,
                props.hospital.hospitalId,
                programId,
                categoryId,
                generatedImage,
                props.hospital.countryId,
                true,
                selectedYear
            )
                .then((response) => {
                    store.dispatch(
                        setPageLoadingStatus({ isPageLoading: false })
                    );
                    const success = {
                        message: certificateInfo.programCertificateId
                            ? `Award ${
                                  brandingMaterialsSelectedInd
                                      ? "and Branding materials"
                                      : ""
                              } successfully updated for ${
                                  props.hospital.facilityName
                              }.`
                            : `Award ${
                                  brandingMaterialsSelectedInd
                                      ? "and Branding materials"
                                      : ""
                              } successfully generated for ${
                                  props.hospital.facilityName
                              }.`,
                        code: "Success:",
                        type: "success",
                    };
                    setGenerateCertificateButtonLabel("Update Award");
                    store.dispatch(showToast(success));
                    setCertificateInfo({
                        ...certificateInfo,
                        programCertificateId: true,
                    });
                })
                .catch((error) => {
                    logger("error: ", error);
                    store.dispatch(
                        setPageLoadingStatus({ isPageLoading: false })
                    );
                });
        });
        e.preventDefault();
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        if (isNaN(new Date(certificateInfo.grantedOn) as any)) {
            return false;
        }
        if (validateCertificateForm()) {
            if (
                event?.nativeEvent?.submitter?.name === "preview" &&
                selectedAward !== "N/A" &&
                selectedAward !== "No Award"
            ) {
                setPreviewModal();
            } else {
                setCertificateInfo({
                    ...certificateInfo,
                    award:
                        targetState === "Yes"
                            ? `${selectedAward} Target`
                            : selectedAward,
                });
                if (event?.nativeEvent?.submitter?.name !== "preview") {
                    setExportProgramMeasureActivateInd(true);
                    showModal();
                }
            }
        }
    };

    useEffect(() => {
        if (CONSTANTS.WINDOW.AUI.Tooltip) {
            CONSTANTS.WINDOW.AUI.Tooltip.init();
        }
    });

    const handleDateChange = (date: any) => {
        if (date) {
            setCertificateInfo({
                ...certificateInfo,
                grantedOn: date,
            });
        }
    };

    const handleChange = (event: any) => {
        if (event.target.value) {
            event.target.value = event.target.value.replace(/[><]/g, "");
        }
        setCertificateInfo({
            ...certificateInfo,
            [event.target.name]:
                event.target.type == "checkbox"
                    ? event.target.checked
                    : event.target.value.trim(),
        });
    };

    const onChangeAward = (value: any) => {
        setSelectedAward(value);
        setCertificateInfo({
            ...certificateInfo,
            award: value,
        });
        if (value === "No Award") {
            setTargetState("No");
            setShowBrandingMaterials(false);
        }
        if (awards?.length > 1) {
            setShowBrandingMaterials(true);
        }
        const updatedAward = targetState === "Yes" ? `${value} Target` : value;
        if (value !== "No Award") {
            loadBrandingMaterials(certificates, updatedAward);
        }
    };

    const loadBrandingMaterials = (
        certificatesData: any,
        value: any,
        userData: any = null
    ) => {
        store.dispatch(setPageLoadingStatus({ isPageLoading: true }));
        const referenceIdArray = new Array(0);
        let selectedCertificate;
        if (value || userData?.programCertificateName) {
            const selectedValue =
                value || (userData ? userData.programCertificateName : null);
            selectedCertificate = certificatesData?.filter(
                (certificateObj: any) => {
                    return (
                        certificateObj.certificateCategoryName === selectedValue
                    );
                }
            );
        } else {
            selectedCertificate = certificatesData;
        }

        if (selectedCertificate.length > 0) {
            selectedCertificate[0].brandingMaterials.map((material: any) => {
                referenceIdArray.push(material.referenceId);
            });
            const referenceIdString = referenceIdArray.join(",");
            getAssetDetails(referenceIdString)
                .then((result) => {
                    const assetArray = new Array(0);
                    let isAllowed: any;
                    result.data.assets.map((asset: any) => {
                        if (userData?.brandingMaterials) {
                            const userMaterial =
                                userData.brandingMaterials.filter(
                                    (data: any) => {
                                        return (
                                            data.referenceId ==
                                            asset.referenceId
                                        );
                                    }
                                );
                            isAllowed = userMaterial.length > 0;
                        }

                        assetArray.push({
                            assetRefId: asset.referenceId,
                            assetName: asset.assetName,
                            isAllowed: !userData ? true : isAllowed,
                        });
                    });
                    setBrandingMaterials(assetArray);
                    store.dispatch(
                        setPageLoadingStatus({ isPageLoading: false })
                    );
                })
                .catch((error) => {
                    logger(error);
                    store.dispatch(showToast(error));
                });
        }
    };

    const handleKeyDown = (event: any) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            formRef.current?.requestSubmit();
        }
    };
    const handleMaterialChange = (event: any) => {
        const materials = brandingMaterials;
        materials.map((certificateObj: any) => {
            if (certificateObj.assetName == event.target.name) {
                certificateObj.isAllowed = event.target.checked;
            }
        });
        setBrandingMaterials(materials);
    };

    const fetchHospitalDetails = async (props: any) => {
        if (props.hospital && Object.keys(props.hospital).length > 0) {
            store.dispatch(setPageLoadingStatus({ isPageLoading: true }));
            const Year = awardYear - 1;
            const eligibleAwardObj = await getEMSAwards(
                props.hospital.hospitalId,
                params.programId,
                params.categoryId,
                Year
            );

            if (eligibleAwardObj.data.eligibleAward) {
                setEligibleAward(eligibleAwardObj.data.eligibleAward);
                setAwardQuarter(eligibleAwardObj.data.quarter);
                eligibleAwardObj.data.tac === true
                    ? setTargetAward("Yes")
                    : setTargetAward("No");
                eligibleAwardObj.data.tac === true
                    ? setTargetState("Yes")
                    : setTargetState("No");
                setSelectedAward(eligibleAwardObj.data.eligibleAward);
                if (eligibleAwardObj.data.eligibleAward === "N/A") {
                    setAwardInd(true);
                }
            }

            if (
                eligibleAwardObj.data.eligibleAward === CONSTANTS.NOT_APPLICABLE
            ) {
                setNotApplicableDisable(true);
                setTargetState("No");
                setSelectedAward("N/A");
                setShowBrandingMaterials(false);
            }

            getHospitalProgramCertificate(
                props.hospital.hospitalId,
                params.programId,
                params.categoryId
            )
                .then((certificateObj: any) => {
                    if (
                        certificateObj.data.programCertficate &&
                        certificateObj.data.programCertficate
                            .programCertificateId
                    ) {
                        setGenerateCertificateButtonLabel("Update Award");
                        setIsAwarded(true);
                        const { programCertificateName } =
                            certificateObj.data.programCertficate;
                        const certificateTargetState =
                            programCertificateName.includes("Target")
                                ? "Yes"
                                : "No";
                        setTargetState(certificateTargetState);
                        setSelectedAward(programCertificateName.split(" ")[0]);
                        getAwardsAndBrandingMaterials(
                            params.programId,
                            params.categoryId,
                            props.hospital.countryId
                        ).then((result) => {
                            if (
                                certificateObj.data.programCertficate
                                    .certificateYear
                            ) {
                                setSelectedYear(
                                    certificateObj.data.programCertficate
                                        .certificateYear
                                );
                            } else {
                                setSelectedYear(
                                    parseInt(
                                        certificateObj.data.programCertficate.programCertificateGrantedOn.slice(
                                            0,
                                            4
                                        )
                                    )
                                );
                            }
                            setCertificates(result.data.certificates);
                            if (result.data.isCertificateYearExists) {
                                setisCertificateYearExists(
                                    result.data.isCertificateYearExists
                                );
                            }
                            const awardsArray = new Array(0);
                            result.data.certificates.map((award: any) => {
                                awardsArray.push({
                                    awardId: award.certificateAwardId,
                                    awardName: award.certificateCategoryName,
                                });
                            });
                            setCertificates(result.data.certificates);
                            setAwards(awardsArray);
                            setCertificateInfo({
                                facilityName:
                                    certificateObj.data.programCertficate
                                        .programCertificateFacilityName ||
                                    props.hospital.facilityName,
                                award: certificateObj.data.programCertficate
                                    .programCertificateName,
                                city: certificateObj.data.programCertficate
                                    .programCertificateCity,
                                state: certificateObj.data.programCertficate
                                    .programCertificateState,
                                countryName:
                                    certificateObj.data.programCertficate
                                        .programCertificateCountry,
                                grantedOn:
                                    certificateObj.data.programCertficate
                                        .programCertificateGrantedOn ===
                                    undefined
                                        ? dateToYYYYMMDDFormatString(new Date())
                                        : certificateObj.data.programCertficate
                                              .programCertificateGrantedOn,
                                programCertificateId:
                                    certificateObj.data.programCertficate
                                        .programCertificateId,
                            });
                            loadBrandingMaterials(
                                result.data.certificates,
                                false,
                                certificateObj.data.programCertficate
                            );
                            store.dispatch(
                                setPageLoadingStatus({ isPageLoading: false })
                            );
                        });
                    } else {
                        getAwardsAndBrandingMaterials(
                            params.programId,
                            params.categoryId,
                            props.hospital.countryId
                        )
                            .then((result) => {
                                setCertificates(result.data.certificates);
                                const awardsData = new Array(0);
                                result.data.certificates.map((award: any) => {
                                    awardsData.push({
                                        awardId: award.certificateAwardId,
                                        awardName:
                                            award.certificateCategoryName,
                                    });
                                });
                                setAwards(awardsData);
                                setCertificateInfo({
                                    award: eligibleAwardObj.data.eligibleAward,
                                    city: props.hospital.city,
                                    state: props.hospital.state,
                                    countryName: props.hospital.countryName,
                                    facilityName: props.hospital.facilityName,
                                    grantedOn: "",
                                });
                                const defaultAward =
                                    eligibleAwardObj.data.tac === true
                                        ? `${eligibleAwardObj.data.eligibleAward} Target`
                                        : eligibleAwardObj.data.eligibleAward;
                                loadBrandingMaterials(
                                    result.data.certificates,
                                    defaultAward
                                );
                                store.dispatch(
                                    setPageLoadingStatus({
                                        isPageLoading: false,
                                    })
                                );
                            })
                            .catch((error) => {
                                logger(error);
                                store.dispatch(showToast(error));
                                store.dispatch(
                                    setPageLoadingStatus({
                                        isPageLoading: false,
                                    })
                                );
                            });
                    }
                })
                .catch((error) => {
                    logger(error);
                });
        }
    };

    useEffect(() => {
        fetchHospitalDetails(props);
    }, [props.hospital]);

    useEffect(() => {
        if (certificateInfo.grantedOn) {
            if (grantedDateRef && grantedDateRef.current) {
                grantedDateRef.current.value = new Date(
                    certificateInfo.grantedOn
                )
                    .toISOString()
                    .substr(0, 10);
            }
        }
    }, [certificateInfo.grantedOn, grantedDateRef]);

    return (
        <CertificationTabWrapper>
            <HospitalBanner
                hospitalName={props.hospital.facilityName}
                hospital={props.hospital}
                programName={props.programName}
                countryName={props.hospital.countryName}
                programId={params?.programId}
                programData={props.programData}
                certificateData={props.certificateData}
                emsAdminInd
            />
            <form ref={formRef} onSubmit={handleSubmit}>
                <div className="d-md-flex justify-content-between cert-header">
                    <h3 className="h3 mb-3 mb-md-0">Award Certificate</h3>

                    <div className="d-flex align-items-center award-year">
                        <label
                            htmlFor="awardYear"
                            className="mb-0 mr-3"
                            id="awardYear-label"
                        >
                            Select Award Year
                        </label>
                        <Dropdown
                            id="awardYear"
                            items={yearsDropdown}
                            selectedValue={awardYear}
                            callParentOnSelect={onChangeAwardYear}
                        />
                    </div>
                </div>
                <div className="cert-form">
                    <div
                        role="group"
                        aria-labelledby="autogenerated"
                        className="cert-autogenerated"
                    >
                        <div className="d-flex align-items-start">
                            <h4 className="cert-sub-header" id="autogenerated">
                                Auto Generated{" "}
                            </h4>
                            <div id="award-info" className="award-tooltip">
                                <button
                                    data-container="#award-info"
                                    type="button"
                                    className="aha-icon-warning-round warning-help"
                                    data-toggle="tooltip"
                                    data-placement="right"
                                    data-trigger="click"
                                    title="Eligible awards are autogenerated based on Volume and Target rules"
                                >
                                    <span className="sr-only">
                                        what is Eligible Award
                                    </span>
                                </button>
                            </div>
                        </div>

                        <div className="row mb-3">
                            <span className="col-md-5">Eligible Award</span>
                            <span className="col-md-7 font-600">
                                {eligibleAward !== "N/A" && awardQuarter
                                    ? `${eligibleAward} (Q${awardQuarter})`
                                    : eligibleAward}
                            </span>
                        </div>
                        <div className="row">
                            <span className="col-md-5">
                                Eligible for Target Award
                            </span>
                            <span className="col-md-7 font-600">
                                {targetAward}
                            </span>
                        </div>
                    </div>
                    <div
                        role="group"
                        aria-labelledby="updateaward"
                        className="cert-updateaward"
                    >
                        <h4 className="cert-sub-header" id="updateaward">
                            Update Award Details
                        </h4>
                        <div className="form-group row required">
                            <label
                                htmlFor="awardType"
                                className="mb-0 col-md-5 label-form"
                                id="awardType-label"
                            >
                                Select Award
                                <sup>*</sup>
                            </label>
                            <div className="col-md-7">
                                {awardsDropdown && (
                                    <Dropdown
                                        disabled={
                                            notApplicableDisable ||
                                            awardsDropdown.length == 0 ||
                                            awardInd
                                        }
                                        id="awardType"
                                        items={awardsDropdown}
                                        selectedValue={selectedAward}
                                        callParentOnSelect={onChangeAward}
                                    />
                                )}
                            </div>
                        </div>
                        <div
                            className="form-group row"
                            role="group"
                            aria-labelledby="targetaward"
                        >
                            <label className="col-md-5" id="targetaward">
                                Select Target Award
                            </label>
                            <div className="col-md-7">
                                <div
                                    className="d-flex flex-row flex-wrap"
                                    role="group"
                                >
                                    <div className="form-radio mr-4">
                                        <input
                                            type="radio"
                                            disabled={
                                                notApplicableDisable ||
                                                selectedAward === "No Award"
                                            }
                                            checked={
                                                targetState === "Yes" &&
                                                selectedAward !== "No Award"
                                            }
                                            name="targetaward"
                                            id="target-yes"
                                            value="Yes"
                                            onChange={onChangeTarget}
                                        />
                                        <label htmlFor="target-yes">Yes</label>
                                    </div>
                                    <div className="form-radio ml-4">
                                        <input
                                            type="radio"
                                            checked={
                                                targetState === "No" ||
                                                selectedAward === "No Award" ||
                                                eligibleAward === "N/A"
                                            }
                                            name="targetaward"
                                            id="target-no"
                                            value="No"
                                            onChange={onChangeTarget}
                                        />
                                        <label htmlFor="target-no">No</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-group row required">
                            <label
                                role="group"
                                htmlFor="facilityName"
                                className="mb-0 col-md-5 label-form"
                            >
                                Organization Name
                                <sup>*</sup>
                            </label>
                            <div className="col-md-7">
                                <input
                                    title={CONSTANTS.ENTER_ALPHANUMERIC_TEXT}
                                    defaultValue={certificateInfo.facilityName?.slice(
                                        0,
                                        CONSTANTS.CERTIFICATE_FACILITY_NAME_LIMIT
                                    )}
                                    type="text"
                                    onChange={handleChange}
                                    onKeyDown={handleKeyDown}
                                    className="form-control"
                                    id="facilityName"
                                    name="facilityName"
                                    required
                                    maxLength={
                                        CONSTANTS.CERTIFICATE_FACILITY_NAME_LIMIT
                                    }
                                    aria-describedby="corpFacName"
                                />
                                <div className="form-help" id="corpFacName">
                                    (Enter 3 to{" "}
                                    {CONSTANTS.CERTIFICATE_FACILITY_NAME_LIMIT}{" "}
                                    characters)
                                </div>
                            </div>
                        </div>
                        <div className="form-group row required">
                            <label
                                role="group"
                                htmlFor="city"
                                className="mb-0 col-md-5 label-form"
                            >
                                City
                                <sup>*</sup>
                            </label>
                            <div className="col-md-7">
                                <input
                                    title={CONSTANTS.ENTER_ALPHANUMERIC_TEXT}
                                    name="city"
                                    defaultValue={certificateInfo.city}
                                    type="text"
                                    className="form-control"
                                    id="city"
                                    onChange={handleChange}
                                    onKeyDown={handleKeyDown}
                                    required
                                    maxLength={30}
                                    aria-describedby="corpFacCity"
                                />
                                <div className="form-help" id="corpFacCity">
                                    (Enter 2 to 30 characters)
                                </div>
                            </div>
                        </div>
                        <div className="form-group row required">
                            <label
                                role="group"
                                htmlFor="state"
                                className="mb-0 col-md-5 label-form"
                            >
                                State / Province
                                <sup>*</sup>
                            </label>
                            <div className="col-md-7">
                                <input
                                    title={CONSTANTS.ENTER_ALPHANUMERIC_TEXT}
                                    defaultValue={certificateInfo.state}
                                    type="text"
                                    onChange={handleChange}
                                    onKeyDown={handleKeyDown}
                                    className="form-control"
                                    name="state"
                                    id="state"
                                    required
                                    maxLength={30}
                                    aria-describedby="corpFacState"
                                />
                                <div className="form-help" id="corpFacState">
                                    (Enter 2 to 30 characters)
                                </div>
                            </div>
                        </div>
                        <div className="form-group row required">
                            <label
                                role="group"
                                htmlFor="countryName"
                                className="mb-0 col-md-5 label-form"
                            >
                                Location
                                <sup>*</sup>
                            </label>
                            <div className="col-md-7">
                                <input
                                    title={CONSTANTS.ENTER_ALPHANUMERIC_TEXT}
                                    pattern={
                                        CONSTANTS.REGEX_ALLOW_ALPHABHET_SPACE
                                    }
                                    name="countryName"
                                    defaultValue={certificateInfo.countryName}
                                    type="text"
                                    onChange={handleChange}
                                    onKeyDown={handleKeyDown}
                                    className="form-control"
                                    id="countryName"
                                    required
                                    maxLength={30}
                                    aria-describedby="corpFacCountry"
                                />
                                <div className="form-help" id="corpFacCountry">
                                    (Enter 2 to 30 characters)
                                </div>
                            </div>
                        </div>
                        <div className="form-group row required">
                            <div className="col-md-5 form-label">
                                <label
                                    role="group"
                                    htmlFor="grantedOn"
                                    className="mb-0 label-form"
                                >
                                    <span className="d-block mb-n2">
                                        Granted On
                                        <sup>*</sup>
                                    </span>
                                </label>
                            </div>
                            <div className="col-md-7">
                                <Datepicker
                                    name="grantedOn"
                                    id="grantedOn"
                                    ref={grantedDateRef}
                                    onKeyDown={handleKeyDown}
                                    defaultValue={certificateInfo.grantedOn}
                                    onChangeDate={handleDateChange}
                                    setBtnInd={() => {}}
                                    errorMessage="Please enter a valid date in yyyy-mmm-dd format"
                                    minMaxDate={false}
                                    isCstCurrentDate={false}
                                />
                            </div>
                        </div>
                        <div className="form-group row required">
                            <label
                                role="group"
                                htmlFor="awardedYear"
                                className="mb-0 col-md-5 label-form"
                            >
                                Award Year
                                <sup>*</sup>
                            </label>
                            <div className="col-md-7">
                                <input
                                    type="text"
                                    className="form-control"
                                    name="awardedYear"
                                    id="awardedYear"
                                    required
                                    readOnly
                                    value={awardYear}
                                />
                            </div>
                        </div>
                        {showBrandingMaterials && (
                            <div
                                className="form-group row"
                                aria-labelledby="brandings"
                                role="group"
                            >
                                <label
                                    className="mb-0 col-md-5 label-form"
                                    id="brandings"
                                >
                                    Branding Materials
                                </label>
                                <div className="col-md-7">
                                    <ul className="d-md-flex flex-wrap cert-checkbox row mt-3 mt-md-0">
                                        {brandingMaterials.map(
                                            (material: any, index: any) => {
                                                return (
                                                    <li
                                                        key={`brandingMaterial-${index}`}
                                                        className="col-sm-6"
                                                    >
                                                        <div
                                                            key={`check${index}`}
                                                            className="form-group form-check-bordered mb-4"
                                                        >
                                                            <input
                                                                key={Math.random()}
                                                                name={
                                                                    material.assetName
                                                                }
                                                                onChange={
                                                                    handleMaterialChange
                                                                }
                                                                onKeyDown={
                                                                    handleKeyDown
                                                                }
                                                                type="checkbox"
                                                                id={`check${index}`}
                                                                defaultChecked={
                                                                    material.isAllowed ==
                                                                    true
                                                                }
                                                            />
                                                            <label
                                                                htmlFor={`check${index}`}
                                                            >
                                                                {material.assetName ===
                                                                "Award Certificate"
                                                                    ? "Award"
                                                                    : material.assetName}
                                                            </label>
                                                        </div>
                                                    </li>
                                                );
                                            }
                                        )}
                                    </ul>
                                </div>
                            </div>
                        )}
                        <div className="row my-5">
                            <div className="col-12">
                                <div className="cert-btns py-4">
                                    <div className="col-xl-8 offset-xl-4 d-flex justify-content-xl-start justify-content-center flex-column flex-sm-row">
                                        {(selectedAward !== "No Award" ||
                                            selectedAward !== "N/A") && (
                                            <button
                                                ref={previewCertificateRef}
                                                className="invisible invisible-btn"
                                                data-toggle="modal"
                                                data-target="#previewCertificate"
                                                aria-hidden="true"
                                                tabIndex={-1}
                                            >
                                                invisible
                                            </button>
                                        )}
                                        <div className="cst-tooltip d-flex flex-column flex-sm-row">
                                            <button
                                                name="preview"
                                                className="btn btn-secondary btn-round tooltip-item "
                                                {...(selectedAward ===
                                                    "No Award" ||
                                                selectedAward === "N/A"
                                                    ? {
                                                          "aria-describedby":
                                                              "previewBtn",
                                                      }
                                                    : {})}
                                            >
                                                Preview
                                            </button>
                                            {(selectedAward === "No Award" ||
                                                selectedAward === "N/A") && (
                                                <div
                                                    className="tooltip tooltip-bottom preview-cert-tooltip"
                                                    role="tooltip"
                                                    id="previewBtn"
                                                >
                                                    <div className="arrow" />
                                                    <div className="tooltip-inner">
                                                        Award Preview is not
                                                        available for currently
                                                        selected award
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <button
                                            name="submit"
                                            className="btn btn-primary btn-round ml-sm-4 mt-4 mt-sm-0"
                                        >
                                            {generateCertificateButtonLabel}
                                        </button>
                                        <div
                                            className="modal fade show aui-modal certificate-modal"
                                            id="previewCertificate"
                                            tabIndex={-1}
                                            aria-labelledby="previewLabel"
                                            aria-modal="true"
                                            role="dialog"
                                        >
                                            <div className="modal-dialog modal-dialog-centered modal-lg aha-card-modal">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h2
                                                            className="h3 mb-0"
                                                            id="previewLabel"
                                                        >
                                                            Preview
                                                        </h2>
                                                        <button
                                                            type="button"
                                                            className="btn btn-text"
                                                            data-dismiss="modal"
                                                            aria-label="Close Preview Certificate Modal"
                                                        >
                                                            <span
                                                                aria-hidden="true"
                                                                className="aha-icon-cross"
                                                            />
                                                        </button>
                                                    </div>
                                                    <div
                                                        id="imagePreviewContainer"
                                                        className="modal-body"
                                                    >
                                                        <span className="hiddenFontElementLubdubLight" />
                                                        <span className="hiddenFontElementLubdubMedium" />
                                                        <span className="hiddenFontElementLubdubBold" />
                                                    </div>
                                                    <div id="hiddendiv" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {exportProgramMeasureActivateInd && (
                            <FocusTrap
                                focusTrapOptions={{
                                    escapeDeactivates: false,
                                    clickOutsideDeactivates: false,
                                }}
                            >
                                <div
                                    className="modal fade show aui-modal"
                                    id="exportProgramMeasuresModal"
                                    tabIndex={-1}
                                    aria-labelledby="exportProgramMeasuresLabel"
                                    aria-modal="true"
                                    role="dialog"
                                    style={{
                                        display: exportProgramMeasureActivateInd
                                            ? "block"
                                            : "none",
                                    }}
                                >
                                    <div className="modal-dialog modal-dialog-centered modal-md">
                                        <div className="modal-content award-modal">
                                            <div className="modal-header">
                                                <h2
                                                    className="h4"
                                                    id="exportProgramMeasuresLabel"
                                                >
                                                    Confirm Award
                                                </h2>
                                                <button
                                                    id="closeExportModal"
                                                    type="button"
                                                    className="close"
                                                    aria-label="Close renewing organization modal"
                                                    onClick={
                                                        dismissAwardGenModal
                                                    }
                                                >
                                                    <span
                                                        aria-hidden="true"
                                                        className="aha-icon-cross"
                                                    />
                                                </button>
                                            </div>
                                            <div className="modal-body p-0">
                                                <div className="d-flex">
                                                    <div className="pb-3">
                                                        <p>
                                                            Are you sure you
                                                            want to generate the
                                                            following award for
                                                            this
                                                            <b className="mx-1">
                                                                {
                                                                    props
                                                                        .hospital
                                                                        .facilityName
                                                                }
                                                            </b>
                                                            ?
                                                        </p>
                                                        <div className="d-flex ">
                                                            <div className="mr-5">
                                                                {" "}
                                                                Select award :{" "}
                                                                <b>
                                                                    {targetState ===
                                                                    "Yes"
                                                                        ? `${selectedAward} Target`
                                                                        : selectedAward}
                                                                </b>
                                                            </div>
                                                            <div>
                                                                Target award :{" "}
                                                                <b>
                                                                    {
                                                                        targetState
                                                                    }
                                                                </b>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-end mt-4 flex-column flex-sm-row ">
                                                    <button
                                                        className="btn btn-round btn-secondary fd-btn-1"
                                                        aria-label="Cancel renewing organization"
                                                        onClick={
                                                            dismissAwardGenModal
                                                        }
                                                    >
                                                        Cancel
                                                    </button>
                                                    <button
                                                        className="btn btn-primary btn-round  mt-4  mt-sm-0 ml-3"
                                                        onClick={
                                                            confirmAwardGenerate
                                                        }
                                                    >
                                                        Confirm
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </FocusTrap>
                        )}
                    </div>
                </div>
            </form>
            <div id="certificateCanvasParent">
                <span className="hiddenFontElementLubdubLight" />
                <span className="hiddenFontElementLubdubMedium" />
                <span className="hiddenFontElementLubdubBold" />
            </div>
        </CertificationTabWrapper>
    );
};

export default OrganizationCertificationTab;
