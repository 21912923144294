import React, { ReactNode } from "react";
import PropTypes from "prop-types";
import AdminHeader from "components/AdminHeader";
import Footer from "components/Footer";
import { Wrapper } from "styled";
import Loader from "components/Loader";
import ScrollToTop from "components/scrollToTop";
import { Toast } from "components/Toast";
import AlertPopup from "components/AlertPopup";

interface Props {
    children: ReactNode;
}

export default function AdminLayout({ children }: Props) {
    return (
        <Wrapper className="d-flex flex-column">
            <AdminHeader />
            <ScrollToTop />
            <main
                className="d-flex flex-column flex-grow-1"
                id="maincontent"
                tabIndex={-1}
            >
                <Toast />
                {children}
            </main>
            <Loader />
            <Footer />
            <AlertPopup message="" />
        </Wrapper>
    );
}
AdminLayout.propTypes = {
    children: PropTypes.element.isRequired,
};
