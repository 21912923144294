import React from "react";

export interface IDynamicModal {
    modalText: any;
    dismissText: string;
    actionText: string;
    actionFunction: any;
}

const DynamicModal = (props: IDynamicModal) => {
    return (
        <div
            className="modal fade show aui-modal"
            id="confirmationModal"
            tabIndex={-1}
            aria-labelledby="modalConfirmationLabel"
            aria-modal="true"
            role="dialog"
        >
            <div className="modal-dialog modal-dialog-centered modal-sm">
                <div className="modal-content">
                    <div className="modal-body p-0">
                        <div className="text-center">
                            <div
                                id="modalConfirmationLabel"
                                className="sr-only"
                            >
                                Offline payment confirmation
                            </div>
                            <div>{props.modalText}</div>
                            <div className="mt-4 d-flex justify-content-center">
                                <button
                                    type="button"
                                    className="btn btn-secondary btn-round btn-sm mx-2 mt-3 mt-sm-2"
                                    data-dismiss="modal"
                                    aria-label="Cancel offline payment"
                                >
                                    {props.dismissText}
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary btn-round btn-sm mx-2 mt-3 mt-sm-2"
                                    data-dismiss="modal"
                                    onClick={() => props.actionFunction()}
                                    aria-label={`${props.actionText} offline payment`}
                                >
                                    {props.actionText}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DynamicModal;
