import store from "app/store";
import { validJSON } from "common/utils";
import { showToast } from "components/Toast/toast.slice";
import APIUtils from "utils/API.utils";
import config from "../config";

const API = new APIUtils();

declare global {
    interface Window {
        userAccessToken: any;
    }
}

export const updateHospitalProgramDetails = async (
    hospitalObj: any,
    programId: any,
    hospitalId: any
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const reqBody = hospitalObj;

            const APIRes = await API.PATCH(
                `${
                    config[config.env].apiEndpoints.accounts
                }/hospitals/${hospitalId}/programs/${programId}`,
                reqBody
            );

            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const saveHospitalProgramDetails = async (
    hospitalObj: any,
    hospitalId: any,
    programId: any
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const reqBody = hospitalObj;

            const APIRes = await API.POST(
                `${
                    config[config.env].apiEndpoints.accounts
                }/hospitals/${hospitalId}/programs/${programId}`,
                reqBody
            );

            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const getHospitalProgramDetails = async (
    programId: any,
    hospitalId: any
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const APIRes = await API.GET(
                `${
                    config[config.env].apiEndpoints.accounts
                }/hospitals/${hospitalId}/programs/${programId}`
            );
            if (APIRes.success === true) {
                resolve(APIRes);
            } else if (
                APIRes?.error?.details &&
                APIRes.error.details[0]?.message ===
                    "hospitalId and programId combination not exist"
            ) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const getProgramCategories = async (programId: any): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const APIRes = await API.GET(
                `${
                    config[config.env].apiEndpoints.catalogs
                }/categories/${programId}`
            );

            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const getProgramCategoriesByCountryId = async (
    programId: any,
    countryId: any
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const APIRes = await API.GET(
                `${
                    config[config.env].apiEndpoints.catalogs
                }/categories/${programId}/countries/${countryId}`
            );

            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const getAllProgramCategories = async (): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const APIRes = await API.GET(
                `${config[config.env].apiEndpoints.catalogs}/categories`
            );
            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const getProgramResgistries = async (categoryId: any): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const APIRes = await API.GET(
                `${
                    config[config.env].apiEndpoints.accounts
                }/registryPrograms?categoryId=${categoryId}`
            );
            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const saveProgramRegistry = async (registryObj: any): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const reqBody = registryObj;

            const APIRes = await API.PATCH(
                `${
                    config[config.env].apiEndpoints.accounts
                }/hospitalRegistryPrograms`,
                reqBody
            );

            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const deleteOrGetProgramRegistry = async (
    hospitalId: number,
    programId: number,
    categoryId: number,
    operation: any
): Promise<any> => {
    if (!localStorage.getItem("userAccessToken")) {
        return [];
    }
    const myHeaders: any = new Headers();
    myHeaders.append(
        "authorization",
        localStorage.getItem("userAccessToken") || ""
    );
    const requestOptions = {
        method: operation,
        headers: myHeaders,
    };

    return new Promise((resolve, reject) => {
        fetch(
            `${
                config[config.env].apiEndpoints.accounts
            }/hospitalRegistryPrograms/hospitals/${hospitalId}/programs/${programId}/categories/${categoryId}`,
            requestOptions
        )
            .then((response) => response.text())
            .then((result) => {
                if (validJSON(result)) {
                    const registryResponse = JSON.parse(result);
                    if (
                        registryResponse.statusCode == 200 ||
                        registryResponse.statusCode == 404
                    ) {
                        resolve(registryResponse);
                    } else {
                        throw registryResponse.error;
                    }
                } else {
                    throw new Error("Invalid json string in the response");
                }
            })
            .catch((error) => {
                const err: any = error;
                store.dispatch(showToast(err));
                reject(error);
            });
    });
};

export const getProgramFieldsById = async (
    categoryId: any,
    programId: any
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const APIRes = await API.GET(
                `${
                    config[config.env].apiEndpoints.catalogs
                }/categories/getParsedProgramDetails/${categoryId}/programs/${programId}`
            );
            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const getPgmMeasuresExportExcel = async (
    { programId, categoryId }: any,
    exportPayload: any
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const reqBody = exportPayload;
            const endPoint = `${
                config[config.env].apiEndpoints.accounts
            }/admin/adminFacilityExport?programId=${programId}&categoryId=${categoryId}`;
            const APIRes = await API.POST(endPoint, reqBody);

            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};
