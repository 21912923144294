import styled from "styled-components";

export const AlertPopupWrapper = styled.div`
    .backdrop {
        width: 100%;
        height: 100%;
        position: fixed;
        background: rgba(0, 0, 0, 0.47);
        top: 0;
        bottom: 0;
    }
`;
export const AlertPopupContainer = styled.div``;
