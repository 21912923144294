import styled from "styled-components";

export const GroupedMultiSelectDropdownWrapper = styled.div`
    .cst-select {
        width: 100%;
        word-break: break-all;
        word-break: break-word;
        position: relative;
        .cst-grouped-select-required {
            display: none;
        }
    }
    .cst-grouped-select-fld {
        height: 34px;
        border-radius: 2px;
        width: 100%;
        cursor: pointer;
        background: #ffffff;
        position: relative;
        @media only screen and (min-width: 575.99px) {
            height: 40px;
        }
        &:focus {
            outline: 2px solid #f7983a;
            box-shadow: 0 0 4px 4px #f7983a;
        }
        &.cst-multiselect-fld {
            min-height: unset;
            height: 34px;
            padding-right: 0;
            @media only screen and (min-width: 575.99px) {
                height: 40px;
            }
            .cst-grouped-selected-item {
                width: 100%;
                height: 34px;
                background: transparent;
                border: none;
                text-align: left;
                border: 1px solid #777979;
                padding: 6px 27px 6px 12px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: block;
                color: #222328;
                @media only screen and (min-width: 575.99px) {
                    height: 40px;
                }
            }
            .aui-tag-list {
                margin-top: 2px;
            }
        }
    }
    .cst-selected-item {
        width: 100%;
        height: 100%;
        border: none;
        overflow: hidden;
        word-break: break-all;
        position: absolute;
        top: 0;
        left: 0;
        background: transparent;
        padding: 6px 27px 6px 12px;
        cursor: pointer;
        z-index: 3;
        color: #222328;
    }
    .cst-arrow {
        width: 25px;
        height: 35px;
        display: inline-block;
        background: transparent;
        position: absolute;
        top: 1px;
        right: 1px;
        bottom: 1px;
        font-size: 8px;
        padding-right: 6px;
        z-index: 2;
        &:before {
            line-height: 36px;
            margin: 0 auto;
            display: block;
            font-size: 8px;
        }
        &.cst-arrow-up {
            transform: rotate(180deg);
            transition: all 0.3s ease;
        }
        &.cst-arrow-down {
            transform: rotate(0deg);
            transition: all 0.3s ease;
        }
    }
    .cst-select-dropdown {
        width: 100%;
        max-height: 292px;
        border: 1px solid #bcc3ca;
        overflow: auto;
        position: absolute;
        margin-top: 5px;
        left: 0;
        background-color: #fff;
        z-index: 99999;
        list-style-type: none;
        padding: 4px 0 1px;
        box-shadow: 0 0 1.125rem #00000029;
        border-radius: 2px;
        .cst-grouped-section {
            border-bottom: 1px solid #e3e3e3;
            padding: 12px 0;
            .cst-grouped-item,
            .cst-grouped-label {
                padding-left: 20px;
                padding-right: 20px;
            }
            .cst-grouped-list {
                list-style-type: none;
                padding: 0;
            }
        }
        .cst-grouped-item,
        .cst-grouped-label {
            padding-left: 12px;
            padding-right: 12px;
        }
        .cst-grouped-section:last-child {
            border-bottom: none;
        }
        .cst-grouped-label {
            font-size: 13px;
        }
        .cst-grouped-item {
            border-bottom: 1px solid #e3e3e3;
            cursor: pointer;
        }
        .cst-grouped-item:last-child {
            border-bottom: 0;
        }
        .cst-grouped-item > div {
            margin: 10px 0;
        }
        .cst-grouped-item div:focus {
            outline: 2px solid #f7983a;
            box-shadow: 0 0 4px 4px #f7983a;
        }
        .cst-item-selected {
            color: #c10e21;
        }
        .form-check-bordered {
            z-index: -1;
            label {
                margin-bottom: 0;
                font-size: 14px;
            }
            &.form-check-all {
                z-index: 0;
            }
        }
        .cst-select-grouped-disabled {
            cursor: not-allowed;
            pointer-events: none;
            > *,
            * {
                cursor: not-allowed;
                pointer-events: none;
            }
            label {
                color: #766f6f;
                &:before,
                &:after {
                    opacity: 0.5;
                }
            }
            .cst-grouped-select-fld {
                pointer-events: none;
                background-color: #f8f8f8;
            }
        }
    }
    .cst-select-grouped-disabled {
        cursor: not-allowed;
        .cst-grouped-select-fld {
            pointer-events: none;
            background-color: #f8f8f8;
        }
    }
    .cst-select-grouped-loading {
        position: relative;
        cursor: not-allowed;
        &:after {
            border: 3px solid #222328;
            border-radius: 50%;
            border-top: 3px solid transparent;
            width: 22px;
            height: 22px;
            -webkit-animation: aui-btn-spin 2s linear infinite;
            animation: aui-btn-spin 2s linear infinite;
            content: "";
            display: inline-block;
            position: absolute;
            margin-left: 10px;
            top: 8px;
            right: 9px;
            z-index: 4;
        }
        .cst-selected-item {
            padding-right: 36px;
            background-color: #f8f8f8;
        }
        .cst-grouped-select-fld {
            pointer-events: none;
        }
        .cst-multiselect-fld {
            padding-right: 0;
            background-color: #ffffff;
        }
        .cst-arrow {
            display: none;
        }
    }
    .required {
        .cst-grouped-select-required {
            display: inline-block;
        }
    }
`;
