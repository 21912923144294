import {
    calculatNumOfDaysBetweenDates,
    expireDataRowStatus,
    getCurrentQuarter,
    hospitalApprovalStatus,
    localDateToYYYYMMDDFormatString,
    wordSplit,
} from "common/utils";
import SortTableColumnButton from "components/SortTableColumnButton";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import CONSTANTS from "../../../common/constants";
import {
    setQuarter,
    setYear,
} from "../../../components/MeasuresDateFilter/measuresDateFilter.slice";
import { CorporationFacilitiesTable } from "./styled";
import "styles/expire-tooltip.scss";
import ExpireIndicator from "components/ExpireIndicator";

interface Props {
    source: string;
    facilities: any;
    isTableLoading: boolean;
    tab: string;
    setSortInd: any;
    sortInd: any;
    pageNumber: number;
}

export const FacilitiesRegisteredTable = ({
    source,
    facilities,
    isTableLoading,
    tab,
    setSortInd,
    sortInd,
    pageNumber,
}: Props) => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (CONSTANTS.WINDOW.AUI.Tooltip) {
            CONSTANTS.WINDOW.AUI.Tooltip.init();
        }
    });

    const checkIsExpierSoon = (
        expiresOn: any,
        programId: any,
        hospitalId: any
    ) => {
        if (!expiresOn) {
            return "";
        }
        return (
            <div>
                <ExpireIndicator
                    expiresOn={expiresOn}
                    programId={programId}
                    hospitalId={hospitalId}
                />
            </div>
        );
    };

    return (
        <CorporationFacilitiesTable>
            <div
                className={`corp-dashboard-factable mt-3 ${
                    source === "registeredFacility"
                        ? "corp-fac-registered-table"
                        : "corp-fac-certified-table"
                }`}
            >
                <table
                    className={`aui-responsive-status-table ${
                        isTableLoading ? CONSTANTS.TABLE_SPINNER : ""
                    }`}
                >
                    <thead>
                        <tr>
                            <th scope="col">
                                <div className="aui-th justify-content-between">
                                    Organization Details
                                </div>
                            </th>
                            <th scope="col">
                                <div className="aui-th">Location Details</div>
                            </th>
                            {source === "registeredFacility" ? (
                                <>
                                    <th scope="col">
                                        <div className="aui-th">
                                            Program Type
                                        </div>
                                    </th>
                                </>
                            ) : (
                                <>
                                    <th scope="col">
                                        <div className="aui-th">Granted On</div>
                                    </th>
                                    <th scope="col">
                                        <div className="aui-th">Expires On</div>
                                    </th>
                                </>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {facilities?.length > 0 &&
                            facilities?.map((facility: any) => {
                                return (
                                    <tr
                                        key={facility.hospitalId}
                                        className={
                                            tab === CONSTANTS.CERTIFIED
                                                ? expireDataRowStatus(
                                                      facility.expiresOn,
                                                      tab
                                                  )
                                                : hospitalApprovalStatus(tab)
                                        }
                                    >
                                        <td data-title="Organization Details">
                                            <div className="aui-td">
                                                <div>
                                                    <Link
                                                        to={{
                                                            pathname: `/corporation/organization-details/${facility.hospitalId}/program/${facility.programId}/category/${facility.programCategoryId}`,
                                                            state: {
                                                                tab,
                                                                pageNumber,
                                                                sortingInd:
                                                                    sortInd,
                                                            },
                                                        }}
                                                        className="btn-text-link-uline"
                                                        onClick={() => {
                                                            dispatch(
                                                                setYear(
                                                                    new Date().getFullYear()
                                                                )
                                                            );
                                                            dispatch(
                                                                setQuarter(
                                                                    getCurrentQuarter()
                                                                )
                                                            );
                                                            localStorage.setItem(
                                                                "selectedHospitalExpiryDate",
                                                                facility.expiresOn
                                                            );
                                                            localStorage.setItem(
                                                                "selectedCountryName",
                                                                facility.countryName
                                                            );
                                                        }}
                                                        id={`facility-${facility.hospitalId}`}
                                                    >
                                                        {facility.facilityName}
                                                    </Link>
                                                    <div className="mt-1">
                                                        Organization Code :{" "}
                                                        <span className="abbr-code">
                                                            {wordSplit(
                                                                facility.facilityCode
                                                            )}
                                                        </span>
                                                        {tab ===
                                                            CONSTANTS.CERTIFIED &&
                                                            checkIsExpierSoon(
                                                                facility.expiresOn,
                                                                facility.programId,
                                                                facility.hospitalId
                                                            )}
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td data-title="Location Details">
                                            <div className="aui-td d-flex flex-column">
                                                <div className="corp-d-location">
                                                    <div>Location:</div>
                                                    <div>
                                                        {facility.countryName}
                                                    </div>
                                                </div>
                                                <div className="corp-d-location">
                                                    <div>State:</div>
                                                    <div>{facility.state}</div>
                                                </div>
                                            </div>
                                        </td>
                                        {source === "registeredFacility" ? (
                                            <>
                                                <td data-title="Program Type">
                                                    <div className="aui-td">
                                                        {
                                                            facility.programCategoryName
                                                        }
                                                    </div>
                                                </td>
                                            </>
                                        ) : (
                                            <>
                                                <td data-title="Granted On">
                                                    <div className="aui-td">
                                                        {localDateToYYYYMMDDFormatString(
                                                            facility.grantedOn
                                                        )}
                                                    </div>
                                                </td>
                                                <td data-title="Expires On">
                                                    <div className="aui-td">
                                                        {localDateToYYYYMMDDFormatString(
                                                            facility.expiresOn
                                                        )}
                                                    </div>
                                                </td>
                                            </>
                                        )}
                                    </tr>
                                );
                            })}
                    </tbody>
                </table>
                <div
                    role="alert"
                    aria-atomic="true"
                    className="table-emptystate-outer"
                >
                    {!facilities?.length && (
                        <div>
                            No organizations match your search.
                            <br />
                            Please try modifying your search criteria
                        </div>
                    )}
                </div>
            </div>
        </CorporationFacilitiesTable>
    );
};

export default FacilitiesRegisteredTable;
