import React from "react";
import { PaymentResultWrapper } from "./styled";

interface Props {
    gotToDashBoard: any;
}

export const PaymentSuccessPage = (props: Props) => {
    return (
        <PaymentResultWrapper>
            <div className="container d-flex flex-column align-items-center py-5 mb-4 text-center">
                <img
                    src="/images/success.svg"
                    alt="Success"
                    className="mb-4 pb-2 pt-5"
                />
                <p className="h5 font-600 pb-2 pb-md-4 mb-2">
                    Thank you for completing your registration.
                </p>
                <p className="pt-3 mb-3">
                    We will review your application and reply to your request as
                    soon as possible.
                </p>
                <p className="mb-3 payment-success-detail">
                    You will receive an email when your request to enter the
                    Quality and Certification Tool (QCT) has been approved.
                </p>
                <button
                    onClick={props.gotToDashBoard}
                    className="btn btn-round btn-primary mt-3 mb-5"
                    role="link"
                >
                    Go to User Dashboard
                </button>
            </div>
        </PaymentResultWrapper>
    );
};

export default PaymentSuccessPage;
