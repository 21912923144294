import React, { useEffect, useState } from "react";
import HospitalBanner from "pages/Admin/HospitalBanner/HospitalBanner";
import { useDispatch } from "react-redux";
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import { getRegisteredProgram } from "api/registeredProgramAPI";
import { getPrograms } from "api/programs";
import { getHospitalById } from "api/hospitalApi";
import { logger } from "utils/logger.utils";
import Navigator from "components/Navigator";
import { BreadCrumb } from "components/BreadCrumb";
import ProgramsCard from "./ProgramsCard";
import SettingSidebar from "../SettingSidebar/SettingSidebar";
import { HospitalProgramWrapper } from "./styled";
import "../styles/style.scss";
import { useLocation } from "react-router-dom";
import CONSTANTS from "common/constants";
import { getHospitalProgramDetails } from "api/hospitalProgramsAPI";
import ProgramsDetails from "pages/Admin/FacilityDetails/ProgramDetails";

const HospitalProfile = () => {
    const dispatch = useDispatch();
    const [programName, setProgramName] = useState<any>("");
    const [hospitalData, setHospitalData] = useState<any>("");
    const [registeredPrograms, setRegisteredPrograms] = useState<any>();
    const [allPrograms, setAllPrograms] = useState<any>();
    const [ahaEmsId, setAhaEmsId] = useState<any>();
    const [breadcrumbItems, setBreadcrumbItems] = useState<any>([]);
    const hospitalId: any = localStorage.getItem("selectedHospitalId");
    const programId: any = localStorage.getItem("selectedProgramId");
    const [categoryId, setCategoryId] = useState<any>();
    const location = useLocation();
    const [emsprogramData, setEmsProgramData] = useState<any>();

    useEffect(() => {
        dispatch(setPageLoadingStatus({ isPageLoading: true }));
        getHospitalById(Number(hospitalId))
            .then((hospitalInfo: any) => {
                if (
                    hospitalInfo.success == true &&
                    hospitalInfo.data &&
                    hospitalInfo.data.hospital
                ) {
                    setHospitalData(hospitalInfo.data.hospital);
                    getHospitalProgramDetails(programId, hospitalId).then(
                        (result) => {
                            if (result.success == true && result.data) {
                                setAhaEmsId(result.data.emsId);
                                setEmsProgramData(result.data);
                            }
                        }
                    );
                    Promise.all([
                        getPrograms(hospitalInfo.data.hospital.countryCode),
                        getRegisteredProgram(hospitalId),
                    ])
                        .then((response: any) => {
                            if (
                                response[0].success == true &&
                                response[1].success == true
                            ) {
                                dispatch(
                                    setPageLoadingStatus({
                                        isPageLoading: false,
                                    })
                                );
                                let program = "";
                                let categoryID = "";
                                const combinedPrograms = [
                                    ...response[0].data.certificationPrograms,
                                    ...response[0].data.qualityPrograms,
                                ];
                                for (
                                    let index = 0;
                                    index < response[1].data.hospitals.length;
                                    index++
                                ) {
                                    const programInfo = combinedPrograms.filter(
                                        (item: any) => {
                                            return (
                                                item.programId ==
                                                response[1].data.hospitals[
                                                    index
                                                ].programId
                                            );
                                        }
                                    );
                                    response[1].data.hospitals[
                                        index
                                    ].programInfo = programInfo;
                                    program +=
                                        response[1].data.hospitals[index]
                                            .programName;
                                    categoryID +=
                                        response[1].data.hospitals[index]
                                            .categoryId;
                                }

                                setRegisteredPrograms(
                                    response[1].data.hospitals
                                );
                                setAllPrograms(combinedPrograms);
                                setProgramName(program);
                                setCategoryId(Number(categoryID));
                            }
                        })
                        .catch((error) => {
                            logger(error);
                            dispatch(
                                setPageLoadingStatus({ isPageLoading: false })
                            );
                        });
                }
            })
            .catch((error) => {
                logger(error);
                dispatch(setPageLoadingStatus({ isPageLoading: false }));
            });
    }, []);

    useEffect(() => {
        const elementsArray = [];
        if (programId !== CONSTANTS.EMS_PROGRAM_ID) {
            elementsArray.push(
                {
                    label: "Home",
                    returnPath: {
                        pathname: CONSTANTS.ROUTES.USER_DASHBOARD,
                        state: {
                            pageNum: location?.state?.pageNum,
                            search: location?.state?.search,
                        },
                    },
                },
                {
                    label: hospitalData.facilityName,
                    returnPath: {
                        pathname: "/dashboard",
                        state: {
                            pageNum: location?.state?.pageNum,
                            search: location?.state?.search,
                        },
                    },
                },
                {
                    label: "Organization Settings",
                }
            );
        } else {
            elementsArray.push(
                {
                    label: "Home",
                    returnPath: {
                        pathname: CONSTANTS.ROUTES.USER_DASHBOARD,
                        state: {
                            pageNum: location?.state?.pageNum,
                            search: location?.state?.search,
                        },
                    },
                },
                {
                    label: hospitalData.facilityName,
                    returnPath: {
                        pathname: `/measures/organization/${hospitalId}/programs/${programId}/categories/${categoryId}`,
                        state: {
                            pageNum: location?.state?.pageNum,
                            search: location?.state?.search,
                        },
                    },
                },
                {
                    label: "Organization Settings",
                }
            );
        }
        setBreadcrumbItems(elementsArray);
    }, [hospitalData.facilityName, categoryId]);
    return (
        <HospitalProgramWrapper className="setting-page">
            <Navigator tabName="Settings" />
            <div className="container">
                <BreadCrumb breadcrumbItems={breadcrumbItems} />
                <h1 className="h3 mb-4">Organization Settings</h1>
                <HospitalBanner
                    hospitalName={hospitalData.facilityName}
                    programName={programName}
                    countryName={hospitalData.countryName}
                    ahaEmsId={ahaEmsId}
                    programId={programId}
                />
                <div className="d-lg-flex">
                    <SettingSidebar
                        activeTabItem="programDetails"
                        hospitalId={hospitalId}
                        programId={programId}
                        categoryId={categoryId}
                    />
                    {programId == 22 ? (
                        <div className="setting-container">
                            <ProgramsDetails
                                hospital={hospitalData}
                                programData={emsprogramData}
                                setProgramData={setEmsProgramData}
                                isEMSEUP
                            />
                        </div>
                    ) : (
                        <div className="setting-container">
                            <h3 className="h3 prg-title pb-3 mb-4">Programs</h3>
                            <div
                                className="d-flex flex-wrap aui-accordion-tab2"
                                id="tabsTemplate1"
                                role="tablist"
                            >
                                <button
                                    className="aui-acc-tab-item aui-accordion-tab1 btn btn-text"
                                    data-toggle="collapse"
                                    data-target="#tabsTemplate11"
                                    aria-expanded="true"
                                    role="tab"
                                >
                                    Programs Registered
                                    <i
                                        className="aha-icon-arrow-down mx-2 d-inline-block d-md-none"
                                        aria-hidden="true"
                                    />
                                </button>
                                <button
                                    className="aui-acc-tab-item aui-accordion-tab2 btn btn-text"
                                    data-toggle="collapse"
                                    data-target="#tabsTemplate12"
                                    aria-expanded="false"
                                    role="tab"
                                >
                                    All Programs
                                    <i
                                        className="aha-icon-arrow-down mx-2 d-inline-block d-md-none"
                                        aria-hidden="true"
                                    />
                                </button>
                                <div
                                    id="tabsTemplate11"
                                    className="collapse show row w-100 no-gutters aui-accordion-content aui-accordion-content1 prg-registered"
                                    data-parent="#tabsTemplate1"
                                    role="tabpanel"
                                >
                                    <ProgramsCard
                                        programs={registeredPrograms}
                                        viewDetails
                                    />
                                </div>
                                <div
                                    id="tabsTemplate12"
                                    className="collapse row w-100 no-gutters aui-accordion-content aui-accordion-content2 prg-registered"
                                    data-parent="#tabsTemplate1"
                                    role="tabpanel"
                                >
                                    <ProgramsCard
                                        programs={allPrograms}
                                        viewDetails={false}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </HospitalProgramWrapper>
    );
};

export default HospitalProfile;
