import React from "react";
import CONSTANTS from "common/constants";
import {
    calculatNumOfDaysBetweenDates,
    localDateToYYYYMMDDFormatString,
} from "common/utils";
import { ExpireIndicatorWrapper } from "./styled";

const ExpirsOn = (props: any) => {
    const toolTipMsg = `${
        props.programId === CONSTANTS.HEART_FAILURE_PROGRAM_ID
            ? CONSTANTS.AWARD_EXPIRES_ON
            : CONSTANTS.CERTIFICATE_EXPIRES_ON
    } ${localDateToYYYYMMDDFormatString(props.expiresOn)}`;

    return (
        <ExpireIndicatorWrapper>
            <div
                className="mt-1 expire-yellow expire-info"
                id={`expire-${props.hospitalId}`}
            >
                {props.days == 1
                    ? `Expires today`
                    : `Expires in ${props.days - 1} day(s)${" "}`}
                <button
                    type="button"
                    className="p-0 ml-1 expire-tooltip"
                    data-toggle="tooltip"
                    title={toolTipMsg}
                    data-container={`#expire-${props.hospitalId}`}
                    aria-label="expire info"
                >
                    <i className="aha-icon-warning-round" aria-hidden="true" />
                </button>
            </div>
        </ExpireIndicatorWrapper>
    );
};
const Expired = (props: any) => {
    const toolTipMsg = `${
        props.programId === CONSTANTS.HEART_FAILURE_PROGRAM_ID
            ? CONSTANTS.AWARD_EXPIRED_ON
            : CONSTANTS.CERTIFICATE_EXPIRED_ON
    } ${localDateToYYYYMMDDFormatString(props.expiresOn)}`;
    return (
        <ExpireIndicatorWrapper>
            <div
                className="mt-1 expire-red expire-info"
                id={`expire-${props.hospitalId}`}
            >
                Expired
                <button
                    type="button"
                    className="p-0 ml-1 expire-tooltip"
                    data-toggle="tooltip"
                    title={toolTipMsg}
                    data-container={`#expire-${props.hospitalId}`}
                    aria-label="expire info"
                >
                    <i className="aha-icon-warning-round" aria-hidden="true" />
                </button>
            </div>
        </ExpireIndicatorWrapper>
    );
};
const ExpireIndicator = (props: any) => {
    const days = calculatNumOfDaysBetweenDates(new Date(), props.expiresOn);
    return (
        <>
            {days >= 1 && days <= 50 && (
                <ExpirsOn
                    expiresOn={props.expiresOn}
                    programId={props.programId}
                    hospitalId={props.hospitalId}
                    days={days}
                />
            )}
            {days === 0 && (
                <Expired
                    expiresOn={props.expiresOn}
                    programId={props.programId}
                    hospitalId={props.hospitalId}
                    days={days}
                />
            )}
        </>
    );
};
export default ExpireIndicator;
