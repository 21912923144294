import styled from "styled-components";

export const UploadDocumentModalContentWrapper = styled.div`
    .doc-submit-btn {
        .btn {
            width: 100%;
            @media only screen and (min-width: 768px) {
                width: auto;
            }
        }
    }
`;
