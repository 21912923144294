import styled from "styled-components";

export const CertificationTabWrapper = styled.div`
    .cert-title {
        border-bottom: 3px solid #c10e21;
        margin-bottom: 60px;
    }
    .cert-btns {
        background: #f8f8f8;
        border: 1px solid #e3e3e3;
        text-align: center;
        .btn {
            width: auto;
        }
        @media only screen and (min-width: 1200px) {
            text-align: left;
        }
    }
    .cert-checkbox {
        list-style-type: none;
        padding: 0;
    }
    #imagePreviewContainer {
        font-family: "Lubdub-bold";
    }
    .hiddenFontElementLubdubLight {
        font-family: "Lubdub-light";
    }
    .hiddenFontElementLubdubMedium {
        font-family: "Lubdub-medium";
    }
    .hiddenFontElementLubdubBold {
        font-family: "Lubdub-bold";
    }
    #certificateCanvasParent {
        font-family: "Lubdub-bold";
    }
    .cert-header {
        border-bottom: 2px solid #c10e21;
        padding-bottom: 12px;
        margin-bottom: 20px;
    }
    .award-year {
        .cst-select {
            width: 160px;
        }
    }
    .cert-sub-header {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 16px;
    }
    .cert-autogenerated {
        border-bottom: 1px solid #e3e3e3;
        padding-bottom: 16px;
        margin-bottom: 16px;
    }
    .cert-checkbox {
        list-style-type: none;
        padding: 0;
    }
    .warning-help {
        color: rgb(193, 14, 33);
        font-size: 15px;
        background-color: transparent;
        border: none;
        margin: 0 6px;
        padding: 0;
    }
    .award-tooltip {
        .tooltip-inner {
            width: 120px;
            max-width: none;
            text-align: left;
            @media only screen and (min-width: 768px) {
                width: 230px;
            }
        }
    }
    .award-modal {
        .modal-header {
            border-bottom: 2px solid #c10e21;
            padding-bottom: 8px;
            margin-bottom: 10px;
        }
    }
    .preview-cert-tooltip {
        width: 238px;
        left: -65px;
        &:before,
        &:after {
            left: 55%;
        }
    }
`;
