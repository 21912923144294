import store from "app/store";
import APIUtils from "utils/API.utils";
import { showToast } from "components/Toast/toast.slice";
import config from "../config";

const API = new APIUtils();

export const addUser = async (
    hospitalId: string | number,
    emailId: any,
    setValidateDisabled: any,
    isEUP: any
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const reqBody = {
                email: emailId,
            };
            let apiEndPoint = `${
                config[config.env].apiEndpoints.accounts
            }/manageUsers/hospitals`;
            if (isEUP) {
                apiEndPoint = `${apiEndPoint}?hospitalId=${hospitalId}`;
            }
            const APIRes = await API.POST(apiEndPoint, reqBody);
            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                setValidateDisabled(false);
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const deleteUser = async (
    userId: any,
    hospitalId: string | number,
    isEUP: any
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            let apiEndPoint = `${
                config[config.env].apiEndpoints.accounts
            }/manageUsers/${userId}/hospitals`;
            if (isEUP) {
                apiEndPoint = `${apiEndPoint}?hospitalId=${hospitalId}`;
            }
            const APIRes = await API.DELETE(apiEndPoint);
            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};
