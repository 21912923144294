import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    getDashboardDetails,
    getNewlyCreatedHospitalPrograms,
    getNewlyCertifiedFacility,
} from "api/adminDashboardAPI";
import "../../styles/styles.scss";
import CONSTANTS from "common/constants";
import { Link } from "react-router-dom";
import SelectDomesticInternational from "components/SelectDomesticInternational";
import {
    calculatNumOfDaysBetweenDates,
    hospitalApprovalStatus,
    localDateToYYYYMMDDFormatString,
    wordSplit,
    expireDataRowStatus,
} from "common/utils";
import ExpireIndicator from "components/ExpireIndicator";
import { AdminWrapper } from "../styled";
import { logger } from "../../../utils/logger.utils";
import {
    HospitalCardWrapper,
    RegisteredHospitalsWrapper,
    CertifiedHospitalsWrapper,
} from "./styled";
import Sidebar from "../Sidebar/Sidebar";
import "styles/expire-tooltip.scss";
import { setRegion } from "../../../components/SelectDomesticInternational/region.slice.";

export const AdminDashboardPage = () => {
    // REDUX variables
    const dispatch = useDispatch();

    // RFC STATE variables
    let dashboardValues: any = {};
    let newlyRegisteredHospitals: any = [];
    let newlyCertifiedFacility: any = [];
    const [mount, setMount] = useState<boolean>(false);
    const [dashboard, setDashboard] = useState<any>({
        dashboardValues,
        newlyRegisteredHospitals,
        newlyCertifiedFacility,
    });
    const region = useSelector((state: any) => {
        return state.region;
    });

    const [isDashboardTableLoading, setIshDashboardTableLoading] =
        useState<boolean>(false);

    useEffect(() => {
        let mounted = true;
        localStorage.setItem("resetFilter", "true");
        setIshDashboardTableLoading(true);
        dashboardValues = {};
        newlyRegisteredHospitals = [];
        newlyCertifiedFacility = [];
        if (!mount) {
            dispatch(setRegion(CONSTANTS.ALL));
        }
        Promise.all([
            getDashboardDetails(region.region),
            getNewlyCreatedHospitalPrograms(region.region),
            getNewlyCertifiedFacility(region.region),
        ])
            .then((responses) => {
                if (mounted) {
                    if (
                        responses[0].success &&
                        responses[0].statusCode === 200
                    ) {
                        dashboardValues = responses[0].data;
                    }
                    if (
                        responses[1].success &&
                        responses[1].statusCode === 200
                    ) {
                        newlyRegisteredHospitals =
                            responses[1].data.hospitalPrograms;
                    }
                    if (
                        responses[2].success &&
                        responses[2].statusCode === 200
                    ) {
                        newlyCertifiedFacility =
                            responses[2].data.hospitalPrograms;
                    }
                    setDashboard({
                        dashboardValues,
                        newlyRegisteredHospitals,
                        newlyCertifiedFacility,
                    });
                }
                setIshDashboardTableLoading(false);
                setMount(true);
            })
            .catch((err) => {
                logger(err);
            });

        return function cleanup() {
            mounted = false;
        };
    }, [region.region, dispatch]);

    const renderCards = () => {
        return (
            <HospitalCardWrapper>
                <div className="d-flex justify-content-start justify-content-md-between flex-column flex-md-row dashboard-main-title mb-4">
                    <h1 className="h2 font-400 mb-0 mt-4 mt-md-0">Dashboard</h1>
                    <div className="d-flex flex-column flex-md-row mt-3 mt-md-0">
                        <label
                            className="pt-0 pt-md-2 pb-2 pb-md-0 pr-4 m-0"
                            htmlFor="locationType"
                            id="locationType-label"
                        >
                            Region
                        </label>
                        <div className="dashboard-select-dropdown">
                            <SelectDomesticInternational />
                        </div>
                    </div>
                </div>

                <div className="row mb-4 pb-2">
                    <div className="col-xl-4 d-flex">
                        <div className="d-flex justify-content-between p-3 p-xl-4 mb-4 total-hospital-card bg-registered flex-grow-1">
                            <i
                                className="aha-icon-hospital mr-2 ml-n2"
                                aria-hidden="true"
                            />
                            <div className="flex-grow-1 d-block d-md-flex d-xl-block align-items-center">
                                <div className="total-hospital-title">
                                    Total number of Organizations Registered
                                    <span className="total-hospital-count font-bold d-block">
                                        {
                                            dashboard.dashboardValues
                                                .registeredHospitalDetails
                                        }
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 d-flex">
                        <div className="d-flex justify-content-between p-3 p-xl-4 mb-4 total-hospital-card bg-certified flex-grow-1">
                            <i
                                className="aha-icon-certified mr-2 ml-n2"
                                aria-hidden="true"
                            />
                            <div className="flex-grow-1 d-block d-md-flex d-xl-block align-items-center">
                                <div className="total-hospital-title">
                                    Total number of Organizations Certified
                                    <span className="total-hospital-count font-bold d-block">
                                        {
                                            dashboard.dashboardValues
                                                .hospitalCertifiedCount
                                        }
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 d-flex">
                        <div className="d-flex justify-content-between p-3 p-xl-4 mb-4 total-hospital-card bg-awards flex-grow-1">
                            <i
                                className="aha-icon-awards mr-2 ml-n2"
                                aria-hidden="true"
                            />
                            <div className="flex-grow-1 d-block d-md-flex d-xl-block align-items-center">
                                <div className="total-hospital-title">
                                    Total Number Of Quality Awards
                                    <span className="total-hospital-count font-bold d-block">
                                        {
                                            dashboard.dashboardValues
                                                .qualityAwardsCount
                                        }
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </HospitalCardWrapper>
        );
    };

    const renderNewlyRegisteredHospitals = () => {
        return (
            <RegisteredHospitalsWrapper className="flex-grow-1 w-100 mb-5">
                <h2 className="h4 font-400 dashboard-newreg mb-4">
                    Recently Registered Organizations
                </h2>
                {isDashboardTableLoading ? (
                    <div
                        role="alert"
                        aria-label="recently Registered and recently certified organizations tables are loading"
                        className="sr-only"
                    />
                ) : (
                    ""
                )}
                <div className="hospital-reg-table">
                    <table
                        className={`aui-responsive-status-table ${
                            isDashboardTableLoading
                                ? CONSTANTS.TABLE_SPINNER
                                : ""
                        }`}
                    >
                        <thead>
                            <tr>
                                <th scope="col">
                                    <div className="aui-th">
                                        Organization Code
                                    </div>
                                </th>
                                <th scope="col">
                                    <div className="aui-th">
                                        Organization Name
                                    </div>
                                </th>
                                <th scope="col">
                                    <div className="aui-th">Program Type</div>
                                </th>
                                <th scope="col">
                                    <div className="aui-th">Location</div>
                                </th>
                                <th scope="col">
                                    <div className="aui-th">State</div>
                                </th>
                                <th scope="col">
                                    <div className="aui-th">Status</div>
                                </th>
                            </tr>
                        </thead>
                        {dashboard.newlyRegisteredHospitals.length > 0 && (
                            <tbody>
                                {dashboard.newlyRegisteredHospitals.map(
                                    (hospital: any) => {
                                        return (
                                            <tr
                                                key={`${hospital.hospitalId}-${hospital.programName}`}
                                                className={hospitalApprovalStatus(
                                                    hospital.programApprovalStatus
                                                )}
                                            >
                                                <td data-title="Organization Code">
                                                    <div className="aui-td">
                                                        <span className="abbr-code">
                                                            {wordSplit(
                                                                hospital.facilityCode
                                                            )}
                                                        </span>
                                                    </div>
                                                </td>
                                                <td data-title="Organization Name">
                                                    <div className="aui-td">
                                                        <Link
                                                            to={{
                                                                pathname:
                                                                    hospital.programApprovalStatus ===
                                                                    "APPROVED"
                                                                        ? `/admin/organization-management/organization-details/${hospital.hospitalId}/program/${hospital.programsId}/category/${hospital.categoryId}`
                                                                        : `/admin/organization-management/approve-organization/${hospital.hospitalId}/program/${hospital.programsId}/category/${hospital.categoryId}`,
                                                                state: {
                                                                    backToAdminDashboard:
                                                                        "/admin/dashboard",
                                                                },
                                                            }}
                                                            className="btn-text-link-uline"
                                                        >
                                                            {
                                                                hospital.hospitalName
                                                            }
                                                        </Link>
                                                    </div>
                                                </td>
                                                <td data-title="Program Type">
                                                    <div className="aui-td">
                                                        {hospital.categoryName}
                                                    </div>
                                                </td>
                                                <td data-title="Country">
                                                    <div className="aui-td">
                                                        {hospital.countryName}
                                                    </div>
                                                </td>
                                                <td data-title="State">
                                                    <div className="aui-td">
                                                        {hospital.stateName}
                                                    </div>
                                                </td>
                                                <td data-title="Status">
                                                    <div className="aui-td">
                                                        {
                                                            hospital.programApprovalStatus
                                                        }
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    }
                                )}
                            </tbody>
                        )}
                    </table>
                    <div className="table-emptystate-outer">
                        {dashboard.newlyRegisteredHospitals.length < 1 && (
                            <div>No data to display</div>
                        )}
                    </div>
                </div>
            </RegisteredHospitalsWrapper>
        );
    };

    const checkIsExpierSoon = (
        expiresOn: any,
        programId: any,
        hospitalId: any
    ) => {
        if (!expiresOn) {
            return "";
        }
        return (
            <div>
                <ExpireIndicator
                    expiresOn={expiresOn}
                    programId={programId}
                    hospitalId={hospitalId}
                />
            </div>
        );
    };

    useEffect(() => {
        if (CONSTANTS.WINDOW.AUI.Tooltip) {
            CONSTANTS.WINDOW.AUI.Tooltip.init();
        }
    });

    return (
        <AdminWrapper className="container-fluid p-0 d-flex flex-grow-1">
            <div className="row no-gutters w-100 flex-grow-1">
                <Sidebar activeTab="dashboard" />

                <section className="d-flex admin-main-content container">
                    <div className="row no-gutters w-100 admin-main">
                        <div className="col-12">{renderCards()}</div>
                        <div className="col-12">
                            {renderNewlyRegisteredHospitals()}
                        </div>
                        <div className="col-12">
                            <CertifiedHospitalsWrapper>
                                <h2 className="h4 font-400 dashboard-newreg mb-4">
                                    Recently Certified Organizations
                                </h2>
                                <div className="hospital-cer-table">
                                    <table
                                        className={`aui-responsive-status-table ${
                                            isDashboardTableLoading
                                                ? CONSTANTS.TABLE_SPINNER
                                                : ""
                                        }`}
                                    >
                                        <thead>
                                            <tr>
                                                <th scope="col">
                                                    <div className="aui-th">
                                                        Organization Code
                                                    </div>
                                                </th>
                                                <th scope="col">
                                                    <div className="aui-th">
                                                        Organization Name
                                                    </div>
                                                </th>
                                                <th scope="col">
                                                    <div className="aui-th">
                                                        Program Type
                                                    </div>
                                                </th>
                                                <th scope="col">
                                                    <div className="aui-th">
                                                        Location
                                                    </div>
                                                </th>
                                                <th scope="col">
                                                    <div className="aui-th">
                                                        State
                                                    </div>
                                                </th>
                                                <th scope="col">
                                                    <div className="aui-th">
                                                        Granted On
                                                    </div>
                                                </th>
                                                <th scope="col">
                                                    <div className="aui-th">
                                                        Expires On
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        {dashboard.newlyCertifiedFacility
                                            .length > 0 && (
                                            <tbody>
                                                {dashboard.newlyCertifiedFacility.map(
                                                    (hospital: any) => {
                                                        return (
                                                            <tr
                                                                key={
                                                                    hospital.hospitalId
                                                                }
                                                                className={expireDataRowStatus(
                                                                    hospital.expiresOn,
                                                                    hospital.programApprovalStatus
                                                                )}
                                                            >
                                                                <td data-title="Organization Code">
                                                                    <div className="aui-td">
                                                                        <div>
                                                                            <div className="mt-1">
                                                                                <span className="abbr-code">
                                                                                    {wordSplit(
                                                                                        hospital.facilityCode
                                                                                    )}
                                                                                </span>
                                                                            </div>
                                                                            {checkIsExpierSoon(
                                                                                hospital.expiresOn,
                                                                                hospital.programsId,
                                                                                hospital.hospitalId
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td data-title="Organization Name">
                                                                    <div className="aui-td">
                                                                        <Link
                                                                            to={{
                                                                                pathname:
                                                                                    hospital.programApprovalStatus ===
                                                                                    "APPROVED"
                                                                                        ? `/admin/organization-management/organization-details/${hospital.hospitalId}/program/${hospital.programsId}/category/${hospital.categoryId}`
                                                                                        : `/admin/organization-management/approve-organization/${hospital.hospitalId}/program/${hospital.programsId}/category/${hospital.categoryId}`,
                                                                                state: {
                                                                                    backToAdminDashboard:
                                                                                        "/admin/dashboard",
                                                                                },
                                                                            }}
                                                                            className="btn-text-link-uline"
                                                                        >
                                                                            {
                                                                                hospital.hospitalName
                                                                            }
                                                                        </Link>
                                                                    </div>
                                                                </td>
                                                                <td data-title="Program Type">
                                                                    <div className="aui-td">
                                                                        {
                                                                            hospital.programName
                                                                        }
                                                                    </div>
                                                                </td>
                                                                <td data-title="Country">
                                                                    <div className="aui-td">
                                                                        {
                                                                            hospital.countryName
                                                                        }
                                                                    </div>
                                                                </td>
                                                                <td data-title="State">
                                                                    <div className="aui-td">
                                                                        {
                                                                            hospital.stateName
                                                                        }
                                                                    </div>
                                                                </td>
                                                                <td data-title="Granted On">
                                                                    <div className="aui-td">
                                                                        {localDateToYYYYMMDDFormatString(
                                                                            hospital.grantedOn
                                                                        )}
                                                                    </div>
                                                                </td>
                                                                <td data-title="Expires On">
                                                                    <div className="aui-td">
                                                                        {localDateToYYYYMMDDFormatString(
                                                                            hospital.expiresOn
                                                                        )}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        );
                                                    }
                                                )}
                                            </tbody>
                                        )}
                                    </table>
                                    <div className="table-emptystate-outer">
                                        {dashboard.newlyCertifiedFacility
                                            .length < 1 && (
                                            <div>No data to display</div>
                                        )}
                                    </div>
                                </div>
                            </CertifiedHospitalsWrapper>
                        </div>
                    </div>
                </section>
            </div>
        </AdminWrapper>
    );
};

export default AdminDashboardPage;
